import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
// Material UI
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
// App Shared
import { LOCAL_STORAGE_KEYS } from '@shared/constants';
import { Newsroom } from '@shared/dialogs';
import { ReactComponent as NewsIcon } from '@shared/assets/icon-news.svg';
import { Routes } from '@shared/enums';
import { useUserInterface, useUserContext } from '@shared/hooks';

export function NewsPromotionOverlay() {
  /* #region  Hooks */
  const styles = useStyles();

  const user = useUserContext();
  const { update, isOpenNewsroom } = useUserInterface();

  const isHomePage = !!useRouteMatch(Routes.Home);
  const isSharedMeetingsPage = !!useRouteMatch(Routes.SharedWithMe);
  const isBookmarksPage = !!useRouteMatch(Routes.Bookmarks);
  const isMyAssignmentsPage = !!useRouteMatch(Routes.MyAssignments);

  const [isClosed, setIsClosed] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleShowNewsroom = (open: boolean) => () => {
    update({ isOpenNewsroom: open });
  };

  const handleClickOnCloseButton: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    const key = LOCAL_STORAGE_KEYS.lastNewsPromotion;
    const oldValue = localStorage.getItem(key);
    const newValue = DateTime.local().toISODate();

    localStorage.setItem(key, newValue);
    window.dispatchEvent(new StorageEvent('storage', { key, oldValue, newValue }));
    setIsClosed(true);
  };
  /* #endregion */

  /* #region  Helpers */
  const hasNews = user.data?.me?.hasUnreadRcNews ?? false;
  const isPromoted = checkIsNewsPromoted();
  const isOpen = hasNews && !isPromoted && !isClosed;
  const isVisible = isHomePage || isSharedMeetingsPage || isBookmarksPage || isMyAssignmentsPage;
  /* #endregion */

  return (
    <>
      <Grow
        in={!!isOpen}
        style={{
          visibility: isVisible ? 'visible' : 'hidden',
          transformOrigin: '100% 100% 0',
          ...(!isOpen && { right: -9999 }),
        }}
      >
        <div className={styles.announcement} onClick={handleShowNewsroom(true)}>
          <NewsIcon />
          <Box ml={1}>See what's new in Sembly!</Box>
          <IconButton className={styles.dismiss} size="small" onClick={handleClickOnCloseButton}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      </Grow>

      {isOpenNewsroom && <Newsroom onClose={handleShowNewsroom(false)} />}
    </>
  );
}

export function checkIsNewsPromoted(): boolean {
  const currentDate = DateTime.local();
  const lastPromotionDate = localStorage.getItem(LOCAL_STORAGE_KEYS.lastNewsPromotion);

  return !!lastPromotionDate && DateTime.fromISO(lastPromotionDate).hasSame(currentDate, 'week');
}

const useStyles = makeStyles((theme) => ({
  announcement: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.surface.contrastText,
    background: theme.palette.surface.main,
    borderRadius: theme.shape.borderRadius * 2,
    position: 'absolute',
    padding: theme.spacing(2),
    right: theme.spacing(2),
    width: 'calc(100vw - 40px)',
    maxWidth: 210,
    bottom: 62,
    zIndex: 10,
    '&:hover': {
      background: theme.palette.surface.dark,
    },
  },
  dismiss: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(0.5),
    position: 'absolute',
    top: -10,
    right: -10,
    fontSize: 14,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
}));

export default NewsPromotionOverlay;
