import { lazyWithRetry } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

const ChangePasswordModuleContainer = lazyWithRetry(
  () => import('./containers/ChangePasswordModuleContainer'),
);

export const ChangePasswordModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.ChangePassword,
    render: () => <ChangePasswordModuleContainer />,
  },
};

export default ChangePasswordModule;
