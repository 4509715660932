// Material UI
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

export interface ToggleOptionProps {
  key: string;
  value: string;
  component: JSX.Element;
}

export interface ToggleProps {
  className?: string;
  options: ToggleOptionProps[];
  value: string;
  onChange: (value: string) => void;
}

export const Toggle: React.VFC<ToggleProps> = ({ className, options, value, onChange }) => {
  const styles = useStyles();

  const handleChangeBellingType = (event: unknown, value: string) => {
    if (value === null) return;
    onChange(value);
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      className={`${styles.root} ${className}`}
      aria-label="Billing Types"
      onChange={handleChangeBellingType}
    >
      {options.map((option) => (
        <ToggleButton
          disableRipple
          key={option.key}
          value={option.value}
          classes={{ root: styles.button, selected: 'selected' }}
        >
          {option.component}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '2em',
    backgroundColor: theme.palette.grey[100],
  },
  button: {
    textTransform: 'none',
    position: 'relative',
    padding: theme.spacing(1, 3),
    color: theme.palette.text.primary,
    borderRadius: '2em',
    fontWeight: 400,
    lineHeight: 1.5,
    textAlign: 'center',
    zIndex: 2,
    '&.selected': {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      zIndex: 1,
      cursor: 'default',
      pointerEvents: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));
