// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export interface ResourceCenterSectionProps {
  title: string;
}

export const ResourceCenterSection: React.FC<ResourceCenterSectionProps> = ({
  title,
  children,
}) => (
  <Box mb={2}>
    <Typography gutterBottom variant="subtitle1">
      <b>{title}</b>
    </Typography>
    {children}
  </Box>
);

export default ResourceCenterSection;
