import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

export interface BrowseAppsButtonProps {
  onClick?: () => void;
}

/**
 * Button to browse all integration apps.
 */
export const BrowseAppsButton: React.VFC<BrowseAppsButtonProps> = ({ onClick }) => {
  const styles = useStyles();

  return (
    <div className={styles.root} onClick={onClick}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path
          fill="#00B3B3"
          d="M4.5 8H8.5V4H4.5V8ZM10.5 20H14.5V16H10.5V20ZM4.5 20H8.5V16H4.5V20ZM4.5 14H8.5V10H4.5V14ZM10.5 14H14.5V10H10.5V14ZM16.5 4V8H20.5V4H16.5ZM10.5 8H14.5V4H10.5V8ZM16.5 14H20.5V10H16.5V14ZM16.5 20H20.5V16H16.5V20Z"
        />
      </svg>
      <Typography variant="subtitle2">Browse all apps</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      boxShadow: theme.shadows[2],
    },
  },
}));
