import { lazyWithRetry } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes as R } from '@shared/enums';

const ChangeEmailModuleContainer = lazyWithRetry(
  () => import('./containers/ChangeEmailModuleContainer'),
);

export const ChangeEmailModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: R.ChangeEmail,
    render: () => <ChangeEmailModuleContainer />,
  },
};

export default ChangeEmailModule;
