import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { useTheme, useMediaQuery } from '@material-ui/core';
// Lib Queries and Types
import meetingQuery from '../graphql/queries/Meeting.graphql';
import createChatMutation from '../graphql/mutations/CreateAssociatesChat.graphql';
import insightsQuery from '../graphql/queries/MeetingInsightsContainerQuery.graphql';
import generateInsightsMutation from '../graphql/mutations/GenerateMeetingInsights.graphql';
import {
  AgentCallPlatform,
  CreateAssociatesChat,
  CreateAssociatesChatVariables,
  GenerateMeetingInsights,
  GenerateMeetingInsightsVariables,
  GenericMeetingInsight,
  GraphError,
  Meeting,
  MeetingInsightsContainerQuery,
  MeetingInsightsContainerQueryVariables,
  MeetingStatuses,
  MeetingVariables,
  RedirectTarget,
} from '../types';
// Lib Components
import GenericDialog from '../components/GenericDialog';
import MeetingInsightCard from '../components/MeetingInsightCard';
import MeetingPlaceholderInsights from '../components/MeetingPlaceholderInsights';
import MeetingPlaceholderProcessing from '../components/MeetingPlaceholderProcessing';
import { WorkstreamsListContainer } from '../containers';
import { formatInTimeZone } from '../utils';
import insightsImage from '../assets/icon-insights-64.svg';

/* #region  Types */
interface CopyContentProps {
  meetingId: string;
  item: GenericMeetingInsight;
}

export interface MeetingInsightsContainerProps {
  isPromoteUpgrade?: boolean;
  isRestrictedKeyItemsPromotion: boolean;
  meetingId: string;
  onClickOnCopyContent: (props: CopyContentProps) => void;
  onClickOnUpgradePlan?: () => void;
  onCreatedChat: (chatId: string) => void;
  onRedirect: (target: RedirectTarget) => void;
  onRequestInsightsGeneration: () => boolean;
  onResponseError?: (error: GraphError) => void;
}
/* #endregion */

/**
 * The container component that renders the meeting insights
 */
export const MeetingInsightsContainer: React.VFC<MeetingInsightsContainerProps> = ({
  isPromoteUpgrade = false,
  isRestrictedKeyItemsPromotion,
  meetingId,
  onClickOnCopyContent,
  onClickOnUpgradePlan,
  onCreatedChat,
  onRedirect,
  onRequestInsightsGeneration,
  onResponseError = () => null,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isRefetchingInsights, setIsRefetchingInsights] = useState(false);
  const [chatCreatorData, setChatCreatorData] = useState<{
    anchorEl: HTMLButtonElement | null;
    insightId: string;
  } | null>(null);

  const { data, loading, refetch } = useQuery<
    MeetingInsightsContainerQuery,
    MeetingInsightsContainerQueryVariables
  >(insightsQuery, {
    variables: { meetingId },
  });

  const [generateInsights, { loading: isGeneratingInsights }] = useMutation<
    GenerateMeetingInsights,
    GenerateMeetingInsightsVariables
  >(generateInsightsMutation);

  const [createChat, { loading: isCreatingChat }] = useMutation<
    CreateAssociatesChat,
    CreateAssociatesChatVariables
  >(createChatMutation);

  const { data: meetingData } = useQuery<Meeting, MeetingVariables>(meetingQuery, {
    variables: { meetingId },
  });
  /* #endregion */

  /* #region  Handlers  */
  const handleGenerateInsights = async () => {
    if (!onRequestInsightsGeneration()) return;
    const response = await generateInsights({ variables: { meetingId } });
    if (!response.data?.generateInsights?.success) {
      onResponseError(response.data?.generateInsights?.errors);
    }
  };

  const handleCreateChat =
    (insightId: string) => async (workstreamId: string | null = null, workstreamName: string | null = null) => {
      const currentDate = formatInTimeZone(new Date(), 'PP p');
      const name = workstreamName
        ? `${workstreamName} | ${currentDate}`
        : `New Chat | ${currentDate}`;

      const response = await createChat({
        variables: { workstreamId, insightId, name },
        update: (cache) => {
          // Remove the chatsPaginated cache to force a refetch
          cache.evict({ fieldName: 'chatsPaginated' });
        },
      });

      if (response.data?.createChat?.chat?.id) {
        onCreatedChat(response.data.createChat.chat.id);
      } else {
        onResponseError(response.data?.createChat?.errors);
      }
    };

  const handleClickOnCreateChat =
    (item: GenericMeetingInsight) => (event: React.MouseEvent<HTMLButtonElement>) => {
      handleCreateChat(item.id)();
      // Currently, we are not opening the dialog to select the workstream
      //setChatCreatorData({ anchorEl: event.currentTarget, insightId: item.id });
    };

  const handleCopyContent = (item: GenericMeetingInsight) => () => {
    onClickOnCopyContent({ meetingId, item });
  };

  const handleCloseWorkstreamsList = () => {
    setChatCreatorData(null);
  };
  /* #endregion */

  useEffect(() => {
    const hasInsights = (data?.meeting?.insights?.length || 0) > 0;

    // Refetching the insight items
    const refetchInsights = async () => {
      setIsRefetchingInsights(true);
      await refetch();
      setIsRefetchingInsights(false);
    };

    if (data?.meeting?.processingResults?.processedAssignments && !hasInsights && !loading) {
      refetchInsights();
    }
  }, [data?.meeting?.processingResults, data?.meeting?.insights?.length, loading, refetch]);

  // Props validation
  if (isPromoteUpgrade && !onClickOnUpgradePlan) {
    throw new Error('If `isPromoteUpgrade` is `true`, handler is required');
  }

  /* #region  Render Helpers */
  const meeting = data?.meeting;
  const meetingInsights = meeting?.insights;
  const processingResults = meeting?.processingResults;
  const agentCall = meetingData?.meeting?.agentCall;
  const isTranscribed = meeting?.processingResults?.processedTranscribing ?? false;
  const isProcessedAssignments = processingResults?.processedAssignments ?? false;
  const isProcessedAnalytics = processingResults?.processedAnalytics ?? false;
  const isProcessedNotes = processingResults?.processedMeetingNotes ?? false;
  const isProcessedInsights = processingResults?.processedInsights ?? false;
  const isProcessingInsights = processingResults?.insightsInProcessing ?? false;
  const isFailedMeeting = meeting?.status === MeetingStatuses.failed;
  const isScheduledMeeting = meeting?.status === MeetingStatuses.scheduled;
  const isNotScheduledMeeting = meeting?.status === MeetingStatuses.not_scheduled;
  const isOnCallMeeting = meeting?.status === MeetingStatuses.on_call;
  const isProcessingComplete = meeting?.status !== MeetingStatuses.processing;
  const isManualUpload = agentCall?.platform === AgentCallPlatform.MANUAL_UPLOAD;
  /* #endregion */

  // Render loading indicator if the data is not ready
  if (loading || isRefetchingInsights) {
    return (
      <Box position="relative" width="100%" paddingTop={4}>
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (isProcessingInsights) {
    return (
      <Box position="relative" width="100%" paddingTop={4}>
        <Box textAlign="center" mt={8} mb={3}>
          <Box mt={isSmallScreen ? 3 : 5} mb={isSmallScreen ? 3 : 4}>
            <img
              alt="Meeting Tasks"
              width={isSmallScreen ? 42 : 64}
              height={isSmallScreen ? 42 : 64}
              src={insightsImage}
            />
          </Box>
        </Box>
        <Typography gutterBottom variant={isSmallScreen ? 'h6' : 'h4'} align="center">
          We are working on your insights...
        </Typography>
        <Typography
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
          color="textSecondary"
          align="center"
        >
          Hang tight, your results will be available in a few minutes.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {isProcessingComplete || isProcessedInsights ? (
        <Box mt={4}>
          {isProcessedInsights ? (
            <>
              <Box mb={1} display="flex" flexWrap="wrap" alignItems="center" gridGap={8}>
                <Box flex={1}>
                  <Typography variant="h4">My Insights </Typography>
                </Box>
              </Box>
              {!!meetingInsights?.length ? (
                <Box mt={2} display="flex" flexWrap="wrap" gridGap={8}>
                  {meetingInsights.map((insight, index) => (
                    <MeetingInsightCard
                      key={insight.id}
                      title={insight.name}
                      topic={insight.topic}
                      nextSteps={insight.nextSteps}
                      deliveriable={insight.deliverable}
                      colorThemeIndex={index}
                      onCopy={handleCopyContent(insight)}
                      onClickOnActionButton={handleClickOnCreateChat(insight)}
                    />
                  ))}
                </Box>
              ) : (
                <Box mt={8} textAlign="center">
                  <Box mt={isSmallScreen ? 3 : 5} mb={isSmallScreen ? 3 : 4}>
                    <img
                      alt="Meeting Tasks"
                      width={isSmallScreen ? 42 : 64}
                      height={isSmallScreen ? 42 : 64}
                      src={insightsImage}
                    />
                  </Box>
                  <Typography gutterBottom variant={isSmallScreen ? 'h6' : 'h4'} align="center">
                    No Insights
                  </Typography>
                  <Typography
                    variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
                    color="textSecondary"
                    align="center"
                  >
                    There are no insights available for this meeting
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <>
              {isFailedMeeting || isNotScheduledMeeting || isScheduledMeeting || isOnCallMeeting ? (
                <MeetingPlaceholderInsights
                  failureReason={agentCall?.failureReason || null}
                  isManualRecording={isManualUpload}
                  isProcessedAnalytics={isProcessedAnalytics}
                  isProcessedNotes={isProcessedNotes}
                  isRestrictedKeyItems={false}
                  isTranscribed={isTranscribed}
                  meetingStatus={meeting.status}
                  onRedirect={onRedirect}
                />
              ) : (
                <Box mt={8} textAlign="center">
                  <Box mt={isSmallScreen ? 3 : 5} mb={isSmallScreen ? 3 : 4}>
                    <img
                      alt="Meeting Tasks"
                      width={isSmallScreen ? 42 : 64}
                      height={isSmallScreen ? 42 : 64}
                      src={insightsImage}
                    />
                  </Box>
                  <Typography gutterBottom variant={isSmallScreen ? 'h6' : 'h4'} align="center">
                    Generate Personal Insights
                  </Typography>
                  <Typography
                    variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
                    color="textSecondary"
                    align="center"
                  >
                    Discover the most impactful actions and deliverables for this meeting, tailored
                    specifically to you.
                  </Typography>
                  <Box mt={4}>
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      disabled={isGeneratingInsights}
                      endIcon={isGeneratingInsights ? <CircularProgress size={16} /> : null}
                      onClick={handleGenerateInsights}
                    >
                      Generate
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      ) : (
        // Meeting is not processed yet
        <MeetingPlaceholderProcessing
          isProcessedAnalytics={isProcessedAnalytics}
          isProcessedAssignments={isProcessedAssignments}
          isProcessedNotes={isProcessedNotes}
          isPromoteUpgrade={isPromoteUpgrade}
          isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
          isTranscribed={isTranscribed}
          meetingDuration={meeting.duration}
          meetingEnd={meeting.finishedAt}
          onChangeRoute={onRedirect}
          onClickOnUpgradePlan={onClickOnUpgradePlan}
        />
      )}
      {!!chatCreatorData && (
        <GenericDialog
          title="Select a workstream associated with this insight"
          dialogProps={{ fullWidth: true, maxWidth: 'md', fullScreen: isSmallScreen }}
          onClose={handleCloseWorkstreamsList}
        >
          <DialogContent>
            <WorkstreamsListContainer
              processing={isCreatingChat}
              onSelect={handleCreateChat(chatCreatorData.insightId)}
            />
          </DialogContent>
        </GenericDialog>
      )}
    </>
  );
};

export default MeetingInsightsContainer;
