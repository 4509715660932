import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
// Lib Shared
import { useConfirmationDialog } from '../hooks';
import { EditRecurringMeeting } from '../dialogs';
import { GenericTeam, MyWorkspaceTeams } from '../types';
import myWorkspaceTeamsQuery from '../graphql/queries/MyWorkspaceTeams.graphql';

export interface MeetingTeamPickerProps {
  isDisabled?: boolean;
  isRecurrentMeeting: boolean;
  selectedTeam: GenericTeam | null;
  variant?: 'outlined' | 'contained';
  onChange: (value: GenericTeam, variant: 'all' | 'single') => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const MeetingTeamPicker: React.VFC<MeetingTeamPickerProps> = ({
  isDisabled = false,
  isRecurrentMeeting,
  selectedTeam,
  variant = 'contained',
  onChange,
  onClick = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [requestData, { data, loading, called }] =
    useLazyQuery<MyWorkspaceTeams>(myWorkspaceTeamsQuery);

  const [confirmUpdate, ConfirmationDialog] = useConfirmationDialog<null | 'all' | 'single'>(
    (resolve) => (
      <EditRecurringMeeting onClose={() => resolve(null)} onSubmit={(value) => resolve(value)} />
    ),
  );
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnMenuTrigger = (event: React.MouseEvent<HTMLElement>) => {
    onClick(event);
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (value: GenericTeam) => async (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);

    const variant = isRecurrentMeeting ? await confirmUpdate() : 'single';

    if (variant !== null) {
      onChange(value, variant);
    }
  };

  const handleCloseMenu = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const isOpenMenu = Boolean(anchorEl);
  const isOutlined = variant === 'outlined';
  const teams = data?.myWorkspace?.teams || [];
  /* #endregion */

  useEffect(() => {
    // fetch teams when the menu is opened for the first time
    if (isOpenMenu && !called) requestData();
  }, [isOpenMenu, called, requestData]);

  return (
    <>
      <Button
        disableElevation
        variant="outlined"
        disabled={isDisabled}
        title={selectedTeam ? selectedTeam.description : 'Set team'}
        endIcon={
          isOpenMenu ? (
            <CloseIcon color={isDisabled ? 'disabled' : 'inherit'} />
          ) : (
            <OpenIcon color={isDisabled ? 'disabled' : 'inherit'} />
          )
        }
        classes={{ root: isOutlined ? styles.buttonOutlined : styles.buttonContained }}
        onClick={handleClickOnMenuTrigger}
      >
        <Box ml={1} className={styles.menuItem}>
          {selectedTeam ? selectedTeam.name : 'Set team'}
        </Box>
      </Button>
      {/* Begin: Menus */}
      <Menu
        anchorEl={anchorEl}
        open={isOpenMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={styles.menu}
        getContentAnchorEl={null}
        onClose={handleCloseMenu}
      >
        <Box my={1} mr={1} ml={1} maxHeight={280} overflow="auto">
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={18} />
              <Box ml={1}>Loading teams...</Box>
            </MenuItem>
          ) : (
            <>
              {teams.length > 0 ? (
                <>
                  {teams.map((team) => (
                    <MenuItem
                      key={team.id}
                      title={team.description}
                      selected={selectedTeam?.id === team.id}
                      onClick={handleSelect(team)}
                    >
                      <span className={styles.menuItem}>{team.name}</span>
                    </MenuItem>
                  ))}
                </>
              ) : (
                <MenuItem disabled>There are no teams in your workspace</MenuItem>
              )}
            </>
          )}
        </Box>
      </Menu>
      {ConfirmationDialog}
      {/* End: Menus */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: theme.spacing(1 / 2),
  },
  menuItem: {
    maxWidth: 220,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonContained: {
    backgroundColor: theme.palette.meetingTeam.backgroudColor.main,
    color: theme.palette.meetingTeam.color,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0.25, 1),
    border: 'none',
    whiteSpace: 'nowrap',
    '&:disabled': {
      color: theme.palette.meetingTeam.color,
      border: 'none !important',
    },
  },
  buttonOutlined: {
    padding: theme.spacing(0, 1),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body1.fontSize,
  },
}));

export default MeetingTeamPicker;
