import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

export const OutlinedIconButton: React.VFC<IconButtonProps> = ({
  children,
  className,
  ...props
}) => {
  const styles = useStyles();

  return (
    <IconButton className={`${styles.root} ${className}`} {...props}>
      {children}
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
  },
}));

export default OutlinedIconButton;
