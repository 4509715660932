// Material UI
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
import BackIcon from '@material-ui/icons/ArrowBackSharp';
import InfoIcon from '@material-ui/icons/InfoOutlined';
// Lib Shared
import { GenericDialog } from '../components';
import { INTEGRATION_APPS, IntegrationAppsDataType } from '../constants';
import continueWithGoogleButtonImage from '../assets/icon-continue-with-google.svg';

export interface IntegrationAppManagerProps {
  connected: boolean;
  connecting?: boolean;
  identifier: string;
  logoUri?: string;
  name: string;
  open: boolean;
  onBrowseApps: () => void;
  onClose: () => void;
  onConnect: () => void;
  onDisconnect: () => void;
  onNewAutomation: () => void;
}

export const IntegrationAppManager: React.VFC<IntegrationAppManagerProps> = ({
  connected,
  connecting = false,
  identifier,
  logoUri,
  name,
  open,
  onBrowseApps,
  onClose,
  onConnect,
  onDisconnect,
  onNewAutomation,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* #endregion */

  /* #region  Handlers */
  const handleConnect = () => {
    onConnect();
  };

  const handleDisconnect = () => {
    onDisconnect();
    onClose();
  };

  const handleNewAutomation = () => {
    onNewAutomation();
    onClose();
  };
  /* #endregion */

  const info: IntegrationAppsDataType = INTEGRATION_APPS[identifier] || {
    title: name,
    description: `Automatically send meeting notes, tasks and transcription to ${name}`,
    category: 'Other',
    categories: [],
    features: [
      'Automatically or manually send meeting notes to selected destination',
      'Automatically or manually send transcriptions to selected destination',
      'Automatically or manually send tasks to selected destination',
    ],
  };

  const getConnectButton = (identifier: string) => {
    const googleIntegrations = ['gdrive', 'sheets'];

    // Temporarily adding special Google button as an exception according to google validation requirements
    if (googleIntegrations.includes(identifier)) {
      return (
        <div className={styles.appConnect}>
          <InfoIcon color="disabled" />
          <Typography variant="body1" align="center">
            Connect your {name} workspace to create your first automation.
          </Typography>
          <img
            src={continueWithGoogleButtonImage}
            style={{ cursor: 'pointer' }}
            alt="Continue with Google"
            onClick={handleConnect}
          />
        </div>
      );
    }

    return (
      <div className={styles.appConnect}>
        <InfoIcon color="disabled" />
        <Typography variant="body1" align="center">
          Connect your {name} workspace to create your first automation.
        </Typography>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          aria-label="Connect app"
          disabled={connecting}
          endIcon={connecting && <CircularProgress size={16} color="inherit" />}
          onClick={handleConnect}
        >
          <Typography component="span" variant="body1">
            Connect
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <GenericDialog
      title="Apps"
      dialogProps={{ open, fullWidth: true, maxWidth: 'md', fullScreen: isSmallScreen }}
      onClose={onClose}
    >
      <DialogContent className={styles.dialogContent}>
        <Button
          variant="outlined"
          aria-label="Back to all apps"
          onClick={onBrowseApps}
          startIcon={<BackIcon color="action" />}
        >
          <Typography component="span" variant="body1">
            Browse all apps
          </Typography>
        </Button>

        <div className={styles.content}>
          <div className={styles.aside}>
            <div className={styles.appLogo}>
              {logoUri ? (
                <img
                  alt={name}
                  width={64}
                  height={64}
                  src={logoUri}
                  className={styles.appLogoImage}
                />
              ) : (
                <Avatar>{name.charAt(0).toUpperCase()}</Avatar>
              )}
            </div>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              aria-label="Create a new automation"
              disabled={!connected}
              startIcon={<AddIcon />}
              onClick={handleNewAutomation}
            >
              <Typography component="span" variant="body1">
                {isSmallScreen ? 'New' : 'New Automation'}
              </Typography>
            </Button>
            {connected ? (
              <Button
                disableElevation
                variant="outlined"
                aria-label="Disconnect app"
                onClick={handleDisconnect}
              >
                <Typography component="span" variant="body1">
                  Disconnect
                </Typography>
              </Button>
            ) : (
              getConnectButton(identifier)
            )}
          </div>
          <div className={styles.main}>
            <Typography variant="h4">{info.title}</Typography>
            <Typography variant="body1">{info.description}</Typography>
            {info.categories.length > 0 && (
              <Box mt={1} display="flex" gridGap={6}>
                {info.categories.map((tag, index) => (
                  <Chip key={index} label={tag} variant="outlined" />
                ))}
              </Box>
            )}
            {info.features.length > 0 && (
              <Box mt={2}>
                <Typography variant="h6">Key Features</Typography>
                <Typography component="div" variant="body1">
                  <ul>
                    {info.features.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))}
                  </ul>
                </Typography>
              </Box>
            )}
          </div>
        </div>
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dialogContent: {
    padding: theme.spacing(1, 4, 4),
    marginBottom: theme.spacing(1),
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: 216,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: theme.spacing(1.5),
    },
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  appLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 216,
    width: 216,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  appLogoImage: {
    objectFit: 'contain',
  },
  appConnect: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export default IntegrationAppManager;
