/**
 * Check if the device is mobile
 * @returns {boolean} true if the device is mobile
 */
export function isMobile(): boolean {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

export default isMobile;
