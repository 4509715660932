import unescape from 'lodash/unescape';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import AddCommentIcon from '@material-ui/icons/AddComment';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import StopIcon from '@material-ui/icons/Stop';
import VerifyIcon from '@material-ui/icons/VerifiedUser';
// Lib Components
import { AccentSplitSwitch, AccentSplitSwitchMenuItem } from './AccentSplitSwitch';
import { AccentSwitch } from './AccentSwitch';
import { LanguageFlag } from './LanguageFlag';
import { MeetingParticipantAvatars, MeetingParticipant } from './MeetingParticipantAvatars';
import { MeetingStatusChip } from './MeetingStatusChip';
import { MeetingStatusInfo } from './MeetingStatusInfo';
import { MeetingTypePicker } from './MeetingTypePicker';
import { MeetingTeamPicker } from '../containers';
// Lib Shared
import SemblianButtonIcon from '../assets/icon-ask-semblian.svg';
import { AGENT_CALL_PLATFORMS, MEETING_STATUSES } from '../constants';
import { ManualRecordType, CustomMeetingStatuses } from '../enums';
import { AttendAll, AttendNone, PlaylistEdit } from '../icons';
import { getFailureReason } from '../utils';
import {
  AgentCallPlatform,
  GenericTeam,
  Languages,
  MeetingProcessingResults,
  MeetingStatusEntity,
  MeetingStatuses,
  MeetingTypes,
} from '../types';

/* #region  Types */
export interface MeetingCardAgentCallProps {
  failureReason: string | null;
  platform: AgentCallPlatform | null;
  url: string | null;
}

export interface MeetingCardAttendanceItemProps {
  id: string;
  message: string | null;
}

export interface MeetingLanguageSettingsProps {
  primaryLanguage: Languages;
  additionalLanguage: Languages | null;
}

export interface MeetingCardMeetingProps {
  editorName: string | null;
  finishDate: string | null;
  id: string;
  languageSettings: MeetingLanguageSettingsProps;
  ownerFullName: string | null;
  participants: MeetingParticipant[];
  processingResults: MeetingProcessingResults | null;
  startDate: string | null;
  status: MeetingStatuses;
  team: GenericTeam | null;
  title: string | null;
  type: MeetingTypes | null;
}

export interface MeetingCardProps {
  agentCall: MeetingCardAgentCallProps;
  attendanceItem: MeetingCardAttendanceItemProps | null;
  disableToggleSerie?: boolean;
  isAttending: boolean;
  isAuthorizedToManage: boolean;
  isAuthorizedToRecord: boolean;
  isDisabledEditingMeetingType?: boolean;
  isInContextWorkspace: boolean;
  isLoading?: boolean;
  isPromotingAIChat?: boolean;
  isRecordingProhibited: boolean;
  isRecurrentMeeting: boolean;
  isVisibleMeetingTeamSelector: boolean;
  meeting: MeetingCardMeetingProps;
  onChangingMeetingType: (
    meetingId: string,
    meetingType: MeetingTypes,
    variant: 'all' | 'single',
  ) => void;
  onChangingTeam: (meetingId: string, team: GenericTeam, variant: 'all' | 'single') => void;
  onClick: (meetingId: string, askSembly?: boolean) => void;
  onClickAgentCallUrl: (url: string) => void;
  onClickLanguageSettings: (meetingId: string) => void;
  onConfirmAttendance: (attendanceItemId: string, meetingId: string) => void;
  onDeclineAttendance: (meetingId: string) => void;
  onPause: (meetingId: string) => void;
  onRequestingSupport: () => void;
  onResume: (meetingId: string) => void;
  onStop: (meetingId: string) => void;
  onUpdateAttendanceMessage: () => void;
  onVerify: (meetingId: string) => void;
  onToggleAttending: (
    meetingId: string,
    recordState: boolean,
    recordType: ManualRecordType,
  ) => void;
}

type ActionType =
  | 'CONFIRM_ATTENDANCE'
  | 'DECLINE_ATTENDANCE'
  | 'OPEN_LANGUAGE_SETTINGS'
  | 'REQUESTING_SUPPORT'
  | 'SET_PAUSE'
  | 'SET_RESUME'
  | 'SET_STOP'
  | 'TOGGLE_ATTENDING'
  | 'UPDATE_ATTENDANCE'
  | 'VERIFY'
  | 'VOID';
/* #endregion */

/**
 * Renders a meeting card
 */
export const MeetingCard: React.VFC<MeetingCardProps> = ({
  agentCall,
  attendanceItem,
  disableToggleSerie = false,
  isAttending,
  isAuthorizedToManage,
  isAuthorizedToRecord,
  isDisabledEditingMeetingType = false,
  isInContextWorkspace,
  isLoading = false,
  isPromotingAIChat = false,
  isRecordingProhibited,
  isRecurrentMeeting,
  isVisibleMeetingTeamSelector,
  meeting,
  onChangingMeetingType,
  onChangingTeam,
  onClick,
  onClickAgentCallUrl,
  onClickLanguageSettings,
  onConfirmAttendance,
  onDeclineAttendance,
  onPause,
  onRequestingSupport,
  onResume,
  onStop,
  onToggleAttending,
  onUpdateAttendanceMessage,
  onVerify,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* #endregion */

  const triggerEvent =
    (actionType: ActionType) => (event: React.MouseEvent | React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const changeEvent = event as React.ChangeEvent<HTMLInputElement>;
      const isWillAttend = changeEvent.target.checked;
      const type = ManualRecordType.SINGLE;

      // prettier-ignore
      switch (actionType) {
        case 'REQUESTING_SUPPORT':  onRequestingSupport();                                      break;
        case 'TOGGLE_ATTENDING':    onToggleAttending(meeting.id, isWillAttend, type);          break;
        case 'SET_PAUSE':           onPause(meeting.id);                                        break;
        case 'SET_RESUME':          onResume(meeting.id);                                       break;
        case 'SET_STOP':            onStop(meeting.id);                                         break;
        case 'VERIFY':              onVerify(meeting.id);                                       break;
        case 'CONFIRM_ATTENDANCE':  onConfirmAttendance(attendanceItem?.id || '', meeting.id);  break;
        case 'DECLINE_ATTENDANCE':  onDeclineAttendance(meeting.id);                            break;
        case 'OPEN_LANGUAGE_SETTINGS': onClickLanguageSettings(meeting.id);                     break;
      }
    };

  /* #region  Handlers */
  const handleClickMeetingCard = () => {
    onClick(meeting.id);
  };

  const handleChangeMeetingType = (meetingType: MeetingTypes, variant: 'all' | 'single') => {
    onChangingMeetingType(meeting.id, meetingType, variant);
  };

  const handleChangingTeam = (teamId: GenericTeam, variant: 'all' | 'single') => {
    onChangingTeam(meeting.id, teamId, variant);
  };

  const handleClickAskSembly = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick(meeting.id, true);
  };

  const handleSetSelectAttend = (state: boolean, type: ManualRecordType) => () => {
    onToggleAttending(meeting.id, state, type);
  };

  const handleClickMeetingUrl = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClickAgentCallUrl(agentCall.url!);
  };

  const handleShowHideDialog = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onUpdateAttendanceMessage();
  };
  /* #endregion */

  const attendMenuItems: AccentSplitSwitchMenuItem[] = [
    {
      label: 'Attend this and all following meetings',
      icon: <CheckIcon fontSize="small" />,
      onClick: handleSetSelectAttend(true, ManualRecordType.FOLLOWING),
    },
    {
      label: 'Attend all meetings',
      icon: <AttendAll fontSize="small" />,
      onClick: handleSetSelectAttend(true, ManualRecordType.ALL),
    },
    'divider',
    {
      label: 'Do not attend this and all following meetings',
      icon: <CloseIcon fontSize="small" />,
      onClick: handleSetSelectAttend(false, ManualRecordType.FOLLOWING),
    },
    {
      label: 'Do not attend all meetings',
      icon: <AttendNone fontSize="small" />,
      onClick: handleSetSelectAttend(false, ManualRecordType.ALL),
    },
  ];

  /* #region  Render Helpers */
  const customMeetingStatus = CustomMeetingStatuses.limit_exceeded;
  const meetingStatus = isRecordingProhibited ? customMeetingStatus : meeting.status;
  const meetingStatusEntity: MeetingStatusEntity = MEETING_STATUSES[meetingStatus];
  const meetingType = meeting.type;
  const hasScheduledStatus = meeting.status === MeetingStatuses.scheduled;
  const hasNotScheduledStatus = meeting.status === MeetingStatuses.not_scheduled;
  const hasProcessingResults = meeting && meeting.processingResults !== null;
  const isScheduled = hasScheduledStatus && isAttending;
  const isNotScheduled = hasNotScheduledStatus || (hasScheduledStatus && !isAttending);
  const isSubmitted = meeting.status === MeetingStatuses.submitted;
  const isProcessing = meeting.status === MeetingStatuses.processing;
  const isFailed = meeting.status === MeetingStatuses.failed;
  const isOnCall = meeting.status === MeetingStatuses.on_call;
  const isOnPause = meeting.status === MeetingStatuses.on_pause;
  const isOnLive = isOnCall || isOnPause;
  const isJoinable = isScheduled || isNotScheduled || isOnLive;
  const isCompleted = isProcessing || isSubmitted;
  const isManualRecord = agentCall.platform === AgentCallPlatform.MANUAL_RECORD;
  const isManualUpload = agentCall.platform === AgentCallPlatform.MANUAL_UPLOAD;
  const isSmartMeetingRecord = agentCall.platform === AgentCallPlatform.SMARTMEETING_RECORD;
  const isSmartMikeRecord = agentCall.platform === AgentCallPlatform.SMARTMIKE_RECORD;
  const isLocalRecord = isManualRecord || isSmartMeetingRecord || isSmartMikeRecord;
  const isEdited = (hasScheduledStatus || hasNotScheduledStatus) && meeting.editorName;
  const isGoing = attendanceItem === null;
  const isTranscribed = meeting.processingResults?.processedTranscribing ?? false;
  const isProcessed = hasProcessingResults ? isTranscribed : isSubmitted;
  const declinedAttendanceMessage = attendanceItem?.message;
  const editorTitle = isEdited ? `Last edited by ${meeting.editorName}` : '';
  const detectedCallPlatform = AGENT_CALL_PLATFORMS[agentCall?.platform || 'COMMON'];
  const isKnownPlatform = !!detectedCallPlatform;
  const agentCallPlatform = isKnownPlatform ? detectedCallPlatform : AGENT_CALL_PLATFORMS['COMMON'];
  const showAttendToggle = isAuthorizedToRecord && (hasScheduledStatus || hasNotScheduledStatus);
  const primaryLanguage = meeting.languageSettings.primaryLanguage || Languages.ENGLISH;
  const additionalLanguage = meeting.languageSettings.additionalLanguage;
  const isDisabledMeetingTypePicker = !isAuthorizedToManage || isDisabledEditingMeetingType;
  const meetingTeam = meeting.team;
  /* #endregion */

  return (
    <div
      data-meeting-id={meeting.id}
      className={styles.root}
      style={{ backgroundColor: meetingStatusEntity.palette.backgroudColor.light }}
      onClick={handleClickMeetingCard}
    >
      <Grid container spacing={1} style={{ overflow: 'hidden' }}>
        <Grid container item sm spacing={1}>
          <Grid container alignItems="center" item spacing={1}>
            <Grid item>
              <div className={styles.icon} title={agentCallPlatform.title}>
                {agentCallPlatform.icon}
              </div>
              <Box mr={1}>
                <Typography className={styles.title} variant="h5">
                  {meeting.title ? unescape(meeting.title) : 'Unnamed meeting'}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center">
                <Tooltip placement="top" title={editorTitle}>
                  <Box display="inline-flex">
                    <MeetingStatusChip
                      meetingStatus={meetingStatus}
                      processingResult={meeting.processingResults}
                    />
                    <Box display="inline-flex" ml={1} visibility={isLoading ? 'visible' : 'hidden'}>
                      <CircularProgress size={22} color="primary" />
                    </Box>
                  </Box>
                </Tooltip>
                {isPromotingAIChat && isProcessed && isSmallScreen && (
                  <Button
                    disableElevation
                    size="small"
                    disabled={isLoading}
                    className="ai-promotion-btn"
                    onClick={handleClickAskSembly}
                  >
                    <img src={SemblianButtonIcon} alt="AI Meeting Chat" />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <MeetingStatusInfo
              startDate={meeting.startDate}
              finishDate={meeting.finishDate}
              isRecurrentMeeting={isRecurrentMeeting}
              ownerFullName={meeting.ownerFullName}
            />
          </Grid>

          {(!isAuthorizedToManage && !meetingType) ||
          (isAuthorizedToManage && isProcessing && !meetingType) ? null : (
            <Grid item>
              <Box mt={0.75}>
                <MeetingTypePicker
                  dense
                  variant="outlined"
                  value={meetingType}
                  isRecurrentMeeting={isRecurrentMeeting && isInContextWorkspace}
                  isDisabled={isDisabledMeetingTypePicker || isProcessing}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleChangeMeetingType}
                />
              </Box>
            </Grid>
          )}

          {isVisibleMeetingTeamSelector && (
            <>
              {(!isAuthorizedToManage && !meetingTeam) ||
              (!isInContextWorkspace && !meetingTeam) ? null : (
                <Grid item>
                  <Box mt={0.75}>
                    <MeetingTeamPicker
                      variant="outlined"
                      isRecurrentMeeting={isRecurrentMeeting && isInContextWorkspace}
                      isDisabled={!isAuthorizedToManage || !isInContextWorkspace || isProcessing}
                      selectedTeam={meetingTeam || null}
                      onClick={(e) => e.stopPropagation()}
                      onChange={handleChangingTeam}
                    />
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {!isSmallScreen && (
          <Grid item>
            <Box display="flex" alignItems="center">
              {isPromotingAIChat && isProcessed && (
                <Button
                  disableElevation
                  size="small"
                  disabled={isLoading}
                  className="ai-promotion-btn"
                  startIcon={<img src={SemblianButtonIcon} alt="AI Meeting Chat" />}
                  onClick={handleClickAskSembly}
                >
                  AI Meeting Chat
                </Button>
              )}
              <MeetingParticipantAvatars
                participants={meeting.participants}
                avatarGroupProps={{ classes: { avatar: styles.avatar } }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {!isCompleted && (
        <div className={styles.attendanceContainer}>
          {!isRecordingProhibited ? (
            <div className={styles.attendanceInner}>
              {isJoinable && !!agentCall.url && (
                <div className={`${styles.attendanceBlock} ${isSmallScreen ? 'wrap' : ''}`}>
                  {isAttending && !isOnLive && (
                    <>
                      <Typography
                        color="textSecondary"
                        style={{ display: 'inline-flex', alignItems: 'center', gap: 4 }}
                      >
                        Going?{' '}
                        <Link
                          color="textSecondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Help"
                          href="https://helpdesk.sembly.ai/hc/en-us/articles/10099438148753-Skip-the-Meeting-Let-Sembly-Attend-for-You-"
                          style={{ display: 'flex', fontSize: '1rem' }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <QuestionMarkIcon fontSize="inherit" />
                        </Link>
                      </Typography>
                      <Button
                        disableElevation
                        size="small"
                        color={isGoing ? 'primary' : 'default'}
                        variant={isGoing ? 'contained' : 'outlined'}
                        disabled={isLoading}
                        className={styles.btn}
                        onClick={triggerEvent(!isGoing ? 'CONFIRM_ATTENDANCE' : 'VOID')}
                      >
                        Yes
                      </Button>
                      <Button
                        disableElevation
                        size="small"
                        color={isGoing ? 'default' : 'primary'}
                        variant={isGoing ? 'outlined' : 'outlined'}
                        disabled={isLoading}
                        className={styles.btn}
                        onClick={triggerEvent(isGoing ? 'DECLINE_ATTENDANCE' : 'VOID')}
                      >
                        No
                      </Button>
                    </>
                  )}
                  {!isAttending || isOnLive || isGoing ? (
                    <>
                      {!isSmallScreen && (
                        <>
                          {isAttending && <div>•</div>}
                          <div className={styles.meetingUrl} onClick={handleClickMeetingUrl}>
                            {agentCall.url}
                          </div>
                          <Box flex={1} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div>•</div>
                      <Button
                        disableElevation
                        size="small"
                        variant="contained"
                        className={styles.btn}
                        disabled={isLoading}
                        onClick={handleShowHideDialog}
                        startIcon={
                          declinedAttendanceMessage ? (
                            <PlaylistEdit color="primary" />
                          ) : (
                            <AddCommentIcon color="primary" />
                          )
                        }
                      >
                        Message to chat
                      </Button>
                      <Box flex={1} />
                    </>
                  )}
                  {showAttendToggle && isSmallScreen && (
                    <>
                      <Box flex={1} />
                      <Button
                        disableElevation
                        size="small"
                        variant="contained"
                        className={styles.btn}
                        disabled={isLoading}
                        onClick={triggerEvent('OPEN_LANGUAGE_SETTINGS')}
                      >
                        <LanguageFlag key={primaryLanguage} laguageCode={primaryLanguage} />
                        {additionalLanguage && (
                          <LanguageFlag key={additionalLanguage} laguageCode={additionalLanguage} />
                        )}
                      </Button>
                      {disableToggleSerie ? (
                        <AccentSwitch
                          label="Attend"
                          size="small"
                          disabled={isLoading}
                          checked={isAttending}
                          onChange={triggerEvent('TOGGLE_ATTENDING')}
                        />
                      ) : (
                        <AccentSplitSwitch
                          label="Attend"
                          size="small"
                          disabled={isLoading}
                          checked={isAttending}
                          menuItems={attendMenuItems}
                          onChange={triggerEvent('TOGGLE_ATTENDING')}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
              {isFailed && !!agentCall.failureReason && (
                <Typography variant="body2" style={{ color: meetingStatusEntity.palette.color }}>
                  {getFailureReason(agentCall.failureReason, isManualUpload)}
                </Typography>
              )}
            </div>
          ) : (
            <Box flex={1} minWidth="0">
              <Typography variant="body1">
                Sembly would love to join, but you have used up your recording limit for this month.
                Upgrade now for unlimited use!
              </Typography>
            </Box>
          )}
          <Box flex={0} display="flex" alignSelf="flex-end" gridGap={6}>
            {showAttendToggle && !isSmallScreen && (
              <>
                <Button
                  disableElevation
                  size="small"
                  variant="contained"
                  className={styles.btn}
                  disabled={isLoading}
                  onClick={triggerEvent('OPEN_LANGUAGE_SETTINGS')}
                >
                  <LanguageFlag key={primaryLanguage} laguageCode={primaryLanguage} />
                  {additionalLanguage && (
                    <LanguageFlag key={additionalLanguage} laguageCode={additionalLanguage} />
                  )}
                </Button>
                {disableToggleSerie ? (
                  <AccentSwitch
                    label="Attend"
                    size="small"
                    disabled={isLoading}
                    checked={isAttending}
                    onChange={triggerEvent('TOGGLE_ATTENDING')}
                  />
                ) : (
                  <AccentSplitSwitch
                    label="Attend"
                    size="small"
                    disabled={isLoading}
                    checked={isAttending}
                    menuItems={attendMenuItems}
                    onChange={triggerEvent('TOGGLE_ATTENDING')}
                  />
                )}
              </>
            )}
            {isOnLive && (
              <>
                {!isLocalRecord && (
                  <Box ml={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={isLoading}
                      onClick={triggerEvent('VERIFY')}
                      startIcon={<VerifyIcon fontSize="small" color="primary" />}
                    >
                      Verify
                    </Button>
                  </Box>
                )}

                {isOnCall && isAuthorizedToManage && !isLocalRecord && (
                  <Box ml={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={isLoading}
                      onClick={triggerEvent('SET_PAUSE')}
                      startIcon={<PauseIcon fontSize="small" color="primary" />}
                    >
                      Pause
                    </Button>
                  </Box>
                )}

                {isOnPause && isAuthorizedToManage && !isLocalRecord && (
                  <Box ml={1}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={isLoading}
                      onClick={triggerEvent('SET_RESUME')}
                      startIcon={<PlayIcon fontSize="small" color="primary" />}
                    >
                      Resume
                    </Button>
                  </Box>
                )}

                {isAuthorizedToManage && (
                  <Box ml={1}>
                    <Button
                      disableElevation
                      onClick={triggerEvent('SET_STOP')}
                      className={styles.redButton}
                      disabled={isLoading}
                      variant="contained"
                      size="small"
                      color="inherit"
                      startIcon={<StopIcon fontSize="small" color="inherit" />}
                    >
                      Stop
                    </Button>
                  </Box>
                )}
              </>
            )}
            {isFailed && (
              <Button
                disableElevation
                className={styles.yellowButton}
                variant="contained"
                size="small"
                color="inherit"
                onClick={triggerEvent('REQUESTING_SUPPORT')}
              >
                Support
              </Button>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    padding: theme.spacing(2, 3),
    position: 'relative',
    color: theme.palette.text.primary,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    transition: 'box-shadow 0.3s ease-in-out',
    cursor: 'pointer',
    textDecoration: 'none',
    '& .ai-promotion-btn': {
      fontSize: theme.typography.body1.fontSize,
      padding: theme.spacing(1 / 2, 1.5),
      marginRight: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[200]}`,
      visibility: 'hidden',
      [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
        padding: theme.spacing(0.75, 1),
        minWidth: 'auto',
      },
    },
    '&:hover': {
      boxShadow: theme.shadows[2],
      '& .ai-promotion-btn': {
        visibility: 'visible',
      },
    },
  },
  title: {
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '0.875rem',
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
  },
  attendanceContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    },
  },
  attendanceInner: {
    flex: '1 0 0',
    width: '100%',
    minWidth: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  attendanceBlock: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'end',
    '&.wrap': {
      flexWrap: 'wrap',
    },
  },
  btn: {
    height: 24,
    padding: theme.spacing(1 / 2, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1 / 2, 1),
      minWidth: 'auto',
    },
  },
  icon: {
    position: 'absolute',
    marginTop: 2,
    marginLeft: 12,
    left: `-${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  meetingUrl: {
    marginRight: theme.spacing(2),
    display: 'block',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '70%',
    width: 'max-content',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  redButton: {
    backgroundColor: '#F04331',
    color: 'white',
    '&:hover': {
      backgroundColor: '#D43423',
    },
  },
  yellowButton: {
    backgroundColor: '#FFBF00',
    color: 'white',
    '&:hover': {
      backgroundColor: '#F7B900',
    },
  },
}));

export default MeetingCard;
