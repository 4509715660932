import { useState } from 'react';
// Material UI
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';

export const CrossTalkItemWrapper: React.FC = ({ children }) => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(true);

  const handleToogleState = () => {
    setIsOpen((state) => !state);
  };

  return (
    <List className={styles.crossItems}>
      <button type="button" className={styles.crossSpeakersButton} onClick={handleToogleState}>
        Cross-speakers
        {isOpen ? (
          <ArrowDropUpIcon className={styles.icon} />
        ) : (
          <ArrowDropDownIcon className={styles.icon} />
        )}
      </button>
      {isOpen && children}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  crossSpeakersButton: {
    listStyle: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(1.5),
    color: theme.palette.primary.main,
    fontSize: theme.typography.body1.fontSize,
  },
  icon: {
    verticalAlign: 'middle',
  },
  crossItems: {
    marginLeft: theme.spacing(6),
    padding: 0,
  },
}));

export default CrossTalkItemWrapper;
