import { useRouteMatch } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// App Shared
import { Routes } from '@shared/enums';
import { history } from '@shared/clients/History';
import { ReactComponent as LimitExceeded } from '@shared/assets/limit-exceeded.svg';

export interface LimitExceededMessageProps {
  onClose: () => void;
}

export const LimitExceededMessage: React.FC<LimitExceededMessageProps> = ({
  onClose,
  children,
}) => {
  const isPromoteUpgrade = !useRouteMatch(Routes.SettingsWorkspacePlans);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCloseModal = () => {
    if (isPromoteUpgrade) {
      history.replace(Routes.SettingsWorkspacePlans);
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <>
      <Box mb={3}>
        <LimitExceeded fontSize="small" />
      </Box>
      <Typography paragraph variant="h4">
        Limit Exceeded
      </Typography>
      <Typography paragraph variant="body1">
        {children}
      </Typography>
      {!isSmallScreen && (
        <Box mt={2}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Upgrade plan"
            onClick={handleCloseModal}
          >
            Upgrade plan
          </Button>
        </Box>
      )}
    </>
  );
};

export default LimitExceededMessage;
