import throttle from 'lodash/throttle';
import { useEffect, useState, useRef } from 'react';
// Lib Shared
import { isMobile } from '../utils';

/* #region  Types */
export type UseVoiceDetectionResult = Readonly<[boolean, { isUnsupported: boolean }]>;

export type UseVoiceDetectionOptions = Readonly<{
  trottle?: number;
  minDecibels?: number;
}>;
/* #endregion */

export function useVoiceDetection(
  mediaStream: MediaStream | null | undefined,
  options?: UseVoiceDetectionOptions,
): UseVoiceDetectionResult {
  const MIN_DECEBELS = options?.minDecibels ?? (-60 as const);
  const THROTTLE = options?.trottle ?? (500 as const);

  /* #region  Hooks */
  const [isVoiceDetecting, setIsVoiceDetecting] = useState(false);
  const [isUnsupported, setIsUnsupported] = useState(false);

  const isMountedRef = useRef(false);
  const intervalRef = useRef<number>();
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mediaStream) return;

    // Temporary disable voice detection on mobile due to performance issues
    if (isMobile()) {
      setIsUnsupported(true);
      return;
    }

    let analyser: AnalyserNode;
    let streamNode: MediaStreamAudioSourceNode;
    let dataArray: Uint8Array;

    try {
      const audioCtx = new AudioContext();

      analyser = audioCtx.createAnalyser();
      analyser.minDecibels = MIN_DECEBELS;

      const streamNode = audioCtx.createMediaStreamSource(mediaStream);

      dataArray = new Uint8Array(analyser.frequencyBinCount);
      streamNode.connect(analyser);
    } catch {
      setIsUnsupported(true);
      return;
    }

    const setResult = throttle((hasSignal: boolean) => {
      if (!isMountedRef.current) return;
      setIsVoiceDetecting(hasSignal);
    }, THROTTLE);

    const analyze = () => {
      analyser.getByteFrequencyData(dataArray);
      const hasSignal = dataArray.some((frequency) => frequency);
      setResult(hasSignal);
      intervalRef.current = requestAnimationFrame(analyze);
    };

    intervalRef.current = requestAnimationFrame(analyze);

    return () => {
      if (intervalRef.current) cancelAnimationFrame(intervalRef.current);
      if (analyser) analyser.disconnect();
      if (streamNode) streamNode.disconnect();
    };
  }, [mediaStream, THROTTLE, MIN_DECEBELS]);
  /* #endregion */

  return [isVoiceDetecting, { isUnsupported }];
}

export default useVoiceDetection;
