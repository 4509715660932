/**
 * Check if the device is mobile
 * @returns {boolean} true if the device is mobile
 */
export function isIOS(): boolean {
  return ['iPhone', 'iPad', 'iPod', 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator'].some(
    (platform) => navigator.userAgent.includes(platform),
  );
}

export default isIOS;
