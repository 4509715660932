import { useEffect, useState, useRef } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { DateTime } from 'luxon';
import { NetworkStatus, Reference } from '@apollo/client/core';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import ActionItemMenusContainer from './ActionItemMenusContainer';
import MyAssignmentsGroup, { MyAssignmentsGroupAttributes } from '../components/MyAssignmentsGroup';
import SearchInput from '../components/SearchInput';
import TasksItemsSkeleton from '../components/TasksItemsSkeleton';
import ToastWithUndo from '../components/ToastWithUndo';
import commitmentsImage from '../assets/icon-commitments-64.svg';
import { ASSIGNMENTS_FILTER_TYPES } from '../constants';
import { useIntersectionObserver } from '../hooks';
// GraphQL Queries and Types
import editMutation from '../graphql/mutations/EditAssignment.graphql';
import genericAssignmentFragment from '../graphql/fragments/GenericAssignment.graphql';
import groupedAssignmentsQuery from '../graphql/queries/MyGroupedAssignment.graphql';
import pinnedCommitmentsQuery from '../graphql/queries/MyPinnedAssignments.graphql';
import {
  ActionItemGroupType,
  ActionItemMenuType,
  EditAssignment,
  EditAssignmentVariables,
  GenericAssignment,
  GenericAssignmentGroupedByWeek,
  GenericDefaultUser,
  GraphError,
  MyGroupedAssignment,
  MyGroupedAssignmentVariables,
  MyPinnedAssignments,
  MyPinnedAssignmentsVariables,
} from '../types';

/* #region  Types */
interface ContextMenu {
  anchorEl: HTMLElement;
  item: GenericAssignment;
  type: ActionItemMenuType;
}

type ClickOnCopyContentHandler = (data: {
  meetingId: string;
  keyItemId: string;
  item: GenericAssignment;
}) => void;

type ClickOnCopyLinkHandler = (data: { meetingId: string; keyItemId: string }) => void;

interface Participant {
  id: string;
  user: GenericDefaultUser | string | null;
  userType: 'assignee' | 'performer';
}

export type MyAssignmentsContainerActionEventType =
  | 'Make Inactive'
  | 'Make Active'
  | 'Make Completed'
  | 'Make Incompleted'
  | null;

export interface MyAssignmentsContainerProps {
  Header?: React.ReactNode;
  enableBetaFeatures: boolean;
  isForceHiddenIntegrations?: boolean;
  restrict3rdPartyIntegrations?: boolean;
  onClickOnCopyKeyItemContent: ClickOnCopyContentHandler;
  onClickOnCopyKeyItemLink: ClickOnCopyLinkHandler;
  onClickOnDiscoverIntegrations?: () => void;
  onClickOnMeetingLink: (meetingId: string) => void;
  onMarkAllAsRead?: () => void;
  onPostingSuccess?: (integrationType: string) => void;
  onResponseError?: (error: GraphError) => void;
  onUpdateSuccess?: (actionEventType: MyAssignmentsContainerActionEventType) => void;
}
/* #endregion */

/**
 * @component MyAssignmentsContainer
 * @description A container that fetches and displays the user's commitments.
 */
export const MyAssignmentsContainer: React.VFC<MyAssignmentsContainerProps> = ({
  Header,
  enableBetaFeatures,
  isForceHiddenIntegrations = false,
  restrict3rdPartyIntegrations = false,
  onClickOnCopyKeyItemContent,
  onClickOnCopyKeyItemLink,
  onClickOnDiscoverIntegrations = () => null,
  onClickOnMeetingLink,
  onMarkAllAsRead = () => null,
  onPostingSuccess = () => null,
  onResponseError = () => null,
  onUpdateSuccess = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const client = useApolloClient();

  const isCooldownRef = useRef(false);

  const [currentContextMenu, setCurrentContextMenu] = useState<ContextMenu | null>(null);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [nextPageButtonRef, setNextPageButtonRef] = useState<HTMLButtonElement | null>(null);
  const [queueForRemoval, setQueueForRemoval] = useState<string[]>([]);
  const [filterData, setFilterData] = useState<{ value: string; touched: boolean }>({
    value: ASSIGNMENTS_FILTER_TYPES[0].value, // Incomplete
    touched: false,
  });

  const nextPageButtonObserver = useIntersectionObserver(nextPageButtonRef);

  const {
    data: recentCommitmentsData,
    loading: isLoadingRecentCommitments,
    networkStatus: recentCommitmentsNetworkStatus,
    refetch: refetchRecentData,
    fetchMore,
  } = useQuery<MyGroupedAssignment, MyGroupedAssignmentVariables>(groupedAssignmentsQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { page: 1, perPage: 1, isCompleted: false },
  });

  const {
    data: pinnedCommitmentsData,
    loading: isLoadingPinnedCommitments,
    networkStatus: pinnedCommitmentsNetworkStatus,
    refetch: refetchPinnedData,
  } = useQuery<MyPinnedAssignments, MyPinnedAssignmentsVariables>(pinnedCommitmentsQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { isCompleted: false },
  });

  const [editAssignment] = useMutation<EditAssignment, EditAssignmentVariables>(editMutation);
  /* #endregion */

  /* #region  Utils */
  const refetchCommitments = (search: string | null, isCompleted: boolean | null = null) => {
    refetchRecentData({ page: 1, perPage: 4, search, isCompleted });
    refetchPinnedData({ search, isCompleted });
  };

  const getIsCompleteAssociatedValue = (value: string) => {
    switch (value) {
      case 'incomplete':
        return false;
      case 'completed':
        return true;
      case 'allCommitments':
        return null;
      default:
        throw new Error(`Unknown value type: ${value}`);
    }
  };

  const groupCommitments = (commitmentGroups: GenericAssignmentGroupedByWeek[]) => {
    let title: ActionItemGroupType = 'This Week';
    const commitmentsGroups: Record<ActionItemGroupType, GenericAssignment[]> = {
      'This Week': [],
      'Previous Week': [],
      'Prior Weeks': [],
    };

    commitmentGroups.forEach(({ startDate, endDate, commitments }) => {
      if (startDate && endDate) {
        const currentLocalDate = DateTime.local();
        const commitmentDate = DateTime.fromISO(startDate);
        const startOfPreviousWeek = currentLocalDate.startOf('week').minus({ weeks: 1 });
        const isCurrentWeek = commitmentDate.hasSame(currentLocalDate, 'week');
        const isRecentWeek = commitmentDate.hasSame(startOfPreviousWeek, 'week');
        const recentWeekTitle: ActionItemGroupType = isRecentWeek ? 'Previous Week' : 'Prior Weeks';
        title = isCurrentWeek ? 'This Week' : recentWeekTitle;
      }
      commitmentsGroups[title].push(...commitments);
    });

    return commitmentsGroups;
  };

  const getCachedAssignment = (id: string): GenericAssignment | null => {
    return client.readFragment({
      id: client.cache.identify({ id, __typename: 'AssignmentType' }),
      fragment: genericAssignmentFragment,
      fragmentName: 'GenericAssignment',
    });
  };

  const updateAssignment = async (
    itemId: string,
    variables: Omit<EditAssignmentVariables, 'id'>,
  ) => {
    const cachedData = getCachedAssignment(itemId);
    if (!cachedData) return;

    const acitionVariables: EditAssignmentVariables = {
      assigneeId: cachedData.assignedBy?.id,
      assigneeRawName: cachedData.rawAssignedBy,
      customHeaderText: cachedData.customHeaderText,
      customText: cachedData.customText,
      dueDate: cachedData.dueDate,
      id: +itemId,
      isCompleted: cachedData.isCompleted,
      inDiscussionWith: cachedData.inDiscussionWith,
      isActive: cachedData.isActive,
      isPinned: cachedData.isPinned,
      performerId: cachedData.performer?.id,
      performerRawName: cachedData.rawPerformerName,
      rawTiming: cachedData.rawTiming,
      ...variables,
    };

    const optimisticAssignment: GenericAssignment = {
      ...cachedData,
      ...variables,
      isActive: variables.isActive ?? cachedData.isActive,
      isCompleted: variables.isCompleted ?? cachedData.isCompleted,
      isPinned: variables.isPinned ?? cachedData.isPinned,
    };

    const result = (
      await editAssignment({
        variables: acitionVariables,
        optimisticResponse: {
          editAssignment: {
            __typename: 'EditAssignmentMutationPayload',
            assignment: optimisticAssignment,
            errors: null,
            success: true,
          },
        },
      })
    ).data?.editAssignment;

    if (result?.success) {
      let actionType: MyAssignmentsContainerActionEventType = null;
      if (variables.isActive === true) actionType = 'Make Active';
      if (variables.isActive === false) actionType = 'Make Inactive';
      if (variables.isCompleted === true) actionType = 'Make Completed';
      if (variables.isCompleted === false) actionType = 'Make Incompleted';
      onUpdateSuccess(actionType);
    } else {
      onResponseError(result?.errors);
    }

    return Promise.resolve();
  };

  const handlePerformer = async ({ id, user }: Participant) => {
    function unloadCallback(event: BeforeUnloadEvent) {
      event.preventDefault();
      return (event.returnValue = '');
    }

    let shouldUpdate = typeof user !== 'object' || pinnedCommitmentsData?.me?.id !== user?.id;
    if (!shouldUpdate) return; // nothing updated, performer is the same

    setQueueForRemoval((prevState) => [...prevState, id]);

    toast.dark(
      <ToastWithUndo
        message="Item succesfully re-assigned"
        onUndo={() => {
          shouldUpdate = false;
          setQueueForRemoval((prevState) => prevState.filter((removedId) => removedId !== id));
          window.removeEventListener('beforeunload', unloadCallback, { capture: true });
        }}
      />,
      {
        onOpen: () => {
          window.addEventListener('beforeunload', unloadCallback, { capture: true });
        },
        onClose: async () => {
          const cachedData = getCachedAssignment(id);
          if (!shouldUpdate || !cachedData) return;

          const isUnknownUser = !!user && typeof user === 'string';
          const isKnownUser = !!user && typeof user !== 'string';
          const isRemoveUser = user === null;

          let optimisticData: GenericAssignment = Object.assign({}, cachedData);
          let variables: EditAssignmentVariables = {
            assigneeId: cachedData.assignedBy?.id,
            assigneeRawName: cachedData.rawAssignedBy,
            customHeaderText: cachedData.customHeaderText,
            customText: cachedData.customText,
            dueDate: cachedData.dueDate,
            id: +id,
            isCompleted: cachedData.isCompleted,
            inDiscussionWith: cachedData.inDiscussionWith,
            isActive: cachedData.isActive,
            isPinned: cachedData.isPinned,
            performerId: cachedData.performer?.id,
            performerRawName: cachedData.rawPerformerName,
            rawTiming: cachedData.rawTiming,
          };

          if (isUnknownUser) {
            variables.performerId = null;
            variables.performerRawName = user;
            optimisticData.performer = null;
            optimisticData.rawPerformerName = user;
          }
          if (isKnownUser) {
            variables.performerId = user.id;
            variables.performerRawName = null;
            optimisticData.performer = { ...user, __typename: 'DefaultUserType' };
            optimisticData.rawPerformerName = null;
          }
          if (isRemoveUser) {
            variables.performerId = null;
            variables.performerRawName = null;
            optimisticData.performer = null;
            optimisticData.rawPerformerName = null;
          }

          const result = await editAssignment({
            variables,
            optimisticResponse: {
              editAssignment: {
                __typename: 'EditAssignmentMutationPayload',
                assignment: optimisticData,
                success: true,
                errors: [],
              },
            },
          });

          window.removeEventListener('beforeunload', unloadCallback);

          if (!result.data?.editAssignment?.success) {
            onResponseError(result.data?.editAssignment?.errors);
          }
        },
      },
    );
  };
  /* #endregion */

  /* #region  Handlers */
  const handleChangeActivity = async (assignment: GenericAssignment) => {
    const keyItemId = assignment.id;
    const isActive = !assignment.isActive;
    updateAssignment(keyItemId, { isActive });
  };

  const handleChangeIsCompleted = async (assignment: GenericAssignment) => {
    const keyItemId = assignment.id;
    const isCompleted = !assignment.isCompleted;
    updateAssignment(keyItemId, { isCompleted });
  };

  const handleChangeIsPinned = async (assignment: GenericAssignment) => {
    const { data: response } = await editAssignment({
      variables: {
        assigneeId: assignment.assignedBy?.id,
        assigneeRawName: assignment.rawAssignedBy,
        customHeaderText: assignment.customHeaderText,
        customText: assignment.customText,
        dueDate: assignment.dueDate,
        id: +assignment.id,
        isCompleted: assignment.isCompleted,
        inDiscussionWith: assignment.inDiscussionWith,
        isActive: assignment.isActive,
        isPinned: !assignment.isPinned,
        performerId: assignment.performer?.id,
        performerRawName: assignment.rawPerformerName,
        rawTiming: assignment.rawTiming,
      },
      optimisticResponse: {
        editAssignment: {
          __typename: 'EditAssignmentMutationPayload',
          success: true,
          errors: [],
          assignment: {
            ...assignment,
            isPinned: !assignment.isPinned,
          },
        },
      },
      update: (cache, result) => {
        const updatedCommitment = result.data?.editAssignment?.assignment;
        if (!updatedCommitment) return;

        if (!updatedCommitment.isPinned) {
          // remove the item from the list of committed commitments
          cache.modify({
            fields: {
              myCommitments(existingRefs: readonly Reference[] = [], { readField }) {
                return existingRefs.filter((ref) => updatedCommitment.id !== readField('id', ref));
              },
            },
          });
        } else {
          // add the item to the list of committed commitments
          cache.modify({
            fields: {
              myCommitments(existingItemsRefs: readonly Reference[] = [], { readField }) {
                const isExisted = existingItemsRefs.some((ref) => {
                  return readField('id', ref) === updatedCommitment.id;
                });

                return isExisted
                  ? [...existingItemsRefs]
                  : [
                      ...existingItemsRefs,
                      { __ref: `${updatedCommitment.__typename}:${updatedCommitment.id}` },
                    ];
              },
            },
          });
        }
      },
    });
    if (!response?.editAssignment?.success) {
      onResponseError(`You do not have permission to manage this meeting`);
    }
  };

  const handleClickOnCopyContent: ClickOnCopyContentHandler = (data) => {
    const { meetingId, keyItemId, item } = data;
    onClickOnCopyKeyItemContent({ meetingId, keyItemId, item });
  };

  const handleClickOnCopyKeyItemLink: ClickOnCopyLinkHandler = (data) => {
    const { meetingId, keyItemId } = data;
    onClickOnCopyKeyItemLink({ meetingId, keyItemId });
  };

  const handleNavigateToMeeting = (meetingId: string) => {
    onClickOnMeetingLink(meetingId);
  };

  const handleUpdateSearchTerm = (value: string) => {
    const isCompleted = getIsCompleteAssociatedValue(filterData.value);
    setSearchTerm(value);
    refetchCommitments(value, isCompleted);
  };

  const handleClearSearchTerm = () => {
    setSearchTerm(null);
    refetchCommitments(null);
  };

  const handleChangeActionParticipant = async ({ id, user, userType }: Participant) => {
    const isAssigneeEvent = userType === 'assignee';
    const isPerformerEvent = userType === 'performer';
    const isUnknownUser = !!user && typeof user === 'string';
    const isKnownUser = !!user && typeof user !== 'string';
    const isRemoveUser = user === null;

    if (isPerformerEvent) {
      handlePerformer({ id, user, userType });
      return;
    }

    const cachedData = getCachedAssignment(id);

    if (!cachedData) return;

    let optimisticData: GenericAssignment = Object.assign({}, cachedData);
    let variables: EditAssignmentVariables = {
      assigneeId: cachedData.assignedBy?.id,
      assigneeRawName: cachedData.rawAssignedBy,
      customHeaderText: cachedData.customHeaderText,
      customText: cachedData.customText,
      dueDate: cachedData.dueDate,
      id: +id,
      isCompleted: cachedData.isCompleted,
      inDiscussionWith: cachedData.inDiscussionWith,
      isActive: cachedData.isActive,
      isPinned: cachedData.isPinned,
      performerId: cachedData.performer?.id,
      performerRawName: cachedData.rawPerformerName,
      rawTiming: cachedData.rawTiming,
    };

    if (isPerformerEvent) {
      if (isUnknownUser) {
        variables.performerId = null;
        variables.performerRawName = user;
        optimisticData.performer = null;
        optimisticData.rawPerformerName = user;
      }
      if (isKnownUser) {
        variables.performerId = user.id;
        variables.performerRawName = null;
        optimisticData.performer = { ...user, __typename: 'DefaultUserType' };
        optimisticData.rawPerformerName = null;
      }
      if (isRemoveUser) {
        variables.performerId = null;
        variables.performerRawName = null;
        optimisticData.performer = null;
        optimisticData.rawPerformerName = null;
      }
    }

    if (isAssigneeEvent) {
      if (isUnknownUser) {
        variables.assigneeId = null;
        variables.assigneeRawName = user;
        optimisticData.assignedBy = null;
        optimisticData.rawAssignedBy = user;
      }
      if (isKnownUser) {
        variables.assigneeId = user.id;
        variables.assigneeRawName = null;
        optimisticData.assignedBy = { ...user, __typename: 'DefaultUserType' };
        optimisticData.rawAssignedBy = null;
      }
      if (isRemoveUser) {
        variables.assigneeId = null;
        variables.assigneeRawName = null;
        optimisticData.assignedBy = null;
        optimisticData.rawAssignedBy = null;
      }
    }

    const { data } = await editAssignment({
      variables,
      optimisticResponse: {
        editAssignment: {
          __typename: 'EditAssignmentMutationPayload',
          success: true,
          errors: [],
          assignment: optimisticData,
        },
      },
    });

    if (!data?.editAssignment?.success) {
      onResponseError(data?.editAssignment?.errors);
    }
  };

  const handleRequestNextPage = async () => {
    const currentPage = recentCommitmentsData?.commitmentsGroupedByWeeks?.page;
    if (!isCooldownRef.current && !isFetchingNextPages && currentPage) {
      isCooldownRef.current = true;
      await fetchMore({ variables: { page: currentPage + 1 } });
      setTimeout(() => (isCooldownRef.current = false), 100);
    }
  };

  const handleChangeDueDate = async (id: string, dueDate: Date | string | null) => {
    const cachedData: GenericAssignment | null = client.readFragment({
      id: client.cache.identify({ id, __typename: 'AssignmentType' }),
      fragment: genericAssignmentFragment,
      fragmentName: 'GenericAssignment',
    });

    if (!cachedData) return;

    const isCustomDate = dueDate !== null && typeof dueDate === 'string';
    const isDataObject = dueDate !== null && typeof dueDate === 'object';

    const { data: response } = await editAssignment({
      variables: {
        assigneeId: cachedData.assignedBy?.id,
        assigneeRawName: cachedData.rawAssignedBy,
        customHeaderText: cachedData.customHeaderText,
        customText: cachedData.customText || '',
        dueDate: isDataObject ? format(dueDate, 'yyyy-MM-dd') : null,
        id: +id,
        isCompleted: cachedData.isCompleted,
        inDiscussionWith: cachedData.inDiscussionWith,
        isActive: cachedData.isActive,
        isPinned: cachedData.isPinned,
        performerId: cachedData.performer?.id,
        performerRawName: cachedData.rawPerformerName,
        rawTiming: isCustomDate ? dueDate : null,
      },
      optimisticResponse: {
        editAssignment: {
          __typename: 'EditAssignmentMutationPayload',
          success: true,
          errors: [],
          assignment: {
            ...cachedData,
            rawTiming: isCustomDate ? dueDate : null,
            dueDate: isDataObject ? format(dueDate, 'yyyy-MM-dd') : null,
          },
        },
      },
    });
    if (!response?.editAssignment?.success) {
      onResponseError(`You do not have permission to manage this meeting`);
    }
  };

  const handleChangeFilter = async (e: React.ChangeEvent<{ value: unknown }>) => {
    setFilterData({ value: e.target.value as string, touched: true });
    const isCompleted = getIsCompleteAssociatedValue(e.target.value as string);

    refetchCommitments(searchTerm || '', isCompleted);
  };

  const handleChangeInDiscussionWith = (keyItem: GenericAssignment, inDiscussionWith: string) => {
    updateAssignment(keyItem.id, { inDiscussionWith });
  };

  const handleChangeText = (keyItem: GenericAssignment, customText: string) => {
    updateAssignment(keyItem.id, { customText });
  };

  const handleChangeTitle = (keyItem: GenericAssignment, customHeaderText: string) => {
    updateAssignment(keyItem.id, { customHeaderText });
  };

  const handleToggleItemAttribute = (
    attr: MyAssignmentsGroupAttributes,
    item: GenericAssignment,
  ) => {
    switch (attr) {
      case 'isCompleted':
        handleChangeIsCompleted(item);
        break;
    }
  };

  const handleToggleContextMenu = (
    data: { type: ActionItemMenuType; item: GenericAssignment; anchorEl: HTMLElement } | null,
  ) => {
    setCurrentContextMenu(data);
  };

  const handleCloseContextMenu = () => {
    setCurrentContextMenu(null);
  };
  /* #endregion */

  /* #region  Effects */
  // Mark all commitments as read when data is fetched
  useEffect(() => {
    if (!!recentCommitmentsData?.commitmentsGroupedByWeeks?.objects?.length) {
      onMarkAllAsRead();
    }
  }, [onMarkAllAsRead, recentCommitmentsData?.commitmentsGroupedByWeeks?.objects?.length]);

  // Fetch next page when user scrolls to the bottom of the page
  useEffect(() => {
    if (nextPageButtonObserver.isIntersecting && hasNextPage && !isFetchingNextPages) {
      handleRequestNextPage();
    }
  });
  /* #endregion */

  /* #region  Render Helpers */
  const currentUserId = pinnedCommitmentsData?.me?.id;
  const myPinnedCommitments = pinnedCommitmentsData?.myCommitments ?? [];
  const commitmentGroups = recentCommitmentsData?.commitmentsGroupedByWeeks?.objects ?? [];
  const commitmentGroupedByWeeks = groupCommitments(commitmentGroups);
  const hasNextPage = recentCommitmentsData?.commitmentsGroupedByWeeks?.hasNext || false;
  const isFetchingNextPages = recentCommitmentsNetworkStatus === NetworkStatus.fetchMore;
  const isSearchingPinnedItems = pinnedCommitmentsNetworkStatus === NetworkStatus.setVariables;
  const isSearchingRecentItems = recentCommitmentsNetworkStatus === NetworkStatus.setVariables;
  const isSearching = isSearchingPinnedItems || isSearchingRecentItems;
  const isFetchedRecentItems = recentCommitmentsData !== undefined;
  const isFetching = isLoadingRecentCommitments && isLoadingPinnedCommitments;

  let pinnedItems = myPinnedCommitments.filter(
    ({ id, isActive, performer }) =>
      // Filter out items that are in the queue for removal and
      // items that are not assigned to the current user
      isActive && !queueForRemoval.includes(id) && performer?.id === currentUserId,
  );

  // additional filtering for pinned items
  if (filterData.value === 'incomplete') {
    pinnedItems = pinnedItems.filter((item) => !item.isCompleted);
  } else if (filterData.value === 'completed') {
    pinnedItems = pinnedItems.filter((item) => item.isCompleted);
  }

  let commitments = Object.entries(commitmentGroupedByWeeks).map(([title, commitments]) => {
    let items = commitments.filter(
      (item) =>
        // Filter out items that are in the queue for removal and
        // items that are not assigned to the current user
        !queueForRemoval.includes(item.id) && item.performer?.id === currentUserId,
    );
    if (filterData.value === 'incomplete') {
      items = items.filter((item) => !item.isCompleted);
    }
    if (filterData.value === 'completed') {
      items = items.filter((item) => item.isCompleted);
    }

    return { items, title };
  });

  const hasRecentItems = recentCommitmentsData && !!commitments.some((c) => !!c.items.length);
  const hasPinnedItems = pinnedCommitmentsData && !!pinnedItems.length;
  const hasFetchedRecentItems = isFetchedRecentItems && !!commitmentGroups.length;
  /* #endregion */

  return (
    <div className={styles.root}>
      <Box pt={5} pb={3}>
        {!!Header && <Box mb={4}>{Header}</Box>}
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h4">
              My Tasks{' '}
              <Link
                color="textSecondary"
                target="_blank"
                rel="noopener noreferrer"
                title="Help"
                href="https://helpdesk.sembly.ai/hc/en-us/articles/7495413385873-Tasks"
                style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
              >
                <QuestionMarkIcon fontSize="inherit" />
              </Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Box display="flex" justifyContent="flex-end" gridGap={6}>
              <SearchInput
                value={searchTerm || ''}
                placeholder="Search items"
                className={styles.searchInput}
                isFetching={isSearchingPinnedItems || isSearchingRecentItems}
                onClear={handleClearSearchTerm}
                onChange={handleUpdateSearchTerm}
              />
              <Select
                variant="outlined"
                value={filterData.value}
                disabled={isSearching}
                classes={{ root: styles.selectRoot }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                }}
                onChange={handleChangeFilter}
              >
                {ASSIGNMENTS_FILTER_TYPES.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isFetching ? (
        <TasksItemsSkeleton count={4} />
      ) : (
        <>
          {!hasPinnedItems &&
          !hasRecentItems &&
          !isLoadingPinnedCommitments &&
          !isLoadingRecentCommitments ? (
            <>
              {!!searchTerm ? (
                <Box mt={4}>
                  <Typography variant="body1">Sorry, nothing matches your search.</Typography>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  justifyContent="center"
                  height="calc(100vh - 100px)"
                >
                  <img src={commitmentsImage} alt="My Tasks" height="64" width="64" />
                  <Box my={2}>
                    <Typography variant="h4">No Tasks</Typography>
                  </Box>
                  <Typography variant="body1">Your tasks will appear here.</Typography>
                </Box>
              )}
            </>
          ) : (
            <div>
              {!isSearching && (
                <>
                  <ActionItemMenusContainer
                    allowPinning
                    isPerformer
                    restrict3rdPartyIntegrations={restrict3rdPartyIntegrations}
                    selectedMenu={currentContextMenu}
                    onChangeActivity={handleChangeActivity}
                    onChangeDueDate={handleChangeDueDate}
                    onChangeIsPinned={handleChangeIsPinned}
                    onClickOnCopyItemContent={handleClickOnCopyContent}
                    onClickOnCopyItemLink={handleClickOnCopyKeyItemLink}
                    onClickOnDiscoverIntegrations={onClickOnDiscoverIntegrations}
                    onPostingSuccess={onPostingSuccess}
                    onResponseError={onResponseError}
                    onCloseContextMenu={handleCloseContextMenu}
                    onSelectParticipant={handleChangeActionParticipant}
                  >
                    {isLoadingPinnedCommitments ? (
                      <Box pt={8} textAlign="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        {/* Render the pinned items */}
                        <MyAssignmentsGroup
                          isPinned
                          title="Pinned"
                          enableBetaFeatures
                          isForceHiddenIntegrations={isForceHiddenIntegrations}
                          items={pinnedItems}
                          onChangeText={handleChangeText}
                          onChangeTitle={handleChangeTitle}
                          onChangeDiscussedWith={handleChangeInDiscussionWith}
                          onClickOnMeetingTitle={handleNavigateToMeeting}
                          onToggleAttribute={handleToggleItemAttribute}
                          onToggleMenu={handleToggleContextMenu}
                        />
                        {/* Render the recent items */}
                        {commitments.map(({ items, title }) => (
                          <MyAssignmentsGroup
                            key={title}
                            enableBetaFeatures={enableBetaFeatures}
                            isForceHiddenIntegrations={isForceHiddenIntegrations}
                            items={items}
                            title={title}
                            onChangeDiscussedWith={handleChangeInDiscussionWith}
                            onChangeText={handleChangeText}
                            onChangeTitle={handleChangeTitle}
                            onClickOnMeetingTitle={handleNavigateToMeeting}
                            onToggleAttribute={handleToggleItemAttribute}
                            onToggleMenu={handleToggleContextMenu}
                          />
                        ))}
                        {isLoadingRecentCommitments && (
                          <Box pt={4} pb={2} textAlign="center">
                            <CircularProgress size={24} />
                          </Box>
                        )}
                      </>
                    )}
                  </ActionItemMenusContainer>

                  {!isFetchingNextPages && hasFetchedRecentItems && hasNextPage && (
                    <Box width="100%" textAlign="center" my={2}>
                      <Button
                        variant="outlined"
                        ref={setNextPageButtonRef}
                        disabled={isFetchingNextPages}
                        onClick={handleRequestNextPage}
                      >
                        Load more
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
    zIndex: 0,
    ...theme.mixins.layout.default.padding,
  },
  searchInput: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    height: 36,
    maxWidth: 360,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  selectRoot: {
    height: 36,
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));

export default MyAssignmentsContainer;
