import { useQuery, useMutation, useLazyQuery, NetworkStatus } from '@apollo/client';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
import BackIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/CloseSharp';
import PageIcon from '@material-ui/icons/Description';
import StorageIcon from '@material-ui/icons/StorageSharp';
// Lib Graphql Types
import {
  CreateNotionDatabase,
  CreateNotionDatabaseVariables,
  GenericNotionDatabase,
  GenericNotionPage,
  GraphError,
  NotionDatabases,
  NotionDatabasesVariables,
  NotionIntegrationsTypes,
  NotionPages,
  NotionPagesVariables,
} from '../../types';
// Lib Shared Queries & Mutations
import { Twemoji } from '../../components';
import createNotionDatabaseMutation from '../../graphql/mutations/CreateNotionDatabase.graphql';
import notionDatabasesQuery from '../../graphql/queries/NotionDatabases.graphql';
import notionPagesQuery from '../../graphql/queries/NotionPages.graphql';
// Lib Assets
import notionIntegrationImage from '../../assets/icon-notion-integration-24.svg';

interface NotionDestinationPickerProps {
  integrationType: NotionIntegrationsTypes;
  onClose: () => void;
  onSend: (destination: GenericNotionDatabase | null) => void;
  onError: (error: GraphError) => void;
}

export const NotionDestinationPicker: React.VFC<NotionDestinationPickerProps> = ({
  integrationType,
  onClose,
  onSend,
  onError,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [selectedNotionPage, setSelectedNotionPage] = useState<GenericNotionPage | null>(null);
  const [notionDbFilterValue, setNotionDbFilterValue] = useState('');
  const [notionPageFilterValue, setNotionPageFilterValue] = useState('');

  const {
    data: notionPagesData,
    fetchMore: fetchMoreNotionPages,
    refetch: refetchNotionPages,
    networkStatus: notionPagesNetworkStatus,
  } = useQuery<NotionPages, NotionPagesVariables>(notionPagesQuery, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    variables: {
      pageSize: 15,
      integrationType,
    },
  });

  const [
    getNotionDatabases,
    {
      data: notionDatabasesData,
      networkStatus: notionDatabasesNetworkStatus,
      refetch: refetchNotionDatabases,
    },
  ] = useLazyQuery<NotionDatabases, NotionDatabasesVariables>(notionDatabasesQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const [createNotionDatabase, { loading: isCreatingNotionDb }] = useMutation<
    CreateNotionDatabase,
    CreateNotionDatabaseVariables
  >(createNotionDatabaseMutation);
  /* #endregion */

  const refetchPagesDebounced = useDebouncedCallback(
    (search: string | null) => refetchNotionPages({ search, pageSize: 15 }),
    500,
  );

  /* #region  Handlers */
  const handleCloseDatabaseMenu = () => {
    onClose();
  };

  const handleCreateNotionDatabaseAndSend = async () => {
    if (!selectedNotionPage) {
      throw new Error('No Notion page selected');
    }

    const result = await createNotionDatabase({
      variables: {
        pageId: selectedNotionPage.id,
        integrationType,
      },
    });

    if (result?.data?.createNotionDatabase?.success) {
      onSend(result.data.createNotionDatabase?.database);
    } else {
      onError(result.data?.createNotionDatabase?.errors);
    }

    onClose();
  };

  const handleChangeNotionDatabase = (targetDatabase: GenericNotionDatabase) => () => {
    onSend(targetDatabase);
    onClose();
  };

  const handleFetchMoreNotionPages = async () => {
    fetchMoreNotionPages({
      variables: {
        pageSize: 15,
        startCursor: notionPagesData?.notionPages?.nextCursor,
        search: notionPageFilterValue || null,
      },
    });
  };

  const handleChangeNotionPage = (targetPage: GenericNotionPage) => async () => {
    setSelectedNotionPage(targetPage);

    let result;

    if (notionDatabasesData?.notionDatabases === undefined) {
      result = await getNotionDatabases({
        variables: {
          pageId: targetPage.id,
          integrationType,
        },
      });
    } else {
      result = await refetchNotionDatabases({
        pageId: targetPage.id,
        integrationType,
      });
    }

    const isNotionAssignmentsIntegration =
      integrationType === NotionIntegrationsTypes.NOTION_ASSIGNMENTS_INTEGRATION;
    const lookupName = isNotionAssignmentsIntegration ? 'Sembly Tasks' : 'Sembly Meetings';
    const databaseCount = result?.data?.notionDatabases?.length;
    const defaultDatabase = result?.data?.notionDatabases?.find((db) => db.name === lookupName);

    if (!!defaultDatabase) {
      // If the default database found, select it automatically
      onSend(defaultDatabase);
      onClose();
    } else if (!databaseCount) {
      // If no databases found, create one automatically
      const creationResult = await createNotionDatabase({
        variables: {
          pageId: targetPage.id,
          integrationType,
        },
      });

      if (creationResult?.data?.createNotionDatabase?.database) {
        onSend(creationResult.data.createNotionDatabase.database);
      } else {
        onError(creationResult.data?.createNotionDatabase?.errors);
      }

      onClose();
    }
  };

  const handleClearNotionPageFilterValue = () => {
    setNotionPageFilterValue('');
    refetchNotionPages({
      pageSize: 15,
      search: null,
    });
  };

  const handleChangeNotionDbFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotionDbFilterValue(event.target.value);
  };

  const handleClearNotionDbFilterValue = () => {
    setNotionDbFilterValue('');
  };

  const handleChangeNotionPageFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotionPageFilterValue(event.target.value);
    refetchPagesDebounced(event.target.value);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const notionPages = notionPagesData?.notionPages.objects || [];
  const notionDatabases = notionDatabasesData?.notionDatabases || [];
  const isLoadingNotionPages = notionPagesNetworkStatus === NetworkStatus.loading;
  const isFetchingNotionPages = notionPagesNetworkStatus === NetworkStatus.fetchMore;
  const isRefetchingNotionPages =
    notionPagesNetworkStatus === NetworkStatus.refetch ||
    notionPagesNetworkStatus === NetworkStatus.setVariables;
  const isLoadingNotionDatabases = notionDatabasesNetworkStatus === NetworkStatus.loading;
  const isRefetchingNotionDatabases =
    notionDatabasesNetworkStatus === NetworkStatus.refetch ||
    notionDatabasesNetworkStatus === NetworkStatus.setVariables;
  const hasMoreNotionPages = notionPagesData?.notionPages.hasNext ?? false;
  const filteredNotionDatabases = notionDatabases.filter(({ name }) =>
    name.toLocaleLowerCase().includes(notionDbFilterValue.toLocaleLowerCase()),
  );
  /* #endregion */

  return (
    <Box height="50vh">
      <div className={styles.sticky}>
        <div className={styles.destinationHeader}>
          <IconButton
            size="small"
            className={`${styles.iconButton} destination`}
            onClick={handleCloseDatabaseMenu}
          >
            <BackIcon fontSize="small" />
          </IconButton>
          <div className={styles.destinationInner}>
            <img src={notionIntegrationImage} alt="Notion Integration" />
            <Typography component="div">
              <b>Post Item</b>
            </Typography>
          </div>
        </div>
        <Divider />
        {selectedNotionPage ? (
          <TextField
            fullWidth
            size="small"
            variant="filled"
            placeholder="Type here to search databases"
            className={styles.destinationSearch}
            value={notionDbFilterValue}
            InputProps={{
              endAdornment: !!notionDbFilterValue && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClearNotionDbFilterValue}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeNotionDbFilterValue}
          />
        ) : (
          <TextField
            fullWidth
            size="small"
            variant="filled"
            placeholder="Type here to search pages"
            className={styles.destinationSearch}
            value={notionPageFilterValue}
            InputProps={{
              endAdornment: !!notionPageFilterValue && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClearNotionPageFilterValue}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeNotionPageFilterValue}
          />
        )}
        <Divider />
      </div>
      {isLoadingNotionPages ||
      isLoadingNotionDatabases ||
      isRefetchingNotionDatabases ||
      isRefetchingNotionPages ||
      isCreatingNotionDb ? (
        <Box display="flex" alignItems="center" justifyContent="center" py={8}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {!selectedNotionPage ? (
            <Box my={1}>
              {notionPages.map((page) => (
                <MenuItem key={page.id} onClick={handleChangeNotionPage(page)}>
                  <Box display="flex" minWidth={32}>
                    {!!page.imageUrl || !!page.emoji ? (
                      <>
                        {!!page.imageUrl ? (
                          <img
                            loading="lazy"
                            src={page.imageUrl}
                            alt={page.name}
                            className={styles.icon}
                          />
                        ) : (
                          <Twemoji options={{ className: styles.icon }}>{page.emoji}</Twemoji>
                        )}
                      </>
                    ) : (
                      <PageIcon className={styles.icon} color="action" />
                    )}
                  </Box>
                  <Typography component="span" className={styles.textWrap}>
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
              {hasMoreNotionPages && (
                <MenuItem onClick={handleFetchMoreNotionPages}>
                  <Box display="flex" minWidth={32}>
                    {isFetchingNotionPages && (
                      <CircularProgress size={16} style={{ marginRight: 8 }} />
                    )}
                  </Box>
                  <Typography component="span" color="primary" className={styles.textWrap}>
                    Load more pages
                  </Typography>
                </MenuItem>
              )}
              {!notionPages.length && (
                <Box m={2}>
                  <Typography variant="body2" color="textSecondary">
                    No pages found
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box my={1}>
              <MenuItem onClick={handleCreateNotionDatabaseAndSend}>
                <Box display="flex" minWidth={32}>
                  <AddIcon className={styles.icon} color="action" />
                </Box>
                <Typography component="span" className={styles.textWrap}>
                  Sembly will create a database for you
                </Typography>
              </MenuItem>
              {filteredNotionDatabases.map((db) => (
                <MenuItem key={db.id} onClick={handleChangeNotionDatabase(db)}>
                  <Box display="flex" minWidth={32}>
                    <StorageIcon className={styles.icon} color="action" />
                  </Box>
                  <Typography component="span" className={styles.textWrap}>
                    {db.name}
                  </Typography>
                </MenuItem>
              ))}
              {!filteredNotionDatabases.length && (
                <Box m={2}>
                  <Typography variant="body2" color="textSecondary">
                    No databases found
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  destinationHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  destinationInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.75),
  },
  destinationSearch: {
    padding: theme.spacing(1.5, 2),
  },
  icon: {
    width: 18,
    height: 18,
    display: 'flex',
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
    '&.destination': {
      position: 'absolute',
      top: '50%',
      left: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  },
  textWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default NotionDestinationPicker;
