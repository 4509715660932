import clsx from 'clsx';
import sanitizeHtml from 'sanitize-html';
import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid, { GridProps } from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
// Icons
import EventIcon from '@material-ui/icons/EventSharp';
import MicIcon from '@material-ui/icons/Mic';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import PersonIcon from '@material-ui/icons/Person';
import SuggestedIcon from '@material-ui/icons/Spellcheck';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
// Lib shared
import logomark from '../assets/sembly-logomark.svg';
import { ACTIVITY_TYPES } from '../constants';
import { ActivityType } from '../types';
import { GenericKeyItemCategory } from '../enums';
import { convertSecondsToMinutes, getNameInitials } from '../utils';
import ContentEditable, { ContentEditableEvent } from './ContentEditable';

/* #region  Types */
export type KeyItemClickDueDateHandler = (data: {
  anchorEl: HTMLElement;
  keyItemId: string;
  dueDate: Date | string | null;
}) => void;

export interface KeyItemDiariazationItem {
  id: string | undefined;
  startTime: number | undefined;
  participantName: string | undefined;
  participantAvatar: string | null | undefined;
}

export interface KeyItemExtraFields {
  dueDate: Date | string | null;
  editedBySembly?: boolean;
}

export interface KeyItemCardProps extends GridProps {
  keyItemExtraFields?: KeyItemExtraFields;
  diariazationItem: KeyItemDiariazationItem;
  isActive?: boolean;
  isAuthorizedToEdit?: boolean;
  isAuthorizedToExport?: boolean;
  isRTLDirected?: boolean;
  keyItemCategory?: keyof typeof GenericKeyItemCategory;
  keyItemId: string;
  keyItemText: string;
  keyItemCustomText: string;
  keyItemType: ActivityType;
  onChangeText?: (value: string) => void;
  onClickOnDueDate?: KeyItemClickDueDateHandler;
  onClickOnTimestamp?: (timestamp: number) => void;
  onOpenMobileMenu?: (anchorEl: HTMLElement) => void;
  onToggleDesktopMenu?: (anchorEl: HTMLElement | null) => void;
}
/* #endregion */

export const KeyItemCard: React.VFC<KeyItemCardProps> = ({
  keyItemExtraFields,
  diariazationItem,
  isActive = false,
  isAuthorizedToEdit = false,
  isAuthorizedToExport = false,
  isRTLDirected = false,
  keyItemCategory,
  keyItemId,
  keyItemText,
  keyItemCustomText,
  keyItemType,
  onChangeText = () => null,
  onClickOnDueDate = () => null,
  onClickOnTimestamp = () => null,
  onOpenMobileMenu = () => null,
  onToggleDesktopMenu = () => null,
  ...gridProps
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const inputRef = useRef<HTMLSpanElement | null>(null);

  const [contentInputValue, setContentInputValue] = useState('');
  const [isContentInputFocused, setIsContentInputFocused] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleOpenMobileMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    onOpenMobileMenu(e.currentTarget);
  };

  const handleToggleDesktopMenu = (open: boolean) => (event: React.MouseEvent<HTMLElement>) => {
    if (!isSmallScreen) {
      onToggleDesktopMenu(open ? event.currentTarget : null);
    }
  };

  const handleClickOnTimestamp = (timestamp: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickOnTimestamp(timestamp);
  };

  const handleClickOnDueDate: React.MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement | HTMLLIElement
  > = (event) => {
    onClickOnDueDate({
      anchorEl: event.currentTarget,
      dueDate: keyItemExtraFields?.dueDate || null,
      keyItemId: keyItemId,
    });
  };

  const handleChangeContentInputValue = (e: ContentEditableEvent) => {
    const updContent = sanitizeHtml(e.target.value, { allowedAttributes: {}, allowedTags: [] });
    setContentInputValue(updContent);
  };

  const handleFocusOnContentInput = () => {
    setIsContentInputFocused(true);
  };

  const handleKeyDownOnContentInput = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      inputRef.current?.blur();
    }
  };

  const handleBlurContentInput: React.FocusEventHandler<HTMLDivElement> = (e) => {
    const inputValue = e.target.innerText;
    if (inputValue === '') setContentInputValue(keyItemText);
    setIsContentInputFocused(false);
    onChangeText(inputValue);
  };
  /* #endregion */

  useEffect(() => {
    setContentInputValue(keyItemCustomText || keyItemText);
  }, [keyItemCustomText, keyItemText]);

  /* #region  Render Helpers */
  const categoryIcons: Record<GenericKeyItemCategory, React.ReactElement> = {
    MANUAL: <PersonIcon fontSize="inherit" color="inherit" />,
    KEYWORD_TRIGGERED: <TrackChangesIcon fontSize="inherit" color="inherit" />,
    RECOGNIZED: <MicIcon fontSize="inherit" color="inherit" />,
    SUGGESTED: <SuggestedIcon fontSize="inherit" color="inherit" />,
  };

  const categoryTitle: Record<GenericKeyItemCategory, string> = {
    MANUAL: 'Manual',
    KEYWORD_TRIGGERED: 'Keyword triggered',
    RECOGNIZED: 'Recognized',
    SUGGESTED: 'Suggested',
  };

  const card = ACTIVITY_TYPES[keyItemType];
  const dueDate = keyItemExtraFields?.dueDate || null;
  const accentColor = isActive ? card.color : alpha(card.color, 0.5);
  const isDateObject = dueDate && typeof dueDate === 'object';
  const formattedDueDate = isDateObject ? format(dueDate, 'd MMM') : dueDate;
  const isEditedContent = keyItemCustomText && contentInputValue !== keyItemText;
  const isShowOriginalContentTooltip = !isContentInputFocused && isEditedContent;
  const isEditedBySembly = !!keyItemExtraFields?.editedBySembly;
  const hasDueDateField = keyItemType === 'keyEvents';
  /* #endregion */

  return (
    <Grid className={styles.root} item md={12} lg={6} xl={4} {...gridProps}>
      <Box
        className={clsx(styles.container, isRTLDirected && 'rtl')}
        borderLeft={`3px solid ${accentColor}`}
        data-scroll-item-id={`${keyItemId}`}
        onMouseEnter={handleToggleDesktopMenu(true)}
        onMouseLeave={handleToggleDesktopMenu(false)}
      >
        <div className={styles.main}>
          <div className={styles.attributes}>
            <Box dir="auto" flex="1" pr={1.5}>
              <ContentEditable
                tagName="div"
                innerRef={inputRef}
                html={contentInputValue}
                maxLength={1024}
                disabled={!isAuthorizedToEdit}
                className={styles.content}
                onBlur={handleBlurContentInput}
                onFocus={handleFocusOnContentInput}
                onKeyDown={handleKeyDownOnContentInput}
                onChange={handleChangeContentInputValue}
              />
              {isShowOriginalContentTooltip ? (
                <Tooltip placement="top" title={keyItemText} classes={{ tooltip: styles.tooltip }}>
                  <span className={styles.editedMark}>
                    (
                    {isEditedBySembly && (
                      <img src={logomark} alt="Sembly" style={{ height: 10, marginRight: 2 }} />
                    )}
                    original)
                  </span>
                </Tooltip>
              ) : null}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={isSmallScreen ? 1 : 0}
            >
              <Box display="flex" alignItems="center">
                {!!diariazationItem.startTime && (
                  <span
                    className={styles.timestamp}
                    onClick={handleClickOnTimestamp(diariazationItem.startTime)}
                  >
                    {convertSecondsToMinutes(diariazationItem.startTime)}
                  </span>
                )}
                {!!keyItemCategory && (
                  <span className={styles.type} title={categoryTitle[keyItemCategory]}>
                    {categoryIcons[keyItemCategory]}
                  </span>
                )}
                {!!diariazationItem.participantName && (
                  <Avatar
                    alt={diariazationItem.participantName}
                    title={diariazationItem.participantName}
                    src={diariazationItem.participantAvatar || undefined}
                    className={styles.avatar}
                  >
                    {!!diariazationItem.participantAvatar
                      ? null
                      : getNameInitials(diariazationItem.participantName)}
                  </Avatar>
                )}
              </Box>
              {/* Begin Mobile controls */}
              {isSmallScreen && (
                <Box display="flex">
                  {hasDueDateField && (
                    <div className={styles.roundedIcon}>
                      {!!dueDate ? (
                        <Link
                          className={styles.link}
                          onClick={isAuthorizedToEdit ? handleClickOnDueDate : undefined}
                        >
                          <Typography className={styles.date} variant="body2">
                            {formattedDueDate}
                          </Typography>
                        </Link>
                      ) : (
                        <IconButton
                          aria-label="Due Date"
                          title="Due Date"
                          size="small"
                          onClick={isAuthorizedToEdit ? handleClickOnDueDate : undefined}
                        >
                          <EventIcon fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  )}
                </Box>
              )}
              {/* End: Mobile Controls */}
            </Box>
          </div>
        </div>
        {/* Begin: Desktop Controls */}
        {hasDueDateField && !isSmallScreen && (
          <Box className={styles.rowCell} width={theme.spacing(6)}>
            {!!dueDate ? (
              <Link
                className={styles.link}
                style={{ cursor: isAuthorizedToEdit ? 'pointer' : 'default' }}
                onClick={isAuthorizedToEdit ? handleClickOnDueDate : undefined}
              >
                <Typography variant="body2">{formattedDueDate}</Typography>
              </Link>
            ) : (
              <IconButton
                aria-label="Due Date"
                title="Due Date"
                size="small"
                style={{ cursor: isAuthorizedToEdit ? 'pointer' : 'default' }}
                onClick={isAuthorizedToEdit ? handleClickOnDueDate : undefined}
              >
                <EventIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
        {/* End: Desktop Controls */}
        {/* Begin: Mobile Controls */}
        {isSmallScreen && (
          <Box className={styles.rowCell} width={theme.spacing(4)}>
            <IconButton
              title="more"
              aria-label="more"
              aria-haspopup="true"
              size="small"
              onClick={handleOpenMobileMenu}
            >
              <MoreVertIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
        {/* End: Mobile Controls */}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(0.25),
  },
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '&.highlighted': {
        backgroundColor: `${alpha(theme.palette.highlight.main, 0.25)} !important`,
      },
    },
    '&.rtl': {
      direction: 'rtl',
    },
  },
  main: {
    flex: '1',
    display: 'flex',
    fontSize: `${theme.typography.body1.fontSize}px`,
  },
  attributes: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing(1.5),
    fontSize: theme.typography.body1.fontSize,
    transition: 'backgroundColor .2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  rowCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: `${theme.spacing(0.25)}px solid ${theme.palette.background.paper}`,
    fontSize: theme.typography.body2.fontSize,
    transition: 'backgroundColor .2s ease-in-out',
  },
  content: {
    display: 'inline',
    position: 'relative',
    wordBreak: 'break-word',
    fontSize: theme.typography.body1.fontSize,
    '&:focus': {
      display: 'block',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'baseline',
    margin: theme.spacing(0, 1),
    flex: 1,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[400],
    '&:hover': {
      textDecoration: 'none',
    },
  },
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
  },
  type: {
    display: 'flex',
    margin: theme.spacing(0, 1),
    alignSelf: 'center',
    color: theme.palette.grey[300],
    fontSize: '1rem',
  },
  avatar: {
    width: 20,
    height: 20,
    fontSize: '0.6875rem',
  },
  timestamp: {
    cursor: 'pointer',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.grey[300],
  },
  editedMark: {
    marginLeft: theme.spacing(1 / 2),
    color: theme.palette.grey[300],
    cursor: 'default',
    whiteSpace: 'nowrap',
  },
  date: {
    padding: theme.spacing(0, 1.5),
    textAlign: 'center',
    lineHeight: '1.0',
  },
  roundedIcon: {
    height: 28,
    minWidth: 28,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 4,
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));

export default KeyItemCard;
