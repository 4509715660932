import sortBy from 'lodash/sortBy';
import { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

/* #region  Types */
export interface SentimentWidgetDataEntity {
  id: string;
  itemId: string;
  sentiment: number;
  startTime: number;
  text: string;
  type: 'highlight' | 'lowlight';
}

export interface SentimentWidgetProps {
  data: SentimentWidgetDataEntity[];
  onClick: (item: SentimentWidgetDataEntity, event: React.MouseEvent<SVGCircleElement>) => void;
}
/* #endregion */

export const SentimentWidget: React.VFC<SentimentWidgetProps> = ({ data, onClick }) => {
  const styles = useStyles();
  const [proxiedData, setProxiedData] = useState<SentimentWidgetDataEntity[]>([]);

  /* #region  Utils */
  function getValue(item: SentimentWidgetDataEntity, ratio: number = 14) {
    return item.type === 'lowlight' ? (1 - item.sentiment) * ratio : item.sentiment * ratio;
  }
  /* #endregion */

  /* #region  Handlers */
  const handleClick =
    (item: SentimentWidgetDataEntity) => (event: React.MouseEvent<SVGCircleElement>) => {
      onClick(item, event);
    };

  const handleMouseEnter = (item: SentimentWidgetDataEntity, target: number) => () => {
    setProxiedData(
      sortedData.map((entity, index) => {
        const { sentiment, startTime, type } = entity;
        const isHoveringItem = entity.id === item.id;
        const isLowlight = type === 'lowlight';
        // shift calculation
        const isTarget = target - index === 0;
        const coef = isTarget ? sortedData.length : Math.abs(sortedData.length / (target - index));
        const shift = index >= target ? startTime + coef : startTime - coef;
        // update value
        return isHoveringItem
          ? item
          : {
              ...entity,
              sentiment: isLowlight ? sentiment + 0.15 : sentiment - 0.15,
              startTime: shift,
            };
      }),
    );
  };

  const handleMouseLeave = () => {
    setProxiedData([]);
  };
  /* #endregion */

  /* #region  Momorized data */
  const sortedData = useMemo(() => {
    return sortBy(data, 'startTime');
  }, [data]);
  /* #endregion */

  /* #region  Render Helpers */
  const items = proxiedData.length ? proxiedData : sortedData;
  const timeline = data.map((entity) => entity.startTime);
  const min = Math.min(...timeline) - 128;
  const max = Math.max(...timeline) + 128;
  /* #endregion */

  return (
    <svg width="100%" height="100px" onMouseLeave={handleMouseLeave}>
      <line
        x1="0"
        y1="50%"
        x2="100%"
        y2="50%"
        fill="none"
        strokeWidth="1px"
        strokeOpacity={0.1}
        shapeRendering="crispEdges"
        stroke="#000000"
      />
      <g>
        {items.map((item, index) => {
          const type = item.type === 'highlight' ? styles.primary : styles.secondary;
          return (
            <circle
              key={item.id}
              className={`${styles.circle} ${type}`}
              cy="50%"
              cx={`${((item.startTime - min) * 100) / (max - min)}%`}
              r={getValue(item)}
              transform-origin={`${((item.startTime - min) * 100) / (max - min)}% center`}
              onClick={handleClick(item)}
              onMouseEnter={handleMouseEnter(item, index)}
            />
          );
        })}
      </g>
    </svg>
  );
};

const useStyles = makeStyles((theme) => ({
  circle: {
    cursor: 'pointer',
    position: 'relative',
    transition: 'transform 0.25s, cx 0.25s',
    opacity: 0.75,
    '&:hover': {
      transition: 'transform 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8), cx 0.1s',
      transform: 'scale(1.5)',
      opacity: 1,
    },
  },
  primary: {
    fill: theme.palette.primary.main,
  },
  secondary: {
    fill: theme.palette.secondary.main,
  },
}));

export default SentimentWidget;
