import { useQuery, useMutation } from '@apollo/client';
// Material UI
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { TIMEZONES_LIST } from '../constants';
import { Switch } from '../components';
import workspaceAutomationsSettingsQuery from '../graphql/queries/WorkspaceAutomationsSettings.graphql';
import manageWorkspaceAutomationsMutation from '../graphql/mutations/ManageWorkspaceAutomationsSettings.graphql';
import {
  AutomationsDelays,
  GraphError,
  ManageWorkspaceAutomationsSettings,
  ManageWorkspaceAutomationsSettingsVariables,
  WorkspaceAutomationsSettings,
} from '../types';

export interface WorkspaceAutomationsSettingsContainerProps {
  onResponseError: (err: GraphError) => void;
}

/**
 * Container for the workspace automations settings page.
 */
export const WorkspaceAutomationsSettingsContainer: React.VFC<
  WorkspaceAutomationsSettingsContainerProps
> = ({ onResponseError }) => {
  const styles = useStyles();

  const { data, loading } = useQuery<WorkspaceAutomationsSettings>(
    workspaceAutomationsSettingsQuery,
  );

  const [manageWorkspaceAutomations] = useMutation<
    ManageWorkspaceAutomationsSettings,
    ManageWorkspaceAutomationsSettingsVariables
  >(manageWorkspaceAutomationsMutation);

  const handleToggleSwitch =
    (type: 'run-immediately' | 'run-with-delay' | 'user-automations') =>
    async (event: unknown, value: boolean) => {
      if (!data?.workspaceAutomationsSettings) {
        throw new Error('No workspace automations settings found');
      }

      const { runImmediately, runWithDelay, delay, timezone } =
        data.workspaceAutomationsSettings;

      const variables: ManageWorkspaceAutomationsSettingsVariables = {
        timezone,
        id: data.workspaceAutomationsSettings.id,
        runImmediately: type === 'run-immediately' ? value : runImmediately,
        runWithDelay: type === 'run-with-delay' ? value : runWithDelay,
        delay: type === 'run-with-delay' ? AutomationsDelays.ONE_HOUR : delay,
      };

      const result = await manageWorkspaceAutomations({
        variables,
        optimisticResponse: {
          manageWorkspaceAutomationsSettings: {
            __typename: 'ManageWorkspaceAutomationsSettingsMutationPayload',
            workspaceAutomationsSettings: {
              ...variables,
              __typename: 'WorkspaceAutomationsSettingsType',
              timezone,
              delay: type === 'run-with-delay' ? AutomationsDelays.ONE_HOUR : delay,
            },
            success: true,
            errors: [],
          },
        },
      });

      if (!result.data?.manageWorkspaceAutomationsSettings?.success) {
        onResponseError(result.data?.manageWorkspaceAutomationsSettings?.errors);
      }
    };

  const handleChangeDelay = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!data?.workspaceAutomationsSettings) {
      throw new Error('No workspace automations settings found');
    }

    const { runImmediately, runWithDelay, timezone } =
      data.workspaceAutomationsSettings;

    const variables: ManageWorkspaceAutomationsSettingsVariables = {
      id: data.workspaceAutomationsSettings.id,
      delay: event.target.value as AutomationsDelays,
      runImmediately,
      runWithDelay,
      timezone,
    };

    manageWorkspaceAutomations({
      variables,
      optimisticResponse: {
        manageWorkspaceAutomationsSettings: {
          __typename: 'ManageWorkspaceAutomationsSettingsMutationPayload',
          workspaceAutomationsSettings: {
            ...variables,
            __typename: 'WorkspaceAutomationsSettingsType',
            delay: event.target.value as AutomationsDelays,
            timezone,
          },
          success: true,
          errors: [],
        },
      },
    });
  };

  const handleChangeTimezone = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (!data?.workspaceAutomationsSettings) {
      throw new Error('No workspace automations settings found');
    }

    const { runImmediately, runWithDelay, delay } =
      data.workspaceAutomationsSettings;

    const value = event.target.value as string;
    const timezone = value === 'auto' ? null : value;
    const variables: ManageWorkspaceAutomationsSettingsVariables = {
      id: data.workspaceAutomationsSettings.id,
      timezone,
      delay,
      runImmediately,
      runWithDelay,
    };

    manageWorkspaceAutomations({
      variables,
      optimisticResponse: {
        manageWorkspaceAutomationsSettings: {
          __typename: 'ManageWorkspaceAutomationsSettingsMutationPayload',
          workspaceAutomationsSettings: {
            ...variables,
            __typename: 'WorkspaceAutomationsSettingsType',
            timezone,
            delay,
          },
          success: true,
          errors: [],
        },
      },
    });
  };

  const timezoneCode = data?.workspaceAutomationsSettings?.timezone;
  const isKnownTimezone = TIMEZONES_LIST.some(
    (item: { label: string; tzCode: string }) => item.tzCode === timezoneCode,
  );

  return (
    <Box pt={2}>
      <div className={styles.header}>
        <Typography variant="h6">Automation Trigger Rules</Typography>
        {loading && <CircularProgress size={18} />}
      </div>

      {!!data?.workspaceAutomationsSettings &&
        !data.workspaceAutomationsSettings.runImmediately &&
        !data.workspaceAutomationsSettings.runWithDelay && (
          <Box mt={3}>
            <Alert icon={false} severity="warning" classes={{ message: styles.message }}>
              <Box width="100%" textAlign="center">
                <Typography variant="body1">
                  All workspace automation trigger rules are disabled, so no meeting insights will
                  be sent automatically
                </Typography>
              </Box>
            </Alert>
          </Box>
        )}

      <Box mt={3}>
        <Paper square>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box py={1} px={2}>
                  <Typography gutterBottom variant="body1">
                    <b>Send immediately</b>
                  </Typography>
                  <Typography variant="body1">
                    Send insights immediately after the meeting is processed
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center" height="100%" px={2}>
                  <Switch
                    color="primary"
                    disabled={loading}
                    checked={data?.workspaceAutomationsSettings?.runImmediately ?? false}
                    onChange={handleToggleSwitch('run-immediately')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box py={1} px={2}>
                  <Typography gutterBottom variant="body1">
                    <b>Send after a delay</b>
                  </Typography>
                  <Typography variant="body1">
                    Send insights with a delay after the meeting is processed, allowing time for
                    manual edits before execution
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center" height="100%" gridGap={12} px={2}>
                  <Switch
                    color="primary"
                    disabled={loading}
                    checked={data?.workspaceAutomationsSettings?.runWithDelay ?? false}
                    onChange={handleToggleSwitch('run-with-delay')}
                  />
                  {data?.workspaceAutomationsSettings?.runWithDelay && (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={data?.workspaceAutomationsSettings?.delay}
                      disabled={loading || !data?.workspaceAutomationsSettings?.runWithDelay}
                      onChange={handleChangeDelay}
                    >
                      <MenuItem value={AutomationsDelays.ONE_HOUR}>1 hour</MenuItem>
                      <MenuItem value={AutomationsDelays.THREE_HOURS}>3 hours</MenuItem>
                      <MenuItem value={AutomationsDelays.SIX_HOURS}>6 hours</MenuItem>
                      <MenuItem value={AutomationsDelays.TWELVE_HOURS}>12 hours</MenuItem>
                      <MenuItem value={AutomationsDelays.TWENTY_FOUR_HOURS}>24 hours</MenuItem>
                    </TextField>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <div className={styles.header}>
        <Typography variant="h6">Automation Timezone</Typography>
        {loading && <CircularProgress size={18} />}
      </div>

      <Box mt={3}>
        <Paper square>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box py={1} px={2}>
                  <Typography gutterBottom variant="body1">
                    <b>Timezone</b>
                  </Typography>
                  <Typography variant="body1">
                    Set the timezone to use for all workspace automations
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" alignItems="center" height="100%" gridGap={12} px={2}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    variant="outlined"
                    disabled={loading}
                    value={timezoneCode || 'auto'}
                    onChange={handleChangeTimezone}
                  >
                    <MenuItem value="auto">Auto</MenuItem>
                    {!!timezoneCode && !isKnownTimezone && (
                      <MenuItem value={timezoneCode}>{timezoneCode}</MenuItem>
                    )}
                    {TIMEZONES_LIST.map(
                      (timezone: { label: string; tzCode: string }, index: number) => (
                        <MenuItem key={index} value={timezone.tzCode}>
                          {timezone.label}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  message: {
    width: '100%',
  },
}));
