import { useHistory } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// App Shared
import connectBgImage from '@shared/assets/connect-successful.png';
import { Routes } from '@shared/enums';

export const EnrollmentSampleComplete: React.VFC = () => {
  const styles = useStyles();
  const history = useHistory();

  const handleClose = () => {
    history.replace(Routes.Home);
  };

  return (
    <GenericDialog dialogProps={{ fullWidth: true, maxWidth: 'sm' }} onClose={handleClose}>
      <DialogContent className={styles.content}>
        <Box textAlign="center">
          <Box mb={4}>
            <img
                src={connectBgImage}
                alt="Sembly AI"
                className={styles.image}
              />
            <Typography gutterBottom variant="h2">
              You're all set!
            </Typography>
          </Box>
          <Typography gutterBottom variant="h6" color="textSecondary">
            With Voice ID configured, Sembly now recognizes your voice in meetings, making your experience more personalized and interactive.
          </Typography>
        </Box>
        <Box display="inline-flex" mb={4} mt={4} width={180}>
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            aria-label="Dive into Sembly"
            onClick={handleClose}
          >
            Dive into Sembly
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'hidden',
  },
  image: {
    width: '64px',
    height: '64px',
    overflow: 'hidden',
    marginBottom: 24
  },
}));

export default EnrollmentSampleComplete;
