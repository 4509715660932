import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ManualRecording = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M12.2851 14.6316C14.0325 14.6316 15.443 13.2211 15.443 11.4737V5.15789C15.443 3.41053 14.0325 2 12.2851 2C10.5378 2 9.12725 3.41053 9.12725 5.15789V11.4737C9.12725 13.2211 10.5378 14.6316 12.2851 14.6316ZM18.5062 11.4737C17.9904 11.4737 17.5588 11.8526 17.4746 12.3684C17.043 14.8421 14.8851 16.7368 12.2851 16.7368C9.68514 16.7368 7.52725 14.8421 7.09567 12.3684C7.01146 11.8526 6.57988 11.4737 6.06409 11.4737C5.42198 11.4737 4.91672 12.0421 5.01146 12.6737C5.52725 15.8316 8.05356 18.3053 11.2325 18.7579V20.9474C11.2325 21.5263 11.7062 22 12.2851 22C12.8641 22 13.3378 21.5263 13.3378 20.9474V18.7579C16.5167 18.3053 19.043 15.8316 19.5588 12.6737C19.6641 12.0421 19.1483 11.4737 18.5062 11.4737Z"
      fill="#60636F"
    />
    <circle cx="19.5" cy="1.5" r="1.5" fill="#F74C01" />
  </SvgIcon>
);

export default ManualRecording;
