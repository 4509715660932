import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import { DataRecord, DataCollectionListResponse, DataCollectionSpec } from '@integration-app/sdk';
// Material UI
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { AutomationDestinationPicker } from '../containers';
import { useIntegrationApp } from '../clients/wrappedIntegrationAppSDK';

export interface SelectAutomationDestinationDialogProps {
  appKey: string;
  value: string;
  fieldKey: string;
  collectionKey: string;
  collectionParameters: Record<string, string> | undefined;
  restrict3rdPartyIntegrations: boolean;
  onChange: (values: Record<string, string>) => void;
  onError: (error: unknown) => void;
}

/**
 * Component that displays a select input with all available destinations for a given collection.
 */
export const AutomationDestinationSelect: React.VFC<SelectAutomationDestinationDialogProps> = ({
  appKey,
  value,
  fieldKey,
  collectionKey,
  collectionParameters,
  restrict3rdPartyIntegrations,
  onChange,
  onError,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const integrationApp = useIntegrationApp({ skip: restrict3rdPartyIntegrations });

  const [data, setData] = useState<DataCollectionListResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [canSearch, setCanSearch] = useState(false);
  const [showPicker, setShowPicker] = useState<HTMLElement | null>(null);
  /* #endregion */

  /* #region  Utils */
  const isValidCollectionParameters = (
    collectionParameters: Record<string, string> | undefined,
  ) => {
    return !Object.values(collectionParameters || {}).some((value) => !value);
  };

  const isValidParameters = isValidCollectionParameters(collectionParameters);
  /* #endregion */

  /* #region  Hooks */
  const handleSubmit = (destination: DataRecord) => {
    if (destination.id === value) return;
    setIsLoading(true);
    onChange({ [fieldKey]: destination.id });
  };

  const handleLoadMore = () => {
    if (data?.cursor) {
      setIsLoadingMore(true);
      integrationApp
        .connection(appKey)
        .dataCollection(collectionKey, collectionParameters)
        .list({ cursor: data.cursor })
        .then((response) => {
          setData((prevData) => ({
            ...response,
            records: [...(prevData?.records || []), ...response.records],
          }));
        })
        .catch(onError);

      setIsLoadingMore(false);
    }
  };

  const handleShowPicker = (event: React.MouseEvent<HTMLElement>) => {
    if (!data) return;
    setShowPicker(event.currentTarget);
  };

  const handleHidePicker = () => {
    setShowPicker(null);
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    setIsLoading(false);
  }, [value]);

  useEffect(() => {
    const loadDataCollection = async () => {
      const dataCollection: DataCollectionSpec = await integrationApp
        .integration(appKey)
        .getDataCollection(collectionKey);
      setCanSearch(!!dataCollection.search);
    };

    loadDataCollection();
  }, [appKey, integrationApp, collectionKey]);

  useEffect(() => {
    const hasCollectionParameters = collectionParameters !== undefined;
    if (hasCollectionParameters && !isValidParameters) return;

    const loadCollectionList = async () => {
      setIsLoading(true);

      try {
        const dataCollectionList = await integrationApp
          .connection(appKey)
          .dataCollection(collectionKey, collectionParameters)
          .list();
        setData(dataCollectionList);
      } catch (err) {
        onError(err);
      }

      setIsLoading(false);
    };

    loadCollectionList();
  }, [appKey, collectionKey, collectionParameters, integrationApp, isValidParameters, onError]);
  /* #endregion */

  const label = collectionKey
    ? startCase(collectionKey.split('-').join(' '))
    : 'Select a destination parameter';

  const selectedValue = data?.records.find((item) => item.id === value) || null;

  return (
    <Box position="relative">
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label={label}
        disabled={isLoading || !data}
        value={selectedValue?.name || ''}
        onClick={handleShowPicker}
        InputProps={{
          readOnly: true,
          classes: { input: styles.input, root: styles.field },
          endAdornment: (
            <>
              {isLoading ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                <ArrowDropDown color="action" />
              )}
            </>
          ),
        }}
      />
      {!!showPicker && (
        <AutomationDestinationPicker
          anchorEl={showPicker}
          appKey={appKey}
          collectionKey={collectionKey}
          collectionParameters={collectionParameters}
          destinations={data}
          isLoadMore={isLoadingMore}
          restrict3rdPartyIntegrations={restrict3rdPartyIntegrations}
          searchable={canSearch}
          value={value}
          onError={onError}
          onClose={handleHidePicker}
          onSubmit={handleSubmit}
          onLoadMore={handleLoadMore}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  field: {
    cursor: 'pointer',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  input: {
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
  },
}));

export default AutomationDestinationSelect;
