// Material UI
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// App Shared
import { ReactComponent as Art } from '@shared/assets/cancel-subscription.svg';
import { useUserContext } from '@shared/hooks';

interface CanceledSubscriptionContainerProps {
  open: boolean;
  onClose: () => void;
}

export const CanceledSubscriptionContainer: React.FC<CanceledSubscriptionContainerProps> = ({
  children,
  open,
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  /* #endregion */

  // Render Helpers
  const isSmartmeetingUser = user.data?.me?.philipsDevices?.smartmeeting ?? false;

  return (
    <GenericDialog
      dialogProps={{ open, fullWidth: true, fullScreen: isSmallScreen }}
      onClose={onClose}
    >
      <DialogContent className={styles.content}>
        <Box mb={4}>
          <Box textAlign="center" mb={3}>
            <Art width="64" height="64" />
          </Box>
          <Typography gutterBottom align="center" variant="h3">
            {isSmartmeetingUser ? 'Trial Expired' : 'Subscription Canceled'}
          </Typography>
        </Box>
        {children}
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 6, 6),
    marginTop: theme.spacing(-6),
  },
}));

export default CanceledSubscriptionContainer;
