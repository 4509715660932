import React, { useEffect, useRef } from 'react';
import twemoji from 'twemoji';

type ParseCallback = (icon: string, options: object, variant: string) => string | false;

export interface TwemojiProps {
  children: React.ReactNode;
  noWrapper?: boolean;
  tag?: keyof JSX.IntrinsicElements;
  options?: {
    /**
     * Default: MaxCDN
     */
    base?: string;
    /**
     * Default: .png
     */
    ext?: string;
    /**
     * Default: emoji
     */
    className?: string;
    /**
     * Default: 72x72
     */
    size?: string | number;
    /**
     * To render with SVG use `folder: svg, ext: .svg`
     */
    folder?: string;
    /**
     * The function to invoke in order to generate image src(s).
     */
    callback?: ParseCallback;
    /**
     * The function to invoke in order to generate additional, custom attributes for the image tag.
     * Default () => ({})
     * @param icon the lower case HEX code point i.e. "1f4a9"
     * @param variant variant the optional \uFE0F ("as image") variant, in case this info is anyhow meaningful. By default this is ignored.
     *
     */
    attributes?(icon: string, variant: string): object;
  };
}

/**
 * A React wrapper for Twemoji. It calls twemoji.parse() to convert emoji characters to Twemoji images.
 * Simply use it to wrap your emotional content. Set the options prop to pass options to twemoji.parse.
 */
export const Twemoji: React.FC<TwemojiProps> = ({ children, noWrapper, options, tag = 'div' }) => {
  const rootRef = useRef<HTMLElement>(null);
  const childrenRefs = useRef<Record<string, React.RefObject<HTMLElement>>>({});

  const BASE = 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/';

  useEffect(() => {
    const twemojiOptions = { ...options, base: BASE };

    if (noWrapper) {
      for (const i in childrenRefs.current) {
        const node = childrenRefs.current[i].current;
        if (node) {
          twemoji.parse(node, twemojiOptions);
        }
      }
    } else {
      const node = rootRef.current;
      if (node) {
        twemoji.parse(node, twemojiOptions);
      }
    }
  }, [children, noWrapper, options]);

  if (noWrapper) {
    return (
      <>
        {React.Children.map(children, (c, i) => {
          if (typeof c === 'string') {
            return c;
          }
          childrenRefs.current[i] = childrenRefs.current[i] || React.createRef();
          return React.cloneElement(c as React.ReactElement, {
            ref: childrenRefs.current[i],
          });
        })}
      </>
    );
  } else {
    return React.createElement(tag, { ref: rootRef }, children);
  }
};

export default Twemoji;
