import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const OnboardingModuleContainer = lazyWithPreload(
  () => import('./containers/OnboardingModuleContainer'),
);

export const OnboardingModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Onboarding,
    render: () => <OnboardingModuleContainer />,
  },
};

export default OnboardingModule;
