import { useState, useEffect } from 'react';
import { Calendar, utils, DayValue } from '@amir04lm26/react-modern-calendar-date-picker';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export interface ActionDueDateMenuProps {
  actionId: string;
  anchorEl: HTMLElement;
  value: Date | string | null;
  onChange: (id: string, value: Date | null) => void;
  onClose: () => void;
}

export const ActionDueDateMenu: React.VFC<ActionDueDateMenuProps> = ({
  actionId,
  anchorEl,
  value,
  onChange,
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [selectedDay, setSelectedDay] = useState<DayValue>(null);
  /* #endregion */

  /* #region  Handlers */
  const handleSetDay = (day: DayValue) => {
    setSelectedDay(day);
  };

  const handleRemoveDueDate = () => {
    onChange(actionId, null);
    onClose();
  };

  const handleApplyDueDate = () => {
    if (!selectedDay) return onClose();

    const dueDate = new Date(
      selectedDay.year,
      selectedDay.month - 1, // convert a month number to a month index
      selectedDay.day,
    );

    onChange(actionId, dueDate);
    onClose();
  };
  /* #endregion */

  useEffect(() => {
    if (value && typeof value === 'object') {
      setSelectedDay({
        day: value.getDate(),
        month: value.getMonth() + 1, // convert a month index to a month number
        year: value.getFullYear(),
      });
    }
  }, [value]);

  return (
    <Popover open classes={{ paper: styles.paper }} anchorEl={anchorEl} onClose={onClose}>
      <Calendar
        shouldHighlightWeekends
        value={selectedDay}
        onChange={handleSetDay}
        minimumDate={utils('en').getToday()}
      />
      <Box display="flex" m={2}>
        {value !== null && (
          <>
            <Button fullWidth variant="outlined" onClick={handleRemoveDueDate}>
              <Typography variant="body2">Clear All</Typography>
            </Button>
            <Box mr={1} />
          </>
        )}
        <Button
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          disabled={!selectedDay}
          onClick={handleApplyDueDate}
        >
          <Typography variant="body2">Apply</Typography>
        </Button>
      </Box>
    </Popover>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius,
  },
}));

export default ActionDueDateMenu;
