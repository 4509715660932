import { lazyWithRetry } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

const OAuth2ModuleContainer = lazyWithRetry(() => import('./containers/OAuth2ModuleContainer'));

export const OAuthModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.OAuth2,
    render: () => <OAuth2ModuleContainer />,
  },
};

export default OAuthModule;
