import { format as dateFnsFormat, utcToZonedTime } from 'date-fns-tz';

export function formatInTimeZone(date: Date | string | number, format: string = ""): string {
  const timeZone = window.SemblyUserTimeZone || 'Etc/GMT';
  let value;

  try {
    const zonedDate = utcToZonedTime(date, timeZone);
    value = dateFnsFormat(zonedDate, format, { timeZone });
  } catch (error) {
    value = dateFnsFormat(date, format);
  }

  return value;
}

export default formatInTimeZone;
