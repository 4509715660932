import { lazyWithRetry } from '@shared/utils';
import { Routes } from '@shared/enums';
import { PublicRouterModule } from '@shared/types';

const ResetPasswordModuleContainer = lazyWithRetry(
  () => import('./containers/ResetPasswordModuleContainer'),
);

export const ResetPasswordModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.ResetPassword,
    render: () => <ResetPasswordModuleContainer />,
  },
};

export default ResetPasswordModule;
