import clsx from 'clsx';
import { useState } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import MeetingParticipantAvatar from './MeetingParticipantAvatar';
import { getNameInitials } from '../utils';
import { GenericParticipant, GenericAccessItem } from '../types';

/* #region  Types */
interface AccessItem extends GenericAccessItem {
  user: NonNullable<GenericAccessItem['user']>;
}

export interface MeetingSpeakersMenuProps {
  accessItems?: GenericAccessItem[];
  anchorEl: HTMLElement;
  disableReplaceAll?: boolean;
  meetingParticipants: GenericParticipant[];
  speakerId: string;
  speakerName: string;
  title?: string;
  onClose: () => void;
  onReplaceSpeaker?: (targetParticipant: GenericParticipant, type: 'one' | 'all') => void;
  onAddSpeaker: (
    speakerName: string,
    accessItemUserId: string | null,
  ) => Promise<GenericParticipant | null>;
}
/* #endregion */

export const MeetingSpeakersMenu: React.VFC<MeetingSpeakersMenuProps> = ({
  accessItems = [],
  anchorEl,
  disableReplaceAll = false,
  meetingParticipants,
  speakerId,
  speakerName,
  title = 'Edit speaker',
  onAddSpeaker,
  onClose,
  onReplaceSpeaker = () => null,
}) => {
  const styles = useStyles();

  const [customSpeakerName, setCustomSpeakerName] = useState<string | null>(null);
  const [isCreatingNewParticipant, setIsCreatingNewParticipant] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState<GenericParticipant | null>(null);
  const [showAddSpeakerForm, setShowAddSpeakerForm] = useState(false);

  /* #region  Utils */
  const getAvailableAttendees = (attendees: GenericAccessItem[]) => {
    let availableAttendees: AccessItem[] = [];

    function isExistingSpeaker(attendee: GenericAccessItem) {
      return !!meetingParticipants.find(
        (participant) => participant?.user?.id === attendee.user?.id,
      );
    }

    attendees.forEach((attendee) => {
      if (!!attendee.user && !isExistingSpeaker(attendee)) {
        availableAttendees.push(attendee as AccessItem);
      }
    });

    return availableAttendees;
  };

  const createSpeaker = async (speakerName: string, accessItemUserId: string | null) => {
    setIsCreatingNewParticipant(true);
    const newParticipant = await onAddSpeaker(speakerName, accessItemUserId);
    setIsCreatingNewParticipant(false);
    return newParticipant;
  };
  /* #endregion */

  /* #region  Handlers */
  const handleCreateParticipant = (accessItems: AccessItem) => async () => {
    const speakerName = accessItems.user.fullName;
    const accessItemUserId = accessItems.user.id;
    const createdParticipant = await createSpeaker(speakerName, accessItemUserId);

    if (!createdParticipant) return;

    if (disableReplaceAll) {
      onReplaceSpeaker(createdParticipant, 'one');
    } else {
      setSelectedParticipant(createdParticipant);
      setShowAddSpeakerForm(false);
    }
  };

  const handleCreateCustomParticipant = async () => {
    if (!customSpeakerName) throw new Error('Speaker name is required');
    const createdParticipant = await createSpeaker(customSpeakerName, null);

    if (!createdParticipant) return;

    if (disableReplaceAll) {
      onReplaceSpeaker(createdParticipant, 'one');
    } else {
      setSelectedParticipant(createdParticipant);
      setCustomSpeakerName(null);
      setShowAddSpeakerForm(false);
    }
  };

  const handleReplaceSpeaker = (item: GenericParticipant, type: 'one' | 'all') => () => {
    onReplaceSpeaker(item, type);
  };

  const handleCancelSubmission = () => {
    setSelectedParticipant(null);
    setShowAddSpeakerForm(false);
  };

  const handleStopPropagination = (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    e?.stopPropagation();
  };

  const handleSetCustomSpeakerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim() !== '' ? e.target.value.substring(0, 40) : null;
    setCustomSpeakerName(value);
  };

  const handleSelectParticipant = (participant: GenericParticipant) => () => {
    if (speakerId === participant.id) {
      return onClose(); // no change happens
    }

    if (disableReplaceAll) {
      onReplaceSpeaker(participant, 'one');
    } else {
      setSelectedParticipant(participant);
    }
  };

  const handleShowAddSpeakerForm = () => {
    setShowAddSpeakerForm(true);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const renderChangeSpeaker = () => (
    <>
      <Box position="relative" mx={2} py={1}>
        <Typography variant="subtitle2" align="center">
          {title}
        </Typography>
        <IconButton
          size="small"
          className={clsx(styles.iconBtn, styles.closeBtn)}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <div>
        <div className={styles.list}>
          {meetingParticipants.map((participant) => (
            <MenuItem
              key={participant.id}
              selected={speakerId === participant.id}
              onClick={handleSelectParticipant(participant)}
            >
              <MeetingParticipantAvatar
                className={styles.avatar}
                name={participant.user?.fullName || participant.name}
                avatar={participant.user?.avatar}
              />
              <span>{participant.user?.fullName || participant.name}</span>
            </MenuItem>
          ))}
        </div>
        <Divider />
        <Box display="flex" alignItems="center" height={48} paddingX={1}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            className={styles.btn}
            onClick={handleShowAddSpeakerForm}
          >
            Add new speaker
          </Button>
        </Box>
      </div>
    </>
  );

  const renderAddSpeaker = () => (
    <>
      <Box position="relative" mx={2} py={1}>
        <IconButton
          size="small"
          className={clsx(styles.iconBtn, styles.backBtn)}
          onClick={handleCancelSubmission}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <Typography variant="subtitle2" align="center">
          Select among attendees
        </Typography>
        <IconButton
          size="small"
          className={clsx(styles.iconBtn, styles.closeBtn)}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <div>
        {getAvailableAttendees(accessItems).map((attendee) => (
          <MenuItem
            key={attendee.id}
            selected={speakerId === attendee.id}
            onClick={handleCreateParticipant(attendee)}
          >
            <MeetingParticipantAvatar
              className={styles.avatar}
              name={attendee.user.fullName}
              avatar={attendee.user.avatar}
            />
            <span>{attendee.user.fullName}</span>
          </MenuItem>
        ))}
        <Divider />
        <Box display="flex" alignItems="center" height={48} paddingX={2}>
          <Avatar alt={customSpeakerName || 'Speaker'} className={styles.avatar}>
            {customSpeakerName ? getNameInitials(customSpeakerName) : null}
          </Avatar>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Enter custom speaker"
            disabled={isCreatingNewParticipant}
            classes={{ root: styles.input }}
            value={customSpeakerName || ''}
            onChange={handleSetCustomSpeakerName}
            onKeyDownCapture={handleStopPropagination}
            onKeyDown={handleStopPropagination}
          />
          <Button
            color="primary"
            className={styles.inputBtn}
            disabled={isCreatingNewParticipant || !customSpeakerName}
            onClick={handleCreateCustomParticipant}
          >
            {isCreatingNewParticipant ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <span>Add</span>
            )}
          </Button>
        </Box>
      </div>
    </>
  );

  const renderApplySpeaker = (participant: GenericParticipant) => {
    const nextSpeakerName = participant.user?.fullName || participant.name;
    return (
      <>
        <Box position="relative" mx={2} py={1}>
          <IconButton
            size="small"
            className={clsx(styles.iconBtn, styles.backBtn)}
            onClick={handleCancelSubmission}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Typography variant="subtitle2" align="center">
            {participant.user?.fullName || participant.name}
          </Typography>
          <IconButton
            size="small"
            className={clsx(styles.iconBtn, styles.closeBtn)}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box fontSize={14} textAlign="center" p={2}>
          <Box mb={3}>
            <b>{speakerName}</b> will be replaced with <b>{nextSpeakerName}</b>
          </Box>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            className={styles.btn}
            onClick={handleReplaceSpeaker(participant, 'one')}
          >
            Replace for this item only
          </Button>
          <Box mb={1} />
          <Button
            fullWidth
            size="small"
            variant="outlined"
            className={styles.btn}
            onClick={handleReplaceSpeaker(participant, 'all')}
          >
            Replace everywhere
          </Button>
        </Box>
      </>
    );
  };
  /* #endregion */

  return (
    <Popover open classes={{ paper: styles.paper }} anchorEl={anchorEl} onClose={onClose}>
      <div className={styles.menu}>
        {showAddSpeakerForm ? (
          renderAddSpeaker()
        ) : (
          <>
            {selectedParticipant ? renderApplySpeaker(selectedParticipant) : renderChangeSpeaker()}
          </>
        )}
      </div>
    </Popover>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 24,
    height: 24,
    fontSize: '0.75rem',
    marginRight: theme.spacing(2),
  },
  menu: {
    padding: 0,
    minWidth: '40ch',
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
  },
  list: { maxHeight: '30vh', overflow: 'auto' },
  btn: {
    padding: theme.spacing(0.75),
  },
  iconBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  backBtn: {
    left: -9,
    '& svg': { width: 21, height: 21 },
  },
  closeBtn: {
    right: -9,
    '& svg': { width: 18, height: 18 },
  },
  input: {
    '& > div': { background: 'none' },
    '& fieldset': { border: 0 },
    '& input': { paddingLeft: 0 },
  },
  inputBtn: {
    minWidth: 0,
    '&:hover': {
      background: 'none',
    },
  },
}));

export default MeetingSpeakersMenu;
