import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { getCookie, setCookie } from 'typescript-cookie';
import { useLayoutEffect, useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { SearchBoxContainer, Switch } from '@sembly-ui';
// Icons
import MenuIcon from '@material-ui/icons/Menu';
import ShowEmptyMeetingsIcon from '@shared/icons/ShowEmptyMeetings';
// App Shared
import { AppBar } from '@shared/components';
import { AppBarActions } from '@shared/containers';
// import { ga } from '@shared/utils';
import { MainPageLayoutContext, useUserInterface, useUserContext } from '@shared/hooks';
import { Routes as R } from '@shared/enums';
import { APP_DRAWER_WIDTH } from '@shared/configuration';

export interface MainPageLayoutProps {
  color?: 'default' | 'paper';
  layout?: 'default' | 'wide';
}

export const MainPageLayout: React.FC<MainPageLayoutProps> = ({
  children,
  color = 'default',
  layout = 'default',
}) => {
  /* #region  Hooks */
  const { update } = useUserInterface();
  const [showNoContentMeetings, setShowNoContentMeetings] = useState(true);
  const user = useUserContext();

  // theme
  const styles = useStyles();
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  // router
  const history = useHistory();
  const { path } = useRouteMatch();
  /* #endregion */

  /* #region  Handlers */
  const handleRegisterSearchComplete = () => {
    // ga.event({ category: 'Search', action: 'Type Search Term' });
  };

  const handleRegisterLoadMoreResults = () => {
    // ga.event({ category: 'Search', action: 'Requested Next Search Page' });
  };

  const handleDrawerOpen = () => {
    update({ isAppDrawerOpen: true });
  };

  const handleJumpToMeeting = (meetingId: string) => {
    history.push(generatePath(R.Meeting, { meetingId }));
    // ga.event({ category: 'Search', action: 'Jump to Search Result' });
  };

  const handleChangeMeetingsVisibility = (e: unknown, checked: boolean) => {
    setShowNoContentMeetings(checked);
    setCookie('showNoContentMeeting', checked ? 'True' : 'False', {
      expires: new Date(2147483647 * 1000), // never expires
    });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const background = color === 'default' ? palette.background.default : palette.background.paper;
  const meetingsListRoutes = [R.Home, R.SharedWithMe] as string[];
  const isShowMeetingsFilter = meetingsListRoutes.includes(path);
  /* #endregion */

  useLayoutEffect(() => {
    const showNoContentMeeting = getCookie('showNoContentMeeting');
    if (showNoContentMeeting !== undefined) {
      setShowNoContentMeetings(showNoContentMeeting === 'True');
    }
  }, []);

  return (
    <div className={styles.root} style={{ background }}>
      {/* Begin: AppDrawer placeholder */}
      {/* AppDrawer currenty always open on desktops */}
      {!isSmallScreen && <Box flex="0 1 auto" width={APP_DRAWER_WIDTH} />}
      {/* End: AppDrawer placeholder */}
      <div className={styles.content}>
        <AppBar layout={layout}>
          {isSmallScreen && (
            <IconButton onClick={handleDrawerOpen} size="small" className={styles.menuIcon}>
              <MenuIcon />
            </IconButton>
          )}
          <div className={styles.grow}>
            {!isSmallScreen && (
              <SearchBoxContainer
                placeholder="Search meetings"
                isSmartMeetingUser={!!user.data?.me?.philipsDevices?.smartmeeting}
                onClickOnSearchResultCard={handleJumpToMeeting}
                onLoadMoreSearchResults={handleRegisterLoadMoreResults}
                onSearchComplete={handleRegisterSearchComplete}
              />
            )}
          </div>
          <div className={styles.nogrow}>
            <AppBarActions />
          </div>
          {isShowMeetingsFilter && (
            <Tooltip title="Show 'No content' meetings" placement="left">
              <FormControlLabel
                value="start"
                label={
                  <div className={styles.icon}>
                    <ShowEmptyMeetingsIcon color={showNoContentMeetings ? 'inherit' : 'disabled'} />
                  </div>
                }
                labelPlacement="start"
                className={styles.filters}
                control={
                  <Box display="flex" alignItems="center" pl={1}>
                    <Switch
                      size="small"
                      variant="colored"
                      checked={showNoContentMeetings}
                      onChange={handleChangeMeetingsVisibility}
                    />
                  </Box>
                }
              />
            </Tooltip>
          )}
        </AppBar>

        <div className={styles.appBarSpacer} />
        <MainPageLayoutContext.Provider value={{ showNoContentMeetings }}>
          {children}
        </MainPageLayoutContext.Provider>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  loading: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(1),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  grow: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
  },
  nogrow: {
    flexGrow: 0,
    paddingLeft: theme.spacing(2),
  },
  filters: {
    position: 'absolute',
    bottom: -90,
    right: 25,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    borderRadius: 18,
    background: theme.palette.background.paper,
    '& > div': {
      padding: 0,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.status.error.color,
  },
}));

export default MainPageLayout;
