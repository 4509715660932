export const ASSIGNMENTS_FILTER_TYPES: ReadonlyArray<{
  readonly value: string;
  readonly label: string;
}> = [
  { value: 'incomplete', label: 'Incomplete' },
  { value: 'completed', label: 'Completed' },
  { value: 'allCommitments', label: 'All items' },
];

export default ASSIGNMENTS_FILTER_TYPES;
