import { useRef } from 'react';
import { useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, alpha } from '@material-ui/core/styles';
// Material Icons
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
// Lib Shared
import announceMutation from '../graphql/mutations/AnnounceAgent.graphql';
import { AnnounceAgent, AnnounceAgentVariables, GraphError } from '../types';

export interface VerifyAgentProps {
  className?: string;
  meetingId: number;
  title?: string;
  onResponseError?: (error: GraphError) => void;
}

export const VerifyAgentContainer: React.VFC<VerifyAgentProps> = ({
  className = '',
  meetingId,
  title = 'Verify Sembly Agent',
  onResponseError = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const codeRef = useRef(Math.random().toString().substr(2, 5).split(''));

  const [announce, { data, loading }] = useMutation<AnnounceAgent, AnnounceAgentVariables>(
    announceMutation,
    { variables: { meetingId, code: parseInt(codeRef.current.join(''), 10) } },
  );
  /* #endregion */

  const handleAnnounce = async () => {
    const { data } = await announce();
    if (!data?.announceAgent?.success) {
      onResponseError(data?.announceAgent?.errors);
    }
  };

  const isAnnounced = data?.announceAgent?.success || false;

  return (
    <div className={className}>
      <Box my={3} textAlign="center">
        <Typography variant="h1">{title}</Typography>
      </Box>

      <Box maxWidth={400}>
        <Typography variant="h6" align="center">
          Sembly Agent should pronounce displayed numbers in your meeting
        </Typography>
      </Box>

      <Box
        mb={3}
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        maxWidth={400}
      >
        <Box display="flex" my={3}>
          {codeRef.current.map((num) => (
            <div key={num} className={styles.input}>
              {num}
            </div>
          ))}
        </Box>
        {isAnnounced ? (
          <div className={styles.success}>
            <Typography>
              Please wait, Sembly Agent will announce the number in a few seconds
            </Typography>
          </div>
        ) : (
          <Button
            disableElevation
            fullWidth
            color="primary"
            variant="contained"
            disabled={loading || isAnnounced}
            onClick={handleAnnounce}
          >
            <RecordVoiceOverIcon />
            <Box component="span" ml={1}>
              Announce
            </Box>
          </Button>
        )}
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    height: '3rem',
    width: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderRadius: '3em',
    [theme.breakpoints.down('sm')]: {
      height: '2rem',
      width: '2rem',
    },
  },
  success: {
    borderRadius: '1.5rem',
    padding: theme.spacing(2),
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    textAlign: 'center',
    maxWidth: 300,
  },
}));

export default VerifyAgentContainer;
