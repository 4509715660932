import { useDebouncedCallback } from 'use-debounce';
import { CircularProgress, Box, Fade } from '@material-ui/core';
import { RichTextEditor } from './RichTextEditor';

export interface NotesEditorProps {
  isLoading: boolean;
  isRTLDirected?: boolean;
  isUpdating: boolean;
  placeholder?: string;
  value: string | undefined;
  onFocus: () => void;
  onUpdateNote: (value: string) => void;
}

export const NotesEditor: React.VFC<NotesEditorProps> = ({
  isLoading,
  isRTLDirected = false,
  isUpdating,
  placeholder,
  value,
  onFocus,
  onUpdateNote,
}) => {
  const handleUpdateNote = useDebouncedCallback((value: string) => {
    onUpdateNote(value);
  }, 2000);

  return (
    <>
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Fade in style={{ transitionDelay: isLoading ? '1000ms' : '0ms' }} unmountOnExit>
            <CircularProgress size={24} />
          </Fade>
        </Box>
      ) : (
        <RichTextEditor
          reverseLayout
          hideSecondaryToolbarItems
          maxLength={150000}
          placeholder={placeholder}
          defaultValue={value}
          isSaving={isUpdating}
          onChange={handleUpdateNote}
          onFocus={onFocus}
        />
      )}
    </>
  );
};

export default NotesEditor;
