import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export interface SearchInputProps {
  className?: string;
  clearButton?: boolean | 'auto';
  debounceInterval?: number;
  hasNext?: boolean;
  hasPrev?: boolean;
  isClearble?: boolean;
  isFetching?: boolean;
  navButtons?: boolean;
  placeholder?: string;
  searchResultCount?: string;
  value?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
  onClick?: () => void;
  onClickOnNext?: () => void;
  onClickOnPrev?: () => void;
}

export const SearchInput: React.VFC<SearchInputProps> = ({
  className = '',
  clearButton = 'auto',
  debounceInterval = 1000,
  hasNext = false,
  hasPrev = false,
  isClearble = true,
  isFetching = false,
  navButtons = false,
  placeholder = 'Search',
  searchResultCount,
  value = '',
  onChange,
  onClear = () => null,
  onClick = () => null,
  onClickOnNext = () => null,
  onClickOnPrev = () => null,
}) => {
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState('');

  /* #region  Handlers */
  const handleSearchDebounced = useDebouncedCallback((value) => {
    const term = value ? value.toLowerCase() : '';
    onChange(term);
  }, debounceInterval);

  const handleChangeSearchTerm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let { value } = event.target;
    if (!value.trim().length) value = '';
    setCurrentValue(value);
    handleSearchDebounced(value);
  };

  const handleCleanSearchTerm = () => {
    onClear(); // trigger onClear
    setCurrentValue('');
    onChange(''); // immediate reset of search results
    handleSearchDebounced(''); // reset debounced value
  };
  /* #endregion */

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={`search-input ${className} ${styles.root}`}>
      <div className={styles.icon}>
        {isFetching ? <CircularProgress size={24} /> : <SearchIcon />}
      </div>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: styles.base,
          input: `${styles.input} ${isClearble && currentValue ? 'active' : ''}`,
        }}
        inputProps={{ 'aria-label': placeholder }}
        value={currentValue}
        onChange={handleChangeSearchTerm}
        onClick={onClick}
      />
      {!!searchResultCount && (
        <Typography component="span" variant="body1" className={styles.addon}>
          {searchResultCount}
        </Typography>
      )}
      {navButtons && (
        <>
          <IconButton
            className={styles.addon}
            color="inherit"
            disabled={!hasPrev}
            edge="end"
            onClick={onClickOnPrev}
            size="small"
            title="Previous"
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            className={styles.addon}
            color="inherit"
            disabled={!hasNext}
            edge="end"
            onClick={onClickOnNext}
            size="small"
            title="Next"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </>
      )}
      {isClearble && (!!currentValue || clearButton === true) && (
        <IconButton
          size="small"
          edge="end"
          className={styles.addon}
          color="inherit"
          title="Close find bar"
          onClick={handleCleanSearchTerm}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    paddingRight: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '2em',
    color: theme.palette.text.primary,
    display: 'flex',
    position: 'relative',
    height: 40,
    width: '100%',
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
    position: 'absolute',
  },
  addon: {
    flex: 0,
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(1 / 2),
  },
  base: {
    color: 'inherit',
    flex: 1,
    fontSize: 'inherit',
    width: '100%',
  },
  input: {
    fontSize: 'inherit',
    height: 'auto',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`, // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(5)}px)`, // vertical padding + font size from searchIcon
    width: '100%',
  },
}));

export default SearchInput;
