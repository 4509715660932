import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { useState } from 'react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/SearchSharp';
// Lib Queries and Types
import query from '../graphql/queries/MyChatsContainerQuery.graphql';
import myPinnedChatsQuery from '../graphql/queries/MyPinnedChats.graphql';
import deleteMutation from '../graphql/mutations/DeleteChat.graphql';
import editMutation from '../graphql/mutations/EditChat.graphql';
import manageMutation from '../graphql/mutations/ManageChat.graphql';
import createChatMutation from '../graphql/mutations/CreateAssociatesChat.graphql';
import {
  CreateAssociatesChat,
  CreateAssociatesChatVariables,
  DeleteChat,
  DeleteChatVariables,
  EditChat,
  EditChatVariables,
  GenericChat,
  GenericChatArtifact,
  GraphError,
  ManageChat,
  ManageChatVariables,
  MyChatsContainerQuery,
  MyChatsContainerQueryVariables,
  MyPinnedChats,
  MyPinnedChatsVariables,
} from '../types';
// Lib Shared
import { formatInTimeZone } from '../utils';
import { ChatCard, ChatCardMenuDesktop } from '../components';
import { ChatTextArtefactViewer, RenameModalDialog } from '../dialogs';
import chatImage from '../assets/icon-chat-64.svg';

export interface MyChatsContainerProps {
  onCreatedChat: (chatId: string) => void;
  onSelectChat: (chatId: string) => void;
  onRequestCreateChat: () => boolean;
  onResponseError?: (error: GraphError) => void;
}

/**
 * The container component that renders the meeting insights
 */
export const MyChatsContainer: React.VFC<MyChatsContainerProps> = ({
  onRequestCreateChat,
  onCreatedChat,
  onSelectChat,
  onResponseError = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchTerm, setSearchTerm] = useState('');
  const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
  const [editingChat, setEditingChat] = useState<null | GenericChat>(null);
  const [selectedArtifact, setSelectedArtifact] = useState<GenericChatArtifact | null>(null);
  const [selectedChat, setSelectedChat] = useState<null | {
    chat: GenericChat;
    element: HTMLElement;
  }>(null);

  const { data, fetchMore, refetch, networkStatus } = useQuery<
    MyChatsContainerQuery,
    MyChatsContainerQueryVariables
  >(query, {
    variables: { page: 1, perPage: 10 },
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: pinnedChatsData,
    networkStatus: pinnedChatsNetworkStatus,
    refetch: refetchPinnedChats,
  } = useQuery<MyPinnedChats, MyPinnedChatsVariables>(myPinnedChatsQuery, {
    variables: { search: '' },
    notifyOnNetworkStatusChange: true,
  });

  const [createChat, { loading: isCreatingChat }] = useMutation<
    CreateAssociatesChat,
    CreateAssociatesChatVariables
  >(createChatMutation);

  const [deleteChat] = useMutation<DeleteChat, DeleteChatVariables>(deleteMutation);
  const [editChat] = useMutation<EditChat, EditChatVariables>(editMutation);

  const [manageChat] = useMutation<ManageChat, ManageChatVariables>(manageMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleSearchDebounced = useDebouncedCallback((value) => {
    const term = value ? value.toLowerCase() : '';
    refetch({ search: term, page: 1, perPage: 10 });
    refetchPinnedChats({ search: term });
  }, 1000);

  const handleUpdateSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchDebounced(value);
  };

  const handleClearSearchTerm = () => {
    setSearchTerm('');
    refetch({ search: '', page: 1, perPage: 10 });
    refetchPinnedChats({ search: '' });
  };

  const handleSelectChat = (chatId: string) => () => {
    onSelectChat(chatId);
  };

  const handleFetchMoreChats = () => {
    const currentPage = data?.chatsPaginated?.page ?? 1;
    fetchMore({
      variables: { page: currentPage + 1, perPage: 10 },
    });
  };

  const handleOpenMenu = (chat: GenericChat) => (anchorEl: HTMLElement) => {
    setSelectedChat({ chat, element: anchorEl });
  };

  const handleClickOnRenameChat = (chat: GenericChat) => () => {
    setEditingChat(chat);
    setSelectedChat(null);
  };

  const handleChangeChatName = (chat: GenericChat) => async (newName: string) => {
    const response = await editChat({
      variables: { chatId: +chat.id, name: newName },
      optimisticResponse: {
        editChat: {
          __typename: 'EditChatMutationPayload',
          success: true,
          errors: [],
          chat: {
            ...chat,
            name: newName,
          },
        },
      },
    });

    if (response.data?.editChat?.success) {
      setEditingChat(null);
    } else {
      onResponseError(response.data?.editChat?.errors);
    }
  };

  const handleDeleteChat = (chat: GenericChat) => async () => {
    setSelectedChat(null);

    const response = await deleteChat({
      variables: { chatId: +chat.id },
      optimisticResponse: {
        deleteChat: {
          __typename: 'DeleteChatMutationPayload',
          success: true,
          errors: [],
        },
      },
      update: (cache) => {
        const id = cache.identify({ __typename: 'ChatType', id: chat.id });
        cache.evict({ id });
        cache.gc();
      },
    });

    if (!response.data?.deleteChat?.success) {
      onResponseError(response.data?.deleteChat?.errors);
    }
  };

  const handleToggleSection = (date: string) => () => {
    setCollapsedSections((sections) =>
      sections.includes(date)
        ? sections.filter((section) => section !== date)
        : [...sections, date],
    );
  };

  const handlePinChat = (chat: GenericChat) => async () => {
    const chatId = chat.id;
    const isPinned = !chat.isPinned;

    const result = await manageChat({
      variables: { id: +chatId, isPinned },
      update: (cache) => {
        // Update the cache to reflect the new pinned state
        cache.modify({
          id: cache.identify({ __typename: 'ChatType', id: chatId }),
          fields: {
            isPinned() {
              return isPinned;
            },
          },
        });

        if (!isPinned) {
          // Remove the pinned chat to the cache
          cache.updateQuery<MyPinnedChats, MyPinnedChatsVariables>(
            { query: myPinnedChatsQuery, variables: { search: searchTerm } },
            (data) => {
              if (!data?.pinnedChats) return data;
              return {
                ...data,
                pinnedChats: data?.pinnedChats.filter((item) => item.id !== chatId),
              };
            },
          );
        } else {
          // Add pinned chat to the cache
          cache.updateQuery<MyPinnedChats, MyPinnedChatsVariables>(
            { query: myPinnedChatsQuery, variables: { search: searchTerm } },
            (data) => {
              if (!data) return data;
              return {
                ...data,
                pinnedChats: uniqBy(
                  [{ ...chat, isPinned: !chat.isPinned }, ...(data.pinnedChats ?? [])],
                  'id',
                ),
              };
            },
          );
        }
      },
    });

    if (!result.data?.manageChatState?.success) {
      onResponseError(result?.data?.manageChatState?.errors);
    }
  };

  const handleHideMenu = () => {
    setSelectedChat(null);
  };

  const handleCloseArtifactViewer = () => {
    setSelectedArtifact(null);
  };

  const handleShowArtifactViewer = (data: GenericChatArtifact) => {
    setSelectedArtifact(data);
  };

  const handleCreateChat = async (
    workstreamId: string | null = null,
    workstreamName: string | null = null,
  ) => {
    const currentDate = formatInTimeZone(new Date(), 'PP p');
    const name = workstreamName
      ? `${workstreamName} | ${currentDate}`
      : `New Chat | ${currentDate}`;
    const result = await createChat({
      variables: { workstreamId, name },
      update: (cache) => {
        // Remove the chatsPaginated cache to force a refetch
        cache.evict({ fieldName: 'chatsPaginated' });
      },
    });

    if (!!result.data?.createChat?.chat?.id) {
      onCreatedChat(result.data.createChat.chat.id);
    } else {
      onResponseError(result?.data?.createChat?.errors);
    }
  };

  const handleClickOnCreateChat = () => {
    if (isCreatingChat) return;
    if (!onRequestCreateChat()) return;
    handleCreateChat();
  };
  /* #endregion */

  /* #region  Render Helpers */
  const hasChats = !!data?.chatsPaginated?.objects?.length;
  const chats = data?.chatsPaginated?.objects || [];
  const hasMore = data?.chatsPaginated?.hasNext ?? false;
  const isRefetchingPinnedChats = pinnedChatsNetworkStatus === NetworkStatus.refetch;
  const isSetVariablesPinnedChats = pinnedChatsNetworkStatus === NetworkStatus.setVariables;
  const isInitialLoading = networkStatus === NetworkStatus.loading;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const isRefetching = isRefetchingPinnedChats || networkStatus === NetworkStatus.refetch;
  const isSetVariables = isSetVariablesPinnedChats || networkStatus === NetworkStatus.setVariables;

  // Group the chats by date (Today, Yesterday, Older)
  const groupedChats = groupBy(chats, (chat) => {
    let result = 'Older';

    const chatDate = new Date(chat.createdAt);
    const today = new Date();
    const yesterday = new Date(today);

    yesterday.setDate(today.getDate() - 1);

    if (chatDate.toDateString() === today.toDateString()) {
      result = 'Today';
    } else if (chatDate.toDateString() === yesterday.toDateString()) {
      result = 'Yesterday';
    }

    return result;
  });

  // Sort the grouped chats exactly in the order: Today, Yesterday, Older
  const sortedChatGroups = Object.entries(groupedChats).sort(([a], [b]) => {
    if (a === 'Today') return -1;
    if (b === 'Today') return 1;
    if (a === 'Yesterday') return -1;
    if (b === 'Yesterday') return 1;
    return 0;
  });

  const sortedGroupedChats = {
    ...(pinnedChatsData?.pinnedChats?.length ? { Pinned: pinnedChatsData.pinnedChats } : {}),
    ...Object.fromEntries(sortedChatGroups),
  };
  /* #endregion */

  // Render loading indicator if the data is not ready
  if (isInitialLoading) {
    return (
      <Box mt={8}>
        <List>
          {[1, 2, 3, 4, 5].map((key) => (
            <ListItem key={key} className={styles.item}>
              <ListItemText primary={<Skeleton width={Math.random() * 100 + 200} />} />
              <ListItemSecondaryAction>
                <Skeleton variant="circle" width={24} height={24} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  }

  return (
    <>
      <Box mt={2}>
        <div className={styles.head}>
          <TextField
            fullWidth
            size="small"
            variant="filled"
            placeholder="Search chats..."
            value={searchTerm}
            onChange={handleUpdateSearchTerm}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    {isSetVariables ? (
                      <CircularProgress size={18} />
                    ) : (
                      <>
                        {!!searchTerm && (
                          <IconButton size="small" onClick={handleClearSearchTerm}>
                            <CloseIcon color="primary" />
                          </IconButton>
                        )}
                      </>
                    )}
                  </>
                </InputAdornment>
              ),
            }}
          />
        </div>

        {isRefetching || isSetVariables ? (
          <List>
            {[1, 2, 3, 4, 5].map((key) => (
              <ListItem key={key} className={styles.item}>
                <ListItemText primary={<Skeleton width={Math.random() * 100 + 200} />} />
                <ListItemSecondaryAction>
                  <Skeleton variant="circle" width={24} height={24} />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <>
            {!isRefetching && !isSetVariables && hasChats ? (
              <div onMouseLeave={handleHideMenu}>
                {Object.entries(sortedGroupedChats).map(([date, chats]) => {
                  const isExpanded = !collapsedSections.includes(date);

                  return (
                    <div key={date} className={styles.list}>
                      <div className={styles.subheader} onClick={handleToggleSection(date)}>
                        <ExpandMoreIcon
                          className={clsx(styles.expandIcon, !isExpanded && styles.expanded)}
                        />
                        {date}
                        <Chip
                          size="small"
                          color="primary"
                          label={chats.length}
                          className={styles.chip}
                        />
                      </div>

                      <Collapse in={isExpanded}>
                        {chats.map((chat) => (
                          <ChatCard
                            key={chat.id}
                            title={chat.name}
                            description={chat.lastInsightName}
                            artifacts={chat.recentArtifacts || []}
                            numArtifacts={chat.numArtifacts}
                            onClick={handleSelectChat(chat.id)}
                            onClickOnArtifact={handleShowArtifactViewer}
                            onToggleMenu={handleOpenMenu(chat)}
                          />
                        ))}
                      </Collapse>
                    </div>
                  );
                })}

                {isFetchingMore && (
                  <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress size={18} />
                  </Box>
                )}

                {hasMore && !isFetchingMore && (
                  <Box display="flex" justifyContent="center" my={4}>
                    <Button disableElevation variant="outlined" onClick={handleFetchMoreChats}>
                      <Typography
                        noWrap
                        component="span"
                        variant={isSmallScreen ? 'body2' : 'body1'}
                      >
                        Load more
                      </Typography>
                    </Button>
                  </Box>
                )}

                {!!selectedChat && (
                  <ChatCardMenuDesktop
                    editable
                    allowPinning
                    pinned={selectedChat.chat.isPinned}
                    anchorEl={selectedChat.element}
                    onClose={() => setSelectedChat(null)}
                    onClickOnDelete={handleDeleteChat(selectedChat.chat)}
                    onClickOnEdit={handleClickOnRenameChat(selectedChat.chat)}
                    onClickOnPin={handlePinChat(selectedChat.chat)}
                  />
                )}
              </div>
            ) : (
              <Box mt={6} textAlign="center">
                {!searchTerm ? (
                  <Box mt={8}>
                    <Box mb={3}>
                      <img src={chatImage} alt="Chat" />
                    </Box>
                    <Box mb={5}>
                      <Typography gutterBottom variant="h2" color="textPrimary">
                        No Chats
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        Start a new conversation and keep track of your chats here.
                      </Typography>
                    </Box>
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      aria-label="Create Chat"
                      onClick={handleClickOnCreateChat}
                      disabled={isCreatingChat}
                      endIcon={isCreatingChat && <CircularProgress size={18} color="inherit" />}
                    >
                      <Typography noWrap component="span" variant="body1">
                        Create New Chat
                      </Typography>
                    </Button>
                  </Box>
                ) : (
                  <Typography paragraph variant="body1" color="textPrimary">
                    No matching chats found
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      {!!editingChat && (
        <RenameModalDialog
          title="Rename Chat"
          defaultValue={editingChat.name}
          onClose={() => setEditingChat(null)}
          onSubmit={handleChangeChatName(editingChat)}
        />
      )}
      {!!selectedArtifact?.text && (
        <ChatTextArtefactViewer
          id={selectedArtifact.id}
          title={selectedArtifact.name}
          data={selectedArtifact.text}
          onClose={handleCloseArtifactViewer}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  head: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 0),
    },
  },
  list: {
    position: 'relative',
    padding: theme.spacing(0, 2, 1),
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius * 2,
  },
  item: {
    marginBottom: theme.spacing(1),
    background: theme.palette.action.selected,
    borderRadius: theme.shape.borderRadius * 2,
  },
  chip: {
    minWidth: 30,
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.grey[100],
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    marginLeft: `-${theme.spacing(2)}px`,
    marginRight: `-${theme.spacing(2)}px`,
    cursor: 'pointer',
  },
  expandIcon: {
    position: 'relative',
    transition: 'transform 0.25s cubic-bezier(0.52, 0.16, 0.24, 1)',
    left: -2,
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
}));

export default MyChatsContainer;
