/**
 * Extracts the path corresponding to the parent node for a given path.
 * @returns {string | null} - The original path minus the last individual
 * segment found in it. Null if the original path corresponds to a top level node.
 */

export type DataType = {
  pathname?: string;
  subroutesNumToCut?: number;
};

export type GetParentPathnameType = (data?: DataType) => string | null;

export const getParentPathname: GetParentPathnameType = (data = {}) => {
  const { pathname = window.location.pathname, subroutesNumToCut = 1 } = data;
  const segments = pathname.split('/');
  if (segments.length < 2) return null;
  return segments.slice(0, -subroutesNumToCut).join('/');
};

export default getParentPathname;
