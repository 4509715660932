// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// Shared
import { GenericDialog } from '@sembly-ui';

export interface ConfirmStopRecordingProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmStopRecording: React.VFC<ConfirmStopRecordingProps> = ({
  open,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  /* #region  Handlers */
  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={onCancel}
    >
      <Box component={DialogContent} mt={1} mb={4}>
        <Box my={3} textAlign="center">
          <Typography paragraph variant="h5" color="primary">
            Do you want to save this recording?
          </Typography>
          <Typography variant="body1">
            If you delete a recording, it will not be possible to restore it.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={1}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              aria-label="Cancel delete recording"
              onClick={handleConfirm}
            >
              Save
            </Button>
          </Box>
          <Button variant="outlined" aria-label="Confirm delete recording" onClick={handleCancel}>
            Delete
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default ConfirmStopRecording;
