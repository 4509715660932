import { useState, useMemo, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'history';
import { useQuery, useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
// Lib Queries
import meetingQuery from '../graphql/queries/Meeting.graphql';
import bookmarksQuery from '../graphql/queries/MeetingBookmarksContainerQuery.graphql';
import meetingDiarizationQuery from '../graphql/queries/MeetingDiarization.graphql';
// Lib Mutations
import createBookmarkMutation from '../graphql/mutations/CreateBookmark.graphql';
import createParticipantMutation from '../graphql/mutations/CreateParticipant.graphql';
import deleteBookmarkMutation from '../graphql/mutations/DeleteBookmark.graphql';
import deleteMutation from '../graphql/mutations/DeleteDiarizationItem.graphql';
import splitTranscriptionItemMutation from '../graphql/mutations/SplitDiarizationItem.graphql';
import updateMutation from '../graphql/mutations/EditDiarizationItem.graphql';
import updateParticipantMutation from '../graphql/mutations/EditParticipant.graphql';
// Lib Types
import {
  AgentCallPlatform,
  CreateBookmark,
  CreateBookmarkVariables,
  CreateParticipant,
  CreateParticipantVariables,
  DeleteBookmark,
  DeleteBookmarkVariables,
  DeleteDiarizationItem,
  DeleteDiarizationItemVariables,
  DiarizationItem,
  DiarizationItemSplitType,
  EditDiarizationItem,
  EditDiarizationItemVariables,
  EditParticipant,
  EditParticipantVariables,
  GenericDiarizationItem,
  GenericParticipant,
  GraphError,
  Meeting,
  MeetingStatuses,
  MeetingDiarization,
  MeetingDiarizationVariables,
  MeetingVariables,
  RedirectTarget,
  SplitDiarizationItem,
  SplitDiarizationItemVariables,
} from '../types';
// Lib
import SplitTranscriptionItemDialog from '../dialogs/SplitTranscriptionItemDialog';
import CrossTalkItemWrapper from '../components/CrossTalkItemWrapper';
import MeetingSpeakersMenu from '../components/MeetingSpeakersMenu';
import MeetingPlaceholderTranscript from '../components/MeetingPlaceholderTranscript';
import MeetingPlaceholderProcessing from '../components/MeetingPlaceholderProcessing';
import TranscriptionItem from '../components/TranscriptionItem';
import MeetingTranscriptView from '../components/MeetingTranscriptView';
import { usePlayer } from '../hooks';

/* #region  Types */
interface MenuState {
  anchorEl: HTMLElement;
  transcriptionItem: DiarizationItem;
}

interface GenericKeyValueStorage<T = string> {
  [id: string]: T;
}

export interface MeetingTranscriptContainerProps {
  isPromoteUpgrade?: boolean;
  isRestrictedKeyItemsPromotion: boolean;
  meetingId: string;
  search: Search;
  onAddedBookmark?: () => void;
  onChangedFilterValue?: () => void;
  onClickOnCopyTranscriptionItemLink: (transcriptionItemId: string) => void;
  onClickOnUpgradePlan?: () => void;
  onDeletedBookmark?: () => void;
  onDeletedTranscriptItem?: () => void;
  onJumpedToTimestamp?: () => void;
  onRedirect: (target: RedirectTarget) => void;
  onResponseError?: (error: GraphError) => void;
  onUpdatedTranscriptItem?: () => void;
  onUpdatedTranscriptItemParticipant?: () => void;
}
/* #endregion */

export const MeetingTranscriptContainer: React.VFC<MeetingTranscriptContainerProps> = ({
  isPromoteUpgrade = false,
  isRestrictedKeyItemsPromotion,
  meetingId,
  search,
  onAddedBookmark = () => null,
  onChangedFilterValue = () => null,
  onClickOnCopyTranscriptionItemLink,
  onClickOnUpgradePlan = () => null,
  onDeletedBookmark = () => null,
  onDeletedTranscriptItem = () => null,
  onJumpedToTimestamp = () => null,
  onRedirect,
  onResponseError = () => null,
  onUpdatedTranscriptItem = () => null,
  onUpdatedTranscriptItemParticipant = () => null,
}) => {
  /* #region  Hooks */
  const diarizationPlayer = usePlayer();

  const [content, setContent] = useState<GenericKeyValueStorage<string>>({});
  const [filter, setFilter] = useState<GenericKeyValueStorage<boolean>>({});
  const [filterMenuEl, setFilterMenuEl] = useState<null | HTMLButtonElement>(null);
  const [mainMenuData, setMainMenuData] = useState<null | MenuState>(null);
  const [searchTerm, setSearchTerm] = useState<{ value: string; itemId: string } | null>(null);
  const [speakerMenuData, setSpeakerMenuData] = useState<null | MenuState>(null);
  const [splitDialogData, setSplitDialogData] = useState<null | DiarizationItem>(null);
  const [isRefetchingDiarization, setIsRefetchingDiarization] = useState(false);
  /* #endregion */

  /* #region  Apollo Hooks */
  const {
    data: diarizationData,
    loading: isDiarizationLoading,
    refetch: refetchDiarization,
  } = useQuery<MeetingDiarization, MeetingDiarizationVariables>(meetingDiarizationQuery, {
    variables: { meetingId },
  });

  const { data: meetingData, loading: isMeetingLoading } = useQuery<Meeting, MeetingVariables>(
    meetingQuery,
    {
      variables: { meetingId },
      onCompleted: async (data) => {
        if (
          data?.meeting?.processingResults?.processedTranscribing &&
          !diarizationData?.diarizationItems?.length &&
          !isDiarizationLoading
        ) {
          // Refetching the diarization when the meeting is completly processed
          setIsRefetchingDiarization(true);
          await refetchDiarization();
          setIsRefetchingDiarization(false);
        }
      },
    },
  );

  const [deleteTranscriptionItem] = useMutation<
    DeleteDiarizationItem,
    DeleteDiarizationItemVariables
  >(deleteMutation);

  const [updateTranscriptionItem] = useMutation<EditDiarizationItem, EditDiarizationItemVariables>(
    updateMutation,
  );

  const [createParticipant] = useMutation<CreateParticipant, CreateParticipantVariables>(
    createParticipantMutation,
  );

  const [updateParticipant] = useMutation<EditParticipant, EditParticipantVariables>(
    updateParticipantMutation,
  );

  const [splitTranscriptionItem] = useMutation<SplitDiarizationItem, SplitDiarizationItemVariables>(
    splitTranscriptionItemMutation,
    { refetchQueries: [{ query: meetingDiarizationQuery, variables: { meetingId } }] },
  );

  const [createBookmark, { loading: createBookmarkLoading }] = useMutation<
    CreateBookmark,
    CreateBookmarkVariables
  >(createBookmarkMutation, {
    refetchQueries: [{ query: bookmarksQuery, variables: { meetingId } }],
  });

  const [deleteBookmark, { loading: deleteBookmarkLoading }] = useMutation<
    DeleteBookmark,
    DeleteBookmarkVariables
  >(deleteBookmarkMutation);

  const editBookmarkLoading = createBookmarkLoading || deleteBookmarkLoading;
  const isAuthorizedToEdit = meetingData?.meeting?.permissions.canManage || false;
  /* #endregion */

  const updateDebounced = useDebouncedCallback((item: DiarizationItem) => {
    handleUpdateTranscriptionItem(item);
  }, 2000);

  /* #region  Handlers */
  const handleChangeContent = useCallback(
    (item: DiarizationItem) => (value: string) => {
      if (!isAuthorizedToEdit) return;
      setContent((state) => ({ ...state, [item.id]: value }));
      // update if valid
      if (!!value) updateDebounced({ ...item, word: value });
    },
    [isAuthorizedToEdit, updateDebounced],
  );

  const handleJumpToTime = useCallback(
    (time: number) => {
      if (!diarizationPlayer?.audio.current) return;
      diarizationPlayer.audio.current.currentTime = time;
      diarizationPlayer.audio.current.play();
      onJumpedToTimestamp();
    },
    [onJumpedToTimestamp, diarizationPlayer?.audio],
  );

  const handleChangeSearchTerm = (value: string | null) => {
    const newSearchTerm = value ? { value, itemId: '' } : null;
    setSearchTerm(newSearchTerm);
  };

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((state) => ({ ...state, [e.target.name]: !e.target.checked }));
    onChangedFilterValue();
  };

  const handleOpenFilterMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterMenuEl(e.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setFilterMenuEl(null);
  };

  const handleOpenItemMenu = (transitionItem: DiarizationItem) => (anchorEl: HTMLButtonElement) => {
    setMainMenuData({ transcriptionItem: transitionItem, anchorEl });
  };

  const handleCloseItemMenu = () => {
    setMainMenuData(null);
  };

  const handleOpenSpeakersMenu = (item: DiarizationItem) => (anchorEl: HTMLButtonElement) => {
    setSpeakerMenuData({ transcriptionItem: item, anchorEl });
  };

  const handleCloseSpeakersMenu = () => {
    setSpeakerMenuData(null);
  };

  const handleReplaceSpeaker =
    (transcriptionItem: DiarizationItem) =>
    (targetParticipant: GenericParticipant, type: 'one' | 'all') => {
      handleCloseSpeakersMenu();
      if (type === 'one') {
        handleUpdateTranscriptionItem({
          ...transcriptionItem,
          participant: targetParticipant,
        });
      } else if (type === 'all') {
        const replacingParticipantId = transcriptionItem.participant.id;
        handleUpdateParticipant(replacingParticipantId, targetParticipant);
      }
    };

  const handleOpenSplitDialog = (item: DiarizationItem) => () => {
    setSplitDialogData(item);
  };

  const handleCloseSplitDialog = () => {
    setSplitDialogData(null);
  };

  const handleDeleteTranscriptionItem = async () => {
    handleCloseItemMenu();

    if (!isAuthorizedToEdit || !mainMenuData) {
      throw new Error('Not authorized to delete');
    }

    const id = mainMenuData.transcriptionItem.id;
    const result = await deleteTranscriptionItem({
      variables: { id: +id },
      optimisticResponse: {
        deleteDiarizationItem: {
          __typename: 'DeleteDiarizationItemMutationPayload',
          success: true,
          errors: [],
        },
      },
      update: (cache) => {
        cache.evict({ id: cache.identify({ __typename: 'DiarizationItemType', id }) });
        cache.gc();
      },
    });

    if (result?.data?.deleteDiarizationItem?.success) {
      onDeletedTranscriptItem();
    } else {
      onResponseError(result?.data?.deleteDiarizationItem?.errors);
    }
  };

  const handleUpdateTranscriptionItem = async (diarizationItem: DiarizationItem) => {
    const { data: responseData } = await updateTranscriptionItem({
      variables: {
        diarizationItemId: +diarizationItem.id,
        participantId: diarizationItem.participant.id,
        word: diarizationItem.word,
      },
      optimisticResponse: {
        editDiarizationItem: {
          __typename: 'EditDiarizationItemMutationPayload',
          success: true,
          errors: [],
          diarizationItem: {
            ...diarizationItem,
            secondaryCrossTalkItems: diarizationItem.secondaryCrossTalkItems || [],
          },
        },
      },
    });

    if (responseData?.editDiarizationItem?.success) {
      onUpdatedTranscriptItem();
    } else {
      onResponseError(responseData?.editDiarizationItem?.errors);
    }
  };

  const handleAddSpeaker = async (name: string, userId: string | null) => {
    const responseData = await createParticipant({
      variables: { meetingId, name, userId },
      refetchQueries: [{ query: meetingQuery, variables: { meetingId } }],
    });

    if (!responseData?.data?.createParticipant?.success) {
      onResponseError(responseData?.data?.createParticipant?.errors);
    }

    return responseData?.data?.createParticipant?.participant || null;
  };

  const handleUpdateParticipant = async (
    replacingParticipantId: string,
    targetParticipant: GenericParticipant,
  ) => {
    const { data } = await updateParticipant({
      variables: {
        participantId: +replacingParticipantId,
        participantName: targetParticipant.name,
        participantUserId: targetParticipant.user?.id,
      },
      optimisticResponse: {
        editParticipant: {
          __typename: 'EditParticipantPayload',
          success: true,
          errors: [],
          participant: targetParticipant,
        },
      },
      refetchQueries: [{ query: meetingQuery, variables: { meetingId } }],
    });

    if (data?.editParticipant?.success) {
      onUpdatedTranscriptItemParticipant();
    } else {
      onResponseError(data?.editParticipant?.errors);
    }
  };

  const handleAddBookmark = useCallback(
    (transitionItem: DiarizationItem) => async () => {
      const diarizationItemId = transitionItem.id;
      const { data } = await createBookmark({
        variables: { diarizationItemId, meetingId },
        optimisticResponse: {
          createBookmark: {
            __typename: 'CreateBookmarkMutationPayload',
            success: true,
            errors: [],
            bookmark: {
              __typename: 'BookmarkType',
              id: new Date().valueOf().toString(),
              diarizationItem: transitionItem as GenericDiarizationItem,
              meeting: { __typename: 'DefaultMeetingType', id: meetingId },
            },
          },
        },
      });
      if (data?.createBookmark?.success) {
        onAddedBookmark();
      } else {
        onResponseError(data?.createBookmark?.errors);
      }
    },
    [meetingId, createBookmark, onAddedBookmark, onResponseError],
  );

  const handleDeleteBookmark = useCallback(
    async (id: string, diarizationItemId: string) => {
      const bookmarkId = parseInt(id, 10);
      const { data } = await deleteBookmark({
        variables: { bookmarkId },
        optimisticResponse: {
          deleteBookmark: {
            __typename: 'DeleteBookmarkMutationPayload',
            success: true,
            errors: [],
          },
        },
        update: (cache) => {
          cache.modify({
            id: cache.identify({ __typename: 'DiarizationItemType', id: diarizationItemId }),
            fields: {
              bookmark() {
                return null;
              },
            },
          });
          cache.evict({ id: cache.identify({ __typename: 'BookmarkType', id: bookmarkId }) });
          cache.gc();
        },
      });

      if (data?.deleteBookmark?.success) {
        onDeletedBookmark();
      } else {
        onResponseError(data?.deleteBookmark?.errors);
      }
    },
    [deleteBookmark, onDeletedBookmark, onResponseError],
  );

  const handleSplitTranscriptionItem =
    (diarizationItem: DiarizationItem) => async (splittedItems: DiarizationItemSplitType[]) => {
      const response = await splitTranscriptionItem({
        variables: {
          diarizationItemId: +diarizationItem.id,
          utterances: splittedItems,
        },
      });

      if (!response?.data?.splitDiarizationItem?.success) {
        onResponseError(response?.data?.splitDiarizationItem?.errors);
      }
    };
  /* #endregion */

  /* #region  Render Helpers */
  const meeting = meetingData?.meeting;
  const agentCall = meeting?.agentCall;
  const diarizationItems = diarizationData?.diarizationItems;
  const hasDiarizationItems = !!diarizationItems && diarizationItems.length > 0;
  const isFiltered = Object.values(filter).includes(true);
  const isTranscribed = meeting?.processingResults?.processedTranscribing ?? false;
  const isProcessedAssignments = meeting?.processingResults?.processedAssignments ?? false;
  const isProcessedAnalytics = meeting?.processingResults?.processedAnalytics ?? false;
  const isProcessedNotes = meeting?.processingResults?.processedMeetingNotes ?? false;
  const isProcessingComplete = meeting?.status !== MeetingStatuses.processing;
  const isProcessed = isProcessingComplete || isTranscribed;
  const isManualUpload = agentCall?.platform === AgentCallPlatform.MANUAL_UPLOAD;
  /* #endregion */

  /* #region  Memorized  Data */
  const renderTranscriptionItem = useCallback(
    (item: DiarizationItem) => {
      const transcriptionItem = {
        id: item.id,
        name: item.participant.name,
        startTime: item.startTime,
        transcriptionItemBookmarkId: item.bookmark?.id || null,
        userAvatar: item.participant.user?.avatar || null,
        userFullName: item?.participant.user?.fullName || null,
        word: item.word,
      };

      return (
        <TranscriptionItem
          key={item.id}
          content={content[item.id]}
          isAuthorizedToEdit={isAuthorizedToEdit}
          isBookmarkable={isAuthorizedToEdit}
          isBookmarked={!!item.bookmark}
          isEditing={editBookmarkLoading}
          searchTerm={searchTerm}
          transcriptionItem={transcriptionItem}
          onChangeContent={handleChangeContent(item)}
          onClickOnActionsMenu={handleOpenItemMenu(item)}
          onClickOnAddBookmark={handleAddBookmark(item)}
          onClickOnCopyLink={onClickOnCopyTranscriptionItemLink}
          onClickOnDelete={handleDeleteBookmark}
          onClickOnParticipant={handleOpenSpeakersMenu(item)}
          onClickOnSplit={handleOpenSplitDialog(item)}
          onClickOnTimestamp={handleJumpToTime}
        >
          {!!item.secondaryCrossTalkItems?.length && (
            <CrossTalkItemWrapper>
              {item.secondaryCrossTalkItems.map((crossTalkItem) =>
                renderTranscriptionItem(crossTalkItem),
              )}
            </CrossTalkItemWrapper>
          )}
        </TranscriptionItem>
      );
    },
    // prettier-ignore
    [
      content, searchTerm, editBookmarkLoading, isAuthorizedToEdit,
      handleChangeContent, handleJumpToTime, handleAddBookmark, handleDeleteBookmark,
      onClickOnCopyTranscriptionItemLink,
    ],
  );

  const renderTranscriptionItems = useMemo(
    () => (
      <List>
        {diarizationItems?.map((item) => {
          return filter && filter[item.participant.id] ? null : renderTranscriptionItem(item);
        })}
      </List>
    ),
    [diarizationItems, filter, renderTranscriptionItem],
  );
  /* #endregion */

  /* #region  Props Validation */
  if (isPromoteUpgrade && !onClickOnUpgradePlan) {
    throw new Error('If promotion upgrade is true, handler is required');
  }
  /* #endregion */

  if (isMeetingLoading || isDiarizationLoading || isRefetchingDiarization) {
    return (
      <Box flex={1} width="100%" mt={5}>
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (!meeting) return null;

  /* #region  Render Placeholder */
  if (!hasDiarizationItems) {
    return isProcessed ? (
      // Show "No transcript" placeholder if meeting is processed but has no diarization items
      <MeetingPlaceholderTranscript
        failureReason={agentCall?.failureReason || null}
        isManualRecording={isManualUpload}
        meetingStatus={meeting.status}
      />
    ) : (
      // Show "Processing" placeholder if meeting is not processed yet
      <MeetingPlaceholderProcessing
        isProcessedAnalytics={isProcessedAnalytics}
        isProcessedAssignments={isProcessedAssignments}
        isProcessedNotes={isProcessedNotes}
        isPromoteUpgrade={isPromoteUpgrade}
        isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
        isTranscribed={isTranscribed}
        meetingDuration={meeting.duration}
        meetingEnd={meeting.finishedAt}
        onChangeRoute={onRedirect}
        onClickOnUpgradePlan={onClickOnUpgradePlan}
      />
    );
  }
  /* #endregion */

  return (
    <>
      <Box flex={1} width="100%" mt={5}>
        <MeetingTranscriptView
          diarizationItems={diarizationItems}
          isFiltered={isFiltered}
          meetingHighlights={diarizationData?.meeting?.highlights || []}
          meetingLowlights={diarizationData?.meeting?.lowlights || []}
          search={search}
          onClickOnFilterParticipans={handleOpenFilterMenu}
          onChangedSearchTerm={handleChangeSearchTerm}
        >
          {renderTranscriptionItems}
        </MeetingTranscriptView>
        {/* Begin: Menus */}
        <Menu
          keepMounted
          anchorEl={filterMenuEl}
          open={Boolean(filterMenuEl)}
          onClose={handleCloseFilterMenu}
        >
          {meeting.participants?.map((item) => (
            <MenuItem key={item.id}>
              <FormControlLabel
                label={item.name}
                control={
                  <Switch
                    color="primary"
                    name={item.id}
                    checked={!filter[item.id]}
                    onChange={handleChangeFilter}
                  />
                }
              />
            </MenuItem>
          ))}
        </Menu>

        <Menu
          keepMounted
          TransitionComponent={Fade}
          anchorEl={mainMenuData?.anchorEl}
          open={Boolean(mainMenuData)}
          onClose={handleCloseItemMenu}
        >
          <MenuItem onClick={handleDeleteTranscriptionItem}>Delete</MenuItem>
        </Menu>

        {!!speakerMenuData && (
          <MeetingSpeakersMenu
            anchorEl={speakerMenuData.anchorEl}
            meetingParticipants={meeting.participants}
            accessItems={meeting.accessItems}
            speakerId={speakerMenuData.transcriptionItem.participant.id}
            speakerName={speakerMenuData.transcriptionItem.participant.name}
            onClose={handleCloseSpeakersMenu}
            onReplaceSpeaker={handleReplaceSpeaker(speakerMenuData.transcriptionItem)}
            onAddSpeaker={handleAddSpeaker}
          />
        )}
        {/* End: Menus */}

        {/* Begin: Dialogs */}
        {!!splitDialogData && (
          <SplitTranscriptionItemDialog
            meetingAccessItems={meeting.accessItems}
            meetingParticipants={meeting.participants}
            transcriptionItem={splitDialogData}
            onClose={handleCloseSplitDialog}
            onAddSpeaker={handleAddSpeaker}
            onSubmit={handleSplitTranscriptionItem(splitDialogData)}
          />
        )}
        {/* End: Dialogs */}
      </Box>
    </>
  );
};

export default MeetingTranscriptContainer;
