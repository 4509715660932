import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const AnalyticsModuleContainer = lazyWithPreload(
  () => import('./containers/AnalyticsModuleContainer'),
);

export const AnalyticsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Analytics,
    render: () => <AnalyticsModuleContainer />,
  },
};

export default AnalyticsModule;
