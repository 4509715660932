import { useState } from 'react';
import { useQuery } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { TransitionSlide } from '@sembly-ui';
// Material Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
// App Shared
import LimitExceededMessage from '@shared/dialogs/LimitExceededMessage';
import { CalendarForm, AgentCallScheduleForm } from '@shared/forms';
import { useUserContext } from '@shared/hooks';
// App Shared Assets
import CiscoWebexLogo from '@shared/assets/platform-webex.png';
import CiscoWebexLogoCropped from '@shared/assets/platform-webex-cropped.png';
import ZoomLogo from '@shared/assets/platform-zoom.svg';
import MsTeamsLogo from '@shared/assets/platform-microsoft-teams.svg';
import GoogleMeetLogo from '@shared/assets/platform-google-meet.svg';
import CalendarLogo from '@shared/assets/platform-generic-calendar.svg';
// GraphQl Queries and Types
import recordingMeetingAccessQuery from '@shared/queries/RecordingMeetingAccess.graphql';
import { RecordingMeetingAccess } from '@gql-types/RecordingMeetingAccess';
import { PlanIDEnum } from '@gql-types/globalTypes';

export interface AgentCallDialogProps {
  onClose: () => void;
}

export const AgentCallDialog: React.VFC<AgentCallDialogProps> = ({ onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();

  const [currentPlatformId, setCurrentPlatformId] = useState<keyof Platforms>('');
  /* #endregion */

  /* #region  Handlers */
  const handleClose = () => {
    onClose();
  };

  const handleChangePlatform = (id: keyof Platforms) => (event: unknown) => {
    setCurrentPlatformId(id);
  };

  const isPersonal = user.data?.me?.billingAccess?.id === PlanIDEnum.PERSONAL;

  const { data, loading } = useQuery<RecordingMeetingAccess>(recordingMeetingAccessQuery, {
    fetchPolicy: 'network-only',
    skip: !isPersonal,
  });
  /* #endregion */

  /* #region  Render Helpers */
  const paymentCustomer = data?.me?.paymentCustomer;
  const meetingsDurationSecondsLeft = paymentCustomer?.meetingsDurationSecondsLeft ?? 0;
  const recordingAccess = isPersonal ? meetingsDurationSecondsLeft > 0 : true;

  // common handlers
  const platformCommonHandlers = {
    onClose: handleClose,
    onCancel: () => setCurrentPlatformId(''),
  };
  // platform types
  const platforms: Platforms = {
    calendar: {
      name: 'Calendar Invite',
      logo: CalendarLogo,
      logoStyle: { height: 65 },
      component: <CalendarForm logo={CalendarLogo} logoStyle={{ height: 65 }} />,
    },
    zoom: {
      name: 'Zoom',
      logo: ZoomLogo,
      logoStyle: { width: 80 },
      component: (
        <AgentCallScheduleForm
          logo={ZoomLogo}
          logoStyle={{ width: 80 }}
          platform="zoom"
          {...platformCommonHandlers}
        />
      ),
    },
    teams: {
      name: 'Microsoft Teams',
      logo: MsTeamsLogo,
      logoStyle: { height: 48 },
      component: (
        <AgentCallScheduleForm
          logo={MsTeamsLogo}
          logoStyle={{ height: 48 }}
          platform="microsoft teams"
          {...platformCommonHandlers}
        />
      ),
    },
    meet: {
      name: 'Google Meet',
      logo: GoogleMeetLogo,
      logoStyle: { height: 52 },
      component: (
        <AgentCallScheduleForm
          logo={GoogleMeetLogo}
          logoStyle={{ height: 52 }}
          platform="google meet"
          {...platformCommonHandlers}
        />
      ),
    },
    webex: {
      name: 'Cisco Webex',
      logo: CiscoWebexLogo,
      logoStyle: { width: 150 },
      component: (
        <AgentCallScheduleForm
          logo={CiscoWebexLogoCropped}
          logoStyle={{ height: 80 }}
          platform="webex"
          {...platformCommonHandlers}
        />
      ),
    },
  };

  // helpers
  const currentPlatform = platforms[currentPlatformId];
  const hasCurrentPlatform = !!currentPlatform;
  /* #endregion */

  return (
    <Dialog
      open
      keepMounted
      fullWidth
      maxWidth="sm"
      scroll="paper"
      fullScreen={isSmallScreen}
      TransitionComponent={TransitionSlide}
      onClose={() => handleClose()}
    >
      <DialogTitle disableTypography className={styles.title}>
        {hasCurrentPlatform && (
          <Box mr={2}>
            <IconButton aria-label="go back" onClick={handleChangePlatform('')}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
        )}
        {recordingAccess && (
          <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
            Invite Sembly to Meeting{' '}
            <Link
              color="textSecondary"
              target="_blank"
              rel="noopener noreferrer"
              title="Help"
              href={
                currentPlatform === platforms.calendar
                  ? 'https://helpdesk.sembly.ai/hc/en-us/articles/4407362769553'
                  : 'https://helpdesk.sembly.ai/hc/en-us/articles/4407874486033-Immediate-Sembly-Invite'
              }
              style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
            >
              <QuestionMarkIcon fontSize="inherit" />
            </Link>
          </Typography>
        )}
        <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {recordingAccess ? (
        <DialogContent className={styles.content}>
          {hasCurrentPlatform ? (
            currentPlatform.component
          ) : (
            <Grid container>
              {Object.keys(platforms).map((key, index) => {
                const platform = platforms[key];
                if (!platform) return null;
                return (
                  <Grid
                    item
                    key={key}
                    md={index === 0 ? 12 : 6}
                    xs={index === 0 ? 12 : 6}
                    className={styles.item}
                    onClick={handleChangePlatform(key)}
                  >
                    <div className={styles.tile}>
                      <img src={platform.logo} alt={platform.name} style={platform.logoStyle} />
                    </div>
                    <Box mt={1}>
                      <Typography className={styles.platformName}>{platform.name}</Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </DialogContent>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          textAlign="center"
          px={4}
          py={4}
          pt={0}
        >
          {loading ? (
            <Box p={10} mt={3} mb={4}>
              <CircularProgress size={24} color="primary" />
            </Box>
          ) : (
            <LimitExceededMessage onClose={onClose}>
              You've exceeded your time limit of Sembly agent invites.
              <br />
              Upgrade now to invite agent without limitations.
            </LimitExceededMessage>
          )}
        </Box>
      )}
    </Dialog>
  );
};

interface Platforms {
  [key: string]: {
    name: string;
    logo: string;
    logoStyle: React.CSSProperties;
    component: JSX.Element;
  } | null;
}

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    paddingTop: 0,
    padding: theme.spacing(0, 4, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2, 2),
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  item: {
    padding: theme.spacing(1),
    textAlign: 'center',
    cursor: 'pointer',
  },
  tile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4 * theme.shape.borderRadius,
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
    },
  },
  platform: {
    maxHeight: 48,
    maxWidth: 48,
  },
  platformName: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default AgentCallDialog;
