import { Avatar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircleSharp';

export interface IntegrationAppCardProps {
  connected: boolean;
  logoUri?: string;
  name: string;
  onClick: () => void;
}

/**
 * Component that represents an integration app card.
 */
export const IntegrationAppCard: React.VFC<IntegrationAppCardProps> = ({
  connected,
  logoUri,
  name,
  onClick,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root} onClick={onClick}>
      {connected && (
        <div className={styles.connected}>
          <CheckIcon fontSize="small" />
        </div>
      )}
      {logoUri ? (
        <img className={styles.appLogo} src={logoUri} alt={name} width={36} height={36} />
      ) : (
        <Avatar>{name.charAt(0).toUpperCase()}</Avatar>
      )}

      <Typography component="div" className={styles.title}>
        {name}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: theme.spacing(3, 2, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    gap: theme.spacing(1.5),
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      boxShadow: theme.shadows[2],
    },
  },
  title: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  connected: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    opacity: 0.5,
    color: theme.palette.success.main,
  },
  appLogo: {
    objectFit: 'contain',
  },
}));

export default IntegrationAppCard;
