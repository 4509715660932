import { format, parseISO } from 'date-fns';
import { useLayoutEffect, useState } from 'react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
// Sembly UI
import { useIntersectionObserver, GenericDialog, RichTextEditor } from '@sembly-ui';
// GraphQL Queries and Types
import rcNewsItemsPaginatedQuery from '@shared/queries/RcNewsItemsPaginated.graphql';
import markRcNewsItemsAsReadMutation from '@shared/queries/MarkRcNewsItemsAsRead.graphql';
import { MarkRcNewsItemsAsRead } from '@gql-types/MarkRcNewsItemsAsRead';
import {
  RcNewsItemsPaginated,
  RcNewsItemsPaginatedVariables,
} from '@gql-types/RcNewsItemsPaginated';
// App Shared
import { graphErrorHorsemen } from '@shared/utils';
import { useUserContext } from '@shared/hooks';

export interface NewsroomProps {
  onClose: () => void;
}

export const Newsroom: React.VFC<NewsroomProps> = ({ onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  const [fetchMoreButtonRef, setFetchMoreButtonRef] = useState<HTMLButtonElement | null>(null);
  const fetchMoreButtonObserver = useIntersectionObserver(fetchMoreButtonRef);

  const { data, fetchMore, networkStatus } = useQuery<
    RcNewsItemsPaginated,
    RcNewsItemsPaginatedVariables
  >(rcNewsItemsPaginatedQuery, {
    notifyOnNetworkStatusChange: true,
    variables: { page: 1, perPage: 3, orderBy: '-date' },
  });

  const [markRcNewsItemsAsRead] = useMutation<MarkRcNewsItemsAsRead>(
    markRcNewsItemsAsReadMutation,
    {
      update: (cache, result) => {
        if (!result?.data?.markRcNewsItemsAsRead?.success) {
          graphErrorHorsemen(result?.data?.markRcNewsItemsAsRead?.errors);
          return;
        }

        cache.modify({
          id: cache.identify({ __typename: 'DetailedUserType', id: user.data?.me?.id }),
          fields: {
            hasUnreadRcNews() {
              return false;
            },
          },
        });

        cache.updateQuery<RcNewsItemsPaginated>({ query: rcNewsItemsPaginatedQuery }, (data) => {
          if (!data?.rcNewsItemsPaginated?.objects) return;
          return {
            rcNewsItemsPaginated: {
              ...data.rcNewsItemsPaginated,
              objects: data?.rcNewsItemsPaginated?.objects?.map((item) => ({
                ...item,
                isRead: true,
              })),
            },
          };
        });
      },
    },
  );
  /* #endregion */

  /* #region  Helpers */
  const newsObjects = data?.rcNewsItemsPaginated?.objects;
  const hasNext = data?.rcNewsItemsPaginated?.hasNext;
  const isLoading = networkStatus === NetworkStatus.loading;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  /* #endregion */

  /* #region  Effects */
  useLayoutEffect(() => {
    if (fetchMoreButtonObserver.isIntersecting && hasNext && !isFetchingMore) {
      handleLoadMore();
    }
  });
  /* #endregion */

  /* #region  Handlers */
  const handleLoadMore = () => {
    const currentPage = data?.rcNewsItemsPaginated?.page ?? 0;
    fetchMore({ variables: { page: currentPage + 1 } });
  };

  const handleMarkAllAsRead = () => {
    markRcNewsItemsAsRead();
    onClose();
  };
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      onClose={onClose}
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
        fullScreen: isSmallScreen,
        classes: { paper: styles.newsDialog },
      }}
    >
      <Typography variant="h4">
        <Box
          display="flex"
          alignItems={isSmallScreen ? 'flex-start' : 'center'}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flex="1"
            flexWrap="wrap"
          >
            <Box mb={1}>What's New</Box>
            <Button
              className={styles.dialogHeaderBtn}
              color="primary"
              variant="outlined"
              aria-label="Mark all as read"
              onClick={handleMarkAllAsRead}
            >
              Mark all as read
            </Button>
          </Box>
          <Box>
            <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Typography>

      <Box mt={3}>
        {isLoading && (
          <Box my={2} textAlign="center">
            <CircularProgress size={24} />
          </Box>
        )}
        {newsObjects?.map((item) => (
          <Box mb={1} key={item.id}>
            <Box
              className={styles.imgPreviewBox}
              style={{ backgroundImage: `url(${item?.image})` }}
            />
            <Box mt={2} color="grey.300">
              <Typography variant="caption">
                {format(parseISO(item.date), 'd MMM, yyyy')}
              </Typography>
            </Box>
            <Box mt={1} position="relative">
              <Typography variant="h5">
                <Box fontSize="16px">{item.header}</Box>
              </Typography>
              {!item.isRead ? (
                <Box position="absolute" top={7} left={-12} className={styles.dot} />
              ) : null}
            </Box>

            <Box mt={1} color="grey.300">
              <Typography variant="caption">{item.subheader}</Typography>
            </Box>
            <Box className={styles.text}>
              <RichTextEditor readOnly hideToolbar defaultValue={item.mainText} />
            </Box>
          </Box>
        ))}
      </Box>
      {/* Load More Section */}
      <Box display="flex" justifyContent="center" alignItems="center">
        {hasNext ? (
          <Button ref={setFetchMoreButtonRef} aria-label="Load more news" onClick={handleLoadMore}>
            {isFetchingMore ? <CircularProgress size={30} /> : <span>Load More</span>}
          </Button>
        ) : null}
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  imgPreviewBox: {
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingBottom: '56.25%',
    position: 'relative'
  },
  dot: {
    display: 'inline-block',
    width: 6,
    height: 6,
    borderRadius: '50%',
    background: theme.palette.indication.dark,
  },
  newsDialog: {
    padding: theme.spacing(4),
  },
  dialogHeaderBtn: {
    marginRight: theme.spacing(1),
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  text: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    '& a': {
      cursor: 'pointer !important',
    },
  },
  closeButton: {
    padding: 5,
    color: theme.palette.grey[500],
  },
}));

export default Newsroom;
