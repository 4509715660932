import H5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import clsx from 'clsx';
import { forwardRef, memo, useRef, useState } from 'react';
// Material UI
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// Material UI Icons
import { SaveAltSharp } from '@material-ui/icons';
import Forward10Icon from '@material-ui/icons/Forward10';
import Replay10Icon from '@material-ui/icons/Replay10';
import VideocamIcon from '@material-ui/icons/Videocam';

export type DiarizationPlayerRef = H5AudioPlayer;

export interface DiarizationPlayerProps {
  className?: string;
  isAuthorizedToExport?: boolean;
  audioSrc?: string | null;
  videoSrc?: string | null;
}

const Player = forwardRef<H5AudioPlayer, DiarizationPlayerProps>(
  ({ audioSrc, videoSrc, className = '', isAuthorizedToExport = false }, ref) => {
    const styles = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const playerRef = useRef<DiarizationPlayerRef | null>(null);
    const [playbackRate, setPlaybackRate] = useState({ value: 1, label: '1x' });

    const handleChangePlaybackSpeed = () => {
      setPlaybackRate((prev) => {
        if (!playerRef?.current?.audio?.current) return prev;
        if (prev.value === 1) {
          // set playback rate to 1.5x
          playerRef.current.audio.current.playbackRate = 1.5;
          return { value: 1.5, label: '1.5x' };
        } else if (prev.value === 1.5) {
          // set playback rate to 2x
          playerRef.current.audio.current.playbackRate = 2;
          return { value: 2, label: '2x' };
        } else {
          // set playback rate to 1x
          playerRef.current.audio.current.playbackRate = 1;
          return { value: 1, label: '1x' };
        }
      });
    };

    const handleFastForward = () => {
      if (!playerRef?.current?.audio?.current) return;
      playerRef.current.audio.current.currentTime += 10;
    };

    const handleFastRewind = () => {
      if (!playerRef?.current?.audio?.current) return;
      playerRef.current.audio.current.currentTime -= 10;
    };

    const sections = isSmallScreen
      ? [RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR]
      : [RHAP_UI.VOLUME, RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION];

    return (
      <div className={`${styles.root} ${className}`}>
        <div
          title={!audioSrc ? 'Audio is not available' : undefined}
          className={clsx(styles.playerWrapper, !audioSrc && 'disabled')}
        >
          <H5AudioPlayer
            className={styles.player}
            ref={(node) => {
              playerRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                ref.current = node;
              }
            }}
            src={audioSrc || undefined}
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            layout="horizontal"
            showJumpControls={false}
            showDownloadProgress={false}
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
            customProgressBarSection={sections}
          />
          {!!audioSrc && (
            <Box display="flex" alignItems="center" gridGap={8}>
              <IconButton size="small" color="inherit" title="-10 sec" onClick={handleFastRewind}>
                <Replay10Icon fontSize="small" color="inherit" />
              </IconButton>
              <IconButton size="small" color="inherit" title="+10 sec" onClick={handleFastForward}>
                <Forward10Icon fontSize="small" color="inherit" />
              </IconButton>
              <IconButton
                size="small"
                color="inherit"
                title="Playback speed"
                onClick={handleChangePlaybackSpeed}
              >
                <Typography component="span" variant="body2" style={{ paddingTop: 2 }}>
                  <b>{playbackRate.label}</b>
                </Typography>
              </IconButton>
              {isAuthorizedToExport && !isSmallScreen && (
                <IconButton
                  component="a"
                  color="inherit"
                  size="small"
                  title="Download audio"
                  href={audioSrc || undefined}
                >
                  <SaveAltSharp fontSize="small" color="inherit" />
                </IconButton>
              )}
            </Box>
          )}
        </div>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href={videoSrc || undefined}
          title={!videoSrc ? 'Video is not available' : undefined}
          className={clsx(styles.video, !videoSrc && 'disabled')}
        >
          <VideocamIcon />
          {!isSmallScreen && <span>Video</span>}
        </a>
      </div>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(1),
  },
  playerWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    background: theme.palette.grey[800],
    color: theme.palette.grey[50],
    borderRadius: '2em',
    '&.disabled': {
      opacity: 0.75,
      cursor: 'not-allowed',
    },
  },
  video: {
    padding: theme.spacing(1, 2),
    background: theme.palette.grey[800],
    color: theme.palette.grey[50],
    borderRadius: '2em',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover:not(.disabled)': {
      background: theme.palette.grey[900],
    },
    '&.disabled': {
      opacity: 0.75,
      cursor: 'not-allowed',
    },
    span: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  player: {
    width: '100%',
    '@global': {
      '.rhap_container': {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1',
        fontFamily: 'inherit',
        width: '100%',
        padding: theme.spacing(1, 2),
      },
      '.rhap_main': {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row-reverse',
      },
      '.rhap_progress-container': {
        display: 'flex',
        alignItems: 'center',
        height: 20,
        flex: '1 0 auto',
        alignSelf: 'center',
        margin: '0 calc(10px + 1%)',
        cursor: 'pointer',
        userSelect: 'none',
        '-webkit-user-select': 'none',
        '&:focus:not(:focus-visible)': {
          outline: 0,
        },
      },
      '.rhap_progress-bar': {
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 0,
        width: '100%',
        height: 5,
        backgroundColor: theme.palette.grey[50],
        borderRadius: theme.shape.borderRadius,
      },
      '.rhap_progress-filled': {
        height: '100%',
        position: 'absolute',
        zIndex: 2,
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
      },
      '.rhap_progress-bar-show-download': {
        backgroundColor: theme.palette.grey[300],
      },
      '.rhap_download-progress': {
        height: '100%',
        position: 'absolute',
        zIndex: 1,
        backgroundColor: theme.palette.grey[200],
        borderRadius: theme.shape.borderRadius,
      },
      '.rhap_progress-indicator': {
        display: 'none',
      },
      '.rhap_progress-section': {
        display: 'flex',
        flex: '3 1 auto',
        alignItems: 'center',
      },
      '.rhap_controls-section': {
        display: 'flex',
        flex: '0 1 auto',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '.rhap_volume-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      '.rhap_main-controls': {
        display: 'flex',
        flex: '0 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '.rhap_main-controls-button': {
        margin: '0 3px',
        color: 'inherit',
        fontSize: 24,
        width: 24,
        height: 24,
      },
      '.rhap_play-pause-button, .rhap_volume-button': {
        fontSize: 24,
        width: 24,
        height: 24,
      },
      '.rhap_time': {
        margin: theme.spacing(0, 0.5),
        paddingTop: 2,
      },
      '.rhap_volume-controls': {
        display: 'flex',
        flex: '1 0 auto',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      '.rhap_button-clear': {
        backgroundColor: 'transparent',
        color: 'inherit',
        border: 'none',
        padding: 0,
        margin: '0 3px',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.9,
          transitionDuration: '.2s',
        },
        '&:active': {
          opacity: 0.95,
        },
        '&:focus:not(:focus-visible)': {
          outline: 0,
        },
      },
    },
  },
}));

const compareProps = (prev: DiarizationPlayerProps, next: DiarizationPlayerProps) =>
  prev.audioSrc !== next.audioSrc || prev.isAuthorizedToExport !== next.isAuthorizedToExport;

/**
 * @name DiarizationPlayer
 * @description A player for audio files
 * @param {DiarizationPlayerProps} props
 * @returns {React.ReactElement}
 */
export const DiarizationPlayer = memo(Player, compareProps);
export default DiarizationPlayer;
