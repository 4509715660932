import { Integration as FabricIntegration } from '@integration-app/sdk';
// Material UI
import AppsIcon from '@material-ui/icons/AppsSharp';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import integrationsImage from '../assets/icon-integrations-36.svg';
import { getNameInitials } from '../utils';
import { Integration } from '../types';

export interface NewAutomationMenuProps {
  anchorEl: HTMLElement;
  items: Integration[] | FabricIntegration[];
  onBrowseApps: () => void;
  onClick: (integration: Integration) => void;
  onClose: () => void;
}

export const NewAutomationMenu: React.VFC<NewAutomationMenuProps> = ({
  anchorEl,
  items,
  onBrowseApps,
  onClick,
  onClose,
}) => {
  const styles = useStyles();

  const handleBrowseApps = () => {
    onBrowseApps();
    onClose();
  };

  const handleClickItem = (integration: Integration | FabricIntegration) => () => {
    const data: Integration = !!(integration as Integration).type
      ? (integration as Integration)
      : { ...integration, type: 'fabric' } as Integration;
    onClick(data);
    onClose();
  };

  return (
    <Popover open classes={{ paper: styles.paper }} anchorEl={anchorEl} onClose={onClose}>
      {!items.length ? (
        <Box display="flex" alignItems="center" flexDirection="column" padding={4}>
          <Box mb={2}>
            <img src={integrationsImage} alt="Connected Apps" />
          </Box>
          <Typography gutterBottom variant="h6">
            No Connected Apps
          </Typography>
          <Typography paragraph align="center" variant="body1">
            Connect Sembly with your favorite apps to create your first automation.
          </Typography>
          <Button disableElevation variant="contained" color="primary" onClick={handleBrowseApps}>
            Browse all apps
          </Button>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" flexDirection="column" pt={1}>
          <List className={styles.list}>
            {items.map((item) => (
              <ListItem dense button key={item.id} component="div" onClick={handleClickItem(item)}>
                <ListItemAvatar classes={{ root: styles.listItemAvatarRoot }}>
                  <Avatar
                    variant="rounded"
                    style={{ width: 24, height: 24 }}
                    title={item.name}
                    alt={item.name}
                    src={item.logoUri || undefined}
                  >
                    {item.logoUri ? null : getNameInitials(item.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography component="div" noWrap variant="body1">
                    {item.name}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <Box p={1} pt={0} width="100%">
            <Button
              fullWidth
              disableElevation
              variant="outlined"
              startIcon={<AppsIcon color="action" />}
              onClick={handleBrowseApps}
            >
              <Typography component="div" noWrap variant="body1">
                Browse all apps
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Popover>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: 360,
  },
  list: {
    width: '100%',
  },
  listItemAvatarRoot: {
    minWidth: 36,
  },
}));

export default NewAutomationMenu;
