import { SvgIcon } from '@material-ui/core';

export const HandWave = () => (
  <SvgIcon width="57" height="56" viewBox="0 0 57 56">
    <g clipPath="url(#clip0_1767_15614)">
      <path
        d="M8.06164 14.2286C9.52386 13.2066 11.7281 13.4026 13.041 14.4868L11.5352 12.2982C10.3234 10.57 10.7574 8.70017 12.4872 7.48684C14.217 6.27817 19.1185 9.52773 19.1185 9.52773C17.8959 7.7824 18.1245 5.5704 19.8699 4.34617C21.6152 3.12662 24.0232 3.54817 25.2459 5.29662L41.4548 28.1944L39.389 48.2222L22.1488 41.9346L7.1112 19.6388C5.87764 17.8826 6.30386 15.4606 8.06164 14.2286Z"
        fill="#EF9645"
      />
      <path
        d="M4.69214 26.9672C4.69214 26.9672 2.93126 24.4005 5.49948 22.6412C8.06459 20.8818 9.82392 23.4469 9.82392 23.4469L17.9921 35.3594C18.2737 34.8896 18.5817 34.426 18.9255 33.9687L7.58859 17.4378C7.58859 17.4378 5.82926 14.8727 8.39592 13.1134C10.961 11.354 12.7204 13.9192 12.7204 13.9192L23.3837 29.47C23.7804 29.1465 24.1864 28.8214 24.6048 28.5025L12.2428 10.472C12.2428 10.472 10.4835 7.90693 13.0501 6.1476C15.6153 4.38827 17.3746 6.95338 17.3746 6.95338L29.7366 24.9807C30.1908 24.7023 30.6404 24.4612 31.0915 24.206L19.5368 7.35627C19.5368 7.35627 17.7775 4.79115 20.3426 3.03182C22.9077 1.27249 24.667 3.8376 24.667 3.8376L36.8844 21.6549L38.7417 24.3647C31.0448 29.6443 30.3121 39.5765 34.7097 45.99C35.5886 47.2734 36.8719 46.3945 36.8719 46.3945C31.5939 38.696 33.2055 30.0456 40.9039 24.7676L38.6344 13.4089C38.6344 13.4089 37.7866 10.416 40.7779 9.56671C43.7708 8.71893 44.6201 11.7118 44.6201 11.7118L47.2413 19.4958C48.2804 22.582 49.3864 25.6574 50.8501 28.5663C54.9833 36.7796 52.5146 46.9872 44.7213 52.3336C36.2201 58.1623 24.5986 55.9954 18.7684 47.4958L4.69214 26.9672Z"
        fill="#FFDC5D"
      />
      <path
        d="M19.1668 49.8432C12.9446 49.8432 6.65701 43.5556 6.65701 37.3334C6.65701 36.4732 6.02701 35.7778 5.16679 35.7778C4.30657 35.7778 3.5459 36.4732 3.5459 37.3334C3.5459 46.6667 9.83345 52.9543 19.1668 52.9543C20.027 52.9543 20.7223 52.1936 20.7223 51.3334C20.7223 50.4732 20.027 49.8432 19.1668 49.8432Z"
        fill="#5DADEC"
      />
      <path
        d="M11.3889 52.8891C6.72222 52.8891 3.61111 49.778 3.61111 45.1113C3.61111 44.2511 2.91578 43.5558 2.05556 43.5558C1.19533 43.5558 0.5 44.2511 0.5 45.1113C0.5 51.3335 5.16667 56.0002 11.3889 56.0002C12.2491 56.0002 12.9444 55.3049 12.9444 54.4447C12.9444 53.5844 12.2491 52.8891 11.3889 52.8891ZM37.8333 3.11133C36.9747 3.11133 36.2778 3.80822 36.2778 4.66688C36.2778 5.52555 36.9747 6.22244 37.8333 6.22244C44.0556 6.22244 50.2778 11.8053 50.2778 18.6669C50.2778 19.5256 50.9747 20.2224 51.8333 20.2224C52.692 20.2224 53.3889 19.5256 53.3889 18.6669C53.3889 10.0896 47.1667 3.11133 37.8333 3.11133Z"
        fill="#5DADEC"
      />
      <path
        d="M45.6112 0.0654297C44.7526 0.0654297 44.0557 0.696985 44.0557 1.55565C44.0557 2.41432 44.7526 3.17654 45.6112 3.17654C50.2779 3.17654 53.3237 6.63765 53.3237 10.889C53.3237 11.7477 54.0843 12.4445 54.9446 12.4445C55.8048 12.4445 56.4348 11.7477 56.4348 10.889C56.4348 4.92032 51.8334 0.0654297 45.6112 0.0654297Z"
        fill="#5DADEC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1767_15614">
        <rect width="56" height="56" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default HandWave;
