import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export const EnrollmentSampleText: React.VFC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography component="div" variant={isSmallScreen ? 'body1' : 'inherit'}>
      <p>“Would you tell me, please, which way I ought to go from here?”</p>
      <p>“That depends a good deal on where you want to get to,” said the Cat.</p>
      <p>“I don’t much care where—” said Alice.</p>
      <p>“Then it doesn’t matter which way you go,” said the Cat.</p>
      <p>“—so long as I get somewhere,” Alice added as an explanation.</p>
      <p>“Oh, you’re sure to do that,” said the Cat, “if you only walk long enough.”</p>
      <p>
        Alice felt that this could not be denied, so she tried another question. “What sort of
        people live about here?”
      </p>
      <p>
        “In that direction,” the Cat said, waving its right paw round, “lives a Hatter: and in that
        direction,” waving the other paw, “lives a March Hare. Visit either you like: they’re both
        mad.”
      </p>
      <p>“But I don’t want to go among mad people,” Alice remarked.</p>
      <p>“Oh, you can’t help that,” said the Cat: “we’re all mad here. I’m mad. You’re mad.”</p>
      <p>“How do you know I’m mad?” said Alice.</p>
      <p>“You must be,” said the Cat, “or you wouldn’t have come here.”</p>
      <p>
        Alice didn’t think that proved it at all; however, she went on “And how do you know that
        you’re mad?”
      </p>
      <p>“To begin with,” said the Cat, “a dog’s not mad. You grant that?”</p>
      <p>“I suppose so,” said Alice.</p>
      <p>
        “Well, then,” the Cat went on, “you see, a dog growls when it’s angry, and wags its tail
        when it’s pleased. Now I growl when I’m pleased, and wag my tail when I’m angry. Therefore
        I’m mad.”
      </p>
    </Typography>
  );
};
