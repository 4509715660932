import { Integration as FabricIntegration } from '@integration-app/sdk';
import { useState, useMemo } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
// Lib Shared
import { GenericDialog, IntegrationAppCard } from '../components';
import { INTEGRATION_APPS } from '../constants';
import { Integration } from '../types';

export interface IntegrationAppsMarketplaceProps {
  open: boolean;
  apps: (Integration | FabricIntegration)[];
  onClose: () => void;
  onClickOnApp: (appId: string) => void;
}

type IntegrationType = 'all' | 'tasks' | 'notes' | 'transcription';

/**
 * Dialog that displays all available apps that can be used to create an automation.
 */
export const IntegrationAppsMarketplace: React.VFC<IntegrationAppsMarketplaceProps> = ({
  open,
  apps,
  onClose,
  onClickOnApp,
}) => {
  const [filterValue, setFilterValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('Any');
  const [selectedType, setSelectedType] = useState<IntegrationType>('all');

  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* #endregion */

  /* #region  Handlers */
  const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value.toLowerCase());
  };

  const handleClearFilterValue = () => {
    setFilterValue('');
  };

  const handleClickOnApp = (appId: string) => () => {
    onClickOnApp(appId);
    onClose();
  };
  /* #endregion */

  /* #region  Render Helpers */
  // Group apps into predefined groups: CRM, ProjectManagement, Collaboration
  const appGroups = Object.entries(INTEGRATION_APPS).reduce(
    (acc: Record<string, string[]>, [appKey, app]) => {
      const { category } = app;
      return { ...acc, [category]: [...(acc[category] || []), appKey] };
    },
    {},
  );

  const appTypes: Record<IntegrationType, string[]> = {
    all: [],
    tasks: Object.entries(INTEGRATION_APPS)
      .filter(([appKey, app]) => app.categories.includes('Tasks'))
      .map(([appKey]) => appKey),
    notes: Object.entries(INTEGRATION_APPS)
      .filter(([appKey, app]) => app.categories.includes('Meeting Notes'))
      .map(([appKey]) => appKey),
    transcription: Object.entries(INTEGRATION_APPS)
      .filter(([appKey, app]) => app.categories.includes('Transcription'))
      .map(([appKey]) => appKey),
  };

  const appTypeLabels: Record<IntegrationType, string> = {
    all: 'All',
    tasks: 'Tasks',
    notes: 'Meeting Notes',
    transcription: 'Transcription',
  };

  const filteredAppList = apps.filter((app) => {
    if (!appTypes[selectedType].includes(app.key)) return false;
    if (selectedCategory !== 'Any' && !appGroups[selectedCategory].includes(app.key)) return false;
    if (!!filterValue && !app.name.toLowerCase().includes(filterValue)) return false;
    return true;
  });

  /**
   * Group apps into predefined groups: CRM, ProjectManagement, Collaboration
   * Any app that is not in one of these groups will be grouped into "Other"
   *
   * @returns An object containing the grouped apps.
   */
  const groupedApps: Record<string, (Integration | FabricIntegration)[]> = useMemo(() => {
    const filteredApps = apps;

    // Group apps based on predefined groups
    const groupedApps = Object.entries(appGroups).reduce((acc, [groupName, groupApps]) => {
      const appsInGroup = (filteredApps as any).filter((app: any) => groupApps.includes(app.key));
      return { ...acc, [groupName]: appsInGroup };
    }, {});

    // Group apps that are not in any predefined group into "Other"
    const otherApps = (filteredApps as any).filter((app: any) => {
      const appKeys = Object.values(appGroups).flat() as string[];
      return !appKeys.includes(app.key);
    });

    return { ...groupedApps, Other: otherApps };
  }, [apps, appGroups]);

  const filteredGroupedApps = Object.entries(groupedApps).map(([groupName, groupApps]) => {
    // This condition checks if the current group name is not equal to the selected category
    // and the selected category is not 'Any'. If this condition is true, it will return null,
    // effectively skipping the rendering of the current group of apps.
    if (groupName !== selectedCategory && selectedCategory !== 'Any') {
      return null;
    }

    // Filter the apps in the current group based on the filter value
    const filteredApps = groupApps.filter((app) => {
      // Check if the filter value exists and if the app name does not include
      // the filter value (case-insensitive)
      return !(!!filterValue && !app.name.toLowerCase().includes(filterValue));
    });

    if (!filteredApps.length) return null;

    return (
      <Grid container spacing={2} key={groupName}>
        <Grid item xs={12}>
          <Typography variant="h6">{groupName}</Typography>
        </Grid>
        {filteredApps.map((app: any) => (
          <Grid item key={app.id} xs={6} sm={6} md={4} lg={3}>
            <IntegrationAppCard
              connected={!!app.connection}
              logoUri={app.logoUri}
              name={app.name}
              onClick={handleClickOnApp(app.id)}
            />
          </Grid>
        ))}
      </Grid>
    );
  });
  /* #endregion */

  return (
    <GenericDialog
      title="Apps"
      dialogProps={{ open, fullWidth: true, maxWidth: 'md', fullScreen: isSmallScreen }}
      onClose={onClose}
    >
      <DialogContent>
        <div className={styles.tools}>
          <TextField
            autoFocus
            fullWidth
            size="small"
            variant="filled"
            placeholder="Search"
            value={filterValue}
            className={styles.search}
            onChange={handleChangeFilterValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" color="disabled" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!!filterValue && (
                    <IconButton size="small" onClick={handleClearFilterValue}>
                      <CloseIcon fontSize="small" color="primary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            select
            fullWidth
            size="small"
            variant="filled"
            placeholder="All Categories"
            value={selectedCategory}
            className={styles.categorySelect}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <MenuItem value="Any">All Categories</MenuItem>
            {Object.entries(groupedApps).map(([groupName, groupApps]) => {
              if (!groupApps.length) return null;
              return (
                <MenuItem key={groupName} value={groupName}>
                  {groupName}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            select
            fullWidth
            size="small"
            variant="filled"
            placeholder="Any Type"
            value={selectedType}
            className={styles.typeSelect}
            onChange={(event) => setSelectedType(event.target.value as IntegrationType)}
          >
            <MenuItem value="all">Any Type</MenuItem>
            {appTypes.tasks.length > 0 && <MenuItem value="tasks">Tasks</MenuItem>}
            {appTypes.notes.length > 0 && <MenuItem value="notes">Meeting Notes</MenuItem>}
            {appTypes.transcription.length > 0 && (
              <MenuItem value="transcription">Transcription</MenuItem>
            )}
          </TextField>
        </div>
        {!apps.length ? (
          <Box textAlign="center" py={4}>
            <Typography variant="body1">There are no apps available.</Typography>
          </Box>
        ) : (
          <div className={styles.content}>
            {!!selectedType && selectedType !== 'all' ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6">
                    {appTypeLabels[selectedType]}
                  </Typography>
                  {selectedCategory !== 'Any' && (
                    <Typography variant="body2">{`Category: ${selectedCategory}`}</Typography>
                  )}
                </Grid>
                {filteredAppList.map((app) => (
                  <Grid item key={app.id} xs={6} sm={6} md={4} lg={3}>
                    <IntegrationAppCard
                      connected={!!app.connection}
                      logoUri={app.logoUri}
                      name={app.name}
                      onClick={handleClickOnApp(app.id)}
                    />
                  </Grid>
                ))}{' '}
                {selectedCategory !== 'Any' && !filterValue && !filteredAppList.length && (
                  <Box textAlign="center" py={4} width="100%">
                    <Typography variant="body1">
                      There are no apps available for this category and type.
                    </Typography>
                  </Box>
                )}
                {!!filterValue && !filteredAppList.length && (
                  <Box textAlign="center" py={4} width="100%">
                    <Typography variant="body1">{`No apps found for "${filterValue}"`}</Typography>
                  </Box>
                )}
              </Grid>
            ) : (
              <>
                {filteredGroupedApps}
                {!!filterValue && filteredGroupedApps.every((i) => !i) && (
                  <Box textAlign="center" py={4}>
                    <Typography variant="body1">{`No apps found for "${filterValue}"`}</Typography>
                  </Box>
                )}
              </>
            )}
          </div>
        )}
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  tools: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  search: {
    flex: 1,
  },
  categorySelect: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  typeSelect: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default IntegrationAppsMarketplace;
