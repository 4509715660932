// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import { GenericDialog } from '../components';
import { getNameInitials, isValidEmail } from '../utils';
import { ShareFormTargetStateObject } from '../components/ShareForm';
import { useAutocomplete } from '../hooks';

export interface ShareDialogFormProps {
  open: boolean;
  note: string;
  permission: 'view' | 'edit';
  target: (string | ShareFormTargetStateObject)[];
  onCancel: () => void;
  onChangeCustomNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangePermission: (e: React.ChangeEvent<any>) => void;
  onConfirm: () => void;
  onGoBack: () => void;
}

export const ShareDialogForm: React.VFC<ShareDialogFormProps> = ({
  open,
  note,
  permission,
  onConfirm,
  onCancel,
  onGoBack,
  onChangeCustomNote,
  onChangePermission,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    isLoadingSuggestions,
    options,
    target,
    inviteValue,
    calledSubmit,
    handlePaste,
    handleChangeInput,
    handleChangeValue,
  } = useAutocomplete();
  /* #endregion */

  const handleChangePermission = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    onChangePermission(event);
  };

  return (
    <GenericDialog
      disableTransition
      showBackButton
      title="Share meeting"
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={onCancel}
      onClickOnGoBack={onGoBack}
    >
      <Box component={DialogContent} mt={1} mb={4}>
        <Grid container alignItems="flex-start" spacing={1}>
          <Grid item xs={12} sm={9}>
            <Autocomplete
              freeSolo
              fullWidth
              multiple
              value={target}
              loading={isLoadingSuggestions}
              classes={{
                root: styles.autocompleteRoot,
                inputRoot: styles.autocompleteInputRoot,
                input: styles.input,
              }}
              options={options.length ? options : isValidEmail(inviteValue) ? [inviteValue] : []}
              getOptionLabel={(option) => {
                const isString = typeof option === 'string';
                const isArray = Array.isArray(option);

                return isString ? option : !isArray ? option.search : '';
              }}
              renderOption={(option, index) => {
                const isString = typeof option === 'string';
                const isArray = Array.isArray(option);
                const key = isString
                  ? `${option}-${index}`
                  : !isArray
                  ? option.value.id
                  : `${index}`;
                const name = isString ? option : !isArray ? option.value.fullName : '';
                const avatar = isString ? null : !isArray ? option.value.avatar : '';
                const email = isString ? null : !isArray ? option.value.email : '';
                return (
                  <ListItem
                    dense
                    key={key}
                    component="div"
                    style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ListItemAvatar style={{ minWidth: 42 }}>
                      <Avatar
                        style={{ width: 28, height: 28 }}
                        title={name}
                        alt={name}
                        src={avatar || undefined}
                      >
                        {avatar ? null : getNameInitials(name)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} secondary={email} />
                  </ListItem>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const isString = typeof option === 'string';
                  const isArray = Array.isArray(option);
                  const label = isString ? option : !isArray ? option.value.fullName : '';

                  return <Chip variant="outlined" label={label} {...getTagProps({ index })} />;
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  placeholder="Enter name or email to share"
                  variant="outlined"
                  size="small"
                  error={calledSubmit && !target}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoadingSuggestions ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              onPaste={handlePaste}
              onChange={handleChangeValue}
              onInputChange={handleChangeInput}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="filled">
              <Select
                className={styles.select}
                value={permission}
                onChange={handleChangePermission}
              >
                <MenuItem value={'view'}>Can view</MenuItem>
                <MenuItem value={'edit'}>Can edit</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                multiline
                minRows={5}
                InputProps={{
                  classes: { root: styles.textField },
                  endAdornment: <span className={styles.lengthText}>{note.length}/500</span>,
                }}
                inputProps={{ maxLength: 500 }}
                variant="filled"
                placeholder="Message (optional)"
                value={note}
                onChange={onChangeCustomNote}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button variant="outlined" onClick={onGoBack}>
            <Typography variant="body1">Cancel</Typography>
          </Button>
          <Box mr={1} />
          <Button
            disableElevation
            disabled={!target.length}
            color="primary"
            variant="contained"
            onClick={onConfirm}
          >
            <Typography variant="body1">Send</Typography>
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  autocompleteRoot: {
    width: '100%',
    '& fieldset': {
      border: `1px solid ${theme.palette.grey['A100']} !important`,
    },
  },
  select: {
    height: 50,
  },
  input: {
    marginLeft: theme.spacing(1),
  },
  autocompleteInputRoot: {
    backgroundColor: theme.palette.common.white,
    minHeight: 40,
  },
  textField: {
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 3,
  },
  lengthText: {
    position: 'absolute',
    color: theme.palette.grey[500],
    bottom: theme.spacing(2),
    right: theme.spacing(1),
  },
}));

export default ShareDialogForm;
