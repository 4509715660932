import FormControlLabel from '@material-ui/core/FormControlLabel';
import { SwitchProps as MaterialSwitchProps } from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
// Lib
import { Switch } from './Switch';

export interface AccentSwitchProps extends MaterialSwitchProps {
  label: string;
  variant?: 'default' | 'colored';
}

export const AccentSwitch: React.VFC<AccentSwitchProps> = ({ label, variant, ...props }) => {
  const styles = useStyles();

  const handleClickOnLabel: React.MouseEventHandler<HTMLLabelElement> = (event) => {
    event.stopPropagation();
  };

  return (
    <FormControlLabel
      value="start"
      label={label}
      labelPlacement="start"
      classes={{ label: styles.label }}
      className={`${styles.root} ${props.checked ? styles.checked : ''}`}
      control={<Switch variant={variant} {...props} />}
      onClick={handleClickOnLabel}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 24,
    borderRadius: 18,
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1 / 2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    minWidth: 85,
    margin: 0,
  },
  checked: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    marginRight: theme.spacing(1),
  },
}));

export default AccentSwitch;
