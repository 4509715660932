import clsx from 'clsx';
// Material UI
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';

export interface SignFormLayoutProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  picture?: string | React.ReactNode;
  classes?: { root?: string; container?: string; content?: string };
}
export const SignFormLayout: React.FC<SignFormLayoutProps> = ({
  children,
  classes,
  picture,
  subtitle,
  title,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // helpers
  const hasPicture = !!picture;
  const hasSubtitle = !!subtitle;
  const hasTitle = !!title;
  const hasHeader = hasTitle || hasSubtitle || hasPicture;

  return (
    <div className={clsx(styles.root, !!classes?.root && classes?.root)}>
      <div className={clsx(styles.container, !!classes?.container && classes?.container)}>
        {hasHeader && (
          <>
            {hasPicture && <>{picture}</>}
            {hasTitle && (
              <Typography variant={isSmallScreen ? 'h2' : 'h1'} className={styles.title}>
                {title}
              </Typography>
            )}
            {hasSubtitle && (
              <Typography variant="h6" className={styles.subtitle}>
                {subtitle}
              </Typography>
            )}
          </>
        )}
        <div className={clsx(styles.content, !!classes?.content && classes?.content)}>
          {children}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: 'fit-content',
    textAlign: 'center',
    width: '100%',
  },
  container: {
    position: 'relative',
    maxWidth: '450px',
    margin: '0 auto',
    zIndex: 3,
  },
  title: {
    fontWeight: 500,
    margin: theme.spacing(2, 0, 1),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    maxWidth: '400px',
    margin: '0 auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    maxWidth: '350px',
    margin: '0 auto',
  },
}));

export default SignFormLayout;
