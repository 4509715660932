// Material UI
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Graphql
import { GenericKeyItemsDestinationList, IntegrationTypes } from '../../types';
// Lib Assets
import msTodoIntegrationIcon from '../../assets/icon-ms-todo-integration-24.svg';

interface MSTodoIntegrationItemProps {
  msTodoListItem: GenericKeyItemsDestinationList | null;
  onOpen: (dialogType: IntegrationTypes) => void;
  onSend: (
    msTodoListItem: GenericKeyItemsDestinationList | null,
    integrationType: IntegrationTypes,
  ) => void;
}

export const MSTodoIntegrationItem: React.VFC<MSTodoIntegrationItemProps> = ({
  msTodoListItem,
  onOpen,
  onSend,
}) => {
  /* #region Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* #endregion */

  /* #region Handlers */
  const handleOpenDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onOpen(IntegrationTypes.MS_TODO);
  };

  const handleClickToSend = () => {
    onSend(msTodoListItem, IntegrationTypes.MS_TODO);
  };
  /* #endregion */

  /* #region Helpers */
  const listName = msTodoListItem?.name || 'Select a destination';
  /* #endregion */

  return (
    <MenuItem className={styles.menuItem} onClick={handleClickToSend}>
      <div className={styles.content}>
        <img src={msTodoIntegrationIcon} alt="MS To do Integration" />
        <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
          Post item to:
        </Typography>
        <Tooltip arrow title={listName} classes={{ tooltip: styles.tooltip }}>
          <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
            <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              <b>{listName}</b>
            </Box>
          </Typography>
        </Tooltip>
      </div>
      <IconButton
        size="small"
        className={`${styles.iconButton} ghostly`}
        onClick={handleOpenDialog}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    </MenuItem>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .ghostly': {
      visibility: 'hidden',
      [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
      },
    },
    '&:hover .ghostly': {
      visibility: 'visible',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    gap: theme.spacing(1),
  },
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
  },
}));

export default MSTodoIntegrationItem;
