const formatHelper = (units: number) => (`${units}`.length === 1 ? `0${units}` : `${units}`);

export const convertSecondsToMinutes = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  return `${formatHelper(minutes)}:${formatHelper(seconds)}`;
};

export default convertSecondsToMinutes;
