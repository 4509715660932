import { utcToZonedTime } from 'date-fns-tz';

export function convertUtcToZonedTime(
  date: Date | string | number,
  timeZone: string = window.SemblyUserTimeZone || 'Etc/GMT',
): Date {
  return utcToZonedTime(date, timeZone);
}

export default convertUtcToZonedTime;
