import clsx from 'clsx';
import sanitizeHtml, { IOptions } from 'sanitize-html';
import { useState } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LinkIcon from '@material-ui/icons/Link';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
// Lib Shared
import { getNameInitials, convertSecondsToMinutes } from '../utils';
import ContentEditable, { ContentEditableEvent } from './ContentEditable';
import SplitIcon from '../icons/SplitIcon';
import Highlighter from './Highlighter';

/* #region  Component Types */
export interface TranscriptionItemData {
  id: string;
  name: string;
  startTime: number;
  transcriptionItemBookmarkId: string | null;
  userAvatar: string | null;
  userFullName: string | null;
  word: string;
}

export interface TranscriptionItemProps {
  button?: boolean;
  content?: string;
  dense?: boolean;
  hideAddedBookmarks?: boolean;
  isAuthorizedToEdit: boolean;
  isBookmarkable?: boolean;
  isBookmarked: boolean;
  isBookmarksWidget?: boolean;
  isEditing?: boolean;
  isRTLDirected?: boolean;
  sanitizeOptions?: IOptions;
  searchTerm?: { value: string; itemId: string } | null;
  transcriptionItem: TranscriptionItemData;
  onChangeContent?: (content: string) => void;
  onClick?: (diarizationItemId: string) => void;
  onClickOnActionsMenu?: (targetEl: HTMLButtonElement) => void;
  onClickOnAddBookmark?: (diarizationItemId: string) => void;
  onClickOnCopyLink?: (diarizationItemId: string) => void;
  onClickOnDelete?: (bookmarkId: string, diarizationItemId: string) => void;
  onClickOnParticipant?: (targetEl: HTMLButtonElement) => void;
  onClickOnSplit?: () => void;
  onClickOnTimestamp?: (timestamp: number) => void;
}
/* #endregion */

/**
 * @component TranscriptionItem
 * @description A component that renders a single transcription item.
 */
export const TranscriptionItem: React.FC<TranscriptionItemProps> = ({
  button = false,
  children,
  content,
  dense = false,
  hideAddedBookmarks = false,
  isAuthorizedToEdit,
  isBookmarkable = false,
  isBookmarked = false,
  isBookmarksWidget = false,
  isEditing,
  isRTLDirected = false,
  sanitizeOptions = { allowedTags: [], allowedAttributes: {} },
  searchTerm,
  transcriptionItem,
  onChangeContent = () => null,
  onClick,
  onClickOnActionsMenu = () => null,
  onClickOnAddBookmark = () => null,
  onClickOnCopyLink = () => null,
  onClickOnDelete = () => null,
  onClickOnParticipant = () => null,
  onClickOnSplit = () => null,
  onClickOnTimestamp = () => null,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const styles = useStyles();
  const [focused, setFocused] = useState(false);

  /* #region  Handlers */
  const handleCopyLink = async (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickOnCopyLink(transcriptionItem.id);
  };

  const handleClickOnTranscriptionItem = () => {
    if (onClick) onClick(transcriptionItem.id);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClickOnActionsMenu(e.currentTarget);
  };

  const handleOpenParticipantsMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClickOnParticipant(e.currentTarget);
  };

  const handleChangeContent = (e: ContentEditableEvent) => {
    const value = sanitizeHtml(e.target.value, sanitizeOptions);
    const isEmptyValue = value.trim() === '';
    if (!isEmptyValue) onChangeContent(value);
  };

  const handleClickOnTimestamp = (timestamp: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onClickOnTimestamp) onClickOnTimestamp(timestamp);
  };

  const handleAddBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClickOnAddBookmark(transcriptionItem.id);
  };

  const handleRemoveBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!transcriptionItem.transcriptionItemBookmarkId) return;
    onClickOnDelete(transcriptionItem.transcriptionItemBookmarkId, transcriptionItem.id);
  };

  const handleUpdateFocus = (value: boolean) => () => {
    setFocused(value);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const isClickable = !!onClick;
  const isEqualTermIds = searchTerm?.itemId === transcriptionItem.transcriptionItemBookmarkId;
  const hasSearchItemId = searchTerm?.itemId;
  const hasSearchTerm = !!searchTerm && (!hasSearchItemId || isEqualTermIds);
  const name = transcriptionItem.userFullName || transcriptionItem.name;
  const text = content === undefined ? transcriptionItem.word : content;
  const scrollId = isBookmarksWidget ? `bookmark-${transcriptionItem.id}` : transcriptionItem.id;
  /* #endregion */

  return (
    <>
      <ListItem
        dense={dense}
        button={button as any}
        alignItems="flex-start"
        className={styles.root}
        style={{
          cursor: isClickable ? 'pointer' : 'default',
          direction: isRTLDirected ? 'rtl' : 'inherit',
        }}
        data-scroll-id={scrollId}
        onClick={handleClickOnTranscriptionItem}
      >
        <ListItemAvatar style={{ minWidth: dense ? 36 : 50 }}>
          <Avatar
            alt={name}
            className={clsx(styles.avatar, dense && 'small')}
            src={transcriptionItem.userAvatar || undefined}
          >
            {transcriptionItem.userAvatar ? null : getNameInitials(name)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Box
              dir="auto"
              display="flex"
              alignItems={isSmallScreen ? 'start' : 'center'}
              flexDirection={isSmallScreen ? 'column' : 'row'}
              gridGap={6}
              style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}
            >
              {isAuthorizedToEdit ? (
                <Tooltip placement="top" title="Change speaker">
                  <Typography
                    component="span"
                    className={clsx(styles.title, dense && styles.denseFont)}
                    style={{ cursor: 'pointer' }}
                    onClick={handleOpenParticipantsMenu}
                  >
                    <b>{name}</b>
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  component="span"
                  className={clsx(styles.title, dense && styles.denseFont)}
                >
                  <b>{name}</b>
                </Typography>
              )}

              <Box display="flex" alignItems="center" flex={1} width="100%">
                <Box display="flex" alignItems="center" flex={1} my={1 / 2} gridGap={6}>
                  <Typography
                    className={clsx(
                      !!onClickOnTimestamp && styles.clickable,
                      dense && styles.denseFont,
                    )}
                    component="span"
                    color="textSecondary"
                    onClick={handleClickOnTimestamp(transcriptionItem.startTime)}
                  >
                    {convertSecondsToMinutes(transcriptionItem.startTime)}
                  </Typography>
                  {isBookmarkable && (
                    <>
                      {isBookmarked ? (
                        <IconButton
                          size="small"
                          title="Remove from Bookmarks"
                          disabled={isEditing}
                          className={clsx(hideAddedBookmarks && !isSmallScreen && styles.lurk)}
                          onClick={handleRemoveBookmark}
                        >
                          <BookmarkIcon fontSize="small" color="secondary" />
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={isEditing}
                          size="small"
                          title="Add to Bookmarks"
                          className={clsx(!isSmallScreen && styles.lurk)}
                          onClick={handleAddBookmark}
                        >
                          <BookmarkBorderIcon fontSize="small" />
                        </IconButton>
                      )}
                    </>
                  )}
                  {!isBookmarksWidget && (
                    <IconButton
                      size="small"
                      title="Play"
                      className={clsx(!isSmallScreen && styles.lurk)}
                      onClick={handleClickOnTimestamp(transcriptionItem.startTime)}
                    >
                      <PlayCircleOutlineIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <div className={styles.actions}>
                  <Box ml={1} className={isSmallScreen ? '' : styles.lurk}>
                    <IconButton size="small" title="Copy link" onClick={handleCopyLink}>
                      <LinkIcon fontSize="small" />
                    </IconButton>
                  </Box>

                  {isAuthorizedToEdit && (
                    <>
                      <Box ml={1} className={isSmallScreen ? '' : styles.lurk}>
                        <IconButton
                          size="small"
                          title="Split"
                          className={styles.splitBtn}
                          onClick={onClickOnSplit}
                        >
                          <SplitIcon />
                        </IconButton>
                      </Box>
                      <Box ml={1} className={isSmallScreen ? '' : styles.lurk}>
                        <IconButton size="small" title="More" onClick={handleOpenMenu}>
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </div>
              </Box>
            </Box>
          }
          secondary={
            <div dir="auto" className={styles.container}>
              <ContentEditable
                tagName="p"
                className={clsx(
                  styles.content,
                  dense && styles.denseFont,
                  hasSearchTerm && !focused && 'hidden',
                )}
                disabled={!isAuthorizedToEdit}
                isRTLDirected={isRTLDirected}
                textAlign={isRTLDirected ? 'right' : 'inherit'}
                html={text}
                onChange={handleChangeContent}
                onFocus={handleUpdateFocus(true)}
                onBlur={handleUpdateFocus(false)}
              />
              {hasSearchTerm && !focused && (
                <Highlighter
                  autoEscape
                  textToHighlight={text}
                  searchWords={[searchTerm?.value || '']}
                  className={clsx(styles.content, 'overlay', dense && styles.denseFont)}
                  style={{
                    direction: isRTLDirected ? 'rtl' : 'inherit',
                    textAlign: isRTLDirected ? 'right' : 'inherit',
                    right: isRTLDirected ? 0 : undefined,
                  }}
                />
              )}
            </div>
          }
        />
      </ListItem>
      {children}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    transition: 'background-color 1.5s linear',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      '& $lurk': {
        visibility: 'visible',
      },
    },
  },
  container: {
    position: 'relative',
    wordBreak: 'break-word',
    textAlign: 'initial',
  },
  content: {
    margin: 0,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    '&.overlay': {
      position: 'absolute',
      top: 0,
      pointerEvents: 'none',
    },
    '&.hidden': {
      opacity: 0,
    },
    '&.invalid': {
      border: `1px solid ${theme.palette.error.main}`,
      borderRadius: theme.shape.borderRadius,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  avatar: {
    height: 36,
    width: 36,
    '&.small': {
      fontSize: '0.75rem',
      height: 24,
      width: 24,
    },
  },
  actions: {
    display: 'flex',
    flex: 0,
  },
  lurk: {
    visibility: 'hidden',
  },
  title: {
    fontWeight: 500,
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
  },
  denseFont: theme.typography.body2,
  splitBtn: {
    '& svg': { width: '18px', height: '18px' },
  },
}));

export default TranscriptionItem;
