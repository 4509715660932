import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Icons
import ArrowRightIcon from '@material-ui/icons/ChevronRightSharp';
import CloseIcon from '@material-ui/icons/CloseSharp';
// Sembly UI
import { GenericDialog, formatInTimeZone, comparePlans } from '@sembly-ui';
// App Shared
import PlanStatsChip from './PlanStatsChip';
import PlanStatsListItem from './PlanStatsListItem';
import PlanStatsUpgradeBanner from './PlanStatsUpgradeBanner';
import { SemblianBanner } from '@shared/components';
import { ReactComponent as ChatbotUploadCreditsIcon } from '../assets/chatbot-upload-credits.svg';
import { ReactComponent as OnlineMeetingRecordingIcon } from '../assets/online-meeting-recording.svg';
import { ReactComponent as UploadAudioVideoIcon } from '../assets/upload-audio-video.svg';
import { Routes } from '@shared/enums';
// GraphQL Queries & Types
import planStatsQuery from '@shared/queries/PlanStats.graphql';
import { UserRole, PlanIDEnum, PlanStats } from '@gql-types';

export interface PlanStatsDialogProps {
  open: boolean;
  onClose: () => void;
}

export const PlanStatsDialog: React.VFC<PlanStatsDialogProps> = ({ open, onClose }) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const { data, refetch } = useQuery<PlanStats>(planStatsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  /* #endregion */

  /* #region  Utils */
  const convertSecondsToMinutes = (value: number): string => {
    if (!value || value < 0) return '0';
    return value < 60 ? '0' : `${Math.floor(value / 60)}`;
  };

  const getTitle = (): string => {
    switch (data?.me?.paymentCustomer?.plan?.id) {
      case PlanIDEnum.PERSONAL:
        return 'Personal Plan';
      case PlanIDEnum.PROFESSIONAL:
        return 'Professional Plan';
      case PlanIDEnum.TEAM:
        return 'Team Plan';
      case PlanIDEnum.TEAM_PLUS:
        return 'Team Plus Plan';
      case PlanIDEnum.ENTERPRISE:
        return 'Enterprise Plan';
      default:
        return 'Personal Plan';
    }
  };
  /* #endregion */

  const handleClickOnUpgradePlan = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    history.push(Routes.SettingsWorkspacePlans);
    onClose();
  };

  const handleClickOnChargeCredits = (e?: React.MouseEvent<HTMLSpanElement>) => {
    e?.stopPropagation();
    history.push(Routes.Credits);
    onClose();
  };

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  /* #region  Render Helpers */
  const me = data?.me;
  const paymentCustomer = me?.paymentCustomer;
  const features = paymentCustomer?.plan?.features;
  const isPersonalPlan = paymentCustomer?.plan?.id === PlanIDEnum.PERSONAL;
  const isDemoPeriodActivated = paymentCustomer?.isDemoPeriodActivated ?? false;
  const isDemoPeriodExpired = paymentCustomer?.isDemoPeriodExpired ?? false;
  const isDemoPeriod = isDemoPeriodActivated && !isDemoPeriodExpired;
  const isWorkspaceAdmin = data?.me?.role === UserRole.CUSTOMER_ADMIN;
  const hasAiAssociateSubscription = !!paymentCustomer?.aiAssociateSubscription;
  const isAiAssociateTrialActivated = paymentCustomer?.isAiAssociateTrialActivated ?? false;

  const userPlan = paymentCustomer?.plan?.id ?? PlanIDEnum.PERSONAL;
  const isPaidPlan = comparePlans(userPlan, 'equalsOrHigher', PlanIDEnum.PROFESSIONAL);
  const demoPeriodExpirationDate = paymentCustomer?.demoPeriodExpirationDate || new Date();
  const creditsLeft = Math.round(paymentCustomer?.credits ?? 0);
  const billingCycleEnd = paymentCustomer?.nextFeaturesUsageResetDate || new Date();
  const onlineSecondsLeft = paymentCustomer?.meetingsDurationSecondsLeft ?? 0;
  const onlineSecondsAmount = features?.staticLimits?.meetingsDurationSecondsLimit ?? 0;
  const uploadSecondsAmount = features?.dynamicLimits?.uploadRecordingsSecondsLimit ?? 0;
  const uploadSecondsUsed = paymentCustomer?.monthlyFeaturesUsage?.uploadRecordingsSecondsUsed ?? 0;
  const uploadSecondsLeft = uploadSecondsAmount - uploadSecondsUsed;
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      dialogProps={{
        open,
        fullWidth: true,
        maxWidth: 'sm',
        fullScreen: isSmallScreen,
        classes: { paper: styles.dialog },
      }}
      onClose={onClose}
    >
      <Box component={DialogContent} mb={3}>
        <Box display="flex" alignItems="center" mt={2} mb={2}>
          <Box flex={1} width="100%" style={{ paddingLeft: 44 }}>
            <Typography align="center" variant="h5">
              {getTitle()}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <div className={styles.list}>
          <PlanStatsListItem
            bonus={isDemoPeriod}
            icon={<OnlineMeetingRecordingIcon />}
            label="Online Meeting Recording"
            tooltip="Zoom, Microsoft Teams, Google Meet and microphone audio recording minutes included in your plan."
            value={
              isPaidPlan
                ? 'Unlimited'
                : {
                    usedMinutes: convertSecondsToMinutes(onlineSecondsLeft),
                    totalMinutes: convertSecondsToMinutes(onlineSecondsAmount),
                    type: 'online',
                  }
            }
          />
          <Divider light />
          <PlanStatsListItem
            bonus={isDemoPeriod}
            icon={<UploadAudioVideoIcon />}
            label="Upload Audio & Video"
            tooltip="Audio and video file upload minutes included in your plan."
            value={{
              usedMinutes: convertSecondsToMinutes(uploadSecondsLeft),
              totalMinutes: convertSecondsToMinutes(uploadSecondsAmount),
              type: 'upload',
            }}
          />
        </div>

        {isWorkspaceAdmin && isPersonalPlan && (
          <div className={styles.list}>
            <PlanStatsListItem
              icon={<ChatbotUploadCreditsIcon />}
              label="Upload Credits"
              tooltip="Purchase credits and spend them on extra audio & video file uploads."
            >
              <Button disableElevation variant="outlined" onClick={handleClickOnUpgradePlan}>
                <Typography component="span" variant="body2">
                  Upgrade
                </Typography>
              </Button>
            </PlanStatsListItem>
          </div>
        )}

        {isPaidPlan && (
          <div className={styles.list}>
            <PlanStatsListItem
              icon={<ChatbotUploadCreditsIcon />}
              label="Upload Credits"
              tooltip="Purchase credits and spend them on extra audio & video file uploads."
            >
              {isWorkspaceAdmin ? (
                <Box
                  gridGap={6}
                  display="flex"
                  alignItems="center"
                  style={{ cursor: isWorkspaceAdmin ? 'pointer' : 'default' }}
                  onClick={handleClickOnChargeCredits}
                >
                  <Typography component="span" variant="body1">
                    {creditsLeft}
                  </Typography>
                  <ArrowRightIcon color="disabled" />
                </Box>
              ) : (
                <Typography component="span" variant="body1">
                  {creditsLeft}
                </Typography>
              )}
            </PlanStatsListItem>
          </div>
        )}

        <Box mt={4}>
          {isDemoPeriod ? (
            <>
              <Typography component="div" align="center" variant="body2">
                <span>
                  We are happy you signed up! Your account is topped up with a one-time welcome
                </span>{' '}
                <PlanStatsChip>Bonus</PlanStatsChip>{' '}
                <span>
                  which is valid until {formatInTimeZone(demoPeriodExpirationDate, 'PP')}. Following
                  this date, your{' '}
                </span>{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://helpdesk.sembly.ai/hc/en-us/articles/15398332715665-Free-Personal-Plan-Conditions"
                >
                  default Personal plan limits
                </Link>{' '}
                will be put in place.
              </Typography>
            </>
          ) : (
            <Typography component="div" align="center" variant="body2">
              Plan limits will renew on {formatInTimeZone(billingCycleEnd, 'PP')}.
            </Typography>
          )}
        </Box>

        {isPersonalPlan && isWorkspaceAdmin && (
          <>
            <Box mt={4} mb={4}>
              <Divider light />
            </Box>
            <div className={styles.list}>
              <PlanStatsUpgradeBanner onClick={handleClickOnUpgradePlan} />
            </div>
          </>
        )}

        {isWorkspaceAdmin && !(hasAiAssociateSubscription || isAiAssociateTrialActivated) && (
          <Box mt={4}>
            <Divider light />
            <Box mt={4}>
              <SemblianBanner fontSize="small" className={styles.banner} />
            </Box>
          </Box>
        )}
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  banner: {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

export default PlanStatsDialog;
