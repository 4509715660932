import minBy from 'lodash/minBy';
import { DateTime } from 'luxon';
import { useState, useEffect, useLayoutEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
// App Shared
import { ACHIEVEMENT_TYPES } from '@shared/constants';
import { LOCAL_STORAGE_KEYS } from '@shared/constants';
import { Routes } from '@shared/enums';
import { checkIsNewsPromoted } from '@shared/containers/NewsPromotionOverlay';
import { useAuth } from '@shared/clients/authClient';
import { useUserInterface, useUserContext } from '@shared/hooks';
// GraphQL Queries and Types
import skipMutation from '@shared/queries/SkipUserGuide.graphql';
import userGuideItemsQuery from '@shared/queries/UserGuideItems.graphql';
import { SkipUserGuide, SkipUserGuideVariables } from '@gql-types/SkipUserGuide';
import { UserGuideTypes, UserGuideItems, UserRole, GenericUserGuideItem } from '@gql-types';

export function AchievementPromotionOverlay() {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isHomePage = !!useRouteMatch(Routes.Home);
  const isSharedMeetingsPage = !!useRouteMatch(Routes.SharedWithMe);
  const isBookmarksPage = !!useRouteMatch(Routes.Bookmarks);
  const isCommitmentsPage = !!useRouteMatch(Routes.MyCommitments);

  const user = useUserContext();
  const isAdmin = user.data?.me?.role === UserRole.CUSTOMER_ADMIN;
  const hasNews = user.data?.me?.hasUnreadRcNews ?? false;

  const [isLoggedIn] = useAuth();
  const { update } = useUserInterface();

  const [isNewsPromoted, setIsNewsPromoted] = useState(checkIsNewsPromoted());
  const [promotedFeature, setPromotedFeature] = useState<GenericUserGuideItem | null>(null);

  const { data } = useQuery<UserGuideItems>(userGuideItemsQuery, {
    skip: !isLoggedIn || checkIsAchievementPromoted(),
  });

  const [skip] = useMutation<SkipUserGuide, SkipUserGuideVariables>(skipMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleShowAllTips = () => {
    update({ isOpenAchievements: true });
    setPromotedFeature(null);
  };

  const handleAchievementDescription = (type: UserGuideTypes) => () => {
    setPromotedFeature(null);
    update({ currentAchievementType: type });
  };

  const handleSkipAchievementPromotion = (type: UserGuideTypes) => () => {
    skip({ variables: { type } });
    setPromotedFeature(null);
    localStorage.setItem(LOCAL_STORAGE_KEYS.lastAchievementPromotion, DateTime.local().toISODate());
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    function registerStorageEvent(event?: StorageEvent) {
      const targetKey = LOCAL_STORAGE_KEYS.lastNewsPromotion;
      if (event && event.key !== targetKey) return; // avoid unnecessary events
      setIsNewsPromoted(checkIsNewsPromoted());
    }

    window.addEventListener('storage', (e) => registerStorageEvent(e));

    return () => {
      window.removeEventListener('storage', (e) => registerStorageEvent(e));
    };
  }, []);

  useLayoutEffect(() => {
    if (checkIsAchievementPromoted()) return;
    const restrictedTypes = isAdmin ? [] : [UserGuideTypes.INVITE_TEAMMEMBER];
    const availablePromotions = data?.userGuideItems.filter(
      (item) => item.showNotification && !restrictedTypes.includes(item.type),
    );

    const timeout = setTimeout(() => {
      setPromotedFeature(minBy(availablePromotions, 'notificationsCount') || null);
    }, 1000 * 3);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [data?.userGuideItems, isAdmin]);
  /* #endregion */

  // render fallback
  if (!promotedFeature) return null;

  /* #region  Render Helpers */
  const achievement = ACHIEVEMENT_TYPES[promotedFeature.type];
  const isLastReminder = promotedFeature.notificationsCount + 1 >= promotedFeature.maxRepeating;
  const isVisible = isHomePage || isSharedMeetingsPage || isBookmarksPage || isCommitmentsPage;
  /* #endregion */

  return (
    <Grow in={!!promotedFeature} style={{ transformOrigin: '100% 100% 0' }}>
      <div
        className={styles.achievement}
        style={{
          visibility: isVisible ? 'visible' : 'hidden',
          bottom: hasNews && !isNewsPromoted ? 126 : 62,
        }}
      >
        <IconButton
          className={styles.dismiss}
          size="small"
          onClick={handleSkipAchievementPromotion(promotedFeature.type)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <Box display="flex" alignItems="center" mb={2}>
          <Box flex={1}>
            <Typography component="div" variant="body1">
              <b>Level up</b>
            </Typography>
          </Box>
          <Button
            className={styles.button}
            variant="outlined"
            aria-label="See all tips"
            onClick={handleShowAllTips}
          >
            <Typography noWrap variant="body2" component="span">
              See all tips
            </Typography>
          </Button>
        </Box>
        {!isSmallScreen && <img src={achievement.image} alt={achievement.title} width="100%" />}
        <Box textAlign="center" mt={1}>
          <Typography gutterBottom variant="body1">
            <b>{achievement.title}</b>
          </Typography>
          <Typography variant="body2">{achievement.description}</Typography>
          <Box mt={2}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={styles.button}
              aria-label="Show me how to do it"
              onClick={handleAchievementDescription(promotedFeature.type)}
            >
              <Typography variant="body2">Show me how</Typography>
            </Button>
            <Box component="span" mr={1} />
            <Button
              variant="outlined"
              className={styles.button}
              aria-label="Remind me later / Skip"
              onClick={handleSkipAchievementPromotion(promotedFeature.type)}
            >
              <Typography variant="body2">{isLastReminder ? 'Skip' : 'Remind me later'}</Typography>
            </Button>
          </Box>
        </Box>
      </div>
    </Grow>
  );
}

function checkIsAchievementPromoted(): boolean {
  const currentDate = DateTime.local();
  const lastPromotionDate = localStorage.getItem(LOCAL_STORAGE_KEYS.lastAchievementPromotion);

  return !!lastPromotionDate && DateTime.fromISO(lastPromotionDate).hasSame(currentDate, 'day');
}

const useStyles = makeStyles((theme) => ({
  achievement: {
    position: 'absolute',
    color: theme.palette.surface.contrastText,
    background: theme.palette.surface.main,
    borderRadius: theme.shape.borderRadius * 2,
    right: theme.spacing(2),
    padding: theme.spacing(2),
    width: 'calc(100vw - 40px)',
    maxWidth: 400,
    zIndex: 10,
  },
  dismiss: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[10],
    padding: theme.spacing(0.5),
    position: 'absolute',
    top: -10,
    right: -10,
    fontSize: 14,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  button: {
    color: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    '&:hover': {
      borderColor: theme.palette.grey[200],
    },
  },
  icon: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.surface.light,
  },
}));

export default AchievementPromotionOverlay;
