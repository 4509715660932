import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText, Typography, Box, List } from '@material-ui/core';
// Lib
import { convertSecondsToMinutes } from '../utils';

export interface TranscriptionItemTextProps {
  id: string;
  participantName: string;
  startTime: number;
  word: string;
}

export const TranscriptionItemText: React.FC<TranscriptionItemTextProps> = ({
  children,
  id,
  participantName,
  startTime,
  word,
}) => {
  const styles = useStyles();

  return (
    <ListItem className={styles.item} key={id}>
      <ListItemText
        disableTypography
        primary={
          <>
            <Box component="span" mr={1}>
              <Typography component="span" variant="h6">
                {participantName}
              </Typography>
            </Box>
            <Box component="span" mr={1}>
              <Typography component="span" color="textSecondary">
                {convertSecondsToMinutes(startTime)}
              </Typography>
            </Box>
          </>
        }
        secondary={
          <>
            <Typography component="p" variant="body1">
              {word}
            </Typography>
            {!!children && <List className={styles.crossTalkItem}>{children}</List>}
          </>
        }
      />
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    pageBreakInside: 'avoid',
  },
  crossTalkItem: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(-2),
  },
}));

export default TranscriptionItemText;
