import { useState, useRef, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import MicIcon from '@material-ui/icons/MicSharp';
// Sembly UI
import {
  GenericDialog,
  VoiceRecordingChunk,
  getMicrophonesList,
  getSelectedMicrophone,
  useMediaDevices,
} from '@sembly-ui';
// App Shared Assets
import philipsBackground from '@shared/assets/smartmike-doc.png';
import semblyBackground from '@shared/assets/enrollment-bg.png';
import { ReactComponent as ConnectorImage } from '@shared/assets/art-connector.svg';
import { ReactComponent as WaveImage } from '@shared/assets/art-wave.svg';
// App Shared
import { LocalMeetingRecorder, RecorderSettingsBox } from '@shared/components';
import { Routes } from '@shared/enums';
import { SMARTMIKE_DEVICE_ID } from '@shared/configuration';
import { SignFormLayout, OnboardingPageLayout } from '@shared/layouts';
import { graphErrorHorsemen } from '@shared/utils';
import { useUserContext } from '@shared/hooks';
// GraphQL Queries and Types
import createEnrollmentSampleMutation from '@shared/queries/CreateEnrollmentSample.graphql';
import meQuery from '@shared/queries/Me.graphql';
import { CreateEnrollmentSample, CreateEnrollmentSampleVariables } from '@gql-types/CreateEnrollmentSample'; // prettier-ignore
import { RecordDevices } from '@gql-types/globalTypes';
// Module Shared
import { EnrollmentSampleText } from '../components';

export const EnrollmentSampleCreator: React.VFC = () => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();
  const user = useUserContext();

  const recordingChunksRef = useRef<Blob[]>([]);

  const [hint, setHint] = useState('');
  const [isCheckingAudio, setIsCheckingAudio] = useState(false);
  const [isReadyToRecord, setIsReadyToRecord] = useState<boolean | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingComplete, setIsRecordingComplete] = useState(false);
  const [isVirtualDevice, setIsVirtualDevice] = useState(false);

  const isSmartMikeDeviceUser = user.data?.me?.philipsDevices?.smartmike ?? false;
  const isSmartMeetingDeviceUser = user.data?.me?.philipsDevices?.smartmeeting ?? false;
  const isSmartDeviceUser = isSmartMeetingDeviceUser || isSmartMikeDeviceUser;
  const deviceType = isSmartMikeDeviceUser ? RecordDevices.smartmike : RecordDevices.smartmeeting;
  const recordingDeviceType = isSmartDeviceUser ? deviceType : undefined;

  const [mediaDevices] = useMediaDevices({
    deviceName: isSmartMikeDeviceUser ? SMARTMIKE_DEVICE_ID : '',
  });
  /* #endregion */

  const [createEnrollment, { loading: initializingEnrollment }] = useMutation<
    CreateEnrollmentSample,
    CreateEnrollmentSampleVariables
  >(createEnrollmentSampleMutation, { refetchQueries: [meQuery] });

  /* #region  Handlers */
  const handleStartRecording = async () => {
    setIsCheckingAudio(false);
    setIsRecording(true);
    return true;
  };

  const handleSubmitRecordingChunk = async (chunk: VoiceRecordingChunk) => {
    recordingChunksRef.current.push(chunk.blob);

    if (chunk.isRecordingCompleted) {
      const date = format(new Date(), 'PP p');
      const title = `Enrollment Sample ${date}`;
      const chunks = recordingChunksRef.current || [];
      const audio = new File(chunks, `${title}.wav`);

      setIsRecording(false);
      const { data } = await createEnrollment({ variables: { audio } });

      if (data?.createEnrollmentSample?.success) {
        // ga.event({ category: 'Settings', action: 'Voice Id Activated' });
        setIsRecordingComplete(true);
      } else {
        graphErrorHorsemen(data?.createEnrollmentSample?.errors);
      }
    }
  };

  const handleDeleteRecording = () => {
    setIsRecording(false);
    recordingChunksRef.current = [];
  };

  const handleDeviceCheck = () => {
    setIsReadyToRecord(true);
  };

  const handleClose = () => {
    history.replace(Routes.Home);
  };

  const handleClickOnCheckAudio = () => {
    setIsCheckingAudio(true);
  };

  const handleFailureCheckAudio = (msg: string) => {
    toast.warn(msg);
    setIsCheckingAudio(false);
  };

  const handleChangeMicrophone = (deviceId: string, isVirtual: boolean) => {
    setIsVirtualDevice(isVirtual);
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    getMicrophonesList().then((devices) => {
      const selectedDeviceId = getSelectedMicrophone()?.deviceId || 'default';
      const selectedDevice = devices.find((device) => device.deviceId === selectedDeviceId);
      const selectedDeviceLabel = selectedDevice?.label.toLowerCase() ?? '';
      const isVirtualDeviceSelected = selectedDeviceLabel.includes('virtual');
      setIsVirtualDevice(isVirtualDeviceSelected);
    });
  }, []);

  useEffect(() => {
    if (!mediaDevices) {
      return;
    }
    // check browser support
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setIsReadyToRecord(false);
      setHint(`Audio recording is not supported in this browser.
        Please open this page in Google Chrome, Mozilla Firefox, or Microsoft Edge.`);
      return;
    }

    // check microphone permission
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        const hasRecordingDevice = !!mediaDevices.length;
        const msg = isSmartMikeDeviceUser
          ? `Sorry, we couldn't detect your SmartMike. Please make sure your SmartMike is connected.`
          : `Sorry, we couldn't detect your device for recording. Please make sure your device is connected.`;
        setIsReadyToRecord(hasRecordingDevice);
        setHint(hasRecordingDevice ? '' : msg);
      })
      .catch(() => {
        setIsReadyToRecord(false);
        setHint(
          `Please allow microphone access in your browser's permission settings and reload the page.`,
        );
      });
  }, [mediaDevices, isSmartMikeDeviceUser]);
  /* #endregion */

  const enrollmentTextEl = (
    <div className={styles.text}>
      <Typography gutterBottom variant={isSmallScreen ? 'h6' : 'h4'}>
        Enrollment Text:
      </Typography>
      <Box mt={4}>
        <EnrollmentSampleText />
      </Box>
    </div>
  );

  const RecordBtn = ({ onStartRecording = () => null }: { onStartRecording?: () => void }) => (
    <Button
      onClick={onStartRecording}
      disableElevation
      color="primary"
      variant="contained"
      aria-label="Record your voice sample"
      startIcon={<MicIcon />}
    >
      Record
    </Button>
  );

  if (isRecordingComplete) {
    return <Redirect to={Routes.HomeCreateEnrollmentComplete} />;
  }

  return (
    <GenericDialog hideTitle dialogProps={{ fullScreen: true }} onClose={handleClose}>
      {isReadyToRecord === null ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <CircularProgress size={38} />
        </Box>
      ) : isReadyToRecord ? (
        <OnboardingPageLayout
          isSmartMikeUser={isSmartMikeDeviceUser}
          background={theme.palette.grey[800]}
          content={enrollmentTextEl}
          footer={
            <Button disabled={isRecording} aria-label="Skip" onClick={handleClose}>
              <Typography noWrap variant={isSmallScreen ? 'body1' : 'inherit'}>
                Set Up Later
              </Typography>
            </Button>
          }
        >
          <SignFormLayout
            picture={!isSmallScreen ? <WaveImage className={styles.art} /> : undefined}
            title="Set Up Your Voice ID"
            subtitle={
              isRecording ? (
                <Typography variant={isSmallScreen ? 'body2' : 'body1'}>
                  Click 'Stop' once you've finished reading.
                </Typography>
              ) : (
                <Typography variant={isSmallScreen ? 'body2' : 'body1'}>
                  Let's set up your Voice ID to help Sembly recognize you in meetings. Please read
                  Enrollment Text into your microphone.
                </Typography>
              )
            }
          >
            <Box position="relative" mt={isSmallScreen ? 1 : 4}>
              {initializingEnrollment ? (
                <CircularProgress size={38} />
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <LocalMeetingRecorder
                    ui="compact"
                    skipStartConfirmation
                    skipDeleteConfirmation
                    chunkMaxDurationSeconds={2 * 60} // 2 minutes
                    maxDurationSeconds={2 * 60} // 2 minutes
                    recordingDeviceType={recordingDeviceType}
                    onDataAvailable={handleSubmitRecordingChunk}
                    onDeleteRecord={handleDeleteRecording}
                    onStartRecording={handleStartRecording}
                  >
                    <RecordBtn />
                  </LocalMeetingRecorder>

                  {isCheckingAudio ? (
                    <Box mt={2}>
                      <RecorderSettingsBox
                        onChangeDevice={handleChangeMicrophone}
                        onFailed={handleFailureCheckAudio}
                      />
                      {isVirtualDevice && (
                        <Box mt={1}>
                          <Typography variant="body2" color="textSecondary">
                            Virtual microphones may not work. Please make sure you're using the
                            working mic for recording
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <>
                      {!isRecording && (
                        <Box mt={2}>
                          {isVirtualDevice ? (
                            <Typography variant="body2" color="textSecondary">
                              <span>Virtual microphones may not work. </span>
                              <Link onClick={handleClickOnCheckAudio}>
                                Please make sure you're using the working mic for recording
                              </Link>
                            </Typography>
                          ) : (
                            <Link onClick={handleClickOnCheckAudio}>Check your audio</Link>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              )}
            </Box>
          </SignFormLayout>
        </OnboardingPageLayout>
      ) : (
        <OnboardingPageLayout
          background={`url(${isSmartMikeDeviceUser ? philipsBackground : semblyBackground})`}
          isSmartMikeUser={isSmartMikeDeviceUser}
          footer={
            <Button
              disabled={isRecording}
              aria-label="Skip creating enrollment for now"
              onClick={handleClose}
            >
              Set Up Later
            </Button>
          }
        >
          <SignFormLayout
            picture={<ConnectorImage className={styles.art} />}
            title="Let's get started..."
            subtitle={hint}
          >
            <Box mt={4} width="100%" minWidth={290}>
              {isReadyToRecord === null ? (
                <CircularProgress />
              ) : (
                <Button
                  fullWidth
                  disableElevation
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={!!mediaDevices?.length}
                  aria-label="Check your device"
                  onClick={handleDeviceCheck}
                >
                  Next
                </Button>
              )}
            </Box>
          </SignFormLayout>
        </OnboardingPageLayout>
      )}
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  art: {
    marginBottom: theme.spacing(4),
  },
  text: {
    color: theme.palette.grey[50],
    padding: theme.spacing(8),
    fontSize: 18,
    maxWidth: '110ch',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
    },
  },
  hidden: {
    display: 'none',
  },
}));

export default EnrollmentSampleCreator;
