import { DateTime } from 'luxon';
import { formatISO } from 'date-fns';
import { useEffect, useState, useContext } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useQuery, NetworkStatus } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
// App Shared
import { Routes } from '@shared/enums';
import { MeetingList } from '@shared/components';
import { MainPageLayoutContext } from '@shared/hooks/context';
import { MeetingModuleContainer } from '@modules/Meeting';
import {
  useCalendarsSyncStatus,
  useMeetingScrollTarget,
  useUserContext,
  useUserInterface,
} from '@shared/hooks';
// Module Shared
import { CalendarSyncedBanner, CalendarSyncingBanner, CalendarPromoBanner } from '../components';
import { UserOnboardingModal } from '../modals';
// GraphQL Queries and Types
import query from '@shared/queries/MyMeetingOverviewsPaginated.graphql';
import {
  GenericMeetingOverview,
  MeetingStatuses,
  MyMeetingOverviewsPaginated,
  MyMeetingOverviewsPaginatedVariables,
} from '@gql-types';

const Home: React.VFC = () => {
  /* #region  Hooks */
  const history = useHistory();

  const user = useUserContext();
  const { update } = useUserInterface();
  const [isSyncCalendars, stopSyncCalendars] = useCalendarsSyncStatus();

  const {
    meetingListStartDate,
    resetMeetingScrollSettings,
    updateMeetingListStartDate,
    updateMeetingScrollTarget,
  } = useMeetingScrollTarget();

  const layoutContext = useContext(MainPageLayoutContext);

  const [isRefetching, setIsRefetching] = useState(false);
  const [isOpenUserOnboarding, setIsOpenUserOnboarding] = useState(false);

  const timeZone = window.SemblyUserTimeZone || 'Etc/GMT';
  const today = DateTime.now().startOf('day').setZone(timeZone).toJSDate();

  const {
    data: upcomingData,
    networkStatus: upcomingNetStatus,
    fetchMore: fetchNextPages,
    refetch: refetchUpcoming,
  } = useQuery<MyMeetingOverviewsPaginated, MyMeetingOverviewsPaginatedVariables>(query, {
    skip: !user?.data?.me?.id,
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 1,
      perPage: 7,
      startTime: meetingListStartDate || formatISO(today),
    },
  });

  const {
    data: recentData,
    networkStatus: recentNetStatus,
    fetchMore: fetchPrevPages,
    refetch: refetchRecent,
  } = useQuery<MyMeetingOverviewsPaginated, MyMeetingOverviewsPaginatedVariables>(query, {
    skip: !user?.data?.me?.id,
    notifyOnNetworkStatusChange: true,
    variables: {
      orderBy: '-started_at',
      endTime: meetingListStartDate || formatISO(today),
      page: 1,
      perPage: 7,
      statuses: [
        MeetingStatuses.failed,
        MeetingStatuses.on_call,
        MeetingStatuses.on_pause,
        MeetingStatuses.processing,
        MeetingStatuses.submitted,
      ],
    },
  });
  /* #endregion */

  /* #region  Helpers */
  const getVisibleMeetings = (meetings: GenericMeetingOverview[] = []) => {
    const isShowAll = layoutContext?.showNoContentMeetings ?? false;
    return isShowAll
      ? meetings
      : meetings.filter(({ status }) => status !== MeetingStatuses.failed);
  };

  const isShowEntryVideo = user?.data?.me?.showEntryVideo;
  const isLoadingRecent = recentNetStatus === NetworkStatus.loading;
  const isLoadingUpcoming = upcomingNetStatus === NetworkStatus.loading;
  const isRefetchingRecent = recentNetStatus === NetworkStatus.refetch;
  const isRefetchingUpcoming = upcomingNetStatus === NetworkStatus.refetch;
  const isLoading =
    isLoadingRecent ||
    isLoadingUpcoming ||
    isRefetchingRecent ||
    isRefetchingUpcoming ||
    isRefetching;
  /* #endregion */

  /* #region  Handlers */
  const handleRequestNextPage = () => {
    resetMeetingScrollSettings('scroll-target');
    if (fetchNextPages && upcomingData?.myMeetingsPaginated?.page) {
      fetchNextPages({
        variables: {
          page: upcomingData.myMeetingsPaginated.page + 1,
        },
      });
    }
  };

  const handleRequestPrevPage = () => {
    resetMeetingScrollSettings('scroll-target');
    if (fetchPrevPages && recentData?.myMeetingsPaginated?.page) {
      fetchPrevPages({
        variables: {
          page: recentData.myMeetingsPaginated.page + 1,
        },
      });
    }
  };

  const handleJumpToDate = async (targetDate: string) => {
    setIsRefetching(true);
    let upcomingMeetings: GenericMeetingOverview[] = [];
    let recentMeetings: GenericMeetingOverview[] = [];

    await Promise.all([
      refetchUpcoming({
        page: 1,
        perPage: 7,
        startTime: targetDate,
      }).then((result) => {
        upcomingMeetings = result.data.myMeetingsPaginated?.objects ?? [];
      }),
      refetchRecent({
        orderBy: '-started_at',
        endTime: targetDate,
        page: 1,
        perPage: 7,
        statuses: [
          MeetingStatuses.failed,
          MeetingStatuses.on_call,
          MeetingStatuses.on_pause,
          MeetingStatuses.processing,
          MeetingStatuses.submitted,
        ],
      }).then((result) => {
        recentMeetings = result.data.myMeetingsPaginated?.objects ?? [];
      }),
    ]);

    const firstMeeting = upcomingMeetings?.[0] || recentMeetings?.[0];
    updateMeetingListStartDate(targetDate);
    if (firstMeeting?.id) updateMeetingScrollTarget(firstMeeting?.id);
    setIsRefetching(false);
  };

  const handleVerifyAgent = (meetingId: string) => {
    history.push(generatePath(Routes.HomeVerifyAgent, { meetingId }));
  };

  const handleGetStarted = () => {
    setIsOpenUserOnboarding(false);
    update({ isOpenIntroduction: true });
  };

  const handleReloadPage = () => {
    window.location.reload();
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    isShowEntryVideo && setIsOpenUserOnboarding(true);
  }, [isShowEntryVideo]);

  useEffect(() => {
    MeetingModuleContainer.preload();
  }, []);
  /* #endregion */

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" height="100%" paddingTop={8}>
        <CircularProgress />
      </Box>
    );
  }

  if (!(recentData?.myMeetingsPaginated && upcomingData?.myMeetingsPaginated)) {
    return null;
  }

  /* #region  Render Helpers */
  const recentMeetings = recentData?.myMeetingsPaginated?.objects || [];
  const upcomingMeetings = upcomingData?.myMeetingsPaginated?.objects || [];
  const isFetchingPrevPages = recentNetStatus === NetworkStatus.fetchMore;
  const isFetchingNextPages = upcomingNetStatus === NetworkStatus.fetchMore;
  const isRecentDataHasNext = recentData.myMeetingsPaginated.hasNext ?? false;
  const isUpcomingDataHasNext = upcomingData.myMeetingsPaginated.hasNext ?? false;
  const upcomingObjects = getVisibleMeetings(upcomingMeetings);
  const recentObjects = getVisibleMeetings(recentMeetings);
  const isCalendarConnected = user.data?.me?.isCalendarConnected ?? false;
  const isCalendarSyncActive = isSyncCalendars !== null;
  /* #endregion */

  return (
    <>
      {isCalendarSyncActive && (
        <>
          {isSyncCalendars ? (
            <CalendarSyncingBanner />
          ) : (
            <CalendarSyncedBanner onClose={stopSyncCalendars} onReload={handleReloadPage} />
          )}
        </>
      )}

      {!isCalendarSyncActive && !isCalendarConnected && <CalendarPromoBanner />}

      <MeetingList
        isFetchingNextPages={isFetchingNextPages}
        isFetchingPrevPages={isFetchingPrevPages}
        isRecentDataHasNext={isRecentDataHasNext}
        isUpcomingDataHasNext={isUpcomingDataHasNext}
        recentData={recentObjects}
        upcomingData={upcomingObjects}
        onJumpToDate={handleJumpToDate}
        onRequestNextPage={handleRequestNextPage}
        onRequestPrevPage={handleRequestPrevPage}
        onVerifyAgent={handleVerifyAgent}
      />
      {/* Begin: Dialogs */}
      <UserOnboardingModal open={isOpenUserOnboarding} onGetStarted={handleGetStarted} />
      {/* End: Dialogs */}
    </>
  );
};

export default Home;
