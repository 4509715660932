import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const SettingsModuleContainer = lazyWithPreload(
  () => import('./containers/SettingsModuleContainer'),
);

export const SettingsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Settings,
    render: () => <SettingsModuleContainer />,
  },
};

export default SettingsModule;
