import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// App Shared
import sendFeedbackMutation from '@shared/queries/SendFeedback.graphql';
import { SendFeedback, SendFeedbackVariables } from '@gql-types/SendFeedback';
import { FeedbackCriticality } from '@gql-types/globalTypes';
import { graphErrorHorsemen } from '@shared/utils';
// Sembly UI
import { GenericDialog } from '@sembly-ui';

export interface FeedbackDialogFormProps {
  open: boolean;
  onClose: () => void;
}

export const FeedbackDialogForm: React.VFC<FeedbackDialogFormProps> = ({ open, onClose }) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [recommendationRatingValue, setRecommendationRatingValue] = useState<number | null>(null);
  const [improvementSuggestionsValue, setImprovementSuggestionsValue] = useState('');
  const [criticalityValue, setCriticalityValue] = useState<FeedbackCriticality | null>(null);

  const [sendFeedback, { loading }] = useMutation<SendFeedback, SendFeedbackVariables>(
    sendFeedbackMutation,
  );
  /* #endregion */

  /* #region  Handlers */
  const handleCancel = () => {
    onClose();
    setRecommendationRatingValue(null);
    setImprovementSuggestionsValue('');
    setCriticalityValue(null);
  };

  const handleConfirm = async () => {
    if (
      criticalityValue &&
      improvementSuggestionsValue &&
      typeof recommendationRatingValue === 'number'
    ) {
      const result = await sendFeedback({
        variables: {
          criticality: criticalityValue,
          improvementSuggestions: improvementSuggestionsValue,
          recommendationRating: recommendationRatingValue,
        },
        optimisticResponse: {
          sendFeedback: {
            __typename: 'SendFeedbackMutationPayload',
            success: true,
            errors: [],
          },
        },
      });
      if (result.data?.sendFeedback?.success) {
        handleCancel();
        toast.dark('Thanks for the feedback!');
      } else {
        graphErrorHorsemen(result.data?.sendFeedback?.errors);
      }
    } else {
      toast.dark('Please fill in all the fields');
    }
  };

  const handleUpdateRaiting = (value: number) => () => {
    setRecommendationRatingValue(value);
  };

  const handleUpdateCriticality = (value: FeedbackCriticality) => () => {
    setCriticalityValue(value);
  };

  const handleUpdateImprovementSuggestions = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (!value.trim().length) value = '';
    setImprovementSuggestionsValue(value);
  };
  /* #endregion */

  return (
    <GenericDialog
      title="Feedback"
      dialogProps={{
        open,
        fullWidth: true,
        fullScreen: isSmallScreen,
      }}
      onClose={handleCancel}
    >
      <Box component={DialogContent} mt={1} mb={4}>
        <Box mt={1}>
          <Typography className={styles.title} paragraph variant="subtitle2">
            How likely are you to recommend Sembly to your friends?
          </Typography>
          <RadioGroup className={styles.listRecomendationsReting} value={recommendationRatingValue}>
            {Array.from(Array(11).keys()).map((elem, i) => (
              <IconButton
                key={i}
                onClick={handleUpdateRaiting(i)}
                className={`${styles.recommend} ${
                  i === recommendationRatingValue ? styles.checked : styles.border
                }`}
              >
                {i}
              </IconButton>
            ))}
          </RadioGroup>
        </Box>
        <Box mt={2}>
          <Typography paragraph variant="subtitle2">
            What can we add or change to improve Sembly?
          </Typography>
          <TextField
            fullWidth
            multiline
            minRows={4}
            InputProps={{ classes: { root: styles.textField } }}
            inputProps={{ maxLength: 512 }}
            variant="filled"
            value={improvementSuggestionsValue}
            onChange={handleUpdateImprovementSuggestions}
            placeholder="We’d love to hear your suggestions"
          />
        </Box>
        <Box mt={2}>
          <Typography paragraph variant="subtitle2">
            How important is this to you?
          </Typography>
          <RadioGroup className={styles.criticalityList} value={criticalityValue || ''}>
            {CRITICALITY_BUTTONS.map((button, index) => (
              <Button
                key={index}
                variant="outlined"
                className={`${styles.criticality} ${
                  button.value === criticalityValue ? styles.checked : styles.border
                }`}
                aria-label={`Select "${button.label}"`}
                onClick={handleUpdateCriticality(button.value)}
              >
                {button.label}
              </Button>
            ))}
          </RadioGroup>
        </Box>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button variant="outlined" aria-label="Cancel" onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            disabled={
              !criticalityValue ||
              !improvementSuggestionsValue ||
              recommendationRatingValue === null ||
              loading
            }
            aria-label="Submit"
            onClick={handleConfirm}
          >
            {loading ? <CircularProgress size={20} color="inherit" /> : <span>Submit</span>}
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default FeedbackDialogForm;

const CRITICALITY_BUTTONS = [
  { label: 'Nice-to-have', value: FeedbackCriticality.NICE_TO_HAVE },
  { label: 'Important', value: FeedbackCriticality.IMPORTANT },
  { label: 'Critical', value: FeedbackCriticality.CRITICAL },
] as const;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  recommend: {
    width: '42px',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    borderRadius: '25%',
    [theme.breakpoints.down('xs')]: {
      width: '22px',
    },
  },
  textField: {
    borderRadius: theme.shape.borderRadius * 3,
    paddingBottom: theme.spacing(3),
  },
  criticality: {
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
  },
  listRecomendationsReting: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  checked: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  criticalityList: { display: 'flex', flexDirection: 'row' },
  border: {
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));
