import { Fragment } from 'react';
// Material UI
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

export interface ChatItemsSkeletonProps {
  count?: number;
}

export const ChatItemsSkeleton: React.VFC<ChatItemsSkeletonProps> = ({ count = 10 }) => {
  const styles = useStyles();

  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Fragment key={index}>
          <div className={styles.question}>
            <Skeleton width="300px" height="24px" />
          </div>

          <div className={styles.answer}>
            <Skeleton className={styles.icon} width="40px" height="40px" variant="rect" />
            <Skeleton width="300px" height="24px" />
          </div>
        </Fragment>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  question: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2),
    gridGap: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 2,
    alignSelf: 'flex-end',
  },
  answer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    gridGap: theme.spacing(2),
  },
  icon: {
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

export default ChatItemsSkeleton;
