import { useState } from 'react';
import { toast } from 'react-toastify';
// Material UI
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
// Lib Shared
import { GenericDefaultUser } from '../types';
import { ShareDialogForm } from '../components';
import { getNameInitials, isValidEmail } from '../utils';
import { useAutocomplete } from '../hooks';

export interface ShareFormProps {
  onCloseShareForm: () => void;
  onSubmit: (users: { email: string; canManage: boolean }[], note: string) => void;
}

export type ShareFormTargetStateObject = {
  label: string;
  value: GenericDefaultUser;
  search: string;
};

type ShareFormPermissionState = 'view' | 'edit';

export const ShareForm: React.VFC<ShareFormProps> = ({ onCloseShareForm, onSubmit }) => {
  /* #region  Hooks */
  const [permission, setPermission] = useState<ShareFormPermissionState>('view');
  const [note, setNote] = useState('');

  const {
    isOpenShareDialog,
    isLoadingSuggestions,
    options,
    target,
    inviteValue,
    calledSubmit,
    handlePaste,
    setCalledSubmit,
    setTarget,
    handleChangeInput,
    handleChangeValue,
    setOpenShareDialog,
  } = useAutocomplete();
  /* #endregion */

  /* #region  Handlers */
  const handleChangePermission = (event: React.ChangeEvent<any>) => {
    setPermission(event.target.value as ShareFormPermissionState);
  };

  const handleCloseShareDialog = () => {
    setNote('');
    setTarget([]);

    setOpenShareDialog(false);
  };

  const handleChangeCustomNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.trimStart();
    setNote(value);
  };

  const handleSubmit = () => {
    if (!target.length) {
      toast.error(`Type an email address to invite people who aren't in your contacts list`);
      return setCalledSubmit(true);
    }

    const canManage = permission === 'edit';
    const users = Array.from(target, (elem) => {
      const email = typeof elem === 'string' ? elem : elem.value.email;
      return { email, canManage };
    });

    onSubmit(users, note);
    handleClose();
  };

  const handleClose = () => {
    handleCloseShareDialog();
    onCloseShareForm();
  };
  /* #endregion */

  /* #region Render Helpers */
  const renderOption = (
    option: string | ShareFormTargetStateObject,
    { inputValue }: AutocompleteRenderOptionState,
  ) => {
    const isString = typeof option === 'string';
    const isArray = Array.isArray(option);
    const key = isString ? `${option}-${inputValue}` : !isArray ? option.value.id : `${inputValue}`;
    const avatar = isString ? null : !isArray ? option.value.avatar : '';
    const email = isString ? null : !isArray ? option.value.email : '';
    const name = isString ? option : !isArray ? option.value.fullName : '';

    return (
      <ListItem
        dense
        key={key}
        component="div"
        style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <ListItemAvatar style={{ minWidth: 42 }}>
          <Avatar
            style={{ width: 28, height: 28 }}
            title={name}
            alt={name}
            src={avatar || undefined}
          >
            {avatar ? null : getNameInitials(name)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={name} secondary={email} />
      </ListItem>
    );
  };

  const renderOptionLabel = (option: string | ShareFormTargetStateObject) => {
    const isString = typeof option === 'string';
    const isArray = Array.isArray(option);

    return isString ? option : !isArray ? option.search : '';
  };

  const renderTags = (
    tags: (string | ShareFormTargetStateObject)[],
    getTagProps: AutocompleteGetTagProps,
  ) => {
    return tags.map((option, index) => {
      const isString = typeof option === 'string';
      const isArray = Array.isArray(option);
      const label = isString ? option : !isArray ? option.value.fullName : '';

      return <Chip variant="outlined" label={label} {...getTagProps({ index })} />;
    });
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        required
        placeholder="Enter name or email to share"
        size="small"
        variant="outlined"
        error={calledSubmit && !target}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoadingSuggestions ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };
  /* #endregion */

  return (
    <>
      <Autocomplete
        freeSolo
        fullWidth
        multiple
        getOptionLabel={renderOptionLabel}
        loading={isLoadingSuggestions}
        options={options.length ? options : isValidEmail(inviteValue) ? [inviteValue] : []}
        renderInput={renderInput}
        renderOption={renderOption}
        renderTags={renderTags}
        value={target}
        onChange={handleChangeValue}
        onInputChange={handleChangeInput}
        onPaste={handlePaste}
      />
      {/* Begin: Dialogs */}
      <ShareDialogForm
        note={note}
        open={isOpenShareDialog}
        permission={permission}
        target={target}
        onCancel={handleClose}
        onChangeCustomNote={handleChangeCustomNote}
        onChangePermission={handleChangePermission}
        onConfirm={handleSubmit}
        onGoBack={handleCloseShareDialog}
      />
      {/* End: Dialogs */}
    </>
  );
};

export default ShareForm;
