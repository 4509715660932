export const AGENT_EMAIL = 'agent@sembly.ai';
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;
export const APP_DRAWER_WIDTH = 250;
export const APP_NAME = 'Sembly AI';
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const CHROME_EXTENSION_ID = process.env.REACT_APP_CHROME_EXTENSION_ID;
export const CHROME_EXTENSION_URI = process.env.REACT_APP_CHROME_EXTENSION_URI || `https://chrome.google.com/webstore/detail/ai-meeting-summaries-zoom/kgncmlkaopailaafofodciacppkjcenl` //prettier-ignore
export const DEDICATED_SUBDOMAIN = process.env.REACT_APP_DEDICATED_SUBDOMAIN;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
export const MS_TEAMS_ADAPTER_INTEGRATION_CLIENT_ID = process.env.REACT_APP_MS_TEAMS_ADAPTER_INTEGRATION_CLIENT_ID; // prettier-ignore
export const NOTION_ASSIGNMENTS_CLIENT_ID = process.env.REACT_APP_NOTION_ASSIGNMENTS_CLIENT_ID;
export const NOTION_NOTES_CLIENT_ID = process.env.REACT_APP_NOTION_NOTES_CLIENT_ID;
export const OAUTH_REDIRECT_DOMAIN = process.env.REACT_APP_OAUTH_REDIRECT_DOMAIN;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;
export const SMARTMEETING_DEVICE_ID = process.env.REACT_APP_SMARTMEETING_DEVICE_ID || 'pse';
export const SMARTMIKE_DEVICE_ID = process.env.REACT_APP_SMARTMIKE_DEVICE_ID || 'smartmike';
export const STORAGE_AUTH_TOKEN_EXPIRATION_KEY = 'sembly_exp';
export const STORAGE_AUTH_TOKENS_KEY = 'sembly_auth';
export const STORAGE_REDIRECT_LOCATION_KEY = 'sembly_redirect_location';
export const STORAGE_SEMBLIAN_PROMOTION_CODE_KEY = 'semblian_promotion_code';
export const STORAGE_SKIP_SEMBLIAN_MEMBER_ONBOARDING_KEY = 'skip_semblian_member_onboarding';
export const STORAGE_SUBSCRIPTION_PROMOTION_CODE_KEY = 'subscription_promotion_code';
export const STORAGE_TARGET_SERVICE_PLAN_KEY = 'sembly_target_service_plan';
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const WHITELIST_DOMAINS = process.env.REACT_APP_WHITELIST_DOMAINS;
export const WSS_ENDPOINT = process.env.REACT_APP_WSS_ENDPOINT!;
export const ZAPIER_CLIENT_ID = process.env.REACT_APP_ZAPIER_CLIENT_ID;
export const ZOOM_CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID;

export const CONFIG = {
  AgentEmail: AGENT_EMAIL,
  APIEndpoint: API_ENDPOINT,
  AppDrawerWidth: APP_DRAWER_WIDTH,
  AppleClientId: APPLE_CLIENT_ID,
  AppName: APP_NAME,
  ChromeExtensionId: CHROME_EXTENSION_ID,
  ChromeExtensionUri: CHROME_EXTENSION_URI,
  DedicatedSubdomain: DEDICATED_SUBDOMAIN,
  GoogleClientId: GOOGLE_CLIENT_ID,
  MicrosoftClientId: MICROSOFT_CLIENT_ID,
  MSTeamsAdapterIntegrationClientId: MS_TEAMS_ADAPTER_INTEGRATION_CLIENT_ID,
  NotionAssignmentsClientId: NOTION_ASSIGNMENTS_CLIENT_ID,
  NotionNotesClientId: NOTION_NOTES_CLIENT_ID,
  OAuthRedirectDomain: OAUTH_REDIRECT_DOMAIN,
  SentryDSN: SENTRY_DSN,
  SlackClientId: SLACK_CLIENT_ID,
  SmartMeetingId: SMARTMEETING_DEVICE_ID,
  SmartMikeId: SMARTMIKE_DEVICE_ID,
  StorageAuthTokenExpirationKey: STORAGE_AUTH_TOKEN_EXPIRATION_KEY,
  StorageAuthTokensKey: STORAGE_AUTH_TOKENS_KEY,
  StorageRedirectLocationKey: STORAGE_REDIRECT_LOCATION_KEY,
  StorageSemblianPromotionCodeKey: STORAGE_SEMBLIAN_PROMOTION_CODE_KEY,
  StorageSkipSemblianMemberOnboardingKey: STORAGE_SKIP_SEMBLIAN_MEMBER_ONBOARDING_KEY,
  StorageSubscriptionPromotionCodeKey: STORAGE_SUBSCRIPTION_PROMOTION_CODE_KEY,
  StorageTargetServicePlanKey: STORAGE_TARGET_SERVICE_PLAN_KEY,
  StripeApiKey: STRIPE_API_KEY,
  WhitelistDomains: WHITELIST_DOMAINS,
  WSSEndpoint: WSS_ENDPOINT,
  ZapierClientId: ZAPIER_CLIENT_ID,
  ZoomClientId: ZOOM_CLIENT_ID,
} as const;

export default CONFIG;
