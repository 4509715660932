import { useHistory, useLocation } from 'react-router-dom';
// Material UI
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Icons
import AssignmentsIcon from '@shared/icons/Assignments';
import MeetingNotesIcon from '@material-ui/icons/Assignment';
import SubjectIcon from '@material-ui/icons/Subject';
import TimelineIcon from '@material-ui/icons/Timeline';
import { Creation as InsightsIcon } from '@sembly-ui';
// App Shared
import { Routes } from '@shared/enums';
import { generateTargetPath } from '@shared/utils';

export interface MeetingNavMenuMobileProps {
  meetingId: string;
  restrictAssignments?: boolean;
  restrictKeyItems?: boolean;
  restrictMeetingNotes?: boolean;
  restrictAiAssociate?: boolean;
}

/**
 * @name MeetingNavMenuMobile
 * @description The mobile version of the meeting's navigation menu
 * @param {MeetingNavMenuMobileProps} props
 * @returns {JSX.Element}
 */
export const MeetingNavMenuMobile: React.VFC<MeetingNavMenuMobileProps> = ({
  meetingId,
  restrictAssignments = false,
  restrictKeyItems = false,
  restrictMeetingNotes = false,
  restrictAiAssociate = false,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleChangeRoute = (route: Routes) => () => {
    history.push(generateTargetPath(route, meetingId));
  };

  return (
    <Tabs
      variant="fullWidth"
      textColor="primary"
      indicatorColor="secondary"
      className={styles.tabs}
      value={generateTargetPath(location.pathname, meetingId)}
    >
      {!restrictMeetingNotes && (
        <Tab
          wrapped={true}
          icon={<MeetingNotesIcon />}
          label={<Typography style={{ fontSize: 9 }}>Meeting Notes</Typography>}
          value={generateTargetPath(Routes.MeetingNotes, meetingId)}
          onClick={handleChangeRoute(Routes.MeetingNotes)}
        />
      )}
      {!restrictAiAssociate && (
        <Tab
          wrapped={true}
          icon={<InsightsIcon />}
          label={<Typography style={{ fontSize: 9 }}>My Insights</Typography>}
          value={generateTargetPath(Routes.MeetingInsights, meetingId)}
          onClick={handleChangeRoute(Routes.MeetingInsights)}
        />
      )}
      {!restrictAssignments && (
        <Tab
          wrapped={true}
          icon={<AssignmentsIcon />}
          label={<Typography style={{ fontSize: 9 }}>Tasks</Typography>}
          value={generateTargetPath(Routes.MeetingAssignments, meetingId)}
          onClick={handleChangeRoute(Routes.MeetingAssignments)}
        />
      )}
      {!restrictKeyItems && (
        <Tab
          wrapped={true}
          icon={<TimelineIcon />}
          label={<Typography style={{ fontSize: 9 }}>Key Items</Typography>}
          value={generateTargetPath(Routes.MeetingKeyItems, meetingId)}
          onClick={handleChangeRoute(Routes.MeetingKeyItems)}
        />
      )}
      <Tab
        wrapped={true}
        icon={<SubjectIcon />}
        label={<Typography style={{ fontSize: 9 }}>Transcription</Typography>}
        value={generateTargetPath(Routes.MeetingTranscript, meetingId)}
        onClick={handleChangeRoute(Routes.MeetingTranscript)}
      />
    </Tabs>
  );
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: '100%',
  },
}));

export default MeetingNavMenuMobile;
