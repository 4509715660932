import { alpha, makeStyles } from '@material-ui/core/styles';
import { Switch as MaterialSwitch, SwitchProps as MaterialSwitchProps } from '@material-ui/core';

export interface SwitchProps extends MaterialSwitchProps {
  variant?: 'default' | 'colored';
}

export const Switch: React.VFC<SwitchProps> = ({ size, variant = 'default', ...props }) => {
  const styles = useStyles({ variant, size, ...props });

  return (
    <MaterialSwitch
      disableRipple
      color="primary"
      classes={{
        root: styles.root,
        switchBase: styles.switchBase,
        thumb: styles.thumb,
        track: styles.track,
        checked: styles.checked,
        disabled: styles.disabled,
      }}
      inputProps={{ 'aria-label': 'controlled' }}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: (props: SwitchProps) =>
    props.size === 'small'
      ? {
          width: 20,
          height: 12,
          padding: 0,
          display: 'flex',
        }
      : {
          width: 40,
          height: 25,
          padding: 0,
        },
  switchBase: (props: SwitchProps) =>
    props.size === 'small'
      ? {
          padding: 3,
          color: theme.palette.common.white,
          transform: 'translate(-1px, -1px)',

          '&$checked': {
            color: theme.palette.common.white,
            transform: 'translate(7px, -1px)',
            '& + $track': {
              opacity: 1,
              borderColor: 'transparent',
              backgroundColor:
                props.variant === 'colored'
                  ? theme.palette.primary.main
                  : alpha(theme.palette.common.black, 0.2),
            },
          },
        }
      : {
          transform: 'translate(4px, 3px)',
          padding: 1,
          '& + $track': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            opacity: 1,
            border: 'none',
          },
        },
  checked: (props: SwitchProps) =>
    props.size === 'small'
      ? {}
      : {
          transform: 'translate(19px, 3px) !important',
          '& + $track': {
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
            border: 'none',
          },
        },
  disabled: {
    opacity: 0.2,
  },
  thumb: (props: SwitchProps) =>
    props.size === 'small'
      ? {
          width: 8,
          height: 8,
        }
      : {
          width: 16,
          height: 16,
        },
  track: (props: SwitchProps) =>
    props.size === 'small'
      ? {
          borderRadius: 16 / 2,
          opacity: 1,
          backgroundColor: theme.palette.grey[200],
          borderColor: 'transparent',
        }
      : {
          borderRadius: 26 / 2,
        },
}));

export default Switch;
