import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { FlowInstance } from '@integration-app/sdk';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
// Sembly UI
import { useIntegrations, useIntegrationApp, useFlowInstances } from '../clients';
// GraphQL Queries and Types
import addKeyItemIntegrationMutation from '../graphql/mutations/AddKeyItemsIntegrationRuleSettings.graphql';
import addNotionAssignmentsMutation from '../graphql/mutations/AddNotionAssignmentsRuleSettings.graphql';
import addNotionMeetingNotesMutation from '../graphql/mutations/AddNotionMeetingNotesRuleSettings.graphql';
import addSlackMeetingNotesMutation from '../graphql/mutations/AddSlackMeetingNotesRuleSettings.graphql';
import deleteKeyItemIntegrationMutation from '../graphql/mutations/DeleteKeyItemsIntegrationRuleSettings.graphql';
import deleteNotionAssignmentsMutation from '../graphql/mutations/DeleteNotionAssignmentsRuleSettings.graphql';
import deleteNotionMeetingNotesMutation from '../graphql/mutations/DeleteNotionMeetingNotesRuleSettings.graphql';
import deleteSlackMeetingNotesMutation from '../graphql/mutations/DeleteSlackMeetingNotesRuleSettings.graphql';
import disconnectOauthIntegrationMutation from '../graphql/mutations/DisconnectOauthIntegration.graphql';
import editKeyItemIntegrationMutation from '../graphql/mutations/EditKeyItemsIntegrationRuleSettings.graphql';
import editNotionAssignmentsMutation from '../graphql/mutations/EditNotionAssignmentsRuleSettings.graphql';
import editNotionMeetingNotesMutation from '../graphql/mutations/EditNotionMeetingNotesRuleSettings.graphql';
import editSlackMeetingNotesMutation from '../graphql/mutations/EditSlackMeetingNotesRuleSettings.graphql';
import outboundIntegrationsQuery from '../graphql/queries/UserOutboundIntegrations.graphql';
import userAutomationsQuery from '../graphql/queries/UserAutomations.graphql';
// Lib Shared
import IntegrationAppManager from '../dialogs/IntegrationAppManager';
import IntegrationAppsMarketplace from '../dialogs/IntegrationAppsMarketplace';
import ManageThirdPartyAutomationDialog from '../dialogs/ManageThirdPartyAutomationDialog';
import NotionAssignmentsAutomationRuleCreatorDialog, { NotionAssignmentsAutomationSettings } from '../dialogs/automations/NotionAssignmentsAutomationRuleCreatorDialog'; // prettier-ignore
import NotionMeetingNotesAutomationRuleCreatorDialog, { NotionMeetingNotesAutomationSettings } from '../dialogs/automations/NotionMeetingNotesAutomationRuleCreatorDialog'; // prettier-ignore
import SlackMeetingNotesAutomationRuleCreatorDialog from '../dialogs/automations/SlackAutomationRuleCreatorDialog';
import TodoAutomationRuleCreatorDialog from '../dialogs/automations/TodoAutomationRuleCreatorDialog';
import {
  AddKeyItemsIntegrationRuleSettingInput,
  AddKeyItemsIntegrationRuleSettings,
  AddKeyItemsIntegrationRuleSettingsVariables,
  AddNotionAssignmentsRuleSettingInput,
  AddNotionAssignmentsRuleSettings,
  AddNotionAssignmentsRuleSettingsVariables,
  AddNotionMeetingNotesRuleSettingInput,
  AddNotionMeetingNotesRuleSettings,
  AddNotionMeetingNotesRuleSettingsVariables,
  AddSlackMeetingNotesRuleSettingInput,
  AddSlackMeetingNotesRuleSettings,
  AddSlackMeetingNotesRuleSettingsVariables,
  DeleteKeyItemsIntegrationRuleSettings,
  DeleteKeyItemsIntegrationRuleSettingsVariables,
  DeleteNotionAssignmentsRuleSettings,
  DeleteNotionAssignmentsRuleSettingsVariables,
  DeleteNotionMeetingNotesRuleSettings,
  DeleteNotionMeetingNotesRuleSettingsVariables,
  DeleteSlackMeetingNotesRuleSettings,
  DeleteSlackMeetingNotesRuleSettingsVariables,
  DisconnectOauthIntegration,
  DisconnectOauthIntegrationVariables,
  EditKeyItemsIntegrationRuleSettingInput,
  EditKeyItemsIntegrationRuleSettings,
  EditKeyItemsIntegrationRuleSettingsVariables,
  EditNotionAssignmentsRuleSettingInput,
  EditNotionAssignmentsRuleSettings,
  EditNotionAssignmentsRuleSettingsVariables,
  EditNotionMeetingNotesRuleSettingInput,
  EditNotionMeetingNotesRuleSettings,
  EditNotionMeetingNotesRuleSettingsVariables,
  EditSlackMeetingNotesRuleSettingInput,
  EditSlackMeetingNotesRuleSettings,
  EditSlackMeetingNotesRuleSettingsVariables,
  GenericGoogleTasksAssignmentsAutomation,
  GenericMSTodoAssignmentsAutomation,
  GenericNotionAssignmentsAutomation,
  GenericNotionMeetingNotesAutomation,
  GenericSlackMeetingNotesAutomation,
  GraphError,
  Integration,
  IntegrationConnectRules,
  IntegrationTypes,
  PlanIDEnum,
  SemblyIntegration,
  UserAutomations,
  UserOutboundIntegrations,
} from '../types';
import {
  AutomationsPlaceholder,
  BrowseAppsButton,
  ConnectedAutomationCard,
  GenericConfirmation,
  IntegrationAppCard,
  NewAutomationMenu,
} from '../components';
import { useConfirmationDialog } from '../hooks';
// Lib Shared Assets
import googleTasksLogo from '../assets/integration-google-tasks.svg';
import msToDoLogo from '../assets/integration-ms-todo.svg';
import notionLogo from '../assets/integration-notion.svg';
import slackLogo from '../assets/integration-slack.svg';

export interface MyNativeAutomationsContainerProps {
  skip3rdPartyAutomations: boolean;
  onConnectFabricIntegration: (integrationName: string) => void;
  onConnectSemblyIntegration: (type: IntegrationTypes) => void;
  onDisconnectIntegration: (integrationName: string) => void;
  onIntegrationAppError: (err: Error | unknown) => void;
  onResponseError: (error: GraphError) => void;
}

type SemblyAutomation =
  | GenericGoogleTasksAssignmentsAutomation
  | GenericMSTodoAssignmentsAutomation
  | GenericNotionAssignmentsAutomation
  | GenericNotionMeetingNotesAutomation
  | GenericSlackMeetingNotesAutomation;

export const MyNativeAutomationsContainer: React.VFC<MyNativeAutomationsContainerProps> = ({
  skip3rdPartyAutomations,
  onConnectFabricIntegration,
  onConnectSemblyIntegration,
  onDisconnectIntegration,
  onIntegrationAppError,
  onResponseError,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isConnectingIntegration, setIsConnectingIntegration] = useState(false);
  const [showAppsDialog, setShowAppsDialog] = useState(false);
  const [showAppsMenu, setShowAppsMenu] = useState<HTMLElement | null>(null);
  const [selectedApp, setSelectedApp] = useState<Integration | null>(null);
  const [creatingAutomation, setCreatingAutomation] = useState<Integration | null>(null);
  const [editingFlowAutomation, setEditingFlowAutomation] = useState<FlowInstance | null>(null);
  const [editingSemblyAutomation, setEditingSemblyAutomation] = useState<SemblyAutomation | null>(
    null,
  );

  const { data, loading: loadingOutboundIntegrations } =
    useQuery<UserOutboundIntegrations>(outboundIntegrationsQuery);

  const { data: semblyData, loading: isLoadingSemblyInstances } =
    useQuery<UserAutomations>(userAutomationsQuery);

  const [addNotionMeetingNotesAutomation] = useMutation<
    AddNotionMeetingNotesRuleSettings,
    AddNotionMeetingNotesRuleSettingsVariables
  >(addNotionMeetingNotesMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [addNotionAssignmentsAutomation] = useMutation<
    AddNotionAssignmentsRuleSettings,
    AddNotionAssignmentsRuleSettingsVariables
  >(addNotionAssignmentsMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [addSlackMeetingNotesAutomation] = useMutation<
    AddSlackMeetingNotesRuleSettings,
    AddSlackMeetingNotesRuleSettingsVariables
  >(addSlackMeetingNotesMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [addKeyItemsIntegrationAutomation] = useMutation<
    AddKeyItemsIntegrationRuleSettings,
    AddKeyItemsIntegrationRuleSettingsVariables
  >(addKeyItemIntegrationMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [editNotionMeetingNotesAutomation] = useMutation<
    EditNotionMeetingNotesRuleSettings,
    EditNotionMeetingNotesRuleSettingsVariables
  >(editNotionMeetingNotesMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [editNotionAssignmentsAutomation] = useMutation<
    EditNotionAssignmentsRuleSettings,
    EditNotionAssignmentsRuleSettingsVariables
  >(editNotionAssignmentsMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [editSlackMeetingNotesAutomation] = useMutation<
    EditSlackMeetingNotesRuleSettings,
    EditSlackMeetingNotesRuleSettingsVariables
  >(editSlackMeetingNotesMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [editKeyItemsIntegrationAutomation] = useMutation<
    EditKeyItemsIntegrationRuleSettings,
    EditKeyItemsIntegrationRuleSettingsVariables
  >(editKeyItemIntegrationMutation, { refetchQueries: [{ query: userAutomationsQuery }] });

  const [deleteNotionMeetingNotesAutomation] = useMutation<
    DeleteNotionMeetingNotesRuleSettings,
    DeleteNotionMeetingNotesRuleSettingsVariables
  >(deleteNotionMeetingNotesMutation);

  const [deleteNotionAssignmentsAutomation] = useMutation<
    DeleteNotionAssignmentsRuleSettings,
    DeleteNotionAssignmentsRuleSettingsVariables
  >(deleteNotionAssignmentsMutation);

  const [deleteSlackMeetingNotesAutomation] = useMutation<
    DeleteSlackMeetingNotesRuleSettings,
    DeleteSlackMeetingNotesRuleSettingsVariables
  >(deleteSlackMeetingNotesMutation);

  const [deleteKeyItemsIntegrationAutomation] = useMutation<
    DeleteKeyItemsIntegrationRuleSettings,
    DeleteKeyItemsIntegrationRuleSettingsVariables
  >(deleteKeyItemIntegrationMutation);

  const [disconnectOauthIntegration] = useMutation<
    DisconnectOauthIntegration,
    DisconnectOauthIntegrationVariables
  >(disconnectOauthIntegrationMutation, {
    refetchQueries: [{ query: outboundIntegrationsQuery }, { query: userAutomationsQuery }],
  });

  const integrationApp = useIntegrationApp({ skip: skip3rdPartyAutomations });

  const {
    items: fabricIntegrations,
    refresh: refreshIntegrations,
    loading: loadingIntegrations,
    error: errorIntegrations,
  } = useIntegrations({ skip: skip3rdPartyAutomations, limit: 1000 });

  const {
    items: flowInstanceItems,
    loading: loadingFlowInstances,
    refresh: refreshFlowInstances,
    error: errorFlowInstances,
  } = useFlowInstances({ skip: skip3rdPartyAutomations, limit: 1000 });

  const [confirmDelete, DeleteConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Delete"
      text="Are you sure you want to delete this automation?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Delete"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));
  /* #endregion */

  /* #region  Handlers */
  const handleOpenAppsDialog = () => {
    setSelectedApp(null);
    setShowAppsDialog(true);
  };

  const handleOpenAppsMenu = (e: React.MouseEvent<HTMLElement>) => {
    setShowAppsMenu(e.currentTarget);
  };

  const handleOpenAppManager = (appId: string) => {
    const app = integrationItems.find((item) => item.id === appId);
    if (!app) throw new Error('There is no app with the provided id');
    setSelectedApp(app);
  };

  const handleCloseAppsMenu = () => {
    setShowAppsMenu(null);
  };

  const handleCreateAutomation = (app: Integration) => async () => {
    setCreatingAutomation(app);
  };

  const handleCreateSlackMeetingNotesAutomation = async (
    values: AddSlackMeetingNotesRuleSettingInput,
  ) => {
    setCreatingAutomation(null);
    const res = await addSlackMeetingNotesAutomation({
      variables: { settings: [values] },
      optimisticResponse: {
        addSlackMeetingNotesRuleSettings: {
          __typename: 'AddSlackMeetingNotesRuleSettingsMutationPayload',
          success: true,
          errors: [],
        },
      },
    });

    if (!res.data?.addSlackMeetingNotesRuleSettings?.success) {
      onResponseError(res.data?.addSlackMeetingNotesRuleSettings?.errors);
    }
  };

  const handleCreateNotionAssignmentsAutomation = async (
    values: AddNotionAssignmentsRuleSettingInput,
  ) => {
    setCreatingAutomation(null);
    const res = await addNotionAssignmentsAutomation({
      variables: { settings: [values] },
      optimisticResponse: {
        addNotionAssignmentsRuleSettings: {
          __typename: 'AddNotionAssignmentsRuleSettingsMutationPayload',
          success: true,
          errors: [],
        },
      },
    });

    if (!res.data?.addNotionAssignmentsRuleSettings?.success) {
      onResponseError(res.data?.addNotionAssignmentsRuleSettings?.errors);
    }
  };

  const handleCreateNotionMeetingNotesAutomation = async (
    values: AddNotionMeetingNotesRuleSettingInput,
  ) => {
    setCreatingAutomation(null);
    const res = await addNotionMeetingNotesAutomation({
      variables: { settings: [values] },
      optimisticResponse: {
        addNotionMeetingNotesRuleSettings: {
          __typename: 'AddNotionMeetingNotesRuleSettingsMutationPayload',
          success: true,
          errors: [],
        },
      },
    });

    if (!res.data?.addNotionMeetingNotesRuleSettings?.success) {
      onResponseError(res.data?.addNotionMeetingNotesRuleSettings?.errors);
    }
  };

  const handleCreateKeyItemsAutomation =
    (integrationType: IntegrationTypes) =>
    async (values: AddKeyItemsIntegrationRuleSettingInput) => {
      setCreatingAutomation(null);
      const res = await addKeyItemsIntegrationAutomation({
        variables: {
          settings: {
            integrationType,
            settings: [values],
          },
        },
        optimisticResponse: {
          addKeyItemsIntegrationRuleSettings: {
            __typename: 'AddKeyItemsIntegrationRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!res.data?.addKeyItemsIntegrationRuleSettings?.success) {
        onResponseError(res.data?.addKeyItemsIntegrationRuleSettings?.errors);
      }
    };

  const handleEditSlackMeetingNotesAutomation = async (
    values: EditSlackMeetingNotesRuleSettingInput,
  ) => {
    setEditingSemblyAutomation(null);
    const res = await editSlackMeetingNotesAutomation({
      variables: { settings: [values] },
      optimisticResponse: {
        editSlackMeetingNotesRuleSettings: {
          __typename: 'EditSlackMeetingNotesRuleSettingsMutationPayload',
          success: true,
          errors: [],
        },
      },
    });

    if (!res.data?.editSlackMeetingNotesRuleSettings?.success) {
      onResponseError(res.data?.editSlackMeetingNotesRuleSettings?.errors);
    }
  };

  const handleEditNotionAssignmentsAutomation = async (
    values: EditNotionAssignmentsRuleSettingInput,
  ) => {
    setEditingSemblyAutomation(null);

    if (values) {
      const res = await editNotionAssignmentsAutomation({
        variables: { settings: [values] },
        optimisticResponse: {
          editNotionAssignmentsRuleSettings: {
            __typename: 'EditNotionAssignmentsRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!res.data?.editNotionAssignmentsRuleSettings?.success) {
        onResponseError(res.data?.editNotionAssignmentsRuleSettings?.errors);
      }
    } else {
      throw new Error(`The "role" is invalid, please check all fields in the role settings.`);
    }
  };

  const handleEditNotionMeetingNotesAutomation = async (
    values: EditNotionMeetingNotesRuleSettingInput,
  ) => {
    setEditingSemblyAutomation(null);

    if (values) {
      const res = await editNotionMeetingNotesAutomation({
        variables: { settings: [values] },
        optimisticResponse: {
          editNotionMeetingNotesRuleSettings: {
            __typename: 'EditNotionMeetingNotesRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!res.data?.editNotionMeetingNotesRuleSettings?.success) {
        onResponseError(res.data?.editNotionMeetingNotesRuleSettings?.errors);
      }
    } else {
      throw new Error(`The "role" is invalid, please check all fields in the role settings.`);
    }
  };

  const handleEditKeyItemsAutomation =
    (integrationType: IntegrationTypes) =>
    async (values: EditKeyItemsIntegrationRuleSettingInput) => {
      setEditingSemblyAutomation(null);
      const res = await editKeyItemsIntegrationAutomation({
        variables: {
          settings: {
            settings: [values],
            integrationType,
          },
        },
        optimisticResponse: {
          editKeyItemsIntegrationRuleSettings: {
            __typename: 'EditKeyItemsIntegrationRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!res.data?.editKeyItemsIntegrationRuleSettings?.success) {
        onResponseError(res.data?.editKeyItemsIntegrationRuleSettings?.errors);
      }
    };

  const handleToggleSemblAutomationActivity = (automation: SemblyAutomation) => async () => {
    if (automation.__typename === 'SlackMeetingNotesRuleSettingType') {
      if (!automation.rule) {
        throw new Error('No rule provided for slack meeting notes automation');
      }

      const result = await editSlackMeetingNotesAutomation({
        variables: {
          settings: [
            {
              id: automation.id,
              description: automation.description,
              destinationSlackId: automation.destination.slackId,
              isActive: !automation.isActive,
              keywords: automation.keywords,
              rule: automation.rule,
              meetingType: automation.meetingType,
            },
          ],
        },
        optimisticResponse: {
          editSlackMeetingNotesRuleSettings: {
            __typename: 'EditSlackMeetingNotesRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!result.data?.editSlackMeetingNotesRuleSettings?.success) {
        onResponseError(result.data?.editSlackMeetingNotesRuleSettings?.errors);
      }
    } else if (automation.__typename === 'NotionMeetingNotesRuleSettingType') {
      if (!automation.rule) {
        throw new Error('No rule provided for notion meeting notes automation');
      }
      const result = await editNotionMeetingNotesAutomation({
        variables: {
          settings: [
            {
              description: automation.description,
              id: automation.id,
              destinationDatabaseId: automation.destination.database.id,
              destinationPageId: automation.destination.page.id,
              isActive: !automation.isActive,
              keywords: automation.keywords,
              rule: automation.rule,
              meetingType: automation.meetingType,
            },
          ],
        },
        optimisticResponse: {
          editNotionMeetingNotesRuleSettings: {
            __typename: 'EditNotionMeetingNotesRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!result.data?.editNotionMeetingNotesRuleSettings?.success) {
        onResponseError(result.data?.editNotionMeetingNotesRuleSettings?.errors);
      }
    } else if (automation.__typename === 'NotionAssignmentsRuleSettingType') {
      if (!automation.rule) {
        throw new Error('No rule provided for notion assignments automation');
      }
      const result = await editNotionAssignmentsAutomation({
        variables: {
          settings: [
            {
              contentTypes: automation.contentTypes,
              description: automation.description,
              destinationDatabaseId: automation.destination.database.id,
              destinationPageId: automation.destination.page.id,
              id: automation.id,
              isActive: !automation.isActive,
              keywords: automation.keywords,
              rule: automation.rule,
              meetingType: automation.meetingType,
            },
          ],
        },
        optimisticResponse: {
          editNotionAssignmentsRuleSettings: {
            __typename: 'EditNotionAssignmentsRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!result.data?.editNotionAssignmentsRuleSettings?.success) {
        onResponseError(result.data?.editNotionAssignmentsRuleSettings?.errors);
      }
    } else if (automation.__typename === 'GoogleTasksAssignmentsRuleSettingType') {
      if (!automation.rule) {
        throw new Error('No rule provided for google tasks assignments automation');
      }

      const result = await editKeyItemsIntegrationAutomation({
        variables: {
          settings: {
            integrationType: IntegrationTypes.GOOGLE_TASKS,
            settings: [
              {
                id: automation.id,
                isActive: !automation.isActive,
                keywords: automation.keywords,
                rule: automation.rule,
                contentTypes: automation.contentTypes,
                description: automation.description,
                destinationListId: automation.destination.id,
                meetingType: automation.meetingType,
              },
            ],
          },
        },
        optimisticResponse: {
          editKeyItemsIntegrationRuleSettings: {
            __typename: 'EditKeyItemsIntegrationRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!result.data?.editKeyItemsIntegrationRuleSettings?.success) {
        onResponseError(result.data?.editKeyItemsIntegrationRuleSettings?.errors);
      }
    } else if (automation.__typename === 'MSTodoAssignmentsRuleSettingType') {
      if (!automation.rule) {
        throw new Error('No rule provided for ms todo assignments automation');
      }

      const result = await editKeyItemsIntegrationAutomation({
        variables: {
          settings: {
            integrationType: IntegrationTypes.MS_TODO,
            settings: [
              {
                id: automation.id,
                isActive: !automation.isActive,
                keywords: automation.keywords,
                rule: automation.rule,
                contentTypes: automation.contentTypes,
                description: automation.description,
                destinationListId: automation.destination.id,
                meetingType: automation.meetingType,
              },
            ],
          },
        },
        optimisticResponse: {
          editKeyItemsIntegrationRuleSettings: {
            __typename: 'EditKeyItemsIntegrationRuleSettingsMutationPayload',
            success: true,
            errors: [],
          },
        },
      });

      if (!result.data?.editKeyItemsIntegrationRuleSettings?.success) {
        onResponseError(result.data?.editKeyItemsIntegrationRuleSettings?.errors);
      }
    } else {
      throw new Error('Unsupported automation type');
    }
  };

  const handleCloseFabricAutomationEditor = async (
    isDiscarded: boolean,
    automation?: FlowInstance,
  ) => {
    setCreatingAutomation(null);
    setEditingFlowAutomation(null);

    if (!!automation && isDiscarded) {
      try {
        await integrationApp.flowInstance({ id: automation.id })?.delete();
      } catch (error) {
        console.warn(error);
      }
    }

    refreshFlowInstances();
  };

  const handleCloseSemblyAutomationEditor = () => {
    setEditingSemblyAutomation(null);
  };

  const handleCloseAutomationCreator = () => {
    setCreatingAutomation(null);
  };

  const handleClickOnApp = (app: Integration) => () => {
    setSelectedApp(app);
  };

  const handleToggleAutomation =
    (automation: FlowInstance) => async (e: unknown, enabled: boolean) => {
      if (!automation.integration) throw new Error('No integration provided for flow instance');
      try {
        await integrationApp.flowInstance({ id: automation.id }).patch({ enabled });
        refreshFlowInstances();
      } catch (error) {
        onIntegrationAppError(error as Error);
      }
    };

  const handleEditFlowAutomation = (automation: FlowInstance) => async () => {
    setEditingFlowAutomation(automation);
  };

  const handleEditSemblAutomation = (automation: SemblyAutomation) => async () => {
    setEditingSemblyAutomation(automation);
  };

  const handleDeleteFabricAutomation = (automation: FlowInstance) => async () => {
    if (!automation.integration) throw new Error('No integration provided for flow instance');

    const confirmed = await confirmDelete();
    if (!confirmed) return;

    try {
      await integrationApp.flowInstance({ id: automation.id }).delete();
      refreshFlowInstances();
    } catch (error) {
      onIntegrationAppError(error as Error);
    }
  };

  const handleDeleteSemblyAutomation =
    (
      automation:
        | GenericMSTodoAssignmentsAutomation
        | GenericNotionAssignmentsAutomation
        | GenericNotionMeetingNotesAutomation
        | GenericSlackMeetingNotesAutomation
        | GenericGoogleTasksAssignmentsAutomation,
    ) =>
    async () => {
      const confirmed = await confirmDelete();
      if (!confirmed) return;

      if (automation.__typename === 'SlackMeetingNotesRuleSettingType') {
        const result = await deleteSlackMeetingNotesAutomation({
          variables: { ids: [automation.id] },
          optimisticResponse: {
            deleteSlackMeetingNotesRuleSettings: {
              __typename: 'DeleteSlackMeetingNotesRuleSettingsMutationPayload',
              errors: [],
              success: true,
            },
          },
          update: (cache) => {
            const id = cache.identify({
              __typename: 'SlackMeetingNotesRuleSettingType',
              id: automation.id,
            });
            cache.evict({ id });
            cache.gc();
          },
        });

        if (!result.data?.deleteSlackMeetingNotesRuleSettings?.success) {
          onResponseError(result.data?.deleteSlackMeetingNotesRuleSettings?.errors);
        }
      } else if (automation.__typename === 'NotionMeetingNotesRuleSettingType') {
        const result = await deleteNotionMeetingNotesAutomation({
          variables: { ids: [automation.id] },
          optimisticResponse: {
            deleteNotionMeetingNotesRuleSettings: {
              __typename: 'DeleteNotionMeetingNotesRuleSettingsMutationPayload',
              errors: [],
              success: true,
            },
          },
          update: (cache) => {
            const id = cache.identify({
              __typename: 'NotionMeetingNotesRuleSettingType',
              id: automation.id,
            });
            cache.evict({ id });
            cache.gc();
          },
        });

        if (!result.data?.deleteNotionMeetingNotesRuleSettings?.success) {
          onResponseError(result.data?.deleteNotionMeetingNotesRuleSettings?.errors);
        }
      } else if (automation.__typename === 'NotionAssignmentsRuleSettingType') {
        const result = await deleteNotionAssignmentsAutomation({
          variables: { ids: [automation.id] },
          optimisticResponse: {
            deleteNotionAssignmentsRuleSettings: {
              __typename: 'DeleteNotionAssignmentsRuleSettingsMutationPayload',
              errors: [],
              success: true,
            },
          },
          update: (cache) => {
            const id = cache.identify({
              __typename: 'NotionAssignmentsRuleSettingType',
              id: automation.id,
            });
            cache.evict({ id });
            cache.gc();
          },
        });

        if (!result.data?.deleteNotionAssignmentsRuleSettings?.success) {
          onResponseError(result.data?.deleteNotionAssignmentsRuleSettings?.errors);
        }
      } else if (automation.__typename === 'GoogleTasksAssignmentsRuleSettingType') {
        const result = await deleteKeyItemsIntegrationAutomation({
          variables: {
            settings: {
              ids: [automation.id],
              integrationType: IntegrationTypes.GOOGLE_TASKS,
            },
          },
          optimisticResponse: {
            deleteKeyItemsIntegrationRuleSettings: {
              __typename: 'DeleteKeyItemsIntegrationRuleSettingsMutationPayload',
              errors: [],
              success: true,
            },
          },
          update: (cache) => {
            const id = cache.identify({
              __typename: 'GoogleTasksAssignmentsRuleSettingType',
              id: automation.id,
            });
            cache.evict({ id });
            cache.gc();
          },
        });

        if (!result.data?.deleteKeyItemsIntegrationRuleSettings?.success) {
          onResponseError(result.data?.deleteKeyItemsIntegrationRuleSettings?.errors);
        }
      } else if (automation.__typename === 'MSTodoAssignmentsRuleSettingType') {
        const result = await deleteKeyItemsIntegrationAutomation({
          variables: {
            settings: {
              ids: [automation.id],
              integrationType: IntegrationTypes.MS_TODO,
            },
          },
          optimisticResponse: {
            deleteKeyItemsIntegrationRuleSettings: {
              __typename: 'DeleteKeyItemsIntegrationRuleSettingsMutationPayload',
              errors: [],
              success: true,
            },
          },
          update: (cache) => {
            const id = cache.identify({
              __typename: 'MSTodoAssignmentsRuleSettingType',
              id: automation.id,
            });
            cache.evict({ id });
            cache.gc();
          },
        });

        if (!result.data?.deleteKeyItemsIntegrationRuleSettings?.success) {
          onResponseError(result.data?.deleteKeyItemsIntegrationRuleSettings?.errors);
        }
      } else {
        throw new Error('Unsupported automation type');
      }
    };

  const handleConnectFabricApp = (app: Integration) => async () => {
    if (app.type === 'fabric') {
      try {
        setIsConnectingIntegration(true);
        const spec = await integrationApp.integration(app.id).getConnectorSpec();

        if (!!spec.ui) {
          setSelectedApp(null);
          await integrationApp.integration(app.id).open({ showPoweredBy: false });
          setSelectedApp(app);
        } else {
          const result = await integrationApp.integration(app.id).connect({
            allowMultipleConnections: false,
          });

          if (!!result?.id) onConnectFabricIntegration(app.name);
        }
        setIsConnectingIntegration(false);
      } catch (error) {
        console.warn(error);
        setIsConnectingIntegration(false);
      }

      refreshIntegrations();
    } else if (app.type === 'sembly') {
      onConnectSemblyIntegration(app.integrationType);
    } else {
      throw new Error('Unsupported app type');
    }
  };

  const handleDisconnectApp = (app: Integration) => async () => {
    if (app.type === 'fabric') {
      try {
        await integrationApp.integration(app.id).disconnect();
        onDisconnectIntegration(app.name);
      } catch (error) {
        onIntegrationAppError(error as Error);
      }
      refreshIntegrations();
    } else if (app.type === 'sembly') {
      const integrationId = app.integration?.id;
      if (!integrationId) throw new Error('Integration id is not provided');
      const result = await disconnectOauthIntegration({
        variables: { integrationId: +integrationId },
        optimisticResponse: {
          disconnectOauthIntegration: {
            __typename: 'DisconnectOAuthIntegrationMutationPayload',
            errors: [],
            success: true,
          },
        },
        update: (cache) => {
          const id = cache.identify({ __typename: 'IntegrationType', id: integrationId });
          cache.evict({ id });
          cache.gc();
        },
      });

      if (result.data?.disconnectOauthIntegration?.success) {
        onDisconnectIntegration(app.name);
      } else {
        onResponseError(result.data?.disconnectOauthIntegration?.errors);
      }
    } else {
      throw new Error('Unsupported app type');
    }
  };
  /* #endregion */

  /* #region  Render Helpers */
  const supportedSemblyIntegrations: Record<string, SemblyIntegration> = {
    [IntegrationTypes.NOTION_MEETING_NOTES]: {
      type: 'sembly',
      id: 'sembly-notion-meeting-notes',
      key: 'notion-meeting-notes',
      name: 'Notion Meeting Notes',
      logoUri: notionLogo,
      connection: false,
      integrationType: IntegrationTypes.NOTION_MEETING_NOTES,
      integration: null,
    },
    [IntegrationTypes.NOTION_ASSIGNMENTS]: {
      type: 'sembly',
      id: 'sembly-notion-assignments',
      key: 'notion-assignments',
      name: 'Notion Assignments',
      logoUri: notionLogo,
      connection: false,
      integrationType: IntegrationTypes.NOTION_ASSIGNMENTS,
      integration: null,
    },
    [IntegrationTypes.SLACK_USER]: {
      type: 'sembly',
      id: 'sembly-slack',
      key: 'slack',
      name: 'Slack Meeting Notes',
      logoUri: slackLogo,
      connection: false,
      integrationType: IntegrationTypes.SLACK_USER,
      integration: null,
    },
    [IntegrationTypes.GOOGLE_TASKS]: {
      type: 'sembly',
      id: 'sembly-google-tasks',
      key: 'google-tasks',
      name: 'Google Tasks',
      logoUri: googleTasksLogo,
      connection: false,
      integrationType: IntegrationTypes.GOOGLE_TASKS,
      integration: null,
    },
    [IntegrationTypes.MS_TODO]: {
      type: 'sembly',
      id: 'sembly-microsoft-todo',
      key: 'microsoft-todo',
      name: 'Microsoft To Do',
      logoUri: msToDoLogo,
      connection: false,
      integrationType: IntegrationTypes.MS_TODO,
      integration: null,
    },
  };

  const semblyIntegrations: SemblyIntegration[] = Object.entries(supportedSemblyIntegrations).map(
    ([_, supportedIntegrations]) => {
      const connectedIntegrations = data?.outboundIntegrations || [];
      const integration = connectedIntegrations.find(
        (connectedIntegration) =>
          connectedIntegration.type === supportedIntegrations.integrationType,
      );
      return {
        ...supportedIntegrations,
        connection: !!integration,
        integration: integration || null,
      };
    },
  );

  const fabricIntegrationItems: Integration[] = fabricIntegrations.map((item) => ({
    type: 'fabric',
    ...item,
  }));

  const integrationItems = [...semblyIntegrations, ...fabricIntegrationItems];
  const semblyInstanceItems = semblyData?.userIntegrationsRuleSettings || [];

  // Sort integration items based on connection status and name
  const sortedIntegrationItems = integrationItems.sort((a, b) => {
    if (!!a.connection && !b.connection) return -1;
    if (!a.connection && !!b.connection) return 1;
    return a.name.localeCompare(b.name);
  });

  const sortedConnectedIntegrationItems = sortedIntegrationItems.filter((item) => item.connection);
  const connectedFlowAutomations = flowInstanceItems.filter((item) => !!item.integration);

  let currentSelectedApp: Integration | null = selectedApp;
  // Get the latest version of the selected app if it's a fabric app
  if (selectedApp?.type === 'fabric') {
    currentSelectedApp = integrationItems.find((item) => item.id === selectedApp?.id) || null;
  }

  const getSemblyAutomationMeta = (automation: SemblyAutomation) => {
    // prettier-ignore
    const mapping: Record<typeof automation.__typename, SemblyIntegration> = {
      NotionAssignmentsRuleSettingType: supportedSemblyIntegrations[IntegrationTypes.NOTION_ASSIGNMENTS],
      NotionMeetingNotesRuleSettingType: supportedSemblyIntegrations[IntegrationTypes.NOTION_MEETING_NOTES],
      SlackMeetingNotesRuleSettingType: supportedSemblyIntegrations[IntegrationTypes.SLACK_USER],
      GoogleTasksAssignmentsRuleSettingType: supportedSemblyIntegrations[IntegrationTypes.GOOGLE_TASKS],
      MSTodoAssignmentsRuleSettingType: supportedSemblyIntegrations[IntegrationTypes.MS_TODO],
    };
    return mapping[automation.__typename];
  };

  const renderSemblyAutomationCreator = (automation: SemblyIntegration) => {
    if (automation.integrationType === IntegrationTypes.NOTION_ASSIGNMENTS) {
      return (
        <NotionAssignmentsAutomationRuleCreatorDialog
          editRule={null}
          paymentPlan={data?.me?.paymentCustomer?.plan?.id || PlanIDEnum.PERSONAL}
          onClose={handleCloseAutomationCreator}
          onCreate={handleCreateNotionAssignmentsAutomation}
          onEdit={handleEditNotionAssignmentsAutomation}
          onResponsesError={onResponseError}
        />
      );
    }

    if (automation.integrationType === IntegrationTypes.NOTION_MEETING_NOTES) {
      return (
        <NotionMeetingNotesAutomationRuleCreatorDialog
          editRule={null}
          onClose={handleCloseAutomationCreator}
          onCreate={handleCreateNotionMeetingNotesAutomation}
          onEdit={handleEditNotionMeetingNotesAutomation}
          onResponseError={onResponseError}
        />
      );
    }

    if (automation.integrationType === IntegrationTypes.SLACK_USER) {
      return (
        <SlackMeetingNotesAutomationRuleCreatorDialog
          editRule={null}
          onClose={handleCloseAutomationCreator}
          onCreate={handleCreateSlackMeetingNotesAutomation}
          onEdit={handleEditSlackMeetingNotesAutomation}
        />
      );
    }

    if (
      automation.integrationType === IntegrationTypes.GOOGLE_TASKS ||
      automation.integrationType === IntegrationTypes.MS_TODO
    ) {
      return (
        <TodoAutomationRuleCreatorDialog
          editRule={null}
          onClose={handleCloseAutomationCreator}
          onEdit={handleEditKeyItemsAutomation(automation.integrationType)}
          onCreate={handleCreateKeyItemsAutomation(automation.integrationType)}
          integrationType={automation.integrationType}
          paymentPlan={data?.me?.paymentCustomer?.plan?.id || PlanIDEnum.PERSONAL}
        />
      );
    }

    throw new Error('Unsupported automation type');
  };

  const renderSemblyAutomatioManager = (automation: SemblyAutomation) => {
    if (automation.__typename === 'NotionAssignmentsRuleSettingType') {
      const editRule: NotionAssignmentsAutomationSettings = {
        id: automation.id,
        description: automation.description,
        contentTypes: automation.contentTypes,
        database: automation.destination.database,
        page: automation.destination.page,
        isActive: automation.isActive ?? false,
        keywords: automation.keywords,
        meetingType: automation.meetingType,
        rule: IntegrationConnectRules[automation.rule!],
      };
      return (
        <NotionAssignmentsAutomationRuleCreatorDialog
          editRule={editRule}
          onClose={handleCloseSemblyAutomationEditor}
          onCreate={handleCreateNotionAssignmentsAutomation}
          onEdit={handleEditNotionAssignmentsAutomation}
          onResponsesError={onResponseError}
          paymentPlan={data?.me?.paymentCustomer?.plan?.id || PlanIDEnum.PERSONAL}
        />
      );
    }

    if (automation.__typename === 'NotionMeetingNotesRuleSettingType') {
      const editRule: NotionMeetingNotesAutomationSettings = {
        id: automation.id,
        description: automation.description,
        keywords: automation.keywords,
        meetingType: automation.meetingType,
        database: automation.destination.database,
        page: automation.destination.page,
        isActive: automation.isActive ?? false,
        rule: IntegrationConnectRules[automation.rule!],
      };
      return (
        <NotionMeetingNotesAutomationRuleCreatorDialog
          editRule={editRule}
          onClose={handleCloseSemblyAutomationEditor}
          onCreate={handleCreateNotionMeetingNotesAutomation}
          onEdit={handleEditNotionMeetingNotesAutomation}
          onResponseError={onResponseError}
        />
      );
    }

    if (automation.__typename === 'SlackMeetingNotesRuleSettingType') {
      const editRule: EditSlackMeetingNotesRuleSettingInput = {
        id: automation.id,
        description: automation.description,
        destinationSlackId: automation.destination.slackId,
        isActive: automation.isActive,
        keywords: automation.keywords,
        meetingType: automation.meetingType,
        rule: IntegrationConnectRules[automation.rule!],
      };

      return (
        <SlackMeetingNotesAutomationRuleCreatorDialog
          editRule={editRule}
          onClose={handleCloseSemblyAutomationEditor}
          onCreate={handleCreateSlackMeetingNotesAutomation}
          onEdit={handleEditSlackMeetingNotesAutomation}
        />
      );
    }

    if (
      automation.__typename === 'GoogleTasksAssignmentsRuleSettingType' ||
      automation.__typename === 'MSTodoAssignmentsRuleSettingType'
    ) {
      const editRule: EditKeyItemsIntegrationRuleSettingInput = {
        id: automation.id,
        description: automation.description,
        contentTypes: automation.contentTypes,
        isActive: automation.isActive,
        destinationListId: automation.destination.id,
        keywords: automation.keywords,
        meetingType: automation.meetingType,
        rule: IntegrationConnectRules[automation.rule!],
      };

      let integrationType: IntegrationTypes | null = null;

      if (automation.__typename === 'GoogleTasksAssignmentsRuleSettingType') {
        integrationType = IntegrationTypes.GOOGLE_TASKS;
      } else if (automation.__typename === 'MSTodoAssignmentsRuleSettingType') {
        integrationType = IntegrationTypes.MS_TODO;
      } else {
        throw new Error('Unsupported key item automation type');
      }

      return (
        <TodoAutomationRuleCreatorDialog
          editRule={editRule}
          integrationType={integrationType}
          paymentPlan={data?.me?.paymentCustomer?.plan?.id || PlanIDEnum.PERSONAL}
          onClose={handleCloseSemblyAutomationEditor}
          onCreate={handleCreateKeyItemsAutomation(integrationType)}
          onEdit={handleEditKeyItemsAutomation(integrationType)}
        />
      );
    }

    throw new Error('Unsupported automation type');
  };
  /* #endregion */

  if (errorIntegrations || errorFlowInstances) {
    onIntegrationAppError(errorIntegrations || errorFlowInstances);
  }

  return (
    <>
      <Box pt={2}>
        <div className={styles.header}>
          <Typography variant="h6">Automations</Typography>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Create a new automation"
            startIcon={<AddIcon />}
            disabled={loadingFlowInstances || loadingOutboundIntegrations}
            onClick={handleOpenAppsMenu}
          >
            <Typography component="span" variant="body1">
              {isSmallScreen ? 'New' : 'New Automation'}
            </Typography>
          </Button>
          {showAppsMenu && (
            <NewAutomationMenu
              anchorEl={showAppsMenu}
              items={sortedConnectedIntegrationItems}
              onBrowseApps={handleOpenAppsDialog}
              onClose={handleCloseAppsMenu}
              onClick={(app) => setCreatingAutomation(app)}
            />
          )}
        </div>

        <div className={styles.connectedIntegrations}>
          {loadingFlowInstances || isLoadingSemblyInstances ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  variant="rect"
                  key={index}
                  className={styles.connectedIntegrationSkeleton}
                />
              ))}
            </>
          ) : (
            <>
              {!connectedFlowAutomations.length && !semblyInstanceItems.length ? (
                <AutomationsPlaceholder />
              ) : (
                <>
                  {connectedFlowAutomations.map((item) => (
                    <ConnectedAutomationCard
                      enabled={item.enabled}
                      description={''}
                      key={item.id}
                      logoUri={item.integration!.logoUri}
                      name={item.name || item.integration!.name}
                      onDelete={handleDeleteFabricAutomation(item)}
                      onEdit={handleEditFlowAutomation(item)}
                      onToggleEnabled={handleToggleAutomation(item)}
                    />
                  ))}
                  {semblyInstanceItems.map((item) => {
                    const meta = getSemblyAutomationMeta(item);
                    return (
                      <ConnectedAutomationCard
                        key={`${item.__typename}_${item.id}`}
                        name={item.description}
                        description={meta.name}
                        enabled={item.isActive}
                        logoUri={meta.logoUri}
                        onDelete={handleDeleteSemblyAutomation(item)}
                        onEdit={handleEditSemblAutomation(item)}
                        onToggleEnabled={handleToggleSemblAutomationActivity(item)}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>

        <div className={styles.header}>
          <Typography variant="h6">Apps</Typography>
        </div>

        <Box mt={3}>
          <BrowseAppsButton onClick={handleOpenAppsDialog} />
        </Box>

        <Box my={3}>
          <Grid container spacing={2}>
            {loadingIntegrations ? (
              <>
                {Array.from({ length: 8 }).map((_, index) => (
                  <Grid item key={index} xs={6} sm={6} md={4} lg={3}>
                    <Skeleton
                      variant="rect"
                      key={index}
                      className={styles.integrationAppSkeleton}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {sortedIntegrationItems.slice(0, 8).map((item) => (
                  <Grid item key={item.id} xs={6} sm={6} md={4} lg={3}>
                    <IntegrationAppCard
                      name={item.name}
                      logoUri={item.logoUri}
                      connected={!!item.connection}
                      onClick={handleClickOnApp(item)}
                    />
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {showAppsDialog && (
        <IntegrationAppsMarketplace
          open
          apps={sortedIntegrationItems}
          onClickOnApp={handleOpenAppManager}
          onClose={() => setShowAppsDialog(false)}
        />
      )}
      {currentSelectedApp && (
        <IntegrationAppManager
          open
          name={currentSelectedApp.name}
          connected={!!currentSelectedApp.connection}
          connecting={isConnectingIntegration}
          identifier={currentSelectedApp.key}
          logoUri={currentSelectedApp.logoUri}
          onBrowseApps={handleOpenAppsDialog}
          onClose={() => setSelectedApp(null)}
          onConnect={handleConnectFabricApp(currentSelectedApp)}
          onDisconnect={handleDisconnectApp(currentSelectedApp)}
          onNewAutomation={handleCreateAutomation(currentSelectedApp)}
        />
      )}
      {creatingAutomation && (
        <>
          {creatingAutomation.type === 'fabric' ? (
            <ManageThirdPartyAutomationDialog
              open
              automation={creatingAutomation}
              containerType="personal"
              flowInstanceId={creatingAutomation.id}
              restrict3rdPartyIntegrations={skip3rdPartyAutomations}
              onClose={handleCloseFabricAutomationEditor}
              onComplete={refreshFlowInstances}
              onIntegrationAppError={onIntegrationAppError}
            />
          ) : (
            renderSemblyAutomationCreator(creatingAutomation)
          )}
        </>
      )}
      {editingFlowAutomation && (
        <ManageThirdPartyAutomationDialog
          open
          automation={editingFlowAutomation.integration!}
          containerType="personal"
          flowInstanceId={editingFlowAutomation.id}
          flowInstanceKey={editingFlowAutomation.instanceKey}
          restrict3rdPartyIntegrations={skip3rdPartyAutomations}
          onClose={handleCloseFabricAutomationEditor}
          onComplete={refreshFlowInstances}
          onIntegrationAppError={onIntegrationAppError}
        />
      )}
      {editingSemblyAutomation && renderSemblyAutomatioManager(editingSemblyAutomation)}
      {DeleteConfirmationDialog}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  connectedIntegrations: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  connectedIntegrationSkeleton: {
    height: 64,
    borderRadius: theme.shape.borderRadius * 2,
  },
  integrationAppSkeleton: {
    width: '100%',
    height: 114,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
