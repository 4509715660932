import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { getNameInitials } from '../utils';

export type MeetingParticipantAvatarProps = AvatarProps & {
  avatar?: string | null;
  name: string;
};

export const MeetingParticipantAvatar: React.VFC<MeetingParticipantAvatarProps> = ({
  avatar,
  name,
  ...props
}) => (
  <Avatar {...props} title={name} alt={name} src={avatar || undefined}>
    {avatar ? null : getNameInitials(name)}
  </Avatar>
);

export default MeetingParticipantAvatar;
