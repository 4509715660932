import { Switch, Route, Redirect } from 'react-router-dom';
// App Shared
import { MainPageLayout } from '@shared/layouts';
import { Routes as R } from '@shared/enums';
import { VerifyAgentModal } from '@shared/dialogs';
// Module Shared
import HomePage from '../routes/Home';
import EnrollmentSampleComplete from '../containers/EnrollmentSampleComplete';
import EnrollmentSampleCreator from '../containers/EnrollmentSampleCreator';

export const HomeModuleContainer: React.VFC = () => {
  const renderVerifyAgentModal = () => <VerifyAgentModal />;
  const renderEnrollmentSampleCreator = () => <EnrollmentSampleCreator />;
  const renderEnrollmentSampleComplete = () => <EnrollmentSampleComplete />;

  return (
    <MainPageLayout>
      <Switch>
        <Route strict exact path={R.HomeVerifyAgent} render={renderVerifyAgentModal} />
        <Route path={R.HomeCreateEnrollmentSample} render={renderEnrollmentSampleCreator} />
        <Route path={R.HomeCreateEnrollmentComplete} render={renderEnrollmentSampleComplete} />
        <Route
          path={R.Home}
          render={({ match }) => !match?.isExact && <Redirect to={R.PageNotFound} />}
        />
      </Switch>
      <HomePage />
    </MainPageLayout>
  );
};

export default HomeModuleContainer;
