import { SvgIcon } from '@material-ui/core';

export const Webex = () => (
  <SvgIcon viewBox="0 0 48 48">
    <path
      fill="#7cb342"
      d="M32.334,13.733c0.092,0.706,0.144,1.424,0.144,2.155c0,9.181-7.463,16.623-16.669,16.623	c-0.733,0-1.453-0.052-2.161-0.144C14.305,33.584,15.895,36.132,19,38c0.699,0.421,3.13,1.851,6,2c7.195,0.374,14.844-7.424,15-15	c0.021-1.024,0.041-4.057-2-7C36.061,15.203,33.379,14.101,32.334,13.733z"
    />
    <path
      fill="#29b6f6"
      d="M10.661,24c0-7.315,5.947-13.246,13.283-13.246c3.668,0,6.989,1.483,9.392,3.88l4.789-4.776	C34.496,6.239,29.482,4,23.944,4C12.867,4,3.888,12.954,3.888,24c0,5.523,2.245,10.523,5.874,14.142l4.789-4.776	C12.148,30.969,10.661,27.658,10.661,24z"
    />
    <path
      fill="#244b71"
      d="M38.126,9.858c-1.323-1.319-3.467-1.319-4.789,0c-1.323,1.319-1.323,3.457,0,4.776c0,0,0,0,0,0	c2.404,2.397,3.89,5.708,3.89,9.366c0,7.315-5.947,13.246-13.283,13.246c-3.668,0-6.989-1.483-9.392-3.88l0,0	c-1.323-1.319-3.467-1.319-4.789,0c-1.323,1.319-1.323,3.457,0,4.776C13.392,41.761,18.406,44,23.944,44C35.021,44,44,35.046,44,24	C44,18.477,41.755,13.477,38.126,9.858z"
    />
  </SvgIcon>
);

export default Webex;
