export const PALETTE = {
  error: {
    color: '#FF9500',
    backgroudColor: {
      main: 'rgba(255, 191, 0, 0.2)',
      light: '#fff9e6',
    },
  },
  live: {
    color: '#F04331',
    backgroudColor: {
      main: 'rgba(240, 67, 49, 0.15)',
      light: '#fff',
    },
  },
  nonscheduled: {
    color: '#808080',
    backgroudColor: {
      main: 'rgba(128, 128, 128, 0.15)',
      light: '#F5F5F5',
    },
  },
  scheduled: {
    color: '#4DA6FF',
    backgroudColor: {
      main: 'rgba(77, 166, 255, 0.2)',
      light: '#EDF6FF',
    },
  },
  success: {
    color: '#29A667',
    backgroudColor: {
      main: 'rgba(41, 166, 103, 0.2)',
      light: '#fff',
    },
  },
  limitexceeded: {
    color: '#808080',
    backgroudColor: {
      main: 'rgba(128, 128, 128, 0.15)',
      light: '#f0f0f0',
    },
  },
} as const;

export default PALETTE;
