import { useEffect, useRef } from 'react';
// Lib Shared
import { useVoiceDetection } from '../hooks/UseVoiceDetection';
import { RecordingControl, RecordingControlProps } from './RecordingControl';

export interface SpeakIndicatiedRecordingControlProps extends RecordingControlProps {
  mediaStream: MediaStream | null;
  silenceDetectionTimeoutSeconds?: number;
  onChangeState?: (speaking: boolean, isUnsupportedDetection: boolean) => void;
  onSilenceDetected?: () => void;
}

export const SpeakIndicatiedRecordingControl: React.VFC<SpeakIndicatiedRecordingControlProps> = ({
  mediaStream,
  silenceDetectionTimeoutSeconds = 0,
  onChangeState = () => null,
  onSilenceDetected = () => null,
  ...recordingControlProps
}) => {
  const isMountedRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [signalDetected, { isUnsupported }] = useVoiceDetection(mediaStream);

  /* #region  Effects */
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isMountedRef.current) return;
    onChangeState(signalDetected, isUnsupported);
  }, [signalDetected, isUnsupported, onChangeState]);

  useEffect(() => {
    if (!silenceDetectionTimeoutSeconds || isUnsupported) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      return;
    }

    function notify() {
      onSilenceDetected();
      timeoutRef.current = setTimeout(notify, silenceDetectionTimeoutSeconds * 1000);
    }

    if ((signalDetected || recordingControlProps.paused) && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      return;
    }

    timeoutRef.current = setTimeout(notify, silenceDetectionTimeoutSeconds * 1000);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [
    isUnsupported,
    onSilenceDetected,
    recordingControlProps.paused,
    signalDetected,
    silenceDetectionTimeoutSeconds,
  ]);
  /* #endregion */

  return (
    <RecordingControl
      {...recordingControlProps}
      displaySpeakingIndicator={!isUnsupported}
      speaking={signalDetected}
    />
  );
};

export default SpeakIndicatiedRecordingControl;
