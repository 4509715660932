import { GenericMeetingInsight } from '../../types';

export const getMeetingInsightContent = (item: GenericMeetingInsight): string => {
  let content = '';

  if (item.name) {
    content = `${item.name}\n${content}`;
  }

  if (item.topic) {
    content = `${content}\nTopic: ${item.topic}`;
  }

  if (item.nextSteps) {
    content = `${content}\nNext steps: ${item.nextSteps}`;
  }

  if (item.deliverable) {
    content = `${content}\nDeliverable: ${item.deliverable}`;
  }

  return content;
};

export default getMeetingInsightContent;
