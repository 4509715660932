import { UserProfessions as Role } from '@gql-types/globalTypes';

/**
 * Professional roles for the user.
 */
export const PROFESSIONAL_ROLES = [
  {
    category: 'Leadership',
    elements: [{ label: 'CxO/President/VP', value: Role.CXO_OR_PRESIDENT_OR_VP }],
  },
  {
    category: 'Development & Design',
    elements: [
      { label: 'Systems Architect', value: Role.SYSTEMS_ARCHITECT },
      { label: 'Developer/Engineer', value: Role.DEVELOPER_OR_ENGINEER },
      {
        label: 'Systems Administrator/DevOps',
        value: Role.SYSTEMS_ADMINISTRATOR_OR_DEVOPS,
      },
      { label: 'UI/UX Specialist', value: Role.UI_OR_UX_SPECIALIST },
      { label: 'Designer', value: Role.DESIGNER },
      { label: 'QA/Tester', value: Role.QA_OR_TESTER },
    ],
  },
  {
    category: 'Sales and Marketing',
    elements: [
      {
        label: 'Sales Manager/Sales Representative',
        value: Role.SALES_MANAGER_OR_SALES_REPRESENTATIVE,
      },
      {
        label: 'Business Development Specialist',
        value: Role.BUSINESS_DEVELOPMENT_SPECIALIST,
      },
      { label: 'Marketing Manager', value: Role.MARKETING_MANAGER },
      {
        label: 'Digital Marketing Specialist',
        value: Role.DIGITAL_MARKETING_SPECIALIST,
      },
      { label: 'Content Creator', value: Role.CONTENT_CREATOR },
    ],
  },
  {
    category: 'Human Resources',
    elements: [
      {
        label: 'Human Resources Manager/Associate',
        value: Role.HUMAN_RESOURCES_MANAGER_OR_ASSOCIATE,
      },
      { label: 'Recruiter', value: Role.RECRUITER },
      {
        label: 'Training & Development Specialist',
        value: Role.TRAINING_AND_DEVELOPMENT_SPECIALIST,
      },
      { label: 'Teacher/Trainer/Educator', value: Role.TEACHER_OR_TRAINER_OR_EDUCATOR },
    ],
  },
  {
    category: 'Project/Product Management',
    elements: [
      { label: 'Project/Product Manager', value: Role.PROJECT_OR_PRODUCT_MANAGER },
      { label: 'Product Owner', value: Role.PRODUCT_OWNER },
      { label: 'Scrum Master', value: Role.SCRUM_MASTER },
    ],
  },
  {
    category: 'Customer Service',
    elements: [
      { label: 'Customer Success Manager', value: Role.CUSTOMER_SUCCESS_MANAGER },
      {
        label: 'Technical Support Specialist',
        value: Role.TECHNICAL_SUPPORT_SPECIALIST,
      },
    ],
  },
] as const;

export default PROFESSIONAL_ROLES;
