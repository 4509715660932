import { useMemo } from 'react';
import { useQuery, useMutation, Reference } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// Lib Shared
import { useConfirmationDialog } from '../hooks';
import { AutocompleteProvider } from '../contexts';
import { GuestAccessForm, ShareForm, ParticipantsForm, GenericConfirmation } from '../components';
// GQL Queries and Types
import createLinkMutation from '../graphql/mutations/CreateMeetingGuestAccess.graphql';
import deleteLinkMutation from '../graphql/mutations/DeleteMeetingGuestAccess.graphql';
import deleteParticipantMutation from '../graphql/mutations/DeleteMeetingAccessItem.graphql';
import inviteParticipantMutation from '../graphql/mutations/CreateMeetingAccessItems.graphql';
import query from '../graphql/queries/MeetingShareContainerQuery.graphql';
import updateLinkMutation from '../graphql/mutations/EditMeetingGuestAccess.graphql';
import updateParticipantMutation from '../graphql/mutations/EditMeetingAccessItem.graphql';
import {
  CreateMeetingAccessItems,
  CreateMeetingAccessItemsVariables,
  CreateMeetingGuestAccess,
  CreateMeetingGuestAccessVariables,
  DeleteMeetingAccessItem,
  DeleteMeetingAccessItemVariables,
  DeleteMeetingGuestAccess,
  DeleteMeetingGuestAccessVariables,
  EditMeetingAccessItem,
  EditMeetingAccessItemVariables,
  EditMeetingGuestAccess,
  EditMeetingGuestAccessVariables,
  GraphError,
  MeetingShareContainerQuery,
  MeetingShareContainerQueryVariables,
  MeetingStatuses,
} from '../types';

export interface MeetingShareContainerProps {
  meetingId: string;
  onClose?: () => void;
  onInviteParticipant?: () => void;
  onMeetingGuestAccessLinkCreated?: () => void;
  onMeetingGuestAccessLinkRemoved?: () => void;
  onMeetingGuestAccessLinkUpdated?: () => void;
  onResponseError?: (errorMessage: GraphError) => void;
}

export const MeetingShareContainer: React.VFC<MeetingShareContainerProps> = ({
  meetingId,
  onClose = () => null,
  onInviteParticipant = () => null,
  onMeetingGuestAccessLinkCreated = () => null,
  onMeetingGuestAccessLinkRemoved = () => null,
  onMeetingGuestAccessLinkUpdated = () => null,
  onResponseError = () => null,
}) => {
  /* #region  Hooks */
  const [confirmDeleteParticipant, deleteConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      title="Are you sure you want to delete this participant?"
      text="This participant will be deleted immediately. You can't undo this action."
      confirmButtonLabel="Delete"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));

  const {
    data,
    refetch,
    loading: isLoading,
  } = useQuery<MeetingShareContainerQuery, MeetingShareContainerQueryVariables>(query, {
    variables: { meetingId },
  });

  const [inviteParticipant] = useMutation<
    CreateMeetingAccessItems,
    CreateMeetingAccessItemsVariables
  >(inviteParticipantMutation);

  const [updateParticipant] = useMutation<EditMeetingAccessItem, EditMeetingAccessItemVariables>(
    updateParticipantMutation,
  );

  const [deleteParticipant] = useMutation<
    DeleteMeetingAccessItem,
    DeleteMeetingAccessItemVariables
  >(deleteParticipantMutation);

  const [createLink, { loading: creatingLink }] = useMutation<
    CreateMeetingGuestAccess,
    CreateMeetingGuestAccessVariables
  >(createLinkMutation);

  const [updateLink, { loading: updatingLink }] = useMutation<
    EditMeetingGuestAccess,
    EditMeetingGuestAccessVariables
  >(updateLinkMutation);

  const [deleteLink, { loading: deletingLink }] = useMutation<
    DeleteMeetingGuestAccess,
    DeleteMeetingGuestAccessVariables
  >(deleteLinkMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleUpdateParticipant = async (arg: { id: string; canManage: boolean }) => {
    const { id, canManage } = arg;
    const { data } = await updateParticipant({ variables: { id: parseInt(id, 10), canManage } });
    if (data?.editMeetingAccessItem?.success) {
      refetch();
    } else {
      onResponseError(data?.editMeetingAccessItem?.errors);
    }
  };

  const handleUpdateGuestAccess = async (enabled: boolean, password: string | null) => {
    if (!data?.meeting) throw new Error('The meeting data must be defined.');

    let success = false;
    const id = data.meeting.guestAccess?.id ? parseInt(data.meeting.guestAccess.id) : null;

    if (enabled) {
      if (!id) {
        const result = await createLink({ variables: { meeting: meetingId, password } });
        success = result.data?.createMeetingGuestAccess?.success || false;
        if (success) onMeetingGuestAccessLinkCreated();
      } else {
        const result = await updateLink({ variables: { id, password } });
        success = result.data?.editMeetingGuestAccess?.success || false;
        if (success) onMeetingGuestAccessLinkUpdated();
      }
    } else if (!!id) {
      const result = await deleteLink({ variables: { id } });
      success = result.data?.deleteMeetingGuestAccess?.success || false;
      if (success) onMeetingGuestAccessLinkRemoved();
    }

    if (success) refetch();
  };

  const handleInviteParticipant = async (
    users: { email: string; canManage: boolean }[],
    message?: string,
  ) => {
    if (!data?.meeting) throw new Error('The meeting data must be defined.');

    const meetingId = data.meeting.id;
    const note = message || null;
    const result = await inviteParticipant({
      variables: { meetingId, users, note },
    });

    if (result.data?.createMeetingAccessItems?.success) {
      onInviteParticipant();
      refetch();
    } else {
      onResponseError(result.data?.createMeetingAccessItems?.errors);
    }
  };

  const handleDeleteParticipant = async (id: string) => {
    const isConfirmed = await confirmDeleteParticipant();

    if (!isConfirmed) return;

    await deleteParticipant({
      variables: { id: parseInt(id, 10) },
      optimisticResponse: {
        deleteMeetingAccessItem: {
          __typename: 'DeleteMeetingAccessItemMutationPayload',
          success: true,
          errors: [],
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteMeetingAccessItem?.success) {
          onResponseError(data?.deleteMeetingAccessItem?.errors);
          return;
        }

        cache.modify({
          id: cache.identify({ __typename: 'DetailedMeetingType', id: meetingId }),
          fields: {
            accessItems(data, { readField }) {
              return data.filter((item: Reference) => readField('id', item) !== id);
            },
          },
        });
      },
    });
  };
  /* #endregion */

  const accessItems = useMemo(
    () => ({
      participants: data?.meeting?.accessItems.filter((item) => !item.isInvited),
      invitedMembers: data?.meeting?.accessItems.filter((item) => item.isInvited && !item.isManager),
      managers: data?.meeting?.accessItems.filter((item) => item.isInvited && item.isManager),
    }),
    [data?.meeting?.accessItems],
  );

  /* #region  Render Helpers */
  const isProcessing = creatingLink || updatingLink || deletingLink;
  const hasNotScheduledStatus = data?.meeting?.status === MeetingStatuses.not_scheduled;
  /* #endregion */

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="50vh" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.me || !data.meeting) return null;

  return (
    <>
      {data.meeting.permissions.canManageAccess && (
        <Box mt={2} mb={4}>
          <AutocompleteProvider>
            <ShareForm onCloseShareForm={onClose} onSubmit={handleInviteParticipant} />
          </AutocompleteProvider>
        </Box>
      )}
      {!!accessItems.participants?.length && (
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            {`Participants (${accessItems.participants.length})`}
          </Typography>
          <ParticipantsForm
            editable={data.meeting.permissions.canManage}
            currentUserId={data.me.id}
            data={accessItems.participants}
            onUpdate={handleUpdateParticipant}
            onRemove={handleDeleteParticipant}
            onClickOnInvite={handleInviteParticipant}
          />
        </Box>
      )}
      {!!accessItems.invitedMembers?.length && (
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            {`Invited Members (${accessItems.invitedMembers.length})`}
          </Typography>
          <ParticipantsForm
            editable={data.meeting.permissions.canManage}
            currentUserId={data.me.id}
            data={accessItems.invitedMembers}
            onUpdate={handleUpdateParticipant}
            onRemove={handleDeleteParticipant}
            onClickOnInvite={handleInviteParticipant}
          />
        </Box>
      )}
      {!!accessItems.managers?.length && (
        <Box my={2}>
          <Typography variant="h6" gutterBottom>
            {`Auto-shared (${accessItems.managers.length})`}
          </Typography>
          <ParticipantsForm
            editable={data.meeting.permissions.canManage}
            currentUserId={data.me.id}
            data={accessItems.managers}
            onUpdate={handleUpdateParticipant}
            onRemove={handleDeleteParticipant}
            onClickOnInvite={handleInviteParticipant}
          />
        </Box>
      )}
      <Box my={2}>
        <Box mt={2} mb={2}>
          <Typography variant="h6">Share link</Typography>
        </Box>
        <GuestAccessForm
          loading={isProcessing}
          editable={data.meeting.permissions.canManageAccess && !hasNotScheduledStatus}
          accessLink={data.meeting.guestAccess?.link || null}
          hasAccessPassword={data.meeting.guestAccess?.hasPassword || false}
          onUpdate={handleUpdateGuestAccess}
        />
      </Box>
      {/* Begin: Dialogs */}
      {deleteConfirmationDialog}
      {/* End: Dialogs */}
    </>
  );
};

export default MeetingShareContainer;
