import { Languages } from '../types';

export const SUPPORTED_LANGUAGES: Partial<Record<Languages, string>> = {
  [Languages.ENGLISH]: 'English (United States)',
  [Languages.ENGLISH_AUSTRALIAN]: 'English (Australia)',
  [Languages.ENGLISH_BRITISH]: 'English (United Kingdom)',
  [Languages.DUTCH]: 'Dutch',
  [Languages.FRENCH]: 'French',
  [Languages.GERMAN]: 'German',
  [Languages.ITALIAN]: 'Italian',
  [Languages.JAPANESE]: 'Japanese',
  [Languages.PORTUGUESE_PORTUGAL]: 'Portuguese (Portugal)',
  [Languages.PORTUGUESE_BRAZIL]: 'Portuguese (Brazil)',
  [Languages.SPANISH_SPAIN]: 'Spanish (Spain)',
  [Languages.SPANISH_AMERICAN]: 'Spanish (Latin America)',
  // Beta languages enabled for testing period
  [Languages.AFRIKAANS]: 'Afrikaans (beta)',
  [Languages.ARABIC]: 'Arabic (beta)',
  [Languages.BOSNIAN]: 'Bosnian (beta)',
  [Languages.BULGARIAN]: 'Bulgarian (beta)',
  [Languages.CANTONESE]: 'Cantonese (beta)',
  [Languages.CATALAN]: 'Catalan (beta)',
  [Languages.CHINESE]: 'Chinese (beta)',
  [Languages.CROATIAN]: 'Croatian (beta)',
  [Languages.CZECH]: 'Czech (beta)',
  [Languages.DANISH]: 'Danish (beta)',
  [Languages.ENGLISH_FINANCE]: 'English Finance (beta)',
  [Languages.ENGLISH_INDIAN]: 'English India (beta)',
  [Languages.FINNISH]: 'Finnish (beta)',
  [Languages.GALICIAN]: 'Galician (beta)',
  [Languages.GREEK]: 'Greek (beta)',
  [Languages.HEBREW]: 'Hebrew (beta)',
  [Languages.HINDI]: 'Hindi (beta)',
  [Languages.HUNGARIAN]: 'Hungarian (beta)',
  [Languages.INDONESIAN]: 'Indonesian (beta)',
  [Languages.KOREAN]: 'Korean (beta)',
  [Languages.MACEDONIAN]: 'Macedonian (beta)',
  [Languages.MALAY]: 'Malay (beta)',
  [Languages.NORWEGIAN]: 'Norwegian (beta)',
  [Languages.PERSIAN]: 'Persian (beta)',
  [Languages.POLISH]: 'Polish (beta)',
  [Languages.ROMANIAN]: 'Romanian (beta)',
  [Languages.RUSSIAN]: 'Russian (beta)',
  [Languages.SLOVAK]: 'Slovak (beta)',
  [Languages.SWEDISH]: 'Swedish (beta)',
  [Languages.TAGALOG]: 'Tagalog (beta)',
  [Languages.TAMIL]: 'Tamil (beta)',
  [Languages.THAI]: 'Thai (beta)',
  [Languages.TURKISH]: 'Turkish (beta)',
  [Languages.UKRAINIAN]: 'Ukrainian (beta)',
  [Languages.URDU]: 'Urdu (beta)',
  [Languages.VIETNAMESE]: 'Vietnamese (beta)',
} as const;

export default SUPPORTED_LANGUAGES;
