/**
 * An utility to extract initials from names.
 */
export const getNameInitials = (value: string): string => {
  const str = value.replace(/  +/g, ' '); // replace multiple spaces with a single one
  return str
    .split(' ')
    .slice(0, 2)
    .map((n) => (n[0] ? n[0].toUpperCase() : null))
    .join('');
};
