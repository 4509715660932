import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const MyAssignmentsModuleContainer = lazyWithPreload(
  () => import('./containers/MyAssignmentsContainer'),
);

export const MyAssignmentsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.MyAssignments,
    render: () => <MyAssignmentsModuleContainer />,
  },
};

export default MyAssignmentsModule;
