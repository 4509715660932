import { ReactNode, forwardRef, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';

export type ListItemLinkProps = {
  activeClassName?: string;
  contentClassName?: string;
  countBadge?: number;
  divider?: boolean;
  exact?: boolean;
  icon?: ReactNode;
  iconCustomColor?: string;
  listItemProps?: ListItemProps;
  primary: ReactNode;
  title?: string;
  secondary?: ReactNode;
  secondaryAction?: ReactNode;
  selected?: boolean;
  to: string;
  onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const ListItemNavLink = ({
  activeClassName = '',
  contentClassName = '',
  countBadge = 0,
  divider = false,
  exact = false,
  icon,
  title,
  iconCustomColor,
  listItemProps,
  primary,
  secondary,
  secondaryAction,
  selected,
  to,
  onMouseOver,
  onClick,
}: ListItemLinkProps) => {
  // theme
  const styles = useStyles();

  const renderNavLink = useMemo(
    () =>
      forwardRef((linkProps, _) => (
        <NavLink
          exact={exact}
          activeClassName={activeClassName || styles.activeNavLink}
          to={to}
          onMouseOver={onMouseOver}
          {...linkProps}
        />
      )),
    [to, exact, activeClassName, styles, onMouseOver],
  );

  return (
    <ListItem
      selected={selected}
      component={renderNavLink}
      divider={divider}
      className={styles.regularNavLink}
      onClick={onClick}
      {...listItemProps}
      button
    >
      {icon ? (
        <ListItemIcon style={{ color: iconCustomColor || 'inherit', minWidth: 32 }}>
          {icon}
        </ListItemIcon>
      ) : null}
      <ListItemText
        title={title}
        primary={primary}
        secondary={secondary}
        className={`${styles.itemText} ${contentClassName}`}
      />
      {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
      {countBadge > 0 && (
        <div className={styles.unread}>{countBadge > 99 ? '99+' : countBadge}</div>
      )}
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  regularNavLink: {
    color: theme.palette.grey['A200'],
    borderRadius: '2em',
    '&:hover': {
      color: theme.palette.grey['A100'],
    },
    padding: theme.spacing(0.75, 1.5),
    marginLeft: '3px',
  },
  activeNavLink: {
    cursor: 'default',
    color: theme.palette.grey[50],
    background: theme.palette.surface.light,
    '&:hover': {
      color: theme.palette.grey[50],
      background: theme.palette.surface.light,
    },
  },
  unread: {
    marginLeft: 28,
    maxWidth: '27px',
    width: '100%',
    height: '20px',
    backgroundColor: '#FF2B00',
    borderRadius: '10px',
    color: '#FFFFFF',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 10px',
  },
  itemText: {
    overflow: 'hidden',
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
}));

export default ListItemNavLink;
