import { SvgIcon } from '@material-ui/core';

export const MessageSupport = () => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25">
    <path
      d="M21 12.72C21 7.23 16.74 3.5 12 3.5C7.31 3.5 3 7.15 3 12.78C2.4 13.12 2 13.76 2 14.5V16.5C2 17.6 2.9 18.5 4 18.5C4.55 18.5 5 18.05 5 17.5V12.69C5 8.86 7.95 5.51 11.78 5.4C15.74 5.28 19 8.46 19 12.4V19.5H12C11.45 19.5 11 19.95 11 20.5C11 21.05 11.45 21.5 12 21.5H19C20.1 21.5 21 20.6 21 19.5V18.28C21.59 17.97 22 17.36 22 16.64V14.34C22 13.64 21.59 13.03 21 12.72Z"
      fill="black"
    />
    <path
      d="M9 14.5C9.55228 14.5 10 14.0523 10 13.5C10 12.9477 9.55228 12.5 9 12.5C8.44772 12.5 8 12.9477 8 13.5C8 14.0523 8.44772 14.5 9 14.5Z"
      fill="black"
    />
    <path
      d="M15 14.5C15.5523 14.5 16 14.0523 16 13.5C16 12.9477 15.5523 12.5 15 12.5C14.4477 12.5 14 12.9477 14 13.5C14 14.0523 14.4477 14.5 15 14.5Z"
      fill="black"
    />
    <path
      d="M18 11.53C17.52 8.68 15.04 6.5 12.05 6.5C9.01997 6.5 5.75997 9.01 6.01997 12.95C8.48997 11.94 10.35 9.74 10.88 7.06C12.19 9.69 14.88 11.5 18 11.53Z"
      fill="black"
    />
  </SvgIcon>
);

export default MessageSupport;
