// Material UI
import { AppBar, Box, Grid, Toolbar, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
// App Configs
import { APP_NAME } from '@shared/configuration';
// App Shared
import logoSembly from '@shared/assets/logo.svg';
import logoPhilips from '@shared/assets/logo-philips.svg';

export interface OnboardingPageLayoutProps {
  background?: string;
  footer?: React.ReactElement;
  content?: React.ReactElement;
  isSmartMikeUser?: boolean;
}

export const OnboardingPageLayout: React.FC<OnboardingPageLayoutProps> = ({
  children,
  isSmartMikeUser = false,
  background = 'none',
  content = null,
  footer = '',
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction={isSmallScreen ? 'column-reverse' : 'row'} className={styles.root}>
      <Grid item md={5} lg={5} className={styles.primary}>
        <AppBar className={styles.appBar} position="static" color="transparent">
          <Toolbar>
            <img src={logoSembly} alt={APP_NAME} className={styles.logo} />
            <Box flex={1} ml={6} textAlign="right">
              {isSmartMikeUser && (
                <img src={logoPhilips} alt="Philips" style={{ marginTop: 5, height: 16 }} />
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <div className={styles.content}>{children}</div>
        {!!footer && <div className={styles.footer}>{footer}</div>}
      </Grid>
      <Grid item md={7} lg={7} className={styles.secondary} style={{ background }}>
        {content}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    boxShadow: 'none',
    flex: 0,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      paddingTop: theme.spacing(3),
    },
  },
  logo: {
    height: 24,
  },
  primary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    maxHeight: '100%',
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2),
  },
  secondary: {
    flex: 1,
    maxHeight: '100%',
    backgroundSize: 'cover !important',
    backgroundPosition: 'bottom !important',
    overflow: 'auto',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  footer: {
    flex: 0,
    textAlign: 'center',
  },
}));

export default OnboardingPageLayout;
