import {
  useIntegrations as originalUseIntegrations,
  useFlowInstances as originalUseFlowInstances,
  useIntegrationApp as originalUseIntegrationApp,
  IntegrationAppProvider as OriginalIntegrationAppProvider,
} from '@integration-app/react';

/**
 * Extended argument type for `useIntegrations`, adding an optional `skip` parameter.
 * If `skip` is true, the function returns a mock result instead of initializing integrations.
 */
type ExtendedUseIntegrationsArgs = Parameters<typeof originalUseIntegrations>[0] & {
  skip?: boolean;
};

/**
 * Extended argument type for `useIntegrationApp`, adding an optional `skip` parameter.
 * If `skip` is true, the function returns an empty mock result.
 */
type ExtendedUseIntegrationAppArgs = {
  skip?: boolean;
};

/**
 * Extended argument type for `useFlowInstances`, adding an optional `skip` parameter.
 * If `skip` is true, the function returns a mock result without initializing flow instances.
 */
type ExtendedUseFlowInstancesArgs = Parameters<typeof originalUseFlowInstances>[0] & {
  skip?: boolean;
};

/**
 * Extended props type for `IntegrationAppProvider`, adding an optional `skip` parameter.
 * If `skip` is true, it renders only the children without initializing the provider.
 */
type ExtendedIntegrationAppProviderProps = Parameters<typeof OriginalIntegrationAppProvider>[0] & {
  skip?: boolean;
};

/**
 * Wrapped `useIntegrations` hook.
 * Allows skipping initialization with `skip`. If `skip` is true, it returns a mock response,
 * preventing the need to load actual integrations, useful for app stability when data isn't required.
 */
export const useIntegrations = (
  args: ExtendedUseIntegrationsArgs,
): ReturnType<typeof originalUseIntegrations> => {
  const { skip = false, ...restArgs } = args;

  if (skip) {
    return {
      items: [],
      loading: false,
      error: null,
      integrations: [],
      loadingMore: false,
      refreshing: false,
      refresh: async () => Promise.resolve(),
      loadMore: async () => Promise.resolve(),
    };
  }

  return originalUseIntegrations(restArgs);
};

/**
 * Wrapped `useFlowInstances` hook.
 * Allows skipping initialization with `skip`. If `skip` is true, it returns a mock response,
 * useful for situations where flow instances aren't needed, maintaining app stability.
 */
export const useFlowInstances = (
  args: ExtendedUseFlowInstancesArgs,
): ReturnType<typeof originalUseFlowInstances> => {
  const { skip = false, ...restArgs } = args;

  if (skip) {
    return {
      items: [],
      loading: false,
      error: null,
      flowInstances: [],
      loadingMore: false,
      refreshing: false,
      refresh: async () => Promise.resolve(),
      loadMore: async () => Promise.resolve(),
    };
  }

  return originalUseFlowInstances(restArgs);
};

/**
 * Wrapped `useIntegrationApp` hook.
 * Allows skipping initialization with `skip`. If `skip` is true, it returns an empty mock,
 * bypassing the app setup and maintaining stability when integration app data isn't needed.
 */
export const useIntegrationApp = (
  args: ExtendedUseIntegrationAppArgs,
): ReturnType<typeof originalUseIntegrationApp> => {
  const { skip = false } = args;

  if (skip) return {} as any;

  return originalUseIntegrationApp();
};

/**
 * Wrapped `IntegrationAppProvider` component.
 * Allows skipping initialization with `skip`. If `skip` is true, only renders children without initializing the provider.
 * Useful for cases where the provider setup can be skipped to improve app stability.
 */
export const IntegrationAppProvider = (props: ExtendedIntegrationAppProviderProps) => {
  const { skip = false, ...restProps } = props;

  if (skip) return <>{props.children}</>;

  return <OriginalIntegrationAppProvider {...restProps} />;
};
