import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export interface AutocompleteInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Full suggestion to display if it starts with the current input value */
  suggestion?: string;
  inputRef?: React.Ref<HTMLInputElement>;
}

/**
 * Custom input component for autocomplete:
 * - Renders the typed text normally (visible).
 * - Shows the remaining suggestion behind it in a lighter color.
 */
const AutocompleteInput = forwardRef<HTMLInputElement, AutocompleteInputProps>((props, ref) => {
  const styles = useStyles();

  const { suggestion, value, inputRef, style, ...other } = props;
  const inputValue = value ? String(value) : '';

  // If `suggestion` starts with the current `inputValue`, show only the leftover part.
  let remainder = '';
  if (
    suggestion &&
    suggestion.toLowerCase().startsWith(inputValue.toLowerCase()) &&
    suggestion.length > inputValue.length
  ) {
    remainder = suggestion.substring(inputValue.length);
  }

  return (
    <div style={{ position: 'relative', flex: 1, overflow: 'hidden' }}>
      {/* Overlay that shows the leftover suggestion */}
      <div
        className={other.className}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
          opacity: 0.5,
          ...style,
        }}
      >
        {/* Hidden span to "reserve" space for the typed text, so the remainder lines up exactly */}
        <span style={{ visibility: 'hidden', whiteSpace: 'pre' }}>{inputValue}</span>
        {/* The actual leftover suggestion (if any) */}
        <span>{remainder}</span>
        {!!remainder && <span className={styles.tabKey}>Tab to auto-complete</span>}
      </div>

      {/* Native <input> that displays the typed text in normal color */}
      <input ref={ref} {...other} value={inputValue} autoComplete="off" data-lpignore={true} />
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  tabKey: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(0.5, 1),
    marginLeft: theme.spacing(1),
    borderRadius: '2em',
    ...theme.typography.body2,
  },
}));

AutocompleteInput.displayName = 'AutocompleteInput';

export default AutocompleteInput;
