import { VFC, ChangeEvent, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: `1px solid #00000019`,
    borderRadius: '2em',
    color: theme.palette.text.primary,
    display: 'flex',
    position: 'relative',
    height: 36,
    width: '100%',
    padding: '0 16px',
  },
  focused: {
    backgroundColor: theme.palette.grey[200],
  },
  base: {
    color: 'inherit',
    width: '100%',
  },
  input: {
    width: '100%',
    height: 'auto',
  },
}));

export interface InputFieldProps {
  className?: string;
  initialValue?: string;
  debounceInterval?: number;
  placeholder?: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  debouncedOnChange?: boolean;
}

export const InputField: VFC<InputFieldProps> = ({
  className = '',
  initialValue,
  debounceInterval = 1000,
  onChange,
  placeholder = 'Placeholder',
  errorMessage = 'Error',
  debouncedOnChange = false,
}) => {
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState(initialValue || '');
  const [isFocused, setIsFocused] = useState(false);

  const handleChangeDebounced = useDebouncedCallback((value) => {
    const term = value || '';
    onChange(term);
  }, debounceInterval);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (!value.trim().length) value = '';
    setCurrentValue(value);

    const handler = debouncedOnChange ? handleChangeDebounced : onChange;

    handler(value);
  };

  return (
    <div className={clsx(styles.root, className, { [styles.focused]: isFocused })}>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: styles.base,
          input: styles.input,
        }}
        inputProps={{
          'aria-label': placeholder,
          onFocus: () => setIsFocused(true),
          onBlur: () => setIsFocused(false),
        }}
        value={currentValue}
        onChange={handleChange}
      />
      {errorMessage && (
        <Tooltip title={errorMessage} placement="top">
          <ErrorIcon style={{ color: '#FF6A00' }} />
        </Tooltip>
      )}
    </div>
  );
};

export default InputField;
