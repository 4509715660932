import { Fragment, useState, useEffect } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { Toggle } from '@sembly-ui';
// Material Icons
import CheckIcon from '@material-ui/icons/Check';
import UncheckIcon from '@material-ui/icons/CloseSharp';
// App Shared
import { useUserContext } from '@shared/hooks';
import { BillingCycle } from '@shared/types';
import { PRICE_LINES } from '@shared/constants';
import { PaymentPlanHeader } from '@shared/components';
import {
  GenericPaymentPlan,
  GenericStripePromotionCode,
  PlanIDEnum,
  StripePricePaymentIntervalEnum,
} from '@gql-types';

export interface WorkspacePlansTableProps {
  billingAccessId: string | null;
  billingCycleSwitchClassname?: string;
  ctaActiveButtonLabel?: string;
  enableActiveCtaButton?: boolean;
  paymentCustomerPriceType: StripePricePaymentIntervalEnum | null;
  paymentPlans: GenericPaymentPlan[];
  promotionCode: GenericStripePromotionCode | null;
  trialExpirationDate: string | null;
  onClickOnCtaButton: (selectedPlan: GenericPaymentPlan, billingCycle: BillingCycle) => void;
}

export const WorkspacePlansTable: React.FC<WorkspacePlansTableProps> = ({
  billingAccessId,
  billingCycleSwitchClassname = '',
  children,
  ctaActiveButtonLabel,
  enableActiveCtaButton = false,
  paymentCustomerPriceType,
  paymentPlans,
  promotionCode,
  trialExpirationDate,
  onClickOnCtaButton,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();

  const [billingCycle, setBillingCycle] = useState<BillingCycle>('monthly');
  /* #endregion */

  /* #region  Handlers */
  const handleChangeBillingCycle = (value: string | null) => {
    if (!value) return;
    const cycle: BillingCycle = value === 'monthly' ? 'monthly' : 'annual';
    setBillingCycle(cycle);
  };

  const handleClickOnPlan = (selectedPlan: GenericPaymentPlan) => {
    onClickOnCtaButton(selectedPlan, billingCycle);
  };
  /* #endregion */

  /* #region  Render Assets */
  const renderPlanHeader = (planId: PlanIDEnum) => {
    if (!user || !paymentPlans) return null;

    const currencyCode = user.data?.me?.paymentCustomer?.subscription?.currency || 'USD';

    return (
      <PaymentPlanHeader
        currencyCode={currencyCode}
        billingCycle={billingCycle}
        ctaActiveButtonLabel={ctaActiveButtonLabel}
        enableActiveCtaButton={enableActiveCtaButton}
        key={planId}
        paymentPlans={paymentPlans}
        planId={planId}
        promotion={promotionCode}
        trialExpirationDate={trialExpirationDate}
        paymentCustomerPriceType={paymentCustomerPriceType}
        userBillingAccessId={billingAccessId}
        onClickOnCtaButton={handleClickOnPlan}
      />
    );
  };

  const renderFeature = (val: boolean | string) => {
    if (typeof val === 'string') return val;
    return val ? (
      <CheckIcon fontSize="small" color="primary" />
    ) : (
      <UncheckIcon fontSize="small" color="disabled" />
    );
  };
  /* #endregion */

  useEffect(() => {
    if (!!paymentCustomerPriceType) {
      const isAnnual = paymentCustomerPriceType === StripePricePaymentIntervalEnum.ANNUAL;
      setBillingCycle(isAnnual ? 'annual' : 'monthly');
    }
  }, [paymentCustomerPriceType]);

  const billingPlansList = [
    PlanIDEnum.PERSONAL,
    PlanIDEnum.PROFESSIONAL,
    PlanIDEnum.TEAM,
    PlanIDEnum.TEAM_PLUS,
    PlanIDEnum.ENTERPRISE,
  ];

  return (
    <>
      {children}

      <Toggle
        value={billingCycle}
        options={[
          {
            key: 'monthly',
            value: 'monthly',
            component: <span>Monthly</span>,
          },
          {
            key: 'annual',
            value: 'annual',
            component: (
              <>
                <span>Annual</span>
                <Chip
                  className={styles.billingSwitchChip}
                  size="small"
                  variant="default"
                  label="−30%"
                />
              </>
            ),
          },
        ]}
        className={`${styles.billingSwitch} ${billingCycleSwitchClassname}`}
        onChange={handleChangeBillingCycle}
      />

      {isSmallScreen ? (
        <Box>
          {billingPlansList.map((plan, index) => (
            <Box pb={2} key={plan}>
              {renderPlanHeader(plan)}
              {index + 1 < billingPlansList.length && <Divider light />}
            </Box>
          ))}
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          square
          variant="elevation"
          elevation={2}
          style={{ overflow: 'inherit', width: '100%' }}
        >
          <Table stickyHeader size="small" aria-label="Workspace Plans">
            <TableHead>
              <TableRow>
                <TableCell className={styles.divider} />
                <TableCell colSpan={2} align="center" className={styles.divider}>
                  <Box my={1}>
                    <Typography variant="body1">For individuals</Typography>
                  </Box>
                </TableCell>
                <TableCell colSpan={3} align="center">
                  <Box my={1}>
                    <Typography variant="body1">For teams & organizations</Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={styles.divider} />
                <TableCell>{renderPlanHeader(PlanIDEnum.PERSONAL)}</TableCell>
                <TableCell className={styles.divider}>
                  {renderPlanHeader(PlanIDEnum.PROFESSIONAL)}
                </TableCell>
                <TableCell>{renderPlanHeader(PlanIDEnum.TEAM)}</TableCell>
                <TableCell>{renderPlanHeader(PlanIDEnum.TEAM_PLUS)}</TableCell>
                <TableCell>{renderPlanHeader(PlanIDEnum.ENTERPRISE)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(PRICE_LINES).map((groupName) => {
                const rows = PRICE_LINES[groupName];
                return (
                  <Fragment key={`feature-group-${groupName}`}>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box my={1 / 2}>
                          <Typography variant="h6">{groupName}</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {rows.map((row, index) => (
                      <TableRow key={`feature-${groupName}-${index}`}>
                        <TableCell className={styles.divider}>
                          <Box my={1 / 2}>{row.feature}</Box>
                        </TableCell>
                        <TableCell align="center">{renderFeature(row.PERSONAL)}</TableCell>
                        <TableCell align="center" className={styles.divider}>
                          {renderFeature(row.PROFESSIONAL)}
                        </TableCell>
                        <TableCell align="center">{renderFeature(row.TEAM)}</TableCell>
                        <TableCell align="center">{renderFeature(row.TEAM_PLUS)}</TableCell>
                        <TableCell align="center">{renderFeature(row.ENTERPRISE)}</TableCell>
                      </TableRow>
                    ))}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  divider: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
  },
  billingSwitch: {
    marginBottom: theme.spacing(4),
  },
  billingSwitchChip: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.highlight.main,
    color: theme.palette.common.black,
  },
}));

export default WorkspacePlansTable;
