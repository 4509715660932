import pluralize from 'pluralize';
import { formatDistanceToNow } from 'date-fns';
// Material UI
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import plusIcon from '../assets/icon-plus-20.svg';

export interface WorkstreamCardProps {
  chatsCount: number;
  lastActivity: string;
  meetingsCount: number;
  processing: boolean;
  tag: string;
  tasksCount: number;
  title: string;
  type: 'Personal' | 'Team';
  unread: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickOnCreateChat: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onToggleMenu?: (anchorEl: HTMLElement) => void;
}

/**
 * Component that represents a workstream card
 */
export const WorkstreamCard: React.VFC<WorkstreamCardProps> = ({
  chatsCount,
  lastActivity,
  meetingsCount,
  processing,
  tag,
  tasksCount,
  title,
  type,
  unread,
  onClick,
  onClickOnCreateChat,
  onToggleMenu = () => {},
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onToggleMenu(event.currentTarget);
  };

  return (
    <Paper className={`${styles.root} ${isSmallScreen ? 'mobile' : ''}`} onClick={onClick} onMouseEnter={handleToggleMenu}>
      {unread && <div className={styles.beacon} />}
      <div className={styles.content}>
        <Typography variant="h6">
          <Typography component="span" color="primary" variant="inherit">
            {`${tag} `}
          </Typography>
          {title}
        </Typography>
        <div className={`${styles.actions} ${isSmallScreen ? 'mobile' : ''}`}>
          {type === 'Personal' && (
            <>
              <Typography noWrap component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
                {pluralize('task', tasksCount, true)}
              </Typography>
              <Typography
                component="span"
                variant={isSmallScreen ? 'body2' : 'body1'}
                color="textSecondary"
              >
                •
              </Typography>
            </>
          )}
          <Typography noWrap component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
            {pluralize('meeting', meetingsCount, true)}
          </Typography>
          <Typography
            component="span"
            variant={isSmallScreen ? 'body2' : 'body1'}
            color="textSecondary"
          >
            •
          </Typography>
          <Typography noWrap component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
            {pluralize('chat', chatsCount, true)}
          </Typography>
          <Typography
            component="span"
            variant={isSmallScreen ? 'body2' : 'body1'}
            color="textSecondary"
          >
            •
          </Typography>
          <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
            {type}
          </Typography>
          <Typography
            component="span"
            variant={isSmallScreen ? 'body2' : 'body1'}
            color="textSecondary"
          >
            •
          </Typography>
          <Typography component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
            Latest activity {formatDistanceToNow(new Date(lastActivity), { addSuffix: true })}
          </Typography>
        </div>
      </div>

      <Button
        disableElevation
        color="inherit"
        variant="contained"
        className={styles.button}
        onClick={onClickOnCreateChat}
        startIcon={
          processing ? (
            <CircularProgress color="inherit" size={18} />
          ) : (
            <img src={plusIcon} alt="New chat" />
          )
        }
      >
        <Typography noWrap component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
          New chat
        </Typography>
      </Button>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.grey[50],
    },
    '&.mobile': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.75, 1),
    '&.mobile': {
      flexWrap: 'wrap',
    },
  },
  beacon: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(-1.25),
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.error.main,
  },
  chip: {
    padding: theme.spacing(0.5, 1),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    height: 16,
    width: 16,
    fontSize: 12,
    marginLeft: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

export default WorkstreamCard;
