import { useState } from 'react';
import { useMutation } from '@apollo/client';
// Material UI
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Queries & Mutations
import updateMutation from '../graphql/mutations/ChangeMeetingType.graphql';
import meetingNotesQuery from '../graphql/queries/MeetingNotesQuery.graphql';
// Lib Shared
import GenericDialog from '../components/GenericDialog';
import LanguageSelect from '../components/LanguageSelect';
import { SUPPORTED_LANGUAGES, MEETING_TYPES } from '../constants';
import {
  ApplyToMeetings,
  ChangeMeetingType,
  ChangeMeetingTypeVariables,
  GraphError,
  Languages,
  MeetingTypes,
} from '../types';

export interface MeetingTypeManageDialogProps {
  meetingId: string;
  targetMeetingType: MeetingTypes;
  targetVariant: ApplyToMeetings;
  primaryLang: Languages;
  onClose: () => void;
  onSubmit?: (data: ChangeMeetingTypeVariables) => void;
  onError?: (error: GraphError) => void;
}

export const MeetingTypeManageDialog: React.VFC<MeetingTypeManageDialogProps> = ({
  meetingId,
  primaryLang,
  targetMeetingType,
  targetVariant,
  onClose,
  onSubmit,
  onError = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [template, setTemplate] = useState<'current' | 'selected'>('selected');
  const [currentPrimaryLang, setCurrentPrimaryLang] = useState(primaryLang);

  const [updateMeetingType, { loading: isUpdating }] = useMutation<
    ChangeMeetingType,
    ChangeMeetingTypeVariables
  >(updateMutation, {
    refetchQueries: [{ query: meetingNotesQuery, variables: { meetingId } }],
  });
  /* #endregion */

  /* #region  Handlers */
  const handleChangeTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplate((event.target as HTMLInputElement).value as 'current' | 'selected');
  };

  const handleChangePrimaryLang = (value: { key: string; value: string } | null) => {
    const nextPrimaryLanguage = (value?.key || null) as Languages | Languages.ENGLISH;
    setCurrentPrimaryLang(nextPrimaryLanguage);
  };

  const handleSubmit = async () => {
    const isRegenerate = template === 'selected';
    const meeting = meetingId;
    const newMeetingType = targetMeetingType;
    const newMeetingMinutesLanguage = isRegenerate ? currentPrimaryLang : null;
    const regenerateMeetingMinutes = isRegenerate;

    if (!!onSubmit) {
      onSubmit({
        meeting,
        newMeetingType,
        newMeetingMinutesLanguage,
        regenerateMeetingMinutes,
        variant: targetVariant,
      });
      return;
    }

    const result = await updateMeetingType({
      variables: {
        meeting,
        newMeetingType,
        newMeetingMinutesLanguage,
        regenerateMeetingMinutes,
        variant: targetVariant,
      },
    });

    if (result.data?.changeMeetingType?.success) {
      onClose();
    } else {
      onError(result.data?.changeMeetingType?.errors);
    }
  };
  /* #endregion */

  /* #region  Render Helpers */
  function getLangKeyValuePair(key: Languages): {
    key: string;
    value: string;
  } {
    const value = SUPPORTED_LANGUAGES[key as keyof typeof SUPPORTED_LANGUAGES] || key;
    return { key, value };
  }

  type SupportedLangKeys = Array<keyof typeof SUPPORTED_LANGUAGES>;
  const supportedLangKeys = Object.keys(SUPPORTED_LANGUAGES) as SupportedLangKeys;
  const supportedLangs = supportedLangKeys.map(getLangKeyValuePair);
  const meetingTypeList = MEETING_TYPES.flatMap(({ elements }) => [...elements]);
  const selectedType = meetingTypeList.find((el) => el.value === targetMeetingType);
  const hasTypeLabel = !!selectedType?.label;
  /* #endregion */

  return (
    <GenericDialog
      title={
        !!hasTypeLabel ? `Change meeting type to ${selectedType.label}` : 'Change meeting type'
      }
      dialogProps={{
        open: true,
        fullScreen: isSmallScreen,
        fullWidth: true,
        scroll: 'paper',
        maxWidth: 'sm',
        classes: { paper: styles.paper },
      }}
      onClose={onClose}
    >
      <DialogContent className={styles.content}>
        <Box mb={3}>
          <Typography paragraph variant="body1">
            <b>
              Would you like to regenerate the notes in a new format according to selected meeting
              type?
            </b>
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup value={template} aria-label="template" onChange={handleChangeTemplate}>
              <FormControlLabel
                value="selected"
                control={<Radio color="primary" />}
                label="Yes, regenerate"
              />
              <FormControlLabel
                value="current"
                control={<Radio color="primary" />}
                label="No, keep current"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {template === 'selected' && (
          <Box mb={4}>
            <Box mb={3}>
              <Typography paragraph variant="body1">
                <b>Would you like the notes to be generated in a different language?</b>
              </Typography>
              <LanguageSelect
                options={supportedLangs}
                value={currentPrimaryLang}
                onChange={handleChangePrimaryLang}
              />
            </Box>
            <Alert icon={<></>} color="warning" style={{ padding: 6 }}>
              <Typography variant="body1">
                Meeting notes will be regenerated in a new format according to selected meeting
                type. Any previous changes made to the meeting notes will be lost.
              </Typography>
            </Alert>
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end" gridGap={6}>
          <Button variant="outlined" aria-label="Cancel changing" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Change meeting type"
            disabled={isUpdating}
            onClick={handleSubmit}
          >
            Apply
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  paper: {
    background: theme.palette.background.default,
  },
}));

export default MeetingTypeManageDialog;
