import { useRef, useState } from 'react';
// Material UI
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';

export interface IntegrationManageProps {
  ruleId: string;
  isDisableAddNewRule: boolean;
  onDuplicate: (ruleId: string) => void;
  onEdit: (ruleId: string) => void;
  onRemove: (ruleId: string) => void;
}

export const IntegrationManage: React.VFC<IntegrationManageProps> = ({
  ruleId,
  isDisableAddNewRule,
  onDuplicate,
  onEdit,
  onRemove,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  /* #endregion */

  /* #region Handlers */
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOnDuplicate = () => {
    onDuplicate(ruleId);
    handleClose();
  };

  const handleClickOnEdit = () => {
    onEdit(ruleId);
    handleClose();
  };

  const handleClickOnRemove = () => {
    onRemove(ruleId);
    handleClose();
  };
  /* #endregion */

  return (
    <div>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className={styles.button}
        ref={anchorRef}
        size="small"
        title="Manage"
        onClick={handleToggle}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Popper
        transition
        disablePortal
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Menu open={open} anchorEl={anchorRef.current} onClose={handleClose}>
                  <MenuItem className={styles.menuItem} onClick={handleClickOnEdit}>
                    Edit
                  </MenuItem>
                  <MenuItem
                    className={styles.menuItem}
                    disabled={isDisableAddNewRule}
                    onClick={handleClickOnDuplicate}
                  >
                    Duplicate
                  </MenuItem>
                  <MenuItem className={styles.menuItem} onClick={handleClickOnRemove}>
                    Remove
                  </MenuItem>
                </Menu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 3.5,
  },
  menuItem: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
      minHeight: 28,
    },
  },
}));

export default IntegrationManage;
