// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
// Lib Shared
import { TransitionSlide } from '../components';
import limitImg from '../assets/limit-exceeded-modal.svg';

export interface AskSemblyProhibitedDialogProps {
  isHiddenUpgradeButtons: boolean;
  onClose: () => void;
  onClickOnPromoteUpgrade: () => void;
}

export const AskSemblyProhibitedDialog: React.FC<AskSemblyProhibitedDialogProps> = ({
  children,
  isHiddenUpgradeButtons,
  onClose,
  onClickOnPromoteUpgrade = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* #endregion */

  const handlePromoteUpgrade = () => {
    onClickOnPromoteUpgrade();
    onClose();
  };

  return (
    <Dialog
      open
      keepMounted
      fullWidth
      fullScreen={isSmallScreen}
      maxWidth="sm"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={styles.title}>
        <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        px={4}
        py={4}
        pt={0}
      >
        <Box mb={3}>
          <img src={limitImg} alt="Sembly" />
        </Box>
        <Typography paragraph variant="h4">
          No more trial prompts remaining
        </Typography>
        <Typography paragraph variant="body1">
          {children}
        </Typography>
        {!isHiddenUpgradeButtons && (
          <Box mt={2}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              aria-label="Upgrade plan"
              onClick={handlePromoteUpgrade}
            >
              Upgrade now
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default AskSemblyProhibitedDialog;
