import { forwardRef } from 'react';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip, { ChipClassKey } from '@material-ui/core/Chip';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab';
import { AutocompleteInputChangeReason, AutocompleteRenderInputParams } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { makeStyles, Theme } from '@material-ui/core/styles';

/* #region  Types */
export type AutocompleteTagsChangeEvent = (
  event: React.ChangeEvent<{}>,
  value: (string | string[])[],
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<string[]> | undefined,
) => void;

export type AutocompleteTagChangeEvent = (
  event: React.ChangeEvent<{}>,
  value: string,
  reason: AutocompleteInputChangeReason,
) => void;

export interface TagsProps {
  all: string[];
  inputValue?: string;
  invalidClassName?: string;
  InputProps?: TextFieldProps;
  placeholder?: string;
  validClassName?: string;
  onBlur?: () => void;
  onChangeInputValue?: (value: string) => void;
  onChangeTag?: AutocompleteTagChangeEvent;
  onChangeTags: AutocompleteTagsChangeEvent;
  onDelete: (item: string) => void;
  onValidate?: (value: string) => boolean;
}

/* #endregion */

export const Tags: React.VFC<TagsProps> = forwardRef(
  (
    {
      all,
      inputValue,
      invalidClassName,
      InputProps,
      validClassName,
      placeholder,
      onBlur,
      onChangeInputValue,
      onChangeTag,
      onChangeTags,
      onDelete,
      onValidate,
    },
    ref,
  ) => {
    const styles = useStyles();

    /* #region  Handlers */
    const handleKeyDown =
      (params: AutocompleteRenderInputParams) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        const char = e.which || e.keyCode;

        if (!(params.inputProps as { value: string }).value && (char === 8 || char === 32)) {
          return e.preventDefault();
        }
      };

    const handleInputComponentChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      onChangeInputValue && onChangeInputValue(e.target.value);
    };

    const handleDelete = (item: string) => () => {
      onDelete(item);
    };
    /* #endregion */

    return (
      <Autocomplete
        ref={ref}
        freeSolo
        multiple
        includeInputInList
        disableClearable
        classes={{
          root: styles.autocompleteRoot,
          inputRoot: styles.autocomplete,
          tag: styles.tag,
        }}
        options={[]}
        onBlur={onBlur}
        value={all}
        onChange={onChangeTags}
        onInputChange={onChangeTag}
        renderTags={(value, getTagProps) =>
          value.map((item, index) => {
            const tag = item.toString();
            let classes: Partial<ClassNameMap<ChipClassKey>> = { label: styles.label };

            if (onValidate && validClassName && invalidClassName) {
              classes = { ...classes, root: onValidate(tag) ? validClassName : invalidClassName };
            }

            return (
              <Chip
                key={index}
                variant="outlined"
                label={tag}
                {...getTagProps({ index })}
                classes={classes}
                onDelete={handleDelete(tag)}
              />
            );
          })
        }
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              {...InputProps}
              placeholder={
                !all?.length
                  ? placeholder ||
                    'Specify which keywords Sembly should identify in the meeting title.'
                  : ''
              }
              value={inputValue}
              onChange={handleInputComponentChange}
              onKeyDown={handleKeyDown(params)}
            />
          );
        }}
      />
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  autocompleteRoot: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(2),
    },
  },
  autocomplete: {
    minHeight: theme.spacing(8 * 2),
    alignItems: 'flex-start',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey['A100']}`,
    borderRadius: theme.shape.borderRadius * 2,
  },
  tag: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    border: 'none',
    ...theme.typography.body1,
  },
  label: {
    lineHeight: 1,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxWidth: 220,
    },
  },
}));

export default Tags;
