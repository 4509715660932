// Material UI
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
// Lib Shared
import { UserProfessions as Role } from '@gql-types/globalTypes';
import { PROFESSIONAL_ROLES } from '@shared/constants';

export interface ProfessionalRoleSelectProps {
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  value: Role | null;
  onChange: (value: Role | null) => void;
}

export const ProfessionalRoleSelect: React.VFC<ProfessionalRoleSelectProps> = ({
  disabled = false,
  fullWidth = true,
  size,
  value,
  onChange,
}) => {
  const handleChangeValue = async (
    event: React.ChangeEvent<{}>,
    option: { key: string; value: string } | string | null,
  ) => {
    if (typeof option === 'string' || !option?.key) {
      onChange(null);
    } else {
      onChange(option.key as Role);
    }
  };

  /* #region  Render Helpers */
  function getLabeledValue(key: Role | null) {
    return options.find((option) => option.key === key) || null;
  }

  function groupByLabel({ key }: { key: string; value: string }) {
    const sections = PROFESSIONAL_ROLES.map((section) => ({
      key: section.category,
      value: section.elements.map((element) => element.value),
    }));

    const matchedSection = sections.find((section) => section.value.includes(key as Role));
    return matchedSection ? matchedSection.key : '';
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Select your professional role"
        InputProps={params.InputProps}
      />
    );
  }
  /* #endregion */

  const options = PROFESSIONAL_ROLES.flatMap((section) => {
    return section.elements.map((element) => ({
      key: element.value,
      value: element.label,
    }));
  });

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionLabel={(option) => option.value}
      groupBy={groupByLabel}
      options={options}
      renderInput={renderInput}
      size={size}
      value={getLabeledValue(value)}
      onChange={handleChangeValue}
    />
  );
};

export default ProfessionalRoleSelect;
