// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import keyItemsImage from '../assets/icon-key-items-64.svg';
import noContentImage from '../assets/icon-no-content-64.svg';
import { RedirectTarget, MeetingStatuses as Status } from '../types';
import { getFailureReason } from '../utils';

export interface MeetingPlaceholderKeyItemsProps {
  failureReason: string | null;
  isManualRecording: boolean;
  isProcessedAssignments: boolean;
  isProcessedNotes: boolean;
  isTranscribed: boolean;
  meetingStatus: Status;
  onRedirect: (target: RedirectTarget) => void;
}

/**
 * The component that renders a placeholder when there are no key items
 */
export const MeetingPlaceholderKeyItems: React.VFC<MeetingPlaceholderKeyItemsProps> = ({
  failureReason,
  isManualRecording,
  isProcessedAssignments,
  isProcessedNotes,
  isTranscribed,
  meetingStatus,
  onRedirect,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRequestRedirect = (target: RedirectTarget) => () => {
    onRedirect(target);
  };

  /* #region  Render Helpers */
  const waitingStatuses = [Status.scheduled, Status.on_call, Status.on_pause];
  const isSubmittedStatus = meetingStatus === Status.submitted;
  const isNotScheduledStatus = meetingStatus === Status.not_scheduled;
  const isFailedStatus = meetingStatus === Status.failed;
  const isWaiting = waitingStatuses.includes(meetingStatus);
  /* #endregion */

  return (
    <div className={styles.root}>
      <Box mt={isSmallScreen ? 3 : 5} mb={isSmallScreen ? 3 : 4}>
        <img
          alt="Key Items"
          width={isSmallScreen ? 42 : 64}
          height={isSmallScreen ? 42 : 64}
          src={isFailedStatus ? noContentImage : keyItemsImage}
        />
      </Box>

      <Typography gutterBottom variant={isSmallScreen ? 'h6' : 'h4'} align="center">
        {isFailedStatus ? 'No Content' : 'No Key Items for this meeting'}
      </Typography>

      {isSubmittedStatus ? (
        <Typography
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
          color="textSecondary"
          align="center"
        >
          Unfortunately, this meeting does not contain enough content to generate Key Items
        </Typography>
      ) : (
        <Typography
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
          color="textSecondary"
          align="center"
        >
          {isFailedStatus && getFailureReason(failureReason, isManualRecording)}
          {isWaiting && 'Key Items will appear here after the meeting end'}
          {isNotScheduledStatus && 'Key Items will appear after the meeting is processed'}
        </Typography>
      )}

      {(isProcessedNotes || isTranscribed) && (
        <Box mt={4}>
          <Typography paragraph variant="body1" align="center">
            Please take a look at the following results instead:
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" gridGap={12}>
            {isProcessedNotes && (
              <Button
                disableElevation
                size="small"
                color="default"
                variant="outlined"
                className={styles.btn}
                onClick={handleRequestRedirect('MeetingNotes')}
              >
                Meeting Notes
              </Button>
            )}
            {isProcessedAssignments && (
              <Button
                disableElevation
                size="small"
                color="default"
                variant="outlined"
                className={styles.btn}
                onClick={handleRequestRedirect('MeetingAssignments')}
              >
                Meeting Tasks
              </Button>
            )}
            {isTranscribed && (
              <Button
                disableElevation
                size="small"
                color="default"
                variant="outlined"
                className={styles.btn}
                onClick={handleRequestRedirect('MeetingTranscript')}
              >
                Transcription
              </Button>
            )}
          </Box>
        </Box>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  btn: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    minWidth: 200,
  },
}));

export default MeetingPlaceholderKeyItems;
