import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
// Material UI
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { AutocompleteChangeReason } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
// GraphQL
import editActionAutomationMutation from '../graphql/mutations/EditActionsCustomOutboundUserIntegration.graphql';
import editMeetingNotesAutomationMutation from '../graphql/mutations/EditMeetingNotesCustomOutboundUserIntegration.graphql';
import editTranscriptAutomationMutation from '../graphql/mutations/EditTranscriptCustomOutboundUserIntegration.graphql';
import sendTestRequestMutation from '../graphql/mutations/SendAutomationTestRequest.graphql';
import userIntegrationsQuery from '../graphql/queries/UserOutboundIntegrations.graphql';
// Lib Shared
import { AutomationRuleCard, GenericConfirmation, GenericDialog, Tags } from '../components';
import { MEETING_TYPES } from '../constants';
import { isValidUri } from '../utils';
import { useConfirmationDialog } from '../hooks';
import {
  ActionFieldTypes,
  ActionsCustomIntegrationSettingsContentTypes,
  CustomIntegrationsTypes,
  CustomOutboundUserIntegrationRules,
  EditActionsCustomOutboundUserIntegration,
  EditActionsCustomOutboundUserIntegrationVariables,
  EditMeetingNotesCustomOutboundUserIntegration,
  EditMeetingNotesCustomOutboundUserIntegrationVariables,
  EditTranscriptCustomOutboundUserIntegration,
  EditTranscriptCustomOutboundUserIntegrationVariables,
  GenericActionsCustomOutboundUserIntegration,
  GenericMeetingNotesCustomOutboundUserIntegration,
  GenericTranscriptionCustomOutboundUserIntegration,
  GraphError,
  MeetingNotesFieldTypes,
  MeetingTypes,
  SendAutomationTestRequest,
  SendAutomationTestRequestVariables,
  TranscriptionFieldTypes,
} from '../types';
// Lib Assets & Icons
import DataTypeIcon from '../icons/DataType';
import DestinationIcon from '../icons/Destination';
import FilterIcon from '../icons/Filter';
import semblyLogo from '../assets/sembly-logo.svg';

export interface ManageCustomUserAutomationDialogProps {
  connectedIntegration:
    | GenericActionsCustomOutboundUserIntegration
    | GenericMeetingNotesCustomOutboundUserIntegration
    | GenericTranscriptionCustomOutboundUserIntegration;
  count: number;
  icon: string;
  open: boolean;
  onClose: (shouldDiscard?: boolean) => void;
  onGraphError: (error: GraphError) => void;
  onUpdated?: (automationName: string) => void;
}

/**
 * Dialog that allows the user to manage a custom user automation.
 * This dialog is used to create, edit, and delete custom user automations.
 * It also allows the user to test the integration and see the results.
 */
export const ManageCustomUserAutomationDialog: React.VFC<ManageCustomUserAutomationDialogProps> = ({
  connectedIntegration,
  count,
  icon,
  open,
  onClose = () => null,
  onGraphError,
  onUpdated = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [testResult, setTestResult] = useState<{ isSuccess: boolean; msg: string } | null>(null);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showDestinationDialog, setShowDestinationDialog] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [values, setValues] = useState({
    active: true,
    endpoint: '',
    keywords: [] as string[],
    meetingType: MeetingTypes.GENERIC,
    rule: CustomOutboundUserIntegrationRules.MANUAL_ONLY,
    contentType: ActionsCustomIntegrationSettingsContentTypes.COMMITMENTS,
    title: `Custom Automation ${count + 1}`,
  });

  const [confirmDiscarding, DiscardingConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Discard Changes"
      text="Are you sure you want to discard all changes and delete the automation?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Discard Changes"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));

  const [editAutomation, { loading: isEditingAutomation }] = useMutation<
    EditActionsCustomOutboundUserIntegration,
    EditActionsCustomOutboundUserIntegrationVariables
  >(editActionAutomationMutation, {
    refetchQueries: [{ query: userIntegrationsQuery }],
  });

  const [editTranscriptAutomation, { loading: isEditingTranscriptIntegration }] = useMutation<
    EditTranscriptCustomOutboundUserIntegration,
    EditTranscriptCustomOutboundUserIntegrationVariables
  >(editTranscriptAutomationMutation, {
    refetchQueries: [{ query: userIntegrationsQuery }],
  });

  const [editMeetingNotesAutomation, { loading: isEditingMeetingNotesIntegration }] = useMutation<
    EditMeetingNotesCustomOutboundUserIntegration,
    EditMeetingNotesCustomOutboundUserIntegrationVariables
  >(editMeetingNotesAutomationMutation, {
    refetchQueries: [{ query: userIntegrationsQuery }],
  });

  const [sendTestRequest, { loading: isSendingTestRequest }] = useMutation<
    SendAutomationTestRequest,
    SendAutomationTestRequestVariables
  >(sendTestRequestMutation);
  /* #endregion */

  /* #region  Helpers */
  const validateFields = () => {
    const isManualOnly = values.rule === CustomOutboundUserIntegrationRules.MANUAL_ONLY;
    const isAllMeetings = values.rule === CustomOutboundUserIntegrationRules.ALL_MEETINGS;
    const isKeywords = values.rule === CustomOutboundUserIntegrationRules.FILTERED_BY_KEYWORDS;
    const isMeetingType =
      values.rule === CustomOutboundUserIntegrationRules.FILTERED_BY_MEETING_TYPE;

    return (
      !!values.title.trim() &&
      !!values.endpoint &&
      (isAllMeetings ||
        isManualOnly ||
        (isKeywords && values.keywords.length > 0) ||
        (isMeetingType && !!values.meetingType))
    );
  };
  /* #endregion */

  /* #region  Handlers */
  const handleChangeIntegrationTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({ ...prev, title: e.target.value }));
  };

  const handleChangeMeetingType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as MeetingTypes;
    setValues((prev) => ({ ...prev, meetingType: newValue }));
  };

  const handleToggleFilterDialog = (open: boolean) => () => {
    setShowFilterDialog(open);
  };

  const handleToggleDestinationDialog = (open: boolean) => () => {
    setShowDestinationDialog(open);
  };

  const handleCloseTestResult = () => {
    setTestResult(null);
  };

  const handleTestIntegration = async () => {
    const isValidForm = validateFields();

    if (!isValidForm) {
      setTestResult({ isSuccess: false, msg: 'Please make sure the form is complete and valid' });
      return;
    }

    setTestResult(null); // Reset test result

    let integrationType: CustomIntegrationsTypes;

    switch (connectedIntegration.__typename) {
      case 'ActionsCustomOutboundUserIntegrationType':
        integrationType = CustomIntegrationsTypes.ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION;
        break;
      case 'TranscriptionCustomOutboundUserIntegrationType':
        integrationType = CustomIntegrationsTypes.TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION;
        break;
      case 'MeetingNotesCustomOutboundUserIntegrationType':
        integrationType = CustomIntegrationsTypes.MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION;
        break;
      default:
        throw new Error('Unknown integration type');
    }

    const result = await sendTestRequest({
      variables: {
        integrationType,
        endpoint: values.endpoint,
      },
    });

    if (result.data?.sendTestRequestToEndpoint?.success) {
      setTestResult({ isSuccess: true, msg: 'The operation completed successfully' });
    } else {
      setTestResult({
        isSuccess: false,
        msg:
          result.data?.sendTestRequestToEndpoint?.errors?.[0]?.messages?.[0] ||
          'Verification Failed. An unknown error occurred',
      });
    }
  };

  const handleChangeEndpoint = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();
    setValues((prev) => ({ ...prev, endpoint: newValue }));
  };

  const handleChangeRule = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as CustomOutboundUserIntegrationRules;
    setValues((prev) => ({ ...prev, rule: newValue }));
  };

  const handleChangeContentType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as ActionsCustomIntegrationSettingsContentTypes;
    setValues((prev) => ({ ...prev, contentType: newValue }));
  };

  const handleChangeTags = async (
    event: unknown,
    allTagsWithNewOne: (string | string[])[],
    reason: AutocompleteChangeReason,
  ) => {
    const tags = allTagsWithNewOne.map((tag) => tag.toString());
    if (tags.length && reason !== 'remove-option') {
      setValues((prev) => ({ ...prev, keywords: tags }));
    }
  };

  const handleDeleteTag = async (itemToDelete: string) => {
    setValues((prev) => ({
      ...prev,
      keywords: prev.keywords.filter((item) => item !== itemToDelete),
    }));
  };

  const handleDiscard = async () => {
    const isValidForm = validateFields();
    if (isValidForm) {
      onClose();
    } else {
      const shouldDiscard = await confirmDiscarding();
      if (shouldDiscard) onClose(shouldDiscard);
    }
  };

  const handleSubmit = async () => {
    const isValidForm = validateFields();
    setFormErrors([]);

    if (!isValidForm) {
      let errors: string[] = [];
      const isKeywordsRequired =
        values.rule === CustomOutboundUserIntegrationRules.FILTERED_BY_KEYWORDS;
      if (!values.title.trim()) errors.push('Automation Name is required');
      if (!values.endpoint) errors.push('Endpoint is required');
      if (values.endpoint && !isValidUri(values.endpoint)) {
        errors.push('Endpoint is not valid. Please enter a valid URL');
      }
      if (isKeywordsRequired && !values.keywords.length) {
        errors.push('Keywords is required. Please add at least one keyword');
      }
      setFormErrors(errors);
      return;
    }

    let integration = connectedIntegration;

    const title = values.title.trim();
    const isActive = true;
    const rule = values.rule;
    const description = ''; // TODO: Add description
    const endpoint = values.endpoint;
    const keywords = values.keywords;
    const meetingType = values.meetingType;
    const contentType = values.contentType;

    let result = null;

    if (integration.__typename === 'ActionsCustomOutboundUserIntegrationType') {
      // Set default configuration for callback fields
      const fields: { fieldName: ActionFieldTypes; name: string }[] = [
        { fieldName: ActionFieldTypes.MEETING_ID, name: 'meeting_id' },
        { fieldName: ActionFieldTypes.MEETING_OWNER_EMAIL, name: 'meeting_owner_email' },
        { fieldName: ActionFieldTypes.AUTOMATION_OWNER_EMAIL, name: 'automation_owner_email' },
        { fieldName: ActionFieldTypes.MEETING_TYPE, name: 'meeting_type' },
        { fieldName: ActionFieldTypes.MEETING_TEAM_ID, name: 'meeting_team_id' },
        { fieldName: ActionFieldTypes.MEETING_TEAM_NAME, name: 'meeting_team_name' },
        { fieldName: ActionFieldTypes.MEETING_TITLE, name: 'meeting_title' },
        { fieldName: ActionFieldTypes.MEETING_LINK, name: 'meeting_link' },
        { fieldName: ActionFieldTypes.MEETING_STARTED_AT, name: 'meeting_started_at' },
        { fieldName: ActionFieldTypes.MEETING_FINISHED_AT, name: 'meeting_finished_at' },
        { fieldName: ActionFieldTypes.ID, name: 'item_id' },
        { fieldName: ActionFieldTypes.TYPE, name: 'item_type' },
        { fieldName: ActionFieldTypes.ACTION_LINK, name: 'item_link' },
        { fieldName: ActionFieldTypes.HEADER_TEXT, name: 'item_header_text' },
        { fieldName: ActionFieldTypes.TEXT, name: 'item_text' },
        { fieldName: ActionFieldTypes.DUE_DATE, name: 'item_due_date' },
        { fieldName: ActionFieldTypes.IN_DISCUSSION_WITH, name: 'item_in_discussion_with' },
        { fieldName: ActionFieldTypes.ASSIGNED_TO, name: 'item_assigned_to' },
        { fieldName: ActionFieldTypes.ASSIGNED_BY, name: 'item_assigned_by' },
        { fieldName: ActionFieldTypes.WORKSPACE_ID, name: 'workspace_id' },
      ];

      const variables: EditActionsCustomOutboundUserIntegrationVariables = {
        integrationId: integration.id,
        rule,
        contentType,
        description,
        title,
        isActive,
        endpoint,
        keywords,
        meetingType,
        fields,
      };

      result = (await editAutomation({ variables })).data?.editActionsCustomOutboundUserIntegration;
    } else if (integration.__typename === 'TranscriptionCustomOutboundUserIntegrationType') {
      // Set default configuration for callback fields
      // prettier-ignore
      const fields: { fieldName: TranscriptionFieldTypes; name: string }[] = [
        { fieldName: TranscriptionFieldTypes.MEETING_TRANSCRIPTION, name: 'meeting_transcription' },
        { fieldName: TranscriptionFieldTypes.MEETING_ID, name: 'meeting_id' },
        { fieldName: TranscriptionFieldTypes.MEETING_OWNER_EMAIL, name: 'meeting_owner_email' },
        { fieldName: TranscriptionFieldTypes.AUTOMATION_OWNER_EMAIL, name: 'automation_owner_email' },
        { fieldName: TranscriptionFieldTypes.MEETING_TYPE, name: 'meeting_type' },
        { fieldName: TranscriptionFieldTypes.MEETING_TEAM_ID, name: 'meeting_team_id' },
        { fieldName: TranscriptionFieldTypes.MEETING_TEAM_NAME, name: 'meeting_team_name' },
        { fieldName: TranscriptionFieldTypes.MEETING_LINK, name: 'meeting_link' },
        { fieldName: TranscriptionFieldTypes.MEETING_TITLE, name: 'meeting_title' },
        { fieldName: TranscriptionFieldTypes.MEETING_STARTED_AT, name: 'meeting_started_at' },
        { fieldName: TranscriptionFieldTypes.MEETING_FINISHED_AT, name: 'meeting_finished_at' },
        { fieldName: TranscriptionFieldTypes.MEETING_DURATION, name: 'meeting_duration' },
        { fieldName: TranscriptionFieldTypes.PARTICIPANTS, name: 'participants' },
        { fieldName: TranscriptionFieldTypes.WORKSPACE_ID, name: 'workspace_id' },
      ];

      const variables: EditTranscriptCustomOutboundUserIntegrationVariables = {
        integrationId: integration.id,
        rule,
        description,
        title,
        isActive,
        endpoint,
        keywords,
        meetingType,
        fields,
      };

      result = (await editTranscriptAutomation({ variables })).data
        ?.editTranscriptionCustomOutboundUserIntegration;
    } else if (integration.__typename === 'MeetingNotesCustomOutboundUserIntegrationType') {
      // Set default configuration for callback fields
      // prettier-ignore
      const fields: { fieldName: MeetingNotesFieldTypes; name: string }[] = [
        { fieldName: MeetingNotesFieldTypes.MEETING_NOTES, name: 'meeting_notes' },
        { fieldName: MeetingNotesFieldTypes.MEETING_ID, name: 'meeting_id' },
        { fieldName: MeetingNotesFieldTypes.MEETING_OWNER_EMAIL, name: 'meeting_owner_email' },
        { fieldName: MeetingNotesFieldTypes.AUTOMATION_OWNER_EMAIL, name: 'automation_owner_email' },
        { fieldName: MeetingNotesFieldTypes.MEETING_TYPE, name: 'meeting_type' },
        { fieldName: MeetingNotesFieldTypes.MEETING_TEAM_ID, name: 'meeting_team_id' },
        { fieldName: MeetingNotesFieldTypes.MEETING_TEAM_NAME, name: 'meeting_team_name' },
        { fieldName: MeetingNotesFieldTypes.MEETING_TITLE, name: 'meeting_title' },
        { fieldName: MeetingNotesFieldTypes.MEETING_LINK, name: 'meeting_link' },
        { fieldName: MeetingNotesFieldTypes.MEETING_STARTED_AT, name: 'meeting_started_at' },
        { fieldName: MeetingNotesFieldTypes.MEETING_FINISHED_AT, name: 'meeting_finished_at' },
        { fieldName: MeetingNotesFieldTypes.MEETING_DURATION, name: 'meeting_duration' },
        { fieldName: MeetingNotesFieldTypes.PARTICIPANTS, name: 'participants' },
        { fieldName: MeetingNotesFieldTypes.WORKSPACE_ID, name: 'workspace_id' },
      ];

      const variables: EditMeetingNotesCustomOutboundUserIntegrationVariables = {
        integrationId: integration.id,
        rule,
        description,
        title,
        isActive,
        endpoint,
        keywords,
        meetingType,
        fields,
      };

      result = (await editMeetingNotesAutomation({ variables })).data
        ?.editMeetingNotesCustomOutboundUserIntegration;
    } else {
      throw new Error('Unknown integration type');
    }

    if (!result?.success) {
      onGraphError(result?.errors);
    } else {
      onUpdated(title);
      onClose();
    }
  };
  /* #endregion */

  /* #region  Effects */
  // Set initial values
  useEffect(() => {
    const contentType =
      connectedIntegration.__typename === 'ActionsCustomOutboundUserIntegrationType'
        ? connectedIntegration.contentType
        : null;

    setValues((currentValues) => ({
      title: connectedIntegration.title || currentValues.title,
      endpoint: connectedIntegration.endpoint || '',
      rule: connectedIntegration.rule ?? CustomOutboundUserIntegrationRules.MANUAL_ONLY,
      contentType: contentType || ActionsCustomIntegrationSettingsContentTypes.COMMITMENTS,
      keywords: (connectedIntegration.keywords as string[]) || [],
      meetingType: connectedIntegration.meetingType || MeetingTypes.GENERIC,
      active: connectedIntegration.isActive,
    }));
  }, [connectedIntegration]);
  /* #endregion */

  /* #region  Render Helpers */
  const isUpdating =
    isEditingAutomation || isEditingTranscriptIntegration || isEditingMeetingNotesIntegration;

  const isActionTypeIntegration =
    connectedIntegration.__typename === 'ActionsCustomOutboundUserIntegrationType';

  const dataTypeLabel = {
    ActionsCustomOutboundUserIntegrationType: 'Tasks',
    MeetingNotesCustomOutboundUserIntegrationType: 'Meeting Notes',
    TranscriptionCustomOutboundUserIntegrationType: 'Transcription',
  }[connectedIntegration.__typename];

  const filterLabel = {
    [CustomOutboundUserIntegrationRules.MANUAL_ONLY]: 'Disabled (Manual only)',
    [CustomOutboundUserIntegrationRules.ALL_MEETINGS]:
      'Apply to all meetings that I have access to',
    [CustomOutboundUserIntegrationRules.FILTERED_BY_MEETING_TYPE]:
      'Apply to meetings of a specific type',
    [CustomOutboundUserIntegrationRules.FILTERED_BY_KEYWORDS]:
      'Apply to meetings with keywords in the title',
  }[values.rule];

  // Convert MEETING_TYPES to array save, category => isCategory true;
  const meetingTypeOptions: { label: string; value: string | null; isCategory: boolean }[] =
    MEETING_TYPES.flatMap(({ category, elements }) => [
      { label: category, value: null, isCategory: true },
      ...elements.map((element) => ({
        label: element.label,
        value: element.value,
        isCategory: false,
      })),
    ]);
  /* #endregion */

  return (
    <>
      <GenericDialog
        hideTitle
        hideCloseIconButton
        disableTransition
        title="Apps"
        dialogProps={{ open, fullScreen: true, style: { zIndex: 1000 } }}
        onClose={handleDiscard}
      >
        <DialogContent className={styles.dialogContent}>
          <div className={styles.header}>
            <img src={semblyLogo} alt="Sembly" className={styles.logo} />
          </div>
          <div className={styles.content}>
            <div className={styles.appLogo}>
              {icon ? (
                <img src={icon} alt={dataTypeLabel} width={64} height={64} />
              ) : (
                <Avatar>{dataTypeLabel.charAt(0).toUpperCase()}</Avatar>
              )}
            </div>
            <div>
              <Box position="relative">
                <Tooltip arrow title="Enter Automation Name">
                  <InputBase
                    autoFocus
                    placeholder="Enter Automation Name"
                    classes={{ root: styles.fieldRoot, input: styles.fieldInput }}
                    inputProps={{ 'aria-label': 'Enter Automation Name' }}
                    value={values.title}
                    onChange={handleChangeIntegrationTitle}
                  />
                </Tooltip>
              </Box>
              <div className={styles.steps}>
                <AutomationRuleCard
                  disabled
                  title="1. Data Type"
                  description="What would you like to send: notes, tasks, or transcriptions?"
                  icon={<DataTypeIcon color="action" />}
                  value={dataTypeLabel}
                />
                <div className={styles.delimiter} role="presentation">
                  —
                </div>
                <AutomationRuleCard
                  title="2. Filter"
                  description="Which meetings group should this be applied to?"
                  icon={<FilterIcon color="action" />}
                  value={!!values.rule ? filterLabel : null}
                  onClick={handleToggleFilterDialog(true)}
                />
                <div className={styles.delimiter} role="presentation">
                  —
                </div>
                <AutomationRuleCard
                  title="3. Destination"
                  description="Which destination would you like to use for sending this information?"
                  icon={<DestinationIcon color="action" />}
                  value={!!values.endpoint ? values.endpoint : null}
                  onClick={handleToggleDestinationDialog(true)}
                />
              </div>

              {!!formErrors.length && (
                <Box my={2} display="flex" flexDirection="column" gridGap={6}>
                  {formErrors.map((error, index) => (
                    <Alert key={index} color="warning">
                      <Typography variant="body1">{error}</Typography>
                    </Alert>
                  ))}
                </Box>
              )}

              <div className={styles.dialogActions}>
                <Box flexGrow={1}>
                  <Button variant="outlined" onClick={handleDiscard}>
                    <span>Back</span>
                  </Button>
                </Box>
                <Box flexGrow={0} display="flex" gridGap={8}>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={!validateFields() || isSendingTestRequest || isUpdating}
                    onClick={handleTestIntegration}
                    startIcon={
                      isSendingTestRequest ? <CircularProgress size={18} color="inherit" /> : null
                    }
                  >
                    <span>Test</span>
                  </Button>
                  <Box display="flex" flexGrow={0} gridGap={8}>
                    <Button
                      disableElevation
                      color="primary"
                      variant="contained"
                      disabled={isUpdating}
                      onClick={handleSubmit}
                      startIcon={isUpdating ? <CircularProgress size={18} color="inherit" /> : null}
                    >
                      <span>Complete</span>
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
          {!!testResult && (
            <Box mt={3}>
              <Alert
                square
                variant="filled"
                severity={testResult.isSuccess ? 'success' : 'error'}
                action={
                  <IconButton
                    aria-label="close"
                    size="small"
                    color="inherit"
                    onClick={handleCloseTestResult}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {testResult.msg}
              </Alert>
            </Box>
          )}
        </DialogContent>
      </GenericDialog>
      {/* Begin: Dialogs */}
      {showFilterDialog && (
        <GenericDialog
          title="Filter meetings"
          dialogProps={{ open, fullWidth: true, maxWidth: 'sm' }}
          onClose={() => setShowFilterDialog(false)}
        >
          <DialogContent>
            {isActionTypeIntegration && (
              <Box mb={3}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Apply to the following Tasks"
                  value={values?.contentType || ''}
                  onChange={handleChangeContentType}
                >
                  <MenuItem value={ActionsCustomIntegrationSettingsContentTypes.COMMITMENTS}>
                    Only Tasks assigned to me
                  </MenuItem>
                  <MenuItem value={ActionsCustomIntegrationSettingsContentTypes.ACTIONS}>
                    All Tasks
                  </MenuItem>
                </TextField>
              </Box>
            )}
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Automation Rule"
              value={values?.rule || ''}
              onChange={handleChangeRule}
            >
              <MenuItem value={CustomOutboundUserIntegrationRules.MANUAL_ONLY}>
                Disabled (Manual only)
              </MenuItem>
              <MenuItem value={CustomOutboundUserIntegrationRules.ALL_MEETINGS}>
                Apply to all meetings that I have access to
              </MenuItem>
              <MenuItem value={CustomOutboundUserIntegrationRules.FILTERED_BY_KEYWORDS}>
                Apply to meetings with keywords in the title
              </MenuItem>
              <MenuItem value={CustomOutboundUserIntegrationRules.FILTERED_BY_MEETING_TYPE}>
                Apply to meetings of a specific type
              </MenuItem>
            </TextField>
            {values.rule === CustomOutboundUserIntegrationRules.FILTERED_BY_KEYWORDS && (
              <Box mt={2}>
                <Tags
                  all={values.keywords}
                  onChangeTags={handleChangeTags}
                  onDelete={handleDeleteTag}
                  InputProps={{
                    variant: 'outlined',
                    helperText: 'Press Enter after typing a keyword to add a new tag.',
                  }}
                />
              </Box>
            )}
            {values?.rule === CustomOutboundUserIntegrationRules.FILTERED_BY_MEETING_TYPE && (
              <Box mt={2}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Meeting Type"
                  value={values?.meetingType || ''}
                  onChange={handleChangeMeetingType}
                >
                  {meetingTypeOptions.map((item, index) =>
                    item.isCategory ? (
                      <ListSubheader key={index} classes={{ root: styles.listSubHeaderRoot }}>
                        {item.label}
                      </ListSubheader>
                    ) : (
                      <MenuItem
                        key={index}
                        value={item.value || ''}
                        selected={item.value === values?.meetingType}
                      >
                        {item.label}
                      </MenuItem>
                    ),
                  )}
                </TextField>
              </Box>
            )}
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              disabled={!values}
              onClick={handleToggleFilterDialog(false)}
            >
              Done
            </Button>
          </DialogActions>
        </GenericDialog>
      )}
      {showDestinationDialog && (
        <GenericDialog
          title="Destination"
          dialogProps={{ open, fullWidth: true, maxWidth: 'sm' }}
          onClose={() => setShowDestinationDialog(false)}
        >
          <DialogContent>
            <TextField
              fullWidth
              type="url"
              size="small"
              variant="outlined"
              label="Endpoint"
              value={values?.endpoint || ''}
              onChange={handleChangeEndpoint}
            />
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              disabled={!values}
              onClick={handleToggleDestinationDialog(false)}
            >
              Done
            </Button>
          </DialogActions>
        </GenericDialog>
      )}
      {DiscardingConfirmationDialog}
      {/* End: Dialogs */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1, 4, 4),
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    flex: 1,
  },
  appLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 128,
    width: 128,
  },
  skeleton: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  logo: {
    flexGrow: 0,
    width: 160,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 20,
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2, 3),
  },
  steps: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    flexDirection: 'row',
    gap: theme.spacing(2),
    margin: theme.spacing(4, 0),
  },
  delimiter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fieldRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px dashed ${theme.palette.divider}`,
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    ...theme.typography.h1,
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
  fieldInput: {
    textAlign: 'center',
  },
  listSubHeaderRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export default ManageCustomUserAutomationDialog;
