import { useMutation, useQuery } from '@apollo/client';
// Lib Shared
import {
  CreateNote,
  CreateNoteVariables,
  EditNote,
  EditNoteVariables,
  GraphError,
  MeetingNote,
  MeetingNoteVariables,
} from '../types';
// Lib Queries
import query from '../graphql/queries/MeetingNote.graphql';
import createNoteMutation from '../graphql/mutations/CreateNote.graphql';
import updateNoteMutation from '../graphql/mutations/EditNote.graphql';
// Lib Components
import NotesEditor from '../components/NotesEditor';

export interface NotesContainerProps {
  meetingId: string;
  onResponseError: (errorMessage: GraphError) => void;
}

export const NotesContainer: React.VFC<NotesContainerProps> = ({ meetingId, onResponseError }) => {
  /* #region  Hooks */
  const { data, loading: isLoadingData } = useQuery<MeetingNote, MeetingNoteVariables>(query, {
    variables: { meetingId },
  });

  const [createNote] = useMutation<CreateNote, CreateNoteVariables>(createNoteMutation);

  const [updateNote, { loading: isUpdatingNote }] = useMutation<EditNote, EditNoteVariables>(
    updateNoteMutation,
  );
  /* #endregion */

  /* #region  Handlers */
  const handleCreateNote = async () => {
    // just ignore if we're have an already created note
    if (!!data?.meetingNote?.id) return;

    const result = await createNote({
      variables: { meetingId },
      optimisticResponse: {
        createNote: {
          __typename: 'CreateNoteMutationPayload',
          note: { __typename: 'NoteType', id: meetingId, text: '' },
          success: true,
          errors: [],
        },
      },
      update: (cache, mutationResult) => {
        if (!data || !mutationResult.data?.createNote?.note) return;
        cache.writeQuery<MeetingNote, MeetingNoteVariables>({
          query,
          variables: { meetingId },
          data: {
            meetingNote: mutationResult.data.createNote.note,
            meeting: data?.meeting || null,
          },
        });
      },
    });

    if (!result.data?.createNote?.success) {
      onResponseError(result.data?.createNote?.errors);
    }
  };

  const handleUpdateNote = async (value: string) => {
    if (!data?.meetingNote?.id) return;

    const result = await updateNote({
      variables: { text: value, id: +data.meetingNote.id },
      optimisticResponse: {
        editNote: {
          __typename: 'EditNoteMutationPayload',
          success: true,
          errors: [],
          note: {
            __typename: 'NoteType',
            id: data.meetingNote.id,
            text: value,
          },
        },
      },
    });

    if (!result.data?.editNote?.success) {
      onResponseError(result.data?.editNote?.errors);
    }
  };
  /* #endregion */

  return (
    <NotesEditor
      isLoading={isLoadingData}
      isUpdating={isUpdatingNote}
      value={data?.meetingNote?.text || undefined}
      onFocus={handleCreateNote}
      onUpdateNote={handleUpdateNote}
    />
  );
};

export default NotesContainer;
