import { ActionItemType } from '../types';

export interface AssignmentType {
  id: ActionItemType;
  name: string;
  description: string;
  color: string;
  backgroundColor: string;
}

export const ASSIGNMENT_TYPES: Record<ActionItemType, AssignmentType> = {
  [ActionItemType.ACTIVITY]: {
    id: ActionItemType.ACTIVITY,
    name: 'Activities',
    description: 'Activities are small tasks that can be completed in a short amount of time.',
    color: '#00A69F',
    backgroundColor: '#F0FBFA',
  },
  [ActionItemType.PROJECT]: {
    id: ActionItemType.PROJECT,
    name: 'Projects',
    description: 'Projects are larger tasks that can be completed in a longer amount of time.',
    color: '#A537A8',
    backgroundColor: '#FFF5FE',
  },
  [ActionItemType.TASK]: {
    id: ActionItemType.TASK,
    name: 'Tasks',
    description: 'Tasks are medium-sized tasks that can be completed in a medium amount of time.',
    color: '#576ACE',
    backgroundColor: '#F7F8FF',
  },
  [ActionItemType.TODO]: {
    id: ActionItemType.TODO,
    name: 'Tasks',
    description: 'Tasks are medium-sized tasks that can be completed in a medium amount of time.',
    color: '#576ACE',
    backgroundColor: '#F7F8FF',
  },
};

export default ASSIGNMENT_TYPES;
