import { Languages, MeetingProcessingResultsMainLanguage } from '../../types';

/**
 * @function isRTLLang
 * @description Returns true if the language is RTL
 */
export function isRTLLang(
  lang: Languages | MeetingProcessingResultsMainLanguage | null | undefined,
): boolean {
  if (!lang) return false;
  return [
    Languages.ARABIC,
    Languages.HEBREW,
    Languages.PERSIAN,
    Languages.URDU,
    MeetingProcessingResultsMainLanguage.ARABIC,
    MeetingProcessingResultsMainLanguage.HEBREW,
    MeetingProcessingResultsMainLanguage.PERSIAN,
    MeetingProcessingResultsMainLanguage.URDU
  ].includes(lang);
}

export default isRTLLang;
