import { useEffect, useState } from 'react';
import throttle from 'lodash/fp/throttle';

export interface useScrollSpyProps {
  activeSectionDefault?: number;
  offsetPx?: number;
  sectionElementRefs: React.RefObject<HTMLElement>[];
  scrollContainer?: React.RefObject<HTMLElement> | null;
  throttleMs?: number;
}

export const useScrollSpy = ({
  activeSectionDefault = 0,
  offsetPx = 0,
  scrollContainer,
  sectionElementRefs = [],
  throttleMs = 100,
}: useScrollSpyProps) => {
  const [activeSection, setActiveSection] = useState(activeSectionDefault);

  const handle = throttle(throttleMs, () => {
    let currentSectionId = activeSection;

    for (let i = 0; i < sectionElementRefs.length; i++) {
      const section = sectionElementRefs[i].current;
      // Needs to be a valid DOM Element
      if (!section || !(section instanceof Element)) continue;
      // GetBoundingClientRect returns values relative to viewport
      if (section.getBoundingClientRect().top + offsetPx < 0) {
        currentSectionId = i;
        continue;
      }
      // No need to continue loop, if last element has been detected
      break;
    }

    setActiveSection(currentSectionId);
  });

  useEffect(() => {
    const container = scrollContainer?.current || window;
    container.addEventListener('scroll', handle);
    handle(); // Run initially

    return () => {
      container.removeEventListener('scroll', handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionElementRefs, offsetPx]);

  return activeSection;
};

export default useScrollSpy;
