import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { parseISO } from 'date-fns';
import { useState, memo } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import AssignmentCard from './AssignmentCard';
import { ASSIGNMENT_TYPES } from '../constants';
import { ActionItemMenuType, ActionItemType, GenericAssignment } from '../types';

/* #region  Types */
export type AssignmentsGroupToggleMenuHandler = (
  data: {
    type: ActionItemMenuType;
    item: GenericAssignment;
    anchorEl: HTMLElement;
  } | null,
) => void;

export interface MeetingAssignmentsGroupProps {
  enableBetaFeatures: boolean;
  isAuthorizedToEdit: boolean;
  isAuthorizedToExport: boolean;
  isRTLDirected?: boolean;
  isForceHiddenIntegrations?: boolean;
  items: GenericAssignment[];
  type: ActionItemType;
  onChangeDiscussedWith: (keyItem: GenericAssignment, text: string) => void;
  onChangeText: (keyItem: GenericAssignment, text: string) => void;
  onChangeTitle: (keyItem: GenericAssignment, text: string) => void;
  onToggleMenu: AssignmentsGroupToggleMenuHandler;
}
/* #endregion */

/**
 * @component MeetingAssignmentsGroup
 * @description A grouped list of assignments
 */
const Component: React.VFC<MeetingAssignmentsGroupProps> = ({
  enableBetaFeatures,
  isAuthorizedToEdit,
  isAuthorizedToExport,
  isRTLDirected = false,
  isForceHiddenIntegrations = false,
  items,
  type,
  onChangeDiscussedWith,
  onChangeText,
  onChangeTitle,
  onToggleMenu,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isExpanded, setIsExpanded] = useState(true);
  const [shownInactive, setShownInactive] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleToggleExpand = () => {
    setIsExpanded((currrentState) => !currrentState);
  };

  const handleToggleInactiveItems = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShownInactive((currentState) => !currentState);
  };

  const handleChangeText = (keyItem: GenericAssignment) => (text: string) => {
    onChangeText(keyItem, text);
  };

  const handleChangeTitle = (keyItem: GenericAssignment) => (text: string) => {
    onChangeTitle(keyItem, text);
  };

  const handleChangeInDiscussionWith = (keyItem: GenericAssignment) => (text: string) => {
    onChangeDiscussedWith(keyItem, text);
  };

  const handleToggleMenu =
    (item: GenericAssignment) => (type: ActionItemMenuType, anchorEl: HTMLElement | null) => {
      onToggleMenu(anchorEl ? { type, item, anchorEl } : null);
    };
  /* #endregion */

  /* #region  Render Helpers */
  const group = ASSIGNMENT_TYPES[type];
  const hasActiveItems = items.some((item) => item.isActive);
  const hasInactiveItems = items.some((item) => !item.isActive);
  /* #endregion */

  return (
    <Box
      bgcolor={group.backgroundColor}
      borderRadius={8}
      color={group.color}
      border={isSmallScreen ? "6px solid transparent" : "none"}
      p={isSmallScreen ? 0 : 2}
      mb={2}
    >
      <Box
        display="flex"
        alignItems="center"
        style={{ cursor: 'pointer', direction: isRTLDirected ? 'rtl' : 'inherit' }}
        mt={1}
        mb={1}
        onClick={handleToggleExpand}
      >
        <Box flex={1} display="flex" alignItems="center">
          <ExpandMoreIcon className={clsx(styles.expandIcon, !isExpanded && styles.expanded)} />
          <Typography component="div" variant="h6">
            {group.name}
          </Typography>
          <Typography
            component="div"
            className={styles.quantity}
            style={{ backgroundColor: alpha(group.color, 0.2) }}
          >
            {items.length}
          </Typography>
        </Box>

        {isExpanded && hasInactiveItems && (
          <Button
            size="small"
            variant="outlined"
            className={styles.chipButton}
            onClick={handleToggleInactiveItems}
          >
            <span>{shownInactive ? 'Hide inactive ' : 'Show inactive '}</span>
          </Button>
        )}
      </Box>

      {shownInactive || hasActiveItems ? (
        <Collapse in={isExpanded}>
          <Box mt={1}>
            {items.map((item) => {
              // Don't render inactive items if they are not shown
              return !shownInactive && !item.isActive ? null : (
                <AssignmentCard
                  assignee={item.assignedBy || item.rawAssignedBy}
                  customText={item.customText || ''}
                  customeTitle={item.customHeaderText || ''}
                  discussedWith={item.inDiscussionWith}
                  dueDate={(item.dueDate && parseISO(item.dueDate)) || item.rawTiming}
                  isActive={item.isActive}
                  isAuthorizedToEdit={isAuthorizedToEdit}
                  isAuthorizedToExport={isAuthorizedToExport}
                  isEditedBySembly={item.editedBySembly}
                  isHiddenIntegrations={isForceHiddenIntegrations}
                  isRTLDirected={isRTLDirected}
                  key={item.id}
                  keyItemId={item.id}
                  performer={item.performer || item.rawPerformerName}
                  text={item.text || ''}
                  title={item.headerText || ''}
                  type={item.itemType}
                  workstreamName={enableBetaFeatures ? item.workstream?.name : undefined}
                  onChangeDiscussedWith={handleChangeInDiscussionWith(item)}
                  onChangeText={handleChangeText(item)}
                  onChangeTitle={handleChangeTitle(item)}
                  onToggleMenu={handleToggleMenu(item)}
                />
              );
            })}
          </Box>
        </Collapse>
      ) : (
        <Typography variant="body1" color="textSecondary">
          There are no active assignments in this group
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  chipButton: {
    height: 24,
    borderColor: theme.palette.grey['A100'],
    color: theme.palette.common.black,
  },
  quantity: {
    padding: theme.spacing(0, 1.25),
    margin: theme.spacing(0, 1),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 10,
  },
  expandIcon: {
    position: 'relative',
    transition: 'transform 0.25s cubic-bezier(0.52, 0.16, 0.24, 1)',
    left: -2,
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
}));

function compareProps(prev: MeetingAssignmentsGroupProps, next: MeetingAssignmentsGroupProps) {
  // Compare data and return true if they are not need to be re-rendered
  return (
    prev.isAuthorizedToEdit === next.isAuthorizedToEdit &&
    prev.isAuthorizedToExport === next.isAuthorizedToExport &&
    prev.isForceHiddenIntegrations === next.isForceHiddenIntegrations &&
    prev.type === next.type &&
    isEqual(prev.items, next.items)
  );
}

/**
 * @name MeetingAssignmentsGroup
 * @description The component that renders a collapsible group of assignment cards
 * @param {MeetingAssignmentsGroupProps} props
 * @returns {JSX.Element}
 */
export const MeetingAssignmentsGroup = memo(Component, compareProps);

export default MeetingAssignmentsGroup;
