import uniq from 'lodash/uniq';
import { useState, createContext } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Material UI
import { AutocompleteChangeReason, AutocompleteChangeDetails } from '@material-ui/lab/Autocomplete'; // prettier-ignore
// Lib Queries
import suggestedInvitedUsers from '../graphql/queries/SuggestedInvitedUsers.graphql';
// App Shared
import { isValidEmail } from '../utils';
import {
  GenericDefaultUser,
  SuggestedInvitedUsers,
  SuggestedInvitedUsersVariables,
} from '../types';

interface AutocompleteContextInterface {
  isOpenShareDialog: boolean;
  isLoadingSuggestions: boolean;
  calledSubmit: boolean;
  inviteValue: string;
  options: ShareFormTargetStateObject[];
  setCalledSubmit: (state: boolean) => void;
  setTarget: (value: (string | ShareFormTargetStateObject)[]) => void;
  handlePaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  handleChangeValue: (
    event: React.ChangeEvent<{}>,
    value: ShareFormTargetState[],
    reason: AutocompleteChangeReason,
  ) => void;
  setOpenShareDialog: (state: boolean) => void;
  handleChangeInput: (event: unknown, email: string) => void;
  target: (string | ShareFormTargetStateObject)[];
}

type ShareFormTargetStateObject = {
  label: string;
  value: GenericDefaultUser;
  search: string;
};

type ShareFormTargetState =
  | ShareFormTargetStateObject
  | ShareFormTargetStateObject[]
  | string
  | string[];

type AutocompleteTagsChangeEvent = (
  event: React.ChangeEvent<{}>,
  value: ShareFormTargetState[],
  reason: AutocompleteChangeReason,
  details?:
    | AutocompleteChangeDetails<
        ShareFormTargetStateObject[] | ShareFormTargetStateObject | string[] | string
      >
    | undefined,
) => void;

export const AutocompleteContext = createContext<AutocompleteContextInterface>({
  isOpenShareDialog: false,
  target: [],
  inviteValue: '',
  calledSubmit: false,
  isLoadingSuggestions: false,
  options: [],
  setTarget: () => null,
  handlePaste: () => null,
  handleChangeInput: () => null,
  handleChangeValue: () => null,
  setCalledSubmit: () => null,
  setOpenShareDialog: () => null,
});

export const AutocompleteProvider: React.FC = ({ children }) => {
  const [target, setTarget] = useState<(string | ShareFormTargetStateObject)[]>([]);
  const [inviteValue, setInviteValue] = useState('');
  const [isOpenShareDialog, setOpenShareDialog] = useState(false);
  const [calledSubmit, setCalledSubmit] = useState(false);
  const { meetingId } = useParams<{ meetingId: string }>();

  /* #region  Hooks */
  const [getSuggestions, { data: suggestionsData, loading: isLoadingSuggestions }] = useLazyQuery<
    SuggestedInvitedUsers,
    SuggestedInvitedUsersVariables
  >(suggestedInvitedUsers);
  /* #endregion */

  /* #region  Handlers */
  const handleGetSuggestionsRequest = (search: string) => {
    getSuggestions({ variables: { meetingId, search } });
  };

  const handleChangeValue: AutocompleteTagsChangeEvent = (e, values, reason) => {
    switch (reason) {
      case 'create-option':
        if (typeof values !== 'string') {
          const filteredEmails = values
            .flat()
            .map((elem) => (typeof elem === 'string' ? elem : elem.value.email))
            .join(';')
            .replaceAll(',', ';')
            .split(';')
            .filter((email) => isValidEmail(email));

          setTarget((emails) => uniq([...emails, ...filteredEmails]));
          setOpenShareDialog(true);
        }
        break;
      case 'select-option':
        const filteredEmails = values
          .flat()
          .map((elem) => (typeof elem === 'string' ? elem : elem.value.email))
          .filter((item) => isValidEmail(item));

        setTarget((emails) => uniq([...emails, ...filteredEmails]));
        setOpenShareDialog(true);
        break;
      case 'remove-option':
        if (typeof values !== 'string') {
          setTarget(values.flat());
        }
        break;
      case 'clear':
        setTarget([]);
        break;
    }
  };

  const handleGetSuggestions = useDebouncedCallback((search: string) => {
    handleGetSuggestionsRequest(search);
  }, 1000);

  const handleChangeInput = (event: unknown, email: string) => {
    setInviteValue(email);
    handleGetSuggestions(email);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const value = event.clipboardData.getData('Text');
    setInviteValue(value);
    handleGetSuggestions(value);
  };
  /* #endregion */

  const suggestedInviteUsers = suggestionsData?.suggestedInviteUsers || [];
  const options =
    suggestedInviteUsers.map((user) => ({
      label: user.fullName,
      value: user,
      search: `${user.fullName} ${user.email}`,
    })) || [];

  return (
    <AutocompleteContext.Provider
      value={{
        isOpenShareDialog,
        isLoadingSuggestions,
        options,
        target,
        inviteValue,
        calledSubmit,
        setTarget,
        handlePaste,
        setCalledSubmit,
        handleChangeInput,
        handleChangeValue,
        setOpenShareDialog,
      }}
    >
      {children}
    </AutocompleteContext.Provider>
  );
};
