/**
 * ResolveSystemTimeZone
 * @description Resolve the timezone of the user. Returns Etc/GMT if the timezone is unknown.
 * @returns {string} The timezone of the user
 */
export function resolveSystemTimeZone(): string {
  const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Etc/GMT';
  const isValidResolvedTimezone = !resolvedTimeZone.toLowerCase().includes('unknown');
  return isValidResolvedTimezone ? resolvedTimeZone : 'Etc/GMT';
}

export default resolveSystemTimeZone;
