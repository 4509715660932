import { lazyWithRetry } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

const WorkspaceTransferModuleContainer = lazyWithRetry(
  () => import('./containers/WorkspaceTransferModuleContainer'),
);

export const WorkspaceTransferModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    exact: true,
    path: Routes.WorkspaceTransfer,
    render: () => <WorkspaceTransferModuleContainer />,
  },
};

export default WorkspaceTransferModule;
