import { lazyWithRetry } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

const GuestMeetingNotesModuleContainer = lazyWithRetry(
  () => import('./containers/GuestMinutesModuleContainer'),
);

/**
 * @name GuestMeetingNotesModule
 * @description Module responsible for the rendering of the meeting notes guest pages
 * @returns JSX.Element
 */
export const GuestMeetingNotesModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.GuestNotes,
    render: () => <GuestMeetingNotesModuleContainer />,
  },
};

export default GuestMeetingNotesModule;
