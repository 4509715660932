// Material UI
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// Shared Assets
import errorIcon from '@shared/assets/visual-error.svg';

export interface ErrorBoundaryDialogProps {
  error?: Error;
  onClose: () => void;
}

export const ErrorBoundaryDialog: React.VFC<ErrorBoundaryDialogProps> = ({ error, onClose }) => {
  const handleShowZendeskWidget = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    onClose();
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <GenericDialog
      hideCloseIconButton
      dialogProps={{ scroll: 'paper', disableEscapeKeyDown: true, disableBackdropClick: true }}
      onClose={onClose}
    >
      <DialogContent>
        <Box my={4} textAlign="center">
          <Box mb={6}>
            <img src={errorIcon} width="176" alt="Sembly" />
          </Box>
          <Typography gutterBottom variant="h4">
            Something went wrong
          </Typography>
          <Box mt={2}>
            {!!error?.message && (
              <Typography gutterBottom display="block" variant="body1">
                {error.message}
              </Typography>
            )}
            <Typography display="block" variant="body1">
              Please try again, <Link onClick={handleReloadPage}>reload the page</Link>, or report
              an issue to support.
            </Typography>
          </Box>
          <Box mt={4}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              aria-label="Try again"
              onClick={onClose}
            >
              Try again
            </Button>
            <Box display="inline-block" pl={1} />
            <Button
              variant="outlined"
              aria-label="Report an issue"
              onClick={handleShowZendeskWidget}
            >
              Report an issue
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

export default ErrorBoundaryDialog;
