import { PlanIDEnum } from '@gql-types';

type PaymentPlanEnum = { [K in PlanIDEnum]: boolean | string };
type PaymentPlanGroup = Record<string, ReadonlyArray<Plan>>;

interface Plan extends PaymentPlanEnum {
  feature: string;
}

// prettier-ignore
export const PRICE_LINES: PaymentPlanGroup = {
  'Workspace': [
    { feature: 'Members limit (charges apply per user in the workspace)', PERSONAL: '1', PROFESSIONAL: '1', TEAM: '40', TEAM_PLUS: '40', ENTERPRISE: 'Unlimited' },
  ],
  'Welcome One-time Bonus': [
    { feature: 'Credits for upload (one time)', PERSONAL: false, PROFESSIONAL: '60', TEAM: '150', TEAM_PLUS: '150', ENTERPRISE: '150' },
  ],
  'Record and Capture': [
    { feature: 'Conference platforms (Google Meet, Microsoft Teams, Zoom, Webex)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Calendar sync (Google, Outlook, iCalendar)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Hybrid meetings (microphone recording)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Video capture', PERSONAL: false, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Credits for upload', PERSONAL: false, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Video capture (data storage is limited to months)', PERSONAL: false, PROFESSIONAL: '12', TEAM: '12', TEAM_PLUS: '12', ENTERPRISE: 'Unlimited' },
    { feature: 'Online Meeting recording (hours)', PERSONAL: '1', PROFESSIONAL: 'Unlimited', TEAM: 'Unlimited', TEAM_PLUS: 'Unlimited', ENTERPRISE: 'Unlimited' },
    { feature: '- first month limit (hours)', PERSONAL: '15', PROFESSIONAL: 'Unlimited', TEAM: 'Unlimited', TEAM_PLUS: 'Unlimited', ENTERPRISE: 'Unlimited' },
    { feature: 'Upload audio & video (hours)', PERSONAL: '1', PROFESSIONAL: '15', TEAM: '15 per user', TEAM_PLUS: '15 per user', ENTERPRISE: '15 per user' },
    { feature: '- first month limit (hours)', PERSONAL: '3', PROFESSIONAL: '15', TEAM: '15 per user', TEAM_PLUS: '15 per user', ENTERPRISE: '15 per user' },
    { feature: 'Meeting history (data storage is limited to months)', PERSONAL: '4', PROFESSIONAL: 'Unlimited', TEAM: 'Unlimited', TEAM_PLUS: 'Unlimited', ENTERPRISE: 'Unlimited' },
  ],
  'AI Insights': [
    { feature: 'Multi-language meeting transcription', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Custom vocabulary', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Meeting Notes with AI summary (supports 48 languages)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Meeting notes by meeting types (AI-based identification)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'AI-powered task identification and management', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'AI Meeting Chat', PERSONAL: '10 requests per month', PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Key Items list (Risks, Issues, Events, Notable items and more)', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Sentiment Analysis (highlights)', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
  ],
  'Automations': [
    { feature: 'Integrations for meeting notes, transcription and tasks', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Collaboration automations: Microsoft Teams & Slack', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Project management: Airtable, Asana, Jira, Monday, Notion, Trello', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Personal productivity: Google Tasks, Microsoft Todo, Todoist', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Document management: Dropbox, Google Drive, Microsoft SharePoint', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'CRM automations: HubSpot, Pipedrive, Salesforce, Zoho CRM, etc.', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Analytics automations: Google Sheets', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Flexible, no-code routing and filtering integration options', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Zapier templates for notes, transcription and tasks', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Custom webhook-based integrations', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Workspace-wide integrations', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Workspace-wide integrations number', PERSONAL: false, PROFESSIONAL: false, TEAM: '10', TEAM_PLUS: '10', ENTERPRISE: '50' },
  ],
  'Manage': [
    { feature: 'In-meeting and cross-meeting search', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Share and guest view', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Personal notes and bookmarks', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Export to PDF (Meeting Notes and Transcripts)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Pre-meeting language selector', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Data retention options', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Agent customization (Microsoft Teams & Zoom)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Agent customization (Google Meet)', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Workspace Team management', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Workspace Analytics Dashboard', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Reporting Hub', PERSONAL: false, PROFESSIONAL: false, TEAM: false, TEAM_PLUS: false, ENTERPRISE: true },
    { feature: 'Custom SSO OAuth configuration', PERSONAL: false, PROFESSIONAL: false, TEAM: false, TEAM_PLUS: false, ENTERPRISE: true },
    { feature: 'Manager meeting auto-share', PERSONAL: false, PROFESSIONAL: false, TEAM: false, TEAM_PLUS: false, ENTERPRISE: true },
  ],
  'In-meeting Manage': [
    { feature: 'Immediate Invite (by link or email)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Google Chrome extension', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Proxy meeting attendance', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Meeting recording control (stop, pause or verify record)', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Voice identification', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Customizable chat message to attendees', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Recording consent email notification', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Post-meeting email notification to attendees', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Manual Voice Commands', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
  ],
  'Support': [
    { feature: 'Onboarding and adoption resources', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Trust Center for security review', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Live chat and email support', PERSONAL: true, PROFESSIONAL: true, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Live demos and webinars', PERSONAL: false, PROFESSIONAL: false, TEAM: true, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Priority Support', PERSONAL: false, PROFESSIONAL: false, TEAM: false, TEAM_PLUS: true, ENTERPRISE: true },
    { feature: 'Dedicated success manager', PERSONAL: false, PROFESSIONAL: false, TEAM: false, TEAM_PLUS: false, ENTERPRISE: true },
  ],
} as const;

export default PRICE_LINES;
