import { useState, useEffect } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import DeleteIcon from '@material-ui/icons/DeleteOutlineSharp';
// Lib Shared
import {
  GenericParticipant,
  GenericAccessItem,
  DiarizationItem,
  DiarizationItemSplitType,
} from '../types';
import { GenericDialog, MeetingSpeakersMenu } from '../components';
import { getNameInitials } from '../utils';

/* #region  Types */
interface SplittedItem {
  item: { participant: number | null; text: string } | null;
  participant: GenericParticipant | null;
}

interface MenuState {
  targetItemIndex: number;
  anchorEl: HTMLElement;
}

interface SplitTranscriptionItemDialogProps {
  transcriptionItem: DiarizationItem;
  meetingAccessItems: GenericAccessItem[];
  meetingParticipants: GenericParticipant[];
  onAddSpeaker: (
    speakerName: string,
    accessItemUserId: string | null,
  ) => Promise<GenericParticipant | null>;
  onClose: () => void;
  onSubmit: (data: DiarizationItemSplitType[]) => void;
}
/* #endregion */

export const SplitTranscriptionItemDialog: React.VFC<SplitTranscriptionItemDialogProps> = ({
  transcriptionItem,
  meetingAccessItems,
  meetingParticipants,
  onAddSpeaker,
  onClose,
  onSubmit,
}) => {
  const styles = useStyles();

  const [splittedItems, setSplittedItems] = useState<SplittedItem[]>([]);
  const [speakerMenuData, setSpeakerMenuData] = useState<null | MenuState>(null);
  const [invalidItemIndex, setInvalidItemIndex] = useState<null | number>(null);

  /* #region  Handlers */
  const handleCancel = () => {
    onClose();
  };

  const handleAddSplittedItem = () => {
    setSplittedItems((currentItems) => [
      ...currentItems,
      {
        participant: transcriptionItem.participant,
        item: { text: '', participant: +transcriptionItem.participant.id },
      },
    ]);
  };

  const handleOpenSpeakersMenu =
    (index: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      setSpeakerMenuData({ anchorEl: event.currentTarget, targetItemIndex: index });
    };

  const handleCloseSpeakersMenu = () => {
    setSpeakerMenuData(null);
  };

  const handleReplaceSpeaker = (targetIndex: number) => (participant: GenericParticipant) => {
    const updatedItems = splittedItems.map((entity, index) =>
      targetIndex === index
        ? { participant, item: { participant: +participant.id, text: entity?.item?.text || '' } }
        : entity,
    );
    setSplittedItems(updatedItems);
    handleCloseSpeakersMenu();
  };

  const handleDeleteSplittedItem = (targetIndex: number) => () => {
    setSplittedItems(splittedItems.filter((_, index) => targetIndex !== index));
  };

  const handleChangeTranscriptionText =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const updated = splittedItems.map((entity, itemIndex) =>
        itemIndex === index
          ? {
              ...entity,
              item: { participant: entity.item?.participant || null, text: e.target.value },
            }
          : entity,
      );

      setSplittedItems(updated);
    };

  const handleSubmit = async () => {
    const invalidItemIndex = splittedItems.findIndex(
      ({ item }) => !item?.participant || !item?.text,
    );

    if (invalidItemIndex !== -1) {
      setInvalidItemIndex(invalidItemIndex);
    } else {
      onSubmit(
        splittedItems.map(({ item }) => ({
          participant: item?.participant!,
          text: item?.text!,
        })),
      );
      onClose();
    }
  };
  /* #endregion */

  useEffect(() => {
    const defaultSplittedItems: SplittedItem[] = [
      {
        participant: transcriptionItem.participant,
        item: {
          text: transcriptionItem.word,
          participant: +transcriptionItem.participant.id,
        },
      },
      {
        participant: transcriptionItem.participant,
        item: {
          text: '',
          participant: +transcriptionItem.participant.id,
        },
      },
    ];

    setSplittedItems(defaultSplittedItems);
  }, [transcriptionItem]);

  return (
    <GenericDialog
      title="Split"
      dialogProps={{
        fullWidth: true,
        scroll: 'paper',
        maxWidth: 'sm',
        classes: { paper: styles.paper },
      }}
      onClose={handleCancel}
    >
      <DialogContent className={styles.content}>
        <Box display="flex" flexDirection="column">
          {splittedItems.map(({ item, participant }, index) => {
            const participantName = participant?.user?.fullName || participant?.name;
            const participantAvatar = participant?.user?.avatar || undefined;
            return (
              <Box display="flex" key={`${index}-${participant?.id}`} mb={3}>
                <Avatar className={styles.avatar} alt={participantName} src={participantAvatar}>
                  {participantName ? getNameInitials(participantName) : null}
                </Avatar>
                <Box display="flex" flexDirection="column" flex="1">
                  <Box mb={1} display="flex" alignItems="center" justifyContent="space-between">
                    <Tooltip title="Change speaker">
                      <Link className={styles.name} onClick={handleOpenSpeakersMenu(index)}>
                        {participantName || 'Add speaker'}
                      </Link>
                    </Tooltip>
                    {index > 1 && (
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={handleDeleteSplittedItem(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      multiline
                      minRows={2}
                      variant="outlined"
                      placeholder="Please, enter a description"
                      defaultValue={item?.text}
                      classes={{ root: styles.textareaRoot }}
                      onChange={handleChangeTranscriptionText(index)}
                    />
                    {index === invalidItemIndex && (
                      <FormHelperText error>
                        Please select a participant and add a description
                      </FormHelperText>
                    )}
                    {splittedItems.length === index + 1 && (
                      <Box mt={2}>
                        <Link onClick={handleAddSplittedItem}>Add new part</Link>
                      </Box>
                    )}
                  </FormControl>
                </Box>
              </Box>
            );
          })}
        </Box>
      </DialogContent>

      <Box display="flex" justifyContent="flex-end" mt={2} px={4} pb={4}>
        <Button variant="outlined" onClick={handleCancel}>
          <Typography variant="body1">Cancel</Typography>
        </Button>
        <Box mr={1} />
        <Button disableElevation color="primary" variant="contained" onClick={handleSubmit}>
          <Typography variant="body1">Submit</Typography>
        </Button>
      </Box>

      {/* Begin: Menu */}
      {!!speakerMenuData && (
        <MeetingSpeakersMenu
          disableReplaceAll
          title="Select speaker"
          anchorEl={speakerMenuData.anchorEl}
          meetingParticipants={meetingParticipants}
          accessItems={meetingAccessItems}
          speakerId={transcriptionItem.participant.id}
          speakerName={transcriptionItem.participant.name}
          onClose={handleCloseSpeakersMenu}
          onReplaceSpeaker={handleReplaceSpeaker(speakerMenuData.targetItemIndex)}
          onAddSpeaker={onAddSpeaker}
        />
      )}
      {/* End: Menu */}
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    maxHeight: '70%',
    background: theme.palette.background.default,
  },
  textareaRoot: { '& > div': { borderRadius: '6px' } },
  name: {
    display: 'inline-flex',
    width: 'fit-content',
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline' },
  },
  avatar: {
    background: theme.palette.grey[100],
    border: 'none',
    height: 36,
    width: 36,
    marginRight: theme.spacing(2),
    '&.small': {
      fontSize: '0.75rem',
      height: 24,
      width: 24,
    },
  },
}));

export default SplitTranscriptionItemDialog;
