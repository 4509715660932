import { useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { comparePlans } from '@sembly-ui';
// App Shared
import { Routes } from '@shared/enums';
import { MeetSemblianDialog } from '@shared/dialogs';
import { useUserContext } from '@shared/hooks';
import { ReactComponent as NewBadgeIcon } from '@shared/assets/art-new-badge.svg';
import { PlanIDEnum } from '@gql-types';
// Module Shared
import { ReactComponent as SemblianIcon } from '../assets/art-semblian.svg';

export interface SemblianBannerProps {
  className?: string;
  fontSize?: 'normal' | 'small';
}

export const SemblianBanner: React.VFC<SemblianBannerProps> = ({
  className = '',
  fontSize = 'normal',
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  const history = useHistory();

  const [openLearnMoreDialog, setOpenLearnMoreDialog] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnCallToAction = () => {
    const userPlanId = user.data?.me?.paymentCustomer?.plan?.id ?? PlanIDEnum.PERSONAL;
    const isPaidPlan = comparePlans(userPlanId, 'equalsOrHigher', PlanIDEnum.PROFESSIONAL);
    if (isPaidPlan) {
      history.push(Routes.OnboardingSemblianWorkspace);
    } else {
      // User should be on the professional plan or higher to be able to activate the Semblain 2.0
      // Redirect to the checkout page with the Professional plan
      history.push(generatePath(Routes.CheckoutStart, { planId: PlanIDEnum.PROFESSIONAL }));
    }
  };

  const handleClickOnLearnMore = () => {
    setOpenLearnMoreDialog(true);
  };

  const handleClickOnCloseLearnMoreDialog = () => {
    setOpenLearnMoreDialog(false);
  };
  /* #endregion */

  const isSmallFont = isSmallScreen || fontSize === 'small';

  return (
    <>
      <Paper square elevation={isSmallFont ? 1 : 2} className={`${styles.banner} ${className}`}>
        <Box
          display="flex"
          flex={1}
          alignItems={isSmallScreen ? 'flex-start' : 'center'}
          gridGap={16}
        >
          <SemblianIcon width={isSmallScreen ? 38 : 76} height={isSmallScreen ? 38 : 76} />
          <Box flex={1}>
            <Box display="flex" alignItems="center" mb={1} gridGap={8}>
              <Typography
                variant={isSmallFont ? 'subtitle2' : 'h6'}
                style={{ lineHeight: 'normal' }}
              >
                Get Semblain 2.0
              </Typography>
              <NewBadgeIcon />
            </Box>

            <Typography variant={isSmallFont ? 'body2' : 'body1'} color="textSecondary">
              Work smarter with AI Artifacts, AI Insights, Multi-Meeting Chats and more.
              {!isSmallFont && <br />}
              Starting at $25 per user/month (annual plan).{' '}
              <Link onClick={handleClickOnLearnMore}>Learn more</Link>
            </Typography>
          </Box>
        </Box>
        <Box
          ml={isSmallScreen ? 13 : 0}
          textAlign={isSmallScreen ? 'left' : 'center'}
          width={isSmallScreen ? '100%' : 'auto'}
        >
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleClickOnCallToAction}
          >
            <Typography noWrap component="span" variant={isSmallFont ? 'body2' : 'body1'}>
              Try it free
            </Typography>
          </Button>
        </Box>
      </Paper>
      {openLearnMoreDialog && <MeetSemblianDialog onClose={handleClickOnCloseLearnMoreDialog} />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  banner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(3),
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
}));

export default SemblianBanner;
