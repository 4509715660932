// Sembly UI
import { GenericDialog } from '@sembly-ui';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

export interface MicIsBlockedProps {
  open: boolean;
  onClose: () => void;
}

export const MicIsBlocked: React.VFC<MicIsBlockedProps> = ({ open, onClose }) => {
  return (
    <GenericDialog
      dialogProps={{
        open: open,
        fullWidth: true,
        maxWidth: 'sm',
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box mt={6} mb={4} textAlign="center">
          <Typography paragraph variant="h5" color="primary">
            Your microphone is blocked
          </Typography>
          <Typography variant="body1">
            Sembly requires access to your microphone. Please allow microphone access in your
            browser's permission settings.
          </Typography>
        </Box>
        <Box mb={6} textAlign="center">
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Close"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

export default MicIsBlocked;
