import { useQuery, useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
// Sembly UI
import { GenericDialog, LanguageSelects, Languages } from '@sembly-ui';
// App Shared
import { graphErrorHorsemen } from '@shared/utils';
import { useUserContext } from '@shared/hooks';
// GraphQL Queries and Types
import meetingLanguageSettingsQuery from '@shared/queries/MeetingLanguageSettings.graphql';
import editMeetingLanguageMutation from '@shared/queries/EditMeetingLanguageSettings.graphql';
import { MeetingLanguageSettings as MeetingLanguageSettingsQuery } from '@gql-types/MeetingLanguageSettings';
import {
  EditMeetingLanguageSettings,
  EditMeetingLanguageSettingsVariables,
} from '@gql-types/EditMeetingLanguageSettings';

export interface MeetingLanguageSettingsProps {
  meetingId: string;
  onClose: () => void;
}

/**
 * @name MeetingLanguageSettings
 * @description A dialog that allows users to change the language settings for a meeting
 * @param {MeetingLanguageSettingsProps} props
 * @returns {JSX.Element}
 */
export const MeetingLanguageSettings: React.VFC<MeetingLanguageSettingsProps> = ({
  meetingId,
  onClose,
}) => {
  /* #region  Hooks */
  const user = useUserContext();

  const { data, loading } = useQuery<MeetingLanguageSettingsQuery>(meetingLanguageSettingsQuery, {
    variables: { meetingId },
  });

  const [editMeetingLanguage] = useMutation<
    EditMeetingLanguageSettings,
    EditMeetingLanguageSettingsVariables
  >(editMeetingLanguageMutation);
  /* #endregion */

  const handleChangeLanguage = async (
    primaryLanguage: Languages,
    additionalLanguage: Languages | null,
  ) => {
    const nextAdditionalLanguage = !!additionalLanguage ? additionalLanguage : null;
    const result = await editMeetingLanguage({
      variables: {
        meetingId,
        languages: {
          primaryLanguage,
          additionalLanguage: nextAdditionalLanguage,
        },
      },
      optimisticResponse: {
        manageMeetingLanguages: {
          __typename: 'ManageMeetingLanguagesMutationPayload',
          meeting: {
            __typename: 'DetailedMeetingType',
            id: meetingId,
            languageSettings: {
              __typename: 'MeetingLanguageSettingsType',
              id: data?.meeting?.languageSettings?.id || meetingId,
              primaryLanguage,
              additionalLanguage,
            },
          },
          success: true,
          errors: [],
        },
      },
    });

    if (!result.data?.manageMeetingLanguages?.success) {
      graphErrorHorsemen(result.data?.manageMeetingLanguages?.errors);
    }
  };

  /* #region  Render Helpers */
  const canManage = data?.meeting?.permissions?.canManage ?? false;
  const languageSettings = data?.meeting?.languageSettings;
  const workspaceLanguageSettings = user.data?.me?.workspace?.languageSettings;
  const hasMeetingLanguageSettings = !!languageSettings;
  const meetingPrimaryLanguage = languageSettings?.primaryLanguage || Languages.ENGLISH;
  const meetingAdditionalLanguage = languageSettings?.additionalLanguage || null;
  const workspacePrimaryLanguage = workspaceLanguageSettings?.primaryLanguage || Languages.ENGLISH;
  const workspaceAdditionalLanguage = workspaceLanguageSettings?.additionalLanguage || null;
  const primaryLanguage = hasMeetingLanguageSettings
    ? meetingPrimaryLanguage
    : workspacePrimaryLanguage;
  const additionalLanguage = hasMeetingLanguageSettings
    ? meetingAdditionalLanguage
    : workspaceAdditionalLanguage;
  /* #endregion */

  return (
    <GenericDialog
      title="Meeting Language"
      dialogProps={{ open: true, fullWidth: true, maxWidth: 'sm' }}
      onClose={onClose}
    >
      <Box component={DialogContent} mt={2} mb={2}>
        <Box display="flex" flexDirection="column" mb={2}>
          {loading ? (
            <Box display="flex" my={6} justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Tooltip title={canManage ? '' : 'You do not have permission to edit this meeting'}>
              <div>
                <LanguageSelects
                  disabled={!canManage}
                  primaryLanguage={primaryLanguage}
                  additionalLanguage={additionalLanguage}
                  onChange={handleChangeLanguage}
                />
              </div>
            </Tooltip>
          )}
        </Box>
        <Box display="flex" justifyContent="right">
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Close meeting language settings dialog"
            onClick={onClose}
          >
            Done
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default MeetingLanguageSettings;
