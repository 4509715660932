import Box, { BoxProps } from '@material-ui/core/Box';
import Chip, { ChipProps } from '@material-ui/core/Chip';
// Lib Shared
import { MeetingStatuses, MeetingProcessingResults } from '../types';
import { CustomMeetingStatuses } from '../enums';
import { MEETING_STATUSES } from '../constants';

export type MeetingStatusChipProps = BoxProps & {
  meetingStatus: MeetingStatuses | CustomMeetingStatuses | null;
  processingResult: MeetingProcessingResults | null;
  chipProps?: ChipProps;
};

/**
 * @name MeetingStatusChip
 * @description Renders a chip with the meeting status
 * @param {MeetingStatusChipProps} props
 * @returns JSX.Element | null
 */
export const MeetingStatusChip: React.VFC<MeetingStatusChipProps> = ({
  meetingStatus,
  processingResult,
  chipProps,
  ...props
}) => {
  const status = meetingStatus ? MEETING_STATUSES[meetingStatus] : null;
  let customStatusTitle: null | string = null;

  if (meetingStatus === MeetingStatuses.processing && status && processingResult) {
    let helperText = 'Transcription';

    if (processingResult.processedTranscribing) helperText = 'Meeting Notes';
    if (processingResult.processedMeetingNotes) helperText = 'Key Items';

    customStatusTitle = `${status.title} ${helperText}`;
  }

  return status ? (
    <Box display="flex" alignItems="center" {...props}>
      <Chip
        size="small"
        label={customStatusTitle || status.title}
        style={{
          color: status.palette.color,
          backgroundColor: status.palette.backgroudColor.main,
        }}
        {...chipProps}
      />
    </Box>
  ) : null;
};

export default MeetingStatusChip;
