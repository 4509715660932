import { SvgIcon } from '@material-ui/core';

export const Play = () => (
  <SvgIcon width="20" height="21" viewBox="0 0 20 21">
    <path
      d="M9.99984 2.16675C5.39984 2.16675 1.6665 5.90008 1.6665 10.5001C1.6665 15.1001 5.39984 18.8334 9.99984 18.8334C14.5998 18.8334 18.3332 15.1001 18.3332 10.5001C18.3332 5.90008 14.5998 2.16675 9.99984 2.16675ZM9.99984 17.1667C6.32484 17.1667 3.33317 14.1751 3.33317 10.5001C3.33317 6.82508 6.32484 3.83341 9.99984 3.83341C13.6748 3.83341 16.6665 6.82508 16.6665 10.5001C16.6665 14.1751 13.6748 17.1667 9.99984 17.1667ZM7.9165 14.2501L13.7498 10.5001L7.9165 6.75008V14.2501Z"
      fill="black"
    />
  </SvgIcon>
);

export default Play;
