import {
  ActionsCustomIntegrationSettingsContentTypes,
  CustomOutboundUserIntegrationRules,
} from '../types';

export const RULE_STATUSES: Record<keyof typeof CustomOutboundUserIntegrationRules, string> = {
  MANUAL_ONLY: 'Disabled (Manual only)',
  ALL_MEETINGS: 'Apply to all meetings that I have access to',
  FILTERED_BY_KEYWORDS: 'Apply to meetings with keywords in the title',
  FILTERED_BY_MEETING_TYPE: 'Apply to meetings of a specific type',
};

export const KEY_ITEMS_LIST: Record<
  keyof typeof ActionsCustomIntegrationSettingsContentTypes,
  string
> = {
  ACTIONS: 'All Tasks',
  COMMITMENTS: 'Only Tasks assigned to me',
};
