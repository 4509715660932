import { ToastContentProps } from 'react-toastify';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export interface ToastWithUndoProps extends Partial<ToastContentProps> {
  message: string;
  onUndo: () => void;
}

export const ToastWithUndo: React.VFC<ToastWithUndoProps> = ({
  message,
  onUndo,
  closeToast = () => null,
}) => {
  const styles = useStyles();

  const handleUndo = () => {
    onUndo();
    closeToast();
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" className={styles.text}>
        {message}
      </Typography>
      <Box ml={1}>
        <Button variant="text" color="primary" aria-label="Undo action" onClick={handleUndo}>
          Undo
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  text: {
    display: 'inline-block',
    flex: 1,
  },
}));

export default ToastWithUndo;
