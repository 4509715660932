import pluralize from 'pluralize';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import processingImage from '../assets/icon-processing-64-anim.gif';
import { RedirectTarget } from '../types';

export interface MeetingPlaceholderProcessingProps {
  isProcessedAnalytics: boolean;
  isProcessedAssignments: boolean;
  isProcessedNotes: boolean;
  isPromoteUpgrade: boolean;
  isRestrictedKeyItems: boolean;
  isTranscribed: boolean;
  meetingDuration: number;
  meetingEnd: string | null;
  onChangeRoute: (route: RedirectTarget) => void;
  onClickOnUpgradePlan?: () => void;
}

/**
 * The component that renders a placeholder when the meeting is being processed
 */
export const MeetingPlaceholderProcessing: React.VFC<MeetingPlaceholderProcessingProps> = ({
  isProcessedAnalytics,
  isProcessedAssignments,
  isProcessedNotes,
  isPromoteUpgrade,
  isRestrictedKeyItems,
  isTranscribed,
  meetingDuration,
  meetingEnd,
  onChangeRoute,
  onClickOnUpgradePlan = () => null,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  /* #region  Handlers */
  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleRequestRedirect = (route: RedirectTarget) => () => {
    onChangeRoute(route);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const currentTimeSec = new Date().getTime() / 1000;
  const durationFree = meetingDuration * 0.15;
  const durationPayed = meetingDuration * 0.25 < 5 * 60 ? 5 * 60 : meetingDuration * 0.25; // ETA duration should be at least 5 min
  const durationComputed = isPromoteUpgrade ? durationFree : durationPayed;
  const meetingEndSec = meetingEnd ? new Date(meetingEnd).getTime() / 1000 : 0;
  const estimateSec = meetingEndSec + durationComputed - currentTimeSec;
  const estimationTime = Math.floor(estimateSec / 60);
  /* #endregion */

  if (isPromoteUpgrade && !onClickOnUpgradePlan) {
    throw new Error('If promotion upgrade is true, handler is required');
  }

  return (
    <div className={styles.root}>
      <Box mt={isSmallScreen ? 3 : 5} mb={isSmallScreen ? 3 : 4}>
        <img
          alt="Processing Meeting..."
          width={isSmallScreen ? 42 : 64}
          height={isSmallScreen ? 42 : 64}
          src={processingImage}
        />
      </Box>
      <Box mb={1} ml={2}>
        <Typography variant={isSmallScreen ? 'h6' : 'h4'} align="center">
          We are working on your meeting...
        </Typography>
      </Box>

      {estimationTime > 0 ? (
        <Typography
          align="center"
          color="textSecondary"
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
        >
          Hang tight, your results will be available within {estimationTime}{' '}
          {pluralize('minute', estimationTime)}.
        </Typography>
      ) : (
        <Typography
          align="center"
          color="textSecondary"
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
        >
          Hang tight, your results will be available momentarily.
        </Typography>
      )}

      {isPromoteUpgrade && (
        <Box mt={3}>
          <Typography className={styles.warningBox}>
            Speed up your results by upgrading to Sembly Professional or Sembly Team.{' '}
            {!isSmallScreen && (
              <Link color="inherit" underline="always" onClick={onClickOnUpgradePlan}>
                Upgrade
              </Link>
            )}
          </Typography>
        </Box>
      )}

      {(isProcessedNotes || isProcessedAnalytics || isTranscribed) && (
        <Box mt={4}>
          <Typography paragraph align="center" variant="body1">
            Meanwhile, you can take a look at the following results:
          </Typography>

          <Box display="flex" flexDirection="column" alignItems="center" gridGap={12}>
            {isProcessedNotes && (
              <Button
                disableElevation
                size="small"
                color="primary"
                variant="outlined"
                className={styles.btn}
                onClick={handleRequestRedirect('MeetingNotes')}
              >
                Meeting Notes
              </Button>
            )}
            {isProcessedAnalytics && !isRestrictedKeyItems && (
              <Button
                disableElevation
                size="small"
                color="primary"
                variant="outlined"
                className={styles.btn}
                aria-label='Navigate to "Key Items"'
                onClick={handleRequestRedirect('MeetingKeyItems')}
              >
                Key Items
              </Button>
            )}
            {isProcessedAssignments && (
              <Button
                disableElevation
                size="small"
                color="primary"
                variant="outlined"
                className={styles.btn}
                aria-label='Navigate to "Tasks"'
                onClick={handleRequestRedirect('MeetingAssignments')}
              >
                Tasks
              </Button>
            )}
            {isTranscribed && (
              <Button
                disableElevation
                size="small"
                color="primary"
                variant="outlined"
                className={styles.btn}
                aria-label='Navigate to "Transcription"'
                onClick={handleRequestRedirect('MeetingTranscript')}
              >
                Transcription
              </Button>
            )}
          </Box>
        </Box>
      )}

      <Box mt={2} display="flex" flexDirection="column" alignItems="center" gridGap={12}>
        <Button
          disableElevation
          size="small"
          color="primary"
          variant="contained"
          className={styles.btn}
          onClick={handleReloadPage}
        >
          Refresh
        </Button>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(8),
    flexDirection: 'column',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  warningBox: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#D9F0FE',
    color: '#2267B8',
    padding: theme.spacing(1, 1.5),
    textAlign: 'center',
  },
  btn: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    minWidth: 200,
  },
}));

export default MeetingPlaceholderProcessing;
