import { ValidationErrors } from '@gql-types/ValidationErrors';
import { ExecutionErrors } from '@gql-types/ExecutionErrors';

type Errors = (ValidationErrors | ExecutionErrors | null)[];

export const extractValidationErrors = (data: Errors) => {
  return data.filter(
    (error) => error && error.__typename === 'ValidationErrorType',
  ) as ValidationErrors[];
};
