import { Fragment } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
// Lib Shared
import { AGENT_CALL_PLATFORMS } from '../constants';
import { GenericMeetingOverview } from '../types';
import { MeetingStatusChip, MeetingStatusInfo } from '../components';
import { getNameInitials } from '../utils';

export interface SearchResultsProps {
  data: GenericMeetingOverview[] | null | undefined;
  hasNextPage?: boolean;
  className?: string;
  onClickOnItem: (id: string) => void;
  onClickOnNextPage: () => void;
}

export const SearchResults: React.VFC<SearchResultsProps> = ({
  data,
  hasNextPage = false,
  className = '',
  onClickOnItem,
  onClickOnNextPage,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickOnListItem = (id: string) => (_: any) => {
    onClickOnItem(id);
  };

  const handleClickOnNextPage = (event: any) => {
    event.stopPropagation();
    onClickOnNextPage && onClickOnNextPage();
  };

  if (!data) return null;

  return (
    <List component="nav" className={className}>
      {data.map((entity, index) => {
        if (!entity) return null;
        const { id, title, agentCall, accessItems, searchCounter } = entity;
        const detectedCallPlatform = AGENT_CALL_PLATFORMS[agentCall?.platform || 'COMMON'];
        const isKnownPlatform = !!detectedCallPlatform;
        const platform = isKnownPlatform ? detectedCallPlatform : AGENT_CALL_PLATFORMS['COMMON'];

        return (
          <Fragment key={`search-result-${id}`}>
            {!!index && <Divider variant={isSmallScreen ? 'fullWidth' : 'middle'} />}
            <ListItem className={styles.item} button onClick={handleClickOnListItem(id)}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <ListItemAvatar>
                    <Avatar className={styles.icon} title={platform.title}>
                      {platform.icon}
                    </Avatar>
                  </ListItemAvatar>
                </Grid>

                <Grid item sm>
                  <div className={styles.title}>
                    <Typography component="span" variant="h6">
                      {title}
                    </Typography>
                    <MeetingStatusChip
                      ml={2}
                      meetingStatus={entity.status}
                      processingResult={entity.processingResults}
                    />
                  </div>
                  <MeetingStatusInfo
                    showStartDate
                    startDate={entity.startedAt}
                    finishDate={entity.finishedAt}
                    isRecurrentMeeting={entity.isRecurrent || false}
                    ownerFullName={entity.owner.fullName}
                  />
                </Grid>
                <Grid item>
                  <div className={styles.action}>
                    <AvatarGroup
                      classes={{ root: styles.avatarGroup, avatar: styles.avatar }}
                      max={3}
                    >
                      {accessItems.map(({ id, email, user }) => {
                        const userName = user?.fullName || email || 'Guest';
                        return (
                          <Avatar
                            key={id}
                            title={userName}
                            alt={userName}
                            src={user?.avatar || undefined}
                          >
                            {user?.avatar ? null : getNameInitials(userName)}
                          </Avatar>
                        );
                      })}
                    </AvatarGroup>
                    {!!searchCounter && (
                      <div className={styles.chip}>
                        <SearchOutlinedIcon
                          fontSize="small"
                          color="inherit"
                          className={styles.highlight}
                        />
                        <Typography variant="body2" component="span" className={styles.label}>
                          {`Instances: ${searchCounter}`}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          </Fragment>
        );
      })}
      {hasNextPage && (
        <>
          <Divider variant={isSmallScreen ? 'fullWidth' : 'middle'} />
          <ListItem button onClick={handleClickOnNextPage}>
            <Typography variant="body1">More Results</Typography>
          </ListItem>
        </>
      )}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  action: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
    },
  },
  avatarGroup: {
    justifyContent: 'flex-end',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '0.875rem',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  item: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
    },
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '2rem',
    backgroundColor: alpha(theme.palette.highlight.main, 0.25),
    padding: theme.spacing(1 / 4, 1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1 / 2, 1),
    },
  },
  icon: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.background.default,
    marginRight: theme.spacing(2),
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.highlight.dark,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default SearchResults;
