import { Breadcrumb, BreadcrumbHint } from '@sentry/types';

/**
 * Format a breadcrumb message from a UI input event.
 */
export function breadcrumbUIInput(
  breadcrumb: Breadcrumb,
  hint: BreadcrumbHint | undefined,
): Breadcrumb {
  if (!hint) return breadcrumb;

  const target = hint?.event?.target as HTMLElement;

  if (!target) return breadcrumb;

  const message = ['input'];

  if (target.ariaLabel) message.push(target.ariaLabel);

  const name = target.getAttribute('name')
  if (!!name) {
    message.push(`[name=${name}]`);
  } else {
    const formControlName = target.getAttribute('formcontrolname');
    if (!!formControlName) message.push(`[formcontrolname=${formControlName}]`);
  }

  const type = target.getAttribute('type');
  if (!!type) message.push(`[type=${type}]`);

  const placeholder = target.getAttribute('placeholder');
  if (!!placeholder) message.push(`[placeholder=${placeholder}]`);

  breadcrumb.message = message.join(' ');

  return breadcrumb;
}

export default breadcrumbUIInput;
