// Material UI
import Grow from '@material-ui/core/Grow';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import FileCopyIcon from '@material-ui/icons/FileCopy';

export interface AskSemblyMenuDesktopProps {
  anchorEl: HTMLElement | null;
  onClickOnCopyContent: () => void;
  onClose: () => void;
  onMouseEnter: () => void;
}

export const AskSemblyMenuDesktop: React.VFC<AskSemblyMenuDesktopProps> = ({
  anchorEl,
  onClickOnCopyContent,
  onClose,
  onMouseEnter,
}) => {
  const styles = useStyles();

  const handleClickOnCopyContent = () => {
    onClickOnCopyContent();
    onClose();
  };

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      container={anchorEl}
      placement={'top-end'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onClose}
      className={styles.root}
    >
      <Grow in={!!anchorEl} timeout={!!anchorEl ? 20 : 0}>
        <MenuList className={styles.list}>
          <MenuItem dense className={styles.item} onClick={handleClickOnCopyContent}>
            <Tooltip title="Copy content" placement="top">
              <ListItemIcon className={styles.icon}>
                <FileCopyIcon fontSize="small" />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </MenuList>
      </Grow>
    </Popper>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(8),
      right: '0 !important',
      top: '-22px !important',
      left: 'auto !important',
      transform: 'translate(0, 0) !important',
    },
    list: {
      position: 'relative',
      zIndex: 2,
      top: theme.spacing(1),
      right: theme.spacing(1),
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
    item: {
      padding: theme.spacing(0.5, 1),
    },
    icon: {
      minWidth: 0,
    },
  };
});

export default AskSemblyMenuDesktop;
