import { SvgIcon } from '@material-ui/core';

export const MinutesGlance = () => (
  <SvgIcon viewBox="0 0 64 64">
    <rect x="20" y="3" width="44" height="2" rx="1" />
    <rect x="20" y="7" width="36" height="2" rx="1" />
    <rect x="20" y="11" width="28" height="2" rx="1" />
    <path
      fill="#00b3b3"
      d="M10.45,15,7.11,12.74a2,2,0,0,0-2.22,0L1.55,15A1,1,0,0,1,0,14.13V2A2,2,0,0,1,2,0h8a2,2,0,0,1,2,2V14.13A1,1,0,0,1,10.45,15Z"
    />
    <rect x="20" y="51" width="44" height="2" rx="1" />
    <rect x="20" y="55" width="36" height="2" rx="1" />
    <rect x="20" y="59" width="28" height="2" rx="1" />
    <path
      fill="#d84caa"
      d="M10.45,63,7.11,60.74a2,2,0,0,0-2.22,0L1.55,63A1,1,0,0,1,0,62.13V50a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2V62.13A1,1,0,0,1,10.45,63Z"
    />
    <rect x="20" y="27" width="44" height="2" rx="1" />
    <rect x="20" y="31" width="36" height="2" rx="1" />
    <rect x="20" y="35" width="28" height="2" rx="1" />
    <path
      fill="#ffbf00"
      d="M10.45,39,7.11,36.74a2,2,0,0,0-2.22,0L1.55,39A1,1,0,0,1,0,38.13V26a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2V38.13A1,1,0,0,1,10.45,39Z"
    />
  </SvgIcon>
);

export default MinutesGlance;
