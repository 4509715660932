import clsx from 'clsx';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { alpha, makeStyles } from '@material-ui/core/styles';
// Material Icons
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PauseIcon from '@material-ui/icons/Pause';
import RecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';

export interface RecordingControlProps {
  disabled?: boolean;
  displaySpeakingIndicator?: boolean;
  hideDeleteButton?: boolean;
  hidePauseButton?: boolean;
  initialization?: boolean;
  paused?: boolean;
  speaking?: boolean;
  onClickOnDelete?: () => void;
  onClickOnPause?: () => void;
  onClickOnResume?: () => void;
  onClickOnStop?: () => void;
}

export const RecordingControl: React.VFC<RecordingControlProps> = ({
  disabled = false,
  displaySpeakingIndicator = false,
  hideDeleteButton = false,
  hidePauseButton = false,
  initialization = false,
  paused = false,
  speaking = false,
  onClickOnDelete = () => null,
  onClickOnPause = () => null,
  onClickOnResume = () => null,
  onClickOnStop = () => null,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {!hidePauseButton && (
        <>
          {paused ? (
            <IconButton
              disabled={disabled}
              className={styles.button}
              data-testid="resume-recording-button"
              onClick={onClickOnResume}
            >
              <RecordIcon color="error" />
            </IconButton>
          ) : (
            <IconButton
              disabled={disabled}
              className={styles.button}
              data-testid="pause-recording-button"
              onClick={onClickOnPause}
            >
              <PauseIcon />
            </IconButton>
          )}
        </>
      )}

      <IconButton
        disabled={disabled || initialization}
        className={clsx(styles.button, styles.accent)}
        data-testid="stop-recording-button"
        onClick={onClickOnStop}
      >
        {initialization ? <CircularProgress size={24} /> : <StopIcon />}
      </IconButton>

      {!hideDeleteButton && (
        <IconButton
          disabled={disabled}
          className={styles.button}
          data-testid="delete-recording-button"
          onClick={onClickOnDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}

      {displaySpeakingIndicator && !paused && (
        <>
          <Box ml={1} />
          <span
            className={clsx(styles.chip, speaking && 'animated')}
            data-testid="speaking-indicator"
          >
            Live
          </span>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  chip: {
    borderRadius: '2rem',
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.light, 0.25),
    padding: theme.spacing(1 / 2, 2),
    marginLeft: theme.spacing(1 / 2),
    border: '1px solid transparent',
    transition: 'all 0.35s ease-in-out',
    '&.animated': {
      animation: 'pulse-in 2s infinite',
      border: '1px solid rgb(255, 82, 82)',
    },
  },
  button: {
    padding: theme.spacing(1),
  },
  accent: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[50],
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.grey[50],
    },
  },
}));

export default RecordingControl;
