/**
 * Webview user-agent detector
 * @returns {boolean}
 */
export const isWebView = (
  platform?: 'android' | 'ios',
  ua: string = navigator.userAgent,
): boolean => {
  const androidRules = [
    // if it says it's a webview, let's go with that
    'WebView',
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to lollipop webview will put {version}.0.0.0
    'Android.*[.0-9].*wv',
    'Version/+[.0-9].*Chrome/+[.0-9]* (Mobile)?',
    // old chrome android webview agent
    'Linux; U; Android',
  ];

  const iosRules = [
    // iOS webview will be the same as safari but missing "Safari"
    '(iPhone|iPod|iPad)(?!.*Safari)',
  ];

  let rules: string[] = [];

  if (!platform) {
    rules = [...androidRules, ...iosRules];
  } else {
    rules = platform === 'android' ? androidRules : iosRules;
  }

  // Exception for FB Messenger for Android
  if (ua.includes('FB_IAB/Orca-Android')) return false;

  const webviewRegExp = new RegExp(`(${rules.join('|')})`, 'ig');
  return !!ua.match(webviewRegExp);
};

export default isWebView;
