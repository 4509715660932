import { useQuery } from '@apollo/client';
import { useState } from 'react';
// Material UI
import BackIcon from '@material-ui/icons/ChevronLeft';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Graphql
import todoDestinationsQuery from '../../graphql/queries/TodoDestinations.graphql';
import {
  GenericKeyItemsDestinationList,
  IntegrationTypes,
  TodoDestinations,
  TodoDestinations_todoDestinations,
  TodoDestinationsVariables,
} from '../../types';
// Lib Assets
import msTodoIntegrationIcon from '../../assets/icon-ms-todo-integration-24.svg';
import googleTasksTodoIntegrationIcon from '../../assets/icon-google-tasks-integration-24.svg';

interface TodoIntegrationsDialogProps {
  onClose: () => void;
  onSend: (
    todoListItem: GenericKeyItemsDestinationList | null,
    integrationType: IntegrationTypes,
  ) => void;
  integrationType: IntegrationTypes;
}

export const TodoIntegrationsDialog: React.VFC<TodoIntegrationsDialogProps> = ({
  onClose,
  onSend,
  integrationType,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [todoListsFilterValue, setTodoListsFilterValue] = useState<string>('');
  /* #endregion */

  /* #region Apollo Hooks */
  const  { data: todoDestinationsListsData, loading: isListLoading } = useQuery<TodoDestinations, TodoDestinationsVariables>(todoDestinationsQuery, {
    variables: {
      integrationType: integrationType
    }
  }); // prettier-ignore
  /* #endregion */

  /* #region Handlers */
  const handleChangeFilterTodoLists = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTodoListsFilterValue(event.target.value);
  };

  const handleClickTodoListItem =
    (todoListDestinationItem: GenericKeyItemsDestinationList | null) => () => {
      onSend(todoListDestinationItem, integrationType);
      onClose();
    };
  /* #endregion */

  /* #region Utils */
  const isTargetTodoList = (list: TodoDestinations_todoDestinations[]) => {
    return list.filter(({ name }) =>
      name.toLocaleLowerCase().includes(todoListsFilterValue.toLocaleLowerCase()),
    );
  };
  /* #endregion */

  /* #region Render Helpers */
  const todoLists = todoDestinationsListsData?.todoDestinations || [];

  const availableTodoLists = isTargetTodoList(todoLists);
  /* #endregion */

  return (
    <>
      <div className={styles.header}>
        <IconButton size="small" className={`${styles.iconButton} destination`} onClick={onClose}>
          <BackIcon fontSize="small" />
        </IconButton>
        <div className={styles.inner}>
          {integrationType === IntegrationTypes.MS_TODO && (
            <img src={msTodoIntegrationIcon} alt="Microsoft To Do Integration" />
          )}
          {integrationType === IntegrationTypes.GOOGLE_TASKS && (
            <img src={googleTasksTodoIntegrationIcon} alt="Google To Do Integration" />
          )}
          <Typography component="div" variant={isSmallScreen ? 'body2' : 'body1'}>
            <b>Post item to list</b>
          </Typography>
        </div>
      </div>
      <Divider />
      <TextField
        fullWidth
        size="small"
        variant="filled"
        placeholder="Type here to search"
        className={styles.search}
        value={todoListsFilterValue}
        onKeyDown={(e) => e.stopPropagation()}
        onChange={handleChangeFilterTodoLists}
      />
      <Divider />
      {isListLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" py={8}>
          <CircularProgress size={24} />
        </Box>
      ) : availableTodoLists.length ? (
        availableTodoLists.map((destination) => (
          <MenuItem key={destination.id} onClick={handleClickTodoListItem(destination)}>
            <Typography component="span" className={styles.textWrap}>
              {destination.name}
            </Typography>
          </MenuItem>
        ))
      ) : (
        <Box m={2}>
          <Typography variant="body2" color="textSecondary">
            No destination found
          </Typography>
        </Box>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
    '&.destination': {
      position: 'absolute',
      top: '50%',
      left: theme.spacing(1),
      transform: 'translateY(-50%)',
    },
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(0.75),
  },
  search: {
    padding: theme.spacing(1.5, 2),
  },
  textWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default TodoIntegrationsDialog;
