/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ActionFieldTypes {
  ACTION_LINK = "ACTION_LINK",
  ASSIGNED_BY = "ASSIGNED_BY",
  ASSIGNED_TO = "ASSIGNED_TO",
  AUTOMATION_OWNER_EMAIL = "AUTOMATION_OWNER_EMAIL",
  DUE_DATE = "DUE_DATE",
  HEADER_TEXT = "HEADER_TEXT",
  ID = "ID",
  IN_DISCUSSION_WITH = "IN_DISCUSSION_WITH",
  MEETING_FINISHED_AT = "MEETING_FINISHED_AT",
  MEETING_ID = "MEETING_ID",
  MEETING_LINK = "MEETING_LINK",
  MEETING_OWNER_EMAIL = "MEETING_OWNER_EMAIL",
  MEETING_STARTED_AT = "MEETING_STARTED_AT",
  MEETING_TEAM_ID = "MEETING_TEAM_ID",
  MEETING_TEAM_NAME = "MEETING_TEAM_NAME",
  MEETING_TITLE = "MEETING_TITLE",
  MEETING_TYPE = "MEETING_TYPE",
  TEXT = "TEXT",
  TYPE = "TYPE",
  WORKSPACE_ID = "WORKSPACE_ID",
}

/**
 * An enumeration.
 */
export enum ActionsCustomIntegrationSettingsContentTypes {
  ACTIONS = "ACTIONS",
  COMMITMENTS = "COMMITMENTS",
}

/**
 * An enumeration.
 */
export enum AgentCallPlatform {
  COMMON = "COMMON",
  GOOGLE_MEET = "GOOGLE_MEET",
  MANUAL_RECORD = "MANUAL_RECORD",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  MS_TEAMS = "MS_TEAMS",
  SMARTMEETING_RECORD = "SMARTMEETING_RECORD",
  SMARTMIKE_RECORD = "SMARTMIKE_RECORD",
  WEBEX = "WEBEX",
  ZOOM = "ZOOM",
}

/**
 * An enumeration.
 */
export enum ApplyToMeetings {
  ALL_MEETINGS = "ALL_MEETINGS",
  SINGLE = "SINGLE",
}

/**
 * An enumeration.
 */
export enum AssignmentsAccess {
  NONE = "NONE",
  TASKS = "TASKS",
  TODOS = "TODOS",
}

/**
 * An enumeration.
 */
export enum AuthProviderNames {
  apple = "apple",
  apple_web = "apple_web",
  custom_google = "custom_google",
  custom_microsoft = "custom_microsoft",
  custom_okta = "custom_okta",
  custom_other = "custom_other",
  google = "google",
  google_tasks = "google_tasks",
  microsoft = "microsoft",
  microsoft_adapter = "microsoft_adapter",
  microsoft_public = "microsoft_public",
  microsoft_search_connector = "microsoft_search_connector",
  microsoft_teams = "microsoft_teams",
  microsoft_todo = "microsoft_todo",
  notion_assignments = "notion_assignments",
  notion_meeting_notes = "notion_meeting_notes",
  slack = "slack",
  zoom = "zoom",
}

/**
 * An enumeration.
 */
export enum CalendarSynchronizationTypes {
  ALL_MEETINGS = "ALL_MEETINGS",
  INVITE_ONLY = "INVITE_ONLY",
  OWNED_MEETINGS = "OWNED_MEETINGS",
}

/**
 * An enumeration.
 */
export enum ChatAnswerFileTypes {
  IMAGE = "IMAGE",
  PDF = "PDF",
  TEXT = "TEXT",
}

/**
 * An enumeration.
 */
export enum ChatMeetingsLibraryRange {
  CUSTOM = "CUSTOM",
  LAST_SEVEN_DAYS = "LAST_SEVEN_DAYS",
  LAST_THIRTY_DAYS = "LAST_THIRTY_DAYS",
  LAST_THREE_MONTHS = "LAST_THREE_MONTHS",
}

/**
 * An enumeration.
 */
export enum ChurnOperationEnum {
  ACCOUNT_DELETE = "ACCOUNT_DELETE",
  SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL",
}

/**
 * An enumeration.
 */
export enum CompanyMaturityEnum {
  EMERGING = "EMERGING",
  ESTABLISHED = "ESTABLISHED",
  MATURE = "MATURE",
}

/**
 * An enumeration.
 */
export enum CompanySizeEnum {
  BIG = "BIG",
  LARGE = "LARGE",
  MIDDLE = "MIDDLE",
  SMALL = "SMALL",
}

/**
 * An enumeration.
 */
export enum CustomAuthProviderNames {
  custom_google = "custom_google",
  custom_microsoft = "custom_microsoft",
  custom_okta = "custom_okta",
  custom_other = "custom_other",
}

/**
 * An enumeration.
 */
export enum CustomIntegrationsTypes {
  ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION",
  ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION",
  TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
}

/**
 * An enumeration.
 */
export enum CustomOutboundUserIntegrationRules {
  ALL_MEETINGS = "ALL_MEETINGS",
  FILTERED_BY_KEYWORDS = "FILTERED_BY_KEYWORDS",
  FILTERED_BY_MEETING_TYPE = "FILTERED_BY_MEETING_TYPE",
  MANUAL_ONLY = "MANUAL_ONLY",
}

/**
 * An enumeration.
 */
export enum CustomOutboundWorkspaceIntegrationRules {
  ALL_MEETINGS = "ALL_MEETINGS",
  FILTERED_BY_KEYWORDS = "FILTERED_BY_KEYWORDS",
  FILTERED_BY_MEETING_TYPE = "FILTERED_BY_MEETING_TYPE",
  FILTERED_BY_OWNER = "FILTERED_BY_OWNER",
  FILTERED_BY_TEAM = "FILTERED_BY_TEAM",
}

/**
 * An enumeration.
 */
export enum DeleteByTimeSettingType {
  ALL_MEETING_DATA = "ALL_MEETING_DATA",
  DISABLED = "DISABLED",
  ONLY_MEETING_MEDIA = "ONLY_MEETING_MEDIA",
}

export enum ExecutionErrorCodes {
  EXTERNAL_SERVICE_ERROR = "EXTERNAL_SERVICE_ERROR",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  OBJECT_DOES_NOT_EXIST = "OBJECT_DOES_NOT_EXIST",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  PROCESSING_ERROR = "PROCESSING_ERROR",
  SCHEMA_ERROR = "SCHEMA_ERROR",
  TOKEN_ERROR = "TOKEN_ERROR",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  USED_FREE_REQUESTS = "USED_FREE_REQUESTS",
}

/**
 * An enumeration.
 */
export enum ExecutionErrorTypes {
  BAD_GATEWAY_502 = "BAD_GATEWAY_502",
  BAD_REQUEST_400 = "BAD_REQUEST_400",
  FORBIDDEN_403 = "FORBIDDEN_403",
  GOOGLE_CALENDAR_FULL_SYNC_REQUIRED = "GOOGLE_CALENDAR_FULL_SYNC_REQUIRED",
  GOOGLE_CALENDAR_INVALID_PUSH_HEADERS = "GOOGLE_CALENDAR_INVALID_PUSH_HEADERS",
  GOOGLE_CALENDAR_NOT_ALLOWED = "GOOGLE_CALENDAR_NOT_ALLOWED",
  GOOGLE_CALENDAR_NO_DATA_RECIEVED = "GOOGLE_CALENDAR_NO_DATA_RECIEVED",
  GOOGLE_CALENDAR_SYNC_ERROR = "GOOGLE_CALENDAR_SYNC_ERROR",
  INTERNAL_SERVER_ERROR_500 = "INTERNAL_SERVER_ERROR_500",
  INVALID_ACCESS_TOKEN = "INVALID_ACCESS_TOKEN",
  MICROSOFT_CALENDAR_LIMITED_REQUESTS = "MICROSOFT_CALENDAR_LIMITED_REQUESTS",
  MICROSOFT_CALENDAR_NO_DATA_RECIEVED = "MICROSOFT_CALENDAR_NO_DATA_RECIEVED",
  MICROSOFT_CALENDAR_SUBSCRIBE = "MICROSOFT_CALENDAR_SUBSCRIBE",
  MICROSOFT_CALENDAR_SYNC_ERROR = "MICROSOFT_CALENDAR_SYNC_ERROR",
  MICROSOFT_CALENDAR_SYNC_STATE_NOT_FOUND = "MICROSOFT_CALENDAR_SYNC_STATE_NOT_FOUND",
  NOT_FOUND_404 = "NOT_FOUND_404",
  UNAUTHORIZED_401 = "UNAUTHORIZED_401",
  UNSUPPORTED_MEDIA_TYPE_415 = "UNSUPPORTED_MEDIA_TYPE_415",
}

/**
 * An enumeration.
 */
export enum FeedbackCriticality {
  CRITICAL = "CRITICAL",
  IMPORTANT = "IMPORTANT",
  NICE_TO_HAVE = "NICE_TO_HAVE",
}

/**
 * An enumeration.
 */
export enum IntegrationActionTypes {
  ACTIVE = "ACTIVE",
  PROCESSING = "PROCESSING",
}

/**
 * An enumeration.
 */
export enum IntegrationConnectRules {
  ALL_MEETINGS = "ALL_MEETINGS",
  FILTERED_BY_KEYWORDS = "FILTERED_BY_KEYWORDS",
  FILTERED_BY_MEETING_TYPE = "FILTERED_BY_MEETING_TYPE",
  MANUAL_ONLY = "MANUAL_ONLY",
}

/**
 * An enumeration.
 */
export enum IntegrationTypes {
  ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION",
  ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  GOOGLE_CALENDAR_USER = "GOOGLE_CALENDAR_USER",
  GOOGLE_TASKS = "GOOGLE_TASKS",
  ICS_CALENDAR_USER_INTEGRATION = "ICS_CALENDAR_USER_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  MS_CALENDAR_USER = "MS_CALENDAR_USER",
  MS_SEARCH_CONNECTOR_WORKSPACE = "MS_SEARCH_CONNECTOR_WORKSPACE",
  MS_TEAMS_LITE_ADAPTER_WORKSPACE = "MS_TEAMS_LITE_ADAPTER_WORKSPACE",
  MS_TEAMS_TEC_ADAPTER_USER = "MS_TEAMS_TEC_ADAPTER_USER",
  MS_TEAMS_TEC_ADAPTER_WORKSPACE = "MS_TEAMS_TEC_ADAPTER_WORKSPACE",
  MS_TODO = "MS_TODO",
  NOTION_ASSIGNMENTS = "NOTION_ASSIGNMENTS",
  NOTION_MEETING_NOTES = "NOTION_MEETING_NOTES",
  SLACK_USER = "SLACK_USER",
  TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION",
  TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  ZOOM_USER = "ZOOM_USER",
}

/**
 * Duplication from Stripe
 */
export enum InvoiceStatusEnum {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  PAID = "PAID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  VOID = "VOID",
}

/**
 * An enumeration.
 */
export enum Languages {
  AFRIKAANS = "AFRIKAANS",
  ARABIC = "ARABIC",
  BOSNIAN = "BOSNIAN",
  BULGARIAN = "BULGARIAN",
  CANTONESE = "CANTONESE",
  CATALAN = "CATALAN",
  CHINESE = "CHINESE",
  CROATIAN = "CROATIAN",
  CZECH = "CZECH",
  DANISH = "DANISH",
  DUTCH = "DUTCH",
  ENGLISH = "ENGLISH",
  ENGLISH_AUSTRALIAN = "ENGLISH_AUSTRALIAN",
  ENGLISH_BRITISH = "ENGLISH_BRITISH",
  ENGLISH_FINANCE = "ENGLISH_FINANCE",
  ENGLISH_INDIAN = "ENGLISH_INDIAN",
  FINNISH = "FINNISH",
  FRENCH = "FRENCH",
  GALICIAN = "GALICIAN",
  GERMAN = "GERMAN",
  GREEK = "GREEK",
  HEBREW = "HEBREW",
  HINDI = "HINDI",
  HUNGARIAN = "HUNGARIAN",
  INDONESIAN = "INDONESIAN",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  MACEDONIAN = "MACEDONIAN",
  MALAY = "MALAY",
  NORWEGIAN = "NORWEGIAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE_BRAZIL = "PORTUGUESE_BRAZIL",
  PORTUGUESE_PORTUGAL = "PORTUGUESE_PORTUGAL",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  SLOVAK = "SLOVAK",
  SPANISH_AMERICAN = "SPANISH_AMERICAN",
  SPANISH_SPAIN = "SPANISH_SPAIN",
  SWEDISH = "SWEDISH",
  TAGALOG = "TAGALOG",
  TAMIL = "TAMIL",
  THAI = "THAI",
  TURKISH = "TURKISH",
  UKRAINIAN = "UKRAINIAN",
  URDU = "URDU",
  VIETNAMESE = "VIETNAMESE",
}

/**
 * An enumeration.
 */
export enum ManualRecordType {
  ALL = "ALL",
  FOLLOWING = "FOLLOWING",
  SINGLE = "SINGLE",
}

/**
 * An enumeration.
 */
export enum MeetingExportFormats {
  MARKDOWN = "MARKDOWN",
}

/**
 * An enumeration.
 */
export enum MeetingMinutesWorkspaceSettingsDefaultTemplate {
  GENERIC = "GENERIC",
  MEETING_TYPE_SPECIFIC = "MEETING_TYPE_SPECIFIC",
}

/**
 * An enumeration.
 */
export enum MeetingNotesFieldTypes {
  AUTOMATION_OWNER_EMAIL = "AUTOMATION_OWNER_EMAIL",
  MEETING_DURATION = "MEETING_DURATION",
  MEETING_FINISHED_AT = "MEETING_FINISHED_AT",
  MEETING_ID = "MEETING_ID",
  MEETING_LINK = "MEETING_LINK",
  MEETING_NOTES = "MEETING_NOTES",
  MEETING_OWNER_EMAIL = "MEETING_OWNER_EMAIL",
  MEETING_STARTED_AT = "MEETING_STARTED_AT",
  MEETING_TEAM_ID = "MEETING_TEAM_ID",
  MEETING_TEAM_NAME = "MEETING_TEAM_NAME",
  MEETING_TITLE = "MEETING_TITLE",
  MEETING_TYPE = "MEETING_TYPE",
  PARTICIPANTS = "PARTICIPANTS",
  WORKSPACE_ID = "WORKSPACE_ID",
}

/**
 * An enumeration.
 */
export enum MeetingProcessingResultsMainLanguage {
  AFRIKAANS = "AFRIKAANS",
  ARABIC = "ARABIC",
  BOSNIAN = "BOSNIAN",
  BULGARIAN = "BULGARIAN",
  CANTONESE = "CANTONESE",
  CATALAN = "CATALAN",
  CHINESE = "CHINESE",
  CROATIAN = "CROATIAN",
  CZECH = "CZECH",
  DANISH = "DANISH",
  DUTCH = "DUTCH",
  ENGLISH = "ENGLISH",
  ENGLISH_AUSTRALIAN = "ENGLISH_AUSTRALIAN",
  ENGLISH_BRITISH = "ENGLISH_BRITISH",
  ENGLISH_FINANCE = "ENGLISH_FINANCE",
  ENGLISH_INDIAN = "ENGLISH_INDIAN",
  FINNISH = "FINNISH",
  FRENCH = "FRENCH",
  GALICIAN = "GALICIAN",
  GERMAN = "GERMAN",
  GREEK = "GREEK",
  HEBREW = "HEBREW",
  HINDI = "HINDI",
  HUNGARIAN = "HUNGARIAN",
  INDONESIAN = "INDONESIAN",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  MACEDONIAN = "MACEDONIAN",
  MALAY = "MALAY",
  NORWEGIAN = "NORWEGIAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE_BRAZIL = "PORTUGUESE_BRAZIL",
  PORTUGUESE_PORTUGAL = "PORTUGUESE_PORTUGAL",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  SLOVAK = "SLOVAK",
  SPANISH_AMERICAN = "SPANISH_AMERICAN",
  SPANISH_SPAIN = "SPANISH_SPAIN",
  SWEDISH = "SWEDISH",
  TAGALOG = "TAGALOG",
  TAMIL = "TAMIL",
  THAI = "THAI",
  TURKISH = "TURKISH",
  UKRAINIAN = "UKRAINIAN",
  URDU = "URDU",
  VIETNAMESE = "VIETNAMESE",
}

/**
 * An enumeration.
 */
export enum MeetingStatuses {
  failed = "failed",
  not_scheduled = "not_scheduled",
  on_call = "on_call",
  on_pause = "on_pause",
  processing = "processing",
  scheduled = "scheduled",
  submitted = "submitted",
}

/**
 * An enumeration.
 */
export enum MeetingTypes {
  AGREEMENT_DRAFT_OR_REVIEW = "AGREEMENT_DRAFT_OR_REVIEW",
  AGREEMENT_REVISE_OR_EXTEND = "AGREEMENT_REVISE_OR_EXTEND",
  BOARD_MEETING = "BOARD_MEETING",
  BUDGET_OR_FINANCIAL_PLANNING = "BUDGET_OR_FINANCIAL_PLANNING",
  BUDGET_OR_FINANCIAL_REVIEW = "BUDGET_OR_FINANCIAL_REVIEW",
  CHANGE_MANAGEMENT_PLANNING = "CHANGE_MANAGEMENT_PLANNING",
  CHANGE_PLANNING = "CHANGE_PLANNING",
  COMPLIANCE_ACTIVITIES = "COMPLIANCE_ACTIVITIES",
  CUSTOMER_TOUCHPOINT = "CUSTOMER_TOUCHPOINT",
  DAILY_STANDUP_OR_STATUS = "DAILY_STANDUP_OR_STATUS",
  DEMO = "DEMO",
  DISCOVERY = "DISCOVERY",
  EMERGENCY_OR_CRISIS = "EMERGENCY_OR_CRISIS",
  FEEDBACK_SESSION = "FEEDBACK_SESSION",
  GENERIC = "GENERIC",
  IMPLEMENTATION_PLANNING = "IMPLEMENTATION_PLANNING",
  INTERVIEW_OR_RECRUITING = "INTERVIEW_OR_RECRUITING",
  ISSUE_TROUBLESHOOTING = "ISSUE_TROUBLESHOOTING",
  LECTURE = "LECTURE",
  OFFBOARDING_OR_EXIT_INTERVIEW = "OFFBOARDING_OR_EXIT_INTERVIEW",
  ONBOARDING = "ONBOARDING",
  ONE_ON_ONE = "ONE_ON_ONE",
  PANELS_OR_DISCUSSION_FORUMS = "PANELS_OR_DISCUSSION_FORUMS",
  PERFORMANCE_REVIEW = "PERFORMANCE_REVIEW",
  PROJECT_PORTFOLIO_PLANNING = "PROJECT_PORTFOLIO_PLANNING",
  PROJECT_PORTFOLIO_REVIEW = "PROJECT_PORTFOLIO_REVIEW",
  Q_AND_A_SESSION = "Q_AND_A_SESSION",
  REQUIREMENTS_GATHERING = "REQUIREMENTS_GATHERING",
  RETROSPECTIVE = "RETROSPECTIVE",
  ROADMAP_DEVELOPMENT = "ROADMAP_DEVELOPMENT",
  SECURITY_REVIEW = "SECURITY_REVIEW",
  SOLUTION_PLANNING = "SOLUTION_PLANNING",
  SPRINT_OR_RELEASE_REVIEW = "SPRINT_OR_RELEASE_REVIEW",
  STRATEGY_DEVELOPMENT = "STRATEGY_DEVELOPMENT",
  STRATEGY_OR_BRAINSTORMING = "STRATEGY_OR_BRAINSTORMING",
  TOWNHALL_OR_ALL_HANDS = "TOWNHALL_OR_ALL_HANDS",
  TRAINING_OR_WORKSHOPS = "TRAINING_OR_WORKSHOPS",
  WEBINARS = "WEBINARS",
  WEEKLY_STATUS_OR_UPDATE = "WEEKLY_STATUS_OR_UPDATE",
  WORKSTREAM_OR_PROJECT_KICKOFF = "WORKSTREAM_OR_PROJECT_KICKOFF",
}

/**
 * An enumeration.
 */
export enum MetricsPeriod {
  PREVIOUS_MONTH = "PREVIOUS_MONTH",
  PREVIOUS_WEEK = "PREVIOUS_WEEK",
  PREVIOUS_YEAR = "PREVIOUS_YEAR",
  THIS_DAY = "THIS_DAY",
  THIS_MONTH = "THIS_MONTH",
  THIS_WEEK = "THIS_WEEK",
  THIS_YEAR = "THIS_YEAR",
}

/**
 * An enumeration.
 */
export enum NotificationApproaches {
  EACH_USER = "EACH_USER",
  ONCE = "ONCE",
}

/**
 * An enumeration.
 */
export enum PlanIDEnum {
  ENTERPRISE = "ENTERPRISE",
  PERSONAL = "PERSONAL",
  PROFESSIONAL = "PROFESSIONAL",
  TEAM = "TEAM",
  TEAM_PLUS = "TEAM_PLUS",
}

/**
 * An enumeration.
 */
export enum PostMeetingInviteesNotificationTypes {
  ALL_INVITEES = "ALL_INVITEES",
  ONLY_NON_WORKSPACE_INVITEES = "ONLY_NON_WORKSPACE_INVITEES",
  ONLY_WORKSPACE_INVITEES = "ONLY_WORKSPACE_INVITEES",
}

/**
 * An enumeration.
 */
export enum RecordDevices {
  smartmeeting = "smartmeeting",
  smartmike = "smartmike",
}

/**
 * An enumeration.
 */
export enum RegistrationInviteRole {
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  POWOW_ADMIN = "POWOW_ADMIN",
  REGULAR_USER = "REGULAR_USER",
}

/**
 * An enumeration.
 */
export enum SlackDestinations {
  PRIVATE_CHANNEL = "PRIVATE_CHANNEL",
  PUBLIC_CHANNEL = "PUBLIC_CHANNEL",
}

/**
 * An enumeration.
 */
export enum StripePricePaymentIntervalEnum {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
}

/**
 * An enumeration.
 */
export enum StripePromotionCodeVariant {
  AMOUNT = "AMOUNT",
  PERCENT = "PERCENT",
}

/**
 * Duplication from Stripe
 */
export enum SubscriptionStatusEnum {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID",
}

/**
 * An enumeration.
 */
export enum TranscriptionFieldTypes {
  AUTOMATION_OWNER_EMAIL = "AUTOMATION_OWNER_EMAIL",
  MEETING_DURATION = "MEETING_DURATION",
  MEETING_FINISHED_AT = "MEETING_FINISHED_AT",
  MEETING_ID = "MEETING_ID",
  MEETING_LINK = "MEETING_LINK",
  MEETING_OWNER_EMAIL = "MEETING_OWNER_EMAIL",
  MEETING_STARTED_AT = "MEETING_STARTED_AT",
  MEETING_TEAM_ID = "MEETING_TEAM_ID",
  MEETING_TEAM_NAME = "MEETING_TEAM_NAME",
  MEETING_TITLE = "MEETING_TITLE",
  MEETING_TRANSCRIPTION = "MEETING_TRANSCRIPTION",
  MEETING_TYPE = "MEETING_TYPE",
  PARTICIPANTS = "PARTICIPANTS",
  WORKSPACE_ID = "WORKSPACE_ID",
}

/**
 * An enumeration.
 */
export enum UserGuideTypes {
  AI_ASSOCIATE = "AI_ASSOCIATE",
  AUTOMATIONS = "AUTOMATIONS",
  CHROME_EXTENSION = "CHROME_EXTENSION",
  COMMITMENTS = "COMMITMENTS",
  CONNECT_CALENDAR = "CONNECT_CALENDAR",
  GUEST_VIEW = "GUEST_VIEW",
  INSTA_INVITE = "INSTA_INVITE",
  INVITE_TEAMMEMBER = "INVITE_TEAMMEMBER",
  MANUAL_RECORDING = "MANUAL_RECORDING",
  MEETING_MINUTES = "MEETING_MINUTES",
  UPLOAD_RECORDING = "UPLOAD_RECORDING",
}

/**
 * An enumeration.
 */
export enum UserProfessions {
  BUSINESS_DEVELOPMENT_SPECIALIST = "BUSINESS_DEVELOPMENT_SPECIALIST",
  CONTENT_CREATOR = "CONTENT_CREATOR",
  CUSTOMER_SUCCESS_MANAGER = "CUSTOMER_SUCCESS_MANAGER",
  CXO_OR_PRESIDENT_OR_VP = "CXO_OR_PRESIDENT_OR_VP",
  DESIGNER = "DESIGNER",
  DEVELOPER_OR_ENGINEER = "DEVELOPER_OR_ENGINEER",
  DIGITAL_MARKETING_SPECIALIST = "DIGITAL_MARKETING_SPECIALIST",
  HUMAN_RESOURCES_MANAGER_OR_ASSOCIATE = "HUMAN_RESOURCES_MANAGER_OR_ASSOCIATE",
  MARKETING_MANAGER = "MARKETING_MANAGER",
  PRODUCT_OWNER = "PRODUCT_OWNER",
  PROJECT_OR_PRODUCT_MANAGER = "PROJECT_OR_PRODUCT_MANAGER",
  QA_OR_TESTER = "QA_OR_TESTER",
  RECRUITER = "RECRUITER",
  SALES_MANAGER_OR_SALES_REPRESENTATIVE = "SALES_MANAGER_OR_SALES_REPRESENTATIVE",
  SCRUM_MASTER = "SCRUM_MASTER",
  SYSTEMS_ADMINISTRATOR_OR_DEVOPS = "SYSTEMS_ADMINISTRATOR_OR_DEVOPS",
  SYSTEMS_ARCHITECT = "SYSTEMS_ARCHITECT",
  TEACHER_OR_TRAINER_OR_EDUCATOR = "TEACHER_OR_TRAINER_OR_EDUCATOR",
  TECHNICAL_SUPPORT_SPECIALIST = "TECHNICAL_SUPPORT_SPECIALIST",
  TRAINING_AND_DEVELOPMENT_SPECIALIST = "TRAINING_AND_DEVELOPMENT_SPECIALIST",
  UI_OR_UX_SPECIALIST = "UI_OR_UX_SPECIALIST",
}

/**
 * An enumeration.
 */
export enum UserRole {
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  POWOW_ADMIN = "POWOW_ADMIN",
  REGULAR_USER = "REGULAR_USER",
}

/**
 * An enumeration.
 */
export enum UserRoles {
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  REGULAR_USER = "REGULAR_USER",
}

export interface ChatMeetingsLibraryRangeInput {
  dateFrom: GqlDate;
  dateTo: GqlDate;
}

export interface EditDataRetentionSettingsMutationInput {
  id: string;
  deleteByTime: DeleteByTimeSettingType;
  deleteByTimeDays: number;
  clientMutationId?: string | null;
}

export interface EditPostMeetingNotificationSettings {
  enabled: boolean;
  notificationType: PostMeetingInviteesNotificationTypes;
}

export interface EditPreMeetingNotificationSettings {
  enabled: boolean;
  headerTextEnabled: boolean;
  customBodyText?: string | null;
  customFooterText?: string | null;
}

export interface InviteWorkspaceMemberInput {
  email: string;
  role: UserRoles;
}

export interface LanguagesInputType {
  primaryLanguage: Languages;
  additionalLanguage?: Languages | null;
}

export interface ManageWorkspaceUseCaseDataMutationInput {
  industry: string;
  subIndustry: string;
  specIndustry: string;
  companySize: CompanySizeEnum;
  companyMaturity: CompanyMaturityEnum;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
