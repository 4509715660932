import { lazyWithPreload } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const SignUpModuleContainer = lazyWithPreload(
  () => import('./containers/SignUpModuleContainer'),
);

export const SignUpModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.SignUp,
    render: () => <SignUpModuleContainer />,
  },
};

export default SignUpModule;
