// Material UI
import CheckIcon from '@material-ui/icons/CheckSharp';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import { Banner } from '@shared/components';

export interface CalendarSyncedBannerProps {
  onClose: () => void;
  onReload: () => void;
}

export const CalendarSyncedBanner: React.VFC<CalendarSyncedBannerProps> = ({
  onClose,
  onReload,
}) => {
  const classes = useStyles();

  return (
    <Banner
      color="#2267B8"
      bgcolor="#D9F0FE"
      icon={<CheckIcon />}
      content={
        <div className={classes.content}>
          <span>Calendar successfully synced. Please reload the page to see updates.</span>
          <Typography color="inherit" component="span" className={classes.link} onClick={onReload}>
            Reload Page
          </Typography>
        </div>
      }
      onClose={onClose}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    ...theme.typography.body1,
    marginLeft: theme.spacing(1),
    color: '#2267B8',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body2,
      textAlign: 'left',
      marginRight: theme.spacing(4),
    },
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
    fontSize: 'inherit',
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
  },
}));

export default CalendarSyncedBanner;
