import { useState } from 'react';
// Material UI
import Box, { BoxProps } from '@material-ui/core/Box';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
// Lib Shared
import LanguageSelect from './LanguageSelect';
import { SUPPORTED_LANGUAGES } from '../constants';
import { Languages } from '../types';

export interface LanguageSelectsProps {
  additionalLanguage: Languages | null;
  disabled?: boolean;
  formGroupProps?: BoxProps;
  formGroupTitleProps?: TypographyProps;
  primaryLanguage: Languages;
  onChange: (primaryLanguage: Languages, additionalLanguage: Languages | null) => void;
}

export const LanguageSelects: React.VFC<LanguageSelectsProps> = ({
  additionalLanguage,
  disabled = false,
  formGroupProps,
  formGroupTitleProps,
  primaryLanguage,
  onChange,
}) => {
  const [currentPrimaryLang, setCurrentPrimaryLang] = useState(primaryLanguage);
  const [currentAdditionalLang, setCurrentAdditionalLang] = useState(additionalLanguage);

  function getLangKeyValuePair(key: Languages): {
    key: string;
    value: string;
  } {
    const value = SUPPORTED_LANGUAGES[key as keyof typeof SUPPORTED_LANGUAGES] || key;
    return { key, value };
  }

  /* #region  Handlers */
  const handleChangePrimaryLang = (value: { key: string; value: string } | null) => {
    const nextPrimaryLanguage = (value?.key || null) as Languages | Languages.ENGLISH;
    const nextAdditionalLanguage: Languages | null = null;
    setCurrentPrimaryLang(nextPrimaryLanguage);
    setCurrentAdditionalLang(nextAdditionalLanguage);
    onChange(nextPrimaryLanguage, nextAdditionalLanguage);
  };

  const handleChangeAdditionalLang = (value: { key: string; value: string } | null) => {
    const nextPrimaryLanguage = currentPrimaryLang;
    const nextAdditionalLanguage: Languages | null = (value?.key as Languages) || null;
    if (currentPrimaryLang === nextAdditionalLanguage) return;
    setCurrentAdditionalLang(nextAdditionalLanguage);
    onChange(nextPrimaryLanguage, nextAdditionalLanguage);
  };
  /* #endregion */

  /* #region  Render Helpers */
  type SupportedLangKeys = Array<keyof typeof SUPPORTED_LANGUAGES>;
  const defaultAdditionalLangOption = { key: '', value: 'No additional language' };
  const supportedLangKeys = Object.keys(SUPPORTED_LANGUAGES) as SupportedLangKeys;
  const supportedLangs = supportedLangKeys.map(getLangKeyValuePair);
  const englishLangsVersions = [
    Languages.ENGLISH,
    Languages.ENGLISH_AUSTRALIAN,
    Languages.ENGLISH_BRITISH,
    Languages.ENGLISH_FINANCE,
    Languages.ENGLISH_INDIAN,
  ];
  let additionalLangs: ReturnType<typeof getLangKeyValuePair>[] = [];

  if (!englishLangsVersions.includes(currentPrimaryLang)) {
    // If the primary language is not English, then we want to include English as an option.
    additionalLangs = [
      defaultAdditionalLangOption,
      getLangKeyValuePair(Languages.ENGLISH),
      getLangKeyValuePair(Languages.ENGLISH_AUSTRALIAN),
      getLangKeyValuePair(Languages.ENGLISH_BRITISH),
      getLangKeyValuePair(Languages.ENGLISH_FINANCE),
      getLangKeyValuePair(Languages.ENGLISH_INDIAN),
    ];
  } else {
    // If the primary language is English, then we want to include all other languages as options.
    additionalLangs = [
      defaultAdditionalLangOption,
      ...supportedLangs.filter(({ key }) => !englishLangsVersions.includes(key as Languages)),
    ];
  }

  const hasAdditionalLangs = additionalLangs.length > 0;
  /* #endregion */

  return (
    <>
      <Box mb={2} {...formGroupProps}>
        <Typography noWrap gutterBottom variant="body2" {...formGroupTitleProps}>
          <b>Primary Language</b>
        </Typography>
        <LanguageSelect
          options={supportedLangs}
          value={currentPrimaryLang}
          disabled={disabled}
          onChange={handleChangePrimaryLang}
        />
      </Box>

      <Box
        mb={2}
        title={hasAdditionalLangs ? '' : 'No additional language available'}
        {...formGroupProps}
      >
        <Typography noWrap gutterBottom variant="body2" {...formGroupTitleProps}>
          <b>Additional Language</b>
        </Typography>
        <LanguageSelect
          disabled={disabled || !hasAdditionalLangs}
          options={additionalLangs}
          value={currentAdditionalLang}
          onChange={handleChangeAdditionalLang}
        />
      </Box>
    </>
  );
};

export default LanguageSelects;
