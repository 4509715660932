import { useApolloClient } from '@apollo/client';
import { useHistory, generatePath } from 'react-router-dom';
import { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// Sembly UI
import { AdvancedSearchDialog } from '@sembly-ui';
// GQL Queries and Types
import recordingMeetingAccessQuery from '@shared/queries/RecordingMeetingAccess.graphql';
import { PlanIDEnum, RecordingMeetingAccess } from '@gql-types';
// App Shared Dialogs
import AgentCallDialog from '@shared/dialogs/AgentCallDialog';
import AudioRecorderModal from '@shared/dialogs/AudioRecorder';
import RecordingProhibitedDialog from '@shared/dialogs/RecordingProhibitedDialog';
import ShowVideoOnboardingDialog from '@shared/dialogs/ShowVideoOnboardingDialog';
import SuccessfullySubmittedRecording from '@shared/dialogs/SuccessfullySubmittedRecording';
import TutorialsDialog from '@shared/dialogs/TutorialsDialog';
import UploadMeetingModal from '@shared/dialogs/UploadMeetingModal';
// App Shared
import AppMissionControl from '@shared/components/AppMissionControl';
import SuspenseFallback from '@shared/components/SuspenseFallback';
// import { ga } from '@shared/utils';
import { LocationState } from '@shared/types';
import { Routes } from '@shared/enums';
import { useUserContext, useUserInterface } from '@shared/hooks';

export const AppBarActions: React.VFC = () => {
  /* #region  Hooks */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory<LocationState>();

  const client = useApolloClient();
  const user = useUserContext();

  const [isOpenAudioRecorder, setIsOpenAudioRecorder] = useState(false);
  const [isOpenRecordingProhibitedDialog, setIsOpenRecordingProhibitedDialog] = useState(false);
  const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
  const [isRequestingLimits, setIsRequestingLimits] = useState(false);
  const [shownSubmissionCompleted, setShownSubmissionCompleted] = useState<string | null>(null);

  const {
    isOpenIntroduction: isOpenGettingStarted,
    isOpenTutorial,
    isOpenUpload,
    isOpenAgentCallPlanner,
    update,
  } = useUserInterface();
  /* #endregion */

  /* #region  Handlers */
  const handleToggleSearchModal = (open: boolean) => () => {
    setIsOpenSearchModal(open);
  };

  const handleToggleAgentCallPlanner = (open: boolean) => () => {
    update({ isOpenAgentCallPlanner: open });
  };

  const handleToggleUploadModal = (open: boolean) => () => {
    update({ isOpenUpload: open });
  };

  const handleCloseMeetingSubmittedDialog = () => {
    setShownSubmissionCompleted(null);
    // update meeting list on home page
    client.refetchQueries({
      updateCache(cache) {
        cache.modify({
          fields: {
            myMeetingsPaginated(value, { DELETE }) {
              return DELETE;
            },
          },
        });
      },
    });
  };

  const handleCloseAudioRecorder = () => {
    setIsOpenAudioRecorder(false);
  };

  const handleStartRecording = () => {
    const userBillingAccessId = user.data?.me?.billingAccess?.id;
    const isPersonalPlan = userBillingAccessId === PlanIDEnum.PERSONAL;

    if (!userBillingAccessId || isPersonalPlan) {
      setIsRequestingLimits(true);

      client
        .query<RecordingMeetingAccess>({
          query: recordingMeetingAccessQuery,
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          const paymentCustomer = data.me?.paymentCustomer;
          const meetingsDurationSecondsLeft = paymentCustomer?.meetingsDurationSecondsLeft ?? 0;
          const canRecord = meetingsDurationSecondsLeft > 0;

          setIsRequestingLimits(false);

          if (canRecord) {
            setIsOpenAudioRecorder(true);
            update({ isOpenRecorder: true });
          } else {
            setIsOpenRecordingProhibitedDialog(true);
          }
        });
    } else {
      setIsOpenAudioRecorder(true);
      update({ isOpenRecorder: true });
    }
  };

  const handleToggleRecordingProhibited = (open: boolean) => () => {
    setIsOpenRecordingProhibitedDialog(open);
  };

    const handleJumpToMeeting = (meetingId: string) => {
      history.push(generatePath(Routes.Meeting, { meetingId }));
      // ga.event({ category: 'Search', action: 'Jump to Search Result' });
    };
  /* #endregion */

  return (
    <>
      <Box display="flex">
        {/* Begin: Mobile search button */}
        {isSmallScreen && (
          <IconButton onClick={handleToggleSearchModal(true)}>
            <SearchIcon />
          </IconButton>
        )}
        {/* End: Mobile search button */}

        <AppMissionControl
          disabled={user.loading}
          onInviteSemblyAgent={handleToggleAgentCallPlanner(true)}
          onRequestMeetingUploading={handleToggleUploadModal(true)}
          onRequestMeetingRecording={handleStartRecording}
        />
      </Box>
      {/* Begin: Dialogs */}
      {isRequestingLimits && <SuspenseFallback />}
      {isOpenSearchModal && (
        <AdvancedSearchDialog
          open
          isSmartMeetingUser={!!user.data?.me?.philipsDevices?.smartmeeting}
          onClickOnMeetingCard={handleJumpToMeeting}
          onClose={handleToggleSearchModal(false)}
        />
      )}
      {isOpenAgentCallPlanner && <AgentCallDialog onClose={handleToggleAgentCallPlanner(false)} />}
      {shownSubmissionCompleted && (
        <SuccessfullySubmittedRecording
          open={!!shownSubmissionCompleted}
          meetingId={shownSubmissionCompleted}
          onClose={handleCloseMeetingSubmittedDialog}
        />
      )}
      {isOpenUpload && (
        <UploadMeetingModal
          onUpload={setShownSubmissionCompleted}
          onClose={handleToggleUploadModal(false)}
        />
      )}
      {isOpenRecordingProhibitedDialog && (
        <RecordingProhibitedDialog onClose={handleToggleRecordingProhibited(false)} />
      )}
      <AudioRecorderModal open={isOpenAudioRecorder} onClose={handleCloseAudioRecorder} />
      {isOpenGettingStarted && <ShowVideoOnboardingDialog />}
      {isOpenTutorial && <TutorialsDialog />}
      {/* End: Modal Dialogs */}
    </>
  );
};

export default AppBarActions;
