import ReactQuill from 'react-quill';
import clsx from 'clsx';
import get from 'lodash/get';
import sanitize from 'sanitize-html';
import { Fragment, useState, useEffect, useRef, useMemo, forwardRef, LegacyRef } from 'react';
import { Quill, Sources, RangeStatic } from 'quill';
// Material UI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatStrikethroughIcon from '@material-ui/icons/FormatStrikethrough';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
// Lib Shared
import FormatHeader1Icon from '../icons/FormatHeader1';
import FormatHeader2Icon from '../icons/FormatHeader2';
import FormatHeader3Icon from '../icons/FormatHeader3';
import { isRTLString } from '../utils';

/* #region  Types */
export interface RichTextEditorProps {
  defaultValue?: string;
  hideSecondaryToolbarItems?: boolean;
  hideToolbar?: boolean;
  isRTLDirected?: boolean;
  isSaving?: boolean;
  maxLength?: number;
  persistToolbar?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  reverseLayout?: boolean;
  scrollContainerId?: string;
  stickyToolbar?: boolean;
  onChange?: (value: string) => void;
  onFocus?: () => void;
}

interface StringMap {
  [key: string]: any;
}

interface Selection {
  editor?: Quill;
  format: StringMap;
  selection: RangeStatic;
  source: Sources;
}

interface ToolbarItem {
  label: string;
  icon: JSX.Element;
  formatType: string;
  formatValue: string | number | boolean;
  primary: boolean;
  gutter: boolean;
}
/* #endregion */

const ALLOWED_TAGS = sanitize.defaults.allowedTags.concat(['p']);

export const RichTextEditor = forwardRef(
  (
    {
      defaultValue,
      hideSecondaryToolbarItems = false,
      hideToolbar = false,
      isRTLDirected = false,
      isSaving = false,
      maxLength,
      persistToolbar = false,
      placeholder = 'Add a note...',
      readOnly = false,
      reverseLayout = false,
      scrollContainerId = undefined,
      stickyToolbar = false,
      onChange = () => null,
      onFocus = () => null,
    }: RichTextEditorProps,
    ref: LegacyRef<HTMLDivElement> | undefined,
  ) => {
    const styles = useStyles();

    const quillRef = useRef<ReactQuill>(null);

    const [isFocused, setIsFocused] = useState(false);
    const [isRTLDetected, setIsRTLDetected] = useState(false);
    const [quillState, setQuillState] = useState<Selection>();

    /* #region  Handlers */
    const handleOnChange = (content: string) => {
      // check if the content has RTL words
      setIsRTLDetected(isRTLString(content));
      // update the content
      if (!readOnly) onChange(content);
    };

    const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
      const clipboardDataText = event.clipboardData.getData('Text').length;
      const currentTextContent = event.currentTarget.textContent?.length ?? 0;
      const contentLength = clipboardDataText + currentTextContent;

      if (maxLength && contentLength > maxLength) {
        event.preventDefault();
      }
    };

    const handleChangeSelection = (selection: RangeStatic, source: Sources) => {
      if (source !== 'user' || readOnly) return;

      const editor = quillRef.current?.getEditor();
      const format = selection && editor ? editor.getFormat(selection) : {};

      setQuillState({ selection, source, editor, format });
    };

    const handleOnFocus = () => {
      setIsFocused(true);
    };

    const handleOnBlur = () => {
      setIsFocused(false);
    };

    const handleFormat = (formatType: string, formatValue: string | number | boolean) => () => {
      const editor = quillRef.current?.getEditor();
      if (!editor) return;

      const selection = quillState?.selection;

      if (selection && selection?.length) {
        editor.formatText(selection, formatType, formatValue);
      } else {
        editor.format(formatType, formatValue);
        if (selection) editor.setSelection(selection);
      }

      const format = selection && editor ? editor.getFormat(selection) : {};

      setQuillState((state) => {
        if (!state) return;
        return { ...state, format };
      });
    };
    /* #endregion */

    useEffect(() => {
      if (!defaultValue) return;
      setIsRTLDetected(isRTLString(defaultValue));
    }, [defaultValue]);

    /* #region  Render Helpers */
    const checkActivity = (formatType: string, formatValue: string | number | boolean): boolean => {
      if (!formatType || !quillState?.format) return false;
      return get(quillState.format, formatType, false) === formatValue;
    };

    const renderToolbarItem = (item: ToolbarItem) => {
      const isActive = checkActivity(item.formatType, item.formatValue);
      return (
        <>
          <IconButton
            size="medium"
            title={item.label}
            color={isActive ? 'primary' : 'default'}
            disabled={readOnly}
            onClick={handleFormat(item.formatType, isActive ? false : item.formatValue)}
          >
            {item.icon}
          </IconButton>
          {item.gutter && <Box mr={2} />}
        </>
      );
    };

    const renderEditor = useMemo(
      () => {
        const scrollContainerNode = scrollContainerId ? document.querySelector(scrollContainerId) : null; // prettier-ignore
        const isHtmlElement = scrollContainerNode instanceof HTMLElement;
        const scrollingContainer = isHtmlElement ? scrollContainerNode : undefined;
        const content = defaultValue
          ? sanitize(defaultValue, { allowedTags: ALLOWED_TAGS })
          : defaultValue;

        const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
          const contentLength = event.currentTarget.textContent?.length ?? 0;

          const allowedControlKeyCodes = ['KeyA', 'KeyC', 'KeyV', 'KeyZ', 'KeyX'];
          const allowedKeyCodes = [
            'Delete',
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
            'ArrowDown',
            'ArrowUp',
          ];

          if (maxLength !== undefined && contentLength >= maxLength) {
            const isControlKeyPressed = event.ctrlKey || event.metaKey;
            const isAllowedKeyCode = allowedKeyCodes.includes(event.code);
            const isAllowedCtrlKeyCode = allowedControlKeyCodes.includes(event.code);
            const isAllowedShortKey = isControlKeyPressed && isAllowedCtrlKeyCode;
            const isException = !(isAllowedKeyCode || isAllowedShortKey);

            if (isException) event.preventDefault();
          }
        };

        return (
          <ReactQuill
            ref={quillRef}
            readOnly={readOnly}
            placeholder={readOnly ? '' : placeholder}
            className={styles.editor}
            formats={[
              'align',
              'blockquote',
              'bold',
              'code',
              'code-block',
              'color',
              'direction',
              'header',
              'indent',
              'italic',
              'list',
              'size',
              'strike',
              'underline',
              'link',
            ]}
            defaultValue={content}
            scrollingContainer={scrollingContainer}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onChangeSelection={handleChangeSelection}
            onFocus={handleOnFocus}
            onKeyDown={handleKeyDown}
          >
            <div
              className={clsx(
                'sembly-editor notranslate',
                isRTLDirected || isRTLDetected ? 'ql-direction-rtl' : '',
              )}
            />
          </ReactQuill>
        );
      },
      // there is no need to re-render this in order not to lose focus during content editing
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [readOnly],
    );

    const toolbarItems = [
      {
        label: 'Bold',
        icon: <FormatBoldIcon fontSize="small" />,
        formatType: 'bold',
        formatValue: true,
        primary: true,
        gutter: false,
      },
      {
        label: 'Italic',
        icon: <FormatItalicIcon fontSize="small" />,
        formatType: 'italic',
        formatValue: true,
        primary: true,
        gutter: false,
      },
      {
        label: 'Underline',
        icon: <FormatUnderlinedIcon fontSize="small" />,
        formatType: 'underline',
        formatValue: true,
        primary: true,
        gutter: false,
      },
      {
        label: 'Strikethrough',
        icon: <FormatStrikethroughIcon fontSize="small" />,
        formatType: 'strike',
        formatValue: true,
        primary: true,
        gutter: true,
      },
      {
        label: 'Bulleted list',
        icon: <FormatListBulletedIcon fontSize="small" />,
        formatType: 'list',
        formatValue: 'bullet',
        primary: false,
        gutter: false,
      },
      {
        label: 'Numbered list',
        icon: <FormatListNumberedIcon fontSize="small" />,
        formatType: 'list',
        formatValue: 'ordered',
        primary: false,
        gutter: true,
      },
      {
        label: 'H1',
        icon: <FormatHeader1Icon fontSize="small" />,
        formatType: 'header',
        formatValue: 1,
        primary: false,
        gutter: false,
      },
      {
        label: 'H2',
        icon: <FormatHeader2Icon fontSize="small" />,
        formatType: 'header',
        formatValue: 2,
        primary: false,
        gutter: false,
      },
      {
        label: 'H3',
        icon: <FormatHeader3Icon fontSize="small" />,
        formatType: 'header',
        formatValue: 3,
        primary: false,
        gutter: false,
      },
      {
        label: 'Blockquote',
        icon: <FormatQuoteIcon fontSize="small" />,
        formatType: 'blockquote',
        formatValue: true,
        primary: true,
        gutter: false,
      },
    ];
    /* #endregion */

    return (
      <div
        onFocus={onFocus}
        className={clsx(
          styles.root,
          reverseLayout && 'reversed',
          stickyToolbar && 'sticky',
          readOnly && 'readonly',
          isRTLDirected && 'rtl',
        )}
      >
        {!hideToolbar && (
          <Toolbar
            disableGutters
            variant="dense"
            className={clsx(
              styles.toolbar,
              isFocused && 'focused',
              reverseLayout && 'reversed',
              persistToolbar && 'persist',
              stickyToolbar && 'sticky',
              isRTLDirected && 'rtl',
            )}
          >
            <Box flex={1} display="flex" flexWrap={reverseLayout ? 'nowrap' : 'wrap'}>
              {toolbarItems.map((item, index) =>
                hideSecondaryToolbarItems && !item.primary ? null : (
                  <Fragment key={`${item.formatType}-${index}`}>{renderToolbarItem(item)}</Fragment>
                ),
              )}
            </Box>

            <Box flex={0}>{isSaving && <SaveIcon color="disabled" />}</Box>
          </Toolbar>
        )}
        <div
          ref={ref}
          style={{ flex: 1, overflow: 'hidden' }}
          dir={isRTLDetected ? 'rtl' : 'ltr'}
          onDrop={(e) => e.preventDefault()}
          onPaste={handlePaste}
        >
          {renderEditor}
        </div>
      </div>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&.sticky': {
      overflow: 'visible',
    },
    '&.reversed': {
      flexDirection: 'column-reverse',
    },
    '&.readonly *': {
      cursor: 'default',
    },
    '&.rtl .ql-editor': {
      direction: 'rtl',
      textAlign: 'right',
    },
  },
  editor: {
    height: '100%',
    display: 'flex',
    '& .ql-hidden': {
      display: 'none',
    },
    '& .ql-toolbar': {
      display: 'none',
    },
    '& .ql-container': {
      flex: 1,
      border: 0,
    },
    '& .ql-editor': {
      position: 'relative',
      boxSizing: 'border-box',
      height: '100%',
      outline: 'none',
      overflowY: 'auto',
      padding: theme.spacing(2, 1),
      tabSize: 4,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      '&.ql-blank::before': {
        color: theme.palette.text.secondary,
        content: 'attr(data-placeholder)',
        top: theme.spacing(2),
        left: theme.spacing(1),
        right: theme.spacing(1),
        pointerEvents: 'none',
        position: 'absolute',
      },
      '& img[src^="https://cdnjs.cloudflare.com"]': {
        width: '30px',
        verticalAlign: 'bottom',
        marginRight: 8,
      },
    },
    '& .ql-editor.ql-blank::before': {
      fontStyle: 'normal',
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.secondary,
    },
    '& .ql-clipboard': {
      left: -100000,
      height: 1,
      overflowY: 'hidden',
      position: 'absolute',
      top: '50%',
    },
  },
  toolbar: {
    flex: 0,
    top: -48,
    '&.persist': {
      top: 0,
    },
    '&.sticky': {
      position: 'sticky',
      top: theme.spacing(0),
      background: theme.palette.background.paper,
      zIndex: 1,
    },
    '&.reversed': {
      top: 'auto',
      bottom: -48,
    },
    '&.focused': {
      transition: 'all 0.2s ease-in',
      top: 0,
      opacity: 1,
      '&.reversed': {
        top: 'auto',
        bottom: 0,
      },
    },
    '&.rtl': {
      direction: 'rtl',
    },
  },
}));

export default RichTextEditor;
