import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// Sembly UI
import {
  GenericDialog,
  formatInTimeZone,
  getMicrophonesList,
  getSelectedMicrophone,
} from '@sembly-ui';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// Material Icons
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import SettingsIcon from '@material-ui/icons/SettingsVoiceSharp';
// Sembly UI
import { LanguageSelects } from '@sembly-ui';
// App Shared
import RecorderSettingsBox from '@shared/components/RecorderSettingsBox';
import { Routes } from '@shared/enums';
import { useUserInterface, useUserContext } from '@shared/hooks';
// GraphQL Types
import { Languages } from '@gql-types';

export interface SubmitConfirmationProps {
  open: boolean;
  processing?: boolean;
  meetingTitle?: string | null;
  onCancel: () => void;
  onSubmit: (
    data: { title: string; primaryLang: Languages; additionalLang: Languages | null } | null,
  ) => void;
}

export const StartConfirmation: React.VFC<SubmitConfirmationProps> = ({
  open,
  meetingTitle = `Audio Recording ${formatInTimeZone(new Date(), 'PP p')}`,
  onCancel,
  onSubmit,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const user = useUserContext();
  const { update } = useUserInterface();

  const { register, handleSubmit, reset, formState, setValue } = useForm<{
    title: string;
    primaryLang: Languages;
    additionalLang: Languages | null;
  }>();

  const [isCheckingAudio, setIsCheckingAudio] = useState(false);
  const [isVirtualDevice, setIsVirtualDevice] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleOpenInstaInvite = () => {
    update({ isOpenAgentCallPlanner: true });
    onCancel();
  };

  const handleOpenCalendarSettings = () => {
    update({ isOpenRecorder: false });
    history.push(Routes.SettingsCalendars);
    onCancel();
  };

  const handleClickOnCheckAudio = () => {
    setIsCheckingAudio(true);
  };

  const handleFailureCheckAudio = (msg: string) => {
    toast.warn(msg);
    setIsCheckingAudio(false);
  };

  const handleChangeMicrophone = (deviceId: string, isVirtual: boolean) => {
    setIsVirtualDevice(isVirtual);
  };

  const handleSubmitLiveMeeting = handleSubmit(({ title, additionalLang, primaryLang }) => {
    if (title) onSubmit({ title, primaryLang, additionalLang });
  });

  const handleCloseDialog = () => {
    onCancel();
  };
  /* #endregion */

  /* #region  Effects */
  // Detecting a virtual microphone in the device list
  useEffect(() => {
    getMicrophonesList().then((devices) => {
      const selectedDeviceId = getSelectedMicrophone()?.deviceId || 'default';
      const selectedDevice = devices.find((device) => device.deviceId === selectedDeviceId);
      const selectedDeviceLabel = selectedDevice?.label.toLowerCase() ?? '';
      const isVirtualDeviceSelected = selectedDeviceLabel.includes('virtual');
      setIsVirtualDevice(isVirtualDeviceSelected);
    });
  }, []);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [open, reset]);
  /* #endregion */

  /* #region  Render Helpers */
  const errors = formState.errors;
  const isMaxLengthError = errors.title && errors.title.type === 'maxLength';
  const languageSettings = user.data?.me?.workspace?.languageSettings;
  const primaryLang = languageSettings?.primaryLanguage || Languages.ENGLISH;
  const additionalLang = languageSettings?.additionalLanguage || null;
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={handleCloseDialog}
    >
      <Box component={DialogContent} mt={3} mb={4}>
        <Box mb={5} textAlign="center">
          <Typography gutterBottom variant="h2">
            Audio Recording{' '}
            <Link
              color="textSecondary"
              target="_blank"
              rel="noopener noreferrer"
              title="Help"
              href="https://helpdesk.sembly.ai/hc/en-us/articles/4420271153297-Sembly-Voice-Recorder"
              style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
            >
              <QuestionMarkIcon fontSize="inherit" />
            </Link>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <span>* To record an online meeting, use </span>
            <Link onClick={handleOpenCalendarSettings}>calendar integration</Link>
            <span> or </span>
            <Link onClick={handleOpenInstaInvite}>instant invitation</Link> of Sembly agent
          </Typography>
        </Box>
        <form onSubmit={handleSubmitLiveMeeting}>
          <Box display="flex">
            <Box flex="1">
              <TextField
                fullWidth
                required
                autoFocus
                variant="outlined"
                label="Meeting Title"
                defaultValue={meetingTitle}
                error={isMaxLengthError}
                {...register('title', { required: true, maxLength: 255 })}
              />
            </Box>
            {!isCheckingAudio && (
              <Box flex="0" pl={1}>
                <Tooltip title="Check your audio">
                  <Button
                    variant="outlined"
                    style={{ height: '100%' }}
                    aria-label="Check recording device"
                    onClick={handleClickOnCheckAudio}
                  >
                    <SettingsIcon color="action" />
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>

          {isMaxLengthError && (
            <Box mt={1} ml={2}>
              <FormHelperText error>
                Please fill in this field. The maximum length is 255 characters.
              </FormHelperText>
            </Box>
          )}

          {isCheckingAudio ? (
            <Box mt={2}>
              <RecorderSettingsBox
                onChangeDevice={handleChangeMicrophone}
                onFailed={handleFailureCheckAudio}
              />
              {isVirtualDevice && (
                <Box mt={1} width="100%" textAlign="center">
                  <Typography variant="body2" color="secondary">
                    Virtual microphones may not work. Please make sure you're using the working mic
                    for recording
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <>
              {isVirtualDevice && (
                <Box mt={2} width="100%" textAlign="center">
                  <Typography variant="body2" color="textSecondary">
                    <span>Virtual microphones may not work. </span>
                    <Link onClick={handleClickOnCheckAudio}>
                      Please make sure you're using the working mic for recording
                    </Link>
                  </Typography>
                </Box>
              )}
            </>
          )}

          <Box mt={3}>
            <LanguageSelects
              primaryLanguage={primaryLang}
              additionalLanguage={additionalLang}
              onChange={(primaryLang, additionalLang) => {
                setValue('primaryLang', primaryLang);
                setValue('additionalLang', additionalLang);
              }}
            />
          </Box>

          <Box display="flex" justifyContent="center" mt={5}>
            <Box mr={1} minWidth="20ch">
              <Button
                fullWidth
                disableElevation
                type="submit"
                color="primary"
                variant="contained"
                aria-label="Start recording"
              >
                <Typography noWrap component="span">
                  Start Recording
                </Typography>
              </Button>
            </Box>
            <Button variant="outlined" aria-label="Cancel recording" onClick={handleCloseDialog}>
              <Typography noWrap component="span">
                Cancel Recording
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </GenericDialog>
  );
};

export default StartConfirmation;
