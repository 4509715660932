import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const PdfType = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23077 13.7692C6.63538 13.7692 7.76923 12.6354 7.76923 11.2308V9.53846C7.76923 8.13385 6.63538 7 5.23077 7H2.69231C1.76154 7 1 7.76154 1 8.69231V15.8846C1 16.5785 1.57538 17.1538 2.26923 17.1538C2.96308 17.1538 3.53846 16.5785 3.53846 15.8846V13.7692H5.23077ZM13.6923 17.1538C15.0969 17.1538 16.2308 16.02 16.2308 14.6154V9.53846C16.2308 8.13385 15.0969 7 13.6923 7H10.3077C9.83385 7 9.46154 7.37231 9.46154 7.84615V16.3077C9.46154 16.7815 9.83385 17.1538 10.3077 17.1538H13.6923ZM21.7308 9.53846C22.4246 9.53846 23 8.96308 23 8.26923C23 7.57538 22.4246 7 21.7308 7H19.6154C18.6846 7 17.9231 7.76154 17.9231 8.69231V15.8846C17.9231 16.5785 18.4985 17.1538 19.1923 17.1538C19.8862 17.1538 20.4615 16.5785 20.4615 15.8846V13.7692H21.7308C22.4246 13.7692 23 13.1938 23 12.5C23 11.8062 22.4246 11.2308 21.7308 11.2308H20.4615V9.53846H21.7308ZM5.23077 11.2308H3.53846V9.53846H5.23077V11.2308ZM13.6923 14.6154H12V9.53846H13.6923V14.6154Z"
    />
  </SvgIcon>
);

export default PdfType;
