import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const CheckoutModuleContainer = lazyWithPreload(
  () => import('./containers/CheckoutModuleContainer'),
);

export const CheckoutModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Checkout,
    render: () => <CheckoutModuleContainer />,
  },
};

export default CheckoutModule;
