import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const MyWorkstreamsModuleContainer = lazyWithPreload(
  () => import('./containers/MyWorkstreamsModuleContainer'),
);

export const MyWorkstreamsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.MyWorkstreams,
    render: () => <MyWorkstreamsModuleContainer />,
  },
};

export default MyWorkstreamsModule;
