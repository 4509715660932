import { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
// Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import { COMPANY_MATURITY_VALUE_MAPPING, COMPANY_SIZE_VALUE_MAPPING } from '@shared/constants';
import { graphErrorHorsemen } from '@shared/utils';
// App Shared Assets
import { ReactComponent as CompanyIndustryIcon } from '@shared/assets/icon-company-industry-48.svg';
import { ReactComponent as CompanyProfileIcon } from '@shared/assets/icon-company-profile-48.svg';
// GraphQL Queries and Types
import caseIndustriesQuery from '@shared/queries/GetCaseIndustries.graphql';
import caseSpecIndustriesQuery from '@shared/queries/GetCaseSpecIndustries.graphql';
import caseSubIndustriesQuery from '@shared/queries/GetCaseSubIndustries.graphql';
import manageUseCaseMutations from '@shared/queries/ManageWorkspaceUseCase.graphql';
import {
  CompanyMaturityEnum,
  CompanySizeEnum,
  GetCaseIndustries,
  GetCaseSpecIndustries,
  GetCaseSpecIndustriesVariables,
  GetCaseSubIndustries,
  GetCaseSubIndustriesVariables,
  ManageWorkspaceUseCase,
  ManageWorkspaceUseCaseVariables,
} from '@gql-types';

type Steps = 'industry' | 'company';

export interface SemblianWorkspaceOnboardingProps {
  disabled?: boolean;
  onSubmitComplete: () => void;
}

export const SemblianWorkspaceOnboarding: React.VFC<SemblianWorkspaceOnboardingProps> = ({
  disabled = false,
  onSubmitComplete,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [step, setStep] = useState<Steps>('industry');
  const [caseIndustryValue, setCaseIndustryValue] = useState<string>('');
  const [caseSubIndustryValue, setCaseSubIndustryValue] = useState<string>('');
  const [caseSpecIndustryValue, setCaseSpecIndustryValue] = useState<string>('');
  const [companyMaturityValue, setCompanyMaturityValue] = useState<CompanyMaturityEnum | ''>('');
  const [companySizeValue, setCompanySizeValue] = useState<CompanySizeEnum | ''>('');

  const { data: caseIndustriesData, loading: isCaseIndustriesLoading } =
    useQuery<GetCaseIndustries>(caseIndustriesQuery, {
      fetchPolicy: 'network-only',
    });

  const [
    getCaseSubIndustries,
    { data: caseSubIndustriesData, loading: isCaseSubIndustriesLoading },
  ] = useLazyQuery<GetCaseSubIndustries, GetCaseSubIndustriesVariables>(caseSubIndustriesQuery, {
    fetchPolicy: 'network-only',
  });

  const [
    getCaseSpecIndustries,
    { data: caseSpecIndustriesData, loading: isCaseSpecIndustriesLoading },
  ] = useLazyQuery<GetCaseSpecIndustries, GetCaseSpecIndustriesVariables>(caseSpecIndustriesQuery, {
    fetchPolicy: 'network-only',
  });

  const [manageUseCases, { loading: isManagingUseCases }] = useMutation<
    ManageWorkspaceUseCase,
    ManageWorkspaceUseCaseVariables
  >(manageUseCaseMutations);
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnStepButton = (nextStep: Steps) => async () => {
    setStep(nextStep);
  };

  const handleChangeIndustryValue = (_: unknown, value: string | null) => {
    setCaseIndustryValue(value || '');
    setCaseSubIndustryValue('');
    setCaseSpecIndustryValue('');
    getCaseSubIndustries({ variables: { industry: value || '' } });
  };

  const handleChangeSubIndustryValue = (_: unknown, value: string | null) => {
    setCaseSubIndustryValue(value || '');
    setCaseSpecIndustryValue('');
    getCaseSpecIndustries({ variables: { subIndustry: value || '' } });
  };

  const handleChangeSpecIndustryValue = (_: unknown, value: string | null) => {
    setCaseSpecIndustryValue(value || '');
  };

  const handleChangeCompanySize = (_: unknown, newValue: { value: string; label: string }) => {
    setCompanySizeValue(newValue.value as CompanySizeEnum);
  };

  const handleChangeCompanyMaturity = (_: unknown, newValue: { value: string; label: string }) => {
    setCompanyMaturityValue(newValue.value as CompanyMaturityEnum);
  };

  const handleSubmitData = async () => {
    if (companyMaturityValue === '' || companySizeValue === '') {
      throw new Error('Company maturity and size are required.');
    }

    const result = await manageUseCases({
      variables: {
        input: {
          industry: caseIndustryValue,
          subIndustry: caseSubIndustryValue,
          specIndustry: caseSpecIndustryValue,
          companyMaturity: companyMaturityValue,
          companySize: companySizeValue,
        },
      },
      optimisticResponse: {
        manageWorkspaceUseCaseData: {
          __typename: 'ManageWorkspaceUseCaseDataMutationPayload',
          success: true,
          errors: [],
          workspaceUseCase: {
            __typename: 'WorkspaceInsightsUseCaseType',
            id: new Date().getTime().toString(),
            industry: caseIndustryValue,
            subIndustry: caseSubIndustryValue,
            specIndustry: caseSpecIndustryValue,
            companyMaturity: companyMaturityValue,
            companySize: companySizeValue,
          },
        },
      },
    });

    if (!result.data?.manageWorkspaceUseCaseData?.success) {
      graphErrorHorsemen(result.data?.manageWorkspaceUseCaseData?.errors);
      setStep('industry'); // Reset step to industry
    } else {
      onSubmitComplete();
    }
  };
  /* #endregion */

  useEffect(() => {
    if (!caseIndustriesData?.myWorkspace?.insightsUseCase) return;

    const { industry, subIndustry, specIndustry, companyMaturity, companySize } =
      caseIndustriesData.myWorkspace.insightsUseCase;

    setCaseIndustryValue(industry);
    setCaseSubIndustryValue(subIndustry);
    setCaseSpecIndustryValue(specIndustry);
    setCompanyMaturityValue(companyMaturity);
    setCompanySizeValue(companySize);

    getCaseSubIndustries({ variables: { industry } });
    getCaseSpecIndustries({ variables: { subIndustry } });
  }, [
    caseIndustriesData?.myWorkspace?.insightsUseCase,
    getCaseSpecIndustries,
    getCaseSubIndustries,
  ]);

  return (
    <Box textAlign="left">
      <Box p={2} my={4} textAlign="center">
        <Typography gutterBottom variant="h1">
          Get Semblian 2.0
        </Typography>
        <Typography variant="subtitle1">
          Answer few questions to personalize Semblian 2.0 experience
        </Typography>
      </Box>

      <Accordion expanded={step === 'industry'} classes={{ root: styles.accordeon }}>
        <AccordionSummary classes={{ content: styles.summaryContent, root: styles.summaryRoot }}>
          <CompanyIndustryIcon />
          <Box ml={2}>
            <Typography gutterBottom variant="h6">
              Company Industry
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Indicate the industry details of your company
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box ml={8} width="100%">
            <Box pb={1} display="flex" flexDirection="column" gridGap={16}>
              <div>
                <Typography gutterBottom variant="body1">
                  <b>Company Industry</b>
                </Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={caseIndustryValue}
                  options={caseIndustriesData?.useCaseIndustries || []}
                  classes={{ input: styles.textfield }}
                  noOptionsText="No industries available"
                  loading={isCaseIndustriesLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Select your industry"
                      classes={{ root: styles.textfield }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {isCaseIndustriesLoading && (
                              <InputAdornment position="end">
                                <CircularProgress size={20} />
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={handleChangeIndustryValue}
                />
              </div>
              <div>
                <Typography gutterBottom variant="body1">
                  <b>Company Sub Industry</b>
                </Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={caseSubIndustryValue}
                  loading={isCaseSubIndustriesLoading}
                  options={caseSubIndustriesData?.useCaseSubIndustries || []}
                  disabled={!caseIndustryValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Select your sub-industry"
                      classes={{ root: styles.textfield }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {isCaseSubIndustriesLoading && (
                              <InputAdornment position="end">
                                <CircularProgress size={20} />
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={handleChangeSubIndustryValue}
                />
              </div>
              <div>
                <Typography gutterBottom variant="body1">
                  <b>Company Specialization</b>
                </Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={caseSpecIndustryValue}
                  loading={isCaseSpecIndustriesLoading}
                  options={caseSpecIndustriesData?.useCaseSpecIndustries || []}
                  disabled={!caseIndustryValue || !caseSubIndustryValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Select your company specialization"
                      classes={{ root: styles.textfield }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            {isCaseSpecIndustriesLoading && (
                              <InputAdornment position="end">
                                <CircularProgress size={20} />
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={handleChangeSpecIndustryValue}
                />
              </div>
            </Box>
            <Box mt={2}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                disabled={!caseSpecIndustryValue}
                onClick={handleClickOnStepButton('company')}
              >
                <Typography variant="body1">Next</Typography>
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={step === 'company'} classes={{ root: styles.accordeon }}>
        <AccordionSummary classes={{ content: styles.summaryContent, root: styles.summaryRoot }}>
          <CompanyProfileIcon />
          <Box ml={2}>
            <Typography gutterBottom variant="h6">
              Company Profile
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Indicate your company details
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box ml={8} width="100%">
            <Box pb={1} display="flex" flexDirection="column" gridGap={16}>
              <div>
                <Typography gutterBottom variant="body1">
                  <b>Company Size</b>
                </Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={
                    companySizeValue
                      ? {
                          value: companySizeValue,
                          label: COMPANY_SIZE_VALUE_MAPPING[companySizeValue],
                        }
                      : { value: '', label: '' }
                  }
                  options={[
                    {
                      value: CompanySizeEnum.SMALL,
                      label: COMPANY_SIZE_VALUE_MAPPING[CompanySizeEnum.SMALL],
                    },
                    {
                      value: CompanySizeEnum.MIDDLE,
                      label: COMPANY_SIZE_VALUE_MAPPING[CompanySizeEnum.MIDDLE],
                    },
                    {
                      value: CompanySizeEnum.BIG,
                      label: COMPANY_SIZE_VALUE_MAPPING[CompanySizeEnum.BIG],
                    },
                    {
                      value: CompanySizeEnum.LARGE,
                      label: COMPANY_SIZE_VALUE_MAPPING[CompanySizeEnum.LARGE],
                    },
                  ]}
                  getOptionLabel={(option) => option.label}
                  classes={{ input: styles.textfield }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Select company size"
                    />
                  )}
                  onChange={handleChangeCompanySize}
                />
              </div>
              <div>
                <Typography gutterBottom variant="body1">
                  <b>Company Maturity</b>
                </Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  value={
                    companyMaturityValue
                      ? {
                          value: companyMaturityValue,
                          label: COMPANY_MATURITY_VALUE_MAPPING[companyMaturityValue],
                        }
                      : { value: '', label: '' }
                  }
                  options={[
                    {
                      value: CompanyMaturityEnum.EMERGING,
                      label: COMPANY_MATURITY_VALUE_MAPPING[CompanyMaturityEnum.EMERGING],
                    },
                    {
                      value: CompanyMaturityEnum.ESTABLISHED,
                      label: COMPANY_MATURITY_VALUE_MAPPING[CompanyMaturityEnum.ESTABLISHED],
                    },
                    {
                      value: CompanyMaturityEnum.MATURE,
                      label: COMPANY_MATURITY_VALUE_MAPPING[CompanyMaturityEnum.MATURE],
                    },
                  ]}
                  getOptionLabel={(option) => option.label}
                  classes={{ input: styles.textfield }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder="Select company maturity"
                    />
                  )}
                  onChange={handleChangeCompanyMaturity}
                />
              </div>
            </Box>
            <Box mt={2}>
              <Button variant="outlined" onClick={handleClickOnStepButton('industry')}>
                <Typography component="span" variant="body1">
                  Back
                </Typography>
              </Button>
              <Box component="span" mr={1} />
              <Button
                disableElevation
                color="primary"
                variant="contained"
                disabled={
                  !companyMaturityValue || !companySizeValue || isManagingUseCases || disabled
                }
                endIcon={isManagingUseCases && <CircularProgress size={18} color="inherit" />}
                onClick={handleSubmitData}
              >
                <Typography component="span" variant="body1">
                  Next
                </Typography>
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  accordeon: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    cursor: 'default',
    '&:before': {
      content: 'none',
    },
  },
  summaryRoot: {
    cursor: 'default',
  },
  summaryContent: {
    alignItems: 'center',
    cursor: 'default',
  },
  textfield: {
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.grey[200],
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      borderColor: 'transparent !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },
  },
}));
