import createMixins from '@material-ui/core/styles/createMixins';
import createPalette from '@material-ui/core/styles/createPalette';
import createTheme from '@material-ui/core/styles/createTheme';
import { Shadows } from '@material-ui/core/styles/shadows';
import { ThemeOptions } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles/withStyles';

export interface PaletteStatusOptions {
  color: string;
  backgroudColor: { main: string; light: string };
}

export interface AccentColors {
  purple: string;
  purpleHaze1300: string;
  pinkDream1200: string;
  greenHawaii1000: string;
}

/**
 * TypeScript's module augmentation.
 */
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    indication: Palette['primary'];
    surface: Palette['primary'];
    fault: Palette['primary'];
    highlight: Palette['primary'];
    meetingType: PaletteStatusOptions;
    meetingTeam: PaletteStatusOptions;
    accents: AccentColors;
    status: {
      error: PaletteStatusOptions;
      live: PaletteStatusOptions;
      nonscheduled: PaletteStatusOptions;
      limitexceeded: PaletteStatusOptions;
      scheduled: PaletteStatusOptions;
      success: PaletteStatusOptions;
    };
  }
  interface PaletteOptions {
    indication: PaletteOptions['primary'];
    highlight: PaletteOptions['primary'];
    fault: PaletteOptions['primary'];
    surface: PaletteOptions['primary'];
    accents: AccentColors;
    meetingType: PaletteStatusOptions;
    meetingTeam: PaletteStatusOptions;
    status: {
      error: PaletteStatusOptions;
      live: PaletteStatusOptions;
      nonscheduled: PaletteStatusOptions;
      limitexceeded: PaletteStatusOptions;
      scheduled: PaletteStatusOptions;
      success: PaletteStatusOptions;
    };
  }
}

// Interface declaration merging to add custom mixins
declare module '@material-ui/core/styles/createMixins' {
  interface Mixins {
    layout: {
      wide: {
        paddingLeft: CSSProperties;
        padding: CSSProperties;
      };
      default: {
        paddingLeft: CSSProperties;
        padding: CSSProperties;
      };
    };
  }
}

const theme = createTheme();

export const palette = createPalette({
  primary: { main: '#00B3B3', light: '#00cccc', dark: '#008383', contrastText: '#fff' },
  secondary: { main: '#ff6a00', light: '#ff9b3f', dark: '#c43800', contrastText: '#fff' },
  highlight: { main: '#FFBF00', light: '#ffcc32', dark: '#FF9500', contrastText: '#000' },
  indication: { main: '#FFCABF', light: '#ffd5cc', dark: '#FF2B00', contrastText: '#000' },
  fault: { main: '#FFE7DC', light: '#ffd5cc', dark: '#FF2B00', contrastText: '#C42C0A' },
  surface: { main: '#333340', light: '#484853', dark: '#2F3241', contrastText: '#fff' },
  background: {
    paper: '#fff',
    default: '#fafafa',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  accents: {
    purple: '#4651B6',
    purpleHaze1300: '#4D5EC3',
    pinkDream1200: '#B443B0',
    greenHawaii1000: '#009996',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#7f7f7f',
    400: '#303030',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#E6E6E8',
    A700: '#60636F',
  },
  meetingType: {
    backgroudColor: { main: '#FEE4FC', light: '#FEE4FC' },
    color: '#9629A1',
  },
  meetingTeam: {
    backgroudColor: { main: '#E7EBFF', light: '#E7EBFF' },
    color: '#4651B6',
  },
  status: {
    error: {
      color: '#FF9500',
      backgroudColor: {
        main: 'rgba(255, 191, 0, 0.2)',
        light: '#fff9e6',
      },
    },
    live: {
      color: '#F04331',
      backgroudColor: {
        main: 'rgba(240, 67, 49, 0.15)',
        light: '#fff',
      },
    },
    nonscheduled: {
      color: '#808080',
      backgroudColor: {
        main: 'rgba(128, 128, 128, 0.15)',
        light: '#F5F5F5',
      },
    },
    scheduled: {
      color: '#4DA6FF',
      backgroudColor: {
        main: 'rgba(77, 166, 255, 0.2)',
        light: '#EDF6FF',
      },
    },
    success: {
      color: '#29A667',
      backgroudColor: {
        main: 'rgba(41, 166, 103, 0.2)',
        light: '#ffffff',
      },
    },
    limitexceeded: {
      color: '#808080',
      backgroudColor: {
        main: 'rgba(128, 128, 128, 0.15)',
        light: '#f0f0f0',
      },
    },
  },
});

const mixins = createMixins(theme.breakpoints, theme.spacing, {
  layout: {
    default: {
      paddingLeft: {
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(8),
        },
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(8 * 2),
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: theme.spacing(8 * 4),
        },
        [theme.breakpoints.up(1980)]: {
          paddingLeft: theme.spacing(8 * 6),
        },
        [theme.breakpoints.up(2400)]: {
          paddingLeft: theme.spacing(8 * 11),
        },
        [theme.breakpoints.up(3000)]: {
          paddingLeft: theme.spacing(8 * 13),
        },
      },
      padding: {
        padding: theme.spacing(0, 1.5),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 8),
        },
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(0, 8 * 2),
        },
        [theme.breakpoints.up('xl')]: {
          padding: theme.spacing(0, 8 * 4),
        },
        [theme.breakpoints.up(1980)]: {
          padding: theme.spacing(0, 8 * 6),
        },
        [theme.breakpoints.up(2400)]: {
          padding: theme.spacing(0, 8 * 11),
        },
        [theme.breakpoints.up(3000)]: {
          padding: theme.spacing(0, 8 * 13),
        },
      },
    },
    wide: {
      paddingLeft: {
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(4),
        },
        [theme.breakpoints.up('lg')]: {
          paddingLeft: theme.spacing(8),
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: theme.spacing(8 * 2),
        },
        [theme.breakpoints.up(1980)]: {
          paddingLeft: theme.spacing(8 * 4),
        },
        [theme.breakpoints.up(2400)]: {
          paddingLeft: theme.spacing(8 * 6),
        },
        [theme.breakpoints.up(3000)]: {
          paddingLeft: theme.spacing(8 * 8),
        },
      },
      padding: {
        padding: theme.spacing(0, 1.5),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(0, 4),
        },
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(0, 8),
        },
        [theme.breakpoints.up('xl')]: {
          padding: theme.spacing(0, 8 * 2),
        },
        [theme.breakpoints.up(1980)]: {
          padding: theme.spacing(0, 8 * 4),
        },
        [theme.breakpoints.up(2400)]: {
          padding: theme.spacing(0, 8 * 6),
        },
        [theme.breakpoints.up(3000)]: {
          padding: theme.spacing(0, 8 * 8),
        },
      },
    },
  },
});

const shadows: Shadows = [
  'none',
  '0 1px 4px 0 rgba(21, 7, 38, 0.08)',
  '0 2px 10px 0 rgba(21, 7, 38, 0.1)',
  '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
  '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
  '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
];

/**
 * Theme configuration variables.
 */
export const THEME_OPTIONS: ThemeOptions = {
  palette,
  shadows,
  mixins,
  typography: {
    h1: { fontSize: 36, fontWeight: 600 },
    h2: { fontSize: 32, fontWeight: 600 },
    h3: { fontSize: 28, fontWeight: 600 },
    h4: { fontSize: 24, fontWeight: 500 },
    h5: { fontSize: 21, fontWeight: 500 },
    h6: { fontSize: 18, fontWeight: 500, lineHeight: 1.24 },
    body1: { fontSize: 14 },
    body2: { fontSize: 12 },
    subtitle1: { fontSize: '1rem', lineHeight: 1.43 },
  },
  overrides: {
    MuiAvatar: {
      root: {
        fontSize: '1rem',
      },
      colorDefault: {
        color: palette.text.primary,
        backgroundColor: palette.background.paper,
        border: `1px solid ${palette.divider}`,
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2em',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        textAlign: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
      label: {
        textTransform: 'none',
        letterSpacing: 0,
      },
      contained: {
        color: palette.text.primary,
        backgroundColor: palette.background.paper,
        '&:hover': {
          backgroundColor: palette.grey[50],
          boxShadow: shadows[4],
        },
        '&::active': {
          backgroundColor: palette.grey[50],
        },
      },
      containedSizeSmall: {
        padding: '2px 10px',
        lineHeight: 1,
      },
      containedSizeLarge: {
        fontSize: '1rem',
        minHeight: 48,
      },
      outlinedSizeLarge: {
        fontSize: '1rem',
        minHeight: 48,
      },
      outlinedSizeSmall: {
        padding: '2px 10px',
        lineHeight: 1,
      },
    },
    MuiChip: {
      root: {
        color: 'inherit',
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: '2em',
        borderTopLeftRadius: '2em',
        borderTopRightRadius: '2em',
        backgroundColor: 'transparent',
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: palette.primary.main,
        },
        '&.Mui-focused': {
          backgroundColor: 'transparent',
          borderColor: palette.primary.main,
        },
        '&.Mui-error': {
          backgroundColor: 'transparent',
          borderColor: palette.error.main,
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
      input: {
        padding: theme.spacing(2),
        '&:-webkit-autofill': {
          borderRadius: 'inherit',
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: 'inherit',
        },
      },
      inputMarginDense: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    MuiInputAdornment: {
      filled: {
        marginTop: '0 !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '2em',
        backgroundColor: '#f2f4f5',
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: palette.grey[900],
        color: palette.grey[100],
        boxShadow: '0 5px 20px 0 rgb(21 27 38 / 8%)',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiListItemIcon: {
      root: { minWidth: 40 },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '2em',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          borderRadius: '2em',
        },
      },
    },
    MuiTab: {
      root: {
        fontWeight: 400,
        fontSize: '0.875rem',
        textTransform: 'none',
        letterSpacing: 0,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '0.875rem',
        letterSpacing: 0,
      },
      stickyHeader: {
        backgroundColor: palette.background.paper,
      },
    },
  },
};

export default THEME_OPTIONS;
