import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Assets
import { ReactComponent as SemblyIcon } from '../assets/sembly-logo-mark.svg';

export interface PlanStatsUpgradeBannerProps {
  onClick: () => void;
}

export const PlanStatsUpgradeBanner: React.VFC<PlanStatsUpgradeBannerProps> = ({ onClick }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <SemblyIcon />
      </div>
      <div className={styles.label}>
        <Typography component="div" variant="subtitle1" style={{ fontWeight: 500 }}>
          Need more minutes and credits?
        </Typography>
        <Typography component="div" variant="body2">
          Upgrade to any paid plan to extend the limits of your current subscription
        </Typography>
      </div>
      <Button disableElevation color="primary" variant="contained" onClick={onClick}>
        <Typography component="span" variant="body1">
          Upgrade
        </Typography>
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  label: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.1)',
  },
}));

export default PlanStatsUpgradeBanner;
