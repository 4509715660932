import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';

export interface SuspenseFallbackProps {
  label?: string;
  bgcolor?: string;
}

export const SuspenseFallback: React.VFC<SuspenseFallbackProps> = ({ label = 'Loading' }) => {
  const { palette } = useTheme();
  const bgcolor = alpha(palette.background.paper, 0.85);

  return (
    <Box
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bgcolor={bgcolor}
      zIndex={99}
    >
      <CircularProgress />
      <Box mt={2}>
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuspenseFallback;
