import { useEffect } from 'react';
import { toast } from 'react-toastify';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
// Other
import { GenericDialog, useMediaDevices } from '@sembly-ui';
import { ReactComponent as ConnectorImage } from '@shared/assets/art-connector.svg';

export interface DisconnectedMicrophoneProps {
  open: boolean;
  onClose: () => void;
}

export const DisconnectedMicrophone: React.VFC<DisconnectedMicrophoneProps> = ({
  open,
  onClose,
}) => {
  const [deviceList] = useMediaDevices({ disabled: !open });

  useEffect(() => {
    if (open && deviceList?.length) {
      onClose();
      toast.success('Microphone connected successfully');
    }
  }, [open, deviceList, onClose]);

  return (
    <GenericDialog
      dialogProps={{
        open: open,
        fullWidth: true,
        maxWidth: 'xs',
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box mt={6} mb={4} textAlign="center">
          <ConnectorImage />
          <Box mb={3} />
          <Typography gutterBottom variant="h5" color="primary">
            Connect your microphone
          </Typography>
          <Typography variant="body1">
            Sorry, we couldn't detect your microphone. Please make sure your microphone is
            connected.
          </Typography>
        </Box>
        <Box mb={6} textAlign="center">
          <Button
            disableElevation
            variant="outlined"
            color="primary"
            aria-label="Dismiss dialog"
            onClick={onClose}
          >
            Dismiss
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

export default DisconnectedMicrophone;
