export const INSIGHT_COLORS = [
  { accent: '#F74C01', background: '#FFE7DC', color: '#B3210D' },
  { accent: '#FCBB00', background: '#FFEBAD', color: '#9F3C00' },
  { accent: '#009996', background: '#D3F3F1', color: '#006575' },
  { accent: '#358DD8', background: '#D9F0FE', color: '#1B5BAD' },
  { accent: '#576ACE', background: '#E7EBFF', color: '#4651B6' },
  { accent: '#C152B8', background: '#FEE4FC', color: '#9629A1' },
];

export default INSIGHT_COLORS;
