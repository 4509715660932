import { lazyWithRetry } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

const GuestMeetingModuleContainer = lazyWithRetry(
  () => import('./containers/GuestMeetingModuleContainer'),
);

export const GuestMeetingModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.GuestMeeting,
    render: () => <GuestMeetingModuleContainer />,
  },
};

export default GuestMeetingModule;
