// Material UI
import Fade from '@material-ui/core/Fade';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/LinkSharp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import PinIcon from '../icons/Pin';
import PinOutline from '../icons/PinOutline';

export interface KeyItemMenuDesktopProps {
  allowPinning: boolean;
  anchorEl: HTMLElement | null;
  isActiveKeyItem: boolean;
  isAuthorizedToEdit: boolean;
  isPinnedKeyItem?: boolean;
  hasTranscriptionItem: boolean;
  onClickOnCopyContent: () => void;
  onClickOnCopyLink: () => void;
  onClickOnPin?: () => void;
  onClickOnToggleActivity: (isActive: boolean) => void;
  onClickOnTranscriptLink?: () => void;
  onClose: () => void;
  onMouseEnter?: () => void;
}

type EventType =
  | 'pin'
  | 'copy-link'
  | 'copy-content'
  | 'jump-transcript'
  | 'make-active'
  | 'make-inactive';

export const KeyItemMenuDesktop: React.VFC<KeyItemMenuDesktopProps> = ({
  allowPinning,
  anchorEl,
  isActiveKeyItem,
  isAuthorizedToEdit,
  isPinnedKeyItem,
  hasTranscriptionItem,
  onClickOnCopyContent,
  onClickOnCopyLink,
  onClickOnPin,
  onClickOnToggleActivity,
  onClickOnTranscriptLink = () => null,
  onClose,
  onMouseEnter = () => null,
}) => {
  const styles = useStyles();

  /* #region  Handlers */
  const handleClickOnMenuItem = (eventType: EventType) => () => {
    onClose();
    // prettier-ignore
    switch (eventType) {
        case 'pin': onClickOnPin && onClickOnPin(); break;
        case 'copy-link': onClickOnCopyLink(); break;
        case 'copy-content': onClickOnCopyContent(); break;
        case 'jump-transcript': onClickOnTranscriptLink(); break;
        case 'make-active': onClickOnToggleActivity(true); break;
        case 'make-inactive': onClickOnToggleActivity(false); break;
      }
  };
  /* #endregion */

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      container={anchorEl}
      placement={'top-end'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onClose}
      className={styles.root}
    >
      <Fade in={!!anchorEl} timeout={!!anchorEl ? 20 : 0}>
        <MenuList className={styles.list}>
          {isAuthorizedToEdit && allowPinning && (
            <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('pin')}>
              <Tooltip title={isPinnedKeyItem ? 'Unpin' : 'Pin'} placement="top">
                <ListItemIcon className={styles.icon}>
                  {isPinnedKeyItem ? (
                    <PinIcon fontSize="small" />
                  ) : (
                    <PinOutline fontSize="small" />
                  )}
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}
          <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('copy-link')}>
            <Tooltip title="Copy link" placement="top">
              <ListItemIcon className={styles.icon}>
                <LinkIcon fontSize="small" />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
          <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('copy-content')}>
            <Tooltip title="Copy content" placement="top">
              <ListItemIcon className={styles.icon}>
                <FileCopyIcon fontSize="small" />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
          {hasTranscriptionItem && (
            <MenuItem
              dense
              className={styles.item}
              onClick={handleClickOnMenuItem('jump-transcript')}
            >
              <Tooltip title="Go to transcript" placement="top">
                <ListItemIcon className={styles.icon}>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}
          {isAuthorizedToEdit && isActiveKeyItem && (
            <MenuItem
              dense
              className={styles.item}
              onClick={handleClickOnMenuItem('make-inactive')}
            >
              <Tooltip title="Make inactive" placement="top">
                <ListItemIcon className={styles.icon}>
                  <CloseIcon fontSize="small" />
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}
          {isAuthorizedToEdit && !isActiveKeyItem && (
            <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('make-active')}>
              <Tooltip title="Make active" placement="top">
                <ListItemIcon className={styles.icon}>
                  <RestoreIcon fontSize="small" />
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}
        </MenuList>
      </Fade>
    </Popper>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(8),
    },
    list: {
      position: 'relative',
      zIndex: 2,
      top: theme.spacing(1),
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
    item: {
      padding: theme.spacing(1),
    },
    icon: {
      minWidth: 0,
    },
  };
});

export default KeyItemMenuDesktop;
