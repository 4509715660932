import pluralize from 'pluralize';

/**
 * Converts the seconds to a human-readable format.
 * @returns The formatted string representing the time
 */
export const convertSecondsToString = (
  value: number,
  template: string = '%value% %label%',
): string => {
  if (!value || value < 0) return template.replace('%value%', '0').replace('%label%', 'minutes');

  if (value > 60000) {
    // If the value is greater than 1000 seconds, format it as hours
    return template.replace('%value%', `${Math.floor(value / 3600)}`).replace('%label%', 'hours');
  }

  return value < 60
    ? template.replace('%value%', 'less than 1').replace('%label%', 'minute')
    : template
        .replace('%value%', `${Math.floor(value / 60)}`)
        .replace('%label%', `${pluralize('minute', Math.floor(value / 60))}`);
};

export default convertSecondsToString;
