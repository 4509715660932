// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import { MeetingStatuses as Status } from '../types';
import transcriptImage from '../assets/icon-transcript-64.svg';
import noContentImage from '../assets/icon-no-content-64.svg';
import { getFailureReason } from '../utils';

export interface MeetingPlaceholderTranscriptProps {
  failureReason: string | null;
  isManualRecording: boolean;
  meetingStatus: Status;
}

export const MeetingPlaceholderTranscript: React.VFC<MeetingPlaceholderTranscriptProps> = ({
  failureReason,
  isManualRecording,
  meetingStatus,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isFailedStatus = meetingStatus === Status.failed;

  return (
    <div className={styles.placeholder}>
      <Box mt={isSmallScreen ? 3 : 6} mb={isSmallScreen ? 3 : 5}>
        <img
          alt="Meeting Transcription"
          width={isSmallScreen ? 42 : 64}
          height={isSmallScreen ? 42 : 64}
          src={isFailedStatus ? noContentImage : transcriptImage}
        />
      </Box>
      <Typography gutterBottom variant={isSmallScreen ? 'h6' : 'h4'}>
        {isFailedStatus ? 'No Content' : 'No transcription'}
      </Typography>
      <Typography
        variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
        color="textSecondary"
        align="center"
      >
        {isFailedStatus
          ? getFailureReason(failureReason, isManualRecording)
          : 'No transcription available for this meeting'}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
}));

export default MeetingPlaceholderTranscript;
