import { useState, useEffect } from 'react';
// Material UI
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import { GenericDialog } from '../components';

export interface RenameModalDialogProps {
  defaultValue: string;
  title?: string;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const RenameModalDialog: React.VFC<RenameModalDialogProps> = ({
  defaultValue,
  title = 'Rename',
  onClose,
  onSubmit,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState<string>('');

  /* #region  Handlers */
  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    if (value.length < 1 || value.length > 120) return;
    onSubmit(value);
    onClose();
  };
  /* #endregion */

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <GenericDialog
      title={title}
      dialogProps={{
        open: true,
        fullWidth: true,
        maxWidth: 'sm',
        fullScreen: isSmallScreen,
        classes: { paper: styles.paper },
        onClick: (e) => e.stopPropagation(),
      }}
      onClose={handleCancel}
    >
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          value={value}
          error={value.length < 1 || value.length > 120}
          inputProps={{ maxLength: 120, minLength: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography noWrap variant="body2">
                  {value.length} / 120
                </Typography>
              </InputAdornment>
            ),
          }}
          onChange={(e) => setValue(e.target.value)}
          helperText={
            value.length < 1 ? 'Name is required' : value.length > 120 ? 'Name is too long' : ''
          }
        />
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button variant="outlined" onClick={handleCancel}>
          <Typography variant="body1">Cancel</Typography>
        </Button>
        <Button disableElevation color="primary" variant="contained" onClick={handleSubmit}>
          <Typography variant="body1">Rename</Typography>
        </Button>
      </DialogActions>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.background.default,
  },
  actions: {
    padding: theme.spacing(2, 3),
  },
}));

export default RenameModalDialog;
