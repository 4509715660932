import { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// Lib Shared
import plusIcon from '../assets/icon-plus-20.svg';

export interface MeetingInsightCardProps {
  title: string;
  nextSteps: string;
  deliveriable: string;
  topic: string;
  active?: boolean;
  colorThemeIndex?: number;
  onCopy?: () => void;
  onClickOnActionButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MeetingInsightCard: React.VFC<MeetingInsightCardProps> = ({
  title,
  nextSteps,
  deliveriable,
  topic,
  active = true,
  colorThemeIndex = 0,
  onCopy = () => null,
  onClickOnActionButton,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isExpanded, setIsExpanded] = useState(false);
  /* #endregion */

  const handleCopy = () => {
    onCopy();
  };

  const COLORS = [
    { accent: '#F74C01', background: '#FFE7DC', color: '#B3210D' },
    { accent: '#FCBB00', background: '#FFEBAD', color: '#9F3C00' },
    { accent: '#009996', background: '#D3F3F1', color: '#006575' },
    { accent: '#358DD8', background: '#D9F0FE', color: '#1B5BAD' },
    { accent: '#576ACE', background: '#E7EBFF', color: '#4651B6' },
    { accent: '#C152B8', background: '#FEE4FC', color: '#9629A1' },
  ];

  const dynamicColor = COLORS[colorThemeIndex % COLORS.length]; // pick a color from the list based on the index

  return (
    <div
      className={styles.root}
      style={{
        background: `radial-gradient(100% 100% at 100% 100%, ${dynamicColor.background} 0%, #F7F7F7 60%)`,
      }}
    >
      <div className={styles.accent} style={{ background: dynamicColor.accent }} />
      <Typography paragraph variant="h6">
        {title}
      </Typography>
      <div
        className={styles.chip}
        style={{ background: dynamicColor.background, color: dynamicColor.color }}
      >
        {topic}
      </div>

      {isExpanded && (
        <div className={styles.content}>
          <Typography variant="body1" color="textSecondary">
            <b>Next Steps:</b>
          </Typography>
          <Typography variant="body1">{nextSteps}</Typography>
          <Typography variant="body1" color="textSecondary">
            <b>Deliverable:</b>
          </Typography>
          <Typography variant="body1">{deliveriable}</Typography>
        </div>
      )}

      <div className={`${styles.actions} ${isSmallScreen ? 'mobile' : ''}`}>
        <Box display="flex" flex={1} gridGap={8}>
          <Button
            variant="outlined"
            size="small"
            endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Hide details' : 'Show details'}
          </Button>
          <Button variant="outlined" size="small" onClick={handleCopy}>
            Copy
          </Button>
        </Box>

        <Button
          disableElevation
          color="inherit"
          variant="contained"
          className={styles.button}
          startIcon={<img src={plusIcon} width={20} height={20} alt="Work on this insight" />}
          onClick={onClickOnActionButton}
        >
          <Typography noWrap component="span" variant={isSmallScreen ? 'body2' : 'body1'}>
            Work on this
          </Typography>
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 3,
    width: '100%',
  },
  chip: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    borderRadius: '2em',
  },
  badge: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    marginTop: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    margin: theme.spacing(2, 0),
    alignItems: 'flex-start',
  },
  accent: {
    position: 'absolute',
    top: 24,
    left: -1,
    width: 3,
    height: 24,
    zIndex: 1,
    borderRadius: '2em',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&.mobile': {
      flexDirection: 'column',
      gap: theme.spacing(2),
      alignItems: 'flex-start',
    },
  },
}));

export default MeetingInsightCard;
