export type GetParentPathnameType = (args?: {
  pathname?: string;
  subroutesNumToCut?: number;
}) => string;

/**
 * Extracts the path corresponding to the parent node for a given path.
 * @returns {string} - The original path minus the last individual
 * segment found in it. '/' if the original path corresponds to a top level node.
 */
export const getParentPathname: GetParentPathnameType = (data = {}) => {
  const { pathname = window.location.pathname, subroutesNumToCut = 1 } = data;
  const segments = pathname.split('/');

  return segments.length < 2 ? '/' : segments.slice(0, -subroutesNumToCut).join('/') || '/';
};

export default getParentPathname;
