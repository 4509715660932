import { useState, useEffect, useRef } from 'react';
import { getMicrophonesList } from '../utils';

/* #region  Types */
export type VoiceRecorderStatus = 'IDLE' | 'INIT' | 'RECORDING' | 'PAUSED';

export type VoiceRecorderError =
  | 'UNSUPPORTED_BROWSER'
  | 'PERMISSION_DENIED'
  | 'STREAM_INIT'
  | 'RECORDER_INIT'
  | 'RECORDING_ERROR'
  | 'NO_DEVICE_AVAILABLE';

export type VoiceRecorderWarn = 'NO_IDEAL_DEVICE_AVAILABLE';

export type VoiceRecorderChangeType =
  | 'RECORDING_STARTED'
  | 'RECORDING_STOPPED'
  | 'RECORDING_PAUSED'
  | 'RECORDING_UNPAUSED';

export interface VoiceRecorderConstrains {
  audioTrackConstraints?: MediaTrackConstraints;
  exactDeviceId?: string;
  idealDeviceId?: string;
}

export interface VoiceRecorderStopOptions {
  isForce?: boolean;
  isMaxDurationReached?: boolean;
}

export interface VoiceRecordingChunk {
  blob: Blob;
  endTime: ReturnType<typeof Date.now>;
  isMaxDurationReached: boolean;
  isRecordingCompleted: boolean;
  recordingDurationSeconds: number;
  startTime: ReturnType<typeof Date.now>;
}

export interface VoiceRecordingResult {
  durationSeconds: number;
  endTime: ReturnType<typeof Date.now>;
  isMaxDurationReached: boolean;
  startTime: ReturnType<typeof Date.now>;
}

export type UseVoiceRecorderResult = Readonly<{
  pause: () => void;
  resume: () => void;
  start: (constrains: VoiceRecorderConstrains) => Promise<MediaStream | null>;
  stop: (options?: VoiceRecorderStopOptions) => void;
  status: VoiceRecorderStatus;
  error: VoiceRecorderError | null;
}>;

export interface UseVoiceRecorderOptions {
  chunkDurationSeconds?: number;
  onError?: (errorCode: VoiceRecorderError) => void;
  onWarn?: (warnCode: VoiceRecorderWarn) => void;
}

export type UseVoiceRecorderHook = (
  onDataAvailable: (data: VoiceRecordingChunk) => void,
  onCompleteRecording: (data: VoiceRecordingResult) => void,
  options?: UseVoiceRecorderOptions,
) => UseVoiceRecorderResult;

interface UseVoiceRecorderState {
  mediaStream: MediaStream | null;
  status: VoiceRecorderStatus;
  error: VoiceRecorderError | null;
}
/* #endregion */

/* #region  Constants */
const INITIAL_STATE: UseVoiceRecorderState = {
  mediaStream: null,
  status: 'IDLE',
  error: null,
} as const;
/* #endregion */

export const useVoiceRecorder: UseVoiceRecorderHook = (
  onDataAvailable,
  onCompleteRecording,
  options,
) => {
  const chunkDurationSeconds = options?.chunkDurationSeconds ?? 2 * 60; // 2 minutes

  /* #region  Hooks */
  const [state, setState] = useState(INITIAL_STATE);
  const chunkStartTimeRef = useRef<number>(0);
  const isMountedRef = useRef(false);
  const isRecordingCompletedRef = useRef(false);
  const isRecordingDeletedRef = useRef(false);
  const isMaxDurationReachedRef = useRef(false);
  const recordingStartTimeRef = useRef<number>(0);
  const voiceRecorderRef = useRef<MediaRecorder | null>(null);
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);
  /* #endregion */

  /* #region  Handlers */
  const handleStartRecording = async ({
    audioTrackConstraints = {},
    exactDeviceId,
    idealDeviceId,
  }: VoiceRecorderConstrains): Promise<MediaStream | null> => {
    let mediaStream: MediaStream;

    isRecordingCompletedRef.current = false;
    isRecordingDeletedRef.current = false;
    isMaxDurationReachedRef.current = false;
    audioTrackConstraints.channelCount = audioTrackConstraints?.channelCount ?? 2;
    setState((currentState) => ({ ...currentState, status: 'INIT' }));

    // check browser support for media devices and audio context
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      if (options?.onError) options.onError('UNSUPPORTED_BROWSER');
      setState((currentState) => ({
        ...currentState,
        status: 'IDLE',
        error: 'UNSUPPORTED_BROWSER',
      }));
      return null;
    }

    // check microphone permission
    try {
      const testStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      testStream.getTracks().forEach((track) => track.stop());
    } catch {
      if (options?.onError) options.onError('PERMISSION_DENIED');
      setState((currentState) => ({ ...currentState, status: 'IDLE', error: 'PERMISSION_DENIED' }));
      return null;
    }

    const availableDevices = await getMicrophonesList({ deviceId: exactDeviceId });

    if (!availableDevices.length) {
      if (options?.onError) options.onError('NO_DEVICE_AVAILABLE');
      setState((currentState) => ({
        ...currentState,
        status: 'IDLE',
        error: 'NO_DEVICE_AVAILABLE',
      }));
      return null;
    }

    let idealDevices: MediaDeviceInfo[] = [];
    if (idealDeviceId && !exactDeviceId && options?.onWarn) {
      idealDevices = await getMicrophonesList({ deviceId: idealDeviceId });
      if (!idealDevices.length) {
        options.onWarn('NO_IDEAL_DEVICE_AVAILABLE');
      }
    }

    try {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          ...audioTrackConstraints,
          deviceId: {
            exact: exactDeviceId ? availableDevices.map((mic) => mic.deviceId) : undefined,
            ideal: idealDeviceId ? idealDevices.map((mic) => mic.deviceId) : undefined,
          },
        },
      });
      voiceRecorderRef.current = new MediaRecorder(mediaStream);
    } catch {
      if (options?.onError) options.onError('STREAM_INIT');
      setState((currentState) => ({ ...currentState, status: 'IDLE', error: 'STREAM_INIT' }));
      return null;
    }

    // Request a wake lock
    try {
      wakeLockRef.current = await navigator.wakeLock.request('screen');
    } catch {
      console.warn('Wake Lock request has failed.');
    }

    voiceRecorderRef.current.ondataavailable = ({ data: blob }) => {
      if (isRecordingDeletedRef.current) return;

      const isRecordingCompleted = isRecordingCompletedRef.current;
      const isMaxDurationReached = isMaxDurationReachedRef.current;
      const startTime = chunkStartTimeRef.current || recordingStartTimeRef.current || Date.now();
      const endTime = Date.now();

      const recordingStartTime = recordingStartTimeRef.current || Date.now();
      const recordingCurrentTime = Date.now();
      const recordingDurationSeconds = (recordingCurrentTime - recordingStartTime) / 1000;

      chunkStartTimeRef.current = Date.now();
      onDataAvailable({
        blob,
        startTime,
        endTime,
        recordingDurationSeconds,
        isRecordingCompleted,
        isMaxDurationReached,
      });

      if (isRecordingCompleted) {
        onCompleteRecording({
          startTime: recordingStartTime,
          endTime: recordingCurrentTime,
          durationSeconds: recordingDurationSeconds,
          isMaxDurationReached,
        });
      }
    };

    voiceRecorderRef.current.onstart = () => {
      recordingStartTimeRef.current = Date.now();
      if (!isMountedRef.current) return;
      setState(
        (currentState): UseVoiceRecorderState => ({
          ...currentState,
          mediaStream,
          status: 'RECORDING',
        }),
      );
    };

    voiceRecorderRef.current.onpause = () => {
      if (!isMountedRef.current) return;
      setState(
        (currentState): UseVoiceRecorderState => ({
          ...currentState,
          status: 'PAUSED',
        }),
      );
    };

    voiceRecorderRef.current.onresume = () => {
      if (!isMountedRef.current) return;
      setState(
        (currentState): UseVoiceRecorderState => ({
          ...currentState,
          status: 'RECORDING',
        }),
      );
    };

    voiceRecorderRef.current.onstop = () => {
      const mediaStreamTracks = mediaStream.getTracks();
      mediaStreamTracks.forEach((track) => track.stop());
      if (!isMountedRef.current) return;
      setState(INITIAL_STATE);
    };

    voiceRecorderRef.current.onerror = () => {
      if (!isMountedRef.current) return;
      setState(
        (currentState): UseVoiceRecorderState => ({
          ...currentState,
          error: 'RECORDING_ERROR',
        }),
      );
    };

    voiceRecorderRef.current.start(chunkDurationSeconds * 1000);
    return mediaStream;
  };

  const handlePauseRecording = () => {
    if (voiceRecorderRef.current?.state === 'recording') {
      voiceRecorderRef.current.pause();
    }
  };

  const handleResumeRecording = () => {
    if (voiceRecorderRef.current?.state === 'paused') {
      voiceRecorderRef.current.resume();
    }
  };

  const handleStopRecording = async (options?: VoiceRecorderStopOptions) => {
    const isForce = options?.isForce ?? false;
    const isMaxDurationReached = options?.isMaxDurationReached ?? false;

    if (
      voiceRecorderRef.current?.state === 'recording' ||
      voiceRecorderRef.current?.state === 'paused'
    ) {
      if (isForce) {
        isRecordingDeletedRef.current = true;
      } else {
        isRecordingCompletedRef.current = true;
        isMaxDurationReachedRef.current = isMaxDurationReached;
      }
      voiceRecorderRef.current.stop();

      // Release the lock
      if (!wakeLockRef.current) return;
      try {
        await wakeLockRef.current.release();
      } catch {
        console.warn('Wake Lock release has failed.');
      }
    }
  };
  /* #endregion */

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      handleStopRecording({ isForce: true });
      isMountedRef.current = false;
    };
  }, []);

  return {
    status: state.status,
    error: state.error,
    start: handleStartRecording,
    pause: handlePauseRecording,
    resume: handleResumeRecording,
    stop: handleStopRecording,
  };
};

export default useVoiceRecorder;
