import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Represents a simple chip component
 */
export const PlanStatsChip: React.FC = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography component="span" variant="body2">
        {children}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    background: 'linear-gradient(90deg, #576ACE 0%, #00A69F 100%)',
  },
}));

export default PlanStatsChip;
