import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const WorkspaceAutomationsModuleContainer = lazyWithPreload(
  () => import('./containers/WorkspaceAutomationsModuleContainer'),
);

export const WorkspaceAutomationsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.WorkspaceAutomations,
    render: () => <WorkspaceAutomationsModuleContainer />,
  },
};

export default WorkspaceAutomationsModule;
