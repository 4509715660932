import { useState, useLayoutEffect } from 'react';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { setCookie, getCookie } from 'typescript-cookie';
// Material UI
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { SearchBox, Switch } from '@sembly-ui';
// Icons
import MenuIcon from '@material-ui/icons/Menu';
import ShowEmptyMeetingsIcon from '@shared/icons/ShowEmptyMeetings';
// App Shared
import { AppBar } from '@shared/components';
import { AppBarActions } from '@shared/containers';
import { Routes as R } from '@shared/enums';
import { ga } from '@shared/utils';
import { useUserInterface, MainPageLayoutContext } from '@shared/hooks';
// GraphQL Queries and Types
import searchQuery from '@shared/queries/MeetingsPaginated.graphql';
import { MeetingsPaginated, MeetingsPaginatedVariables, MeetingStatuses } from '@gql-types';
import { APP_DRAWER_WIDTH } from '@shared/configuration';

export interface MainPageLayoutProps {
  color?: 'default' | 'paper';
  layout?: 'default' | 'wide';
}

export const MainPageLayout: React.FC<MainPageLayoutProps> = ({
  children,
  color = 'default',
  layout = 'default',
}) => {
  /* #region  Hooks */
  const { update } = useUserInterface();
  const [showNoContentMeetings, setShowNoContentMeetings] = useState(true);

  // theme
  const styles = useStyles();
  const { palette, breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  // router
  const history = useHistory();
  const { path } = useRouteMatch();

  // apollo
  const [find, { fetchMore, refetch, data, called, networkStatus }] = useLazyQuery<
    MeetingsPaginated,
    MeetingsPaginatedVariables
  >(searchQuery, { notifyOnNetworkStatusChange: true });
  /* #endregion */

  /* #region  Handlers */
  const handleSearch = ({
    search,
    page = 1,
    perPage = 6,
    ...other
  }: MeetingsPaginatedVariables) => {
    if (search && called && refetch) {
      refetch({ search, page, perPage, statuses: [MeetingStatuses.submitted], ...other });
    } else {
      find({
        variables: {
          search,
          page,
          perPage,
          statuses: [MeetingStatuses.submitted],
          ...other,
        },
      });
    }
    ga.event({ category: 'Search', action: 'Type Search Term' });
  };

  const handleDrawerOpen = () => {
    update({ isAppDrawerOpen: true });
  };

  const handleRequestNextSearchPage = () => {
    if (fetchMore && data?.meetingsPaginated?.page) {
      fetchMore({ variables: { page: data.meetingsPaginated.page + 1 } });
      ga.event({ category: 'Search', action: 'Requested Next Search Page' });
    }
  };

  const handleJumpToMeeting = (meetingId: string) => {
    history.push(generatePath(R.Meeting, { meetingId }));
    ga.event({ category: 'Search', action: 'Jump to Search Result' });
  };

  const handleChangeMeetingsVisibility = (e: unknown, checked: boolean) => {
    setShowNoContentMeetings(checked);
    setCookie('showNoContentMeeting', checked ? 'True' : 'False', {
      expires: new Date(2147483647 * 1000), // never expires
    });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const fetchingStatuses = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.fetchMore,
    NetworkStatus.refetch,
  ];

  const background = color === 'default' ? palette.background.default : palette.background.paper;
  const isFetching = fetchingStatuses.includes(networkStatus);
  const meetingsListRoutes = [R.Home, R.SharedWithMe] as string[];
  const isShowMeetingsFilter = meetingsListRoutes.includes(path);
  /* #endregion */

  useLayoutEffect(() => {
    const showNoContentMeeting = getCookie('showNoContentMeeting');
    if (showNoContentMeeting !== undefined) {
      setShowNoContentMeetings(showNoContentMeeting === 'True');
    }
  }, []);

  return (
    <div className={styles.root} style={{ background }}>
      {/* Begin: AppDrawer placeholder */}
      {/* AppDrawer currenty always open on desktops */}
      {!isSmallScreen && <Box flex="0 1 auto" width={APP_DRAWER_WIDTH} />}
      {/* End: AppDrawer placeholder */}
      <div className={styles.content}>
        <AppBar layout={layout}>
          {isSmallScreen && (
            <IconButton onClick={handleDrawerOpen} size="small" className={styles.menuIcon}>
              <MenuIcon />
            </IconButton>
          )}
          <div className={styles.grow}>
            {!isSmallScreen && (
              <SearchBox
                isFetching={isFetching}
                onChangeSearchTerm={handleSearch}
                onSelectSearchResult={handleJumpToMeeting}
                onRequestNextPage={handleRequestNextSearchPage}
                placeholder="Search meetings"
                data={data?.meetingsPaginated?.objects}
                hasNextPage={data?.meetingsPaginated?.hasNext || false}
              />
            )}
          </div>
          <div className={styles.nogrow}>
            <AppBarActions />
          </div>
          {isShowMeetingsFilter && (
            <Tooltip title="Show 'No content' meetings" placement="left">
              <FormControlLabel
                value="start"
                label={
                  <div className={styles.icon}>
                    <ShowEmptyMeetingsIcon color={showNoContentMeetings ? 'inherit' : 'disabled'} />
                  </div>
                }
                labelPlacement="start"
                className={styles.filters}
                control={
                  <Box display="flex" alignItems="center" pl={1}>
                    <Switch
                      size="small"
                      variant="colored"
                      checked={showNoContentMeetings}
                      onChange={handleChangeMeetingsVisibility}
                    />
                  </Box>
                }
              />
            </Tooltip>
          )}
        </AppBar>

        <div className={styles.appBarSpacer} />
        <MainPageLayoutContext.Provider value={{ showNoContentMeetings }}>
          {children}
        </MainPageLayoutContext.Provider>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  loading: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(1),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  grow: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
  },
  nogrow: {
    flexGrow: 0,
    paddingLeft: theme.spacing(2),
  },
  filters: {
    position: 'absolute',
    bottom: -90,
    right: 25,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    borderRadius: 18,
    background: theme.palette.background.paper,
    '& > div': {
      padding: 0,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.status.error.color,
  },
}));

export default MainPageLayout;
