import { Currency } from '../../types';
import { getCurrency } from './GetCurrency';

export function getCurrencies(currencyCodes: string[]): Currency[] {
  const result: Currency[] = [];

  for (const currencyCode of currencyCodes) {
    const currency = getCurrency(currencyCode);
    if (currency) {
      result.push(currency);
    } else {
      throw new Error(`Currency with code ${currencyCode} not found`);
    }
  }

  return result;
}

export default getCurrencies;
