import { SvgIcon } from '@material-ui/core';

export const MeetingColored = () => (
  <SvgIcon viewBox="0 0 48 48">
    <path
      fill="#28a165"
      d="M13.24,22.58A30.19,30.19,0,0,0,26.42,35.76l4.4-4.4a2,2,0,0,1,2-.5A22.72,22.72,0,0,0,40,32a2,2,0,0,1,2,2v7a2,2,0,0,1-2,2A34,34,0,0,1,6,9,2,2,0,0,1,8,7h7a2,2,0,0,1,2,2,22.72,22.72,0,0,0,1.14,7.14,2,2,0,0,1-.5,2Z"
    />
  </SvgIcon>
);

export default MeetingColored;
