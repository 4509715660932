import { useState, forwardRef } from 'react';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';
import { useMutation, useLazyQuery, ApolloCache } from '@apollo/client';
import { DateTime } from 'luxon';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import TodayIcon from '@material-ui/icons/TodayTwoTone';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// Sembly UI
import {
  AttendanceMessageDialog,
  DayCalendarPopover,
  MeetingCard,
  MeetingParticipant,
  MeetingTypeManageDialog,
  PlanIDEnum,
  formatDateWithOrdinalSuffix,
} from '@sembly-ui';
// App Shared
import { ConfirmStopRecording, MeetingLanguageSettings } from '@shared/dialogs';
import { useUserContext } from '@shared/hooks';
import { Routes } from '@shared/enums';
import { graphErrorHorsemen, SemblyGQLErrors } from '@shared/utils';
// GraphQl Queries
import changeMeetingTeamMutation from '@shared/queries/ChangeMeetingTeam.graphql';
import createDeclinedAttendanceItemMutation from '@shared/queries/CreateDeclinedAttendanceItem.graphql';
import deleteDeclinedAttendanceItemMutation from '@shared/queries/DeleteDeclinedAttendanceItem.graphql';
import deleteMeetingMutation from '@shared/queries/DeleteMeeting.graphql';
import editDeclinedAttendanceItemMutation from '@shared/queries/EditDeclinedAttendanceItem.graphql';
import meetingMinutesQuery from '@shared/queries/MeetingMinutes.graphql';
import pauseCallMutation from '@shared/queries/PauseCall.graphql';
import resumeCallMutation from '@shared/queries/ResumeCall.graphql';
import stopCallMutation from '@shared/queries/StopCall.graphql';
import submitLiveMeetingMutation from '@shared/queries/SubmitLiveMeeting.graphql';
import updateAttendingMutation from '@shared/queries/UpdateAttendStatus.graphql';
import updateMeetingTypeMutation from '@shared/queries/ChangeMeetingType.graphql';
import validateTypeChangeQuery from '@shared/queries/ValidateMeetingTypeChange.graphql';
// GraphQl Types
import {
  AgentCallPlatform,
  ApplyToMeetings,
  MeetingStatuses,
  Languages,
} from '@gql-types/globalTypes';
import { ChangeMeetingTeam, ChangeMeetingTeamVariables } from '@gql-types/ChangeMeetingTeam';
import { ChangeMeetingType, ChangeMeetingTypeVariables } from '@gql-types/ChangeMeetingType';
import { CreateDeclinedAttendanceItem, CreateDeclinedAttendanceItemVariables } from '@gql-types/CreateDeclinedAttendanceItem'; // prettier-ignore
import { DeleteDeclinedAttendanceItem, DeleteDeclinedAttendanceItemVariables } from '@gql-types/DeleteDeclinedAttendanceItem'; // prettier-ignore
import { DeleteMeeting, DeleteMeetingVariables } from '@gql-types/DeleteMeeting';
import { EditDeclinedAttendanceItem, EditDeclinedAttendanceItemVariables } from '@gql-types/EditDeclinedAttendanceItem'; // prettier-ignore
import { GenericMeetingOverview } from '@gql-types/GenericMeetingOverview';
import { GenericTeam } from '@gql-types/GenericTeam';
import { ManualRecordType, MeetingTypes } from '@gql-types/globalTypes';
import { PauseCall, PauseCallVariables } from '@gql-types/PauseCall';
import { ResumeCall, ResumeCallVariables } from '@gql-types/ResumeCall';
import { StopCall, StopCallVariables } from '@gql-types/StopCall';
import { SubmitLiveMeeting, SubmitLiveMeetingVariables } from '@gql-types/SubmitLiveMeeting';
import { UpdateAttendStatus, UpdateAttendStatusVariables } from '@gql-types/UpdateAttendStatus';
import { ValidateMeetingTypeChange, ValidateMeetingTypeChangeVariables } from '@gql-types/ValidateMeetingTypeChange'; // prettier-ignore

interface Dictionary<T> {
  [index: string]: T;
}
export interface MeetingCardsProps {
  data: Dictionary<GenericMeetingOverview[]> | null;
  onVerifyAgent: (meetingId: string) => void;
  onJumpToDate: (date: string) => void;
}

type QueryResult =
  | {
      success: boolean;
      errors: SemblyGQLErrors | null[];
      meeting?: GenericMeetingOverview | null;
      meetings?: GenericMeetingOverview[] | null;
    }
  | null
  | undefined;

export const MeetingCards = forwardRef(
  (
    { data, onVerifyAgent, onJumpToDate }: MeetingCardsProps,
    ref: React.LegacyRef<HTMLDivElement> | undefined,
  ) => {
    /* #region  Hooks */
    const styles = useStyles();

    const history = useHistory();
    const isSharedWithMe = !!useRouteMatch(Routes.SharedWithMe);

    const user = useUserContext();

    const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(null);
    const [updatingAttendanceMessage, setUpdatingAttendanceMessage] = useState<GenericMeetingOverview | null>(null); // prettier-ignore
    const [requestedStopMeeting, setRequestedStopMeeting] = useState<GenericMeetingOverview | null>(null); // prettier-ignore
    const [updatingMeetingId, setUpdatingMeetingId] = useState<string | null>(null);
    const [languageSettingsMeetingId, setLanguageSettingsMeetingId] = useState<string | null>(null);
    const [validatingMeetingId, setValidatingMeetingId] = useState<string | null>(null);
    const [nextMeetingType, setNextMeetingType] = useState<{
      meetingId: string;
      type: MeetingTypes;
      language: Languages | null;
      variant: ApplyToMeetings;
    } | null>(null);

    const [deleteMeeting] = useMutation<DeleteMeeting, DeleteMeetingVariables>(deleteMeetingMutation); // prettier-ignore
    const [pauseCall] = useMutation<PauseCall, PauseCallVariables>(pauseCallMutation);
    const [resumeCall] = useMutation<ResumeCall, ResumeCallVariables>(resumeCallMutation);
    const [stopCall] = useMutation<StopCall, StopCallVariables>(stopCallMutation);
    const [submitLiveMeeting] = useMutation<SubmitLiveMeeting, SubmitLiveMeetingVariables>(submitLiveMeetingMutation); // prettier-ignore

    const [updateRecording, { loading: updatingAttending }] = useMutation<
      UpdateAttendStatus,
      UpdateAttendStatusVariables
    >(updateAttendingMutation);

    const [editDeclinedAttendanceItem] = useMutation<
      EditDeclinedAttendanceItem,
      EditDeclinedAttendanceItemVariables
    >(editDeclinedAttendanceItemMutation);

    const [createDeclinedAttendanceItem] = useMutation<
      CreateDeclinedAttendanceItem,
      CreateDeclinedAttendanceItemVariables
    >(createDeclinedAttendanceItemMutation);

    const [deleteDeclinedAttendanceItem] = useMutation<
      DeleteDeclinedAttendanceItem,
      DeleteDeclinedAttendanceItemVariables
    >(deleteDeclinedAttendanceItemMutation);

    const [validateTypeChange] = useLazyQuery<
      ValidateMeetingTypeChange,
      ValidateMeetingTypeChangeVariables
    >(validateTypeChangeQuery, {
      fetchPolicy: 'no-cache',
    });

    const [updateMeetingType] = useMutation<ChangeMeetingType, ChangeMeetingTypeVariables>(
      updateMeetingTypeMutation,
    );

    const [changeMeetingTeam] = useMutation<ChangeMeetingTeam, ChangeMeetingTeamVariables>(
      changeMeetingTeamMutation,
    );
    /* #endregion */

    /* #region  Auxiliary Utils */
    const checkIsUpdatingAttending = (meeting: GenericMeetingOverview) => {
      return (
        updatingAttending &&
        (updatingMeetingId === meeting.seriesId || updatingMeetingId === meeting.id)
      );
    };

    const processQueryResult = (result?: QueryResult) => {
      if (!result?.success) {
        graphErrorHorsemen(result?.errors);
      }
    };

    const updateCachedData = (cache: ApolloCache<unknown>, result: QueryResult) => {
      const updMeetings = result?.meetings || [];
      if (!!result?.meeting) updMeetings.push(result.meeting);

      updMeetings.forEach((entity) => {
        cache.modify({
          id: cache.identify({ __typename: 'DetailedMeetingType', id: entity.id }),
          fields: {
            isRecorder() {
              return entity.isRecorder;
            },
            status() {
              return entity.status;
            },
          },
        });
      });
    };
    /* #endregion */

    /* #region  Handlers */
    const handleConfirmChangeMeetingType = async (variables: ChangeMeetingTypeVariables) => {
      setNextMeetingType(null);

      const targetMeeting = Object.values(data || {})
        .flat()
        .find((m) => m.id === variables.meeting);

      if (!targetMeeting) throw new Error('Meeting not found');

      const { data: result } = await updateMeetingType({
        variables,
        optimisticResponse: {
          changeMeetingType: {
            __typename: 'ChangeMeetingTypeMutationPayload',
            success: true,
            errors: [],
            meetings: [{ ...targetMeeting, meetingType: variables.newMeetingType }],
          },
        },
        // We need to refetch meeting minutes after meeting type change
        // because we need to update meeting minutes content after regeneration
        refetchQueries: [
          { query: meetingMinutesQuery, variables: { meetingId: targetMeeting.id } },
        ],
      });

      if (!result?.changeMeetingType?.success) {
        graphErrorHorsemen(result?.changeMeetingType?.errors);
      }
    };

    const handleChangeMeetingType = async (
      meetingId: string,
      type: MeetingTypes,
      mode: 'all' | 'single',
    ) => {
      const targetMeeting = Object.values(data || {})
        .flat()
        .find((m) => m.id === meetingId);

      if (!targetMeeting) throw new Error('Meeting not found');

      const language =
        (targetMeeting.processingResults?.mainLanguage as unknown as Languages | null) || null;

      setValidatingMeetingId(meetingId);

      const result = await validateTypeChange({
        variables: { meetingId: meetingId, newMeetingType: type },
      });

      setValidatingMeetingId(null);

      const variant = mode === 'all' ? ApplyToMeetings.ALL_MEETINGS : ApplyToMeetings.SINGLE;

      if (result?.data?.validateMeetingTypeChange?.canRegenerateMeetingMinutes) {
        setNextMeetingType({ meetingId, type, language, variant });
      } else {
        const result = await updateMeetingType({
          variables: {
            meeting: meetingId,
            newMeetingType: type,
            newMeetingMinutesLanguage: null,
            regenerateMeetingMinutes: false,
            variant,
          },
          optimisticResponse: {
            changeMeetingType: {
              __typename: 'ChangeMeetingTypeMutationPayload',
              success: true,
              errors: [],
              meetings: [{ ...targetMeeting, meetingType: type }],
            },
          },
          // We need to refetch meeting minutes after meeting type change
          // because we need to update meeting minutes content after regeneration
          refetchQueries: [
            { query: meetingMinutesQuery, variables: { meetingId: targetMeeting.id } },
          ],
        });

        setNextMeetingType(null);

        if (!result?.data?.changeMeetingType?.success) {
          graphErrorHorsemen(result?.data?.changeMeetingType?.errors);
        }
      }
    };

    const handleChangeMeetingTeam = async (
      meetingId: string,
      team: Omit<GenericTeam, 'users'>,
      type: 'single' | 'all',
    ) => {
      const targetMeeting = Object.values(data || {})
        .flat()
        .find((m) => m.id === meetingId);

      if (!targetMeeting) throw new Error('Meeting not found');

      const variant = type === 'all' ? ApplyToMeetings.ALL_MEETINGS : ApplyToMeetings.SINGLE;

      const result = await changeMeetingTeam({
        variables: {
          meetingId: targetMeeting.id,
          teamId: team.id,
          variant,
        },
        optimisticResponse: {
          changeMeetingTeam: {
            __typename: 'ChangeMeetingTeamMutationPayload',
            errors: [],
            success: true,
            meetings: [{ ...targetMeeting, team }],
          },
        },
      });

      if (!result?.data?.changeMeetingTeam?.success) {
        graphErrorHorsemen(result?.data?.changeMeetingTeam?.errors);
      }
    };

    const handleCancelChangeMeetingType = () => {
      setNextMeetingType(null);
    };

    const handleJumpToMeeting = (meetingId: string, askSembly = false) => {
      history.push({
        pathname: generatePath(Routes.Meeting, { meetingId }),
        state: { askSembly },
      });
    };

    const handleClickAgentCallUrl = (url: string) => {
      window.open(url, '_blank', 'noopener');
    };

    const handleSetPause = (meeting: GenericMeetingOverview) => async () => {
      const { data } = await pauseCall({
        variables: { id: meeting.id },
        update: (cache, result) => updateCachedData(cache, result.data?.pauseCall),
        optimisticResponse: {
          pauseCall: {
            __typename: 'PauseCallMutationPayload',
            errors: [],
            success: true,
            meeting: { ...meeting, status: MeetingStatuses.on_pause },
          },
        },
      });
      processQueryResult(data?.pauseCall);
    };

    const handleSetResume = (meeting: GenericMeetingOverview) => async () => {
      const { data } = await resumeCall({
        variables: { id: meeting.id },
        update: (cache, result) => updateCachedData(cache, result.data?.resumeCall),
        optimisticResponse: {
          resumeCall: {
            __typename: 'ResumeCallMutationPayload',
            errors: [],
            success: true,
            meeting: { ...meeting, status: MeetingStatuses.on_call },
          },
        },
      });
      processQueryResult(data?.resumeCall);
    };

    const handleSetStop = (meeting: GenericMeetingOverview) => async () => {
      const agentCallPlatform = meeting.agentCall?.platform;
      const recordablePlatforms = [
        AgentCallPlatform.MANUAL_RECORD,
        AgentCallPlatform.SMARTMEETING_RECORD,
        AgentCallPlatform.SMARTMIKE_RECORD,
      ];
      if (agentCallPlatform && recordablePlatforms.includes(agentCallPlatform)) {
        // show confirmation dialog
        setRequestedStopMeeting(meeting);
      } else {
        const { data } = await stopCall({
          variables: { id: meeting.id },
          update: (cache, result) => updateCachedData(cache, result.data?.stopCall),
          optimisticResponse: {
            stopCall: {
              __typename: 'StopCallMutationPayload',
              errors: [],
              success: true,
              meeting: { ...meeting, status: MeetingStatuses.processing },
            },
          },
        });
        processQueryResult(data?.stopCall);
      }
    };

    const handleCloseConfirmationDialog = () => {
      setRequestedStopMeeting(null);
    };

    const handleCompleteMeetingRecording = (meeting: GenericMeetingOverview | null) => async () => {
      if (!meeting) return;
      const { data } = await submitLiveMeeting({
        variables: { meetingId: meeting.id },
        update: (cache, result) => updateCachedData(cache, result.data?.submitLiveMeeting),
        optimisticResponse: {
          submitLiveMeeting: {
            __typename: 'SubmitLiveMeetingMutationPayload',
            errors: [],
            success: true,
            meeting: { ...meeting, status: MeetingStatuses.processing },
          },
        },
      });
      processQueryResult(data?.submitLiveMeeting);
    };

    const handleDeleteMeetingRecording = (meeting: GenericMeetingOverview | null) => async () => {
      if (!meeting) return;
      const { data } = await deleteMeeting({
        variables: { meetingId: parseInt(meeting.id, 10) },
        optimisticResponse: {
          deleteMeeting: {
            __typename: 'DeleteMeetingMutationPayload',
            errors: [],
            success: true,
          },
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({ __typename: 'DetailedMeetingType', id: meeting.id }),
          });
          cache.gc();
        },
      });

      if (!data?.deleteMeeting?.success) {
        graphErrorHorsemen(data?.deleteMeeting?.errors);
      }
    };

    const handleVerify = (meetingId: string) => {
      onVerifyAgent(meetingId);
    };

    const handleSetIsRecordable =
      (meeting: GenericMeetingOverview) =>
      async (meetingId: string, isRecordable: boolean, recordType: ManualRecordType) => {
        setUpdatingMeetingId(
          recordType !== ManualRecordType.SINGLE ? meeting.seriesId : meeting.id,
        );

        const { data } = await updateRecording({
          variables: { id: meeting.id, recordType, record: isRecordable },
          update: (cache, result) => updateCachedData(cache, result.data?.updateAttendStatus),
          optimisticResponse: {
            updateAttendStatus: {
              __typename: 'UpdateAttendStatusMutationPayload',
              errors: [],
              success: true,
              meetings: [
                {
                  ...meeting,
                  status: isRecordable ? MeetingStatuses.scheduled : MeetingStatuses.not_scheduled,
                  isRecorder: isRecordable,
                },
              ],
            },
          },
        });

        processQueryResult(data?.updateAttendStatus);
      };

    const handleEditDeclinedAttendanceItem =
      (attendanceItemId: string, meetingId: string) => async (message: string) => {
        await editDeclinedAttendanceItem({
          variables: { id: parseInt(attendanceItemId), message },
          optimisticResponse: {
            editDeclinedAttendanceItem: {
              __typename: 'EditDeclinedAttendanceItemPayload',
              success: true,
              attendanceItem: {
                __typename: 'AttendanceItemType',
                id: attendanceItemId,
                message: message,
              },
              errors: [],
            },
          },
          update: (cache, { data }) => {
            if (!data?.editDeclinedAttendanceItem?.success) return;
            cache.modify({
              id: cache.identify({ __typename: 'DetailedMeetingType', id: meetingId }),
              fields: {
                attendanceItem() {
                  return data?.editDeclinedAttendanceItem?.attendanceItem || null;
                },
              },
            });
          },
        });
      };

    const handleCreateDeclinedAttendanceItem = async (meetingId: string) => {
      await createDeclinedAttendanceItem({
        variables: { meeting: meetingId },
        optimisticResponse: {
          createDeclinedAttendanceItem: {
            __typename: 'CreateDeclinedAttendanceItemPayload',
            success: true,
            attendanceItem: {
              __typename: 'AttendanceItemType',
              id: meetingId,
              message: null,
            },
            errors: [],
          },
        },
        update: (cache, { data }) => {
          if (!data?.createDeclinedAttendanceItem?.success) return;
          cache.modify({
            id: cache.identify({ __typename: 'DetailedMeetingType', id: meetingId }),
            fields: {
              attendanceItem() {
                return data?.createDeclinedAttendanceItem?.attendanceItem || null;
              },
            },
          });
        },
      });
    };

    const handleDeleteDeclinedAttendanceItem = async (id: string, meetingId: string) => {
      await deleteDeclinedAttendanceItem({
        variables: { id: parseInt(id) },
        update: (cache, { data }) => {
          if (!data?.deleteDeclinedAttendanceItem?.success) return;
          cache.modify({
            id: cache.identify({ __typename: 'DetailedMeetingType', id: meetingId }),
            fields: {
              attendanceItem() {
                return null;
              },
            },
          });
        },
      });
    };

    const handleOpenAttendanceMessageDialog = (meeting: GenericMeetingOverview) => () => {
      setUpdatingAttendanceMessage(meeting);
    };

    const handleCloseAttendanceMessageDialog = () => {
      setUpdatingAttendanceMessage(null);
    };

    const handleOpenLanguageSettingsDialog = (meetingId: string) => {
      setLanguageSettingsMeetingId(meetingId);
    };

    const handleCloseLanguageSettingsDialog = () => {
      setLanguageSettingsMeetingId(null);
    };

    const handleShowCalendar = (event: React.MouseEvent<HTMLElement>) => {
      setCalendarAnchorEl(event.currentTarget);
    };

    const handleChangeCalendarDate = (date: Date | null) => {
      if (!date) return;

      // convert date to string '2024-04-30T00:00:00+01:00'
      const dateString = DateTime.fromJSDate(date).toISO();
      onJumpToDate(dateString);
      setCalendarAnchorEl(null);
    };

    const handleCloseCalendar = () => {
      setCalendarAnchorEl(null);
    };

    const handleShowZendeskWidget = () => {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
    };
    /* #endregion */

    // render fallback
    if (!data) return null;

    const meetingGroups = Object.keys(data);
    const workspaceLangSettings = user.data?.me?.workspace?.languageSettings;
    const workspacePrimaryLanguage = workspaceLangSettings?.primaryLanguage || Languages.ENGLISH;
    const workspaceAdditionalLanguage = workspaceLangSettings?.additionalLanguage || null;
    const billingAccessId = user.data?.me?.billingAccess?.id;
    const isTeamPlan = billingAccessId === PlanIDEnum.TEAM;
    const isTeamPlusPlan = billingAccessId === PlanIDEnum.TEAM_PLUS;
    const isEnterprisePlan = billingAccessId === PlanIDEnum.ENTERPRISE;
    const isTeamsSubscription = isTeamPlan || isTeamPlusPlan || isEnterprisePlan;

    const containsTodaySection = meetingGroups.some((groupDate) => {
      const timeZone = window.SemblyUserTimeZone || 'Etc/GMT';
      const groupDateTime = DateTime.fromISO(groupDate).setZone(timeZone);
      const currentDateTime = DateTime.now().setZone(timeZone);
      const hasTodaySection = data[groupDate].some((m) => m.isDummy);
      return hasTodaySection || groupDateTime.hasSame(currentDateTime, 'day');
    });

    return (
      <>
        {meetingGroups.map((groupDate, index) => {
          const meetings = data[groupDate];
          const timeZone = window.SemblyUserTimeZone || 'Etc/GMT';
          const fallbackDateISO = new Date(-8640000000000000).toISOString(); // farthest date
          const groupDateTime = DateTime.fromISO(groupDate || fallbackDateISO);
          const groupDateTimeZoned = groupDateTime.setZone(timeZone);
          const groupDateTimeJS = groupDateTime.toJSDate();
          const now = DateTime.now().setZone(timeZone).endOf('day');
          const formattedDate = formatDateWithOrdinalSuffix(groupDateTimeJS);
          const isTodayDate = groupDateTimeZoned.hasSame(now, 'day');
          const isEmptyToday =
            isTodayDate && meetings.length === 1 && meetings.some((m) => m.isDummy);

          return (
            <div key={groupDate || `${fallbackDateISO}-${index}`} className={styles.group} ref={isTodayDate ? ref : null}>
              {(isTodayDate || (!!meetings.length && !!groupDate)) && (
                <>
                  {isTodayDate && <div role="link" style={{ position: 'relative', top: -70 }} />}
                  <div className={styles.dayHeader}>
                    <Button
                      className={styles.daySelector}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={handleShowCalendar}
                    >
                      <span>{isTodayDate && !isSharedWithMe ? 'Today' : formattedDate}</span>
                    </Button>
                  </div>
                </>
              )}

              {meetings.map((meeting) => {
                // skip dummy meetings
                if (meeting.isDummy) return null;

                const languageSettings = meeting.languageSettings;
                const hasMeetingLanguageSettings = !!languageSettings?.primaryLanguage;
                const isDisabledEditingMeetingType = validatingMeetingId === meeting.id;

                const meetingPrimaryLanguage =
                  languageSettings?.primaryLanguage || Languages.ENGLISH;
                const meetingAdditionalLanguage = languageSettings?.additionalLanguage || null;

                const primaryLanguage = hasMeetingLanguageSettings
                  ? meetingPrimaryLanguage
                  : workspacePrimaryLanguage;

                const additionalLanguage = hasMeetingLanguageSettings
                  ? meetingAdditionalLanguage
                  : workspaceAdditionalLanguage;

                const agentCallData = {
                  failureReason: meeting.agentCall?.failureReason || null,
                  platform: meeting.agentCall?.platform || null,
                  url: meeting.agentCall?.url || null,
                };

                const participants: MeetingParticipant[] = meeting.accessItems
                  .filter((item) => !item.isInvited)
                  .map((item) => ({
                    id: item.id,
                    email: item.email,
                    avatar: item.user?.avatar || null,
                    fullName: item.user?.fullName || null,
                  }));

                const meetingData = {
                  id: meeting.id,
                  editorName: meeting.manualStatusInitiator?.fullName || null,
                  finishDate: meeting.finishedAt,
                  ownerFullName: meeting.owner?.fullName || null,
                  participants: participants,
                  processingResults: meeting.processingResults ?? null,
                  startDate: meeting.startedAt,
                  status: meeting.status,
                  title: meeting.title,
                  team: meeting.team,
                  type: meeting.meetingType,
                  languageSettings: {
                    primaryLanguage,
                    additionalLanguage,
                  },
                };

                return (
                  <Box key={meeting.id} mb={2}>
                    <MeetingCard
                      agentCall={agentCallData}
                      attendanceItem={meeting.attendanceItem}
                      disableToggleSerie={!meeting.isRecurrent}
                      isAttending={meeting.isRecorder || false}
                      isAuthorizedToManage={meeting.permissions.canManage}
                      isAuthorizedToRecord={meeting.permissions.canRecord}
                      isDisabledEditingMeetingType={isDisabledEditingMeetingType}
                      isInContextWorkspace={meeting.isInContextWorkspace}
                      isLoading={checkIsUpdatingAttending(meeting)}
                      isPromotingAIChat
                      isRecordingProhibited={meeting.permissions.isRecordingProhibited}
                      isRecurrentMeeting={meeting.isRecurrent || false}
                      isVisibleMeetingTeamSelector={isTeamsSubscription}
                      key={meeting.id}
                      meeting={meetingData}
                      onChangingMeetingType={handleChangeMeetingType}
                      onChangingTeam={handleChangeMeetingTeam}
                      onClick={handleJumpToMeeting}
                      onClickAgentCallUrl={handleClickAgentCallUrl}
                      onClickLanguageSettings={handleOpenLanguageSettingsDialog}
                      onConfirmAttendance={handleDeleteDeclinedAttendanceItem}
                      onDeclineAttendance={handleCreateDeclinedAttendanceItem}
                      onPause={handleSetPause(meeting)}
                      onRequestingSupport={handleShowZendeskWidget}
                      onResume={handleSetResume(meeting)}
                      onStop={handleSetStop(meeting)}
                      onToggleAttending={handleSetIsRecordable(meeting)}
                      onUpdateAttendanceMessage={handleOpenAttendanceMessageDialog(meeting)}
                      onVerify={handleVerify}
                    />
                  </Box>
                );
              })}

              {!isSharedWithMe && isEmptyToday && (
                <Box
                  display="flex"
                  alignItems="center"
                  pt={3}
                  mb={meetingGroups.length === 1 ? 6 : 0}
                >
                  <Box display="flex" alignItems="center" mr={1}>
                    <TodayIcon color="action" fontSize="small" />
                  </Box>
                  <Typography variant="subtitle1">No meetings today.</Typography>
                </Box>
              )}
            </div>
          );
        })}

        {!containsTodaySection && (
          <div id="dummy-today" ref={ref}>
            <div role="link" style={{ position: 'relative', top: -70 }} />
          </div>
        )}

        {/* Begin: Dialogs */}
        <ConfirmStopRecording
          open={!!requestedStopMeeting}
          onClose={handleCloseConfirmationDialog}
          onCancel={handleDeleteMeetingRecording(requestedStopMeeting)}
          onConfirm={handleCompleteMeetingRecording(requestedStopMeeting)}
        />
        {updatingAttendanceMessage && (
          <AttendanceMessageDialog
            open={!!updatingAttendanceMessage}
            message={updatingAttendanceMessage.attendanceItem?.message}
            userName={user.data?.me?.fullName || ''}
            onClose={handleCloseAttendanceMessageDialog}
            onSubmit={handleEditDeclinedAttendanceItem(
              updatingAttendanceMessage.attendanceItem?.id || '',
              updatingAttendanceMessage.id,
            )}
          />
        )}
        {!!languageSettingsMeetingId && (
          <MeetingLanguageSettings
            meetingId={languageSettingsMeetingId}
            onClose={handleCloseLanguageSettingsDialog}
          />
        )}
        {!!nextMeetingType && (
          <MeetingTypeManageDialog
            meetingId={nextMeetingType.meetingId}
            targetMeetingType={nextMeetingType.type}
            targetVariant={nextMeetingType.variant}
            primaryLang={nextMeetingType.language || workspacePrimaryLanguage}
            onClose={handleCancelChangeMeetingType}
            onSubmit={handleConfirmChangeMeetingType}
          />
        )}
        {!!calendarAnchorEl && (
          <DayCalendarPopover
            confirmText="Jump to date"
            anchorEl={calendarAnchorEl}
            calendarProps={{
              maximumDate: {
                year: DateTime.now().year,
                month: DateTime.now().month,
                day: DateTime.now().day,
              },
            }}
            onClose={handleCloseCalendar}
            onChange={handleChangeCalendarDate}
            value={DateTime.now().toJSDate()}
          />
        )}
        {/* End: Dialogs */}
      </>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  group: {
    '& div:last-child': {
      marginBottom: 0,
    },
  },
  dayHeader: {
    paddingTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      width: '140vw',
      height: 1,
      marginTop: 19,
      transform: 'translateX(-50%)',
      backgroundColor: theme.palette.divider,
    },
  },
  daySelector: {
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.75, 2),
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export default MeetingCards;
