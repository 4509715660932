import { AGENT_CALL_FAILURES } from '../../constants';

export const getFailureReason = (failureReason: string | null, isManualUpload: boolean): string => {
  const defaultFailureReason = 'We encountered a problem during your meeting recording.';
  const manualUploadFailureReason = `Sembly really wanted to process the meeting, but you have used up your upload limit for this month. Upgrade or buy credits in billing for more usage.`;

  return isManualUpload && failureReason === 'not_payed'
    ? manualUploadFailureReason
    : failureReason !== null
    ? AGENT_CALL_FAILURES[failureReason] || defaultFailureReason
    : defaultFailureReason;
};

export default getFailureReason;
