import { history } from '@shared/clients/History';
import { isWebView } from '@shared/utils';
import { Routes } from '@shared/enums';

export const openOauthWindow = (arg: { uri: string; fallbackRoute: Routes | string }) => {
  // Google has blocked the possibility to login via `WebView`. The reason is that
  // Google does not trust external app providers, where it isn't aware of security
  // of connection, so we have to check it out on our side as well.
  if (isWebView()) {
    history.push(arg.fallbackRoute);
  } else {
    window.open(arg.uri, '_self');
  }
};

export default openOauthWindow;
