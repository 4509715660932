/**
 * Formats a date into a human-readable string with an ordinal suffix for the day.
 *
 * The function returns the date in one of two formats:
 * - For the current year: `"Wednesday, November 27th"`
 * - For previous years: `"December 25th, 2023"`
 *
 * @param date - The date to format.
 * @returns The formatted date string with an ordinal suffix.
 *
 * @example
 * // Current year example
 * const currentDate = new Date(2024, 10, 27); // November 27, 2024
 * console.log(formatDateWithOrdinalSuffix(currentDate)); // "Wednesday, November 27th"
 *
 * @example
 * // Previous year example
 * const pastDate = new Date(2023, 11, 25); // December 25, 2023
 * console.log(formatDateWithOrdinalSuffix(pastDate)); // "December 25th, 2023"
 */
export function formatDateWithOrdinalSuffix(date: Date): string {
  const day = date.getDate();

  const getOrdinalSuffix = (day: number): string => {
    const lastDigit = day % 10;
    const lastTwoDigits = day % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return 'th';
    switch (lastDigit) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
  const currentYear = new Date().getFullYear();
  const dateYear = date.getFullYear();

  if (dateYear === currentYear) {
    // Format: "Wednesday, November 27th"
    return `${date.toLocaleString('en-US', options)}${getOrdinalSuffix(day)}`;
  } else {
    // Format: "December 25th, 2023"
    const pastYearOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const formattedDate = date.toLocaleString('en-US', pastYearOptions);
    return formattedDate.replace(/\d+(?=,)/, `${day}${getOrdinalSuffix(day)}`);
  }
}
