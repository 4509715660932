// Material UI
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/LinkSharp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RestoreIcon from '@material-ui/icons/SettingsBackupRestore';
// Lib Icons
import PinIcon from '../icons/Pin';
import PinOutline from '../icons/PinOutline';

export interface KeyItemMenuMobileProps {
  allowPinning: boolean;
  anchorEl: HTMLElement | null;
  hasTranscriptionItem: boolean;
  isActiveKeyItem: boolean;
  isAuthorizedToEdit: boolean;
  isPinnedKeyItem?: boolean;
  onClickOnCopyContent: () => void;
  onClickOnCopyLink: () => void;
  onClickOnPin?: () => void;
  onClickOnToggleActivity: (isActive: boolean) => void;
  onClickOnTranscriptLink?: () => void;
  onClose: () => void;
}

type EventType =
  | 'copy-content'
  | 'copy-link'
  | 'jump-transcript'
  | 'make-active'
  | 'make-inactive'
  | 'pin';

export const KeyItemMenuMobile: React.VFC<KeyItemMenuMobileProps> = ({
  allowPinning,
  anchorEl,
  hasTranscriptionItem,
  isActiveKeyItem,
  isAuthorizedToEdit,
  isPinnedKeyItem,
  onClickOnCopyContent,
  onClickOnCopyLink,
  onClickOnPin,
  onClickOnToggleActivity,
  onClickOnTranscriptLink = () => null,
  onClose,
}) => {
  /* #region  Handlers */
  const handleClickOnMenuItem = (eventType: EventType) => () => {
    onClose();
    // prettier-ignore
    switch (eventType) {
      case 'copy-content': onClickOnCopyContent(); break;
      case 'copy-link': onClickOnCopyLink(); break;
      case 'jump-transcript': onClickOnTranscriptLink(); break;
      case 'make-active': onClickOnToggleActivity(true); break;
      case 'make-inactive': onClickOnToggleActivity(false); break;
      case 'pin': onClickOnPin && onClickOnPin(); break;
    }
  };
  /* #endregion */

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      {isAuthorizedToEdit && allowPinning && (
        <MenuItem dense onClick={handleClickOnMenuItem('pin')}>
          <ListItemIcon>
            {isPinnedKeyItem ? <PinIcon fontSize="small" /> : <PinOutline fontSize="small" />}
          </ListItemIcon>
          <ListItemText>{isPinnedKeyItem ? 'Unpin' : 'Pin'}</ListItemText>
        </MenuItem>
      )}
      <MenuItem dense onClick={handleClickOnMenuItem('copy-link')}>
        <ListItemIcon>
          <LinkIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Copy link</ListItemText>
      </MenuItem>
      <MenuItem dense onClick={handleClickOnMenuItem('copy-content')}>
        <ListItemIcon>
          <FileCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Copy content</ListItemText>
      </MenuItem>
      {hasTranscriptionItem && (
        <MenuItem dense onClick={handleClickOnMenuItem('jump-transcript')}>
          <ListItemIcon>
            <OpenInNewIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Go to transcript</ListItemText>
        </MenuItem>
      )}
      {isAuthorizedToEdit && isActiveKeyItem && (
        <MenuItem dense onClick={handleClickOnMenuItem('make-inactive')}>
          <ListItemIcon>
            <CloseIcon fontSize="small" />
          </ListItemIcon>
          Make inactive
        </MenuItem>
      )}
      {isAuthorizedToEdit && !isActiveKeyItem && (
        <MenuItem dense onClick={handleClickOnMenuItem('make-active')}>
          <ListItemIcon>
            <RestoreIcon fontSize="small" />
          </ListItemIcon>
          Make active
        </MenuItem>
      )}
    </Menu>
  );
};

export default KeyItemMenuMobile;
