import { lazyWithPreload } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const ErrorModuleContainer = lazyWithPreload(
  () => import('./containers/ErrorModuleContainer'),
);

export const ErrorModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.Error,
    render: () => <ErrorModuleContainer />,
  },
};

export default ErrorModule;
