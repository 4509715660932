// Material UI
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import { GenericDialog } from '../components';

export interface YouTubeModalProps {
  autoplay?: boolean;
  disableTransition?: boolean;
  title: string;
  videoSrc: string;
  onClose: () => void;
}

export const YouTubeModal: React.VFC<YouTubeModalProps> = ({
  autoplay = false,
  disableTransition = false,
  title,
  videoSrc,
  onClose,
}) => {
  const styles = useStyles();

  return (
    <GenericDialog
      title={title}
      disableTransition={disableTransition}
      dialogProps={{ open: true, fullWidth: true, maxWidth: 'md' }}
      onClose={onClose}
    >
      <Box component={DialogContent} mt={1} mb={4}>
        <iframe
          allowFullScreen
          title={title}
          className={styles.video}
          src={autoplay ? `${videoSrc}?autoplay=1` : videoSrc}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    border: 'none',
    aspectRatio: '16 / 9',
  },
}));

export default YouTubeModal;
