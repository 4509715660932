import { forwardRef } from 'react';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { AutocompleteChangeReason, AutocompleteRenderInputParams } from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';

export interface TagsFieldProps {
  disabled?: boolean;
  disabledValues?: string[];
  maxTagLength?: number;
  maxTagWords?: number;
  maxTags?: number;
  tagPattern?: RegExp;
  tagPatternMessage?: string;
  values: string[];
  placeholder?: string;
  onChange: (values: string[], reason: 'create' | 'delete') => void;
  onValidationError?: (error: string) => void;
}

/**
 *  Represents a field for adding tags optionally separated by commas
 */
export const TagsField: React.VFC<TagsFieldProps> = forwardRef(
  (
    {
      disabled = false,
      disabledValues = [],
      maxTagLength = 256,
      maxTagWords = 6,
      maxTags = 1000,
      tagPattern = /^[\p{L}\p{M}\s'’]*$/u, // only letters, spaces, single quotes
      tagPatternMessage = 'Only letters, spaces and single quotes are allowed',
      values,
      placeholder = 'Add items separated by commas',
      onChange,
      onValidationError = () => null,
    },
    ref,
  ) => {
    const styles = useStyles();

    const validateInput = (value: string[]) => {
      let result = value;

      // validate max tags, return if validation fails
      if (result.length > maxTags) {
        onValidationError(`Maximum number of items is ${maxTags}`);
        result.length = maxTags; // trim result to max tags
      }

      // validate max tag length, filter out tags that exceed the limit
      if (result.some((tag) => tag.length > maxTagLength)) {
        const invalidTags = result.filter((tag) => tag.length > maxTagLength);
        onValidationError(
          `Maximum item length is ${maxTagLength}. Please shorten the following items: "${invalidTags.join(
            '; ',
          )}"`,
        );
        result = result.filter((tag) => tag.length <= maxTagLength);
      }

      // validate max tag words, filter out tags that exceed the limit
      if (result.some((tag) => tag.split(' ').length > maxTagWords)) {
        // slice tags to 100 characters
        const invalidTags = result
          .filter((tag) => tag.split(' ').length > maxTagWords)
          .map((tag) => tag.slice(0, 100));

        onValidationError(
          `Maximum item words is ${maxTagWords}. Please shorten the following items: "${invalidTags.join(
            '; ',
          )}"`,
        );
        result = result.filter((tag) => tag.split(' ').length <= maxTagWords);
      }

      // validate tag pattern, filter out tags that do not match the pattern
      if (result.some((tag) => !tagPattern.test(tag.trim()))) {
        let invalidTags = result.filter((tag) => !tagPattern.test(tag));
        // slice tags to 100 characters and add ellipsis if needed
        invalidTags = invalidTags.map((tag) => (tag.length > 100 ? `${tag.slice(0, 25)}...` : tag));
        onValidationError(
          `${tagPatternMessage}. Please correct the following items: "${invalidTags.join('; ')}"`,
        );
        result = result.filter((tag) => tagPattern.test(tag));
      }

      return result;
    };

    /* #region  Handlers */
    const handleDelete = (item: string) => () => {
      onChange(
        values.filter((tag) => tag !== item),
        'delete',
      );
    };

    const handleBlur: React.FocusEventHandler<HTMLDivElement> = (e) => {
      if (disabled) return;

      const target = e.target as HTMLInputElement;
      let tags = [...values, ...target.value.split(',')]; // concat and split by comma

      tags = tags
        .filter((tag) => tag.trim() !== '') // remove empty strings
        .filter((tag, index, self) => self.indexOf(tag) === index); // remove duplicates

      // validate tags
      const result = validateInput(tags);

      onChange(result, 'create');
    };

    const handleChangeTags = (
      event: unknown,
      value: (string | string[])[],
      reason: AutocompleteChangeReason,
    ) => {
      // flatten array, split by coma and remove remove empty strings and duplicates
      let result = value
        .flat()
        .map((tag) => tag.split(','))
        .flat()
        .filter((tag) => tag.trim() !== '') // remove empty strings
        .map((tag) => tag.trim())
        .filter((tag, index, self) => self.indexOf(tag) === index); // remove duplicates

      result = validateInput(result);

      const isDeleted = reason === 'remove-option' || reason === 'clear';
      onChange(result, isDeleted ? 'delete' : 'create');
    };
    /* #endregion */

    return (
      <Autocomplete
        ref={ref}
        freeSolo
        multiple
        fullWidth
        includeInputInList
        disableClearable
        disabled={disabled}
        classes={{
          root: styles.autocompleteRoot,
          inputRoot: styles.autocomplete,
          tag: styles.tag,
        }}
        options={[]}
        onBlur={handleBlur}
        value={values}
        onChange={handleChangeTags}
        renderTags={(value, getTagProps) =>
          value.map((item, index) => {
            const tag = item.toString();
            return (
              <Chip
                key={index}
                variant="outlined"
                label={tag}
                {...getTagProps({ index })}
                classes={{ label: styles.label }}
                disabled={disabledValues.includes(tag)}
                onDelete={handleDelete(tag)}
              />
            );
          })
        }
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              placeholder={!values?.length ? placeholder : ''}
            />
          );
        }}
      />
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  autocompleteRoot: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius * 3,
    },
  },
  autocomplete: {
    minHeight: 110,
    alignItems: 'flex-start',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey['A100']}`,
  },
  tag: {
    border: 'none',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
    ...theme.typography.body1,
  },
  label: {
    lineHeight: 1,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxWidth: 220,
    },
  },
}));

export default TagsField;
