// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// Lib Shared
import LanguageFlag from './LanguageFlag';
import { Languages } from '../types';
import { SUPPORTED_LANGUAGES } from '../constants';

export interface LanguageItemProps {
  languageCode: Languages;
}

export const LanguageItem: React.VFC<LanguageItemProps> = ({ languageCode }) => {
  const label = SUPPORTED_LANGUAGES[languageCode];

  return (
    <Box display="flex" alignItems="center" gridGap={6}>
      <LanguageFlag laguageCode={languageCode} />
      <Typography variant="body1">{label}</Typography>
    </Box>
  );
};

export default LanguageItem;
