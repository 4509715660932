import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
// GraphQL
import connectIntegrationMutation from '../graphql/mutations/ConnectBasicIntegration.graphql';
import disconnectIntegrationMutation from '../graphql/mutations/DisconnectBasicIntegration.graphql';
import editActionsIntegrationMutation from '../graphql/mutations/EditActionsCustomOutboundUserIntegration.graphql';
import editTranscriptIntegrationMutation from '../graphql/mutations/EditTranscriptCustomOutboundUserIntegration.graphql';
import editMeetingNotesIntegrationMutation from '../graphql/mutations/EditMeetingNotesCustomOutboundUserIntegration.graphql';
import query from '../graphql/queries/UserOutboundIntegrations.graphql';
// Lib Assets
import actionsIntegrationIcon from '../assets/icon-custom-actions-integration-36.svg';
import meetingNotesIntegrationIcon from '../assets/icon-custom-meeting-notes-integration-36.svg';
import transcriptIntegrationIcon from '../assets/icon-custom-trascript-integration-36.svg';
// Lib Shared
import {
  ConnectBasicIntegration,
  ConnectBasicIntegrationVariables,
  DisconnectBasicIntegration,
  DisconnectBasicIntegrationVariables,
  EditActionsCustomOutboundUserIntegration,
  EditActionsCustomOutboundUserIntegrationVariables,
  EditMeetingNotesCustomOutboundUserIntegration,
  EditMeetingNotesCustomOutboundUserIntegrationVariables,
  EditTranscriptCustomOutboundUserIntegration,
  EditTranscriptCustomOutboundUserIntegrationVariables,
  GenericActionsCustomOutboundUserIntegration,
  GenericTranscriptionCustomOutboundUserIntegration,
  GenericMeetingNotesCustomOutboundUserIntegration,
  GenericOutboundIntegration,
  GraphError,
  IntegrationTypes,
  UserOutboundIntegrations,
} from '../types';
import { useConfirmationDialog } from '../hooks';
import { ManageCustomUserAutomationDialog } from '../dialogs';
import {
  AutomationsPlaceholder,
  ConnectedAutomationCard,
  GenericConfirmation,
} from '../components';

export interface MyCustomAutomationsContainerProps {
  onConnectedIntegration?: () => void;
  onUpdateCustomIntegration?: (automationName: string) => void;
  onDisconnectIntegration?: (integrationName: string) => void;
  onResponseError?: (err: GraphError) => void;
  onWarning?: (message: string) => void;
}

export const MyCustomAutomationsContainer: React.VFC<MyCustomAutomationsContainerProps> = ({
  onConnectedIntegration = () => null,
  onUpdateCustomIntegration = () => null,
  onDisconnectIntegration = () => null,
  onResponseError = () => null,
  onWarning = () => null,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [managingAutomation, setManagingAutomation] = useState<
    | GenericActionsCustomOutboundUserIntegration
    | GenericTranscriptionCustomOutboundUserIntegration
    | GenericMeetingNotesCustomOutboundUserIntegration
    | null
  >(null);

  const { data, refetch, loading } = useQuery<UserOutboundIntegrations>(query);

  const [connectIntegration] = useMutation<
    ConnectBasicIntegration,
    ConnectBasicIntegrationVariables
  >(connectIntegrationMutation);

  const [disconnectIntegration] = useMutation<
    DisconnectBasicIntegration,
    DisconnectBasicIntegrationVariables
  >(disconnectIntegrationMutation);

  const [editActionsIntegration] = useMutation<
    EditActionsCustomOutboundUserIntegration,
    EditActionsCustomOutboundUserIntegrationVariables
  >(editActionsIntegrationMutation, { refetchQueries: [{ query }] });

  const [editTranscriptIntegration] = useMutation<
    EditTranscriptCustomOutboundUserIntegration,
    EditTranscriptCustomOutboundUserIntegrationVariables
  >(editTranscriptIntegrationMutation, { refetchQueries: [{ query }] });

  const [editMeetingNotesIntegration] = useMutation<
    EditMeetingNotesCustomOutboundUserIntegration,
    EditMeetingNotesCustomOutboundUserIntegrationVariables
  >(editMeetingNotesIntegrationMutation, { refetchQueries: [{ query }] });

  const [confirmDelete, DeleteConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Delete"
      text="Are you sure you want to delete this automation?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Delete"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));
  /* #endregion */

  /* #region  Helpers */
  const connectBasicIntegration = async (integrationType: IntegrationTypes) => {
    const result = await connectIntegration({
      variables: { integrationType },
    });

    const connectedIntegration = result.data?.connectBasicIntegration?.integration?.childObject;
    const typename = connectedIntegration?.__typename;

    if (
      result.data?.connectBasicIntegration?.success &&
      (typename === 'ActionsCustomOutboundUserIntegrationType' ||
        typename === 'TranscriptionCustomOutboundUserIntegrationType' ||
        typename === 'MeetingNotesCustomOutboundUserIntegrationType')
    ) {
      onConnectedIntegration();
      refetch();
      return connectedIntegration;
    }

    onResponseError(result.data?.connectBasicIntegration?.errors);
    return null;
  };

  const disconnectBasicIntegration = async (integrationId: string) => {
    const result = await disconnectIntegration({
      variables: { integrationId: +integrationId },
      optimisticResponse: {
        disconnectBasicIntegration: {
          __typename: 'DisconnectBasicIntegrationMutationPayload',
          errors: [],
          success: true,
        },
      },
      update: (cache) => {
        const targetIntegrationId = cache.identify({
          __typename: 'IntegrationType',
          id: integrationId,
        });
        const targetCustomIntegrationId = cache.identify({
          __typename: 'CustomOutboundIntegrationType',
          id: integrationId,
        });
        cache.evict({ id: targetCustomIntegrationId });
        cache.evict({ id: targetIntegrationId });
        cache.gc();
      },
    });

    if (result.data?.disconnectBasicIntegration?.success) {
      onDisconnectIntegration('Custom Automation');
    } else {
      onResponseError(result.data?.disconnectBasicIntegration?.errors);
    }
  };
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnMenuButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseIntegrationMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleCloseManageAutomationDialog =
    (id: string) =>
    (shouldDiscard: boolean = false) => {
      if (shouldDiscard) disconnectBasicIntegration(id);
      setManagingAutomation(null);
    };

  const handleEditAutomation =
    (
      childObject:
        | GenericActionsCustomOutboundUserIntegration
        | GenericTranscriptionCustomOutboundUserIntegration
        | GenericMeetingNotesCustomOutboundUserIntegration,
    ) =>
    () => {
      setManagingAutomation(childObject);
    };

  const handleChangeActivity =
    (
      integration:
        | GenericActionsCustomOutboundUserIntegration
        | GenericTranscriptionCustomOutboundUserIntegration
        | GenericMeetingNotesCustomOutboundUserIntegration,
      isActive: boolean,
    ) =>
    async () => {
      const errorMessage =
        'The integration is invalid, please check all fields in the integration settings.';

      if (!integration.title || !integration.rule || !integration.endpoint) {
        return onWarning(errorMessage);
      }

      if (integration.__typename === 'ActionsCustomOutboundUserIntegrationType') {
        if (!integration.actionsFields || !integration.contentType) {
          return onWarning(errorMessage);
        }

        const result = await editActionsIntegration({
          variables: {
            keywords: integration.keywords,
            fields: integration.actionsFields.map((field) => ({
              fieldName: field.fieldName,
              name: field.name,
            })),
            description: integration.description,
            contentType: integration.contentType,
            rule: integration.rule,
            endpoint: integration.endpoint,
            integrationId: integration.id,
            title: integration.title,
            meetingType: integration.meetingType,
            isActive,
          },
          optimisticResponse: {
            editActionsCustomOutboundUserIntegration: {
              __typename: 'EditActionsCustomOutboundUserIntegrationMutationPayload',
              success: true,
              errors: [],
              integration: {
                __typename: 'ActionsCustomOutboundUserIntegrationType',
                id: integration.id,
                actionsFields: integration.actionsFields,
                description: integration.description,
                endpoint: integration.endpoint,
                keywords: integration.keywords,
                meetingType: integration.meetingType,
                rule: integration.rule,
                contentType: integration.contentType,
                title: integration.title,
                isActive,
              },
            },
          },
        });

        if (!result.data?.editActionsCustomOutboundUserIntegration?.success) {
          onResponseError(result.data?.editActionsCustomOutboundUserIntegration?.errors);
        }
      } else if (integration.__typename === 'MeetingNotesCustomOutboundUserIntegrationType') {
        if (!integration.meetingNotesFields) {
          return onWarning(errorMessage);
        }

        const result = await editMeetingNotesIntegration({
          variables: {
            keywords: integration.keywords,
            fields: integration.meetingNotesFields.map((field) => ({
              fieldName: field.fieldName,
              name: field.name,
            })),
            description: integration.description,
            endpoint: integration.endpoint,
            integrationId: integration.id,
            rule: integration.rule,
            title: integration.title,
            meetingType: integration.meetingType,
            isActive,
          },
          optimisticResponse: {
            editMeetingNotesCustomOutboundUserIntegration: {
              __typename: 'EditMeetingNotesCustomOutboundUserIntegrationMutationPayload',
              success: true,
              errors: [],
              integration: {
                __typename: 'MeetingNotesCustomOutboundUserIntegrationType',
                id: integration.id,
                meetingNotesFields: integration.meetingNotesFields,
                description: integration.description,
                endpoint: integration.endpoint,
                keywords: integration.keywords,
                meetingType: integration.meetingType,
                rule: integration.rule,
                title: integration.title,
                isActive,
              },
            },
          },
        });

        if (!result.data?.editMeetingNotesCustomOutboundUserIntegration?.success) {
          onResponseError(result.data?.editMeetingNotesCustomOutboundUserIntegration?.errors);
        }
      } else if (integration.__typename === 'TranscriptionCustomOutboundUserIntegrationType') {
        if (!integration.transcriptionFields) {
          return onWarning(errorMessage);
        }

        const result = await editTranscriptIntegration({
          variables: {
            keywords: integration.keywords,
            fields: integration.transcriptionFields.map((field) => ({
              fieldName: field.fieldName,
              name: field.name,
            })),
            description: integration.description,
            endpoint: integration.endpoint,
            integrationId: integration.id,
            rule: integration.rule,
            title: integration.title,
            meetingType: integration.meetingType,
            isActive,
          },
          optimisticResponse: {
            editTranscriptionCustomOutboundUserIntegration: {
              __typename: 'EditTranscriptionCustomOutboundUserIntegrationMutationPayload',
              success: true,
              errors: [],
              integration: {
                __typename: 'TranscriptionCustomOutboundUserIntegrationType',
                id: integration.id,
                transcriptionFields: integration.transcriptionFields,
                description: integration.description,
                endpoint: integration.endpoint,
                keywords: integration.keywords,
                meetingType: integration.meetingType,
                rule: integration.rule,
                title: integration.title,
                isActive,
              },
            },
          },
        });

        if (!result.data?.editTranscriptionCustomOutboundUserIntegration?.success) {
          onResponseError(result.data?.editTranscriptionCustomOutboundUserIntegration?.errors);
        }
      }
    };

  const handleDisconnectBasicIntegration = (integrationId: string) => async () => {
    const confirmed = await confirmDelete();
    if (!confirmed) return;

    disconnectBasicIntegration(integrationId);
  };

  const handleCreateIntegration =
    (state: 'meetingNotes' | 'actions' | 'transcript') => async () => {
      setMenuAnchorEl(null);

      let connectedIntegration:
        | GenericActionsCustomOutboundUserIntegration
        | GenericTranscriptionCustomOutboundUserIntegration
        | GenericMeetingNotesCustomOutboundUserIntegration
        | null = null;

      if (state === 'actions') {
        connectedIntegration = await connectBasicIntegration(
          IntegrationTypes.ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION,
        );
      } else if (state === 'meetingNotes') {
        connectedIntegration = await connectBasicIntegration(
          IntegrationTypes.MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION,
        );
      } else if (state === 'transcript') {
        connectedIntegration = await connectBasicIntegration(
          IntegrationTypes.TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION,
        );
      }

      if (!!connectedIntegration) {
        setManagingAutomation(connectedIntegration);
      }
    };
  /* #endregion */

  // Utils
  const sortAlphabetically = (a: GenericOutboundIntegration, b: GenericOutboundIntegration) => {
    return a.id.localeCompare(b.id);
  };

  const isTargetIntegration = ({ childObject }: GenericOutboundIntegration) => {
    return (
      childObject &&
      (childObject.__typename === 'ActionsCustomOutboundUserIntegrationType' ||
        childObject.__typename === 'MeetingNotesCustomOutboundUserIntegrationType' ||
        childObject.__typename === 'TranscriptionCustomOutboundUserIntegrationType')
    );
  };

  /* #region  Render Helpers */
  const getAutomationIcon = (
    typename:
      | 'ActionsCustomOutboundUserIntegrationType'
      | 'MeetingNotesCustomOutboundUserIntegrationType'
      | 'TranscriptionCustomOutboundUserIntegrationType',
  ) => {
    return {
      ActionsCustomOutboundUserIntegrationType: actionsIntegrationIcon,
      MeetingNotesCustomOutboundUserIntegrationType: meetingNotesIntegrationIcon,
      TranscriptionCustomOutboundUserIntegrationType: transcriptIntegrationIcon,
    }[typename];
  };

  const connectedIntegrations = data?.outboundIntegrations;
  const filteredCustomIntegrations = connectedIntegrations?.filter(isTargetIntegration) ?? [];
  const sortedCustomIntegrations = filteredCustomIntegrations.sort(sortAlphabetically);
  const customIntegrations = sortedCustomIntegrations.map(({ childObject }) => childObject);
  const paymentPlanFeatures = data?.me?.paymentCustomer?.plan?.features;
  const staticLimits = paymentPlanFeatures?.staticLimits;
  const connectedIntegrationsCount = customIntegrations?.length ?? 0;
  const maxIntegrartionsCount = staticLimits?.customOutboundIntegrationsLimit ?? 0;
  const isLimitExhausted = connectedIntegrationsCount >= maxIntegrartionsCount;
  /* #endregion */

  return (
    <>
      <Box pt={2}>
        <div className={styles.header}>
          <Box display="flex" flexDirection="column" gridGap={6} flex={1}>
            <Typography variant="h6">Custom Automations</Typography>
            <Typography component="div" variant="body2" color="textSecondary">
              Connected integrations: {`${connectedIntegrationsCount} of ${maxIntegrartionsCount} `}
            </Typography>
          </Box>
          <Tooltip
            arrow
            title={
              isLimitExhausted
                ? 'You have reached the limit of custom integrations'
                : 'Create a new automation'
            }
          >
            <span>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                aria-label="Create a new automation"
                disabled={loading || isLimitExhausted}
                onClick={handleClickOnMenuButton}
                startIcon={<AddIcon />}
              >
                <Typography noWrap component="span" variant="body1">
                  {isSmallScreen ? 'New' : 'New Automation'}
                </Typography>
              </Button>
            </span>
          </Tooltip>
          <Menu
            keepMounted
            id="custom-integrations-menu"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleCloseIntegrationMenu}
          >
            <MenuItem onClick={handleCreateIntegration('meetingNotes')}>
              <ListItemIcon>
                <img
                  src={meetingNotesIntegrationIcon}
                  width={24}
                  height={24}
                  alt="Custom Meeting Notes Integration"
                />
              </ListItemIcon>
              <Typography variant="inherit"> Meeting Notes</Typography>
            </MenuItem>
            <MenuItem onClick={handleCreateIntegration('actions')}>
              <ListItemIcon>
                <img
                  src={actionsIntegrationIcon}
                  width={24}
                  height={24}
                  alt="Custom Tasks Integration"
                />
              </ListItemIcon>
              <Typography variant="inherit">Tasks</Typography>
            </MenuItem>
            <MenuItem onClick={handleCreateIntegration('transcript')}>
              <ListItemIcon>
                <img
                  src={transcriptIntegrationIcon}
                  width={24}
                  height={24}
                  alt="Custom Transcription Integration"
                />
              </ListItemIcon>
              <Typography variant="inherit"> Transcription</Typography>
            </MenuItem>
          </Menu>
        </div>

        <div className={styles.connectedIntegrations}>
          {loading ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  variant="rect"
                  key={index}
                  className={styles.connectedIntegrationSkeleton}
                />
              ))}
            </>
          ) : (
            <>
              {!customIntegrations.length ? (
                <AutomationsPlaceholder />
              ) : (
                <>
                  {customIntegrations.map((childObject) => {
                    if (!childObject) return null;

                    const typename = childObject.__typename;

                    if (
                      typename !== 'ActionsCustomOutboundUserIntegrationType' &&
                      typename !== 'MeetingNotesCustomOutboundUserIntegrationType' &&
                      typename !== 'TranscriptionCustomOutboundUserIntegrationType'
                    ) {
                      return null; // Render only known user level custom integrations
                    }

                    const integrationIcon = getAutomationIcon(typename);

                    return (
                      <ConnectedAutomationCard
                        key={childObject.id}
                        enabled={childObject.isActive}
                        description={childObject.description || ''}
                        logoUri={integrationIcon}
                        name={childObject.title || 'Custom Automation'}
                        onDelete={handleDisconnectBasicIntegration(childObject.id)}
                        onEdit={handleEditAutomation(childObject)}
                        onToggleEnabled={handleChangeActivity(childObject, !childObject.isActive)}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </Box>
      {!!managingAutomation && (
        <ManageCustomUserAutomationDialog
          open
          connectedIntegration={managingAutomation}
          count={connectedIntegrationsCount}
          icon={getAutomationIcon(managingAutomation.__typename)}
          onClose={handleCloseManageAutomationDialog(managingAutomation.id)}
          onUpdated={onUpdateCustomIntegration}
          onGraphError={onResponseError}
        />
      )}
      {DeleteConfirmationDialog}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  connectedIntegrations: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  connectedIntegrationSkeleton: {
    height: 64,
    borderRadius: theme.shape.borderRadius * 2,
  },
  integrationAppSkeleton: {
    width: '100%',
    height: 114,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
