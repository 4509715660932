import { useState, forwardRef } from 'react';
import { format, formatDistance, isAfter } from 'date-fns';
import { Calendar, utils, DayValue } from '@amir04lm26/react-modern-calendar-date-picker';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CalendarIcon from '@material-ui/icons/InsertInvitation';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core';
// App Shared
import { GenericDialog } from '@sembly-ui';

export type DateTimeTextFieldProps = TextFieldProps & {
  onUpdate?: (value: Date) => void;
};

export const DateTimeTextField = forwardRef<HTMLDivElement, DateTimeTextFieldProps>(
  ({ onUpdate = () => null, ...props }, ref) => {
    const styles = useStyles();
    const { breakpoints } = useTheme();
    const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

    /* #region  State */
    const [currentDate] = useState(new Date());
    const [shownPicker, setShownPicker] = useState(false);
    const [selectedDay, setSelectedDay] = useState<DayValue>(null);
    const [period, setPeriod] = useState<'AM' | 'PM'>(format(currentDate, 'aa') as 'AM' | 'PM');
    const [hours, setHours] = useState(format(currentDate, 'h'));
    const [minutes, setMinutes] = useState(format(currentDate, 'mm'));
    /* #endregion */

    /* #region  Utils */
    const getJoinDate = () => {
      const defaultDate = {
        day: currentDate.getDate(),
        month: currentDate.getMonth() + 1, // convert a month index to a month number
        year: currentDate.getFullYear(),
      };

      const day: DayValue = !selectedDay ? defaultDate : selectedDay;
      const hours12 = parseInt(hours, 10);

      let hours24: number;

      if (period === 'AM') {
        hours24 = hours12 === 12 ? 0 : hours12;
      } else {
        hours24 = hours12 === 12 ? hours12 : hours12 + 12;
      }

      return new Date(
        day.year,
        day.month - 1, // convert a month number to a month index
        day.day,
        hours24,
        parseInt(minutes, 10),
        currentDate.getSeconds(),
      );
    };
    /* #endregion */

    /* #region  Handlers */
    const handleClose = () => {
      setShownPicker(false);
    };

    const handleSetDay = (day: DayValue) => {
      setSelectedDay(day);
    };

    const handleTogglePeriod = (_: unknown, isPM: boolean) => {
      setPeriod(isPM ? 'PM' : 'AM');
    };

    const handleSetHour = (_: unknown, value: string) => {
      if (HOURS.includes(value)) setHours(value);
    };

    const handleSetMinutes = (_: unknown, value: string) => {
      const updatedValue = value.length === 1 ? `0${value}` : value;

      if (MINUTES.includes(updatedValue)) {
        setMinutes(value);
      }
    };

    const handleShowPicker = () => {
      setShownPicker(true);
    };

    const handleCancel = () => {
      setSelectedDay(null);
      setPeriod(format(currentDate, 'aa') as 'AM' | 'PM');
      setHours(format(currentDate, 'h'));
      setMinutes(format(currentDate, 'mm'));
      setShownPicker(false);
    };

    const handleSubmit = () => {
      setShownPicker(false);
      onUpdate(getJoinDate());
    };
    /* #endregion */

    /* #region  Render Helpers */
    const joinDate = getJoinDate();
    const joinDateText = format(joinDate, 'PP p');
    const joinDateDistance = formatDistance(joinDate, currentDate, { addSuffix: true });
    const isJoinNow = isAfter(currentDate, joinDate);
    /* #endregion */

    return (
      <>
        <TextField
          {...props}
          value={isJoinNow ? 'Joining now' : `${joinDateText} (joining ${joinDateDistance})`}
          inputProps={{ readOnly: true, style: { cursor: 'pointer' } }}
          InputProps={{
            endAdornment: <CalendarIcon color={props.disabled ? 'disabled' : 'action'} />,
            classes: { root: styles.inputRoot },
          }}
          onClick={props?.disabled ? undefined : handleShowPicker}
          ref={ref}
        />

        <GenericDialog
          dialogProps={{ keepMounted: true, open: shownPicker, fullScreen: isSmallScreen }}
          title={isJoinNow ? 'Select date and time' : joinDateText}
          onClose={handleClose}
        >
          <DialogContent className={styles.content}>
            <Calendar
              shouldHighlightWeekends
              value={selectedDay}
              onChange={handleSetDay}
              minimumDate={utils('en').getToday()}
            />

            <Box display="flex" alignItems="center" justifyContent="center" py={2}>
              <Autocomplete
                freeSolo
                disableClearable
                value={hours}
                inputValue={hours}
                classes={{ input: styles.autocomplete, option: styles.option }}
                onChange={handleSetHour}
                onInputChange={handleSetHour}
                options={HOURS}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
              <Box px={1}>:</Box>

              <Autocomplete
                freeSolo
                disableClearable
                value={minutes}
                inputValue={minutes}
                classes={{ input: styles.autocomplete, option: styles.option }}
                onChange={handleSetMinutes}
                onInputChange={handleSetMinutes}
                options={MINUTES_OPTIONS}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
              <FormControlLabel
                value="top"
                style={{ padding: 0 }}
                className={styles.label}
                label={period === 'AM' ? 'AM' : 'PM'}
                labelPlacement="top"
                control={
                  <Switch
                    color="primary"
                    checked={period === 'PM'}
                    className={styles.switch}
                    onChange={handleTogglePeriod}
                  />
                }
              />
            </Box>
            <Divider light />
            <Box display="flex" justifyContent="center" pt={2}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={styles.submit}
                aria-label="Confirm"
                onClick={handleSubmit}
              >
                OK
              </Button>
              <Button aria-label="Cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </GenericDialog>
      </>
    );
  },
);

const HOURS = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
const MINUTES = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
const MINUTES_OPTIONS = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    background: theme.palette.background.paper,
    '& fieldset': {
      border: `1px solid ${theme.palette.grey['A100']} !important`,
    },
  },
  label: {
    paddingTop: 6,
    marginRight: 0,
  },
  option: {
    justifyContent: 'center',
  },
  autocomplete: {
    minWidth: '2.5rem !important',
    textAlign: 'center',
  },
  content: {
    padding: theme.spacing(2, 3),
    '& .Calendar__day.-selected': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  submit: {
    minWidth: '5rem',
    marginRight: theme.spacing(1),
  },
  switch: {
    padding: 6,
    '& .MuiSwitch-track': {
      opacity: 1,
      borderRadius: 14,
      backgroundColor: '#8fd7fd',
      transition: 'all 0.5s cubic-bezier(0.52, 0.16, 0.24, 1)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '30%',
        left: '25%',
        borderRadius: '50%',
        width: '1em',
        height: '1em',
        color: 'rgba(255,255,255,0.5)',
        fontSize: 3,
        backgroundColor: 'currentColor',
        boxShadow: `
          -0.4em 4.2em 0 -0.2em,
          3.2em -1em 0 -0.35em,
          -1.7em 2em 0 -0.35em,
          1em 2.5em 0 -0.35em,
          2.8em 4.8em 0 -0.35em
        `,
        opacity: 0,
        transition: 'opacity 0.3s, transform 0.5s',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '53%',
        width: '1em',
        height: '1em',
        color: '#fff',
        fontSize: 6,
        backgroundColor: 'currentColor',
        borderRadius: '1em',
        transformOrigin: 'left',
        boxShadow: `
          0.3em -0.4em 0 -0.05em,
          1em -0.6em 0 0.1em,
          0.1em 0,
          0.2em 0,
          0.3em 0,
          0.4em 0,
          0.5em 0,
          0.6em 0,
          0.7em 0,
          0.8em 0,
          0.9em 0,
          1em 0,
          1.2em 0,
          1.3em 0,
          1.4em 0,
          1.5em 0
        `,
        transition: 'opacity 0.3s, transform 0.5s',
      },
    },
    '& .MuiSwitch-thumb': {
      transition: 'all 1s cubic-bezier(0.52, 0.16, 0.24, 1)',
      backgroundColor: '#ffe001',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
    '& .Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#799cd4',
        '&::before': {
          opacity: 0,
          transform: 'translate(30%)',
        },
        '&::after': {
          opacity: 1,
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#ffd283',
      },
    },
  },
}));

export default DateTimeTextField;
