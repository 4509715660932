import { CURRENCIES } from '../../constants';
import { Currency } from '../../types';

export function getCurrency(currencyCode: string): Currency | null {
  const result = CURRENCIES.find(
    (currency) => currency.code.toLowerCase() === currencyCode.toLowerCase(),
  );

  return result || null;
}
