// Material UI
import AvatarGroup, { AvatarGroupProps } from '@material-ui/lab/AvatarGroup';
import Box, { BoxProps } from '@material-ui/core/Box';
import { AvatarProps } from '@material-ui/core/Avatar';
// Lib Shared
import ParticipantAvatar from './MeetingParticipantAvatar';

export interface MeetingParticipant {
  id: string;
  fullName: string | null;
  email: string | null;
  avatar: string | null;
}

export type MeetingParticipantAvatarsProps = BoxProps & {
  avatarGroupProps?: AvatarGroupProps;
  avatarProps?: AvatarProps;
  participants: MeetingParticipant[];
};

export const MeetingParticipantAvatars: React.VFC<MeetingParticipantAvatarsProps> = ({
  avatarGroupProps,
  avatarProps,
  participants,
  ...props
}) => {
  return (
    <Box display="flex" flexGrow={0} {...props}>
      <AvatarGroup max={3} {...avatarGroupProps}>
        {participants.map((item, index) => (
          <ParticipantAvatar
            {...avatarProps}
            key={`participant-avatar-${item.id}`}
            name={item.fullName || item.email || `Guest ${index + 1}`}
            avatar={item.avatar}
          />
        ))}
      </AvatarGroup>
    </Box>
  );
};

export default MeetingParticipantAvatars;
