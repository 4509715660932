import { useState, useEffect } from 'react';
// Material UI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// App Shared
import { LOCAL_STORAGE_KEYS } from '@shared/constants';
import { MobileAppPromotionDialog } from '@shared/dialogs';

export function MobileAppPromotionOverlay() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isPromotingMobileApp, setIsPromotingMobileApp] = useState(false);

  const handleClosePromotionDialog = () => {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.isPromotedMobileApp, 'true');
    setIsPromotingMobileApp(false);
  };

  useEffect(() => {
    if (isSmallScreen && !sessionStorage.getItem(LOCAL_STORAGE_KEYS.isPromotedMobileApp)) {
      setIsPromotingMobileApp(true);
    } else {
      setIsPromotingMobileApp(false);
    }
  }, [isSmallScreen]);

  return (
    <MobileAppPromotionDialog open={isPromotingMobileApp} onClose={handleClosePromotionDialog} />
  );
}

export default MobileAppPromotionOverlay;
