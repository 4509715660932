import { useState } from 'react';
// Material UI
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { GenericDialog } from '../components';

interface EditRecurringMeetingProps {
  onClose: () => void;
  onSubmit: (value: 'all' | 'single') => void;
}

export const EditRecurringMeeting: React.VFC<EditRecurringMeetingProps> = ({
  onClose,
  onSubmit,
}) => {
  const styles = useStyles();

  const [value, setValue] = useState<'all' | 'single'>('single');

  /* #region  Handlers */
  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    onSubmit(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as unknown as typeof value);
  };
  /* #endregion */

  return (
    <GenericDialog
      title="Edit recurring meeting"
      dialogProps={{
        maxWidth: 'sm',
        fullWidth: true,
        classes: { paper: styles.paper },
        onClick: (e) => e.stopPropagation(),
      }}
      onClose={handleCancel}
    >
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup aria-label="gender" name="options" value={value} onChange={handleChange}>
            <FormControlLabel
              value="single"
              control={<Radio size="small" />}
              label="This Meeting"
            />
            <FormControlLabel
              value="all"
              control={<Radio size="small" />}
              label="This and all future meetings from the series"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions className={styles.actions}>
        <Button variant="outlined" onClick={handleCancel}>
          <Typography variant="body1">Cancel</Typography>
        </Button>
        <Button disableElevation color="primary" variant="contained" onClick={handleSubmit}>
          <Typography variant="body1">Done</Typography>
        </Button>
      </DialogActions>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    background: theme.palette.background.default,
  },
  actions: {
    padding: theme.spacing(2, 3),
  },
}));

export default EditRecurringMeeting;
