import { lazyWithRetry } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

const IntegrationsModuleContainer = lazyWithRetry(
  () => import('./containers/IntegrationsModuleContainer'),
);

export const IntegrationsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Integrations,
    render: () => <IntegrationsModuleContainer />,
  },
};

export default IntegrationsModule;
