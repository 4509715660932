// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
// App Shared
import { TransitionSlide } from './TransitionSlide';

export interface GenericDialogProps {
  backButtonLabel?: string;
  dialogProps?: Partial<DialogProps>;
  disableTransition?: boolean;
  hideCloseIconButton?: boolean;
  hideTitle?: boolean;
  showBackButton?: boolean;
  title?: string;
  description?: string;
  onClose: (event: any, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  onClickOnGoBack?: () => void;
}

export const GenericDialog: React.FC<GenericDialogProps> = ({
  backButtonLabel,
  children,
  dialogProps,
  disableTransition = false,
  hideCloseIconButton = false,
  hideTitle = false,
  showBackButton = false,
  title,
  description,
  onClose,
  onClickOnGoBack = () => null,
}) => {
  const styles = useStyles();

  return (
    <Dialog
      open
      scroll="body"
      onClose={onClose}
      TransitionComponent={!disableTransition ? TransitionSlide : undefined}
      {...dialogProps}
    >
      {!hideTitle && (
        <DialogTitle disableTypography>
          <Box display="flex" alignItems="center" width="100%">
            {showBackButton && (
              <>
                {!!backButtonLabel ? (
                  <Button
                    size="large"
                    aria-label="go back"
                    className={styles.button}
                    startIcon={<ArrowBackIcon />}
                    onClick={onClickOnGoBack}
                  >
                    {backButtonLabel}
                  </Button>
                ) : (
                  <IconButton
                    aria-label="go back"
                    className={styles.button}
                    onClick={onClickOnGoBack}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                )}
              </>
            )}
            <Box flex={1}>
              {title && (
                <Typography
                  className={showBackButton ? styles.title : ''}
                  gutterBottom={!!description}
                  variant="h5"
                >
                  {title}
                </Typography>
              )}
              {!!description && (
                <Typography className={showBackButton ? styles.title : ''} variant="body1">
                  {description}
                </Typography>
              )}
            </Box>

            {!hideCloseIconButton && (
              <IconButton aria-label="close" className={styles.button} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[500],
  },
  title: {
    margin: theme.spacing(0, 1),
  },
}));

export default GenericDialog;
