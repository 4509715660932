import { lazyWithPreload } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const ConsentModuleContainer = lazyWithPreload(
  () => import('./containers/ConsentModuleContainer'),
);

export const ConsentModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.Consent,
    render: () => <ConsentModuleContainer />,
  },
};

export default ConsentModule;
