import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
// Sembly UI
import { YouTubeModal } from '@sembly-ui';
// Material UI
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
import FeedbackIcon from '@material-ui/icons/SmsFailedOutlined';
// App Shared Assets
import { ReactComponent as DiscoverIcon } from '@shared/assets/icon-discover-semblian-57.svg';
import { ReactComponent as EventIcon } from '@shared/assets/icon-event-cup-57.svg';
import { ReactComponent as SemblyIcon } from '@shared/assets/sembly-icon.svg';
import { ReactComponent as TriggerIcon } from '@shared/assets/resource-center-guide-icon.svg';
import { ReactComponent as UpdatesIcon } from '@shared/assets/see-all-updates-icon.svg';
// App Shared
import HelpCenterIcon from '@shared/icons/HelpCenter';
import PlayIcon from '@shared/icons/Play';
import { Achievements, Achievement, FeedbackDialogForm, MeetSemblianDialog } from '@shared/dialogs';
import { RESOURCE_CENTER_ELEMENTS } from '@shared/constants';
import { ResourceCenterSection, TutorialBtn } from '@shared/components';
import { Routes } from '@shared/enums';
// import { ga } from '@shared/utils';
import { useUserContext, useUserInterface } from '@shared/hooks';
// GraphQL Queries and Types
import rcNewsItemsPaginatedQuery from '@shared/queries/RcNewsItemsPaginated.graphql';
import { RcNewsItemsPaginated, RcNewsItemsPaginatedVariables } from '@gql-types';

export function ResourceCenterOverlay() {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isAnalyticsPage = !!useRouteMatch(Routes.Analytics);
  const isAssignmentsPage = !!useRouteMatch(Routes.MyAssignments);
  const isBookmarksPage = !!useRouteMatch(Routes.Bookmarks);
  const isHomePage = !!useRouteMatch(Routes.Home);
  const isMyAutomationsPage = !!useRouteMatch(Routes.MyAutomations);
  const isMyChatsPage = !!useRouteMatch(Routes.MyChats)?.isExact;
  const isMyWorkstreamsPage = !!useRouteMatch(Routes.MyWorkstreams);
  const isSharedMeetingsPage = !!useRouteMatch(Routes.SharedWithMe);
  const isWorkspaceAutomationsPage = !!useRouteMatch(Routes.WorkspaceAutomations);

  const user = useUserContext();
  const {
    currentAchievementType,
    isFeedbackDialogOpen,
    isOpenAchievements,
    isOpenResourceCenter,
    update,
  } = useUserInterface();

  const [tutorialIndex, setTutorialIndex] = useState<number | null>(null);
  const [isOpenDiscoverSemblian, setIsOpenDiscoverSemblian] = useState(false);

  const { data, loading } = useQuery<RcNewsItemsPaginated, RcNewsItemsPaginatedVariables>(
    rcNewsItemsPaginatedQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: { page: 1, perPage: 2, orderBy: '-date' },
      skip: !isOpenResourceCenter,
    },
  );
  /* #endregion */

  /* #region  Handlers */
  const handleCloseDrawer = () => {
    update({ isOpenResourceCenter: false });
  };

  const handleCloseAchievement = () => {
    update({ currentAchievementType: null });
  };

  const handleToggleAchievements = (value: boolean) => () => {
    update({
      isOpenAchievements: value,
      isOpenResourceCenter: false,
    });
  };

  const handleToggleDiscoverSemblian = (value: boolean) => () => {
    setIsOpenDiscoverSemblian(value);
    update({ isOpenResourceCenter: false });
  };

  const handleOpenGettingStarted = () => {
    if (isSmallScreen) {
      update({ isOpenIntroduction: true, isOpenResourceCenter: false });
    } else {
      update({ isOpenIntroduction: true });
    }
  };

  const handleOpenTutorials = () => {
    if (isSmallScreen) {
      update({ isOpenTutorial: true, isOpenResourceCenter: false });
    } else {
      update({ isOpenTutorial: true });
    }
  };

  const handleOpenResourceCenter = () => {
    update({ isOpenResourceCenter: true });
  };

  const handleOpenZendeskWidget = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    update({ isOpenResourceCenter: false });
  };

  const handleToggleUpdatesDialog = (value: boolean) => () => {
    update({ isOpenNewsroom: value });
  };

  const handleToggleFeedbackForm = (value: boolean) => () => {
    update({ isFeedbackDialogOpen: value, isOpenResourceCenter: false });
  };

  const handleClickOnHelpCenter = () => {
    // ga.event({ category: 'Support', action: 'Open Help Center' });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const hasUnreadRcNews = user.data?.me?.hasUnreadRcNews || false;
  const news = data?.rcNewsItemsPaginated?.objects?.slice(0, 2) || [];
  const { promotedPlatforms, socialMedia, support, tutorials } = RESOURCE_CENTER_ELEMENTS;
  const isAiAssociateTrialActivated = !!user.data?.me?.paymentCustomer?.isAiAssociateTrialActivated;
  const hasAiAssociateSubscription = !!user.data?.me?.paymentCustomer?.aiAssociateSubscription;

  const hasTrigger =
    isAnalyticsPage ||
    isAssignmentsPage ||
    isBookmarksPage ||
    isHomePage ||
    isMyAutomationsPage ||
    isMyChatsPage ||
    isMyWorkstreamsPage ||
    isSharedMeetingsPage ||
    isWorkspaceAutomationsPage;

  const renderPromotedPlatforms = () => (
    <>
      {promotedPlatforms.map(({ icon, link, text }) => (
        <Button
          fullWidth
          disableElevation
          key={text}
          href={link}
          startIcon={icon}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.listItem}
          aria-label="Follow the link"
        >
          {text}
        </Button>
      ))}
    </>
  );

  const renderSupportLinks = () => (
    <>
      <Button
        fullWidth
        disableElevation
        target="_blank"
        rel="noopener noreferrer"
        href="https://sembly-ai.zendesk.com/hc/en-us"
        className={styles.listItem}
        startIcon={<HelpCenterIcon />}
        aria-label="Follow the link"
        onClick={handleClickOnHelpCenter}
      >
        Help center
      </Button>
      {support.map((item, index) =>
        !item.link ? (
          <Button
            fullWidth
            disableElevation
            key={index}
            className={styles.listItem}
            startIcon={item.icon}
            aria-label="Request support"
            onClick={handleOpenZendeskWidget}
          >
            {item.text}
          </Button>
        ) : (
          <Button
            fullWidth
            disableElevation
            target="_blank"
            rel="noopener noreferrer"
            href={item.link}
            key={index}
            className={styles.listItem}
            startIcon={item.icon}
            aria-label="Follow the link"
          >
            {item.text}
          </Button>
        ),
      )}
      <Button
        disableElevation
        fullWidth
        startIcon={<FeedbackIcon />}
        className={styles.listItem}
        aria-label="Submit feedback"
        onClick={handleToggleFeedbackForm(true)}
      >
        Submit feedback
      </Button>
    </>
  );

  const renderSocialsEl = () => (
    <Box display="flex">
      {socialMedia.map(({ icon, link, text }) => (
        <Button
          disableElevation
          key={text}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLink}
          aria-label="Follow the link"
        >
          {icon}
        </Button>
      ))}
    </Box>
  );
  /* #endregion */

  return (
    <>
      {hasTrigger && (
        <div className={styles.trigger}>
          <Button
            disableElevation
            variant="contained"
            className={styles.button}
            startIcon={<TriggerIcon />}
            aria-label="Open resource center"
            onClick={handleOpenResourceCenter}
          >
            Guide
            {hasUnreadRcNews ? <div className={styles.mark} /> : null}
          </Button>
        </div>
      )}

      <Drawer
        anchor="right"
        open={isOpenResourceCenter}
        onClose={handleCloseDrawer}
        classes={{ paper: styles.paper }}
      >
        {/* Drawer Header */}
        <Box className={styles.header}>
          <Typography variant="h6">Resource Center</Typography>
          <IconButton classes={{ root: styles.iconBtn }} onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Drawer Content */}
        <Box padding={2}>
          <ResourceCenterSection title="What’s New">
            <>
              <Box width="100%" overflow="hidden">
                <Box mb={2} display="flex" gridGap={6}>
                  {loading ? (
                    <Box display="flex" justifyContent="center" width="100%" py={3}>
                      <CircularProgress size={24} />
                    </Box>
                  ) : (
                    <>
                      {news.map((item) => (
                        <Box
                          key={item.id}
                          height={108}
                          className={styles.imgPreviewBox}
                          style={{ backgroundImage: `url(${item?.image})` }}
                        />
                      ))}
                    </>
                  )}
                </Box>
              </Box>
              {!!news.length && (
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<UpdatesIcon />}
                  aria-label="Open the News Center to see all updates"
                  onClick={handleToggleUpdatesDialog(true)}
                >
                  {hasUnreadRcNews && <div className={styles.dot} />}
                  <span>See all updates</span>
                </Button>
              )}
            </>
          </ResourceCenterSection>
          <ResourceCenterSection title="Discover Sembly">
            <div className={styles.discover}>
              <div className={styles.discoverItem}>
                <SemblyIcon />
                <Button
                  fullWidth
                  className={styles.discoverButton}
                  aria-label="Open introduction to Sembly"
                  onClick={handleOpenGettingStarted}
                >
                  Get started
                </Button>
              </div>
              <div className={styles.discoverItem}>
                <EventIcon />
                <Button
                  fullWidth
                  className={styles.discoverButton}
                  aria-label="Open level-up achievements"
                  onClick={handleToggleAchievements(true)}
                >
                  Level up
                </Button>
              </div>
            </div>
            {!isAiAssociateTrialActivated && !hasAiAssociateSubscription && (
              <>
                <Box mt={1} />
                <div className={styles.discover}>
                  <div className={styles.discoverItem}>
                    <Badge
                      className={styles.badge}
                      overlap="rectangular"
                      color="error"
                      variant="standard"
                    >
                      New
                    </Badge>
                    <DiscoverIcon />
                    <Button
                      fullWidth
                      className={styles.discoverButton}
                      aria-label="Discover Semblian 2.0"
                      onClick={handleToggleDiscoverSemblian(true)}
                    >
                      Discover Semblian 2.0
                    </Button>
                  </div>
                </div>
              </>
            )}
          </ResourceCenterSection>
          <ResourceCenterSection title="Tutorials">
            <>
              <Box mb={1}>
                {tutorials.map(({ description, icon, title, url }, index) => (
                  <TutorialBtn
                    key={index}
                    description={description}
                    icon={icon}
                    title={title}
                    url={url}
                    onClick={() => setTutorialIndex(index)}
                  />
                ))}
              </Box>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<PlayIcon />}
                aria-label="Open all tutorials"
                onClick={handleOpenTutorials}
              >
                Watch all tutorials
              </Button>
            </>
          </ResourceCenterSection>
          <ResourceCenterSection title="Get Sembly">
            {renderPromotedPlatforms()}
          </ResourceCenterSection>
          <ResourceCenterSection title="Support">{renderSupportLinks()}</ResourceCenterSection>
          {renderSocialsEl()}
        </Box>
      </Drawer>

      {/* Begin: Dialogs */}
      {tutorialIndex !== null && (
        <YouTubeModal
          autoplay
          title={tutorials[tutorialIndex].title}
          videoSrc={tutorials[tutorialIndex].url}
          onClose={() => setTutorialIndex(null)}
        />
      )}
      {currentAchievementType && (
        <Achievement type={currentAchievementType} onClose={handleCloseAchievement} />
      )}
      {isOpenAchievements && <Achievements onClose={handleToggleAchievements(false)} />}
      {isOpenDiscoverSemblian && (
        <MeetSemblianDialog onClose={handleToggleDiscoverSemblian(false)} />
      )}
      <FeedbackDialogForm open={isFeedbackDialogOpen} onClose={handleToggleFeedbackForm(false)} />
      {/* End: Dialogs */}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  trigger: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
  },
  mark: {
    boxSizing: 'content-box',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 6,
    height: 6,
    border: `2px solid ${theme.palette.background.default}`,
    borderRadius: '50%',
    background: theme.palette.indication.dark,
  },
  badge: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0, 1),
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    borderRadius: theme.shape.borderRadius * 2,
  },
  button: {
    padding: theme.spacing(1, 2),
    color: theme.palette.surface.contrastText,
    background: theme.palette.surface.main,
    fontSize: theme.typography.body2.fontSize,
    '&:hover': {
      background: theme.palette.surface.dark,
    },
  },
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 1,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    background: theme.palette.background.default,
  },
  paper: {
    width: 360,
    background: theme.palette.background.default,
    color: theme.palette.common.black,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  discover: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  discoverItem: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.surface.main,
    color: theme.palette.surface.contrastText,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  discoverButton: {
    backgroundColor: theme.palette.surface.light,
    color: theme.palette.surface.contrastText,
    fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.surface.dark,
    },
  },
  iconBtn: {
    padding: 0,
  },
  imgPreviewBox: {
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  dot: {
    boxSizing: 'content-box',
    position: 'absolute',
    top: 0,
    right: 0,
    width: 6,
    height: 6,
    border: `2px solid ${theme.palette.background.default}`,
    borderRadius: '50%',
    background: theme.palette.indication.dark,
  },
  listItem: {
    justifyContent: 'start',
    '& svg': {
      fontSize: '24px !important',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  socialLink: {
    minWidth: 0,
    padding: theme.spacing(1 / 2),
    '& svg': {
      fontSize: '30px',
    },
  },
}));

export default ResourceCenterOverlay;
