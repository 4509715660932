import clsx from 'clsx';
// Material UI
import CheckIcon from '@material-ui/icons/CheckCircleSharp';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export interface AutomationManagerDialogProps {
  description: string;
  disabled?: boolean;
  icon: React.ReactNode;
  loading?: boolean;
  title: string;
  value: string | null;
  onClick?: () => void;
}

export const AutomationRuleCard: React.VFC<AutomationManagerDialogProps> = ({
  description,
  disabled = false,
  icon,
  loading = false,
  title,
  value,
  onClick = () => null,
}) => {
  const styles = useStyles();

  const handleClick = () => {
    if (!disabled && !loading) {
      onClick();
    }
  };

  const hasValue = !!value;

  return (
    <div
      className={clsx(styles.step, hasValue && 'active', (disabled || loading) && 'disabled')}
      onClick={handleClick}
    >
      {loading && <Skeleton variant="rect" animation="wave" className={styles.skeleton} />}
      <div className={styles.content}>
        {hasValue && <CheckIcon className={styles.mark} color="primary" />}
        <div className={styles.stepIcon}>{icon}</div>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
      <div className={styles.value}>
        <div className={clsx(styles.cta, disabled && 'disabled', hasValue && 'selected')}>
          {hasValue ? value : 'Select'}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  step: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    width: '20vw',
    maxWidth: 300,
    minWidth: 200,
    cursor: 'pointer',
    transition: theme.transitions.create(['box-shadow'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover:not(.disabled)': {
      boxShadow: theme.shadows[2],
    },
    '&.active': {
      backgroundColor: '#f0fbfa',
      borderColor: theme.palette.status.success.color,
    },
    '&.disabled': {
      cursor: 'default',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  skeleton: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
  content: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  value: {
    ...theme.typography.body1,
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  stepIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(1),
  },
  cta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    textWrap: 'balance',
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.short,
    }),
    ...theme.typography.body1,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.disabled': {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.grey[100],
      cursor: 'default',
      '&:hover': {
        color: theme.palette.text.disabled,
      },
    },
    '&.selected': {
      fontWeight: 500,
    },
  },
  mark: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

export default AutomationRuleCard;
