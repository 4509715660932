import React, { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
// Lib Shared
import { GenericDialog } from '../components/GenericDialog';

export interface AttendanceMessageProps {
  open: boolean;
  message?: string | null;
  userName: string;
  onClose: () => void;
  onSubmit: (message: string) => void;
}

export const AttendanceMessageDialog: React.VFC<AttendanceMessageProps> = ({
  open,
  message,
  userName,
  onClose,
  onSubmit,
}) => {
  const styles = useStyles();

  const defaultMessage = `Dear team,\n\nThanks for including me, but I’m unavailable to attend at the moment. I will review the meeting after Sembly Agent provides the results and get back to you with my input.\n\nBest,\n${userName}`;

  const [userMessage, setUserMessage] = useState(message || defaultMessage);
  const [messageCharsCount, setMessageCharsCount] = useState(
    message?.length || defaultMessage.length,
  );

  /* #region  Handlers */
  const handleCloseDialog = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(userMessage.trim());
    onClose();
  };

  const handleRemove = () => {
    onSubmit('');
    setUserMessage(defaultMessage);
    onClose();
  };

  const handleChangeContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    let content = e.target.value.trimStart();
    if (content.length >= MESSAGE_CHARS_MAX_COUNT) {
      content = content.slice(0, MESSAGE_CHARS_MAX_COUNT);
    }
    setMessageCharsCount(content.length);
    setUserMessage(content);
  };
  /* #endregion */

  return (
    <GenericDialog
      onClose={handleCloseDialog}
      title="Message to chat"
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm' }}
    >
      <DialogContent>
        <form>
          <div className={styles.textareaBlock}>
            <TextField
              fullWidth
              multiline
              minRows={4}
              maxRows={8}
              variant="filled"
              InputProps={{ classes: { root: styles.textField } }}
              value={userMessage}
              onChange={handleChangeContent}
            />
            <span className={styles.messageCharsCount}>
              {messageCharsCount}/{MESSAGE_CHARS_MAX_COUNT}
            </span>
          </div>
          <Box className={styles.actionButtons}>
            <Button
              disableElevation
              type="button"
              size="medium"
              color="secondary"
              variant="outlined"
              onClick={handleRemove}
            >
              Remove
            </Button>
            <Box className={styles.rightButtonsBlock}>
              <Button
                disableElevation
                type="button"
                size="medium"
                variant="outlined"
                onClick={handleCloseDialog}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                size="medium"
                color="primary"
                variant="contained"
                disabled={!userMessage.trim()}
                onClick={handleSubmit}
              >
                Done
              </Button>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </GenericDialog>
  );
};

const MESSAGE_CHARS_MAX_COUNT = 400;

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    margin: theme.spacing(4, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightButtonsBlock: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  messageCharsCount: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    right: theme.spacing(1),
  },
  textareaBlock: {
    position: 'relative',
  },
  textField: {
    borderRadius: theme.shape.borderRadius * 3,
    paddingBottom: theme.spacing(3),
  },
}));

export default AttendanceMessageDialog;
