import { SvgIcon } from '@material-ui/core';

export const VideoCamera = () => (
  <SvgIcon width="24" height="25" viewBox="0 0 24 25">
    <path
      d="M15 8.5V16.5H5V8.5H15ZM16 6.5H4C3.45 6.5 3 6.95 3 7.5V17.5C3 18.05 3.45 18.5 4 18.5H16C16.55 18.5 17 18.05 17 17.5V14L21 18V7L17 11V7.5C17 6.95 16.55 6.5 16 6.5Z"
      fill="black"
    />
  </SvgIcon>
);

export default VideoCamera;
