import { useState, useEffect, CSSProperties } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useForm, FormState } from 'react-hook-form';
import { formatISO } from 'date-fns';
import { useMutation } from '@apollo/client';
// Sembly UI
import { formatInTimeZone, LanguageSelects } from '@sembly-ui';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// App Shared
import { DateTimeTextField } from '@shared/components';
import { useUserContext } from '@shared/hooks';
import { Routes } from '@shared/enums';
import { MEETING_PLATFORM_URL_REGEXPS as RegExps } from '@shared/constants';
import { graphErrorHorsemen, SemblyGQLErrors, getAgentCallProcessedLink } from '@shared/utils';
// GraphQl Queries and Types
import myMeetingsQuery from '@shared/queries/MyMeetingOverviewsPaginated.graphql';
import scheduleMeetMutation from '@shared/queries/ScheduleMeetAgentCall.graphql';
import scheduleTeamsMutation from '@shared/queries/ScheduleTeamsAgentCall.graphql';
import scheduleWebexMutation from '@shared/queries/ScheduleWebexAgentCall.graphql';
import scheduleZoomMutation from '@shared/queries/ScheduleZoomAgentCall.graphql';
import { ScheduleMeetAgentCall,  ScheduleMeetAgentCallVariables } from '@gql-types/ScheduleMeetAgentCall'; // prettier-ignore
import { ScheduleTeamsAgentCall, ScheduleTeamsAgentCallVariables } from '@gql-types/ScheduleTeamsAgentCall'; // prettier-ignore
import { ScheduleWebexAgentCall, ScheduleWebexAgentCallVariables } from '@gql-types/ScheduleWebexAgentCall'; // prettier-ignore
import { ScheduleZoomAgentCall, ScheduleZoomAgentCallVariables } from '@gql-types/ScheduleZoomAgentCall'; // prettier-ignore
import { Languages } from '@gql-types/globalTypes';

export interface AgentCallScheduleFormProps {
  platform: 'webex' | 'zoom' | 'microsoft teams' | 'google meet';
  logo: string;
  logoStyle: CSSProperties;
  onClose: () => void;
}

type Response = null | {
  errors: SemblyGQLErrors;
  id: string | undefined;
  success: boolean;
};

type Variables =
  | ScheduleMeetAgentCallVariables
  | ScheduleTeamsAgentCallVariables
  | ScheduleZoomAgentCallVariables
  | ScheduleWebexAgentCallVariables;

export const AgentCallScheduleForm: React.VFC<AgentCallScheduleFormProps> = ({
  platform,
  onClose,
  logo,
  logoStyle,
}) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();

  const user = useUserContext();

  const { handleSubmit, setValue, formState, register } = useForm<Variables>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const [joinDate, setJoinDate] = useState<Date>();
  const [response, setResponse] = useState<Response>(null);
  const [url, setUrl] = useState('');
  /* #endregion */

  /* #region  Mutations */
  const refetchQueries = [myMeetingsQuery];
  const [submitMeet,   { loading: loadingMeet }] = useMutation<ScheduleMeetAgentCall, ScheduleMeetAgentCallVariables>(scheduleMeetMutation, { refetchQueries }); // prettier-ignore
  const [submitTeams,  { loading: loadingTeams }] = useMutation<ScheduleTeamsAgentCall, ScheduleTeamsAgentCallVariables>(scheduleTeamsMutation, { refetchQueries }); // prettier-ignore
  const [submitZoom,   { loading: loadingZoom }] = useMutation<ScheduleZoomAgentCall, ScheduleZoomAgentCallVariables>(scheduleZoomMutation, { refetchQueries }); // prettier-ignore
  const [submitWebex,  { loading: loadingWebex }] = useMutation<ScheduleWebexAgentCall, ScheduleWebexAgentCallVariables>(scheduleWebexMutation, { refetchQueries }); // prettier-ignore
  /* #endregion */

  // Component Helpers
  const VALIDATE = 'validate';
  const REQUIRED = 'required';
  const defaultMeetingTitle = `Meeting | ${formatInTimeZone(joinDate || new Date(), 'PPPP')}`;

  // Effects
  // updates default meeting title
  useEffect(() => {
    if (!formState.dirtyFields.meetingTitle) {
      setValue('meetingTitle', defaultMeetingTitle);
    }
  }, [defaultMeetingTitle, formState, setValue]);

  /* #region  Handlers */
  const validate = (value: string) => {
    const message = 'Please enter the correct link for the meeting.';

    const termsForZoom = [
      RegExps.outlookSafeLinkWithZoomRegExp,
      RegExps.zoomWithDomainAndPwdUrlRegExp,
      RegExps.zoomUrlRegExp,
      RegExps.zoomWithDomainUrlRegExp,
      RegExps.zoomWithCustomDomainAndPwdUrlRegExp,
    ];

    const termsForMicrosoftTeams = [
      RegExps.outlookSafeLinkWithTeemsRegExp,
      RegExps.teamsLiveUrlRegExp,
      RegExps.protectUsMimecastUrlRegExp,
      RegExps.teamsUrlRegExp,
    ];

    const termsForGoogleMeet = [RegExps.outlookSafeLinkWithMeetRegExp, RegExps.meetUrlRegExp];

    const regExpForZoom = termsForZoom.some((elem) => {
      return new RegExp(elem).test(value);
    });

    const regExpForMicrosoftTeams = termsForMicrosoftTeams.some((elem) => {
      return new RegExp(elem).test(value);
    });

    const regExpForGoogleMeet = termsForGoogleMeet.some((elem) => {
      return new RegExp(elem).test(value);
    });

    switch (platform) {
      case 'zoom':
        return regExpForZoom ? true : message;

      case 'microsoft teams':
        return regExpForMicrosoftTeams ? true : message;

      case 'google meet':
        return regExpForGoogleMeet ? true : message;

      case 'webex':
        return new RegExp(RegExps.webexUrlRegExp).test(value) ? true : message;
    }
  };

  const handleScheduleAgentCall = handleSubmit(async (variables) => {
    let result: Response = null;

    variables.startTime = joinDate ? formatISO(joinDate) : formatISO(new Date());
    // ga.event({ category: 'New Meeting', action: 'Agent Invited', label: platform });

    switch (platform) {
      case 'zoom': {
        const { url, ...rest } = variables as ScheduleZoomAgentCallVariables;
        const { data } = await submitZoom({
          variables: {
            ...rest,
            url: getAgentCallProcessedLink(platform, url) || url,
          },
        });
        if (data?.scheduleZoomAgentCall) {
          result = {
            errors: data.scheduleZoomAgentCall.errors,
            id: data.scheduleZoomAgentCall.agentCall?.meeting?.id,
            success: data.scheduleZoomAgentCall.success,
          };
        }
        break;
      }
      case 'google meet': {
        const { url, ...rest } = variables as ScheduleMeetAgentCallVariables;
        const { data } = await submitMeet({
          variables: {
            ...rest,
            url: getAgentCallProcessedLink(platform, url) || url,
          },
        });
        if (data?.scheduleGoogleMeetAgentCall) {
          result = {
            errors: data.scheduleGoogleMeetAgentCall.errors,
            id: data.scheduleGoogleMeetAgentCall.agentCall?.meeting?.id,
            success: data.scheduleGoogleMeetAgentCall.success,
          };
        }
        break;
      }
      case 'microsoft teams': {
        const { url, ...rest } = variables as ScheduleTeamsAgentCallVariables;
        const { data } = await submitTeams({
          variables: {
            ...rest,
            url: getAgentCallProcessedLink(platform, url) || url,
          },
        });
        if (data?.scheduleMsTeamsAgentCall) {
          result = {
            errors: data.scheduleMsTeamsAgentCall.errors,
            id: data.scheduleMsTeamsAgentCall.agentCall?.meeting?.id,
            success: data.scheduleMsTeamsAgentCall.success,
          };
        }
        break;
      }
      case 'webex': {
        const { data } = await submitWebex({ variables: variables as ScheduleWebexAgentCallVariables }); // prettier-ignore
        if (data?.scheduleWebexAgentCall) {
          result = {
            errors: data.scheduleWebexAgentCall.errors,
            id: data.scheduleWebexAgentCall.agentCall?.meeting?.id,
            success: data.scheduleWebexAgentCall.success,
          };
        }
        break;
      }
    }

    setResponse(result);

    if (!result?.success) {
      graphErrorHorsemen(result?.errors);
    }
  });

  const handleUpdateJoinDate = (date: Date) => {
    setJoinDate(date);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const value = event.clipboardData.getData('Text');
    const filteredUrl = getAgentCallProcessedLink(platform, value);
    setTimeout(() => {
      setUrl(filteredUrl || value);
    }, 100);
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const handleClose = () => {
    onClose();
  };
  /* #endregion */

  /* #region  Render Helpers */
  const workspaceLanguageSettings = user.data?.me?.workspace?.languageSettings;
  const workspacePrimaryLanguage = workspaceLanguageSettings?.primaryLanguage || Languages.ENGLISH;
  const workspaceAdditionalLanguage = workspaceLanguageSettings?.additionalLanguage || null;
  const loading = loadingMeet || loadingTeams || loadingZoom || loadingWebex;
  const commonProps: TextFieldProps = {
    fullWidth: true,
    variant: 'outlined',
    margin: 'normal',
    InputProps: { classes: { root: styles.inputRoot } },
  };
  /* #endregion */

  /* #region  Render Assets */
  const renderControls = () => {
    let errors;

    switch (platform) {
      case 'google meet':
        errors = (formState as FormState<ScheduleMeetAgentCallVariables>).errors;
        break;
      case 'zoom':
        errors = (formState as FormState<ScheduleZoomAgentCallVariables>).errors;
        break;
      case 'microsoft teams':
        errors = (formState as FormState<ScheduleTeamsAgentCallVariables>).errors;
        break;
      case 'webex':
        errors = (formState as FormState<ScheduleWebexAgentCallVariables>).errors;
        break;
    }

    return (
      <>
        <TextField
          autoFocus
          label="Meeting URL"
          autoComplete="off"
          type="url"
          value={url}
          error={!!errors?.url}
          {...register('url', {
            onChange: handleChangeValue,
            required: true,
            setValueAs: (value) => value,
            validate,
          })}
          onPaste={handlePaste}
          {...commonProps}
        />
        {errors?.url?.type === REQUIRED && (
          <FormHelperText error variant="outlined">
            Meeting URL is required.
          </FormHelperText>
        )}
        {errors?.url?.type === VALIDATE && (
          <FormHelperText error variant="outlined">
            {errors.url.message}
          </FormHelperText>
        )}
      </>
    );
  };
  /* #endregion */

  /* #region  Render Success Dialog */
  if (response?.success) {
    return (
      <div className={styles.root}>
        <Box mb={2} ml={2}>
          <Box display="flex" alignItems="center" mb={4}>
            <Box mr={1}>
              <CheckCircleOutlineIcon fontSize="large" color="primary" />
            </Box>
            <Typography variant="h4" color="primary">
              Sembly successfully invited
            </Typography>
          </Box>
          {!!response.id && (
            <Box>
              <Typography variant="subtitle1">
                You can now go to the meeting and manage its details.
              </Typography>
            </Box>
          )}
          <Box display="flex" mt={4}>
            {!!response.id && (
              <Box mr={1}>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={generatePath(Routes.Meeting, { meetingId: response.id })}
                  aria-label="Go to Meeting"
                  onClick={handleClose}
                >
                  Go to Meeting
                </Button>
              </Box>
            )}
            <Button variant="outlined" aria-label="Close" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </div>
    );
  }
  /* #endregion */

  return (
    <div className={styles.root}>
      <Box
        height={120}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        mb={4}
        bgcolor="grey.100"
        borderRadius={6 * theme.shape.borderRadius}
      >
        <img src={logo} alt={platform} style={logoStyle} />
      </Box>

      <form onSubmit={handleScheduleAgentCall}>
        <fieldset disabled={loading}>
          <TextField
            label="Meeting Title"
            defaultValue={defaultMeetingTitle}
            error={!!formState.errors.meetingTitle}
            inputProps={{ maxLength: 255 }}
            {...register('meetingTitle', { required: true })}
            {...commonProps}
          />
          {formState.errors.meetingTitle?.type === REQUIRED && (
            <FormHelperText error variant="outlined">
              Meeting title is required.
            </FormHelperText>
          )}

          <DateTimeTextField
            fullWidth
            label="Join Date"
            variant="outlined"
            className={styles.date}
            error={!!formState.errors.startTime}
            onUpdate={handleUpdateJoinDate}
            {...register('startTime', { valueAsDate: true })}
          />

          {renderControls()}

          <Box py={2}>
            <Divider />
          </Box>

          <LanguageSelects
            primaryLanguage={workspacePrimaryLanguage}
            additionalLanguage={workspaceAdditionalLanguage}
            onChange={(primaryLang, additionalLang) => {
              setValue('languages.primaryLanguage', primaryLang);
              setValue('languages.additionalLanguage', additionalLang);
            }}
          />
        </fieldset>

        <div className={styles.actions}>
          <Box display="flex" flex={1}>
            <Button
              disableElevation
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading}
              classes={{ root: styles.buttonRoot }}
              aria-label="Schedule Meeting"
            >
              {loading ? <CircularProgress size={20} color="inherit" /> : <span>Submit</span>}
            </Button>
          </Box>
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1),
    },
  },
  actions: {
    display: 'flex',
    margin: theme.spacing(3, 0),
  },
  buttonRoot: {
    flex: 1,
    height: '48px',
  },
  date: {
    marginTop: theme.spacing(1),
  },
  inputRoot: {
    background: theme.palette.background.paper,
    '& fieldset': {
      border: `1px solid ${theme.palette.grey['A100']} !important`,
    },
  },
}));

export default AgentCallScheduleForm;
