import { useHistory, generatePath } from 'react-router-dom';
// Material Ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
// App Shared
import CanceledSubscriptionContainer from '@shared/containers/CanceledSubscriptionContainer';
import { Routes } from '@shared/enums';
import { useUserContext } from '@shared/hooks';

interface RemindCancelSubscriptionDialogProps {
  open: boolean;
  isTeam: boolean;
  onClose: () => void;
}

export const RemindCancelSubscriptionDialog: React.VFC<RemindCancelSubscriptionDialogProps> = ({
  open,
  isTeam,
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const history = useHistory();

  const user = useUserContext();

  /* #endregion */

  /* #region  Handlers */
  const handleClickOnActivateSubscription = () => {
    const planId = user.data?.me?.paymentCustomer?.plan?.id;

    if (!planId) {
      history.push(Routes.SettingsWorkspacePlans);
    } else {
      history.push(generatePath(Routes.CheckoutStart, { planId }));
    }

    onClose();
  };

  const handleSupport = () => {
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    onClose();
  };
  /* #endregion */

  /* #region  Render Helpers */
  const isSmartmeetingUser = user.data?.me?.philipsDevices?.smartmeeting ?? false;
  const smartmeetingUserText = `Your trial has expired, account will be limited to 4 free meetings per month. Activate your subscription to get full access to Sembly features.`;
  const text = isTeam
    ? 'Your subscription has been canceled. You have team members in your Workspace. Please renew your subscription or delete team members from your Workspace to switch to Sembly Personal.'
    : 'Your subscription has been canceled. Renew your subscription or switch to Sembly Personal now to keep access.';
  /* #endregion */

  return (
    <CanceledSubscriptionContainer open={open} onClose={onClose}>
      <>
        <Box mb={4} textAlign="center">
          <Typography paragraph variant="body1">
            {isSmartmeetingUser ? smartmeetingUserText : text}
          </Typography>
          <Box p={1} borderRadius={4} bgcolor="#d9f0fe" color="info.dark">
            <Typography variant="body1">
              Please note that switching to a personal plan may lead to data loss according to the
              plan's data retention policy
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Button
            className={styles.button}
            variant="outlined"
            aria-label="Contact support"
            onClick={handleSupport}
          >
            Contact Support
          </Button>
          <Box mr={2} />
          <>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              className={styles.button}
              aria-label="Activate Subscription"
              onClick={handleClickOnActivateSubscription}
            >
              {isSmartmeetingUser ? `Activate Subscription` : `Renew Subscription`}
            </Button>
          </>
        </Box>
      </>
    </CanceledSubscriptionContainer>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '160px',
  },
}));

export default RemindCancelSubscriptionDialog;
