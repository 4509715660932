/**
 * Checks if a string is right-to-left (RTL) or not.
 */
export function isRTLString(text: string): boolean {
  let rtlCharCount = 0;
  let totalCharCount = text.length;

  for (let i = 0; i < text.length; i++) {
    let charCode = text.charCodeAt(i);
    // Arabic, Hebrew, Persian/Farsi, Urdu
    if (
      (charCode >= 0x0590 && charCode <= 0x05ff) || // Hebrew
      (charCode >= 0x0600 && charCode <= 0x06ff) || // Arabic
      (charCode >= 0x0750 && charCode <= 0x077f) || // Arabic Supplement
      (charCode >= 0x08a0 && charCode <= 0x08ff) || // Arabic Extended-A
      (charCode >= 0xfb50 && charCode <= 0xfdff) || // Arabic Presentation Forms-A
      (charCode >= 0xfe70 && charCode <= 0xfeff) || // Arabic Presentation Forms-B
      (charCode >= 0x0600 && charCode <= 0x06FF)    // Urdu
    ) {
      rtlCharCount++;
    }
  }

  return rtlCharCount > totalCharCount / 4;
}


export default isRTLString;
