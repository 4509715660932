import { CompanySizeEnum } from '@gql-types';

export const COMPANY_SIZE_VALUE_MAPPING: Record<CompanySizeEnum, string> = {
  [CompanySizeEnum.SMALL]: 'Up to 15 employees',
  [CompanySizeEnum.MIDDLE]: '15 to 50 employees',
  [CompanySizeEnum.BIG]: '50 to 200 employees',
  [CompanySizeEnum.LARGE]: 'More than 200 employees',
};

export default COMPANY_SIZE_VALUE_MAPPING;
