// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
// Sembly UI
import { GenericDialog } from '@sembly-ui';

export interface AudioRecordingIsTooShortProps {
  open: boolean;
  onClose: () => void;
}

export const AudioRecordingIsTooShort: React.VFC<AudioRecordingIsTooShortProps> = ({
  open,
  onClose,
}) => {
  return (
    <GenericDialog
      dialogProps={{
        open: open,
        fullWidth: true,
        maxWidth: 'sm',
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box mt={6} mb={4} textAlign="center">
          <Typography gutterBottom variant="h5" color="primary">
            Sembly was unable to record your voice
          </Typography>
          <Typography variant="body1">Looks like your audio is too short.</Typography>
        </Box>
        <Box mb={6} textAlign="center">
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Close"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

export default AudioRecordingIsTooShort;
