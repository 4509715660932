import { Routes } from '@shared/enums';
import { matchPath } from 'react-router-dom';

export function isGuestAccessRoute(): boolean {
  const currentPath = window.location.pathname;
  const path = Routes.GuestMeeting;
  return !!matchPath(currentPath, { path });
}

export default isGuestAccessRoute;
