import { makeStyles } from '@material-ui/core/styles';
// Material UI
import LanguageIcon from '@material-ui/icons/Language';
// App Shared
import Twemoji from './Twemoji';
import { Languages } from '../types';
import { SUPPORTED_LANGUAGES } from '../constants';

export interface LanguageFlagProps {
  laguageCode: Languages | null;
}

/**
 * @name LanguageFlag
 * @description A component that renders a flag emoji based on the language code.
 * @param {LanguageFlagProps} props
 * @returns {JSX.Element}
 */
export const LanguageFlag: React.VFC<LanguageFlagProps> = ({ laguageCode }) => {
  const styles = useStyles();

  const getLanguageFlag = (code: typeof laguageCode) => {
    // prettier-ignore
    switch (code) {
      case 'DUTCH': return <>🇳🇱</>;
      case 'ENGLISH': return <>🇺🇸</>;
      case 'ENGLISH_AUSTRALIAN': return <>🇦🇺</>;
      case 'ENGLISH_BRITISH': return <>🇬🇧</>;
      case 'ENGLISH_FINANCE': return <>📈</>;
      case 'ENGLISH_INDIAN': return <>🇮🇳</>;
      case 'FRENCH': return <>🇫🇷</>;
      case 'GERMAN': return <>🇩🇪</>;
      case 'ITALIAN': return <>🇮🇹</>;
      case 'JAPANESE': return <>🇯🇵</>;
      case 'PORTUGUESE_PORTUGAL': return <>🇵🇹</>;
      case 'PORTUGUESE_BRAZIL': return <>🇧🇷</>;
      case 'SPANISH_SPAIN': return <>🇪🇸</>;
      // Beta languages enabled for testing period
      case 'ARABIC': return <>🇵🇸</>;
      case 'BOSNIAN': return <>🇧🇦</>;
      case 'BULGARIAN': return <>🇧🇬</>;
      case 'CHINESE': return <>🇨🇳</>;
      case 'CROATIAN': return <>🇭🇷</>;
      case 'CZECH': return <>🇨🇿</>;
      case 'DANISH': return <>🇩🇰</>;
      case 'FINNISH': return <>🇫🇮</>;
      case 'GREEK': return <>🇬🇷</>;
      case 'HEBREW': return <>🇮🇱</>;
      case 'HINDI': return <>🇮🇳</>;
      case 'HUNGARIAN': return <>🇭🇺</>;
      case 'INDONESIAN': return <>🇮🇩</>;
      case 'KOREAN': return <>🇰🇷</>;
      case 'MACEDONIAN': return <>🇲🇰</>;
      case 'MALAY': return <>🇲🇾</>;
      case 'NORWEGIAN': return <>🇳🇴</>;
      case 'POLISH': return <>🇵🇱</>;
      case 'ROMANIAN': return <>🇷🇴</>;
      case 'SLOVAK': return <>🇸🇰</>;
      case 'SWEDISH': return <>🇸🇪</>;
      case 'TAGALOG': return <>🇵🇭</>;
      case 'THAI': return <>🇹🇭</>;
      case 'TURKISH': return <>🇹🇷</>;
      case 'UKRAINIAN': return <>🇺🇦</>;
      case 'VIETNAMESE': return <>🇻🇳</>;
      default: return null;
    }
  };

  const icon = getLanguageFlag(laguageCode);

  return (
    <div className={styles.root} title={laguageCode ? SUPPORTED_LANGUAGES[laguageCode] : ''}>
      {!icon ? (
        <LanguageIcon className={styles.icon} color="action" fontSize="inherit" />
      ) : (
        <Twemoji options={{ className: styles.icon }}>{icon}</Twemoji>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 24,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  icon: {
    width: 18,
    height: 18,
  },
}));

export default LanguageFlag;
