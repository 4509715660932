import clsx from 'clsx';
// Material UI
import Box, { BoxProps } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';

export interface DetailedButtonProps extends BoxProps {
  title: string;
  subtitle?: string;
  icon: React.ReactElement;
}

export const DetailedButton: React.VFC<DetailedButtonProps> = ({
  title,
  subtitle,
  icon,
  ...boxProps
}) => {
  const styles = useStyles();

  return (
    <Box className={clsx(styles.root, !!boxProps.className && boxProps.className)} {...boxProps}>
      <div className={styles.icon}>{icon}</div>
      <Box flex={1} textAlign="left">
        <Typography gutterBottom={!!subtitle} variant="h6">
          {title}
        </Typography>
        {!!subtitle && (
          <Typography variant="body1" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Box>
      <Box flex={0} mx={3}>
        <ArrowIcon color="action" fontSize="inherit" />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2, 0),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    fontSize: 36,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default DetailedButton;
