// Material UI
import Fade from '@material-ui/core/Fade';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PinIcon from '../icons/Pin';
import PinOutline from '../icons/PinOutline';

export interface ChatCardMenuDesktopProps {
  allowPinning: boolean;
  anchorEl: HTMLElement | null;
  editable: boolean;
  pinned?: boolean;
  onClickOnDelete?: () => void;
  onClickOnEdit: (isActive: boolean) => void;
  onClickOnPin?: () => void;
  onClose: () => void;
  onMouseEnter?: () => void;
}

type EventType = 'pin' | 'edit' | 'delete';

export const ChatCardMenuDesktop: React.VFC<ChatCardMenuDesktopProps> = ({
  allowPinning,
  anchorEl,
  editable,
  pinned,
  onClickOnDelete,
  onClickOnEdit,
  onClickOnPin,
  onClose,
  onMouseEnter = () => null,
}) => {
  const styles = useStyles();

  /* #region  Handlers */
  const handleClickOnMenuItem = (eventType: EventType) => () => {
    onClose();
    // prettier-ignore
    switch (eventType) {
      case 'pin': onClickOnPin && onClickOnPin(); break;
      case 'edit': onClickOnEdit(true); break;
      case 'delete': onClickOnDelete && onClickOnDelete(); break;
    }
  };
  /* #endregion */

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      container={anchorEl}
      placement={'top-end'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onClose}
      className={styles.root}
    >
      <Fade in={!!anchorEl} timeout={!!anchorEl ? 20 : 0}>
        <MenuList className={styles.list}>
          {editable && allowPinning && (
            <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('pin')}>
              <Tooltip title={pinned ? 'Unpin' : 'Pin'} placement="top">
                <ListItemIcon className={styles.icon}>
                  {pinned ? <PinIcon fontSize="small" /> : <PinOutline fontSize="small" />}
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}

          {editable && (
            <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('edit')}>
              <Tooltip title="Rename" placement="top">
                <ListItemIcon className={styles.icon}>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}
          {editable && (
            <MenuItem dense className={styles.item} onClick={handleClickOnMenuItem('delete')}>
              <Tooltip title="Delete" placement="top">
                <ListItemIcon className={styles.icon}>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
              </Tooltip>
            </MenuItem>
          )}
        </MenuList>
      </Fade>
    </Popper>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(8),
    },
    list: {
      position: 'relative',
      zIndex: 999,
      top: theme.spacing(1),
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
    item: {
      padding: theme.spacing(1),
    },
    icon: {
      minWidth: 0,
    },
  };
});

export default ChatCardMenuDesktop;
