import sanitizeHtml from 'sanitize-html';
import { forwardRef } from 'react';
import ContentEditableArea, {
  Props,
  ContentEditableEvent as ContentEditableAreaEvent,
} from 'react-contenteditable';

export type ContentEditableEvent = ContentEditableAreaEvent;

export interface ContentEditableProps extends Props {
  dir?: string | 'none';
  maxLength?: number;
  isRTLDirected?: boolean;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
}

export const ContentEditable = forwardRef<any, ContentEditableProps>(
  (
    {
      dir = 'auto',
      html,
      isRTLDirected = false,
      maxLength,
      className,
      onKeyDown,
      onPaste,
      textAlign,
      ...restProps
    },
    ref,
  ) => {
    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
      const allowedKeyCodes = [
        'Delete',
        'Backspace',
        'ArrowLeft',
        'ArrowRight',
        'ArrowDown',
        'ArrowUp',
      ];
      const allowedControlKeyCodes = ['KeyA', 'KeyC', 'KeyV', 'KeyZ', 'KeyX'];
      const contentLength = event.currentTarget.textContent?.length ?? 0;

      if (maxLength !== undefined && contentLength >= maxLength) {
        const isControlKeyPressed = event.ctrlKey || event.metaKey;
        const isAllowedKeyCode = allowedKeyCodes.includes(event.code);
        const isAllowedCtrlKeyCode = allowedControlKeyCodes.includes(event.code);
        const isAllowedShortKey = isControlKeyPressed && isAllowedCtrlKeyCode;
        const isException = !(isAllowedKeyCode || isAllowedShortKey);

        if (isException) event.preventDefault();
      }

      if (onKeyDown) onKeyDown(event);
    };

    const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
      const clipboardDataText = event.clipboardData.getData('Text').length;
      const currentTextContent = event.currentTarget.textContent?.length ?? 0;
      const contentLength = clipboardDataText + currentTextContent;

      if (maxLength && contentLength > maxLength) {
        event.preventDefault();
      }

      if (onPaste) onPaste(event);
    };

    let style = {};
    if (isRTLDirected) style = { ...style, direction: 'rtl' };
    if (textAlign) style = { ...style, textAlign };

    return (
      <ContentEditableArea
        {...restProps}
        {...(dir !== 'none' ? { dir } : {})}
        className={`${className} notranslate`}
        style={style}
        ref={ref}
        html={sanitizeHtml(html)}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
      />
    );
  },
);

export default ContentEditable;
