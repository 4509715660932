import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const MeetingModuleContainer = lazyWithPreload(
  () => import('./containers/MeetingModuleContainer'),
);

export const MeetingModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Meeting,
    render: () => <MeetingModuleContainer />,
  },
};

export default MeetingModule;
