import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const DVTConnectModuleContainer = lazyWithPreload(
  () => import('./containers/DVTConnectModuleContainer'),
);

export const DVTConnect: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.DvtConnect,
    render: () => <DVTConnectModuleContainer />,
  },
};

export default DVTConnect;
