import { useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
// Material Icons
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/LinkSharp';
// Lib Generated Types
import {
  ApplyToMeetings,
  ChangeMeetingTeam,
  ChangeMeetingTeamVariables,
  ChangeMeetingType,
  ChangeMeetingTypeVariables,
  DeleteMeeting,
  DeleteMeetingVariables,
  EditMeetingDetails,
  EditMeetingDetailsVariables,
  GenericTeam,
  Languages,
  MeetingHeaderContainerQuery,
  MeetingHeaderContainerQueryVariables,
  MeetingStatuses,
  MeetingTypes,
  ValidateMeetingTypeChange,
  ValidateMeetingTypeChangeVariables,
} from '../types';
// Lib Queries
import changeMeetingTeamMutation from '../graphql/mutations/ChangeMeetingTeam.graphql';
import deleteMutation from '../graphql/mutations/DeleteMeeting.graphql';
import meetingNotesQuery from '../graphql/queries/MeetingNotesQuery.graphql';
import query from '../graphql/queries/MeetingHeaderContainerQuery.graphql';
import updateMeetingTypeMutation from '../graphql/mutations/ChangeMeetingType.graphql';
import updateMutation from '../graphql/mutations/EditMeetingDetails.graphql';
import validateTypeChangeQuery from '../graphql/queries/ValidateMeetingTypeChange.graphql';
// Lib Shared
import { CustomMeetingStatuses } from '../enums';
import { GraphError, PlanIDEnum } from '../types';
import { MeetingHeader, GenericConfirmation } from '../components';
import { MeetingIntegrationsMenu } from '../containers';
import { MeetingTypeManageDialog } from '../dialogs';
import { isAsianLang } from '../utils';

export interface MeetingHeaderContainerProps {
  meetingId: string;
  restrictMeetingExport?: boolean;
  restrict3rdPartyIntegrations: boolean;
  onClickOnDiscoverIntegrations?: () => void;
  onClickOnUpgradePlan?: () => void;
  onClickOnMeetingDetails: () => void;
  onClickOnMeetingExport?: () => void;
  onClickOnMeetingSharing: () => void;
  onDeleteMeeting: () => void;
  onResponseError?: (error: GraphError) => void;
  onPostingSuccess?: (msg: string, integrationType: string) => void;
}

export const MeetingHeaderContainer: React.VFC<MeetingHeaderContainerProps> = ({
  meetingId,
  restrictMeetingExport = false,
  restrict3rdPartyIntegrations,
  onClickOnDiscoverIntegrations = () => null,
  onClickOnMeetingDetails,
  onClickOnMeetingExport = () => null,
  onClickOnMeetingSharing,
  onClickOnUpgradePlan = () => null,
  onDeleteMeeting,
  onPostingSuccess = () => null,
  onResponseError = () => null,
}) => {
  /* #region  Hooks */
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [integrationMenuAnchor, setIntegrationMenuAnchor] = useState<HTMLElement | null>(null);
  const [nextMeetingType, setNextMeetingType] = useState<{
    type: MeetingTypes;
    variant: ApplyToMeetings;
  } | null>(null);

  const { data } = useQuery<MeetingHeaderContainerQuery, MeetingHeaderContainerQueryVariables>(
    query,
    { variables: { meetingId } },
  );

  const [validateTypeChange, { loading: isValidatingMeetingType }] = useLazyQuery<
    ValidateMeetingTypeChange,
    ValidateMeetingTypeChangeVariables
  >(validateTypeChangeQuery, {
    fetchPolicy: 'no-cache',
  });

  const [updateMeetingType, { loading: isUpdating }] = useMutation<
    ChangeMeetingType,
    ChangeMeetingTypeVariables
  >(updateMeetingTypeMutation);

  const [deleteMeeting, { loading: isDeletingMeeting }] = useMutation<
    DeleteMeeting,
    DeleteMeetingVariables
  >(deleteMutation);

  const [updateMeeting] = useMutation<EditMeetingDetails, EditMeetingDetailsVariables>(
    updateMutation,
  );

  const [changeMeetingTeam] = useMutation<ChangeMeetingTeam, ChangeMeetingTeamVariables>(
    changeMeetingTeamMutation,
  );
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnKeyMaker = () => {
    const endpoint = process.env.REACT_APP_KEYMAKER_ENDPOINT;
    if (!endpoint) throw new Error('Keymaker endpoint must be defined in environment variables');

    window.open(`https://${endpoint}?meeting_id=${meetingId}`, '_blank');
  };

  const handleToggleDeleteConfirmation = (state: boolean) => () => {
    setIsDeleteConfirmation(state);
  };

  const handleShowIntegrationMenu = (anchorEl: HTMLElement) => {
    setIntegrationMenuAnchor(anchorEl);
  };

  const handleCloseIntegrationsMenu = () => {
    setIntegrationMenuAnchor(null);
  };

  const handleUpdateMeetingTitle = async (meetingId: string, title: string) => {
    const currentMeeting = data?.meeting;

    if (!currentMeeting) throw new Error('Meeting data not found');

    const result = await updateMeeting({
      variables: { title, meetingId: +meetingId },
      optimisticResponse: {
        editMeetingDetails: {
          __typename: 'EditMeetingDetailsMutationPayload',
          success: true,
          errors: [],
          meeting: {
            ...currentMeeting,
            title,
          },
        },
      },
    });

    if (!result?.data?.editMeetingDetails?.success) {
      onResponseError(result?.data?.editMeetingDetails?.errors);
    }
  };

  const handleChangeMeetingTeam = async (
    meetingId: string,
    team: GenericTeam,
    type: 'all' | 'single',
  ) => {
    const currentMeeting = data?.meeting;

    if (!currentMeeting) throw new Error('Meeting data not found');

    const variant = type === 'all' ? ApplyToMeetings.ALL_MEETINGS : ApplyToMeetings.SINGLE;

    const result = await changeMeetingTeam({
      variables: { meetingId: meetingId, teamId: team.id, variant },
      optimisticResponse: {
        changeMeetingTeam: {
          __typename: 'ChangeMeetingTeamMutationPayload',
          success: true,
          errors: [],
          meetings: [{ ...currentMeeting, team }],
        },
      },
    });

    if (!result?.data?.changeMeetingTeam?.success) {
      onResponseError(result?.data?.changeMeetingTeam?.errors);
    }
  };

  const handleChangeMeetingType = async (
    meetingId: string,
    type: MeetingTypes,
    mode: 'all' | 'single',
  ) => {
    if (!data?.meeting) throw new Error('Meeting data not found');

    const result = await validateTypeChange({
      variables: { meetingId: meetingId, newMeetingType: type },
    });

    const variant = mode === 'all' ? ApplyToMeetings.ALL_MEETINGS : ApplyToMeetings.SINGLE;

    if (result?.data?.validateMeetingTypeChange?.canRegenerateMeetingMinutes) {
      setNextMeetingType({ type, variant });
    } else {
      const result = await updateMeetingType({
        variables: {
          variant,
          meeting: meetingId,
          newMeetingType: type,
          newMeetingMinutesLanguage: null,
          regenerateMeetingMinutes: false,
        },
        optimisticResponse: {
          changeMeetingType: {
            __typename: 'ChangeMeetingTypeMutationPayload',
            success: true,
            errors: [],
            meetings: [{ ...data?.meeting, meetingType: type }],
          },
        },
        refetchQueries: [{ query: meetingNotesQuery, variables: { meetingId } }],
      });

      setNextMeetingType(null);

      if (!result?.data?.changeMeetingType?.success) {
        onResponseError(result?.data?.changeMeetingType?.errors);
      }
    }
  };

  const handleCancelChangeMeetingType = () => {
    setNextMeetingType(null);
  };

  const handleDeleteMeeting = async () => {
    const { data } = await deleteMeeting({
      variables: { meetingId: +meetingId },
      optimisticResponse: {
        deleteMeeting: {
          __typename: 'DeleteMeetingMutationPayload',
          errors: [],
          success: true,
        },
      },
      update: (cache) => {
        const id = cache.identify({ __typename: 'DetailedMeetingType', id: meetingId });
        cache.evict({ id });
        cache.gc();
      },
    });

    if (!data?.deleteMeeting?.success) {
      onResponseError(data?.deleteMeeting?.errors);
    } else {
      onDeleteMeeting();
    }
  };
  /* #endregion */

  /* #region  Render Helpers */
  const accessItems = data?.meeting?.accessItems || [];
  const permissions = data?.meeting?.permissions;
  const meetingWorkspace = data?.meeting?.workspace;
  const meetingMainLang = data?.meeting?.processingResults?.mainLanguage;
  const automationsUserSettings = data?.myWorkspace?.automationsUserSettings;
  const userAutomationsEnabled = automationsUserSettings?.userAutomationsEnabled ?? false;
  const isRecordingProhibited = permissions?.isRecordingProhibited ?? false;
  const customMeetingStatus = CustomMeetingStatuses.limit_exceeded;
  const meetingStatus = isRecordingProhibited ? customMeetingStatus : data?.meeting?.status || null;
  const meetingType = data?.meeting?.meetingType || null;
  const userBillingAccessId = data?.me?.billingAccess?.id;
  const isSubmitted = meetingStatus === MeetingStatuses.submitted;
  const isFailed = meetingStatus === MeetingStatuses.failed;
  const isEditable = isSubmitted || isFailed;
  const isAuthorizedToExport = permissions?.canExport ?? false;
  const isAuthorizedToManage = permissions?.canManage ?? false;
  const isTeamPlan = userBillingAccessId === PlanIDEnum.TEAM;
  const isTeamPlusPlan = userBillingAccessId === PlanIDEnum.TEAM_PLUS;
  const isEnterprisePlan = userBillingAccessId === PlanIDEnum.ENTERPRISE;
  const isTeamsPlan = isTeamPlan || isTeamPlusPlan || isEnterprisePlan;
  const isUnsubmitted = data?.meeting?.status !== MeetingStatuses.submitted;
  const primaryMeetingWorkspaceLang = meetingWorkspace?.languageSettings?.primaryLanguage;
  const additionalMeetingWorkspaceLang = meetingWorkspace?.languageSettings?.additionalLanguage;
  const processingMainLang = data?.meeting?.processingResults?.mainLanguage as unknown as Languages;
  const meetingTeam = data?.meeting?.team || null;
  const isInContextWorkspace = data?.meeting?.isInContextWorkspace ?? false;

  const isExportable = isAsianLang(
    meetingMainLang || primaryMeetingWorkspaceLang || additionalMeetingWorkspaceLang,
  );

  const participants = accessItems
    .filter((item) => !item.isInvited)
    .map((item) => ({
      id: item.id,
      email: item.email,
      avatar: item.user?.avatar || null,
      fullName: item.user?.fullName || null,
    }));

  const menuItems = [
    {
      label: 'View details',
      icon: <InfoIcon fontSize="small" />,
      handler: onClickOnMeetingDetails,
      hidden: false,
    },
    {
      label: 'Export',
      icon: <GetAppIcon fontSize="small" />,
      handler: onClickOnMeetingExport,
      hidden: !isAuthorizedToExport || restrictMeetingExport || isUnsubmitted || isExportable,
    },
    {
      label: 'Delete',
      icon: <DeleteIcon fontSize="small" />,
      handler: handleToggleDeleteConfirmation(true),
      hidden: !isEditable || !isAuthorizedToManage,
    },
    {
      label: 'Open KeyMaker',
      icon: <LinkIcon fontSize="small" />,
      handler: handleClickOnKeyMaker,
      hidden: !data?.myWorkspace?.isForBetaTesting,
    },
  ];
  /* #endregion */

  return (
    <>
      <MeetingHeader
        agentCallPlatform={data?.meeting?.agentCall?.platform || null}
        callIssueCategory={data?.meeting?.agentCall?.callIssueCategory || null}
        isAuthorizedToExport={isAuthorizedToExport}
        isAuthorizedToManage={isAuthorizedToManage}
        isInContextWorkspace={isInContextWorkspace}
        isChangingMeetingType={isValidatingMeetingType || isUpdating}
        isHiddenMeetingTeam={!isTeamsPlan}
        isHiddenIntegrations={!userAutomationsEnabled}
        isRecurrentMeeting={data?.meeting?.isRecurrent || false}
        meetingFinishDate={data?.meeting?.finishedAt || null}
        meetingId={meetingId}
        meetingOwnerFullName={data?.meeting?.owner?.fullName || null}
        meetingParticipants={participants}
        meetingProcessingResults={data?.meeting?.processingResults || null}
        meetingStartDate={data?.meeting?.startedAt || null}
        meetingStatus={meetingStatus}
        meetingTeam={meetingTeam}
        meetingTitle={data?.meeting?.title || null}
        meetingType={nextMeetingType?.type || meetingType || null}
        menuItems={menuItems}
        onDeleteMeeting={handleToggleDeleteConfirmation(true)}
        onChangeMeetingTitle={handleUpdateMeetingTitle}
        onChangeMeetingTeam={handleChangeMeetingTeam}
        onChangeMeetingType={handleChangeMeetingType}
        onShareMeeting={onClickOnMeetingSharing}
        onClickOnUpgradePlan={onClickOnUpgradePlan}
        onShowIntegrationsMenu={handleShowIntegrationMenu}
      />
      {/* Begin: Dialogs */}
      <GenericConfirmation
        title="Are you sure you want to delete this meeting?"
        text="This meeting will be deleted immediately. You can't undo this action."
        confirmButtonLabel="Delete"
        open={isDeleteConfirmation}
        confirming={isDeletingMeeting}
        onCancel={handleToggleDeleteConfirmation(false)}
        onConfirm={handleDeleteMeeting}
      />
      {!!nextMeetingType && (
        <MeetingTypeManageDialog
          meetingId={meetingId}
          targetMeetingType={nextMeetingType.type}
          targetVariant={nextMeetingType.variant}
          primaryLang={processingMainLang || Languages.ENGLISH}
          onClose={handleCancelChangeMeetingType}
          onError={onResponseError}
        />
      )}
      {/* End: Dialogs */}
      {!!integrationMenuAnchor && (
        <MeetingIntegrationsMenu
          anchorEl={integrationMenuAnchor}
          meetingId={meetingId}
          restrict3rdPartyIntegrations={restrict3rdPartyIntegrations}
          onClickOnDiscoverIntegrations={onClickOnDiscoverIntegrations}
          onClose={handleCloseIntegrationsMenu}
          onError={onResponseError}
          onSuccess={onPostingSuccess}
        />
      )}
    </>
  );
};

export default MeetingHeaderContainer;
