import { MeetingTypes } from '../types';

type MeetingTypeElement = {
  label: string;
  value: MeetingTypes;
  spec: boolean;
};

type MeetingTypeCategory = {
  category: string;
  elements: MeetingTypeElement[];
};

/**
 * An categorization of meeting types.
 * Each category contains an array of meeting elements, each with a label and value.
 */
export const MEETING_TYPES: MeetingTypeCategory[] = [
  {
    category: 'Generic',
    elements: [{ label: 'Generic', value: MeetingTypes.GENERIC, spec: false }],
  },
  {
    category: 'Product & Project',
    elements: [
      {
        label: 'Strategy / Brainstorming',
        value: MeetingTypes.STRATEGY_OR_BRAINSTORMING,
        spec: false,
      },
      {
        label: 'Roadmap Development',
        value: MeetingTypes.ROADMAP_DEVELOPMENT,
        spec: false,
      },
      {
        label: 'Requirements Gathering',
        value: MeetingTypes.REQUIREMENTS_GATHERING,
        spec: false,
      },
      {
        label: 'Workstream / Project Kickoff',
        value: MeetingTypes.WORKSTREAM_OR_PROJECT_KICKOFF,
        spec: true,
      },
      {
        label: 'Daily Standup / Status',
        value: MeetingTypes.DAILY_STANDUP_OR_STATUS,
        spec: true,
      },
      {
        label: 'Weekly Update / Status',
        value: MeetingTypes.WEEKLY_STATUS_OR_UPDATE,
        spec: true,
      },
      {
        label: 'Implementation Planning',
        value: MeetingTypes.IMPLEMENTATION_PLANNING,
        spec: true,
      },
      {
        label: 'Change Management Planning',
        value: MeetingTypes.CHANGE_MANAGEMENT_PLANNING,
        spec: false,
      },
      {
        label: 'Sprint / Release Review',
        value: MeetingTypes.SPRINT_OR_RELEASE_REVIEW,
        spec: true,
      },
      {
        label: 'Retrospective',
        value: MeetingTypes.RETROSPECTIVE,
        spec: true,
      },
    ],
  },
  {
    category: 'Client & Sales',
    elements: [
      {
        label: 'Demo',
        value: MeetingTypes.DEMO,
        spec: true,
      },
      {
        label: 'Discovery',
        value: MeetingTypes.DISCOVERY,
        spec: true,
      },
      {
        label: 'Agreement Draft / Review',
        value: MeetingTypes.AGREEMENT_DRAFT_OR_REVIEW,
        spec: false,
      },
      {
        label: 'Agreement Revise / Extend',
        value: MeetingTypes.AGREEMENT_REVISE_OR_EXTEND,
        spec: false,
      },
      {
        label: 'Customer Touchpoint',
        value: MeetingTypes.CUSTOMER_TOUCHPOINT,
        spec: false,
      },
      {
        label: 'Feedback Session',
        value: MeetingTypes.FEEDBACK_SESSION,
        spec: false,
      },
    ],
  },
  {
    category: 'Organization & Management',
    elements: [
      {
        label: 'Strategy Development',
        value: MeetingTypes.STRATEGY_DEVELOPMENT,
        spec: false,
      },
      {
        label: 'Project Portfolio Planning',
        value: MeetingTypes.PROJECT_PORTFOLIO_PLANNING,
        spec: false,
      },
      {
        label: 'Budget / Financial Planning',
        value: MeetingTypes.BUDGET_OR_FINANCIAL_PLANNING,
        spec: false,
      },
      {
        label: 'Project Portfolio Review',
        value: MeetingTypes.PROJECT_PORTFOLIO_REVIEW,
        spec: false,
      },
      {
        label: 'Budget / Financial Review',
        value: MeetingTypes.BUDGET_OR_FINANCIAL_REVIEW,
        spec: false,
      },
      {
        label: 'Townhall / All-Hands',
        value: MeetingTypes.TOWNHALL_OR_ALL_HANDS,
        spec: true,
      },
      {
        label: 'Board Meeting',
        value: MeetingTypes.BOARD_MEETING,
        spec: false,
      },
    ],
  },
  {
    category: 'Operations',
    elements: [
      { label: 'Solution Planning', value: MeetingTypes.SOLUTION_PLANNING, spec: false },
      { label: 'Change Planning', value: MeetingTypes.CHANGE_PLANNING, spec: false },
      { label: 'Security Review', value: MeetingTypes.SECURITY_REVIEW, spec: false },
      { label: 'Issue Troubleshooting', value: MeetingTypes.ISSUE_TROUBLESHOOTING, spec: false },
      { label: 'Compliance Activities', value: MeetingTypes.COMPLIANCE_ACTIVITIES, spec: false },
      { label: 'Emergency / Crisis', value: MeetingTypes.EMERGENCY_OR_CRISIS, spec: false },
    ],
  },
  {
    category: 'HR',
    elements: [
      {
        label: '1-on-1',
        value: MeetingTypes.ONE_ON_ONE,
        spec: false,
      },
      {
        label: 'Interview / Recruiting',
        value: MeetingTypes.INTERVIEW_OR_RECRUITING,
        spec: false,
      },
      {
        label: 'Performance Review',
        value: MeetingTypes.PERFORMANCE_REVIEW,
        spec: false,
      },
      {
        label: 'Onboarding',
        value: MeetingTypes.ONBOARDING,
        spec: false,
      },
      {
        label: 'Offboarding / Exit interview',
        value: MeetingTypes.OFFBOARDING_OR_EXIT_INTERVIEW,
        spec: false,
      },
    ],
  },
  {
    category: 'Training & Education',
    elements: [
      {
        label: 'Training / Workshop',
        value: MeetingTypes.TRAINING_OR_WORKSHOPS,
        spec: false,
      },
      {
        label: 'Lecture',
        value: MeetingTypes.LECTURE,
        spec: false,
      },
      {
        label: 'Q&A Session',
        value: MeetingTypes.Q_AND_A_SESSION,
        spec: false,
      },
      {
        label: 'Webinar',
        value: MeetingTypes.WEBINARS,
        spec: false,
      },
      {
        label: 'Panel / Discussion Forum',
        value: MeetingTypes.PANELS_OR_DISCUSSION_FORUMS,
        spec: false,
      },
    ],
  },
];

export default MEETING_TYPES;
