// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// Icons
import semblyIcon from './assets/sembly-icon.svg';
import appStoreIcon from './assets/app-store-icon.svg';
import googlePlayIcon from './assets/google-play-icon.svg';

export interface MobileAppPromotionDialogProps {
  open: boolean;
  onClose: () => void;
}

export const MobileAppPromotionDialog: React.VFC<MobileAppPromotionDialogProps> = ({
  open,
  onClose,
}) => {
  const styles = useStyles();
  const isIosDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  return (
    <GenericDialog
      dialogProps={{
        open: open,
        fullWidth: true,
        maxWidth: 'xs',
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box textAlign="center">
          <img src={semblyIcon} width="48" height="48" alt="Smartmike icon" />

          <Typography className={styles.title} gutterBottom variant="h5">
            Get Sembly App
          </Typography>

          <Typography className={styles.subtitle} variant="body1">
            Sembly records, transcribes and generates smart meeting summaries with meeting notes.
          </Typography>
        </Box>
        <Box mb={6} textAlign="center">
          {isIosDevice ? (
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/ua/app/sembly-ai/id1618211441"
              className={styles.button}
              variant="outlined"
              aria-label="Download thge app from the App Store"
              onClick={onClose}
            >
              <img className={styles.icon} src={appStoreIcon} alt="SemblyAI for IOS" /> Download on
              the App Store
            </Button>
          ) : (
            <Button
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.semblyai.android"
              className={styles.button}
              variant="outlined"
              aria-label="Download thge app from the Play Store"
              onClick={onClose}
            >
              <img className={styles.icon} src={googlePlayIcon} alt="SemblyAI for Android" /> Get it
              on Google Play
            </Button>
          )}
          <Button
            className={styles.buttonClose}
            variant="outlined"
            aria-label="Not now"
            onClick={onClose}
          >
            Not now
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  button: {
    width: '100%',
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  subtitle: {
    margin: theme.spacing(2, 0),
  },
  buttonClose: {
    width: '100%',
  },
}));

export default MobileAppPromotionDialog;
