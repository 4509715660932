import clsx from 'clsx';
import sanitizeHtml from 'sanitize-html';
import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid, { GridProps } from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventIcon from '@material-ui/icons/EventSharp';
import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVertSharp';
import PersonIcon from '@material-ui/icons/Person';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// Lib Shared
import ContentEditable, { ContentEditableEvent } from './ContentEditable';
import logomark from '../assets/sembly-logomark.svg';
import { ASSIGNMENT_TYPES } from '../constants';
import { ActionItemType, ActionItemMenuType, GenericDefaultUser } from '../types';
import { LightningBolt as LightningBoltIcon } from '../icons';
import { formatInTimeZone, getNameInitials } from '../utils';

export interface AssignmentCardProps extends GridProps {
  assignee: GenericDefaultUser | string | null;
  customText: string;
  customeTitle: string;
  discussedWith: string | null;
  dueDate: Date | string | null;
  isActive: boolean;
  isAuthorizedToEdit?: boolean;
  isAuthorizedToExport?: boolean;
  isAuthorizedToViewMeeting?: boolean;
  isCompleted?: boolean;
  isEditedBySembly: boolean;
  isHiddenIntegrations?: boolean;
  isPerformer?: boolean;
  isPinned?: boolean;
  isRTLDirected?: boolean;
  isUnreaded?: boolean;
  keyItemId: string;
  meetingStartDate?: string | null;
  meetingTitle?: string | null;
  mode?: 'regular' | 'extended';
  performer: GenericDefaultUser | string | null;
  text: string;
  title: string;
  type: ActionItemType;
  workstreamName?: string;
  onChangeDiscussedWith?: (value: string) => void;
  onChangeText?: (value: string) => void;
  onChangeTitle?: (value: string) => void;
  onClickOnMeetingTitle?: () => void;
  onToggleIsCompleted?: () => void;
  onToggleMenu: (type: ActionItemMenuType, anchorEl: HTMLElement) => void;
}

/**
 * The component that renders a single assignment card
 */
export const AssignmentCard: React.VFC<AssignmentCardProps> = ({
  assignee,
  customText,
  customeTitle,
  discussedWith,
  dueDate,
  isActive = false,
  isAuthorizedToEdit = false,
  isAuthorizedToExport = false,
  isAuthorizedToViewMeeting = false,
  isCompleted,
  isEditedBySembly,
  isHiddenIntegrations = false,
  isPerformer = false,
  isPinned,
  isRTLDirected = false,
  isUnreaded = false,
  keyItemId,
  meetingStartDate,
  meetingTitle,
  mode = 'regular',
  performer,
  text,
  title,
  type,
  workstreamName,
  onChangeDiscussedWith = () => null,
  onChangeText = () => null,
  onChangeTitle = () => null,
  onClickOnMeetingTitle = () => null,
  onToggleIsCompleted = () => null,
  onToggleMenu = () => null,
  ...gridProps
}: AssignmentCardProps): JSX.Element => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const textInputRef = useRef<HTMLSpanElement | null>(null);
  const titleInputRef = useRef<HTMLSpanElement | null>(null);

  const [isTextInputFocused, setIsTextInputFocused] = useState(false);
  const [isTitleInputFocused, setIsTitleInputFocused] = useState(false);
  const [textInputValue, setTextInputValue] = useState('');
  const [titleInputValue, setTitleInputValue] = useState('');
  /* #endregion */

  /* #region  Handlers */
  const handleToggleMenu = (type: ActionItemMenuType) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    // restrictions
    if (type === 'assigneeMenu' && !(isAuthorizedToEdit || isPerformer)) return;
    if (type === 'performerMenu' && !(isAuthorizedToEdit || isPerformer)) return;
    if (type === 'dueDateMenu' && !(isAuthorizedToEdit || isPerformer)) return;
    if (type === 'desktopActionsMenu' && isSmallScreen) return;

    onToggleMenu(type, event.currentTarget);
  };

  const handleChangeIsCompleted = () => {
    onToggleIsCompleted();
  };

  const handleChangeTitleInputValue = (e: ContentEditableEvent) => {
    const updContent = sanitizeHtml(e.target.value, { allowedAttributes: {}, allowedTags: [] });
    setTitleInputValue(updContent);
  };

  const handleChangeTextInputValue = (e: ContentEditableEvent) => {
    const updContent = sanitizeHtml(e.target.value, { allowedAttributes: {}, allowedTags: [] });
    setTextInputValue(updContent);
  };

  const handleKeyDownOnTitleInput = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      titleInputRef.current?.blur();
    }
  };

  const handleKeyDownOnTextInput = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      textInputRef.current?.blur();
    }
  };

  const handleBlurOnTitleInput: React.FocusEventHandler<HTMLDivElement> = (e) => {
    const inputValue = e.target.innerText;
    if (inputValue === '') setTextInputValue(text);
    setIsTitleInputFocused(false);
    onChangeTitle(inputValue);
  };

  const handleBlurOnTextInput: React.FocusEventHandler<HTMLDivElement> = (e) => {
    const inputValue = e.target.innerText;
    if (inputValue === '') setTextInputValue(text);
    setIsTextInputFocused(false);
    onChangeText(inputValue);
  };

  const handleFocusOnTextInput = () => {
    setIsTextInputFocused(true);
  };

  const handleFocusOnTitleInput = () => {
    setIsTitleInputFocused(true);
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    setTextInputValue(customText || text);
  }, [customText, text]);

  useEffect(() => {
    setTitleInputValue(customeTitle || title);
  }, [customeTitle, title]);
  /* #endregion */

  const renderToolbox = () => (
    <Box mt={1} display="flex" alignItems="center" gridGap={6}>
      {!isHiddenIntegrations && (isAuthorizedToExport || isPerformer) && (
        <IconButton
          size="small"
          title="Integrations"
          aria-label="Integrations"
          className={styles.outlinedIconButton}
          onClick={handleToggleMenu('integrationsMenu')}
        >
          <LightningBoltIcon fontSize="small" />
        </IconButton>
      )}
      {isSmallScreen && (
        <IconButton
          size="small"
          title="more"
          aria-label="more"
          aria-haspopup="true"
          className={styles.outlinedIconButton}
          onClick={handleToggleMenu('mobileActionsMenu')}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );

  /* #region  Render Helpers */
  const card = ASSIGNMENT_TYPES[type];
  const accentColor = isActive ? card.color : alpha(card.color, 0.5);
  const isDateObject = dueDate && typeof dueDate === 'object';
  const isExtendedMode = mode === 'extended';
  const isEditedText = customText && textInputValue !== text;
  const isEditedTitle = customeTitle && titleInputValue !== title;
  const isEditabe = isAuthorizedToEdit || isPerformer;
  const isShowOriginalTextTooltip = !isTextInputFocused && isEditedText;
  const isShowOriginalTitleTooltip = !isTitleInputFocused && isEditedTitle;
  const isUnidentifiedAssignee = typeof assignee === 'string';
  const isUnidentifiedPerformer = typeof performer === 'string';
  const formattedDueDate = isDateObject ? format(dueDate, 'd MMM') : dueDate;
  const formattedStartDate = meetingStartDate ? formatInTimeZone(meetingStartDate, 'd MMM') : null;
  const performerName = isUnidentifiedPerformer ? performer : performer?.fullName;
  const assigneeName = isUnidentifiedAssignee ? assignee : assignee?.fullName;
  const showTitle = !!title;
  /* #endregion */

  // Property validation
  if (
    mode === 'extended' &&
    (typeof isCompleted === 'undefined' ||
      typeof isPinned === 'undefined' ||
      typeof meetingTitle === 'undefined')
  ) {
    throw new Error(
      `The props "isCompleted", "isPinned", "meetingTitle", "meetingStartDate" is required when "mode" is "extended"`,
    );
  }

  return (
    <Grid item className={styles.root} xs={12} {...gridProps}>
      {isUnreaded && <div className={styles.mark} />}
      <Box
        className={clsx(styles.container, isRTLDirected && 'rtl')}
        borderLeft={`3px solid ${accentColor}`}
        data-scroll-item-id={`${keyItemId}`}
        onMouseEnter={handleToggleMenu('desktopActionsMenu')}
      >
        {isExtendedMode && (
          <div className={clsx(styles.attributes, isRTLDirected && 'rtl')}>
            <Checkbox
              checked={isCompleted}
              classes={{ root: styles.checkbox }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              onChange={handleChangeIsCompleted}
            />
          </div>
        )}

        <div className={styles.items}>
          <div dir="auto">
            {/* Assignment Title */}
            {showTitle && (
              <Typography gutterBottom component="div" variant="subtitle1">
                <b>
                  <ContentEditable
                    tagName="div"
                    innerRef={titleInputRef}
                    html={titleInputValue || title}
                    maxLength={512}
                    disabled={!isEditabe}
                    className={styles.content}
                    onBlur={handleBlurOnTitleInput}
                    onFocus={handleFocusOnTitleInput}
                    onKeyDown={handleKeyDownOnTitleInput}
                    onChange={handleChangeTitleInputValue}
                  />
                </b>
                {isShowOriginalTitleTooltip ? (
                  <Tooltip placement="top" title={title} classes={{ tooltip: styles.tooltip }}>
                    <span className={styles.editedMark}>(original)</span>
                  </Tooltip>
                ) : null}
              </Typography>
            )}
            {/* Assignment Text */}
            <div>
              <ContentEditable
                tagName="div"
                innerRef={textInputRef}
                html={textInputValue}
                maxLength={512}
                disabled={!isEditabe}
                className={styles.content}
                onBlur={handleBlurOnTextInput}
                onFocus={handleFocusOnTextInput}
                onKeyDown={handleKeyDownOnTextInput}
                onChange={handleChangeTextInputValue}
              />
              {isShowOriginalTextTooltip ? (
                <Tooltip placement="top" title={text} classes={{ tooltip: styles.tooltip }}>
                  <span className={styles.editedMark}>
                    (
                    {isEditedBySembly && (
                      <img src={logomark} alt="Sembly" style={{ height: 10, marginRight: 2 }} />
                    )}
                    original)
                  </span>
                </Tooltip>
              ) : null}
            </div>
            {/* Meeting Title */}
            {isExtendedMode && !!meetingTitle && (
              <Box
                mt={1}
                display="flex"
                alignItems="center"
                fontSize="0.75rem"
                color="grey.500"
                width="100%"
              >
                <Box display="flex" alignItems="center">
                  <div onClick={isAuthorizedToViewMeeting ? onClickOnMeetingTitle : undefined}>
                    <Tooltip
                      placement="bottom"
                      title={
                        isAuthorizedToViewMeeting
                          ? 'Jump to the meeting'
                          : 'You don’t have permissions to view this meeting'
                      }
                    >
                      <Box display="flex" alignItems="center">
                        {!isAuthorizedToViewMeeting && <LockIcon className={styles.lock} />}
                        <ContentEditable
                          className={styles.meetingTitle}
                          style={{ cursor: isAuthorizedToViewMeeting ? 'pointer' : 'default' }}
                          disabled={true}
                          dir="none"
                          html={meetingTitle}
                          onChange={() => {}}
                        />
                      </Box>
                    </Tooltip>
                  </div>
                  {!!meetingStartDate && (
                    <Box display="flex" alignItems="center">
                      <Box mx={1} width={3} height={3} borderRadius="50%" bgcolor="grey.300" />
                      {formattedStartDate}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </div>
          {/* Desktop Toolbox */}
          {!isSmallScreen && renderToolbox()}
        </div>

        <div className={styles.actions}>
          {/* Assignee */}
          <div className={styles.action}>
            <div className={styles.actionLabel}>Assigned by</div>
            <Tooltip title={assigneeName || 'Not selected'} arrow>
              <Link
                className={clsx(styles.actionLink, isEditabe && 'editable')}
                onClick={handleToggleMenu('assigneeMenu')}
              >
                <Typography noWrap component="span" variant="inherit">
                  {assigneeName || 'Not selected'}
                </Typography>
                {!!assignee ? (
                  <Avatar
                    className={styles.avatar}
                    alt={assigneeName}
                    title={assigneeName}
                    src={!isUnidentifiedAssignee ? assignee.avatar || undefined : undefined}
                  >
                    {!isUnidentifiedAssignee && assignee.avatar
                      ? null
                      : getNameInitials(assigneeName || '')}
                  </Avatar>
                ) : (
                  <PersonIcon fontSize="small" color="action" />
                )}
              </Link>
            </Tooltip>
          </div>
          {/* Performer */}
          <div className={styles.action}>
            <div className={styles.actionLabel}>Assigned to</div>
            <Tooltip title={performerName || 'Not selected'} arrow>
              <Link
                className={clsx(styles.actionLink, isEditabe && 'editable')}
                onClick={handleToggleMenu('performerMenu')}
              >
                <Typography noWrap component="span" variant="inherit">
                  {performerName || 'Not selected'}
                </Typography>
                {!!performer ? (
                  <Avatar
                    className={styles.avatar}
                    alt={performerName}
                    title={performerName}
                    src={!isUnidentifiedPerformer ? performer.avatar || undefined : undefined}
                  >
                    {!isUnidentifiedPerformer && performer.avatar
                      ? null
                      : getNameInitials(performerName || '')}
                  </Avatar>
                ) : (
                  <PersonIcon fontSize="small" color="action" />
                )}
              </Link>
            </Tooltip>
          </div>
          {/* Due Date */}
          <div className={styles.action}>
            <div className={styles.actionLabel}>Deadline</div>
            <Tooltip title={formattedDueDate || 'Not selected'} arrow>
              <Link
                className={clsx(styles.actionLink, isEditabe && 'editable')}
                onClick={isEditabe ? handleToggleMenu('dueDateMenu') : undefined}
              >
                <Typography noWrap component="span" variant="inherit">
                  {formattedDueDate || 'Not selected'}
                </Typography>
                <EventIcon fontSize="small" color="action" />
              </Link>
            </Tooltip>
          </div>
          {/* Workstream */}
          {!!workstreamName && (
            <div className={styles.action}>
              <div className={styles.actionLabel}>Workstream</div>
              <Link className={clsx(styles.actionLink)}>
                <Typography noWrap component="span" variant="inherit">
                  {workstreamName}
                </Typography>
              </Link>
            </div>
          )}
        </div>

        {/* Mobile Toolbox */}
        {isSmallScreen && (
          <Box p={1} mb={1}>
            {renderToolbox()}
          </Box>
        )}
      </Box>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(0.5),
  },
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    transition: 'backgroundColor .2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.25),
      '&.highlighted': {
        backgroundColor: `${alpha(theme.palette.highlight.main, 0.25)} !important`,
      },
    },
    '&.rtl': {
      direction: 'rtl',
    },
  },
  attributes: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
    '&.rtl': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
  },
  items: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  content: {
    display: 'inline',
    position: 'relative',
    wordBreak: 'break-word',
    fontSize: 'inherit',
    '&:focus': {
      display: 'block',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'baseline',
    margin: theme.spacing(0, 1),
    flex: 1,
  },
  outlinedIconButton: {
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
  },
  type: {
    display: 'flex',
    margin: theme.spacing(0, 1),
    alignSelf: 'center',
    color: theme.palette.grey[300],
    fontSize: '1rem',
  },
  checkbox: {
    padding: 0,
    '& svg': {
      width: 20,
    },
    '&.Mui-checked svg': {
      color: 'rgb(165, 55, 168)',
    },
  },
  avatar: {
    width: 20,
    height: 20,
    fontSize: '0.6875rem',
  },
  editedMark: {
    marginLeft: theme.spacing(1 / 2),
    color: theme.palette.grey[300],
    cursor: 'default',
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body2.fontSize,
  },
  date: {
    padding: theme.spacing(0, 1.5),
    textAlign: 'center',
    lineHeight: '1.0',
  },
  actions: {
    width: '15vw',
    minWidth: 260,
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1),
    padding: theme.spacing(1, 1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0, 1),
      width: `calc(100% - ${theme.spacing(2)}px)`,
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.body2.fontSize,
    padding: theme.spacing(0.5, 0),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[200],
    direction: 'initial',
    '&:last-child': { borderBottom: 'none' },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
      overflow: 'hidden',
    },
  },
  actionLabel: {
    width: 110,
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionLink: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    fontSize: 'inherit',
    overflow: 'hidden',
    cursor: 'default',
    '&.editable': {
      cursor: 'pointer',
    },
    '&:not(.editable)': {
      color: theme.palette.grey[400],
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '&:not(:hover)': {
      color: theme.palette.grey[400],
    },
    '& input': {
      textAlign: 'right',
    },
  },
  lock: {
    marginRight: theme.spacing(0.5),
    marginBottom: 3,
    color: theme.palette.grey[300],
    fontSize: '1rem',
  },
  mark: {
    position: 'absolute',
    top: theme.spacing(0.5),
    left: -theme.spacing(1.25),
    width: 5,
    height: 5,
    backgroundColor: theme.palette.indication.dark,
    borderRadius: '50%',
  },
  meetingTitle: {
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '34vw',
    },
  },
}));

export default AssignmentCard;
