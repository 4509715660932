import { useState } from 'react';
// Material UI
import { ClickAwayListener, Divider, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// Lib
import { GenericMeetingOverview } from '../types';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';

export interface SearchBoxProps {
  data: GenericMeetingOverview[] | null | undefined;
  hasNextPage?: boolean;
  placeholder?: string;
  isFetching?: boolean;
  onChangeSearchTerm: ({ search }: { search: string }) => void;
  onRequestNextPage: () => void;
  onSelectSearchResult: (id: string) => void;
}

export const SearchBox: React.VFC<SearchBoxProps> = ({
  data,
  onChangeSearchTerm,
  onRequestNextPage,
  onSelectSearchResult,
  placeholder,
  hasNextPage = false,
  isFetching = false,
}) => {
  const styles = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleChangeSearchTerm = (search: string) => {
    setSearchTerm(search);
    onChangeSearchTerm({ search });
  };

  const handleClickOnResult = (id: string) => {
    onSelectSearchResult(id);
    setIsFocused(false);
  };

  const handleRequestNextPage = () => {
    onRequestNextPage();
  };

  const handleSetFocus = () => {
    setIsFocused(true);
  };

  const handleClickAway = () => {
    setIsFocused(false);
  };

  const hasRequest = !!searchTerm;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={styles.wrapper}>
        <div className={`${styles.root} ${isFocused && hasRequest ? styles.rootFocused : ''}`}>
          <SearchInput
            className={isFocused && hasRequest ? styles.inputExpanded : styles.inputCollapsed}
            isFetching={isFetching}
            onChange={handleChangeSearchTerm}
            placeholder={placeholder}
            onClick={handleSetFocus}
          />

          {!isFocused || !hasRequest ? null : (
            <>
              <Divider />
              {!data?.length ? (
                <div className={styles.container}>
                  {!isFetching && (
                    <Box m={2}>
                      <Typography variant="body1">Sorry, nothing matches your search.</Typography>
                    </Box>
                  )}
                </div>
              ) : (
                <SearchResults
                  className={styles.container}
                  data={data}
                  hasNextPage={hasNextPage}
                  onClickOnItem={handleClickOnResult}
                  onClickOnNextPage={handleRequestNextPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden',
    borderRadius: '2em',
    transition: 'box-shadow 100ms',
  },
  rootFocused: {
    boxShadow: theme.shadows[15],
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: theme.breakpoints.values.md,
    height: 40,
  },
  container: {
    borderBottomLeftRadius: '2em',
    borderBottomRightRadius: '2em',
    color: theme.palette.text.primary,
    left: 0,
    maxHeight: '80vh',
    overflow: 'auto',
    top: 1,
    width: '100%',
  },
  inputCollapsed: {
    transition: 'all 100ms',
  },
  inputExpanded: {
    boxShadow: theme.shadows[0],
    borderColor: 'transparent',
    borderRadius: 0,
    backgroundColor: 'inherit',
  },
}));

export default SearchBox;
