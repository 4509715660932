import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';

/**
 * Converts all the delimiter separated words in a String into camelCase,
 * that is each word is made up of a title case character and then a series
 * of lowercase characters.
 */
export function toCamelCase(value: string): string {
  return startCase(camelCase(value));
}

export default toCamelCase;
