import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';
import HomeModuleContainer from './containers/HomeModuleContainer';

export const HomeModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Home,
    render: () => <HomeModuleContainer />,
  },
};

export default HomeModule;
