// Material UI
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Components
import PlanStatsChip from './PlanStatsChip';

export interface PlanStatsListItemProps {
  bonus?: boolean;
  icon: React.ReactElement;
  label: string;
  tooltip?: string;
  value?: { usedMinutes: string; totalMinutes: string; type: string } | string;
}

export const PlanStatsListItem: React.FC<PlanStatsListItemProps> = ({
  bonus = false,
  children,
  icon,
  label,
  tooltip,
  value,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  /* #endregion */

  /* #region  Handlers */
  const valueTooltip =
    typeof value === 'object'
      ? `${value.usedMinutes} of ${value.totalMinutes} ${value.type} minutes left`
      : '';

  const valueText =
    typeof value === 'object' ? `${value.usedMinutes} / ${value.totalMinutes}` : value || '';
  /* #endregion */

  return (
    <div className={styles.item}>
      <div className={styles.image}>{icon}</div>
      <div className={styles.label}>
        <Typography
          component="div"
          display="inline"
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
          style={{ fontWeight: 500 }}
        >
          {label}
        </Typography>
        {tooltip && (
          <Tooltip placement="top" title={tooltip}>
            <InfoIcon color="disabled" fontSize="small" />
          </Tooltip>
        )}
      </div>
      {bonus && <PlanStatsChip>Bonus</PlanStatsChip>}
      {value && (
        <Tooltip title={valueTooltip} placement="top">
          <Typography noWrap component="div" variant="body1">
            {valueText}
          </Typography>
        </Tooltip>
      )}
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
  label: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  image: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.1)',
  },
}));

export default PlanStatsListItem;
