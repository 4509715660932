import { useState } from 'react';

export type UseConfirmationDialogHook = <T = boolean>(
  callbackFn: (resolverFn: (result: T) => void) => React.ReactElement,
) => [caller: () => Promise<T>, dialog: React.ReactElement | null];

/**
 * A hook that returns a function that can be called to trigger a confirmation dialog.
 * The function returns a promise that resolves to the result of the dialog.
 * @param callbackFn A function that returns a React element that will be rendered as a dialog.
 * @returns A tuple containing the trigger function and the dialog element.
 * @example
 * const [trigger, dialog] = useConfirmationDialog((resolve) => (
 *    <ConfirmationDialog
 *     title="Are you sure?"
 *     onConfirm={() => resolve(true)}
 *     onCancel={() => resolve(false)}
 *    />
 * ));
 * const result = await trigger();
 * if (result) {
 *   // do something
 * }
 */
export const useConfirmationDialog: UseConfirmationDialogHook = (resolver) => {
  const [dialog, setDialog] = useState<React.ReactElement | null>(null);

  const trigger = (): Promise<any> =>
    new Promise((resolve) => {
      setDialog(
        resolver((val) => {
          setDialog(null);
          resolve(val);
        }),
      );
    });

  return [trigger, dialog];
};

export default useConfirmationDialog;
