interface Options {
  deviceName?: string;
  deviceId?: string;
}

export const getMicrophonesList = async ({ deviceName = '', deviceId }: Options = {}): Promise<
  MediaDeviceInfo[]
> => {
  try {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices();
    return mediaDevices.filter((device) => {
      const deviceLabel = device.label.toLowerCase();
      const hasRequiredLabel = deviceLabel.includes(deviceName.toLowerCase());
      const hasRequiredDeviceId = deviceId ? deviceId === device.deviceId : true;
      return device.kind === 'audioinput' && hasRequiredLabel && hasRequiredDeviceId;
    });
  } catch {
    return [];
  }
};

export default getMicrophonesList;
