import { useQuery, useMutation } from '@apollo/client';
import { copyToClipboard } from '@powowfe/common';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// GraphQL
import generateKeyMutation from '../graphql/mutations/GenerateZapierIntegrationsApiKey.graphql';
import query from '../graphql/queries/ZapierIntegrationSettings.graphql';
// Lib Shared
import { GenerateZapierIntegrationsApiKey, GraphError, ZapierIntegrationSettings } from '../types';

export interface MyZapierAutomationsContainerProps {
  onResponseError?: (err: GraphError) => void;
  onCopySuccess?: (message: string) => void;
}

export const MyZapierAutomationsContainer: React.VFC<MyZapierAutomationsContainerProps> = ({
  onResponseError = () => null,
  onCopySuccess = () => null,
}) => {
  /* #region  Hooks */
  const { data, loading } = useQuery<ZapierIntegrationSettings>(query);

  const [generateKey, { loading: isGeneratingKey }] = useMutation<GenerateZapierIntegrationsApiKey>(
    generateKeyMutation,
    { refetchQueries: [query] },
  );
  /* #endregion */

  /* #region  Handlers */
  const handleCopyApiKey = (url: string) => async () => {
    const result = await copyToClipboard(url);
    if (result) onCopySuccess('Authorization endpoint copied to clipboard');
  };

  const handleGenerateAPIKey = async () => {
    const result = await generateKey();
    if (!result.data?.generateZapierIntegrationsApiKey?.success) {
      onResponseError(result.data?.generateZapierIntegrationsApiKey?.errors);
    } else {
      onCopySuccess('API Key generated successfully');
    }
  };
  /* #endregion */

  const apiKey = data?.zapierIntegrationSettings?.apiKey;
  const ZAPIER_CLIENT_ID = process.env.REACT_APP_ZAPIER_CLIENT_ID;

  if (!ZAPIER_CLIENT_ID) {
    throw new Error('REACT_APP_ZAPIER_CLIENT_ID is not defined');
  }

  return (
    <Box pt={2}>
      <Box mt={3}>
        <Grid container alignItems="center">
          <Grid item sm={12} md={6}>
            <Typography gutterBottom variant="h6">
              <b>Zapier Authentication</b>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} style={{ textAlign: 'right' }}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                {!apiKey && (
                  <Button
                    disableElevation
                    color="primary"
                    variant="contained"
                    disabled={isGeneratingKey}
                    endIcon={isGeneratingKey ? <CircularProgress size={24} /> : undefined}
                    onClick={handleGenerateAPIKey}
                  >
                    <Typography noWrap variant="body1">
                      Generate New Api Key
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {!!apiKey && (
          <Box mt={1}>
            <TextField
              fullWidth
              disabled
              type="string"
              size="small"
              margin="dense"
              variant="outlined"
              label="Authentication Api Key"
              inputProps={{ readOnly: true }}
              defaultValue={apiKey}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      title="Copy authorization endpoint"
                      onClick={handleCopyApiKey(apiKey)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
      </Box>

      <Box mt={2}>
        <zapier-zap-templates
          client-id={ZAPIER_CLIENT_ID}
          theme="light"
          apps="sembly-ai"
          create-without-template="show"
          limit={10}
          link-target="new-tab"
          use-this-zap="show"
        />
      </Box>
    </Box>
  );
};
