import { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
// Lib Shared
import { MEETING_TYPES } from '../constants';
import { MeetingTypes } from '../types';
import { useConfirmationDialog } from '../hooks';
import { EditRecurringMeeting } from '../dialogs';
import specMeetingType from '../assets/spec-meeting-type.svg';

export interface MeetingTypePickerProps {
  dense?: boolean;
  isDisabled: boolean;
  isRecurrentMeeting: boolean;
  value: MeetingTypes | null;
  variant?: 'outlined' | 'contained';
  onChange: (value: MeetingTypes, variant: 'all' | 'single') => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const MeetingTypePicker: React.VFC<MeetingTypePickerProps> = ({
  dense = false,
  isDisabled,
  isRecurrentMeeting,
  value,
  variant = 'contained',
  onChange,
  onClick = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [confirmUpdate, ConfirmationDialog] = useConfirmationDialog<null | 'all' | 'single'>(
    (resolve) => (
      <EditRecurringMeeting onClose={() => resolve(null)} onSubmit={(value) => resolve(value)} />
    ),
  );
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnMeetingTypeMenu = (event: React.MouseEvent<HTMLElement>) => {
    onClick(event);
    setAnchorEl(event.currentTarget);
  };

  const handleSelectType = (value: MeetingTypes) => async (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);

    const variant = isRecurrentMeeting ? await confirmUpdate() : 'single';

    if (variant !== null) {
      onChange(value, variant);
    }
  };

  const handleCloseMenu = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const isOpenMenu = Boolean(anchorEl);
  const meetingTypeList = MEETING_TYPES.flatMap(({ elements }) => [...elements]);
  const selectedType = meetingTypeList.find((el) => el.value === value);
  const isOutlined = variant === 'outlined';
  /* #endregion */

  return (
    <>
      <Box mx={dense ? 0 : 1}>
        <Button
          disableElevation
          variant="outlined"
          disabled={isDisabled}
          endIcon={
            isOpenMenu ? (
              <CloseIcon color={isDisabled ? 'disabled' : 'inherit'} />
            ) : (
              <OpenIcon color={isDisabled ? 'disabled' : 'inherit'} />
            )
          }
          classes={{ root: isOutlined ? styles.buttonOutlined : styles.buttonContained }}
          onClick={handleClickOnMeetingTypeMenu}
        >
          <Box ml={1}>{selectedType ? selectedType.label : 'Set meeting type'}</Box>
        </Button>
      </Box>
      {/* Begin: Menus */}
      <Menu
        anchorEl={anchorEl}
        open={isOpenMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={styles.menu}
        getContentAnchorEl={null}
        onClose={handleCloseMenu}
      >
        <Box my={1} mr={1} ml={1} maxHeight={280} overflow="auto">
          {MEETING_TYPES.map(({ category, elements }, index) => (
            <div key={index}>
              <ListSubheader classes={{ root: styles.listSubHeaderRoot }}>{category}</ListSubheader>
              {elements.map((element) => (
                <MenuItem
                  key={element.value}
                  classes={{ root: styles.menuItemRoot }}
                  selected={value === element.value}
                  onClick={handleSelectType(element.value)}
                >
                  <Box display="flex" alignItems="flex-start" gridGap={6}>
                    {element.label}
                    {element.spec && (
                      <img
                        src={specMeetingType}
                        alt="template"
                        title="Specific Meeting Notes template available"
                        width={20}
                        height={20}
                      />
                    )}
                  </Box>
                </MenuItem>
              ))}
            </div>
          ))}
        </Box>
      </Menu>
      {ConfirmationDialog}
      {/* End: Menus */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  listSubHeaderRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  menu: {
    marginTop: theme.spacing(1 / 2),
  },
  menuItemRoot: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttonContained: {
    backgroundColor: theme.palette.meetingType.backgroudColor.main,
    color: theme.palette.meetingType.color,
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(0.25, 1),
    whiteSpace: 'nowrap',
    border: 'none',
    '&:disabled': {
      color: theme.palette.meetingType.color,
      border: 'none !important',
    },
  },
  buttonOutlined: {
    padding: theme.spacing(0, 1),
    whiteSpace: 'nowrap',
    fontSize: theme.typography.body1.fontSize,
  },
}));

export default MeetingTypePicker;
