import { useSubscription } from '@apollo/client';
// App Shared
import { useAuth } from '@shared/clients/authClient';
import meetingSubscription from '@shared/subscriptions/MeetingSubscription.subscription';

export const GQLSubscriptionsContainer = () => {
  const [isLoggedIn] = useAuth();

  useSubscription(meetingSubscription, { skip: !isLoggedIn });

  return null;
};

export default GQLSubscriptionsContainer;
