import { Link, generatePath } from 'react-router-dom';
import { GenericDialog } from '@sembly-ui';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// App Shared
import { Routes } from '@shared/enums';

export interface SuccessfullySubmittedProps {
  open: boolean;
  meetingId: string | null;
  onClose: () => void;
}

export const SuccessfullySubmittedRecording: React.VFC<SuccessfullySubmittedProps> = ({
  open,
  meetingId,
  onClose,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={onClose}
    >
      <Box component={DialogContent} mt={2} mb={5}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
          <CheckCircleOutlineIcon fontSize="large" color="primary" />
          <Box ml={1}>
            <Typography variant="h4" color="primary">
              Recording Submitted
            </Typography>
          </Box>
        </Box>
        {meetingId && (
          <Box textAlign="center">
            <Typography variant="subtitle1">
              You can now go to the meeting and manage its details.
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center" mt={4}>
          {meetingId && (
            <Box mr={1}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                aria-label="Go to meeting"
                component={Link}
                onClick={onClose}
                to={generatePath(Routes.Meeting, { meetingId })}
              >
                Go to Meeting
              </Button>
            </Box>
          )}
          <Button variant="outlined" aria-label="Close" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default SuccessfullySubmittedRecording;
