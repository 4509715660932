import { PlanIDEnum } from '../../types';

/**
 * Compares two subscription plans based on a given condition.
 *
 * @param userPlan - The user's subscription plan.
 * @param selector - The comparison condition.
 * @param targetPlan - The target subscription plan.
 * @returns `true` if the condition is met, otherwise `false`.
 */
export const comparePlans = (
  userPlan: PlanIDEnum,
  selector: 'equalsOrHigher' | 'equalsOrLower' | 'equals' | 'notEquals' | 'higher' | 'lower',
  targetPlan: PlanIDEnum,
): boolean => {
  const plansOrder = [
    PlanIDEnum.PERSONAL,
    PlanIDEnum.PROFESSIONAL,
    PlanIDEnum.TEAM,
    PlanIDEnum.TEAM_PLUS,
    PlanIDEnum.ENTERPRISE,
  ];

  const userPlanIndex = plansOrder.indexOf(userPlan);
  const targetPlanIndex = plansOrder.indexOf(targetPlan);

  switch (selector) {
    case 'equalsOrHigher':
      return userPlanIndex >= targetPlanIndex;
    case 'equalsOrLower':
      return userPlanIndex <= targetPlanIndex;
    case 'higher':
      return userPlanIndex > targetPlanIndex;
    case 'lower':
      return userPlanIndex < targetPlanIndex;
    case 'equals':
      return userPlanIndex === targetPlanIndex;
    case 'notEquals':
      return userPlanIndex !== targetPlanIndex;
    default:
      throw new Error('Invalid selector');
  }
};
