import { useState, useEffect, createContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
// App Shared
import { UserGuideTypes } from '@gql-types';
import { ga } from '@shared/utils';

export interface UserInterfaceContextState {
  currentAchievementType: UserGuideTypes | null;
  isAppDrawerOpen: boolean;
  isFeedbackDialogOpen: boolean;
  isOpenAchievements: boolean;
  isOpenAgentCallPlanner: boolean;
  isOpenIntroduction: boolean;
  isOpenNewsroom: boolean;
  isOpenRecorder: boolean;
  isOpenResourceCenter: boolean;
  isOpenTutorial: boolean;
  isOpenUpload: boolean;
  isPhillipsUser: boolean;
}

export interface UserInterfaceContextProps extends UserInterfaceContextState {
  update: (props: Partial<UserInterfaceContextState>) => void;
}

export const UserInterfaceContext = createContext<UserInterfaceContextProps>({
  currentAchievementType: null,
  isAppDrawerOpen: false,
  isFeedbackDialogOpen: false,
  isOpenAchievements: false,
  isOpenAgentCallPlanner: false,
  isOpenIntroduction: false,
  isOpenNewsroom: false,
  isOpenRecorder: false,
  isOpenResourceCenter: false,
  isOpenTutorial: false,
  isOpenUpload: false,
  isPhillipsUser: false,
  update: () => null,
});

export const UserInterfaceProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useState<UserInterfaceContextState>({
    currentAchievementType: null,
    isAppDrawerOpen: false,
    isFeedbackDialogOpen: false,
    isOpenAchievements: false,
    isOpenAgentCallPlanner: false,
    isOpenIntroduction: false,
    isOpenNewsroom: false,
    isOpenRecorder: false,
    isOpenResourceCenter: false,
    isOpenTutorial: false,
    isOpenUpload: false,
    isPhillipsUser: false,
  });

  const handleUpdate = (props: Partial<UserInterfaceContextState>) => {
    setState((currentState) => ({ ...currentState, ...props }));

    if (props.isOpenRecorder) {
      ga.event({ category: 'New Meeting', action: 'Record Audio Clicked' });
    }

    if (props.isOpenUpload) {
      ga.event({ category: 'New Meeting', action: 'Upload Audio Clicked' });
    }

    if (props.isOpenAgentCallPlanner) {
      ga.event({ category: 'New Meeting', action: 'Invite Sembly Agent Clicked' });
    }

    if (props.isOpenTutorial) {
      ga.event({ category: 'Support', action: 'Open Tutorials' });
    }

    if (props.isOpenResourceCenter) {
      ga.event({ category: 'Support', action: 'Open Resource Center' });
    }

    if (props.isOpenIntroduction) {
      ga.event({ category: 'Support', action: 'Open Welcome Video' });
    }

    if (props.isOpenAchievements) {
      ga.event({ category: 'Support', action: 'Open Level Up' });
    }

    if (props.isOpenNewsroom) {
      ga.event({ category: 'Support', action: 'Open News' });
    }

    if (props.isOpenNewsroom) {
      ga.event({ category: 'Support', action: 'Open News' });
    }
  };

  // Check if the user is a Phillips user
  useEffect(() => {
    const LS_KEY = 'isPhilipsUser' as const;
    const QP_KEY = 'philips' as const;

    let isPhillipsUser: boolean = !!window.localStorage.getItem(LS_KEY);

    // if the hydrated value is false, check the query string
    if (!isPhillipsUser) {
      const searchParams = new URLSearchParams(location.search);
      const hasPhillipsSearchParam = searchParams.has(QP_KEY);
      if (hasPhillipsSearchParam) {
        isPhillipsUser = true;
        searchParams.delete(QP_KEY);
        history.replace({ search: searchParams.toString() });
        window.localStorage.setItem(LS_KEY, 'True');
      }
    }

    setState((currentState) => ({ ...currentState, isPhillipsUser }));
  }, [history, location.search]);

  return (
    <UserInterfaceContext.Provider value={{ ...state, update: handleUpdate }}>
      {children}
    </UserInterfaceContext.Provider>
  );
};

export default UserInterfaceProvider;
