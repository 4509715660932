import { parseISO } from 'date-fns';
import { useQuery } from '@apollo/client';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
// Lib Shared
import query from '../graphql/queries/MeetingDetailsContainerQuery.graphql';
import { AGENT_CALL_PLATFORMS } from '../constants';
import { MeetingDetailsContainerQuery, MeetingDetailsContainerQueryVariables } from '../types';
import { MeetingStatusChip } from '../components';
import { convertSecondsToMinutes, formatInTimeZone, formatRecordQuality } from '../utils';

export interface MeetingDetailsContainerProps {
  meetingId: string;
}

export const MeetingDetailsContainer: React.VFC<MeetingDetailsContainerProps> = ({ meetingId }) => {
  const { data, loading } = useQuery<
    MeetingDetailsContainerQuery,
    MeetingDetailsContainerQueryVariables
  >(query, {
    variables: { meetingId },
  });

  /* #region  Render Helpers */
  const agentCallPlatform = data?.meeting?.agentCall?.platform;
  const detectedCallPlatform = AGENT_CALL_PLATFORMS[agentCallPlatform || 'COMMON'];
  const isKnownPlatform = !!detectedCallPlatform;
  const platform = isKnownPlatform ? detectedCallPlatform : AGENT_CALL_PLATFORMS['COMMON'];

  const detailItems = data?.meeting
    ? [
        { label: 'Session ID:', value: data.meeting.uuid },
        { label: 'Owner', value: data.meeting.owner.fullName },
        {
          label: 'Status',
          value: (
            <MeetingStatusChip
              meetingStatus={data?.meeting?.status}
              processingResult={data.meeting.processingResults}
            />
          ),
        },
        { label: 'Meeting Platform', value: platform.title },
        {
          label: 'Start Date',
          value: data.meeting.startedAt
            ? formatInTimeZone(parseISO(data.meeting.startedAt), 'p')
            : null,
        },
        {
          label: 'Finish Date',
          value: data.meeting.finishedAt
            ? formatInTimeZone(parseISO(data.meeting.finishedAt), 'p')
            : null,
        },
        { label: 'Duration', value: convertSecondsToMinutes(data.meeting.duration) },
        { label: 'Phone', value: data.meeting.agentCall?.phone || null },
        { label: 'Pin', value: data.meeting.agentCall?.pin || null },
        { label: 'Record Quality', value: formatRecordQuality(data.meeting.wer) },
      ]
    : [];
  /* #endregion */

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="50vh"
          width="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} square>
          <Table>
            <TableBody>
              {detailItems.map((row) => {
                if (!row.value) return null;
                return (
                  <TableRow key={row.label}>
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default MeetingDetailsContainer;
