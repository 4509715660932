import { useState } from 'react';
// Sembly UI
import { GenericDialog, YouTubeModal } from '@sembly-ui';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MaterialLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
// App Shared
import HelpCenterIcon from '@shared/icons/HelpCenter';
import { useUserInterface } from '@shared/hooks';
import { TUTORIALS } from '@shared/constants';

export const TutorialsDialog: React.VFC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { isOpenTutorial, update } = useUserInterface();
  const [tutorialIndex, setTutorialIndex] = useState<number | null>(null);

  const handleClose = () => {
    update({ isOpenTutorial: false });
  };

  return (
    <>
      <GenericDialog
        hideTitle
        dialogProps={{
          open: isOpenTutorial,
          fullWidth: true,
          maxWidth: 'md',
          classes: { paper: styles.paper },
          fullScreen: isSmallScreen,
        }}
        onClose={handleClose}
      >
        <div>
          <Box
            mb={3}
            flexDirection="row"
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="h4">Tutorials</Typography>
            <IconButton size="small" color="inherit" aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <div>
            {TUTORIALS.map((section, index) => (
              <Box mb={4} key={`${section.title}-${index}`}>
                <Grid container alignItems="flex-start" spacing={2}>
                  {section.list.map((item, index) => (
                    <Grid key={`${item.title}-${index}`} item xs={12} sm={6} md={4} lg={4}>
                      <MaterialLink
                        className={styles.link}
                        variant="body1"
                        onClick={() => setTutorialIndex(index)}
                        //href={item.url}
                        //target="_blank"
                        //rel="noopener noreferrer"
                      >
                        <img className={styles.img} src={item.preview} alt="Getting Started" />
                        <Box color="inherit">{item.title}</Box>
                      </MaterialLink>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}
          </div>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              startIcon={<HelpCenterIcon />}
              variant="outlined"
              color="default"
              href="https://sembly-ai.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open Help Center"
            >
              Help Center
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              aria-label="Done"
              onClick={handleClose}
            >
              Done
            </Button>
          </Box>
        </div>
      </GenericDialog>
      {tutorialIndex !== null && (
        <YouTubeModal
          autoplay
          disableTransition
          videoSrc={TUTORIALS[0].list[tutorialIndex].url}
          title={TUTORIALS[0].list[tutorialIndex].title}
          onClose={() => setTutorialIndex(null)}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
  },
  img: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(1),
  },
}));

export default TutorialsDialog;
