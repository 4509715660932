import { useState, useEffect } from 'react';

export type UseMediaDevicesOptions = { deviceName?: string; disabled?: boolean };
export type UseMediaDevicesResult = Readonly<[MediaDeviceInfo[] | null, { called: boolean }]>;

export const useMediaDevices = ({
  disabled = false,
  deviceName,
}: UseMediaDevicesOptions): UseMediaDevicesResult => {
  const [state, setState] = useState<UseMediaDevicesResult>([null, { called: false }]);

  useEffect(() => {
    const handleUpdateDeviceList = async () => {
      try {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const deviceNameValue = deviceName?.toLowerCase() || '';
        const availableDevices = mediaDevices.filter(
          ({ label, kind }) =>
            kind === 'audioinput' && label.toLowerCase().includes(deviceNameValue),
        );

        setState([availableDevices, { called: true }]);
      } catch {
        setState([[], { called: true }]);
      }
    };

    if (!disabled) {
      handleUpdateDeviceList(); // get initial device list
      navigator.mediaDevices.addEventListener('devicechange', handleUpdateDeviceList);
    } else {
      setState([null, { called: false }]);
      if (typeof navigator.mediaDevices?.removeEventListener !== 'function') return;
      navigator.mediaDevices.removeEventListener('devicechange', handleUpdateDeviceList);
    }

    return () => {
      if (typeof navigator.mediaDevices?.removeEventListener === 'function') return;
      navigator.mediaDevices.removeEventListener('devicechange', handleUpdateDeviceList);
    };
  }, [deviceName, disabled]);

  return state;
};

export default useMediaDevices;
