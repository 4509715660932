import { lazy, Fragment } from 'react';

/**
 * `lazyWithRetry' wraps the `React.lazy()` API and adds the ability
 * to perform a page refresh whenever a file fails to load lazy, ensuring
 * that users see the latest version of the application.
 */
export const lazyWithRetry: typeof lazy = (importer) =>
  lazy(async () => {
    const LOCAL_STORAGE_KEY = 'page-has-been-force-refreshed' as const;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEY) || 'false',
    );

    try {
      const component = await importer();
      window.localStorage.setItem(LOCAL_STORAGE_KEY, 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
        window.location.reload();

        return Promise.resolve({ default: FallbackModule } as unknown as any);
      }

      throw error;
    }
  });

function FallbackModule() {
  return <Fragment />;
}

export default lazyWithRetry;
