// Material UI
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
// Lib Shared
import { MEETING_TYPES, MeetingTypes } from '@sembly-ui';

export interface MeetingTypeSelectProps {
  disabled?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  values: MeetingTypes[] | null;
  onChange: (values: MeetingTypes[]) => void;
}

export const MeetingTypeSelect: React.VFC<MeetingTypeSelectProps> = ({
  disabled = false,
  fullWidth = true,
  size,
  values,
  onChange,
}) => {
  const handleChangeValue = async (
    event: React.ChangeEvent<{}>,
    options: { key: string; value: string }[],
  ) => {
    const mappedOptions = options.map((option) => option.key as MeetingTypes);
    onChange(mappedOptions);
  };

  /* #region  Render Helpers */
  const options = MEETING_TYPES.flatMap((section) => {
    return section.elements.map((element) => {
      return {
        key: element.value,
        value: element.label,
      };
    });
  });

  const getLabeledValue = (
    key: MeetingTypes,
  ): {
    key: MeetingTypes;
    value: string;
  } => {
    const matchedOption = options.find((option) => option.key === key);
    if (!matchedOption) throw new Error(`No option found for key: ${key}`);
    return matchedOption;
  };

  const groupByLabel = ({ key }: { key: string; value: string }) => {
    const sections = MEETING_TYPES.map((section) => ({
      key: section.category,
      value: section.elements.map((element) => element.value),
    }));

    const matchedSection = sections.find((section) => section.value.includes(key as MeetingTypes));
    return matchedSection ? matchedSection.key : '';
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Select up to 3 meeting types"
        InputProps={params.InputProps}
      />
    );
  };

  const renderTags = (value: { key: string; value: string }[], getTagProps: any) => {
    return value.map((option, index) => (
      <Chip variant="outlined" label={option.value} size="small" {...getTagProps({ index })} />
    ));
  };

  const selectedValues =
    values?.map((value) => getLabeledValue(value)).filter((item) => !!item) || [];
  /* #endregion */

  return (
    <Autocomplete
      multiple
      disableClearable
      filterSelectedOptions
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionLabel={(option) => option.value}
      groupBy={groupByLabel}
      options={options}
      renderInput={renderInput}
      renderTags={renderTags}
      size={size}
      value={selectedValues}
      onChange={handleChangeValue}
    />
  );
};

export default MeetingTypeSelect;
