import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  DataCollectionListResponse,
  DataCollectionSearchResponse,
  DataRecord,
} from '@integration-app/sdk';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { useIntegrationApp } from '../clients/wrappedIntegrationAppSDK';

export interface AutomationDestinationPickerProps {
  anchorEl: HTMLElement;
  appKey: string;
  collectionKey: string;
  collectionParameters: Record<string, string> | undefined;
  destinations: DataCollectionListResponse | null;
  isLoadMore: boolean;
  restrict3rdPartyIntegrations: boolean;
  searchable: boolean;
  value: string;
  onClose: () => void;
  onError: (error: unknown) => void;
  onSubmit: (destination: DataRecord) => void;
  onLoadMore: () => void;
}

export const AutomationDestinationPicker: React.VFC<AutomationDestinationPickerProps> = ({
  anchorEl,
  appKey,
  collectionKey,
  collectionParameters,
  destinations,
  isLoadMore,
  restrict3rdPartyIntegrations,
  searchable,
  value,
  onClose,
  onError,
  onSubmit,
  onLoadMore,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const integrationApp = useIntegrationApp({ skip: restrict3rdPartyIntegrations });

  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [queryResonse, setQueryResponse] = useState<DataCollectionSearchResponse | null>(null);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(false);
  const [isLoadingMoreSearchItems, setIsLoadingMoreSearchItems] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleChangeDestination = (destination: DataRecord) => () => {
    onClose();
    onSubmit(destination);
  };

  const handleUpdateQuery = useDebouncedCallback(async (value: string) => {
    if (!searchable) return; // make sure the collection is searchable

    setIsLoadingSearchResults(true);

    try {
      const dataCollectionList = await integrationApp
        .connection(appKey)
        .dataCollection(collectionKey, collectionParameters)
        .search({ query: value });

      setQueryResponse(dataCollectionList);
    } catch (err) {
      onError(err);
    }

    setIsLoadingSearchResults(false);
  }, 1000);

  const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    if (!!value) {
      handleUpdateQuery(value);
    } else {
      setQueryResponse(null);
    }
  };

  const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const handleClearSearch = () => {
    setQuery('');
    setQueryResponse(null);
  };

  const handleClearFilter = () => {
    setFilter('');
  };

  const handleLoadMoreSearchItems = async () => {
    if (!queryResonse?.cursor) return;
    setIsLoadingMoreSearchItems(true);

    try {
      const dataCollectionList = await integrationApp
        .connection(appKey)
        .dataCollection(collectionKey, collectionParameters)
        .search({ query, cursor: queryResonse.cursor });

      setQueryResponse(dataCollectionList);
    } catch (err) {
      onError(err);
    }

    setIsLoadingMoreSearchItems(false);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const menuWidth = anchorEl.clientWidth;
  const isQueryResponse = query && queryResonse !== null;
  const availableDestinations = isQueryResponse ? queryResonse.records : destinations?.records;
  const filteredDestinations = availableDestinations?.filter((destination) =>
    destination.name?.toLowerCase().includes(filter.toLowerCase()),
  );
  /* #endregion */

  return (
    <Menu
      open
      disablePortal
      variant="menu"
      elevation={2}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ paper: styles.paper, list: styles.menu }}
      onClose={onClose}
      PaperProps={{ style: { width: menuWidth } }}
      MenuListProps={{ className: 'rounded-scrollbar' }}
    >
      <div>
        <div className={styles.sticky}>
          {searchable ? (
            <TextField
              autoFocus
              fullWidth
              size="small"
              variant="filled"
              placeholder="Type here to search"
              className={styles.destinationSearch}
              value={query}
              onChange={handleChangeSearchValue}
              InputProps={{
                endAdornment: isLoadingSearchResults ? (
                  <CircularProgress size={18} />
                ) : (
                  !!query && (
                    <IconButton size="small" onClick={handleClearSearch}>
                      <CloseIcon fontSize="small" color="action" />
                    </IconButton>
                  )
                ),
              }}
            />
          ) : (
            <TextField
              autoFocus
              fullWidth
              size="small"
              variant="filled"
              placeholder="Type here to search"
              className={styles.destinationSearch}
              value={filter}
              onChange={handleChangeFilterValue}
              InputProps={{
                endAdornment: !!filter && (
                  <IconButton size="small" onClick={handleClearFilter}>
                    <CloseIcon fontSize="small" color="action" />
                  </IconButton>
                ),
              }}
            />
          )}
          <Divider />
        </div>

        {filteredDestinations?.map((destination) => (
          <MenuItem
            key={destination.id}
            selected={destination.id === value}
            onClick={handleChangeDestination(destination)}
          >
            <Typography component="span" className={styles.textWrap}>
              {destination.name}
            </Typography>
          </MenuItem>
        ))}

        {isQueryResponse && !!queryResonse?.cursor && (
          <MenuItem
            key={queryResonse.cursor}
            disabled={isLoadingMoreSearchItems}
            onClick={handleLoadMoreSearchItems}
          >
            <Typography variant="body1" color="primary">
              Load more...
            </Typography>
            {isLoadingMoreSearchItems && <CircularProgress size={18} style={{ marginLeft: 8 }} />}
          </MenuItem>
        )}

        {!isQueryResponse && !!destinations?.cursor && (
          <MenuItem disabled={isLoadingMoreSearchItems} onClick={onLoadMore}>
            <Typography variant="body1" color="primary">
              Load more...
            </Typography>
            {isLoadMore && <CircularProgress size={18} style={{ marginLeft: 8 }} />}
          </MenuItem>
        )}

        <Box m={2}>
          {filteredDestinations && !filteredDestinations.length && (
            <Typography variant="body2" color="textSecondary">
              {query || filter ? 'No matching destination found' : 'No destinations available'}
            </Typography>
          )}
        </Box>
      </div>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: 0,
    overflow: 'auto',
    maxHeight: 300,
    margin: 0,
    width: 'auto',
  },
  paper: {
    maxHeight: 300,
    marginTop: theme.spacing(0.25),
    borderRadius: theme.shape.borderRadius * 4,
    overflow: 'hidden',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  destinationSearch: {
    padding: theme.spacing(1.5, 2),
  },
  textWrap: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default AutomationDestinationPicker;
