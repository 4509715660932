// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Sembly UI
import {
  GenericConfirmation,
  normalizeCurrencyAmount,
  useConfirmationDialog,
  comparePlans,
} from '@sembly-ui';
// GQL Types
import { GenericPaymentPlan } from '@gql-types/GenericPaymentPlan';
import { GenericStripePromotionCode } from '@gql-types/GenericStripePromotionCode';
import { PlanIDEnum, StripePricePaymentIntervalEnum } from '@gql-types/globalTypes';
// App Shared
import { BillingCycle } from '@shared/types';
import { PAYMENT_PLANS } from '@shared/constants';
import { formatCurrencyAmount, isPastISO, calculateDiscountAmount } from '@shared/utils';
import { useUserContext } from '@shared/hooks';

export interface PaymentPlanHeaderProps {
  billingCycle: BillingCycle;
  ctaActiveButtonLabel?: string;
  ctaButtonLabel?: string;
  currencyCode: string;
  enableActiveCtaButton?: boolean;
  paymentCustomerPriceType: StripePricePaymentIntervalEnum | null;
  paymentPlans: GenericPaymentPlan[];
  planId: PlanIDEnum;
  promotion: GenericStripePromotionCode | null;
  trialExpirationDate: string | null;
  userBillingAccessId: string | null;
  onClickOnCtaButton: (plan: GenericPaymentPlan) => void;
}

export const PaymentPlanHeader: React.FC<PaymentPlanHeaderProps> = ({
  billingCycle,
  ctaActiveButtonLabel = 'Select',
  ctaButtonLabel = 'Try It Free',
  currencyCode,
  enableActiveCtaButton = false,
  paymentCustomerPriceType,
  paymentPlans,
  planId,
  promotion,
  trialExpirationDate,
  userBillingAccessId,
  onClickOnCtaButton,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const user = useUserContext();

  const [confirmDowngrade, DowngradeConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Confirm Downgrade"
      text="If you choose to downgrade your plan, it may lead to data loss based on the data retention policy of that plan."
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Downgrade"
      cancelButtonLabel="Keep Current"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));
  /* #endregion */

  /* #region  Handlers */
  const handleChangeCurrentBillingPlan = (plan: GenericPaymentPlan) => async () => {
    const currentUserPlan = user?.data?.me?.paymentCustomer?.plan?.id ?? PlanIDEnum.PERSONAL;
    const isDowngradingPlan = comparePlans(currentUserPlan, 'higher', plan.id);
    const isConfirmed = isDowngradingPlan ? await confirmDowngrade() : true;

    // prevent user from downgrading plan if not confirmed
    if (!isConfirmed) return;

    onClickOnCtaButton(plan);
  };
  /* #endregion */

  /* #region  Render Helpers */
  const paymentPlan = PAYMENT_PLANS[planId];
  const stripePlan = paymentPlans.find((item) => item?.id === planId);
  const stripeProductId = stripePlan?.productId || '';

  const isPersonal = planId === PlanIDEnum.PERSONAL;
  const isEnterprise = planId === PlanIDEnum.ENTERPRISE;
  const isExpiredTial = isPastISO(trialExpirationDate);
  const isDiscounted = checkIsApplicablePromotion(promotion?.appliesTo, stripeProductId);
  const isSelected =
    isPersonal || isEnterprise
      ? planId === userBillingAccessId
      : planId === userBillingAccessId && paymentCustomerPriceType === billingCycle.toUpperCase();

  const selectedPrice = stripePlan?.priceOptions[billingCycle];
  const currencyOptions = selectedPrice?.currencyOptions;
  const currencyOption = currencyOptions?.find(
    ({ currency }) => currency.toLowerCase() === currencyCode.toLowerCase(),
  );
  const selectedPriceValue = currencyOption?.amount || 0;
  const amount = normalizeCurrencyAmount(selectedPriceValue, currencyCode);

  const billingCycleUpper = billingCycle[0].toUpperCase() + billingCycle.slice(1);
  const price = formatCurrencyAmount(amount, currencyCode);
  const label = stripePlan?.name || paymentPlan.name;

  const discountPercent = promotion?.percentOff || 0;
  const discountOption = promotion?.currencyOptions?.find(
    ({ currency }) => currency.toLowerCase() === currencyCode.toLowerCase(),
  );
  const discountAmount = normalizeCurrencyAmount(discountOption?.amount || 0, currencyCode);
  const discountValue = calculateDiscountAmount(
    discountPercent,
    discountAmount,
    amount,
    currencyCode,
  );

  const amountValue = amount - discountValue <= 0 ? 0 : amount - discountValue;
  const discount = formatCurrencyAmount(isDiscounted ? amountValue : amount, currencyCode);

  const forTeams = stripePlan?.forTeams || false;
  const paymentType = forTeams ? `Member / ${billingCycleUpper}` : billingCycleUpper;
  const isTriedOut = isExpiredTial || isPersonal;
  const ctaLabel = isTriedOut ? ctaActiveButtonLabel : ctaButtonLabel;

  const currentUserPlan = user?.data?.me?.paymentCustomer?.plan?.id ?? PlanIDEnum.PERSONAL;
  const isEnterpriseUser = comparePlans(currentUserPlan, 'equals', PlanIDEnum.ENTERPRISE);
  /* #endregion */

  return (
    <>
      <Box
        key={planId}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle2">{label}</Typography>
        {isEnterprise ? (
          <Box display="flex" alignItems="center" minHeight="53px" mt={1}>
            {!isSelected && (
              <Typography
                noWrap
                variant="inherit"
                color="textSecondary"
                className={`${styles.secondaryText} ${styles.placeholder}`}
              >
                Contact the Sembly Team to learn more
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <Box display="flex" alignItems="center" mt={1}>
              {isDiscounted && amount > 0 ? (
                <>
                  <Typography variant="h4" color="primary">
                    {discount}
                  </Typography>
                  <Box mr={1} />
                  <Tooltip arrow title={`${promotion?.code} – ${promotion?.name}`}>
                    <Typography variant="subtitle2" color="textSecondary">
                      <s>{price}</s>
                    </Typography>
                  </Tooltip>
                </>
              ) : (
                <Typography variant="h4" color="primary">
                  {price}
                </Typography>
              )}
            </Box>
            <Typography
              noWrap
              variant="inherit"
              color="textSecondary"
              className={styles.secondaryText}
            >
              {paymentType}
            </Typography>
          </>
        )}
        <Box mt={1} mb={1}>
          {!stripePlan || isEnterprise ? (
            <Button
              disableElevation
              href="https://www.sembly.ai/form/"
              target="_blank"
              rel="noopener noreferrer"
              variant={isSelected ? 'outlined' : 'contained'}
              color="primary"
              disabled={isSelected}
              aria-label="Contact Sales"
            >
              <Typography noWrap component="span" variant="body2">
                {isSelected ? 'Current' : isEnterprise ? 'Contact Sales' : 'Contact us'}
              </Typography>
            </Button>
          ) : (
            <Tooltip arrow title={isEnterpriseUser ? 'Contact Sales' : ''}>
              <span>
                <Button
                  disableElevation
                  color="primary"
                  variant={isSelected ? 'outlined' : 'contained'}
                  disabled={isEnterpriseUser || (!enableActiveCtaButton && isSelected)}
                  aria-label="Select Plan"
                  onClick={handleChangeCurrentBillingPlan(stripePlan)}
                >
                  <Typography noWrap component="span" variant="body2">
                    {isSelected && !enableActiveCtaButton ? 'Current' : ctaLabel}
                  </Typography>
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
      {DowngradeConfirmationDialog}
    </>
  );
};

function checkIsApplicablePromotion(
  applicableItems: string[] | null = null,
  target: string = '',
): boolean {
  if (applicableItems === null) return false;
  const isRestrictedPromotion = !!applicableItems.length;
  return !isRestrictedPromotion || (isRestrictedPromotion && applicableItems.includes(target));
}

const useStyles = makeStyles((theme) => ({
  link: {
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 'normal',
  },
  secondaryText: {
    fontWeight: 'normal',
    fontSize: '0.75em',
  },
  placeholder: {
    maxWidth: 110,
    textAlign: 'center',
    whiteSpace: 'inherit',
    lineHeight: 1.5,
  },
}));

export default PaymentPlanHeader;
