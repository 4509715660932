// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

export interface TutorialBtnProps {
  description: string;
  icon: JSX.Element;
  title: string;
  url: string;
  onClick: () => void;
}

export const TutorialBtn: React.VFC<TutorialBtnProps> = ({
  description,
  icon,
  title,
  url,
  onClick,
}) => {
  const styles = useStyles();

  return (
    <Button
      fullWidth
      disableElevation
      variant="outlined"
      className={styles.tutorialLink}
      classes={{ label: styles.buttonLabel }}
      aria-label="Open tutorial"
      onClick={onClick}
    >
      <Box display="flex" alignItems="center" justifyContent="start">
        {icon}
        <Box ml={1}>
          <Typography gutterBottom variant="body1">
            <Box component="span" fontWeight="500">
              {title}
            </Box>
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  tutorialLink: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    borderColor: theme.palette.grey[200],
    textAlign: 'left',
    '& svg': {
      fontSize: '3rem',
    },
  },
  buttonLabel: {
    display: 'block',
  },
}));

export default TutorialBtn;
