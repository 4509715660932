import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';

export interface BannerProps {
  icon: JSX.Element;
  content: string | JSX.Element;
  bgcolor: string;
  color: string;
  onClose?: () => void;
}

export const Banner: React.VFC<BannerProps> = ({ icon, content, bgcolor, color, onClose }) => {
  const { typography } = useTheme();

  return (
    <Box
      p={1 / 2}
      position="relative"
      bgcolor={bgcolor}
      boxShadow="inset rgb(0 0 0 / 3%) 0 3px 3px 0"
      borderTop="1px solid rgb(0 0 0 / 6%)"
      marginTop="1px"
    >
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize={typography.body1.fontSize}
        textAlign="center"
      >
        <Box width={24} height={24} mr={1 / 2} color={color}>
          {icon}
        </Box>
        <Box lineHeight={1} component="span">
          {content}
        </Box>
      </Box>
      {onClose && (
        <Box
          position="absolute"
          top="5px"
          right="5px"
          style={{ cursor: 'pointer' }}
          color={color}
          onClick={onClose}
        >
          <CloseIcon />
        </Box>
      )}
    </Box>
  );
};

export default Banner;
