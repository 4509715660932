// Material UI
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
// Lib Shared
import LanguageFlag from './LanguageFlag';
import LanguageItem from './LanguageItem';
import NoLanguageIcon from '../icons/EarthOff';
import { Languages } from '../types';
import { SUPPORTED_LANGUAGES } from '../constants';

export interface LanguageSelectProps {
  disabled?: boolean;
  fullWidth?: boolean;
  options: { key: string; value: string }[];
  size?: 'small' | 'medium';
  value: Languages | null;
  onChange: (value: { key: string; value: string } | null) => void;
}

export const LanguageSelect: React.VFC<LanguageSelectProps> = ({
  disabled = false,
  fullWidth = true,
  options,
  size,
  value,
  onChange,
}) => {
  const handleChangeValue = async (
    event: React.ChangeEvent<{}>,
    option: { key: string; value: string } | string | null,
  ) => {
    if (typeof option === 'string') return;
    onChange(option || null);
  };

  /* #region  Render Helpers */
  function getLangKeyValuePair(key: Languages): {
    key: string;
    value: string;
  } {
    const value = SUPPORTED_LANGUAGES[key as keyof typeof SUPPORTED_LANGUAGES] || key;
    return { key, value };
  }

  function groupByLabel({ value }: { key: string; value: string }) {
    return value.includes('(beta)') ? 'Beta languages' : 'Main languages';
  }

  function renderOptionItem({ key, value }: { key: string; value: string }) {
    return !!key ? <LanguageItem key={key} languageCode={key as Languages} /> :
      <Box display="flex" alignItems="center" minWidth={24} gridGap={8}>
        <NoLanguageIcon color="action" fontSize="small" />
        <Typography variant="body1">{value}</Typography>
      </Box>
  }

  function renderInput(params: AutocompleteRenderInputParams) {
    return (
      <TextField
        {...params}
        fullWidth
        size="small"
        variant="outlined"
        placeholder="No language selected"
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="end">
              {!value ? (
                <Box display="flex" alignItems="center" justifyContent="center" minWidth={24}>
                  <NoLanguageIcon color="action" fontSize="small" />
                </Box>
              ) : (
                <LanguageFlag key={value} laguageCode={value} />
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  }
  /* #endregion */

  return (
    <Autocomplete
      disableClearable
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionLabel={(option) => option.value}
      getOptionSelected={(option) => (value ? option.key === value : option.key === '')}
      groupBy={groupByLabel}
      options={options}
      renderInput={renderInput}
      renderOption={renderOptionItem}
      size={size}
      value={value ? getLangKeyValuePair(value) : { key: '', value: 'No language selected' }}
      onChange={handleChangeValue}
    />
  );
};

export default LanguageSelect;
