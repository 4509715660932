import { ZERO_DECIMAL_CURRENCIES } from '../../constants';

/**
 * Normalizes the amount based on the currency's decimal structure.
 * For zero-decimal currencies (e.g., JPY, UGX), the amount is returned as is.
 * For other currencies, the amount is divided by 100 to match their smallest unit format.
 *
 * @param amount - The amount in the smallest currency unit (e.g., cents for USD).
 * @param currencyCode - The currency code to check against zero-decimal currencies. Defaults to 'USD'.
 * @returns The normalized amount, adjusted based on the currency's decimal structure.
 *
 * Example:
 * // For JPY (zero-decimal currency):
 * normalizeCurrencyAmount(500, 'JPY') // returns 500
 *
 * // For USD:
 * normalizeCurrencyAmount(500, 'USD') // returns 5
 */
export const normalizeCurrencyAmount = (amount: number, currencyCode: string = 'USD'): number => {
  return ZERO_DECIMAL_CURRENCIES.includes(currencyCode.toUpperCase()) ? amount : amount / 100;
};
