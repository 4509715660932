import { useState, useEffect} from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import AutocompleteInput from './AutocompleteInput';

export interface AutocompleteTextFieldProps extends Omit<TextFieldProps, 'onKeyDown'> {
  disableAutoComplete?: boolean;
  /** Function that returns the full suggestion based on the current input value */
  getSuggestion: (input: string, cb: (suggestion: string) => void) => void;
  /** Called after the suggestion is applied (e.g. when Tab is pressed) */
  onSuggestionApplied?: (newValue: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * A universal TextField with autocomplete.
 * - Manages its own state for the input value.
 * - Computes the suggestion (via getSuggestion).
 * - Applies the leftover on Tab press if it matches.
 * - Uses a custom inputComponent to properly align suggestion with typed text.
 */
const AutocompleteTextField: React.FC<AutocompleteTextFieldProps> = ({
  disableAutoComplete = false,
  getSuggestion,
  onKeyDown,
  onSuggestionApplied,
  onChange,
  value,
  ...textFieldProps
}) => {
  const [suggestion, setSuggestion] = useState<string>('');

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange && onChange(event);
    if (disableAutoComplete) return;
    getSuggestion(newValue, (suggestion) => {
      setSuggestion(suggestion);
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = (value as string) ?? '';
    // If user presses Tab and there's a leftover suggestion, apply it
    if (
      event.key === 'Tab' &&
      suggestion &&
      suggestion.toLowerCase().startsWith(currentValue.toLowerCase()) &&
      suggestion !== currentValue
    ) {
      event.preventDefault();
      event.stopPropagation();
      setSuggestion('');
      onSuggestionApplied && onSuggestionApplied(suggestion);
    } else if (event.key === 'Escape' || event.key === 'Enter') {
      setSuggestion('');
    }
    onKeyDown && onKeyDown(event);
  };

  useEffect(() => {
    if (!value) setSuggestion('');
  }, [value]);

  return (
    <TextField
      {...textFieldProps}
      variant={textFieldProps.variant ?? 'outlined'}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        ...textFieldProps.InputProps,
        inputComponent: AutocompleteInput,
        inputProps: {
          ...textFieldProps.InputProps?.inputProps,
          suggestion,
        },
      }}
    />
  );
};

export default AutocompleteTextField;
