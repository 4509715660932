export const AUTOMATION_FILTER_TYPES: Record<string, string> = {
  ALL_MEETINGS: 'Apply to all meetings that I have access to',
  FILTERED_BY_KEYWORDS: 'Apply to meetings with keywords in the title',
  FILTERED_BY_TEAM: 'Apply to meetings of a specific team',
  FILTERED_BY_MEETING_TYPE: 'Apply to meetings of a specific type',
  FILTERED_BY_OWNER: 'Apply to meetings of a specific Sembly user',
  MANUAL_ONLY: 'Disabled (Manual only)',
} as const;

export default AUTOMATION_FILTER_TYPES;
