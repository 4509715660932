import { useState, FormEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { copyToClipboard } from '@powowfe/common';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import SaveIcon from '@material-ui/icons/Save';

export interface GuestAccessFormProps {
  accessLink: string | null;
  editable?: boolean;
  hasAccessPassword: boolean;
  loading?: boolean;
  onUpdate: (enabled: boolean, password: string | null) => void;
}

export const GuestAccessForm: React.VFC<GuestAccessFormProps> = ({
  accessLink,
  editable = false,
  hasAccessPassword,
  loading,
  onUpdate,
}) => {
  const styles = useStyles();

  const [password, setPassword] = useState<string | null>(null);
  const [edited, setEdited] = useState(false);

  /* #region  Handlers */
  const handleChangeValue = (event: React.ChangeEvent<{ value: unknown }>) => {
    // sets anyone with the link
    if (event.target.value === 'enabled') {
      return onUpdate(true, null);
    }
    // sets anyone with the link and password
    if (event.target.value === 'protected') {
      const generatedPassword = Math.random().toString(36).substr(2, 8);
      setPassword(generatedPassword);
      return onUpdate(true, generatedPassword);
    }
    // sets no link access
    onUpdate(false, null);
  };

  const handleUpdatePassword = (event: FormEvent) => {
    event.preventDefault();
    setEdited(false);
    onUpdate(true, password);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEdited(true);
    setPassword(event.target.value);
  };

  const handleCopyLink = async () => {
    if (!accessLink) return;
    const success = await copyToClipboard(accessLink);
    if (success) toast.dark('Copied to clipboard.');
  };
  /* #endregion */

  /* #region  Render Helpers */
  const currentValue = !accessLink ? 'disabled' : hasAccessPassword ? 'protected' : 'enabled';
  const STATUSES: Record<string, string> = {
    disabled: 'No link access',
    enabled: 'Anyone with the link',
    protected: 'Anyone with the password',
  };
  /* #endregion */

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} sm={currentValue !== 'protected' ? 8 : 6}>
        <FormControl fullWidth size="small" variant="outlined">
          <Select disabled={loading || !editable} value={currentValue} onChange={handleChangeValue}>
            {Object.keys(STATUSES).map((key) => (
              <MenuItem key={key} value={key}>
                {STATUSES[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={currentValue !== 'protected' ? 4 : 6}>
        <Box display="flex" alignItems="center">
          {currentValue === 'protected' && (
            <Box display="flex" mr={1}>
              <form onSubmit={handleUpdatePassword}>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  disabled={loading || !editable}
                  type={!password ? 'password' : 'text'}
                  onChange={handleChangePassword}
                  value={password === null ? '********' : password}
                  InputProps={{
                    endAdornment: edited && (
                      <InputAdornment position="end">
                        <Tooltip placement="top" title="Apply password" arrow>
                          <IconButton
                            className={styles.savePasswordButton}
                            type="submit"
                            size="small"
                          >
                            <SaveIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Box>
          )}

          <Button
            disableElevation
            fullWidth={currentValue !== 'protected'}
            variant="contained"
            color="primary"
            disabled={!accessLink}
            onClick={handleCopyLink}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Box component="span" fontSize="0.875rem" whiteSpace="nowrap">
                Copy Link
              </Box>
            )}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  savePasswordButton: {
    background: theme.palette.background.default,
    boxShadow: theme.shadows[2],
  },
}));

export default GuestAccessForm;
