import clsx from 'clsx';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
// Material UI
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRightSharp';
// Sembly UI
import { convertSecondsToString } from '@sembly-ui';

export interface PlanStatsWidgetProps {
  creditsLeft: number;
  disabled?: boolean;
  isPaidPlan: boolean;
  onlineUsagePercentage: number;
  onlineSecondsLeft: number;
  uploadUsagePercentage: number;
  uploadSecondsLeft: number;
  onClick: () => void;
}

/**
 * Represents a widget that displays the usage stats of the user's plan
 */
export const PlanStatsWidget: React.VFC<PlanStatsWidgetProps> = ({
  creditsLeft,
  disabled = false,
  isPaidPlan,
  onlineSecondsLeft,
  onlineUsagePercentage,
  uploadSecondsLeft,
  uploadUsagePercentage,
  onClick,
}) => {
  const styles = useStyles();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  /* #region  Render Helpers */
  const WIDGET_COLORS = {
    green: '#00B4A8',
    blue: '#6577D4',
  };

  // The widget data for the radial bar chart
  // If the plan is paid, only show the upload widget
  const widgetData = isPaidPlan
    ? [
        {
          id: 'upload',
          data: [{ color: WIDGET_COLORS.green, x: 'Upload', y: uploadUsagePercentage }],
        },
      ]
    : [
        {
          id: 'upload',
          data: [{ color: WIDGET_COLORS.blue, x: 'Upload', y: uploadUsagePercentage }],
        },
        {
          id: 'recording',
          data: [{ color: WIDGET_COLORS.green, x: 'Recording', y: onlineUsagePercentage }],
        },
      ];
  /* #endregion */

  return (
    <div
      className={clsx(styles.root, { disabled })}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      onClick={handleClick}
    >
      <div className={styles.charts}>
        <ResponsiveRadialBar
          animate={true}
          circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
          colors={(d) => d.data.color}
          cornerRadius={45}
          data={widgetData}
          enableCircularGrid={false}
          enableLabels={false}
          enableRadialGrid={false}
          endAngle={360}
          isInteractive={false}
          layers={['bars', 'tracks']}
          legends={[]}
          maxValue={100}
          padding={0.55}
          radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
          tracksColor="rgba(101, 119, 212, .15)"
        />
      </div>
      <Typography component="div" variant="body2">
        {
          isPaidPlan
            ? `${convertSecondsToString(uploadSecondsLeft,'%value% upload %label%')} and ${creditsLeft} credits remaining` // prettier-ignore
            : `${convertSecondsToString(uploadSecondsLeft,'%value% upload %label%')} and ${convertSecondsToString(onlineSecondsLeft, '%value% online %label% remaining')}` // prettier-ignore
        }
      </Typography>
      {!disabled && <ChevronRightIcon style={{ opacity: 0.25 }} fontSize="small" color="inherit" />}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1.5),
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: theme.shape.borderRadius * 2,
    whiteSpace: 'break-spaces',
    '&:hover:not(.disabled)': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  charts: {
    flex: '32px 0 0',
    width: '32px',
    height: '32px',
  },
}));

export default PlanStatsWidget;
