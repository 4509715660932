import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const MyChatsModuleContainer = lazyWithPreload(
  () => import('./containers/MyChatsModuleContainer'),
);

export const MyChatsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.MyChats,
    render: () => <MyChatsModuleContainer />,
  },
};

export default MyChatsModule;
