import pluralize from 'pluralize';
import uniq from 'lodash/uniq';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
// Material UI
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog, isValidEmail } from '@sembly-ui';
// App Shared
import inviteToSemblyMutation from '@shared/queries/InviteToSembly.graphql';
import { InviteToSembly, InviteToSemblyVariables } from '@gql-types/InviteToSembly';
import { graphErrorHorsemen } from '@shared/utils';
import { APP_NAME } from '@shared/configuration';
// Shared Assets
import logo from '@shared/assets/logo.svg';
import background from '@shared/assets/invite-user-bg.png';

export type AutocompleteTagsChangeEvent = (
  event: React.ChangeEvent<{}>,
  value: (string | string[])[],
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<string[]> | undefined,
) => void;

export interface InviteUserDialogProps {
  open: boolean;
  onClose: () => void;
}

export const InviteUserDialog: React.VFC<InviteUserDialogProps> = ({ open, onClose }) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();

  const [emails, setEmails] = useState<string[]>([]);
  const [isInvited, setIsInvited] = useState(false);
  const [inviteValue, setInviteValue] = useState('');

  const [inviteToSembly, { loading }] = useMutation<InviteToSembly, InviteToSemblyVariables>(
    inviteToSemblyMutation,
  );
  /* #endregion */

  /* #region  Handlers */
  const handleCloseDialog = () => {
    setEmails([]);
    setIsInvited(false);
    onClose();
  };

  const handleIniviteMorePeople = () => {
    setEmails([]);
    setIsInvited(false);
  };

  const handleChangeInviteValue = (event: unknown, value: string) => {
    setInviteValue(value);
  };

  const handleSubmitInviteValue = () => {
    if (inviteValue && isValidEmail(inviteValue)) {
      setEmails((emails) => uniq([...emails, inviteValue]));
    }
  };

  const handleSubmitInvites = async () => {
    if (emails.length) {
      const result = await inviteToSembly({ variables: { emails } });
      if (result.data?.inviteToSembly?.success) {
        setIsInvited(true);
        setInviteValue('');
      } else {
        graphErrorHorsemen(result.data?.inviteToSembly?.errors);
      }
    } else {
      toast.dark('Please enter valid email address', { toastId: TOAST_ID });
    }
  };

  const handleChangeInviteList: AutocompleteTagsChangeEvent = (e, values, reason) => {
    switch (reason) {
      case 'create-option':
        const upd = values.flat().filter((item) => isValidEmail(item));
        setEmails((emails) => uniq([...emails, ...upd]));
        break;
      case 'remove-option':
        setEmails(values.flat());
        break;
      case 'clear':
        setEmails([]);
        break;
    }
  };
  /* #endregion */

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open: open, fullWidth: true, maxWidth: 'md' }}
      onClose={handleCloseDialog}
    >
      <Box display="flex">
        <Box className={styles.content}>
          <img src={logo} alt={APP_NAME} className={styles.logo} />
          {isInvited ? (
            <>
              <Typography variant="h2" style={{ marginBottom: theme.spacing(4) }}>
                Thanks for helping to grow the Sembly community!
              </Typography>
              <Box mb={2}>
                <Typography variant="subtitle1" style={{ marginBottom: theme.spacing(4) }}>
                  You have successfully sent an invite to {emails.length}{' '}
                  {pluralize('user', emails.length)}
                </Typography>
              </Box>
              <Box display="flex">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  aria-label="Invite more people"
                  onClick={handleIniviteMorePeople}
                >
                  <Box p={1 / 2} component="span">
                    Inivite More People
                  </Box>
                </Button>
                <Box mr={1} />
                <Button variant="outlined" aria-label="Close window" onClick={handleCloseDialog}>
                  <Box p={1 / 2}>Close Window</Box>
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography paragraph variant="h2" style={{ marginBottom: theme.spacing(4) }}>
                Know someone who'd benefit from Sembly insights? Invite them below!
              </Typography>

              <Box mb={3}>
                <Autocomplete
                  multiple
                  freeSolo
                  options={[]}
                  value={emails}
                  onChange={handleChangeInviteList}
                  onInputChange={handleChangeInviteValue}
                  onBlur={handleSubmitInviteValue}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="email"
                      autoComplete="on"
                      variant="outlined"
                      placeholder="Enter one or more email addresses here"
                    />
                  )}
                />
              </Box>

              <Box display="flex">
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  style={{ minWidth: '9rem' }}
                  aria-label="Send invite"
                  onClick={handleSubmitInvites}
                >
                  {loading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <Box p={1 / 2}>Send Invite</Box>
                  )}
                </Button>
                <Box mr={1} />
                <Button
                  disableElevation
                  variant="outlined"
                  aria-label="Close window"
                  onClick={handleCloseDialog}
                >
                  <Box component="span" p={1 / 2}>
                    Close Window
                  </Box>
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Box className={styles.image} />
      </Box>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    flex: 1,
    padding: theme.spacing(8),
  },
  logo: {
    height: 24,
    marginBottom: theme.spacing(4),
  },
  image: {
    flex: 1,
    background: `url(${background})`,
    backgroundPosition: '0 100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

const TOAST_ID = 'notification';

export default InviteUserDialog;
