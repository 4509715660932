import { useHistory, generatePath } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog, comparePlans } from '@sembly-ui';
// App Shared
import { PlanIDEnum } from '@gql-types';
import { ReactComponent as NewBadgeIcon } from '@shared/assets/art-new-badge.svg';
import { Routes } from '@shared/enums';
import { useUserContext } from '@shared/hooks';

export interface MeetSemblianDialogProps {
  onClose: () => void;
}

export const MeetSemblianDialog: React.VFC<MeetSemblianDialogProps> = ({ onClose }) => {
  /* #region  Hooks */
  const theme = useTheme();
  const styles = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();
  const history = useHistory();
  /* #endregion */

  const handleActivateSemblain = () => {
    const userPlan = user.data?.me?.paymentCustomer?.plan?.id ?? PlanIDEnum.PERSONAL;
    const isPaidPlan = comparePlans(userPlan, 'equalsOrHigher', PlanIDEnum.PROFESSIONAL);
    if (isPaidPlan) {
      history.push(Routes.OnboardingSemblianWorkspace);
    } else {
      // User should be on the professional plan or higher to be able to activate the Semblain 2.0
      // Redirect to the checkout page with the Professional plan
      history.push(generatePath(Routes.CheckoutStart, { planId: PlanIDEnum.PROFESSIONAL }));
    }
    onClose();
  };

  const renderStarIcon = (fillColor: string) => (
    <svg
      fill="none"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
    >
      <path
        fill={fillColor}
        d="M10.5 6.18311L8 0.683105L5.5 6.18311L0 8.68311L5.5 11.1831L8 16.6831L10.5 11.1831L16 8.68311L10.5 6.18311Z"
      />
    </svg>
  );

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open: true, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={onClose}
    >
      {isSmallScreen && (
        <Box textAlign="right">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <DialogContent className={styles.dialogContent}>
        <iframe
          className={styles.video}
          width="100%"
          allowFullScreen
          title="YouTube"
          height={isSmallScreen ? '206px' : '292px'}
          src="https://www.youtube.com/embed/J4YWyMpnGnQ"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />

        <Box display="flex" alignItems="center" gridGap={8} mt={3} mb={1}>
          <Typography variant="h5">Meet Semblian 2.0</Typography>
          <NewBadgeIcon />
        </Box>

        <Box mb={4}>
          <Typography paragraph variant="body1">
            Available with any paid plan starting at $25 per user/month (annual plan)
          </Typography>

          <Typography variant="body1">
            {renderStarIcon('#FFD700')}
            <b>AI artifacts:</b> Automate Your Deliverables
          </Typography>
          <Typography variant="body1">
            {renderStarIcon('#009996')}
            <b>AI Insights:</b> Personalized Next-Steps
          </Typography>
          <Typography variant="body1">
            {renderStarIcon('#576ACE')}
            <b>Multi-Meeting Chats:</b> AI Chat Across Multiple Meetings
          </Typography>
        </Box>

        <Box mb={2} width="100%" textAlign="center">
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleActivateSemblain}
          >
            <span>Try it Free</span>
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  video: {
    borderRadius: theme.spacing(1),
    border: 'none',
  },
  icon: {
    position: 'relative',
    top: 3,
    marginRight: theme.spacing(1),
  },
}));

export default MeetSemblianDialog;
