// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import iconImage from '../assets/icon-voice-id-36.svg';

export interface VoiceIdPromotionBannerProps {
  onClose: () => void;
  onManage: () => void;
}

export const VoiceIdPromotionBanner: React.VFC<VoiceIdPromotionBannerProps> = ({
  onClose,
  onManage,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={styles.root}>
      <Box flex={1} display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={2}>
          <img width={36} height={36} alt="Voice ID" src={iconImage} />
        </Box>
        <Box display="flex" flexDirection="column" flex="1">
          <Typography variant="subtitle2">Record your Voiceprint</Typography>
          <Typography variant="body2">
            Using your voiceprint, Sembly will learn to recognize your voice and automatically
            identify your tasks in conversations.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gridGap={6} pl={isSmallScreen ? 6 : 2}>
        <Button
          disableElevation
          size="medium"
          variant="contained"
          classes={{ root: styles.button, contained: styles.contained }}
          onClick={onManage}
        >
          Record
        </Button>
        <Button
          size="medium"
          variant="outlined"
          classes={{ root: styles.button, contained: styles.contained }}
          onClick={onClose}
        >
          Dismiss
        </Button>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#D9F0FE',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
  button: {
    height: 28,
    padding: 12,
    fontSize: theme.typography.body1.fontSize,
  },
  contained: {
    backgroundColor: '#2267B8',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#174880',
      color: theme.palette.common.white,
    },
  },
}));

export default VoiceIdPromotionBanner;
