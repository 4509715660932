// Material UI
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Other
import logoGuest from '@shared/assets/logo-guest.svg';

export interface GuestPageLayoutProps {
  color?: 'default' | 'paper';
  logo?: string;
}

export const GuestPageLayout: React.FC<GuestPageLayoutProps> = ({
  children,
  color = 'default',
  logo = logoGuest,
}) => {
  const { palette } = useTheme();
  const styles = useStyles();

  const background = color === 'default' ? palette.background.default : palette.background.paper;

  return (
    <div className={styles.root} style={{ background }}>
      <div className={styles.content}>
        <AppBar className={styles.appBar} position="fixed" elevation={0} color="inherit">
          <Toolbar className={styles.toolbar}>
            <div className={styles.nogrow}>
              <a
                href="https://www.sembly.ai"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.logoContainer}
              >
                <img src={logo} alt="Sembly" className={styles.logo} />
              </a>
            </div>
            <div className={styles.grow}></div>
            <div className={styles.nogrow}>
              <Button
                disableElevation
                color="secondary"
                variant="contained"
                href="https://www.sembly.ai/pricing/"
                target="_self"
                rel="noopener"
                aria-label="Start Free Trial"
              >
                <Box component="span" ml={1} whiteSpace="nowrap">
                  Start Free Trial
                </Box>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <div className={styles.appBarSpacer} />
        {children}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  grow: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
  },
  nogrow: {
    flexGrow: 0,
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(2),
  },
  logoContainer: {
    display: 'flex',
    height: 20,
  },
  logo: {
    maxHeight: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 180,
    },
  },
  toolbar: {
    flex: 1,
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
    },
  },
}));

export default GuestPageLayout;
