export const event = (
  arg: {
    event: string;
  } & Record<string, string | number | boolean>,
) => {
  if (!window.dataLayer) {
    console.warn('GTM is not available. Event not sent.');
    return;
  }

  const { event, ...rest } = arg;
  window.dataLayer.push({ event, ...rest });
};
