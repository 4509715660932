import { VFC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export interface LabeledDividerProps {
  label: string;
  className?: string;
}

export const LabeledDivider: VFC<LabeledDividerProps> = ({ label, className = '' }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className}`}>
      <span className={classes.line} />
      <span className={classes.label}>{label}</span>
      <span className={classes.line} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    opacity: 0.65,
    margin: theme.spacing(0, 1),
  },
  line: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    flex: '1 1 auto',
    minWidth: 1,
    margin: theme.spacing(1, 0),
  },
}));

export default LabeledDivider;
