import { useState } from 'react';
// Material UI
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { TransitionSlide } from '@sembly-ui';
// App Shared
import accentImage from '@shared/assets/calendar-accent.webp';
import backgroundImage from '@shared/assets/generic-gradient-background.webp';
import logoImage from '@shared/assets/logo.svg';
import secureLogoImage from '@shared/assets/secure-logo.svg';
import { APP_NAME } from '@shared/configuration';
import { CalendarSynchronizationTypes } from '@gql-types';

export interface CalendarSyncTypeOnboardingProps {
  disableSkip?: boolean;
  onClose: () => void;
  onChangeSyncType: (value: CalendarSynchronizationTypes | null) => void;
}

export const CalendarSyncTypeOnboarding: React.VFC<CalendarSyncTypeOnboardingProps> = ({
  disableSkip = false,
  onClose,
  onChangeSyncType,
}) => {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState<CalendarSynchronizationTypes>(
    CalendarSynchronizationTypes.OWNED_MEETINGS,
  );

  /* #region  Handlers */
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedVariant(event.target.value as CalendarSynchronizationTypes);
  };

  const handleChangeSyncType = () => {
    setIsLoading(true);
    onChangeSyncType(selectedVariant);
  };

  const handleSkipConnectCalendar = () => {
    setIsLoading(true);
    onChangeSyncType(null);
  };
  /* #endregion */

  /* #region  Component Constants */
  const buttonsList = [
    {
      value: CalendarSynchronizationTypes.OWNED_MEETINGS,
      label: 'Only meetings I created',
    },
    {
      value: CalendarSynchronizationTypes.ALL_MEETINGS,
      label: 'All meetings in my calendar',
    },
    {
      value: CalendarSynchronizationTypes.INVITE_ONLY,
      label: `Don't automatically attend`,
    },
  ];
  /* #endregion */

  return (
    <Dialog fullScreen open onClose={onClose} TransitionComponent={TransitionSlide}>
      <Grid container className={styles.root}>
        <Grid item xs={12} lg={6} className={styles.primary}>
          <AppBar className={styles.appBar} position="static" color="transparent">
            <Toolbar>
              <img src={logoImage} alt={APP_NAME} className={styles.logo} />
            </Toolbar>
          </AppBar>
          <div className={styles.content}>
            <div className={styles.container}>
              <Typography className={styles.mainHead}>Almost done</Typography>
              <Typography className={styles.description}>
                Sembly works best when connected with your calendar.&nbsp;
                <span className={styles.symbol}>*</span>
              </Typography>
              <FormControl className={styles.formControl} fullWidth component="fieldset">
                <RadioGroup value={selectedVariant} onChange={handleRadioChange}>
                  {buttonsList.map((elem, index) => (
                    <FormControlLabel
                      key={index}
                      className={`${styles.radioButton} ${
                        elem.value === selectedVariant ? styles.checked : styles.border
                      }`}
                      value={elem.value}
                      control={<Radio color="primary" />}
                      label={<Typography className={styles.label}>{elem.label}</Typography>}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Box mt={2} mb={4} p={3} width="100%" display="flex" className={styles.extraBox}>
                <img src={secureLogoImage} alt="SOC2" className={styles.secureLogo} />
                <Typography variant="body2" className={styles.text}>
                  To provide you the optimal Sembly experience, we kindly ask you to{' '}
                  <span className={styles.textBold}> grant Sembly permissions</span> to access your
                  calendar. Rest assured, we request the minimal permissions needed and follow{' '}
                  <span className={styles.textBold}> SOC2 </span>
                  and <span className={styles.textBold}>GDPR</span> best practices in handling your
                  information.
                </Typography>
              </Box>
              <Box>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  className={styles.button}
                  endIcon={isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  aria-label="Grant Access to Calendar"
                  onClick={handleChangeSyncType}
                >
                  Grant Access
                </Button>

                {!disableSkip && (
                  <Button
                    disabled={isLoading}
                    className={styles.button}
                    aria-label="Skip Connect Calendar"
                    onClick={handleSkipConnectCalendar}
                  >
                    Skip
                  </Button>
                )}
              </Box>
            </div>
          </div>
          <Typography variant="body2" className={styles.info}>
            <span className={styles.infoSymbol}>*</span> You can change this later in your profile
          </Typography>
        </Grid>
        <Grid item xs={false} lg={6} className={styles.secondary}>
          <img
            loading="lazy"
            src={accentImage}
            alt="Connect Calendar"
            style={{ objectFit: 'contain', maxWidth: 500, width: '50%', height: '50%' }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row-reverse',
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(0, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  label: { fontWeight: 500 },
  appBar: {
    boxShadow: 'none',
    flex: 0,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  formControl: {
    marginLeft: '10px',
  },
  extraBox: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  logo: {
    height: 24,
  },
  secureLogo: {
    marginRight: theme.spacing(2),
  },
  primary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 2)
    },
  },
  secondary: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#1D202B',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  infoSymbol: {
    color: theme.palette.primary.main,
  },
  info: {
    flex: 0,
    textAlign: 'center',
  },
  text: {
    textAlign: 'left',
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
  },
  textBold: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  mainHead: {
    marginBottom: '10px',
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 500,
  },
  description: {
    marginBottom: theme.spacing(4),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 500,
  },
  button: {
    margin: theme.spacing(0, 0.5, 1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  checked: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  border: {
    border: `2px solid ${theme.palette.grey[200]}`,
  },
  radioButton: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    fontSize: theme.typography.h6.fontSize,
    cursor: 'pointer',
  },
  symbol: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: 2,
    verticalAlign: 'top',
  },
}));

export default CalendarSyncTypeOnboarding;
