import { MicrophoneSettings } from '../../types';

export const getSelectedMicrophone = (): MediaDeviceInfo | null => {
  const micsString = localStorage.getItem('mics');

  if (micsString) {
    try {
      const parsedValue = JSON.parse(micsString) as MicrophoneSettings;
      return parsedValue?.selectedDevice?.deviceId ? parsedValue?.selectedDevice : null;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
};

export default getSelectedMicrophone;
