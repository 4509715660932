import { Redirect } from 'react-router-dom';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

/**
 * @deprecated since SB26
 * @returns JSX.Element
 */
export const ConfirmEmailAliasModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    exact: true,
    path: Routes.ConfirmEmailAliasLegacy,
    render: () => <Redirect to={Routes.ConfirmEmailAlias} />,
  },
};

export default ConfirmEmailAliasModule;
