import { useState, useEffect } from 'react';
import clsx from 'clsx';
// Material UI
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import HelpIcon from '../icons/Help';

/* #region  Types */
export interface GlanceViewNavItem {
  id: string;
  numberOfActivities: number;
  title: string;
}

export interface KeyItemsViewProps {
  isRTLDirected?: boolean;
  navItems: GlanceViewNavItem[];
}
/* #endregion */

export const KeyItemsView: React.FC<KeyItemsViewProps> = ({
  children,
  isRTLDirected = false,
  navItems,
}) => {
  const styles = useStyles();

  const [isVisibleHelpBadge, setIsVisibleHelpBadge] = useState(false);

  /* #region  Handlers */
  const handleJumpToSection = (scrollId: string) => () => {
    const target = document.querySelector(`[data-scroll-group-id="${scrollId}"]`);
    if (!!target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      target.classList.add('highlighted');
      setTimeout(() => target.classList.remove('highlighted'), 0);
    }
  };

  const handleHideHelpBadge = () => {
    sessionStorage.setItem('semblyTranscriptionHelpBadge', 'true');
    setIsVisibleHelpBadge(true);
  };
  /* #endregion */

  useEffect(() => {
    setIsVisibleHelpBadge(!!sessionStorage.getItem('semblyTranscriptionHelpBadge'));
  }, []);

  return (
    <>
      <div className={clsx(styles.actions, isRTLDirected && 'rtl')}>
        <div className={styles.nav}>
          {navItems.map(({ id, title, numberOfActivities }) => {
            return !numberOfActivities ? null : (
              <Chip
                key={id}
                size="small"
                variant="outlined"
                className={styles.chip}
                label={`${title} (${numberOfActivities})`}
                onClick={handleJumpToSection(id)}
              />
            );
          })}
        </div>
        <a
          target="_blank"
          href="https://sembly-ai.zendesk.com/hc/en-us/articles/8839674096529-Add-Key-Items-with-the-In-meeting-command"
          title="How do I use Sembly commands?"
          rel="noopener noreferrer"
        >
          <Badge color="secondary" variant="dot" invisible={isVisibleHelpBadge} overlap="circular">
            <IconButton onClick={handleHideHelpBadge} size="small">
              <HelpIcon />
            </IconButton>
          </Badge>
        </a>
      </div>
      {children}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    position: 'sticky',
    alignItems: 'center',
    top: 0,
    padding: theme.spacing(1, 1, 0),
    marginLeft: `-${theme.spacing(1)}px`,
    width: `calc(100% + ${theme.spacing(2)}px)`,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    '&.rtl': {
      direction: 'rtl',
    },
  },
  nav: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1 / 2, 1, 1 / 2, 0),
    transition: 'color 0.5s linear, backgroud-color 0.3s ease 0.5s',
    borderColor: theme.palette.grey['A100'],
    color: theme.palette.common.black,
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(8),
    flexDirection: 'column',
    cursor: 'default',
  },
}));
