import clsx from 'clsx';
import sanitizeHtml from 'sanitize-html';
import { useState, useEffect } from 'react';
// Material UI
import Box, { BoxProps } from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Lib Shared Components
import IntegrationsIcon from '../icons/LightningBolt';
import ContentEditable, { ContentEditableEvent } from './ContentEditable';
import MeetingTypePicker from '../components/MeetingTypePicker';
import OutlinedIconButton from '../components/OutlinedIconButton';
import ParticipantAvatars, { MeetingParticipant } from '../components/MeetingParticipantAvatars';
import StatusChip from '../components/MeetingStatusChip';
import StatusInfo from '../components/MeetingStatusInfo';
import { AGENT_CALL_PLATFORMS } from '../constants';
import { MeetingTeamPicker } from '../containers';
import { CustomMeetingStatuses } from '../enums';
import {
  AgentCallPlatform,
  GenericTeam,
  MeetingProcessingResults,
  MeetingStatuses,
  MeetingTypes,
} from '../types';

export interface MeetingHeaderMenuItem {
  label: string;
  icon: JSX.Element | null;
  handler: React.MouseEventHandler<HTMLLIElement>;
  hidden: boolean;
}

export interface MeetingHeaderProps extends BoxProps {
  agentCallPlatform: AgentCallPlatform | null;
  callIssueCategory: string | null;
  isAuthorizedToExport: boolean;
  isAuthorizedToManage: boolean;
  isChangingMeetingType?: boolean;
  isHiddenMeetingTeam?: boolean;
  isHiddenMeetingType?: boolean;
  isHiddenIntegrations?: boolean;
  isInContextWorkspace: boolean;
  isRTLDirected?: boolean;
  isRecurrentMeeting: boolean;
  meetingFinishDate: string | null;
  meetingId: string;
  meetingOwnerFullName: string | null;
  meetingParticipants: MeetingParticipant[];
  meetingProcessingResults: MeetingProcessingResults | null;
  meetingStartDate: string | null;
  meetingStatus: MeetingStatuses | CustomMeetingStatuses | null;
  meetingTeam: GenericTeam | null;
  meetingTitle: string | null;
  meetingType: MeetingTypes | null;
  menuItems?: MeetingHeaderMenuItem[];
  onChangeMeetingTitle?: (meetingId: string, meetingTitle: string) => void;
  onChangeMeetingTeam?: (meetingId: string, team: GenericTeam, variant: 'all' | 'single') => void;
  onChangeMeetingType?: (
    meetingId: string,
    meetingType: MeetingTypes,
    variant: 'all' | 'single',
  ) => void;
  onDeleteMeeting?: (meetingId: string) => void;
  onShareMeeting?: (meetingId: string) => void;
  onClickOnUpgradePlan?: () => void;
  onShowIntegrationsMenu?: (anchorEl: HTMLElement) => void;
}

export const MeetingHeader: React.VFC<MeetingHeaderProps> = ({
  agentCallPlatform,
  callIssueCategory,
  isAuthorizedToExport,
  isAuthorizedToManage,
  isChangingMeetingType = false,
  isHiddenMeetingTeam = false,
  isHiddenMeetingType = false,
  isHiddenIntegrations = false,
  isInContextWorkspace,
  isRTLDirected = false,
  isRecurrentMeeting,
  meetingFinishDate,
  meetingId,
  meetingOwnerFullName,
  meetingParticipants,
  meetingProcessingResults,
  meetingStartDate,
  meetingStatus,
  meetingTeam,
  meetingTitle,
  meetingType,
  menuItems = [],
  onChangeMeetingTitle,
  onChangeMeetingType,
  onChangeMeetingTeam,
  onClickOnUpgradePlan,
  onDeleteMeeting,
  onShareMeeting,
  onShowIntegrationsMenu,
  ...props
}) => {
  const MAX_TITLE_LENGTH = 1024;

  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [title, setTitle] = useState('');
  /* #endregion */

  /* #region  Handlers */
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleChangeTitle = (e: ContentEditableEvent) => {
    const updTitle = sanitizeHtml(e.target.value, { allowedAttributes: {}, allowedTags: [] });
    setTitle(updTitle);
  };

  const handleBlurTitle: React.FocusEventHandler<HTMLDivElement> = (event) => {
    const value = event.currentTarget.textContent || '';
    const updTitle = sanitizeHtml(value, { allowedAttributes: {}, allowedTags: [] });
    const updTitleLength = updTitle.length;
    const isValidUpdTitle = !!updTitleLength && updTitleLength <= MAX_TITLE_LENGTH;

    setTitle(isValidUpdTitle ? updTitle : meetingTitle || 'Untitled Meeting');

    if (onChangeMeetingTitle && isValidUpdTitle) {
      onChangeMeetingTitle(meetingId, updTitle);
    }
  };

  const handleChangeMeetingTeam = (team: GenericTeam, variant: 'all' | 'single') => {
    if (!onChangeMeetingTeam) throw new Error('Meeting team handler is undefined');
    onChangeMeetingTeam(meetingId, team, variant);
  };

  const handleChangeMeetingType = (type: MeetingTypes, variant: 'all' | 'single') => {
    if (!onChangeMeetingType) throw new Error('Meeting type handler is undefined');
    onChangeMeetingType(meetingId, type, variant);
  };

  const handleClickOnShare = () => {
    if (!onShareMeeting) return;
    onShareMeeting(meetingId);
  };

  const handleShowIntegrationsMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!onShowIntegrationsMenu) return;
    onShowIntegrationsMenu(event.currentTarget);
  };

  const handleClickOnMenuItem =
    (item: MeetingHeaderMenuItem) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      handleCloseMenu();
      item.handler(e);
    };
  /* #endregion */

  useEffect(() => {
    setTitle(meetingTitle || '');
  }, [meetingTitle]);

  /* #region  Render Helpers */
  const isSubmitted = meetingStatus === MeetingStatuses.submitted;
  const isFailed = meetingStatus === MeetingStatuses.failed;
  const isProcessing = meetingStatus === MeetingStatuses.processing;
  const isEditable = isSubmitted || isFailed;
  const isDisabledEditingTitle = !isEditable || !isAuthorizedToManage || !onChangeMeetingTitle;
  const isDisabledEditingMeetingType =
    !isAuthorizedToManage || !onChangeMeetingTitle || isProcessing;
  const isProcessedNotes = meetingProcessingResults?.processedMeetingNotes ?? false;
  const isProcessingComplete = meetingStatus === MeetingStatuses.submitted;
  const isShareable = isProcessingComplete || isProcessedNotes;
  const menu = menuItems.filter((item) => !item.hidden);
  const platform = agentCallPlatform ? AGENT_CALL_PLATFORMS[agentCallPlatform] : null;
  /* #endregion */

  const renderActionCenter = (
    <Box display="flex">
      <Box
        flex={1}
        display="flex"
        alignItems="center"
        gridGap={8}
        style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}
      >
        <div id="google_translate_element" />
        <ParticipantAvatars
          participants={meetingParticipants}
          avatarGroupProps={{ classes: { avatar: styles.avatar } }}
        />
        {!!onShareMeeting && (
          <Button
            disableElevation
            size="small"
            color="primary"
            variant="contained"
            onClick={handleClickOnShare}
          >
            <Box my={1}>Share</Box>
          </Button>
        )}
      </Box>

      {isAuthorizedToExport && isShareable && !!onShowIntegrationsMenu && !isHiddenIntegrations && (
        <Box flex={0} ml={1}>
          <OutlinedIconButton
            size="small"
            title="Integrations"
            onClick={handleShowIntegrationsMenu}
          >
            <Box display="flex" m={1 / 2}>
              <IntegrationsIcon fontSize="small" />
            </Box>
          </OutlinedIconButton>
        </Box>
      )}

      {!!menu.length && (
        <Box flex={0} ml={1} minWidth={32}>
          <OutlinedIconButton size="small" onClick={handleOpenMenu}>
            <Box display="flex" m={1 / 2}>
              <MoreVertIcon fontSize="small" />
            </Box>
          </OutlinedIconButton>
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Box dir="auto" {...props}>
        <Hidden lgUp>
          <Box mt={2} mb={2}>
            {renderActionCenter}
          </Box>
        </Hidden>
        <Box display="flex" style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}>
          <Box flex={1} mr={isRTLDirected ? 0 : 4} ml={isRTLDirected ? 4 : 0}>
            <Typography
              className={styles.title}
              variant={isSmallScreen ? 'h6' : 'h4'}
              style={{
                direction: isRTLDirected ? 'rtl' : 'inherit',
                textAlign: isRTLDirected ? 'right' : 'initial',
              }}
            >
              <ContentEditable
                html={title}
                dir="none"
                isRTLDirected={isRTLDirected}
                maxLength={MAX_TITLE_LENGTH}
                disabled={isDisabledEditingTitle}
                className={clsx(styles.editableHeader, isDisabledEditingTitle && styles.disabled)}
                onChange={handleChangeTitle}
                onBlur={handleBlurTitle}
              />
            </Typography>
          </Box>
          <Box flex={0} pt={0.25}>
            <Hidden mdDown>{renderActionCenter}</Hidden>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}
        >
          <Box className={styles.meta} mr={isRTLDirected ? 0 : 2} ml={isRTLDirected ? 2 : 0}>
            <span className={styles.platformIcon} title={platform?.title}>
              {platform ? platform.icon : null}
            </span>
          </Box>
          <StatusInfo
            showStartDate
            finishDate={meetingFinishDate}
            isRecurrentMeeting={isRecurrentMeeting}
            ownerFullName={meetingOwnerFullName}
            startDate={meetingStartDate}
          />
          {!isSmallScreen && <Box mr={2} />}
          <Box className={styles.meta} mr={isRTLDirected ? 0 : 2} ml={isRTLDirected ? 2 : 0}>
            {(!isSubmitted || isProcessing) && (
              <StatusChip
                meetingStatus={meetingStatus}
                processingResult={meetingProcessingResults}
                minHeight={32}
              />
            )}
            {!isHiddenMeetingType && (
              <>
                {(!isAuthorizedToManage && !meetingType) ||
                (isAuthorizedToExport && isProcessing && !meetingType) ? null : (
                  <MeetingTypePicker
                    value={meetingType}
                    isRecurrentMeeting={isRecurrentMeeting && isInContextWorkspace}
                    isDisabled={isDisabledEditingMeetingType || isChangingMeetingType}
                    onChange={handleChangeMeetingType}
                  />
                )}
                {isChangingMeetingType && <CircularProgress size={20} />}
              </>
            )}

            {!isHiddenMeetingTeam && (
              <>
                {(!isAuthorizedToManage && !meetingTeam) ||
                (!isInContextWorkspace && !meetingTeam) ? null : (
                  <MeetingTeamPicker
                    selectedTeam={meetingTeam ?? null}
                    isRecurrentMeeting={isRecurrentMeeting && isInContextWorkspace}
                    isDisabled={isProcessing || !isAuthorizedToManage || !isInContextWorkspace}
                    onChange={handleChangeMeetingTeam}
                  />
                )}
              </>
            )}
          </Box>
        </Box>

        {callIssueCategory === 'unexpected_failure' && (
          <Box bgcolor="#D9F0FE" color="#2267B8" mt={3} px={1} py={0.5} borderRadius={3}>
            <Typography variant="body2" align="center">
              Apologies! Sembly could only record and process part of your meeting due to a
              technical issue.
            </Typography>
          </Box>
        )}
      </Box>

      {/* Begin: Menus */}
      {menu.length > 0 && (
        <Menu
          keepMounted
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
        >
          {menu.map((item, index) => (
            <MenuItem
              key={`menu-item-${item.label}-${index}`}
              onClick={handleClickOnMenuItem(item)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Menu>
      )}
      {/* End: Menus */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: 1.5,
  },
  editableHeader: {
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
  },
  disabled: {
    cursor: 'default',
  },
  meta: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    flexWrap: 'wrap',
    gridGap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  avatar: {
    width: 36,
    height: 36,
    fontSize: '0.875rem',
    borderWidth: 1,
    borderColor: theme.palette.grey[200],
  },
  platformIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default MeetingHeader;
