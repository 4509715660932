// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
// Sembly UI
import { GenericDialog } from '@sembly-ui';

export interface AudioRecordingIsTooQuietProps {
  open: boolean;
  onClose: () => void;
}

export const AudioRecordingIsTooQuiet: React.VFC<AudioRecordingIsTooQuietProps> = ({
  open,
  onClose,
}) => {
  return (
    <GenericDialog
      dialogProps={{
        open: open,
        fullWidth: true,
        maxWidth: 'xs',
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box mt={6} mb={4} textAlign="center">
          <Typography gutterBottom variant="h5" color="primary">
            Sembly was not able to record your voice
          </Typography>
          <Typography variant="body1">
            Is your microphone muted? Please make sure your microphone is working and try again.
          </Typography>
        </Box>
        <Box mb={6} textAlign="center">
          <Button
            disableElevation
            variant="contained"
            color="primary"
            aria-label="Close"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

export default AudioRecordingIsTooQuiet;
