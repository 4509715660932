// Material UI
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface KeyItemsIntegrationsLayoutProps {
  title: string;
}

export const KeyItemsIntegrationsLayout: React.FC<KeyItemsIntegrationsLayoutProps> = ({
  children,
  title,
}) => {
  return (
    <>
      <Box px={2} my={2}>
        <Typography component="div">
          <b>{title}</b>
        </Typography>
      </Box>
      {children}
    </>
  );
};

export default KeyItemsIntegrationsLayout;
