import { Link } from 'react-router-dom';
// Material UI
import InfoIcon from '@material-ui/icons/InfoRounded';
import { makeStyles } from '@material-ui/core/styles';
// App Shared
import { Banner } from '@shared/components';
import { Routes } from '@shared/enums';

export const CalendarPromoBanner: React.VFC = () => {
  const classes = useStyles();

  return (
    <Banner
      color="#2267B8"
      bgcolor="#D9F0FE"
      icon={<InfoIcon color="inherit" />}
      content={
        <div className={classes.content}>
          <span>
            Connect your calendar to unlock the full experience and let Sembly join your scheduled
            meetings.
          </span>
          <Link to={Routes.SettingsCalendars} className={classes.link}>
            Connect Calendar
          </Link>
        </div>
      }
    />
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    ...theme.typography.body1,
    marginLeft: theme.spacing(1),
    color: '#2267B8',
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.body2,
      textAlign: 'left',
    },
  },
  link: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginLeft: theme.spacing(1),
  },
}));

export default CalendarPromoBanner;
