import { PlanIDEnum } from '@gql-types/globalTypes';
// Assets
import logoPersonal from '@shared/assets/logo-personal.svg';
import logoProfessional from '@shared/assets/logo-professional.svg';
import logoTeam from '@shared/assets/logo-team.svg';
import logoTeamPlus from '@shared/assets/logo-team-plus.svg';
import logoEnterprise from '@shared/assets/logo-enterprise.svg';

export interface PaymentPlan {
  name: string;
  logo: string;
}

export type PaymentPlans = { [K in PlanIDEnum]: PaymentPlan };

export const PAYMENT_PLANS: PaymentPlans = {
  [PlanIDEnum.PERSONAL]: {
    name: 'Personal',
    logo: logoPersonal,
  },
  [PlanIDEnum.PROFESSIONAL]: {
    name: 'Professional',
    logo: logoProfessional,
  },
  [PlanIDEnum.TEAM]: {
    name: 'Team',
    logo: logoTeam,
  },
  [PlanIDEnum.TEAM_PLUS]: {
    name: 'Team Plus',
    logo: logoTeamPlus,
  },
  [PlanIDEnum.ENTERPRISE]: {
    name: 'Enterprise',
    logo: logoEnterprise,
  },
} as const;

export default PAYMENT_PLANS;
