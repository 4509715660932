import { SvgIcon } from '@material-ui/core';

export const MinutesBlank = () => (
  <SvgIcon viewBox="0 0 64 64">
    <path
      fill="#fff"
      d="M15 63a5 5 0 01-5-5V6a5 5 0 015-5h23.51a5 5 0 013.54 1.46L54.54 15A5 5 0 0156 18.49V58a5 5 0 01-5 5z"
    />
    <path d="M38.51 2a4 4 0 012.83 1.17l12.49 12.49A4 4 0 0155 18.49V58a4 4 0 01-4 4H15a4 4 0 01-4-4V6a4 4 0 014-4h23.51m0-2H15a6 6 0 00-6 6v52a6 6 0 006 6h36a6 6 0 006-6V18.49a6 6 0 00-1.76-4.25L42.76 1.76A6 6 0 0038.51 0z" />
    <path fill="#fff" d="M47 15a5 5 0 01-5-5V2.42L54.59 15z" />
    <path d="M43 4.83L52.17 14H47a4 4 0 01-4-4V4.83M41 .55V10a6 6 0 006 6h9.45a5.88 5.88 0 00-1.21-1.76L42.76 1.76A5.83 5.83 0 0041 .55z" />
    <path d="M17 8H18V16H17z" />
  </SvgIcon>
);

export default MinutesBlank;
