// Material UI
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Lib Types
import { GenericAccessItem, GenericDefaultUser } from '../types';

export interface ParticipantsDataItemProp extends GenericAccessItem {
  user: GenericDefaultUser | null;
}

export interface ParticipantsFormProps {
  data: ParticipantsDataItemProp[];
  editable?: boolean;
  currentUserId: string;
  onUpdate: (data: { id: string; canManage: boolean }) => void;
  onClickOnInvite: (users: [{ canManage: boolean; email: string }]) => void;
  onRemove: (id: string) => void;
}

export const ParticipantsForm: React.VFC<ParticipantsFormProps> = ({
  data,
  editable = false,
  currentUserId,
  onClickOnInvite,
  onRemove,
  onUpdate,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  /* #region  Handlers */
  const handleChangeValue = (id: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    event.preventDefault();
    if (event.target.value === 'edit') {
      return onUpdate({ id, canManage: true });
    }
    if (event.target.value === 'view') {
      return onUpdate({ id, canManage: false });
    }
    onRemove(id);
  };

  const handleInvite =
    ({ canManage, email }: GenericAccessItem) =>
    (event: unknown) => {
      if (!email) return;
      onClickOnInvite([{ canManage, email }]);
    };
  /* #endregion */

  const ACTIONS: Record<string, string> = {
    view: 'Can view',
    edit: 'Can edit',
    remove: 'Remove',
  } as const;

  return (
    <List>
      {data.map((item) => {
        const currentValue = item.canManage ? 'edit' : 'view';
        const isOwner = item.user?.id === currentUserId;
        const isKnownGuest = !item?.user && item.email;
        const name = item.user?.fullName || 'Guest';
        const email = item.user?.email || item.email || 'No email';

        return (
          <ListItem divider={!isSmallScreen} key={item.id} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={name} src={item.user?.avatar || undefined} />
            </ListItemAvatar>
            <ListItemText classes={{ secondary: styles.text }} primary={name} secondary={email} />
            <ListItemSecondaryAction
              style={isSmallScreen ? { position: 'static', transform: 'none' } : undefined}
            >
              <Box display="flex">
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    value={currentValue}
                    disabled={isOwner || !editable}
                    onChange={handleChangeValue(item.id)}
                  >
                    {Object.keys(ACTIONS).map((key) => (
                      <MenuItem key={key} value={key}>
                        {ACTIONS[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isKnownGuest && editable && (
                  <Box ml={1}>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={handleInvite(item)}
                    >
                      <Box component="span" fontSize="0.875rem" whiteSpace="nowrap">
                        Resend invite
                      </Box>
                    </Button>
                  </Box>
                )}
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const useStyles = makeStyles(() => {
  return {
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '275px',
    },
  };
});

export default ParticipantsForm;
