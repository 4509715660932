import { lazyWithRetry } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

const ConfirmationsModuleContainer = lazyWithRetry(
  () => import('./containers/ConfirmationsModuleContainer'),
);

export const ConfirmationsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Confirm,
    render: () => <ConfirmationsModuleContainer />,
  },
};

export default ConfirmationsModule;
