import { Prompt } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import Box, { BoxProps } from '@material-ui/core/Box';
// Lib Shared
import { SpeakIndicatiedRecordingControl } from './SpeakIndicatiedRecordingControl';
import {
  useVoiceRecorder,
  VoiceRecorderError,
  VoiceRecorderWarn,
  VoiceRecordingChunk,
  VoiceRecordingResult,
} from '../hooks/UseVoiceRecorder';

/* #region  Types */
export interface VoiceRecorderResultMeta {
  isDetectedSpeaking: boolean;
  isUnsupportedSpeakingDetection: boolean;
}

export interface VoiceRecorderProps {
  audioTrackConstraints?: MediaTrackConstraints;
  chunkMaxDurationSeconds?: number;
  disabled?: boolean;
  displayVoiceIndicator?: boolean;
  exactDeviceId?: string;
  forceRecordingStart?: boolean; // starts recording immediately after the recorder is initialized
  idealDeviceId?: string;
  maxDurationSeconds?: number;
  restartRecordingAtMaxDuration?: boolean; // automatic restart of the recording when the maximum duration is reached
  silenceDetectionTimeoutSeconds?: number;
  wrapperElementProps?: BoxProps;
  onCompleteRecording?: (data: VoiceRecordingResult, meta: VoiceRecorderResultMeta) => void;
  onDataAvailable?: (chunk: VoiceRecordingChunk, meta: VoiceRecorderResultMeta) => void;
  onDelete?: () => void;
  onDeleteConfirmation?: () => Promise<boolean>;
  onError?: (error: VoiceRecorderError) => void;
  onSilenceDetected?: () => void;
  onStart?: (sliceNumber: number) => Promise<boolean>;
  onStop?: (isMaxDurationReached: boolean) => void;
  onWarn?: (error: VoiceRecorderWarn) => void;
}
/* #endregion */

export const VoiceRecorder: React.FC<VoiceRecorderProps> = ({
  audioTrackConstraints = {},
  children,
  chunkMaxDurationSeconds,
  disabled = false,
  exactDeviceId,
  forceRecordingStart = false,
  idealDeviceId,
  maxDurationSeconds,
  restartRecordingAtMaxDuration = false,
  silenceDetectionTimeoutSeconds = 20,
  wrapperElementProps,
  onCompleteRecording = () => null,
  onDataAvailable = () => null,
  onDelete = () => null,
  onDeleteConfirmation = () => true,
  onError = () => null,
  onSilenceDetected = () => null,
  onStart = () => null,
  onStop = () => null,
  onWarn = () => null,
}) => {
  /* #region  Hooks */
  const [isInitialization, setIsInitialization] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const intervalRef = useRef<NodeJS.Timer>();
  const isDetectedSpeakingRef = useRef(false);
  const isMountedRef = useRef(false);
  const isUnsupportedSpeakingDetectionRef = useRef(false);
  const sliceNumberRef = useRef(1);
  const timeoutSecondsRef = useRef<number | null>(null);

  const { start, stop, pause, resume, status } = useVoiceRecorder(
    // onDataAvailable
    (chunk: VoiceRecordingChunk) => {
      onDataAvailable(chunk, {
        isDetectedSpeaking: isDetectedSpeakingRef.current,
        isUnsupportedSpeakingDetection: isUnsupportedSpeakingDetectionRef.current,
      });
    },
    // onCompleteRecording
    (data: VoiceRecordingResult) => {
      const isDetectedSpeaking = isDetectedSpeakingRef.current;
      const isUnsupportedSpeakingDetection = isUnsupportedSpeakingDetectionRef.current;
      const hasContent = isDetectedSpeaking || isUnsupportedSpeakingDetection;
      onCompleteRecording(data, { isDetectedSpeaking, isUnsupportedSpeakingDetection });
      if (restartRecordingAtMaxDuration && data.isMaxDurationReached && hasContent) {
        // maximum duration reached, restart the recorder
        sliceNumberRef.current = sliceNumberRef.current + 1;
        handleStartRecording();
      } else {
        sliceNumberRef.current = 1;
      }
    },
    {
      onError,
      onWarn,
      chunkDurationSeconds: chunkMaxDurationSeconds,
    },
  );
  /* #endregion */

  /* #region  Helper Functions */
  const stopRecording = (options?: { isForce?: boolean; isMaxDurationReached?: boolean }) => {
    const isForce = options?.isForce ?? false;
    const isMaxDurationReached = options?.isMaxDurationReached ?? false;

    if (intervalRef.current) clearInterval(intervalRef.current);
    if (!isMaxDurationReached) sliceNumberRef.current = 1;
    stop({ isForce, isMaxDurationReached });
    if (!isForce) onStop(isMaxDurationReached);
    setMediaStream(null);
  };
  /* #endregion */

  /* #region  Handlers */
  const handleChangeSpeakingState = (isSpeaking: boolean, isUnsupportedDetection: boolean) => {
    if (isSpeaking) isDetectedSpeakingRef.current = true;
    if (isUnsupportedDetection) isUnsupportedSpeakingDetectionRef.current = true;
  };

  const handleRunInterval = () => {
    intervalRef.current = setInterval(() => {
      if (timeoutSecondsRef.current === 0) {
        // max duration reached
        stopRecording({ isMaxDurationReached: true });
        if (intervalRef.current) clearInterval(intervalRef.current);
      } else {
        if (timeoutSecondsRef.current === null) {
          // no limit on maximum duration
          if (intervalRef.current) clearInterval(intervalRef.current);
          return;
        }
        timeoutSecondsRef.current--;
      }
    }, 1000);
  };

  const handleStartRecording = async () => {
    const isStarted = await onStart(sliceNumberRef.current);

    if (!isStarted) return;

    setIsInitialization(true);

    if (intervalRef.current) clearInterval(intervalRef.current);
    timeoutSecondsRef.current = maxDurationSeconds || null;
    isDetectedSpeakingRef.current = false;
    isUnsupportedSpeakingDetectionRef.current = false;

    const stream = await start({ audioTrackConstraints, exactDeviceId, idealDeviceId });

    if (!isMountedRef.current) return;

    setIsInitialization(false);

    if (!stream) return;

    setMediaStream(stream);
    handleRunInterval();
  };

  const handleStopRecording = async () => {
    stopRecording();
  };

  const handleDeleteRecording = async () => {
    const isConfirmed = await onDeleteConfirmation();
    if (isConfirmed) {
      stopRecording({ isForce: true });
      onDelete();
    }
  };

  const handlePauseRecording = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    pause();
  };

  const handleResumeRecording = () => {
    if (intervalRef.current) handleRunInterval();
    resume();
  };
  /* #endregion */

  const isRecording = status === 'RECORDING' || status === 'PAUSED';

  /* #region  Effects */
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Check if it is necessary to perform an autostart of the recording
  useEffect(() => {
    if (forceRecordingStart) {
      handleStartRecording();
    }
    // there is no need to track `handleStartRecording` updates here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRecordingStart]);

  useEffect(() => {
    function beforeUnloadListener(event: BeforeUnloadEvent) {
      event.preventDefault();
      return (event.returnValue = '');
    }

    if (isRecording) {
      window.addEventListener('beforeunload', beforeUnloadListener);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    }

    return () => window.removeEventListener('beforeunload', beforeUnloadListener);
  }, [isRecording]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);
  /* #endregion */

  return (
    <>
      {isRecording ? (
        <SpeakIndicatiedRecordingControl
          disabled={disabled}
          initialization={disabled}
          mediaStream={mediaStream}
          silenceDetectionTimeoutSeconds={silenceDetectionTimeoutSeconds}
          paused={status === 'PAUSED'}
          onClickOnStop={handleStopRecording}
          onClickOnPause={handlePauseRecording}
          onClickOnResume={handleResumeRecording}
          onClickOnDelete={handleDeleteRecording}
          onSilenceDetected={onSilenceDetected}
          onChangeState={handleChangeSpeakingState}
        />
      ) : (
        <Box {...wrapperElementProps}>
          {isInitialization ? (
            <CircularProgress size={24} />
          ) : (
            <Box {...wrapperElementProps} onClick={handleStartRecording}>
              {isInitialization ? <CircularProgress size={24} /> : children}
            </Box>
          )}
        </Box>
      )}

      <Prompt
        message={(location, action) => {
          return isRecording && action !== 'REPLACE'
            ? 'Leave recording mode? All unsaved recorded data will be lost.'
            : true;
        }}
      />
    </>
  );
};

export default VoiceRecorder;
