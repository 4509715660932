import { useState, createContext } from 'react';

export interface ChatPageLayoutContextState {
  chatTitle?: string;
  chatId?: string;
}

export interface ChatPageLayoutContextProps extends ChatPageLayoutContextState {
  update: (props: Partial<ChatPageLayoutContextState>) => void;
}

export const ChatPageLayoutContext = createContext<ChatPageLayoutContextProps>({
  chatTitle: undefined,
  chatId: undefined,
  update: () => null,
});

export const ChatPageLayoutProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<ChatPageLayoutContextState>({
    chatTitle: undefined,
    chatId: undefined,
  });

  const handleUpdate = (props: Partial<ChatPageLayoutContextState>) => {
    setState((currentState) => ({ ...currentState, ...props }));
  };

  return (
    <ChatPageLayoutContext.Provider value={{ ...state, update: handleUpdate }}>
      {children}
    </ChatPageLayoutContext.Provider>
  );
};

export default ChatPageLayoutProvider;
