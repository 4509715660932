/**
 * A list of zero-decimal currencies. These currencies do not use decimal points
 * and therefore amounts should be provided as whole integers, without multiplying
 * by 100. For example, to charge ¥500, provide an amount value of 500 (instead of 50000).
 *
 * For more details, refer to the Stripe API documentation on handling zero-decimal currencies.
 * @see https://docs.stripe.com/currencies#zero-decimal
 */
export const ZERO_DECIMAL_CURRENCIES = [
  'BIF', // Burundian Franc
  'CLP', // Chilean Peso
  'DJF', // Djiboutian Franc
  'GNF', // Guinean Franc
  'JPY', // Japanese Yen
  'KMF', // Comorian Franc
  'KRW', // South Korean Won
  'MGA', // Malagasy Ariary
  'PYG', // Paraguayan Guarani
  'RWF', // Rwandan Franc
  'UGX', // Ugandan Shilling
  'VND', // Vietnamese Dong
  'VUV', // Vanuatu Vatu
  'XAF', // Central African CFA Franc
  'XOF', // West African CFA Franc
  'XPF', // CFP Franc
];

export default ZERO_DECIMAL_CURRENCIES;
