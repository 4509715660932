import { useParams, useHistory, Redirect } from 'react-router-dom';
// Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
// Sembly UI
import { TransitionSlide, VerifyAgentContainer } from '@sembly-ui';
// App Shared
import { getParentPathname } from '@shared/utils';
import { Routes } from '../enums';

export interface VerifyAgentModalParams {
  meetingId: string;
}

export const VerifyAgentModal: React.VFC = () => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { meetingId } = useParams<VerifyAgentModalParams>();
  /* #endregion */

  const handleClose = () => {
    history.replace(getParentPathname({ subroutesNumToCut: 2 }) || Routes.Home);
  };

  if (!meetingId) return <Redirect to={Routes.PageNotFound} />;

  return (
    <Dialog
      open
      fullScreen={isSmallScreen}
      onClose={handleClose}
      TransitionComponent={TransitionSlide}
    >
      <DialogContent className={styles.content}>
        <DialogTitle>
          <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <VerifyAgentContainer meetingId={+meetingId} />
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default VerifyAgentModal;
