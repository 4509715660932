import clsx from 'clsx';
import { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import { ActivityType } from '../types';
import { ACTIVITY_TYPES } from '../constants';

export interface KeyItemsGroupProps {
  isLoading?: boolean;
  isRTLDirected?: boolean;
  isShowInactive: boolean;
  isUpdating?: boolean;
  keyItemsType: ActivityType;
  numberOfActiveItems: number;
  title: string;
  totalNumberOfActiveItems: number;
  totalNumberOfInactiveItems: number;
  onClickOnToggleInactiveItems: (keyItemsType: ActivityType) => void;
  onClickOnClickOnShowHiddenItems: (keyItemsType: ActivityType) => void;
}

/**
 * @component KeyItemsGroup
 * @description A group of key items
 */
export const KeyItemsGroup: React.FC<KeyItemsGroupProps> = ({
  children,
  isLoading,
  isRTLDirected = false,
  isShowInactive,
  isUpdating,
  keyItemsType,
  numberOfActiveItems,
  title,
  totalNumberOfActiveItems,
  totalNumberOfInactiveItems,
  onClickOnToggleInactiveItems,
  onClickOnClickOnShowHiddenItems,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const [isExpanded, setIsExpanded] = useState(true);
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnToggleInactiveItems = () => {
    onClickOnToggleInactiveItems(keyItemsType);
  };

  const handleOnClickOnShowHiddenItems = () => {
    onClickOnClickOnShowHiddenItems(keyItemsType);
  };

  const handleToggleExpand = () => {
    setIsExpanded((expanded) => !expanded);
  };
  /* #endregion */

  const hasInactiveItems = totalNumberOfInactiveItems > 0;
  const hasHiddenActiveItems = totalNumberOfActiveItems > numberOfActiveItems;
  const totalNumberOfItems = totalNumberOfInactiveItems + totalNumberOfActiveItems;
  const card = ACTIVITY_TYPES[keyItemsType];

  return (
    <section className={styles.root} data-scroll-group-id={keyItemsType}>
      <div className={clsx(styles.head, isRTLDirected && 'rtl')}>
        <Box
          onClick={handleToggleExpand}
          display="flex"
          alignItems="center"
          style={{ cursor: 'pointer' }}
        >
          <ExpandMoreIcon
            style={{ color: card.color }}
            className={`${styles.expandIcon} ${!isExpanded ? styles.expanded : ''}`}
          />
          <Typography style={{ color: card.color }} variant="h6">
            {title}
          </Typography>
          <Typography className={styles.quantity} component="span">
            {totalNumberOfItems}
          </Typography>
        </Box>
        {hasInactiveItems && isExpanded && (
          <Button
            size="small"
            variant="outlined"
            className={styles.chipButton}
            disabled={isLoading || isUpdating}
            onClick={handleClickOnToggleInactiveItems}
          >
            {isUpdating ? (
              <CircularProgress size={16} />
            ) : (
              <span>
                {isShowInactive ? 'Hide inactive ' : 'Show inactive '}({totalNumberOfInactiveItems})
              </span>
            )}
          </Button>
        )}
      </div>
      <Collapse in={isExpanded}>
        <Grid container spacing={0}>
          <Box width="100%" pl={isSmallScreen ? 0 : 3}>
            {children}
          </Box>
        </Grid>
        {hasHiddenActiveItems && !isUpdating && (
          <Box
            mt={1}
            pl={isSmallScreen ? 0 : 3}
            style={{ direction: isRTLDirected ? 'rtl' : 'inherit' }}
          >
            {isLoading ? (
              <div className={styles.loading}>
                <CircularProgress size={24} />
              </div>
            ) : (
              <Button
                onClick={handleOnClickOnShowHiddenItems}
                className={styles.moreButton}
                size="small"
                variant="outlined"
              >
                Show more
              </Button>
            )}
          </Box>
        )}
      </Collapse>
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    transition: 'background-color 1.5s linear',
    padding: theme.spacing(1, 1.5, 0),
    margin: theme.spacing(1, -1.5, 1),
    borderRadius: '2rem',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
    '&.rtl': {
      direction: 'rtl',
    },
  },
  loading: {
    width: '100%',
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  moreButton: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5, 1.5),
  },
  chipButton: {
    height: 24,
    borderColor: theme.palette.grey['A100'],
    color: theme.palette.common.black,
  },
  quantity: {
    padding: theme.spacing(0, 1.25),
    margin: theme.spacing(0, 1),
    lineHeight: '20px',
    fontSize: 12,
    borderRadius: 10,
    backgroundColor: theme.palette.grey['A400'],
    color: theme.palette.grey['A700'],
  },
  expandIcon: {
    position: 'relative',
    left: -2,
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
}));

export default KeyItemsGroup;
