export const isValidUri = (uri: string): boolean => {
  try {
    new URL(uri);
    return true;
  } catch (e) {
    return false;
  }
};

export default isValidUri;
