import { VFC } from 'react';
import { Link } from 'react-router-dom';
import MaterialLink, { LinkProps } from '@material-ui/core/Link';

export interface StyledLinkProps extends LinkProps {
  to: string;
}

export const StyledLink: VFC<StyledLinkProps> = ({ to, ...props }) => {
  return <MaterialLink {...props} component={Link} to={to} />;
};
