import { makeStyles } from '@material-ui/core/styles';

export const Typing: React.VFC = () => {
  const styles = useStyles();

  return (
    <div className={styles.loading}>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    gap: '4px',
    marginLeft: theme.spacing(1),
  },
  dot: {
    width: '6px',
    height: '6px',
    backgroundColor: theme.palette.grey[500],
    borderRadius: '50%',
    animation: 'cycle 1s ease-in-out infinite',
    '&:nth-child(1)': {
      animationDelay: 0,
    },
    '&:nth-child(2)': {
      animationDelay: '0.3s',
    },
    '&:nth-child(3)': {
      animationDelay: '0.6s',
    },
  },
}));

export default Typing;
