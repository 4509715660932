import { createContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
// App Shared
import { Routes } from '@shared/enums';
import { ga, resolveSystemTimeZone } from '@shared/utils';
import { useAuth } from '@shared/clients/authClient';
// Graphql Queries and Types
import query from '@shared/queries/Me.graphql';
import { Me } from '@gql-types';

interface UserContextData {
  loading: boolean;
  data?: Me;
}

export const UserContext = createContext<UserContextData | undefined>(undefined);

export const UserProvider: React.FC = ({ children }) => {
  const isGuestAccess = !!useRouteMatch(Routes.GuestMeeting);
  const [isUserLoggedIn] = useAuth();

  const { data, loading } = useQuery<Me>(query, {
    skip: !isUserLoggedIn || isGuestAccess,
    fetchPolicy: 'cache-and-network',
  });

  const user = data?.me;
  const workspaceId = data?.me?.workspace?.id;
  const userTimeZone = data?.me?.timezone;
  const value: UserContextData = { data: loading ? undefined : data, loading };

  /* #region  Effects */
  // set user id and workspace id for gtag
  useEffect(() => {
    if (user && workspaceId) {
      ga.set({ userId: user.id, workspaceId });
    }
  }, [user, workspaceId]);

  // set timezone
  useEffect(() => {
    window.SemblyUserTimeZone = userTimeZone || resolveSystemTimeZone();
  }, [userTimeZone]);
  /* #endregion */

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
