import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
// Material UI
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
// Lib Shared
import ActionDueDateMenu from '../components/ActionDueDateMenu';
import ActionPerformerMenu from '../components/ActionPerformerMenu';
import KeyItemMenuDesktop from '../components/KeyItemMenuDesktop';
import KeyItemMenuMobile from '../components/KeyItemMenuMobile';
import KeyItemIntegrationsMenuContainer from '../containers/KeyItemIntegrationsMenuContainer';
import myWorkspaceUsersQuery from '../graphql/queries/MyWorkspaceUsers.graphql';
import {
  ActionItemMenuType,
  GenericAssignment,
  GenericDefaultUser,
  GraphError,
  MyWorkspaceUsers,
} from '../types';

/* #region  Types */
interface KeyItem {
  meetingId: string;
  keyItemId: string;
}

interface KeyItemWithActionItem extends KeyItem {
  item: GenericAssignment;
}

interface ContextMenu {
  anchorEl: HTMLElement;
  item: GenericAssignment;
  type: ActionItemMenuType;
}

interface SelectedParticipant {
  id: string;
  userType: 'performer' | 'assignee';
  user: string | GenericDefaultUser | null;
}

export interface ActionItemMenusProps {
  allowPinning?: boolean;
  isPerformer?: boolean;
  restrict3rdPartyIntegrations?: boolean;
  selectedMenu: ContextMenu | null;
  onChangeActivity: (data: GenericAssignment) => void;
  onChangeDueDate: (id: string, dueDate: Date | string | null) => void;
  onChangeIsPinned?: (data: GenericAssignment) => void;
  onClickOnCopyItemContent: (data: KeyItemWithActionItem) => void;
  onClickOnCopyItemLink: (data: KeyItem) => void;
  onClickOnDiscoverIntegrations?: () => void;
  onCloseContextMenu: () => void;
  onPostingSuccess?: (integrationType: string) => void;
  onResponseError?: (error: GraphError) => void;
  onSelectParticipant?: (data: SelectedParticipant) => void;
}
/* #endregion */

export const ActionItemMenusContainer: React.FC<ActionItemMenusProps> = ({
  allowPinning = false,
  children,
  isPerformer = false,
  restrict3rdPartyIntegrations = false,
  selectedMenu,
  onChangeActivity,
  onChangeDueDate,
  onChangeIsPinned = () => null,
  onClickOnCopyItemContent,
  onClickOnCopyItemLink,
  onClickOnDiscoverIntegrations = () => null,
  onCloseContextMenu,
  onPostingSuccess = () => null,
  onResponseError = () => null,
  onSelectParticipant = () => null,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const actionItem = selectedMenu?.item;
  const canManageActionItem = actionItem?.meeting.permissions.canManage ?? false;
  const isAuthorizedToEdit = canManageActionItem || isPerformer;

  const { data: workspaceUsersData, loading: isLoadingWorkspaceUsers } = useQuery<MyWorkspaceUsers>(
    myWorkspaceUsersQuery,
    { skip: !isAuthorizedToEdit, fetchPolicy: 'cache-first' },
  );

  /* #region  Handlers */
  const handleClickOnTogglePin = (item: GenericAssignment) => () => {
    onChangeIsPinned(item);
  };

  const handleOnClickOnCopyContent = (data: NonNullable<ContextMenu>) => () => {
    const meetingId = data.item.meeting.id;
    const keyItemId = data.item.id;
    const item = data.item;
    onClickOnCopyItemContent({ meetingId, keyItemId, item });
  };

  const handleOnClickOnCopyLink = (data: NonNullable<ContextMenu>) => () => {
    const meetingId = data.item.meeting.id;
    const keyItemId = data.item.id;
    onClickOnCopyItemLink({ meetingId, keyItemId });
  };

  const handleClickOnToggleActivity = (data: NonNullable<ContextMenu>) => async () => {
    onCloseContextMenu();
    onChangeActivity(data.item);
  };

  const handleSelectParticipant =
    (userType: 'performer' | 'assignee') =>
    (id: string, performer: string | GenericDefaultUser | null) => {
      onCloseContextMenu();
      onSelectParticipant({ id, userType, user: performer });
    };

  const handleCloseMenu = () => {
    // force close the menu when the user leaves the menu area
    if (selectedMenu?.type === 'desktopActionsMenu') {
      onCloseContextMenu();
    }
  };
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    onCloseContextMenu();
    // there is no need to track changes on onCloseContextMenu
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen]);
  /* #endregion */

  /* #region  Render Helpers */
  const meetingAttendees = workspaceUsersData?.myWorkspace?.users ?? [];
  /* #endregion */

  return (
    <div onMouseLeave={handleCloseMenu}>
      {/* Begin: Menus */}
      {!!selectedMenu && !!actionItem && (
        <>
          {selectedMenu.type === 'desktopActionsMenu' && (
            <KeyItemMenuDesktop
              allowPinning={allowPinning}
              anchorEl={selectedMenu.anchorEl}
              isActiveKeyItem={selectedMenu.item.isActive}
              isAuthorizedToEdit={isAuthorizedToEdit}
              isPinnedKeyItem={selectedMenu.item.isPinned}
              hasTranscriptionItem={false}
              onClickOnCopyContent={handleOnClickOnCopyContent(selectedMenu)}
              onClickOnCopyLink={handleOnClickOnCopyLink(selectedMenu)}
              onClickOnToggleActivity={handleClickOnToggleActivity(selectedMenu)}
              onClickOnPin={handleClickOnTogglePin(selectedMenu.item)}
              onClose={onCloseContextMenu}
            />
          )}
          {selectedMenu.type === 'mobileActionsMenu' && (
            <KeyItemMenuMobile
              allowPinning={allowPinning}
              anchorEl={selectedMenu.anchorEl}
              isActiveKeyItem={selectedMenu.item.isActive}
              isAuthorizedToEdit={isAuthorizedToEdit}
              isPinnedKeyItem={selectedMenu.item.isPinned}
              hasTranscriptionItem={false}
              onClickOnCopyContent={handleOnClickOnCopyContent(selectedMenu)}
              onClickOnCopyLink={handleOnClickOnCopyLink(selectedMenu)}
              onClickOnPin={handleClickOnTogglePin(selectedMenu.item)}
              onClickOnToggleActivity={handleClickOnToggleActivity(selectedMenu)}
              onClose={onCloseContextMenu}
            />
          )}
          {selectedMenu.type === 'integrationsMenu' && (
            <KeyItemIntegrationsMenuContainer
              anchorEl={selectedMenu.anchorEl}
              keyItemId={selectedMenu.item.id}
              restrict3rdPartyIntegrations={restrict3rdPartyIntegrations}
              onClickOnDiscoverIntegrations={onClickOnDiscoverIntegrations}
              onClose={onCloseContextMenu}
              onError={onResponseError}
              onSuccess={onPostingSuccess}
            />
          )}
          {selectedMenu.type === 'dueDateMenu' && (
            <ActionDueDateMenu
              actionId={selectedMenu.item.id}
              anchorEl={selectedMenu.anchorEl}
              value={selectedMenu.item.dueDate}
              onChange={onChangeDueDate}
              onClose={onCloseContextMenu}
            />
          )}
          {selectedMenu.type === 'performerMenu' && (
            <ActionPerformerMenu
              actionId={actionItem.id}
              anchorEl={selectedMenu.anchorEl}
              attendees={meetingAttendees}
              currentPerformer={actionItem.performer || actionItem.rawPerformerName}
              isLoading={isLoadingWorkspaceUsers}
              onChange={handleSelectParticipant('performer')}
              onClose={onCloseContextMenu}
            />
          )}
          {selectedMenu.type === 'assigneeMenu' && (
            <ActionPerformerMenu
              actionId={actionItem.id}
              anchorEl={selectedMenu.anchorEl}
              attendees={meetingAttendees}
              currentPerformer={actionItem.assignedBy || actionItem.rawAssignedBy}
              isLoading={isLoadingWorkspaceUsers}
              onChange={handleSelectParticipant('assignee')}
              onClose={onCloseContextMenu}
            />
          )}
        </>
      )}
      {/* End: Menus */}
      {children}
    </div>
  );
};

export default ActionItemMenusContainer;
