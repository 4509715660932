import { toast, ToastOptions } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { extractValidationErrors, extractExecutionErrors } from '@shared/utils';
import { logout } from '@shared/clients/authClient';
import { ValidationErrors, ExecutionErrors, ExecutionErrorCodes } from '@gql-types';

const UNKNOWN_ERR_MSG = 'An error has occurred while processing your request.';

const genericFieldTypes: { [key: string]: string } = {
  __all__: 'Validation Error',
};

const extractMessage = (messages: (string | null)[] | null): string => {
  if (messages === null) return UNKNOWN_ERR_MSG;
  return messages.join(' ');
};

export type SemblyGQLErrors =
  | (ValidationErrors | ExecutionErrors | null)[]
  | string
  | null
  | undefined;

export const graphErrorHorsemen = (
  errors: SemblyGQLErrors,
  options?: ToastOptions,
): string | null => {
  if (errors === null || errors === undefined) return null;

  if (typeof errors === 'string') {
    toast.error(
      <div>
        <Typography variant="body2">{errors}</Typography>
      </div>,
    );
    return errors;
  }

  const validationErrors = extractValidationErrors(errors);
  const executionErrors = extractExecutionErrors(errors);

  if (!validationErrors.length && !executionErrors.length) {
    toast.error(
      <div>
        <Typography variant="body2">{UNKNOWN_ERR_MSG}</Typography>
      </div>,
      options,
    );
    return UNKNOWN_ERR_MSG;
  }

  const isExpiredAuth = errors.some((error) => {
    if (error?.__typename !== 'ExecutionErrorType') return false;

    const targetErrorCodes = [
      ExecutionErrorCodes.UNAUTHENTICATED,
      ExecutionErrorCodes.TOKEN_ERROR,
      ExecutionErrorCodes.TOKEN_EXPIRED,
    ];

    return targetErrorCodes.includes(error.extensions.code);
  });

  if (isExpiredAuth) {
    logout();
    const errorMsg = 'Session expired, please log in again.';
    toast.warn(errorMsg, options);
    return errorMsg;
  }

  toast.error(
    <div>
      {validationErrors.map((err, key) =>
        err ? (
          <Typography key={`${err.field}-${key}`} variant="body2">
            {!!err.field && <span>{genericFieldTypes[err.field] || err.field}: </span>}
            {!!err.messages && <span>{extractMessage(err.messages)}</span>}
          </Typography>
        ) : (
          <Typography variant="body2">{UNKNOWN_ERR_MSG}</Typography>
        ),
      )}
      {executionErrors.map((err, key) =>
        err ? (
          <Typography key={key} variant="body2">
            {!!err.messages && <span>{extractMessage(err.messages)}</span>}
          </Typography>
        ) : (
          <Typography variant="body2">{UNKNOWN_ERR_MSG}</Typography>
        ),
      )}
    </div>,
    options,
  );

  const validationError = !!validationErrors[0]
    ? `${genericFieldTypes[validationErrors[0].field]}: ${
        !validationErrors[0].messages ? '' : extractMessage(validationErrors[0].messages)
      }`
    : null;

  const executionError = !!executionErrors[0]
    ? `${!executionErrors[0].messages ? '' : extractMessage(executionErrors[0].messages)}`
    : null;

  return validationError || executionError || UNKNOWN_ERR_MSG;
};

export default graphErrorHorsemen;
