import { Languages, MeetingProcessingResultsMainLanguage } from '../../types';

/**
 * @function isAsianLang
 * @description Returns true if the language is Asian
 */
export function isAsianLang(
  lang: Languages | MeetingProcessingResultsMainLanguage | string | null | undefined,
): boolean {
  if (!lang) return false;
  return [
    // Languages
    Languages.ARABIC,
    Languages.CANTONESE,
    Languages.CHINESE,
    Languages.HEBREW,
    Languages.HINDI,
    Languages.JAPANESE,
    Languages.KOREAN,
    Languages.TAMIL,
    Languages.THAI,
    Languages.PERSIAN,
    Languages.URDU,
    // MeetingProcessingResultsMainLanguage
    MeetingProcessingResultsMainLanguage.ARABIC,
    MeetingProcessingResultsMainLanguage.CANTONESE,
    MeetingProcessingResultsMainLanguage.CHINESE,
    MeetingProcessingResultsMainLanguage.HEBREW,
    MeetingProcessingResultsMainLanguage.HINDI,
    MeetingProcessingResultsMainLanguage.JAPANESE,
    MeetingProcessingResultsMainLanguage.KOREAN,
    MeetingProcessingResultsMainLanguage.TAMIL,
    MeetingProcessingResultsMainLanguage.THAI,
    MeetingProcessingResultsMainLanguage.PERSIAN,
    MeetingProcessingResultsMainLanguage.URDU
  ].includes(lang as Languages | MeetingProcessingResultsMainLanguage);
}

export default isAsianLang;
