import { AgentCallPlatform as Platform } from '../types';
// Lib Assets
import smartMikeImage from '../assets/icon-platform-smartmike.png';
import smartMeetingImage from '../assets/icon-platform-smartmeeting.svg';
// Lib Icons
import GenericMeetingIcon from '../icons/MeetingColored';
import GoogleMeetIcon from '../icons/GoogleMeet';
import ManualRecordingIcon from '../icons/ManualRecording';
import MicrosoftTeamsIcon from '../icons/MicrosoftTeams';
import UploadRecordingIcon from '../icons/UploadRecording';
import WebexIcon from '../icons/Webex';
import ZoomIcon from '../icons/Zoom';

export interface AgentCallPlatformEntity {
  title: string;
  icon: React.ReactElement;
}

export const AGENT_CALL_PLATFORMS: Record<Platform, AgentCallPlatformEntity> = {
  [Platform.COMMON]: {
    title: 'Common',
    icon: <GenericMeetingIcon />,
  },
  [Platform.GOOGLE_MEET]: {
    title: 'Google Meet',
    icon: <GoogleMeetIcon />,
  },
  [Platform.MS_TEAMS]: {
    title: 'Microsoft Teams',
    icon: <MicrosoftTeamsIcon />,
  },
  [Platform.WEBEX]: {
    title: 'Webex',
    icon: <WebexIcon />,
  },
  [Platform.ZOOM]: {
    title: 'Zoom',
    icon: <ZoomIcon />,
  },
  [Platform.MANUAL_RECORD]: {
    title: 'Audio Record',
    icon: <ManualRecordingIcon />,
  },
  [Platform.MANUAL_UPLOAD]: {
    title: 'Audio Upload',
    icon: <UploadRecordingIcon />,
  },
  [Platform.SMARTMIKE_RECORD]: {
    title: 'SmartMike Record',
    icon: <img width="24" height="24" alt="SmartMike record" src={smartMikeImage} />,
  },
  [Platform.SMARTMEETING_RECORD]: {
    title: 'SmartMeeting Record',
    icon: <img width="24" height="24" alt="SmartMeeting record" src={smartMeetingImage} />,
  },
} as const;

export default AGENT_CALL_PLATFORMS;
