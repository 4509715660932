// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import { GenericDialog } from '../components';

export interface ManageThirdPartyAutomationDataTypeDialogProps {
  open: boolean;
  value: string | null;
  loading?: boolean;
  items: { label: string; value: string }[];
  onChange: (value: string) => void;
  onClose: () => void;
}

/**
 * Dialog that displays all available data types that can be used to create an automation.
 */
export const ManageThirdPartyAutomationDataTypeDialog: React.VFC<
  ManageThirdPartyAutomationDataTypeDialogProps
> = ({ open, value, items, loading = false, onChange, onClose }) => {
  const styles = useStyles();

  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    onChange(e.target.value as string);
  };

  return (
    <GenericDialog
      title="Select a data type"
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm' }}
      onClose={onClose}
    >
      <DialogContent>
        <Box mb={2}>
          <TextField
            select
            fullWidth
            size="small"
            variant="outlined"
            label="Select a data type"
            value={value}
            onChange={handleOnChange}
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="end">
                  <Box display="flex" alignItems="center" mr={3}>
                    <CircularProgress size={20} />
                  </Box>
                </InputAdornment>
              ),
            }}
          >
            {items.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          disabled={!value}
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2, 3, 3),
  },
}));

export default ManageThirdPartyAutomationDataTypeDialog;
