/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ActionCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum ActionFieldTypes {
  ACTION_LINK = "ACTION_LINK",
  ASSIGNED_BY = "ASSIGNED_BY",
  ASSIGNED_TO = "ASSIGNED_TO",
  AUTOMATION_OWNER_EMAIL = "AUTOMATION_OWNER_EMAIL",
  DUE_DATE = "DUE_DATE",
  HEADER_TEXT = "HEADER_TEXT",
  ID = "ID",
  IN_DISCUSSION_WITH = "IN_DISCUSSION_WITH",
  MEETING_FINISHED_AT = "MEETING_FINISHED_AT",
  MEETING_ID = "MEETING_ID",
  MEETING_LINK = "MEETING_LINK",
  MEETING_OWNER_EMAIL = "MEETING_OWNER_EMAIL",
  MEETING_STARTED_AT = "MEETING_STARTED_AT",
  MEETING_TEAM_ID = "MEETING_TEAM_ID",
  MEETING_TEAM_NAME = "MEETING_TEAM_NAME",
  MEETING_TITLE = "MEETING_TITLE",
  MEETING_TYPE = "MEETING_TYPE",
  TEXT = "TEXT",
  TYPE = "TYPE",
  WORKSPACE_ID = "WORKSPACE_ID",
}

/**
 * An enumeration.
 */
export enum ActionItemType {
  ACTIVITY = "ACTIVITY",
  PROJECT = "PROJECT",
  TASK = "TASK",
  TODO = "TODO",
}

/**
 * An enumeration.
 */
export enum ActionsCustomIntegrationSettingsContentTypes {
  ACTIONS = "ACTIONS",
  COMMITMENTS = "COMMITMENTS",
}

/**
 * An enumeration.
 */
export enum AgentCallPlatform {
  COMMON = "COMMON",
  GOOGLE_MEET = "GOOGLE_MEET",
  MANUAL_RECORD = "MANUAL_RECORD",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  MS_TEAMS = "MS_TEAMS",
  SMARTMEETING_RECORD = "SMARTMEETING_RECORD",
  SMARTMIKE_RECORD = "SMARTMIKE_RECORD",
  WEBEX = "WEBEX",
  ZOOM = "ZOOM",
}

/**
 * An enumeration.
 */
export enum ApplyToMeetings {
  ALL_MEETINGS = "ALL_MEETINGS",
  SINGLE = "SINGLE",
}

/**
 * An enumeration.
 */
export enum AssignmentsAccess {
  NONE = "NONE",
  TASKS = "TASKS",
  TODOS = "TODOS",
}

/**
 * An enumeration.
 */
export enum AutomationsDelays {
  ONE_HOUR = "ONE_HOUR",
  SIX_HOURS = "SIX_HOURS",
  THREE_HOURS = "THREE_HOURS",
  TWELVE_HOURS = "TWELVE_HOURS",
  TWENTY_FOUR_HOURS = "TWENTY_FOUR_HOURS",
}

/**
 * An enumeration.
 */
export enum ChatAnswerFileTypes {
  IMAGE = "IMAGE",
  PDF = "PDF",
  TEXT = "TEXT",
}

/**
 * An enumeration.
 */
export enum CustomIntegrationsTypes {
  ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION",
  ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION",
  TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
}

/**
 * An enumeration.
 */
export enum CustomOutboundUserIntegrationRules {
  ALL_MEETINGS = "ALL_MEETINGS",
  FILTERED_BY_KEYWORDS = "FILTERED_BY_KEYWORDS",
  FILTERED_BY_MEETING_TYPE = "FILTERED_BY_MEETING_TYPE",
  MANUAL_ONLY = "MANUAL_ONLY",
}

/**
 * An enumeration.
 */
export enum CustomOutboundWorkspaceIntegrationRules {
  ALL_MEETINGS = "ALL_MEETINGS",
  FILTERED_BY_KEYWORDS = "FILTERED_BY_KEYWORDS",
  FILTERED_BY_MEETING_TYPE = "FILTERED_BY_MEETING_TYPE",
  FILTERED_BY_OWNER = "FILTERED_BY_OWNER",
  FILTERED_BY_TEAM = "FILTERED_BY_TEAM",
}

/**
 * An enumeration.
 */
export enum DecisionCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

export enum ExecutionErrorCodes {
  EXTERNAL_SERVICE_ERROR = "EXTERNAL_SERVICE_ERROR",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  OBJECT_DOES_NOT_EXIST = "OBJECT_DOES_NOT_EXIST",
  PERMISSION_DENIED = "PERMISSION_DENIED",
  PROCESSING_ERROR = "PROCESSING_ERROR",
  SCHEMA_ERROR = "SCHEMA_ERROR",
  TOKEN_ERROR = "TOKEN_ERROR",
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  USED_FREE_REQUESTS = "USED_FREE_REQUESTS",
}

/**
 * An enumeration.
 */
export enum ExecutionErrorTypes {
  BAD_GATEWAY_502 = "BAD_GATEWAY_502",
  BAD_REQUEST_400 = "BAD_REQUEST_400",
  FORBIDDEN_403 = "FORBIDDEN_403",
  GOOGLE_CALENDAR_FULL_SYNC_REQUIRED = "GOOGLE_CALENDAR_FULL_SYNC_REQUIRED",
  GOOGLE_CALENDAR_INVALID_PUSH_HEADERS = "GOOGLE_CALENDAR_INVALID_PUSH_HEADERS",
  GOOGLE_CALENDAR_NOT_ALLOWED = "GOOGLE_CALENDAR_NOT_ALLOWED",
  GOOGLE_CALENDAR_NO_DATA_RECIEVED = "GOOGLE_CALENDAR_NO_DATA_RECIEVED",
  GOOGLE_CALENDAR_SYNC_ERROR = "GOOGLE_CALENDAR_SYNC_ERROR",
  INTERNAL_SERVER_ERROR_500 = "INTERNAL_SERVER_ERROR_500",
  INVALID_ACCESS_TOKEN = "INVALID_ACCESS_TOKEN",
  MICROSOFT_CALENDAR_LIMITED_REQUESTS = "MICROSOFT_CALENDAR_LIMITED_REQUESTS",
  MICROSOFT_CALENDAR_NO_DATA_RECIEVED = "MICROSOFT_CALENDAR_NO_DATA_RECIEVED",
  MICROSOFT_CALENDAR_SUBSCRIBE = "MICROSOFT_CALENDAR_SUBSCRIBE",
  MICROSOFT_CALENDAR_SYNC_ERROR = "MICROSOFT_CALENDAR_SYNC_ERROR",
  MICROSOFT_CALENDAR_SYNC_STATE_NOT_FOUND = "MICROSOFT_CALENDAR_SYNC_STATE_NOT_FOUND",
  NOT_FOUND_404 = "NOT_FOUND_404",
  UNAUTHORIZED_401 = "UNAUTHORIZED_401",
  UNSUPPORTED_MEDIA_TYPE_415 = "UNSUPPORTED_MEDIA_TYPE_415",
}

/**
 * An enumeration.
 */
export enum IntegrationConnectRules {
  ALL_MEETINGS = "ALL_MEETINGS",
  FILTERED_BY_KEYWORDS = "FILTERED_BY_KEYWORDS",
  FILTERED_BY_MEETING_TYPE = "FILTERED_BY_MEETING_TYPE",
  MANUAL_ONLY = "MANUAL_ONLY",
}

/**
 * An enumeration.
 */
export enum IntegrationTypes {
  ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_USER_INTEGRATION",
  ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  GOOGLE_CALENDAR_USER = "GOOGLE_CALENDAR_USER",
  GOOGLE_TASKS = "GOOGLE_TASKS",
  ICS_CALENDAR_USER_INTEGRATION = "ICS_CALENDAR_USER_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION",
  MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  MS_CALENDAR_USER = "MS_CALENDAR_USER",
  MS_SEARCH_CONNECTOR_WORKSPACE = "MS_SEARCH_CONNECTOR_WORKSPACE",
  MS_TEAMS_LITE_ADAPTER_WORKSPACE = "MS_TEAMS_LITE_ADAPTER_WORKSPACE",
  MS_TEAMS_TEC_ADAPTER_USER = "MS_TEAMS_TEC_ADAPTER_USER",
  MS_TEAMS_TEC_ADAPTER_WORKSPACE = "MS_TEAMS_TEC_ADAPTER_WORKSPACE",
  MS_TODO = "MS_TODO",
  NOTION_ASSIGNMENTS = "NOTION_ASSIGNMENTS",
  NOTION_MEETING_NOTES = "NOTION_MEETING_NOTES",
  SLACK_USER = "SLACK_USER",
  TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION",
  TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION = "TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION",
  ZOOM_USER = "ZOOM_USER",
}

/**
 * An enumeration.
 */
export enum IssueCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum KeyEventCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum Languages {
  AFRIKAANS = "AFRIKAANS",
  ARABIC = "ARABIC",
  BOSNIAN = "BOSNIAN",
  BULGARIAN = "BULGARIAN",
  CANTONESE = "CANTONESE",
  CATALAN = "CATALAN",
  CHINESE = "CHINESE",
  CROATIAN = "CROATIAN",
  CZECH = "CZECH",
  DANISH = "DANISH",
  DUTCH = "DUTCH",
  ENGLISH = "ENGLISH",
  ENGLISH_AUSTRALIAN = "ENGLISH_AUSTRALIAN",
  ENGLISH_BRITISH = "ENGLISH_BRITISH",
  ENGLISH_FINANCE = "ENGLISH_FINANCE",
  ENGLISH_INDIAN = "ENGLISH_INDIAN",
  FINNISH = "FINNISH",
  FRENCH = "FRENCH",
  GALICIAN = "GALICIAN",
  GERMAN = "GERMAN",
  GREEK = "GREEK",
  HEBREW = "HEBREW",
  HINDI = "HINDI",
  HUNGARIAN = "HUNGARIAN",
  INDONESIAN = "INDONESIAN",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  MACEDONIAN = "MACEDONIAN",
  MALAY = "MALAY",
  NORWEGIAN = "NORWEGIAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE_BRAZIL = "PORTUGUESE_BRAZIL",
  PORTUGUESE_PORTUGAL = "PORTUGUESE_PORTUGAL",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  SLOVAK = "SLOVAK",
  SPANISH_AMERICAN = "SPANISH_AMERICAN",
  SPANISH_SPAIN = "SPANISH_SPAIN",
  SWEDISH = "SWEDISH",
  TAGALOG = "TAGALOG",
  TAMIL = "TAMIL",
  THAI = "THAI",
  TURKISH = "TURKISH",
  UKRAINIAN = "UKRAINIAN",
  URDU = "URDU",
  VIETNAMESE = "VIETNAMESE",
}

/**
 * An enumeration.
 */
export enum MeetingNotesFieldTypes {
  AUTOMATION_OWNER_EMAIL = "AUTOMATION_OWNER_EMAIL",
  MEETING_DURATION = "MEETING_DURATION",
  MEETING_FINISHED_AT = "MEETING_FINISHED_AT",
  MEETING_ID = "MEETING_ID",
  MEETING_LINK = "MEETING_LINK",
  MEETING_NOTES = "MEETING_NOTES",
  MEETING_OWNER_EMAIL = "MEETING_OWNER_EMAIL",
  MEETING_STARTED_AT = "MEETING_STARTED_AT",
  MEETING_TEAM_ID = "MEETING_TEAM_ID",
  MEETING_TEAM_NAME = "MEETING_TEAM_NAME",
  MEETING_TITLE = "MEETING_TITLE",
  MEETING_TYPE = "MEETING_TYPE",
  PARTICIPANTS = "PARTICIPANTS",
  WORKSPACE_ID = "WORKSPACE_ID",
}

/**
 * An enumeration.
 */
export enum MeetingProcessingResultsMainLanguage {
  AFRIKAANS = "AFRIKAANS",
  ARABIC = "ARABIC",
  BOSNIAN = "BOSNIAN",
  BULGARIAN = "BULGARIAN",
  CANTONESE = "CANTONESE",
  CATALAN = "CATALAN",
  CHINESE = "CHINESE",
  CROATIAN = "CROATIAN",
  CZECH = "CZECH",
  DANISH = "DANISH",
  DUTCH = "DUTCH",
  ENGLISH = "ENGLISH",
  ENGLISH_AUSTRALIAN = "ENGLISH_AUSTRALIAN",
  ENGLISH_BRITISH = "ENGLISH_BRITISH",
  ENGLISH_FINANCE = "ENGLISH_FINANCE",
  ENGLISH_INDIAN = "ENGLISH_INDIAN",
  FINNISH = "FINNISH",
  FRENCH = "FRENCH",
  GALICIAN = "GALICIAN",
  GERMAN = "GERMAN",
  GREEK = "GREEK",
  HEBREW = "HEBREW",
  HINDI = "HINDI",
  HUNGARIAN = "HUNGARIAN",
  INDONESIAN = "INDONESIAN",
  ITALIAN = "ITALIAN",
  JAPANESE = "JAPANESE",
  KOREAN = "KOREAN",
  MACEDONIAN = "MACEDONIAN",
  MALAY = "MALAY",
  NORWEGIAN = "NORWEGIAN",
  PERSIAN = "PERSIAN",
  POLISH = "POLISH",
  PORTUGUESE_BRAZIL = "PORTUGUESE_BRAZIL",
  PORTUGUESE_PORTUGAL = "PORTUGUESE_PORTUGAL",
  ROMANIAN = "ROMANIAN",
  RUSSIAN = "RUSSIAN",
  SLOVAK = "SLOVAK",
  SPANISH_AMERICAN = "SPANISH_AMERICAN",
  SPANISH_SPAIN = "SPANISH_SPAIN",
  SWEDISH = "SWEDISH",
  TAGALOG = "TAGALOG",
  TAMIL = "TAMIL",
  THAI = "THAI",
  TURKISH = "TURKISH",
  UKRAINIAN = "UKRAINIAN",
  URDU = "URDU",
  VIETNAMESE = "VIETNAMESE",
}

/**
 * An enumeration.
 */
export enum MeetingStatuses {
  failed = "failed",
  not_scheduled = "not_scheduled",
  on_call = "on_call",
  on_pause = "on_pause",
  processing = "processing",
  scheduled = "scheduled",
  submitted = "submitted",
}

/**
 * An enumeration.
 */
export enum MeetingTypes {
  AGREEMENT_DRAFT_OR_REVIEW = "AGREEMENT_DRAFT_OR_REVIEW",
  AGREEMENT_REVISE_OR_EXTEND = "AGREEMENT_REVISE_OR_EXTEND",
  BOARD_MEETING = "BOARD_MEETING",
  BUDGET_OR_FINANCIAL_PLANNING = "BUDGET_OR_FINANCIAL_PLANNING",
  BUDGET_OR_FINANCIAL_REVIEW = "BUDGET_OR_FINANCIAL_REVIEW",
  CHANGE_MANAGEMENT_PLANNING = "CHANGE_MANAGEMENT_PLANNING",
  CHANGE_PLANNING = "CHANGE_PLANNING",
  COMPLIANCE_ACTIVITIES = "COMPLIANCE_ACTIVITIES",
  CUSTOMER_TOUCHPOINT = "CUSTOMER_TOUCHPOINT",
  DAILY_STANDUP_OR_STATUS = "DAILY_STANDUP_OR_STATUS",
  DEMO = "DEMO",
  DISCOVERY = "DISCOVERY",
  EMERGENCY_OR_CRISIS = "EMERGENCY_OR_CRISIS",
  FEEDBACK_SESSION = "FEEDBACK_SESSION",
  GENERIC = "GENERIC",
  IMPLEMENTATION_PLANNING = "IMPLEMENTATION_PLANNING",
  INTERVIEW_OR_RECRUITING = "INTERVIEW_OR_RECRUITING",
  ISSUE_TROUBLESHOOTING = "ISSUE_TROUBLESHOOTING",
  LECTURE = "LECTURE",
  OFFBOARDING_OR_EXIT_INTERVIEW = "OFFBOARDING_OR_EXIT_INTERVIEW",
  ONBOARDING = "ONBOARDING",
  ONE_ON_ONE = "ONE_ON_ONE",
  PANELS_OR_DISCUSSION_FORUMS = "PANELS_OR_DISCUSSION_FORUMS",
  PERFORMANCE_REVIEW = "PERFORMANCE_REVIEW",
  PROJECT_PORTFOLIO_PLANNING = "PROJECT_PORTFOLIO_PLANNING",
  PROJECT_PORTFOLIO_REVIEW = "PROJECT_PORTFOLIO_REVIEW",
  Q_AND_A_SESSION = "Q_AND_A_SESSION",
  REQUIREMENTS_GATHERING = "REQUIREMENTS_GATHERING",
  RETROSPECTIVE = "RETROSPECTIVE",
  ROADMAP_DEVELOPMENT = "ROADMAP_DEVELOPMENT",
  SECURITY_REVIEW = "SECURITY_REVIEW",
  SOLUTION_PLANNING = "SOLUTION_PLANNING",
  SPRINT_OR_RELEASE_REVIEW = "SPRINT_OR_RELEASE_REVIEW",
  STRATEGY_DEVELOPMENT = "STRATEGY_DEVELOPMENT",
  STRATEGY_OR_BRAINSTORMING = "STRATEGY_OR_BRAINSTORMING",
  TOWNHALL_OR_ALL_HANDS = "TOWNHALL_OR_ALL_HANDS",
  TRAINING_OR_WORKSHOPS = "TRAINING_OR_WORKSHOPS",
  WEBINARS = "WEBINARS",
  WEEKLY_STATUS_OR_UPDATE = "WEEKLY_STATUS_OR_UPDATE",
  WORKSTREAM_OR_PROJECT_KICKOFF = "WORKSTREAM_OR_PROJECT_KICKOFF",
}

/**
 * An enumeration.
 */
export enum NoteworthyDetailCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum NotionIntegrationsTypes {
  NOTION_ASSIGNMENTS_INTEGRATION = "NOTION_ASSIGNMENTS_INTEGRATION",
  NOTION_MEETING_NOTES_INTEGRATION = "NOTION_MEETING_NOTES_INTEGRATION",
}

/**
 * An enumeration.
 */
export enum OutputFormatEnum {
  DOCX = "DOCX",
  HTML = "HTML",
  MARKDOWN = "MARKDOWN",
  PDF = "PDF",
}

/**
 * An enumeration.
 */
export enum ParkingLotCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum PlanIDEnum {
  ENTERPRISE = "ENTERPRISE",
  PERSONAL = "PERSONAL",
  PROFESSIONAL = "PROFESSIONAL",
  TEAM = "TEAM",
  TEAM_PLUS = "TEAM_PLUS",
}

/**
 * An enumeration.
 */
export enum QAndACategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum RequestCategory {
  GENERATIVE = "GENERATIVE",
  INFORMATIONAL = "INFORMATIONAL",
}

/**
 * An enumeration.
 */
export enum RequirementCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum RiskCategory {
  KEYWORD_TRIGGERED = "KEYWORD_TRIGGERED",
  MANUAL = "MANUAL",
  RECOGNIZED = "RECOGNIZED",
  SUGGESTED = "SUGGESTED",
}

/**
 * An enumeration.
 */
export enum SlackDestinations {
  PRIVATE_CHANNEL = "PRIVATE_CHANNEL",
  PUBLIC_CHANNEL = "PUBLIC_CHANNEL",
}

/**
 * An enumeration.
 */
export enum TranscriptionFieldTypes {
  AUTOMATION_OWNER_EMAIL = "AUTOMATION_OWNER_EMAIL",
  MEETING_DURATION = "MEETING_DURATION",
  MEETING_FINISHED_AT = "MEETING_FINISHED_AT",
  MEETING_ID = "MEETING_ID",
  MEETING_LINK = "MEETING_LINK",
  MEETING_OWNER_EMAIL = "MEETING_OWNER_EMAIL",
  MEETING_STARTED_AT = "MEETING_STARTED_AT",
  MEETING_TEAM_ID = "MEETING_TEAM_ID",
  MEETING_TEAM_NAME = "MEETING_TEAM_NAME",
  MEETING_TITLE = "MEETING_TITLE",
  MEETING_TRANSCRIPTION = "MEETING_TRANSCRIPTION",
  MEETING_TYPE = "MEETING_TYPE",
  PARTICIPANTS = "PARTICIPANTS",
  WORKSPACE_ID = "WORKSPACE_ID",
}

/**
 * An enumeration.
 */
export enum UserRole {
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  POWOW_ADMIN = "POWOW_ADMIN",
  REGULAR_USER = "REGULAR_USER",
}

export interface ActionsCustomOutboundIntegrationFieldsInput {
  name: string;
  fieldName: ActionFieldTypes;
}

export interface AddKeyItemsIntegrationRuleSettingInput {
  destinationListId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  contentTypes: ActionsCustomIntegrationSettingsContentTypes[];
  keywords: (string | null)[];
  isActive: boolean;
}

export interface AddKeyItemsIntegrationRuleSettingsMutationInput {
  integrationType: IntegrationTypes;
  settings: (AddKeyItemsIntegrationRuleSettingInput | null)[];
  clientMutationId?: string | null;
}

export interface AddNotionAssignmentsRuleSettingInput {
  destinationDatabaseId: string;
  destinationPageId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  contentTypes: ActionsCustomIntegrationSettingsContentTypes[];
  keywords: (string | null)[];
  isActive: boolean;
}

export interface AddNotionMeetingNotesRuleSettingInput {
  destinationDatabaseId: string;
  destinationPageId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  keywords: (string | null)[];
  isActive: boolean;
}

export interface AddSlackMeetingNotesRuleSettingInput {
  destinationSlackId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  keywords: (string | null)[];
  isActive: boolean;
}

export interface CreateTodoTaskFromKeyItemMutationInput {
  integrationType: IntegrationTypes;
  id: string;
  contentType: ActionsCustomIntegrationSettingsContentTypes;
  destinationListId: string;
  clientMutationId?: string | null;
}

export interface DeleteKeyItemsIntegrationRuleSettingsMutationInput {
  integrationType: IntegrationTypes;
  ids: (string | null)[];
  clientMutationId?: string | null;
}

export interface DiarizationItemSplitType {
  participant: number;
  text: string;
}

export interface EditKeyItemsIntegrationRuleSettingInput {
  destinationListId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  contentTypes: ActionsCustomIntegrationSettingsContentTypes[];
  keywords: (string | null)[];
  isActive: boolean;
  id: string;
}

export interface EditKeyItemsIntegrationRuleSettingsMutationInput {
  integrationType: IntegrationTypes;
  settings: (EditKeyItemsIntegrationRuleSettingInput | null)[];
  clientMutationId?: string | null;
}

export interface EditNotionAssignmentsRuleSettingInput {
  destinationDatabaseId: string;
  destinationPageId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  contentTypes: ActionsCustomIntegrationSettingsContentTypes[];
  keywords: (string | null)[];
  isActive: boolean;
  id: string;
}

export interface EditNotionMeetingNotesRuleSettingInput {
  destinationDatabaseId: string;
  destinationPageId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  keywords: (string | null)[];
  isActive: boolean;
  id: string;
}

export interface EditSlackMeetingNotesRuleSettingInput {
  destinationSlackId: string;
  description: string;
  rule: IntegrationConnectRules;
  meetingType?: MeetingTypes | null;
  keywords: (string | null)[];
  isActive: boolean;
  id: string;
}

export interface MeetingAccessUserInput {
  email: string;
  canManage: boolean;
}

export interface MeetingNotesCustomOutboundIntegrationFieldsInput {
  name: string;
  fieldName: MeetingNotesFieldTypes;
}

export interface TranscriptionCustomOutboundIntegrationFieldsInput {
  name: string;
  fieldName: TranscriptionFieldTypes;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
