export const formatRecordQuality = (wer: number | null) => {
  if (wer === null || wer === undefined) {
    return <span style={{ color: '#808080' }}>Recording quality is not available</span>;
  }

  if (wer > 0.4) return <span style={{ color: '#FF6A00' }}>Poor</span>;
  if (wer < 0.2) return <span style={{ color: '#00CCCC' }}>Good</span>;

  return <span style={{ color: '#FFBF00' }}>Medium</span>;
};

export default formatRecordQuality;
