import { useRouteMatch } from 'react-router-dom';
// App Shared
import AchievementPromotionOverlay from '@shared/containers/AchievementPromotionOverlay';
import AppDrawer from '@shared/containers/AppDrawer';
import GQLSubscriptions from '@shared/containers/GQLSubscriptionsContainer';
import NewsPromotionOverlay from '@shared/containers/NewsPromotionOverlay';
import ResourceCenterOverlay from '@shared/containers/ResourceCenterOverlay';
import { Routes } from '@shared/enums';
import { useAuth } from '@shared/clients/authClient';

/**
 * @name AppOverlay
 * @description Overlay components that should be rendered on top of the app
 * @returns JSX.Element | null
 */
export function AppOverlay() {
  const [isLoggedIn] = useAuth();

  const isGuestMeetingRoute = !!useRouteMatch(Routes.GuestMeeting);
  const isGuestMotesRoute = !!useRouteMatch(Routes.GuestNotes);

  const isGuestRoute = isGuestMeetingRoute || isGuestMotesRoute;

  // Don't render the AppOverlay if the user is not logged in
  // or if the user is on a guest meeting route
  if (!isLoggedIn || isGuestRoute) return null;

  return (
    <>
      <AppDrawer />
      <GQLSubscriptions />
      <AchievementPromotionOverlay />
      <NewsPromotionOverlay />
      <ResourceCenterOverlay />
    </>
  );
}

export default AppOverlay;
