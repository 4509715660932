import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const CreditsModuleContainer = lazyWithPreload(
  () => import('./containers/CreditsModuleContainer'),
);

export const CreditsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Credits,
    render: () => <CreditsModuleContainer />,
  },
};

export default CreditsModule;
