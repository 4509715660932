// Sembly UI
import { GenericDialog } from '@sembly-ui';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

export interface UnsupportedBrowserProps {
  open: boolean;
  onClose: () => void;
}

export const UnsupportedBrowser: React.VFC<UnsupportedBrowserProps> = ({ open, onClose }) => {
  return (
    <GenericDialog
      dialogProps={{
        open: open,
        fullWidth: true,
        maxWidth: 'sm',
      }}
      onClose={onClose}
    >
      <DialogContent>
        <Box mt={6} mb={4} textAlign="center">
          <Typography gutterBottom variant="h5" color="primary">
            Browser not supported
          </Typography>
          <Typography variant="body1">
            The current version of your browser doesn’t support our recorder. Please, upgrade to the
            latest version or try another browser.
          </Typography>
        </Box>
        <Box mb={6} textAlign="center">
          <Button
            disableElevation
            color="primary"
            variant="contained"
            aria-label="Dismiss dialog"
            onClick={onClose}
          >
            Dismiss
          </Button>
        </Box>
      </DialogContent>
    </GenericDialog>
  );
};

export default UnsupportedBrowser;
