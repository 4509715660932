import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Automations = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0009 13H2V11H18.0009V8.99991L21.9991 12L18.0009 15.0001V13ZM14 18.9999C14 18.9956 14 18.9912 14 18.9869V17.9998C14 17.7349 13.8941 17.4803 13.7086 17.293C13.5205 17.1054 13.2661 17.0001 13.0011 17.0001H2V15.0001H13.0011C13.796 15.0001 14.5591 15.3159 15.1208 15.8787C15.6851 16.4414 16.0005 17.2044 16.0005 17.9998V18.9869C16.0005 18.9912 16.0005 18.9956 16.0005 18.9999H18.0009L15.0016 23L11.9996 18.9999H14ZM14 5.0001H11.9996L15.0016 1L18.0009 5.0001H16.0005C16.0005 5.00442 16.0005 5.00873 16.0005 5.01305V5.99984C16.0005 6.7956 15.6851 7.55857 15.1208 8.1213C14.5591 8.68403 13.796 8.99991 13.0011 8.99991H2V6.99986H13.0011C13.2661 6.99986 13.5205 6.89456 13.7086 6.70699C13.8941 6.51941 14 6.2651 14 5.99984V5.01305C14 5.00873 14 5.00442 14 5.0001Z"
    />
  </SvgIcon>
);

export default Automations;
