import clsx from 'clsx';
// Material UI
import Box from '@material-ui/core/Box';
import MaterialAppBar, { AppBarProps as MaterialAppBarProps } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export interface AppBarProps extends MaterialAppBarProps {
  className?: string;
  toolbarClassName?: string;
  layout?: 'extra-wide' | 'wide' | 'default';
}

export const AppBar: React.FC<AppBarProps> = ({
  children,
  className = '',
  toolbarClassName = '',
  layout = 'default',
  ...props
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MaterialAppBar
      position="fixed"
      elevation={0}
      color="inherit"
      className={`${styles.root} ${className}`}
      {...props}
    >
      {!isSmallScreen && <Box width={230} />}
      <Toolbar
        className={clsx(styles.toolbar, {
          [toolbarClassName]: !!toolbarClassName,
          wide: layout === 'wide',
          'extra-wide': layout === 'extra-wide',
        })}
      >
        {children}
      </Toolbar>
    </MaterialAppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    top: 'auto',
    paddingLeft: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    zIndex: 1,
  },
  toolbar: {
    flex: 1,
    overflow: 'inherit',
    ...theme.mixins.layout.default.paddingLeft,
    '&.wide': {
      ...theme.mixins.layout.wide.paddingLeft,
    },
    '&.extra-wide': {
      paddingLeft: `${theme.spacing(2)}px !important`,
    },
  },
}));

export default AppBar;
