import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import AddIcon from '@material-ui/icons/AddSharp';
// GraphQL
import connectIntegrationMutation from '../graphql/mutations/ConnectBasicIntegration.graphql';
import disconnectIntegrationMutation from '../graphql/mutations/DisconnectBasicIntegration.graphql';
import editActionsIntegrationMutation from '../graphql/mutations/EditActionsCustomOutboundWorkspaceIntegration.graphql';
import editTranscriptIntegrationMutation from '../graphql/mutations/EditTranscriptCustomOutboundWorkspaceIntegration.graphql';
import editMeetingNotesIntegrationMutation from '../graphql/mutations/EditMeetingNotesCustomOutboundWorkspaceIntegration.graphql';
import query from '../graphql/queries/WorkspaceOutboundIntegrations.graphql';
// Lib Assets
import actionsIntegrationIcon from '../assets/icon-custom-actions-integration-36.svg';
import meetingNotesIntegrationIcon from '../assets/icon-custom-meeting-notes-integration-36.svg';
import transcriptIntegrationIcon from '../assets/icon-custom-trascript-integration-36.svg';
// Lib Shared
import {
  ConnectBasicIntegration,
  ConnectBasicIntegrationVariables,
  DisconnectBasicIntegration,
  DisconnectBasicIntegrationVariables,
  EditActionsCustomOutboundWorkspaceIntegration,
  EditActionsCustomOutboundWorkspaceIntegrationVariables,
  EditMeetingNotesCustomOutboundWorkspaceIntegration,
  EditMeetingNotesCustomOutboundWorkspaceIntegrationVariables,
  EditTranscriptCustomOutboundWorkspaceIntegration,
  EditTranscriptCustomOutboundWorkspaceIntegrationVariables,
  GenericActionsCustomOutboundWorkspaceIntegration,
  GenericMeetingNotesCustomOutboundWorkspaceIntegration,
  GenericOutboundIntegration,
  GenericTranscriptionCustomOutboundWorkspaceIntegration,
  GraphError,
  IntegrationTypes,
  WorkspaceOutboundIntegrations,
} from '../types';
import { useConfirmationDialog } from '../hooks';
import { ManageCustomWorkspaceAutomationDialog } from '../dialogs';
import {
  AutomationsPlaceholder,
  ConnectedAutomationCard,
  GenericConfirmation,
} from '../components';

export interface WorkspaceCustomAutomationsContainerProps {
  onConnectedIntegration?: () => void;
  onUpdateCustomIntegration?: (automationName: string) => void;
  onDisconnectIntegration?: (integrationName: string) => void;
  onResponseError?: (err: GraphError) => void;
  onWarning?: (message: string) => void;
}

export const WorkspaceCustomAutomationsContainer: React.VFC<
  WorkspaceCustomAutomationsContainerProps
> = ({
  onConnectedIntegration = () => null,
  onUpdateCustomIntegration = () => null,
  onDisconnectIntegration = () => null,
  onResponseError = () => null,
  onWarning = () => null,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [managingAutomation, setManagingAutomation] = useState<
    | GenericActionsCustomOutboundWorkspaceIntegration
    | GenericTranscriptionCustomOutboundWorkspaceIntegration
    | GenericMeetingNotesCustomOutboundWorkspaceIntegration
    | null
  >(null);

  const { data, refetch, loading } = useQuery<WorkspaceOutboundIntegrations>(query);

  const [connectIntegration, { loading: isConnectingIntegration }] = useMutation<
    ConnectBasicIntegration,
    ConnectBasicIntegrationVariables
  >(connectIntegrationMutation);

  const [disconnectIntegration] = useMutation<
    DisconnectBasicIntegration,
    DisconnectBasicIntegrationVariables
  >(disconnectIntegrationMutation);

  const [editActionsIntegration] = useMutation<
    EditActionsCustomOutboundWorkspaceIntegration,
    EditActionsCustomOutboundWorkspaceIntegrationVariables
  >(editActionsIntegrationMutation, { refetchQueries: [{ query }] });

  const [editTranscriptIntegration] = useMutation<
    EditTranscriptCustomOutboundWorkspaceIntegration,
    EditTranscriptCustomOutboundWorkspaceIntegrationVariables
  >(editTranscriptIntegrationMutation, { refetchQueries: [{ query }] });

  const [editMeetingNotesIntegration] = useMutation<
    EditMeetingNotesCustomOutboundWorkspaceIntegration,
    EditMeetingNotesCustomOutboundWorkspaceIntegrationVariables
  >(editMeetingNotesIntegrationMutation, { refetchQueries: [{ query }] });

  const [confirmDelete, DeleteConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Delete"
      text="Are you sure you want to delete this automation?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Delete"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));
  /* #endregion */

  /* #region  Helpers */
  const connectBasicIntegration = async (integrationType: IntegrationTypes) => {
    const result = await connectIntegration({
      variables: { integrationType },
    });

    const connectedIntegration = result.data?.connectBasicIntegration?.integration?.childObject;
    const typename = connectedIntegration?.__typename;

    if (
      result.data?.connectBasicIntegration?.success &&
      (typename === 'ActionsCustomOutboundWorkspaceIntegrationType' ||
        typename === 'TranscriptionCustomOutboundWorkspaceIntegrationType' ||
        typename === 'MeetingNotesCustomOutboundWorkspaceIntegrationType')
    ) {
      onConnectedIntegration();
      refetch();
      return connectedIntegration;
    }

    onResponseError(result.data?.connectBasicIntegration?.errors);
    return null;
  };

  const disconnectBasicIntegration = async (integrationId: string) => {
    const result = await disconnectIntegration({
      variables: { integrationId: +integrationId },
      optimisticResponse: {
        disconnectBasicIntegration: {
          __typename: 'DisconnectBasicIntegrationMutationPayload',
          errors: [],
          success: true,
        },
      },
      update: (cache) => {
        const targetIntegrationId = cache.identify({
          __typename: 'IntegrationType',
          id: integrationId,
        });
        const targetCustomIntegrationId = cache.identify({
          __typename: 'CustomOutboundIntegrationType',
          id: integrationId,
        });
        cache.evict({ id: targetCustomIntegrationId });
        cache.evict({ id: targetIntegrationId });
        cache.gc();
      },
    });

    if (result.data?.disconnectBasicIntegration?.success) {
      onDisconnectIntegration('Custom Automation');
    } else {
      onResponseError(result.data?.disconnectBasicIntegration?.errors);
    }
  };
  /* #endregion */

  /* #region  Handlers */
  const handleClickOnMenuButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseIntegrationMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleCloseManageAutomationDialog =
    (id: string) =>
    (shouldDiscard: boolean = false) => {
      if (shouldDiscard) disconnectBasicIntegration(id);
      setManagingAutomation(null);
    };

  const handleEditAutomation =
    (
      childObject:
        | GenericActionsCustomOutboundWorkspaceIntegration
        | GenericTranscriptionCustomOutboundWorkspaceIntegration
        | GenericMeetingNotesCustomOutboundWorkspaceIntegration,
    ) =>
    () => {
      setManagingAutomation(childObject);
    };

  const handleChangeActivity =
    (
      integration:
        | GenericActionsCustomOutboundWorkspaceIntegration
        | GenericTranscriptionCustomOutboundWorkspaceIntegration
        | GenericMeetingNotesCustomOutboundWorkspaceIntegration,
      isActive: boolean,
    ) =>
    async () => {
      const errorMessage =
        'The integration is invalid, please check all fields in the integration settings.';

      if (!integration.title || !integration.workspaceRule || !integration.endpoint) {
        return onWarning(errorMessage);
      }

      if (integration.__typename === 'ActionsCustomOutboundWorkspaceIntegrationType') {
        if (!integration.actionsFields) {
          return onWarning(errorMessage);
        }

        const result = await editActionsIntegration({
          variables: {
            keywords: integration.keywords,
            fields: integration.actionsFields.map((field) => ({
              fieldName: field.fieldName,
              name: field.name,
            })),
            description: integration.description,
            endpoint: integration.endpoint,
            integrationId: integration.id,
            rule: integration.workspaceRule,
            title: integration.title,
            meetingType: integration.meetingType,
            ownerForRule: integration.ownerForRule?.id,
            teamForRule: integration.teamForRule?.id,
            isActive,
          },
          optimisticResponse: {
            editActionsCustomOutboundWorkspaceIntegration: {
              __typename: 'EditActionsCustomOutboundWorkspaceIntegrationMutationPayload',
              success: true,
              errors: [],
              integration: {
                __typename: 'ActionsCustomOutboundWorkspaceIntegrationType',
                id: integration.id,
                actionsFields: integration.actionsFields,
                description: integration.description,
                endpoint: integration.endpoint,
                keywords: integration.keywords,
                meetingType: integration.meetingType,
                ownerForRule: integration.ownerForRule,
                teamForRule: integration.teamForRule,
                workspaceRule: integration.workspaceRule,
                title: integration.title,
                isActive,
              },
            },
          },
        });

        if (!result.data?.editActionsCustomOutboundWorkspaceIntegration?.success) {
          onResponseError(result.data?.editActionsCustomOutboundWorkspaceIntegration?.errors);
        }
      } else if (integration.__typename === 'MeetingNotesCustomOutboundWorkspaceIntegrationType') {
        if (!integration.meetingNotesFields) {
          return onWarning(errorMessage);
        }

        const result = await editMeetingNotesIntegration({
          variables: {
            keywords: integration.keywords,
            fields: integration.meetingNotesFields.map((field) => ({
              fieldName: field.fieldName,
              name: field.name,
            })),
            description: integration.description,
            endpoint: integration.endpoint,
            integrationId: integration.id,
            rule: integration.workspaceRule,
            title: integration.title,
            meetingType: integration.meetingType,
            ownerForRule: integration.ownerForRule?.id,
            teamForRule: integration.teamForRule?.id,
            isActive,
          },
          optimisticResponse: {
            editMeetingNotesCustomOutboundWorkspaceIntegration: {
              __typename: 'EditMeetingNotesCustomOutboundWorkspaceIntegrationMutationPayload',
              success: true,
              errors: [],
              integration: {
                __typename: 'MeetingNotesCustomOutboundWorkspaceIntegrationType',
                id: integration.id,
                meetingNotesFields: integration.meetingNotesFields,
                description: integration.description,
                endpoint: integration.endpoint,
                keywords: integration.keywords,
                meetingType: integration.meetingType,
                ownerForRule: integration.ownerForRule,
                teamForRule: integration.teamForRule,
                workspaceRule: integration.workspaceRule,
                title: integration.title,
                isActive,
              },
            },
          },
        });

        if (!result.data?.editMeetingNotesCustomOutboundWorkspaceIntegration?.success) {
          onResponseError(result.data?.editMeetingNotesCustomOutboundWorkspaceIntegration?.errors);
        }
      } else if (integration.__typename === 'TranscriptionCustomOutboundWorkspaceIntegrationType') {
        if (!integration.transcriptionFields) {
          return onWarning(errorMessage);
        }

        const result = await editTranscriptIntegration({
          variables: {
            keywords: integration.keywords,
            fields: integration.transcriptionFields.map((field) => ({
              fieldName: field.fieldName,
              name: field.name,
            })),
            description: integration.description,
            endpoint: integration.endpoint,
            integrationId: integration.id,
            rule: integration.workspaceRule,
            title: integration.title,
            meetingType: integration.meetingType,
            ownerForRule: integration.ownerForRule?.id,
            teamForRule: integration.teamForRule?.id,
            isActive,
          },
          optimisticResponse: {
            editTranscriptionCustomOutboundWorkspaceIntegration: {
              __typename: 'EditTranscriptionCustomOutboundWorkspaceIntegrationMutationPayload',
              success: true,
              errors: [],
              integration: {
                __typename: 'TranscriptionCustomOutboundWorkspaceIntegrationType',
                id: integration.id,
                transcriptionFields: integration.transcriptionFields,
                description: integration.description,
                endpoint: integration.endpoint,
                keywords: integration.keywords,
                meetingType: integration.meetingType,
                ownerForRule: integration.ownerForRule,
                teamForRule: integration.teamForRule,
                workspaceRule: integration.workspaceRule,
                title: integration.title,
                isActive,
              },
            },
          },
        });

        if (!result.data?.editTranscriptionCustomOutboundWorkspaceIntegration?.success) {
          onResponseError(result.data?.editTranscriptionCustomOutboundWorkspaceIntegration?.errors);
        }
      }
    };

  const handleDisconnectBasicIntegration = (integrationId: string) => async () => {
    const confirmed = await confirmDelete();
    if (!confirmed) return;

    disconnectBasicIntegration(integrationId);
  };

  const handleCreateIntegration =
    (state: 'meetingNotes' | 'actions' | 'transcript') => async () => {
      setMenuAnchorEl(null);

      let connectedIntegration:
        | GenericActionsCustomOutboundWorkspaceIntegration
        | GenericTranscriptionCustomOutboundWorkspaceIntegration
        | GenericMeetingNotesCustomOutboundWorkspaceIntegration
        | null = null;

      if (state === 'actions') {
        connectedIntegration = await connectBasicIntegration(
          IntegrationTypes.ACTIONS_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION,
        );
      } else if (state === 'meetingNotes') {
        connectedIntegration = await connectBasicIntegration(
          IntegrationTypes.MEETING_NOTES_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION,
        );
      } else if (state === 'transcript') {
        connectedIntegration = await connectBasicIntegration(
          IntegrationTypes.TRANSCRIPTION_CUSTOM_OUTBOUND_WORKSPACE_INTEGRATION,
        );
      }

      if (!!connectedIntegration) {
        setManagingAutomation(connectedIntegration);
      }
    };
  /* #endregion */

  // Utils
  const isTargetIntegration = ({ childObject }: GenericOutboundIntegration) => {
    return (
      childObject &&
      (childObject.__typename === 'ActionsCustomOutboundWorkspaceIntegrationType' ||
        childObject.__typename === 'MeetingNotesCustomOutboundWorkspaceIntegrationType' ||
        childObject.__typename === 'TranscriptionCustomOutboundWorkspaceIntegrationType')
    );
  };

  const sortAlphabetically = (a: GenericOutboundIntegration, b: GenericOutboundIntegration) => {
    return a.id.localeCompare(b.id);
  };

  /* #region  Render Helpers */
  const getAutomationIcon = (
    typename:
      | 'ActionsCustomOutboundWorkspaceIntegrationType'
      | 'MeetingNotesCustomOutboundWorkspaceIntegrationType'
      | 'TranscriptionCustomOutboundWorkspaceIntegrationType',
  ) => {
    return {
      ActionsCustomOutboundWorkspaceIntegrationType: actionsIntegrationIcon,
      MeetingNotesCustomOutboundWorkspaceIntegrationType: meetingNotesIntegrationIcon,
      TranscriptionCustomOutboundWorkspaceIntegrationType: transcriptIntegrationIcon,
    }[typename];
  };

  const connectedIntegrations = data?.workspaceIntegrations;
  const filteredCustomIntegrations = connectedIntegrations?.filter(isTargetIntegration) ?? [];
  const sortedCustomIntegrations = filteredCustomIntegrations.sort(sortAlphabetically);
  const customIntegrations = sortedCustomIntegrations.map(({ childObject }) => childObject);
  const paymentPlanFeatures = data?.me?.paymentCustomer?.plan?.features;
  const staticLimits = paymentPlanFeatures?.staticLimits;
  const connectedIntegrationsCount = customIntegrations?.length ?? 0;
  const maxIntegrartionsCount = staticLimits?.customOutboundIntegrationsLimit ?? 0;
  const isLimitExhausted = connectedIntegrationsCount >= maxIntegrartionsCount;
  /* #endregion */

  return (
    <>
      <Box pt={2}>
        <div className={styles.header}>
          <Box display="flex" flexDirection="column" gridGap={6} flex={1}>
            <Typography variant="h6">Custom Automations</Typography>
            <Typography component="div" variant="body2" color="textSecondary">
              Connected integrations: {`${connectedIntegrationsCount} of ${maxIntegrartionsCount} `}
            </Typography>
          </Box>
          <Tooltip
            arrow
            title={
              isLimitExhausted
                ? 'You have reached the limit of custom integrations'
                : 'Create a new automation'
            }
          >
            <span>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                aria-label="Create a new automation"
                disabled={loading || isLimitExhausted || isConnectingIntegration}
                onClick={handleClickOnMenuButton}
                startIcon={
                  isConnectingIntegration ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <AddIcon />
                  )
                }
              >
                <Typography noWrap component="span" variant="body1">
                  {isSmallScreen ? 'New' : 'New Automation'}
                </Typography>
              </Button>
            </span>
          </Tooltip>
          <Menu
            keepMounted
            id="custom-integrations-menu"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleCloseIntegrationMenu}
          >
            <MenuItem onClick={handleCreateIntegration('meetingNotes')}>
              <ListItemIcon>
                <img
                  src={meetingNotesIntegrationIcon}
                  width={24}
                  height={24}
                  alt="Custom Meeting Notes Integration"
                />
              </ListItemIcon>
              <Typography variant="inherit"> Meeting Notes</Typography>
            </MenuItem>
            <MenuItem onClick={handleCreateIntegration('actions')}>
              <ListItemIcon>
                <img
                  src={actionsIntegrationIcon}
                  width={24}
                  height={24}
                  alt="Custom Tasks Integration"
                />
              </ListItemIcon>
              <Typography variant="inherit"> Tasks</Typography>
            </MenuItem>
            <MenuItem onClick={handleCreateIntegration('transcript')}>
              <ListItemIcon>
                <img
                  src={transcriptIntegrationIcon}
                  width={24}
                  height={24}
                  alt="Custom Transcription Integration"
                />
              </ListItemIcon>
              <Typography variant="inherit"> Transcription</Typography>
            </MenuItem>
          </Menu>
        </div>

        <div className={styles.connectedIntegrations}>
          {loading ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  variant="rect"
                  key={index}
                  className={styles.connectedIntegrationSkeleton}
                />
              ))}
            </>
          ) : (
            <>
              {!customIntegrations.length ? (
                <AutomationsPlaceholder />
              ) : (
                <>
                  {customIntegrations.map((childObject, index) => {
                    if (!childObject) return null;

                    const typename = childObject.__typename;

                    if (
                      typename !== 'ActionsCustomOutboundWorkspaceIntegrationType' &&
                      typename !== 'MeetingNotesCustomOutboundWorkspaceIntegrationType' &&
                      typename !== 'TranscriptionCustomOutboundWorkspaceIntegrationType'
                    ) {
                      return null; // Render onlym known workspace level custom integrations
                    }

                    const integrationIcon = getAutomationIcon(typename);

                    return (
                      <ConnectedAutomationCard
                        key={childObject.id}
                        enabled={childObject.isActive}
                        description={childObject.description || ''}
                        logoUri={integrationIcon}
                        name={childObject.title || 'Custom Automation'}
                        onDelete={handleDisconnectBasicIntegration(childObject.id)}
                        onEdit={handleEditAutomation(childObject)}
                        onToggleEnabled={handleChangeActivity(childObject, !childObject.isActive)}
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </div>
      </Box>
      {!!managingAutomation && (
        <ManageCustomWorkspaceAutomationDialog
          open
          count={connectedIntegrationsCount}
          icon={getAutomationIcon(managingAutomation.__typename)}
          connectedIntegration={managingAutomation}
          onClose={handleCloseManageAutomationDialog(managingAutomation.id)}
          onUpdated={onUpdateCustomIntegration}
          onGraphError={onResponseError}
        />
      )}
      {DeleteConfirmationDialog}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  connectedIntegrations: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  connectedIntegrationSkeleton: {
    height: 64,
    borderRadius: theme.shape.borderRadius * 2,
  },
  integrationAppSkeleton: {
    width: '100%',
    height: 114,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));
