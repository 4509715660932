// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import AppsIcon from '@material-ui/icons/Apps';
// Lib Graphql
import {
  ActionsCustomIntegrationSettingsContentTypes,
  ExtendedFlowInstance,
  GenericKeyItemsDestinationList,
  GenericNotionDatabase,
  GenericOutboundIntegration,
  IntegrationTypes,
  UserOutboundIntegrations,
} from '../../types';
// Lib Shared
import CustomIntegrationItem from './CustomIntegrationItem';
import GoogleTasksIntegrationItem from './GoogleTasksIntegrationItem';
import KeyItemsIntegrationsLayout from '../../layouts/KeyItemsIntegrationsLayout';
import MSTodoIntegrationItem from './MSTodoIntegrationItem';
import NotionIntegrationItem from './NotionIntegrationItem';
import integrationsImage from '../../assets/icon-integrations-36.svg';

interface KeyItemsIntegrationsProps {
  isSending: boolean;
  userOutboundIntegrations: UserOutboundIntegrations | undefined;
  fabricIntegrations: ExtendedFlowInstance[];
  onClickOnDiscoverIntegrations: () => void;
  onOpenListDialog: (dialogType: IntegrationTypes) => void;
  onSendCustom: (
    customId: string,
    customContentType: ActionsCustomIntegrationSettingsContentTypes,
  ) => void;
  onSendTodo: (
    todoListItem: GenericKeyItemsDestinationList | null,
    integrationType: IntegrationTypes,
  ) => void;
  onSendToNotion: (destination: GenericNotionDatabase | null) => void;
  onSendFabricItem: (automation: ExtendedFlowInstance) => void;
}

export const KeyItemsIntegrations: React.VFC<KeyItemsIntegrationsProps> = ({
  isSending,
  userOutboundIntegrations,
  fabricIntegrations,
  onClickOnDiscoverIntegrations,
  onOpenListDialog,
  onSendCustom,
  onSendTodo,
  onSendToNotion,
  onSendFabricItem,
}) => {
  const styles = useStyles();

  /* #region Utils */
  const getPostIntegrations = (data: GenericOutboundIntegration) => {
    const { childObject } = data;
    return (
      childObject?.__typename === 'MsTodoUserIntegrationType' ||
      childObject?.__typename === 'GoogleTasksUserIntegrationType' ||
      childObject?.__typename === 'NotionAssignmentsUserIntegrationType'
    );
  };

  const getCustomIntegrations = (data: GenericOutboundIntegration) => {
    const { childObject } = data;
    return (
      childObject?.__typename === 'ActionsCustomOutboundUserIntegrationType' && childObject.isActive
    );
  };
  /* #endregion */

  const handleClickOnFabricItem = (automation: ExtendedFlowInstance) => () => {
    onSendFabricItem(automation);
  };

  /* #region Render Helpers */
  const outboundIntegrations = userOutboundIntegrations?.outboundIntegrations || [];
  const postIntegrations = outboundIntegrations.filter(getPostIntegrations);
  const customIntegrations = outboundIntegrations.filter(getCustomIntegrations);
  const hasPostOutboundIntegrations = !!postIntegrations?.length;
  const hasCustomOutboundIntegrations = !!customIntegrations.length;
  const hasCustomIntegrations = hasPostOutboundIntegrations || hasCustomOutboundIntegrations;
  const hasFabricIntegrations = !!fabricIntegrations.length;
  /* #endregion */

  if (!hasCustomIntegrations && !hasFabricIntegrations) {
    return (
      <Box m={3} textAlign="center">
        <Box mb={2}>
          <img src={integrationsImage} alt="Connected Apps" />
        </Box>
        <Box my={2}>
          <Typography gutterBottom variant="h6">
            No Connected Apps
          </Typography>
          <Typography variant="body1">
            Connect Sembly with your favorite apps to create your first automation.
          </Typography>
        </Box>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          onClick={onClickOnDiscoverIntegrations}
        >
          <Typography variant="body1">Browse all apps</Typography>
        </Button>
      </Box>
    );
  }

  return (
    <>
      {isSending ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          minWidth={300}
          py={8}
        >
          <CircularProgress size={24} />
          <Box mt={2} />
          <Typography variant="body2">Sending...</Typography>
        </Box>
      ) : (
        <>
          <Box maxHeight="50vh" overflow="auto" mb={1}>
            {hasPostOutboundIntegrations || hasFabricIntegrations ? (
              <KeyItemsIntegrationsLayout title="Post">
                {fabricIntegrations.map((automation) => {
                  if (automation.flow?.key !== 'push-assignments') return null;
                  return (
                    <MenuItem
                      key={automation.id}
                      className={styles.menuItem}
                      onClick={handleClickOnFabricItem(automation)}
                    >
                      <div className={styles.content}>
                        {!!automation.integration?.logoUri && (
                          <img
                            src={automation.integration?.logoUri}
                            alt={automation.name}
                            width={24}
                            height={24}
                          />
                        )}
                        <Box display="flex" ml={1} mr={1}>
                          <span>Post item to:</span>
                          <Tooltip title={automation.name} classes={{ tooltip: styles.tooltip }}>
                            <Typography noWrap variant="inherit" className={styles.label}>
                              <b>{automation.name}</b>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </div>
                    </MenuItem>
                  );
                })}
                {postIntegrations.map(({ childObject }, index) => {
                  if (childObject?.__typename === 'MsTodoUserIntegrationType') {
                    const { lastUsedDestination } = childObject;
                    return (
                      <MSTodoIntegrationItem
                        key={childObject.__typename + index}
                        msTodoListItem={lastUsedDestination}
                        onOpen={onOpenListDialog}
                        onSend={onSendTodo}
                      />
                    );
                  }
                  if (childObject?.__typename === 'GoogleTasksUserIntegrationType') {
                    const { lastUsedDestination } = childObject;
                    return (
                      <GoogleTasksIntegrationItem
                        key={childObject.__typename + index}
                        googleTaskItemDestination={lastUsedDestination}
                        onOpen={onOpenListDialog}
                        onSend={onSendTodo}
                      />
                    );
                  }
                  if (childObject?.__typename === 'NotionAssignmentsUserIntegrationType') {
                    const { lastUsedDatabase } = childObject;
                    return (
                      <NotionIntegrationItem
                        key={childObject.__typename + index}
                        destination={lastUsedDatabase}
                        onOpen={onOpenListDialog}
                        onSend={onSendToNotion}
                      />
                    );
                  }
                  return null;
                })}
              </KeyItemsIntegrationsLayout>
            ) : null}
            {hasCustomOutboundIntegrations ? (
              <KeyItemsIntegrationsLayout title="Post to custom integrations">
                {customIntegrations.map(({ childObject }, index) => {
                  if (childObject?.__typename === 'ActionsCustomOutboundUserIntegrationType') {
                    return (
                      <CustomIntegrationItem
                        key={childObject.__typename + index}
                        customIntegrations={childObject}
                        onSend={onSendCustom}
                      />
                    );
                  }
                  return null;
                })}
              </KeyItemsIntegrationsLayout>
            ) : null}
          </Box>
          <Box m={2}>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AppsIcon />}
              onClick={onClickOnDiscoverIntegrations}
            >
              <Typography variant="body2">Discover new integrations</Typography>
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .ghostly': {
      visibility: 'hidden',
      [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
      },
    },
    '&:hover .ghostly': {
      visibility: 'visible',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
  },
  label: {
    display: 'block',
    maxWidth: '10rem',
    marginLeft: theme.spacing(0.5),
  },
}));

export default KeyItemsIntegrations;
