// Material UI
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import integrationsImage from '../assets/icon-integrations-alt-36.svg';

/**
 * Placeholder for when there are no automations.
 */
export const AutomationsPlaceholder: React.VFC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <img width={36} alt="Integrations" src={integrationsImage} />
      </div>
      <Typography variant="h6">No Automations</Typography>
      <Typography variant="body1">All your automations will appear here.</Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(3, 2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    margin: theme.spacing(1),
  },
}));

export default AutomationsPlaceholder;
