import { SvgIcon } from '@material-ui/core';

export const MinutesKeyItems = () => (
  <SvgIcon viewBox="0 0 64 64">
    <rect fill="#00b3b3" y="18" width="56" height="28" rx="6" />
    <path
      fill="#fff"
      d="M12,35.73,8.62,32.38a.95.95,0,0,0-1.35,1.33h0l4,4a1,1,0,0,0,1.34,0L22.76,27.61a.95.95,0,0,0-1.34-1.34Z"
    />
    <circle fill="#fff" cx="17.86" cy="9" r="5" />
    <path d="M17.86,5a4,4,0,1,1-4,4,4,4,0,0,1,4-4m0-2a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z" />
    <rect x="28" y="6" width="36" height="2" rx="1" />
    <rect x="28" y="10" width="24" height="2" rx="1" />
    <circle fill="#fff" cx="17.86" cy="55" r="5" />
    <path d="M17.86,51a4,4,0,1,1-4,4,4,4,0,0,1,4-4m0-2a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z" />
    <rect x="28" y="52" width="36" height="2" rx="1" />
    <rect x="28" y="56" width="24" height="2" rx="1" />
    <rect fill="#fff" x="30" y="25" width="20" height="2" rx="1" />
    <rect fill="#fff" x="30" y="29" width="14" height="2" rx="1" />
    <rect fill="#fff" x="30" y="33" width="20" height="2" rx="1" />
    <rect fill="#fff" x="30" y="37" width="14" height="2" rx="1" />
  </SvgIcon>
);

export default MinutesKeyItems;
