import genericDecisionFragment from '../graphql/fragments/GenericDecision.graphql';
import genericIssueFragment from '../graphql/fragments/GenericIssue.graphql';
import genericParkingLotFragment from '../graphql/fragments/GenericParkingLot.graphql';
import genericQAndAFragment from '../graphql/fragments/GenericQAndA.graphql';
import genericRequirementFragment from '../graphql/fragments/GenericRequirement.graphql';
import genericRiskFragment from '../graphql/fragments/GenericRisk.graphql';
import genericKeyEventFragment from '../graphql/fragments/GenericKeyEvent.graphql';
import genericNoteworthyDetailFragment from '../graphql/fragments/GenericNoteworthyDetail.graphql';

// prettier-ignore
export const ACTIVITY_TYPES = {
  decisions: { title: 'Decisions', label: 'Decision', color: '#00A69F', countKey: 'numDecisions', fragmentName: 'GenericDecision', fragment: genericDecisionFragment },
  issues: { title: 'Issues', label: 'Issue', color: '#F74C01', countKey: 'numIssues', fragmentName: 'GenericIssue', fragment: genericIssueFragment  },
  keyEvents: { title: 'Events', label: 'Event', color: '#576ACE', countKey: 'numKeyEvents', fragmentName: 'GenericKeyEvent', fragment: genericKeyEventFragment },
  parkingLots: { title: 'Parking Lot', label: 'Parking Lot', color: '#576ACE', countKey: 'numParkingLots', fragmentName: 'GenericParkingLot', fragment: genericParkingLotFragment },
  qAndAs: { title: 'Questions', label: 'Question', color: '#00A69F', countKey: 'numQAndA', fragmentName: 'GenericQAndA', fragment: genericQAndAFragment },
  requirements: { title: 'Requirements', label: 'Requirement', color: '#00A69F', countKey: 'numRequirements', fragmentName: 'GenericRequirement', fragment: genericRequirementFragment },
  risks: { title: 'Risks', label: 'Risk', color: '#F74C01', countKey: 'numRisks', fragmentName: 'GenericRisk', fragment: genericRiskFragment },
  noteworthyDetails: { title: 'Notable', label: 'Notable', color: '#00A69F', countKey: 'numNoteworthyDetails', fragmentName: 'GenericNoteworthyDetail', fragment: genericNoteworthyDetailFragment },
} as const;

export default ACTIVITY_TYPES;
