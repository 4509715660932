import { GenericAssignment } from '../../types';

export const getAssignmentContent = (item: GenericAssignment): string => {
  let content = item.customText || item.text || '';

  // If there is a header text, add it to the content
  if (item.headerText) {
    content = `${item.customHeaderText || item.headerText}\n${content}`;
  }
  // If there is a discussion, add it to the content
  if (item.inDiscussionWith) {
    content = `${content}\nDiscussed with: ${item.inDiscussionWith}`;
  }
  // If there is a assignee, add it to the content
  if (item.rawAssignedBy || item.assignedBy) {
    content = `${content}\nAssigned by: ${item.assignedBy?.fullName || item.rawAssignedBy}`;
  }
  // If there is a performer, add it to the content
  if (item.rawPerformerName || item.performer) {
    content = `${content}\nAssigned to: ${item.performer?.fullName || item.rawPerformerName}`;
  }
  // If there is a due date, add it to the content
  if (item.dueDate || item.rawTiming) {
    content = `${content}\nDeadline: ${item.dueDate || item.rawTiming}`;
  }

  return content;
};
