import { useState } from 'react';
// Material UI
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Material UI Icons
import MoreIcon from '@material-ui/icons/MoreVertSharp';
// Lib Shared
import Switch from './Switch';

export interface ConnectedAutomationCardProps {
  description: string;
  enabled: boolean;
  logoUri: string | null;
  name: string;
  onDelete: () => void;
  onEdit: () => void;
  onToggleEnabled: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

/**
 * Represents a card that displays a connected automation.
 */
export const ConnectedAutomationCard: React.VFC<ConnectedAutomationCardProps> = ({
  description,
  enabled,
  logoUri,
  name,
  onDelete,
  onEdit,
  onToggleEnabled,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  /* #endregion */

  /* #region  Handlers */
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleCloseMenu();
    onEdit();
  };

  const handleDelete = () => {
    handleCloseMenu();
    onDelete();
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.target as HTMLElement);
  };
  /* #endregion */

  return (
    <>
      <div className={styles.root}>
        <div className={styles.appLogo}>
          {logoUri ? (
            <img className={styles.appLogoImage} src={logoUri} alt={name} width={24} height={24} />
          ) : (
            <Avatar variant="square">{name.charAt(0).toUpperCase()}</Avatar>
          )}
        </div>

        <div className={styles.content}>
          <Typography noWrap className={styles.title}>
            {name}
          </Typography>
          <Typography variant={isSmallScreen ? 'body2' : 'body1'}>{description}</Typography>
        </div>
        <div className={styles.actions}>
          <Switch checked={enabled} onChange={onToggleEnabled} />
          <IconButton
            size="small"
            className={styles.outlinedButton}
            aria-label="More options"
            onClick={handleOpenMenu}
          >
            <MoreIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      {anchorEl && (
        <Menu
          open
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          transformOrigin={{ vertical: 'center', horizontal: 'center' }}
          getContentAnchorEl={null}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleEdit}>Edit</MenuItem>
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    cursor: 'default',
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(1),
    },
  },
  appLogo: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    gap: theme.spacing(0.25),
    overflow: 'hidden',
  },
  actions: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    textOverflow: 'ellipsis',
  },
  outlinedButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
  },
  appLogoImage: {
    objectFit: 'contain',
  },
}));

export default ConnectedAutomationCard;
