import 'intersection-observer';
import { RefObject, useState, useEffect, useLayoutEffect } from 'react';

/**
 * A React hook for the IntersectionObserver API that uses a polyfill
 * when the native API is not available The Intersection Observer API
 * provides a way to asynchronously observe changes in the intersection
 * of a target element with an ancestor element or with a top-level
 * document's viewport. The ancestor element or viewport is referred to
 * as the root.
 *
 * @usage
 * const Component = () => {
 *  const [ref, setRef] = React.useState()
 *  const {isIntersecting} = useIntersectionObserver(ref)
 *  return <div ref={setRef}>Is intersecting? {isIntersecting}</div>
 * }
 *
 * Forked from:
 * https://github.com/jaredLunde/react-hook/tree/master/packages/intersection-observer
 */
export function useIntersectionObserver<T extends HTMLElement = HTMLElement>(
  target: RefObject<T> | T | null,
  options: IntersectionObserverOptions = {},
): MockIntersectionObserverEntry | IntersectionObserverEntry {
  const {
    root = null,
    pollInterval = null,
    useMutationObserver = false,
    rootMargin = '0px 0px 0px 0px',
    threshold = 0,
    initialIsIntersecting = false,
  } = options;

  const [entry, setEntry] = useState<IntersectionObserverEntry | MockIntersectionObserverEntry>(
    () => ({
      boundingClientRect: null,
      intersectionRatio: 0,
      intersectionRect: null,
      isIntersecting: initialIsIntersecting,
      rootBounds: null,
      target: null,
      time: 0,
    }),
  );

  const [observer, setObserver] = useState(() =>
    getIntersectionObserver({
      root,
      pollInterval,
      useMutationObserver,
      rootMargin,
      threshold,
    }),
  );

  useEffect(() => {
    const observer = getIntersectionObserver({
      root,
      pollInterval,
      useMutationObserver,
      rootMargin,
      threshold,
    });
    setObserver(observer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [root, rootMargin, pollInterval, useMutationObserver, JSON.stringify(threshold)]);

  useLayoutEffect(() => {
    const targetEl = target && 'current' in target ? target.current : target;
    if (!observer || !targetEl) return;
    let didUnsubscribe = false;
    observer.observer.observe(targetEl as any);

    const callback = (entries: IntersectionObserverEntry[]) => {
      if (didUnsubscribe) return;

      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];
        if (entry.target === targetEl) {
          setEntry(entry);
        }
      }
    };

    observer.subscribe(callback);

    return () => {
      didUnsubscribe = true;
      observer.observer.unobserve(targetEl as any);
      observer.unsubscribe(callback);
    };
  }, [target, observer]);

  return entry;
}

function createIntersectionObserver({
  root = null,
  pollInterval = null,
  useMutationObserver = false,
  rootMargin = '0px 0px 0px 0px',
  threshold = 0,
}: IntersectionObserverOptions) {
  const callbacks: Set<IntersectionObserverCallback> = new Set();
  if (typeof IntersectionObserver === 'undefined') return null;
  const observer = new IntersectionObserver(
    (entries) => {
      callbacks.forEach((callback) => callback(entries, observer));
    },
    { root, rootMargin, threshold },
  );

  // @ts-ignore
  observer.POLL_INTERVAL = pollInterval;
  // @ts-ignore
  observer.USE_MUTATION_OBSERVER = useMutationObserver;

  return {
    observer,
    getListeners() {
      return callbacks;
    },
    subscribe: (callback: IntersectionObserverCallback) => callbacks.add(callback),
    unsubscribe: (callback: IntersectionObserverCallback) => callbacks.delete(callback),
  };
}

const _intersectionObserver: Map<
  HTMLElement | null | undefined,
  Record<string, ReturnType<typeof createIntersectionObserver>>
> = new Map();

function getIntersectionObserver(options: IntersectionObserverOptions) {
  const { root, ...keys } = options;
  const key = JSON.stringify(keys);
  let base = _intersectionObserver.get(root);
  if (!base) {
    base = {};
    _intersectionObserver.set(root, base);
  }
  return !base[key] ? (base[key] = createIntersectionObserver(options)) : base[key];
}

export type UseIntersectionObserverCallback = (
  entry: IntersectionObserverEntry,
  observer: IntersectionObserver,
) => any;

export interface IntersectionObserverOptions {
  /**
   * A specific ancestor of the target element being observed. If no value was passed
   * to the constructor or this is null, the top-level document's viewport is used
   */
  root?: HTMLElement | null;
  /**
   * The frequency in which the polyfill polls for intersection changes.
   */
  pollInterval?: number | null;
  /**
   * You can also choose to not check for intersections in the polyfill when the DOM
   * changes by setting this to `false`.
   */
  useMutationObserver?: boolean;
  /**
   * Margin around the root. Can have values similar to the CSS margin property, e.g.
   * "10px 20px 30px 40px" (top, right, bottom, left). The values can be percentages.
   * This set of values serves to grow or shrink each side of the root element's bounding
   * box before computing intersections.
   */
  rootMargin?: string;
  /**
   * Either a single number or an array of numbers which indicate at what percentage of
   * the target's visibility the observer's callback should be executed. If you only want
   * to detect when visibility passes the 50% mark, you can use a value of 0.5. If you want
   * the callback to run every time visibility passes another 25%, you would specify the
   * array [0, 0.25, 0.5, 0.75, 1]. The default is 0 (meaning as soon as even one pixel is
   * visible, the callback will be run). A value of 1.0 means that the threshold isn't
   * considered passed until every pixel is visible.
   */
  threshold?: number | number[];
  /**
   * Changes the default value of isIntersecting for use in places like SSR.
   */
  initialIsIntersecting?: boolean;
}

export interface IntersectionObserverBounds {
  readonly height: number;
  readonly width: number;
  readonly top: number;
  readonly left: number;
  readonly right: number;
  readonly bottom: number;
}

export interface MockIntersectionObserverEntry {
  readonly time: number | null;
  readonly rootBounds: IntersectionObserverBounds | null;
  readonly boundingClientRect: IntersectionObserverBounds | null;
  readonly intersectionRect: IntersectionObserverBounds | null;
  readonly intersectionRatio: number | null;
  readonly target: HTMLElement | null;
  readonly isIntersecting: boolean;
}

export default useIntersectionObserver;
