// prettier-ignore
export const AGENT_CALL_FAILURES: Record<string, string> = {
  analytics_failed: 'Encountered error while processing the analytics',
  busy: 'Sembly was unable to join your meeting: line was busy',
  chunking_completion_timeout: 'Encountered error while processing the audio',
  dc_meeting_access_denied: 'Sembly was unable to join your meeting: access denied',
  dc_no_such_meeting: 'Sembly was unable to join your meeting: invalid meeting link',
  dc_service_unavailable: 'We encountered a problem during your meeting recording',
  dc_unsupported_gmeet: 'This type of Google Meet meetings is not supported. Please change your GMeet service plan or use another conference platform',
  invalid_audio: 'Meeting audio is invalid',
  invalid_dial_in_info: 'Sembly was unable to join your meeting: invalid dial-in information',
  no_call_info: 'Sembly was unable to process the invite: no meeting link provided',
  not_admitted_from_lobby: 'Sembly was unable to join your meeting: not admitted in lobby',
  not_payed: 'Sembly really wanted to process the meeting, but you have used up your recording limit for this month. Upgrade now for unlimited usage',
  not_paid: 'Sembly really wanted to process the meeting, but you have used up your recording limit for this month. Upgrade now for unlimited usage',
  removed_from_lobby: 'Sembly was unable to join your meeting: denied in lobby',
  too_long_transcription: 'Meeting transcription is too long',
  too_short_duration: 'Sembly was unable to process your meeting: meeting audio is too short',
  too_long_duration: 'Sembly was unable to process your meeting: meeting audio is too long (no more than 5 hours required)',
  too_short_transcription: 'Meeting transcription is too short',
  transcription_failed: 'Encountered an error while transcribing the meeting',
  unexpected_error: 'Encountered unexpected error',
  unexpected_failure: 'We encountered a problem during your meeting recording',
  unsupported_gmeet_personal_event: 'Personal Google Meet meetings are not supported. Please upgrade your GMeet service plan or use another conference platform',
  ms_teams_call_prohibited: 'Unfortunately, Sembly cannot attend this meeting because the host organization prohibits meeting recording.',
  meeting_ratelimit_exceeded: 'Your account has reached the soft limit on the number of hours submitted. Please contact customer support'
} as const;

export default AGENT_CALL_FAILURES;
