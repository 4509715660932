/**
 * Stores user-related data in the GTM Data Layer.
 * These values persist across subsequent GTM events.
 */
export const set = (user: { userId: string; workspaceId: string }) => {
  if (!window.dataLayer) {
    console.warn('GTM is not available. User data not set.');
    return;
  }

  window.dataLayer.push({
    userId: user.userId,
    workspaceId: user.workspaceId,
  });
};
