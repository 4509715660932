import pluralize from 'pluralize';
import { useState, useEffect } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import PromotionIcon from '@material-ui/icons/LocalOffer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
// Sembly UI
import { normalizeCurrencyAmount } from '@sembly-ui';
// App Shared
import semblianPromotionImage from '@shared/assets/art-semblian-checkout.png';
import { BillingCycle } from '@shared/types';
import { GenericPaymentPlan } from '@gql-types/GenericPaymentPlan';
import { GenericProduct } from '@gql-types/GenericProduct';
import { GenericStripePromotionCode } from '@gql-types/GenericStripePromotionCode';
import { GenericWorkspace } from '@gql-types/GenericWorkspace';
import { ReactComponent as NewBadgeIcon } from '@shared/assets/art-new-badge.svg';
import { formatCurrencyAmount, calculateDiscountAmount } from '@shared/utils';
import { useUserContext } from '@shared/hooks';

export interface CheckoutDetailsProps {
  billingCycle: BillingCycle;
  currencyCode: string;
  paymentPlan: GenericPaymentPlan;
  product: GenericProduct | null;
  subscriptionPromotion?: GenericStripePromotionCode | null;
  semblianPromotion?: GenericStripePromotionCode | null;
  trialDurationDays?: number;
  verifyingSemblianPromotion?: boolean;
  verifyingSubscriptionPromotion?: boolean;
  workspace: GenericWorkspace;
  onApplySemblianPromotion?: (value: string) => void;
  onApplySubscriptionPromotion?: (value: string) => void;
  onChangePriceAddons?: (isIncludedSemblian: boolean) => void;
  onChangeSemblianPromotion?: (value: string) => void;
  onChangeSubscriptionPromotion?: (value: string) => void;
  onRemoveSemblianPromotion?: () => void;
  onRemoveSubscriptionPromotion?: () => void;
}

export const CheckoutDetails: React.VFC<CheckoutDetailsProps> = ({
  billingCycle,
  currencyCode,
  paymentPlan,
  product,
  subscriptionPromotion,
  semblianPromotion,
  trialDurationDays = 0,
  verifyingSemblianPromotion = false,
  verifyingSubscriptionPromotion = false,
  workspace,
  onApplySemblianPromotion,
  onApplySubscriptionPromotion,
  onChangePriceAddons = () => null,
  onChangeSemblianPromotion = () => null,
  onChangeSubscriptionPromotion = () => null,
  onRemoveSemblianPromotion = () => null,
  onRemoveSubscriptionPromotion = () => null,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const user = useUserContext();

  const [isIncludedSemblian, setIsIncludedSemblian] = useState(false);
  const [subscriptionPromoCode, setSubscriptionPromoCode] = useState('');
  const [showSubscriptionPromoCode, setShowSubscriptionPromoCode] = useState(false);
  const [semblianPromoCode, setSemblianPromoCode] = useState('');
  const [showSemblianPromoCode, setShowSemblianPromoCode] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleToggleSubscriptionPromotion = (visible: boolean) => () => {
    setShowSubscriptionPromoCode(visible);
    if (!visible) handleRemoveSubscriptionPromotion();
  };

  const handleApplySubscriptionPromotion = () => {
    if (!onApplySubscriptionPromotion) return;
    onApplySubscriptionPromotion(subscriptionPromoCode);
  };

  const handleRemoveSubscriptionPromotion = () => {
    onRemoveSubscriptionPromotion();
    setSubscriptionPromoCode('');
    setShowSubscriptionPromoCode(false);
  };

  const handleUpdateSubscriptionPromotionCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionPromoCode(e.target.value);
    onChangeSubscriptionPromotion && onChangeSubscriptionPromotion(e.target.value);
  };

  const handleSubmitSubscriptionPromotionCode = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleApplySubscriptionPromotion();
  };

  const handleToggleSemblianPromotion = (visible: boolean) => () => {
    setShowSemblianPromoCode(visible);
    if (!visible) handleRemoveSemblianPromotion();
  };

  const handleApplySemblianPromotion = () => {
    if (!onApplySemblianPromotion) return;
    onApplySemblianPromotion(semblianPromoCode);
  };

  const handleRemoveSemblianPromotion = () => {
    onRemoveSemblianPromotion();
    setSemblianPromoCode('');
    setShowSemblianPromoCode(false);
  };

  const handleUpdateSemblianPromotionCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSemblianPromoCode(e.target.value);
    onChangeSemblianPromotion && onChangeSemblianPromotion(e.target.value);
  };

  const handleSubmitSemblianPromotionCode = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    handleApplySemblianPromotion();
  };

  const handleToggleIncludeSemblian = (value: boolean) => () => {
    setIsIncludedSemblian(value);
    onChangePriceAddons(value);
  };
  /* #endregion */

  const renderStarIcon = (fillColor: string) => (
    <svg
      fill="none"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
    >
      <path
        fill={fillColor}
        d="M10.5 6.18311L8 0.683105L5.5 6.18311L0 8.68311L5.5 11.1831L8 16.6831L10.5 11.1831L16 8.68311L10.5 6.18311Z"
      />
    </svg>
  );

  /* #region  Render Helpers */
  const isSemblianActivated = !!user?.data?.me?.paymentCustomer?.aiAssociateSubscription;
  const billingName = billingCycle === 'monthly' ? billingCycle : `${billingCycle}ly`;
  const numUsers = workspace.numUsers || 1;
  const priceOptions = paymentPlan.priceOptions[billingCycle].currencyOptions;
  const priceOption = priceOptions.find(
    ({ currency }) => currency.toLowerCase() === currencyCode.toLowerCase(),
  );
  const priceAmount = normalizeCurrencyAmount(priceOption?.amount || 0, currencyCode);
  const priceFormattedAmount = formatCurrencyAmount(priceAmount, currencyCode);
  const subtotalValue = numUsers * priceAmount;

  const subscriptionDiscountName = subscriptionPromotion?.name || '';
  const subscriptionDiscountPercent = subscriptionPromotion?.percentOff || 0;
  const subscriptionDiscountOption = subscriptionPromotion?.currencyOptions.find(
    ({ currency }) => currency.toLowerCase() === currencyCode.toLowerCase(),
  );
  const subscriptionDiscountValue = normalizeCurrencyAmount(
    subscriptionDiscountOption?.amount || 0,
    currencyCode,
  );

  const hasSubscriptionDiscount = !!subscriptionDiscountPercent || !!subscriptionDiscountValue;
  const subscriptionDiscountAmount = calculateDiscountAmount(
    subscriptionDiscountPercent,
    subscriptionDiscountValue,
    subtotalValue,
    currencyCode,
  );
  const subscriptionDiscount = hasSubscriptionDiscount
    ? `-${formatCurrencyAmount(subscriptionDiscountAmount, currencyCode)}`
    : '-';

  const productPriceOptions = product?.priceOptions?.[billingCycle].currencyOptions;
  const productPriceOption = productPriceOptions?.find(
    ({ currency }) => currency.toLowerCase() === currencyCode.toLowerCase(),
  );
  const semblianAmountValue = normalizeCurrencyAmount(
    productPriceOption?.amount || 0,
    currencyCode,
  );
  const semblianAmount = formatCurrencyAmount(semblianAmountValue, currencyCode);

  const semblianDiscountName = semblianPromotion?.name || '';
  const semblianDiscountPercent = semblianPromotion?.percentOff || 0;
  const semblianDiscountOption = semblianPromotion?.currencyOptions.find(
    ({ currency }) => currency.toLowerCase() === currencyCode.toLowerCase(),
  );
  const semblianDiscountValue = normalizeCurrencyAmount(
    semblianDiscountOption?.amount || 0,
    currencyCode,
  );

  const hasSemblianDiscount = !!semblianDiscountPercent || !!semblianDiscountValue;
  const semblianDiscountAmount = calculateDiscountAmount(
    semblianDiscountPercent,
    semblianDiscountValue,
    semblianAmountValue,
    currencyCode,
  );
  const semblianDiscount = hasSemblianDiscount
    ? `-${formatCurrencyAmount(semblianDiscountAmount, currencyCode)}`
    : '-';

  const subtotal = formatCurrencyAmount(subtotalValue, currencyCode);

  const team = numUsers > 1 ? `${numUsers} ${pluralize('member', numUsers)}` : '1 member';
  const trialDurationText = `${trialDurationDays} ${pluralize('day', trialDurationDays)}`;
  const subtotalDescription = `${trialDurationDays}-day trial`;

  const description = paymentPlan.forTeams
    ? `${team}, ${priceFormattedAmount} per member, billed ${billingName}`
    : `${priceFormattedAmount}, billed ${billingName}`;

  const descriptionForTrial = paymentPlan.forTeams
    ? `${team}, ${priceFormattedAmount} per member, billed ${billingName} after ${trialDurationText} trial`
    : `${priceFormattedAmount}, billed ${billingName} after ${trialDurationText} trial`;

  let totalValue = subtotalValue - subscriptionDiscountAmount;
  if (isIncludedSemblian) totalValue += semblianAmountValue;
  if (isIncludedSemblian && hasSemblianDiscount) totalValue = totalValue - semblianDiscountAmount;

  const total = formatCurrencyAmount(totalValue <= 0 ? 0 : totalValue, currencyCode);
  /* #endregion */

  /* #region  Effects */
  useEffect(() => {
    if (hasSubscriptionDiscount && showSubscriptionPromoCode) {
      setShowSubscriptionPromoCode(false);
    }
  }, [hasSubscriptionDiscount, showSubscriptionPromoCode]);

  useEffect(() => {
    if (hasSemblianDiscount && showSemblianPromoCode) {
      setShowSemblianPromoCode(false);
    }
  }, [hasSemblianDiscount, showSemblianPromoCode]);
  /* #endregion */

  return (
    <>
      <Typography paragraph component="div" variant="h6" align="left">
        Your plan
      </Typography>
      <Paper square elevation={0} className={styles.card}>
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography gutterBottom variant="h6">
                  {paymentPlan.name}
                </Typography>
              }
              secondary={
                <Box component="span" display="block" maxWidth={isSmallScreen ? 250 : 'auto'}>
                  {!!trialDurationDays ? descriptionForTrial : description}
                </Box>
              }
            />
            <ListItemSecondaryAction>
              {!!trialDurationDays ? (
                <Box textAlign="right">
                  <Typography gutterBottom variant="subtitle1">
                    <b>{subtotal}</b>
                  </Typography>
                  <Typography variant="body2">{subtotalDescription}</Typography>
                </Box>
              ) : (
                <Typography variant="subtitle1">
                  <b>{subtotal}</b>
                </Typography>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="middle" component="li" />
          <ListItem>
            <ListItemText
              primary="Promotion code"
              secondary={
                hasSubscriptionDiscount ? (
                  <Box component="span" display="flex" alignItems="center" maxWidth="70%">
                    <PromotionIcon color="primary" fontSize="small" />
                    <Box component="span" mr={1} />
                    <Typography component="span" variant="body2" color="textSecondary">
                      {subscriptionPromotion?.code} - {subscriptionDiscountName}
                    </Typography>
                    <Box component="span" mr={1} />
                    <Link onClick={handleRemoveSubscriptionPromotion}>
                      <Typography component="span" variant="body2">
                        Delete
                      </Typography>
                    </Link>
                  </Box>
                ) : null
              }
            />
            {!showSubscriptionPromoCode && (
              <ListItemSecondaryAction>
                {hasSubscriptionDiscount ? (
                  <Typography variant="subtitle1">{subscriptionDiscount}</Typography>
                ) : (
                  <Link onClick={handleToggleSubscriptionPromotion(true)}>
                    <Typography variant="button">Add</Typography>
                  </Link>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
          {!hasSubscriptionDiscount && showSubscriptionPromoCode && (
            <ListItem>
              <TextField
                fullWidth
                autoFocus
                size="small"
                variant="filled"
                disabled={verifyingSubscriptionPromotion}
                InputProps={{
                  endAdornment: !!subscriptionPromoCode && !verifyingSubscriptionPromotion && (
                    <InputAdornment position="end">
                      <Link onClick={handleApplySubscriptionPromotion}>
                        <Typography variant="button">Apply</Typography>
                      </Link>
                    </InputAdornment>
                  ),
                }}
                value={subscriptionPromoCode}
                onChange={handleUpdateSubscriptionPromotionCode}
                onKeyDown={handleSubmitSubscriptionPromotionCode}
              />
              <Box mr={1} />
              <Button
                variant="outlined"
                disabled={verifyingSubscriptionPromotion}
                style={{ minWidth: 80 }}
                aria-label="Cancel"
                onClick={handleToggleSubscriptionPromotion(false)}
              >
                {verifyingSubscriptionPromotion ? (
                  <CircularProgress size={24} />
                ) : (
                  <span>Cancel</span>
                )}
              </Button>
            </ListItem>
          )}
        </List>
      </Paper>

      {!isSemblianActivated && (
        <Box mt={4}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridGap={12}
            mb={2}
          >
            <Typography component="div" variant="h6" align="left">
              Power-up Your Subscription
            </Typography>
            {isIncludedSemblian && (
              <Button variant="outlined" onClick={handleToggleIncludeSemblian(false)}>
                <Typography noWrap component="span" variant="body2">
                  Remove
                </Typography>
              </Button>
            )}
          </Box>

          {!!product && (
            <>
              {isIncludedSemblian ? (
                <Paper square elevation={0} className={styles.card}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography gutterBottom variant="h6">
                            Semblian 2.0
                          </Typography>
                        }
                        secondary={`${semblianAmount} per license, billed ${billingName} after 7-day risk-free trial`}
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="subtitle1">
                          <b>{semblianAmount}</b>
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider variant="middle" component="li" />
                    <ListItem>
                      <ListItemText
                        primary="Promotion code"
                        secondary={
                          hasSemblianDiscount ? (
                            <Box component="span" display="flex" alignItems="center" maxWidth="70%">
                              <PromotionIcon color="primary" fontSize="small" />
                              <Box component="span" mr={1} />
                              <Typography component="span" variant="body2" color="textSecondary">
                                {semblianPromotion?.code} - {semblianDiscountName}
                              </Typography>
                              <Box component="span" mr={1} />
                              <Link onClick={handleRemoveSemblianPromotion}>
                                <Typography component="span" variant="body2">
                                  Delete
                                </Typography>
                              </Link>
                            </Box>
                          ) : null
                        }
                      />
                      {!showSemblianPromoCode && (
                        <ListItemSecondaryAction>
                          {hasSemblianDiscount ? (
                            <Typography variant="subtitle1">{semblianDiscount}</Typography>
                          ) : (
                            <Link onClick={handleToggleSemblianPromotion(true)}>
                              <Typography variant="button">Add</Typography>
                            </Link>
                          )}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                    {!hasSemblianDiscount && showSemblianPromoCode && (
                      <ListItem>
                        <TextField
                          fullWidth
                          autoFocus
                          size="small"
                          variant="filled"
                          disabled={verifyingSemblianPromotion}
                          InputProps={{
                            endAdornment: !!semblianPromoCode && !verifyingSemblianPromotion && (
                              <InputAdornment position="end">
                                <Link onClick={handleApplySemblianPromotion}>
                                  <Typography variant="button">Apply</Typography>
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                          value={semblianPromoCode}
                          onChange={handleUpdateSemblianPromotionCode}
                          onKeyDown={handleSubmitSemblianPromotionCode}
                        />
                        <Box mr={1} />
                        <Button
                          variant="outlined"
                          disabled={verifyingSemblianPromotion}
                          style={{ minWidth: 80 }}
                          aria-label="Cancel"
                          onClick={handleToggleSemblianPromotion(false)}
                        >
                          {verifyingSemblianPromotion ? (
                            <CircularProgress size={24} />
                          ) : (
                            <span>Cancel</span>
                          )}
                        </Button>
                      </ListItem>
                    )}
                  </List>
                </Paper>
              ) : (
                <Paper square elevation={0} className={styles.promo}>
                  <img src={semblianPromotionImage} alt="Semblian 2.0" width="100%" />
                  <Box px={2} pb={2} textAlign="left">
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Box display="flex" alignItems="center" gridGap={8} mt={3} mb={1}>
                              <Typography variant="h6">Meet Semblian 2.0</Typography>
                              <NewBadgeIcon />
                            </Box>
                          }
                          secondary={`${semblianAmount} per license, billed ${billingName} after 7-day risk-free trial`}
                        />
                      </ListItem>
                    </List>
                    <Box px={2} pb={2} textAlign="left">
                      <Typography variant="body1">
                        {renderStarIcon('#FFD700')}
                        <b>AI artifacts:</b> Automate Your Deliverables
                      </Typography>
                      <Typography variant="body1">
                        {renderStarIcon('#009996')}
                        <b>AI Insights:</b> Personalized Next-Steps
                      </Typography>
                      <Typography variant="body1">
                        {renderStarIcon('#576ACE')}
                        <b>Multi-Meeting Chats:</b> AI Chat Across Multiple Meetings
                      </Typography>
                    </Box>
                    <Box textAlign="right">
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleToggleIncludeSemblian(true)}
                      >
                        <Typography noWrap component="span" variant="body1">
                          Add Semblian 2.0
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              )}
            </>
          )}
        </Box>
      )}

      <Box mt={4}>
        <Paper square elevation={0} className={styles.card}>
          <List>
            <ListItem>
              <ListItemText primary={<Typography variant="h6">Total</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle1">
                  <b>{total}</b>
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  promo: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  icon: {
    position: 'relative',
    top: 3,
    marginRight: theme.spacing(1),
  },
}));

export default CheckoutDetails;
