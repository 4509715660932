import { useState, useEffect, useCallback } from 'react';

export const SCROLL_TARGET_STORAGE_KEY = 'meeting_list_scroll_target';
export const START_DATE_STORAGE_KEY = 'meeting_list_start_date';

/**
 * Custom hook to manage the meeting scroll target for navigation in a meeting list.
 * Stores and retrieves the meeting ID and start date to sessionStorage.
 */
export function useMeetingScrollTarget() {
  const [meetingListScrollTarget, setMeetingListScrollTarget] = useState(() =>
    sessionStorage.getItem(SCROLL_TARGET_STORAGE_KEY),
  );

  const [meetingListStartDate, setMeetingListStartDate] = useState(() =>
    sessionStorage.getItem(START_DATE_STORAGE_KEY),
  );

  const updateMeetingScrollTarget = useCallback((id: string) => {
    if (id) {
      sessionStorage.setItem(SCROLL_TARGET_STORAGE_KEY, id);
      setMeetingListScrollTarget(id);
    }
  }, []);

  const updateMeetingListStartDate = useCallback((date: string) => {
    if (date) {
      sessionStorage.setItem(START_DATE_STORAGE_KEY, date);
      setMeetingListStartDate(date);
    }
  }, []);

  const resetMeetingScrollSettings = useCallback(
    (type: 'all' | 'scroll-target' | 'start-date' = 'all') => {
      if (type === 'all' || type === 'scroll-target') {
        sessionStorage.removeItem(SCROLL_TARGET_STORAGE_KEY);
        setMeetingListScrollTarget(null);
      }
      if (type === 'all' || type === 'start-date') {
        sessionStorage.removeItem(START_DATE_STORAGE_KEY);
        setMeetingListStartDate(null);
      }
    },
    [],
  );

  useEffect(() => {
    setMeetingListScrollTarget(sessionStorage.getItem(SCROLL_TARGET_STORAGE_KEY));
    setMeetingListStartDate(sessionStorage.getItem(START_DATE_STORAGE_KEY));
  }, []);

  return {
    meetingListStartDate,
    meetingListScrollTarget,
    updateMeetingScrollTarget,
    updateMeetingListStartDate,
    resetMeetingScrollSettings,
  };
}

export default useMeetingScrollTarget;
