import { FC, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Menu,
  Grow,
  MenuItem,
  IconButton,
  Paper,
  Popper,
  ClickAwayListener,
} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// Icons
import LinkIcon from '@material-ui/icons/LinkSharp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReplyIcon from '@material-ui/icons/Reply';

/* #region  Component Types */
export interface CopyPopoverProps {
  onClickOnCopyLink: () => void;
  onCopyToClipboard: () => void;
}
/* #endregion */

export const CopyPopover: FC<CopyPopoverProps> = ({
  onClickOnCopyLink = () => null,
  onCopyToClipboard = () => null,
}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOnCopyLink = () => {
    onClickOnCopyLink();
    handleClose();
  };

  const handleCopyToClipboard = () => {
    onCopyToClipboard();
    handleClose();
  };

  return (
    <div>
      <IconButton
        size="small"
        title="Share"
        ref={anchorRef}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ReplyIcon className={styles.iconCopy} fontSize="small" />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Menu
                  className={styles.paper}
                  id="basic-menu"
                  open={open}
                  anchorEl={anchorRef.current}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className={styles.item} onClick={handleClickOnCopyLink}>
                    <ListItemIcon>
                      <LinkIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copy link</ListItemText>
                  </MenuItem>
                  <MenuItem className={styles.item} onClick={handleCopyToClipboard}>
                    <ListItemIcon>
                      <FileCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copy content</ListItemText>
                  </MenuItem>
                </Menu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    width: '190px',
  },
  paper: {
    marginTop: 45,
  },
  iconCopy: {
    transform: 'scale(-1, 1)',
  },
}));

export default CopyPopover;
