import { DataSchema } from '@integration-app/sdk';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
// Material UI
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import workspaceTeamsQuery from '../graphql/queries/WorkspaceTeamList.graphql';
import workspaceUsersQuery from '../graphql/queries/MyWorkspaceUsers.graphql';
import { WorkspaceTeamList, MyWorkspaceUsers, GenericDefaultUser } from '../types';
import { GenericDialog, Tags } from '../components';
import {
  AUTOMATION_FILTER_TYPES,
  WORKSPACE_AUTOMATION_FILTER_TYPES,
  MEETING_TYPES,
} from '../constants';

export interface ManageThirdPartyWorkspaceAutomationFilterDialogProps {
  open: boolean;
  containerType: 'workspace' | 'personal';
  values: Record<string, string> | null;
  parametersSchema: DataSchema | null;
  onClose: () => void;
  onChange: (data: {
    rule: string;
    keywords?: string[];
    meetingType?: string;
    ownerId?: number;
    teamId?: number;
  }) => void;
}

/**
 * Dialog that displays all available filters that can be used to create an automation.
 */
export const ManageThirdPartyWorkspaceAutomationFilterDialog: React.VFC<
  ManageThirdPartyWorkspaceAutomationFilterDialogProps
> = ({ open, containerType, values, parametersSchema, onChange, onClose }) => {
  const styles = useStyles();

  const [keywords, setKeywords] = useState<string[]>(
    (values?.keywords as unknown as string[]) || [],
  );

  const { data: workspaceTeamsData, loading: loadingWorkspaceTeams } = useQuery<WorkspaceTeamList>(
    workspaceTeamsQuery,
    { skip: values?.rule !== 'FILTERED_BY_TEAM' },
  );

  const { data: workspaceUsersData, loading: loadingWorkspaceUsers } = useQuery<MyWorkspaceUsers>(
    workspaceUsersQuery,
    { skip: values?.rule !== 'FILTERED_BY_OWNER' },
  );

  const handleChangeRule = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    onChange({ rule: newValue });
  };

  const handleChangeMeetingType = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    onChange({ rule: 'FILTERED_BY_MEETING_TYPE', meetingType: newValue });
  };

  const handleChangeTeam = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as number;
    onChange({ rule: 'FILTERED_BY_TEAM', teamId: newValue });
  };

  const handleChangeUser = (event: unknown, newValue: GenericDefaultUser | string | null) => {
    if (typeof newValue === 'string') return; // type guard
    onChange({ rule: 'FILTERED_BY_OWNER', ownerId: newValue?.id ? +newValue.id : undefined });
  };

  const handleChangeTags = async (event: unknown, allTagsWithNewOne: (string | string[])[]) => {
    const tags = allTagsWithNewOne.map((tag) => tag.toString());
    setKeywords(tags);
    onChange({ rule: 'FILTERED_BY_KEYWORDS', keywords: tags });
  };

  const handleDeleteTag = async (itemToDelete: string) => {
    const value = keywords.filter((item) => item !== itemToDelete);
    setKeywords(value);
    onChange({ rule: 'FILTERED_BY_KEYWORDS', keywords: value });
  };

  /* #region  Render Helpers */
  const rules = parametersSchema?.properties?.rule?.enum || [];

  let options: { label: string; value: string }[] = [];

  rules.forEach((key) => {
    const label =
      containerType === 'workspace'
        ? WORKSPACE_AUTOMATION_FILTER_TYPES[key]
        : AUTOMATION_FILTER_TYPES[key];
    if (!label) return null;
    options.push({ label, value: key });
  });

  // Convert MEETING_TYPES to array save, category => isCategory true;
  const meetingTypeOptions: { label: string; value: string | null; isCategory: boolean }[] =
    MEETING_TYPES.flatMap(({ category, elements }) => [
      { label: category, value: null, isCategory: true },
      ...elements.map((element) => ({
        label: element.label,
        value: element.value,
        isCategory: false,
      })),
    ]);

  const users = workspaceUsersData?.myWorkspace?.users || [];
  /* #endregion */

  return (
    <GenericDialog
      title="Filter meetings"
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm' }}
      onClose={onClose}
    >
      <DialogContent>
        <TextField
          select
          fullWidth
          size="small"
          variant="outlined"
          label="Automation Rule"
          value={values?.rule || ''}
          onChange={handleChangeRule}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
        <Box mt={2}>
          {values?.rule === 'FILTERED_BY_KEYWORDS' && (
            <Tags
              all={keywords}
              onChangeTags={handleChangeTags}
              onDelete={handleDeleteTag}
              InputProps={{
                variant: 'outlined',
                helperText: 'Press Enter after typing a keyword to add a new tag.',
              }}
            />
          )}
          {values?.rule === 'FILTERED_BY_TEAM' && (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Team"
              disabled={loadingWorkspaceTeams}
              value={values?.teamId || ''}
              onChange={handleChangeTeam}
              InputProps={{
                endAdornment: loadingWorkspaceTeams && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ),
              }}
            >
              {workspaceTeamsData?.teams.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
              {workspaceTeamsData?.teams.length === 0 && (
                <MenuItem disabled>No teams available</MenuItem>
              )}
            </TextField>
          )}
          {values?.rule === 'FILTERED_BY_OWNER' && (
            <Autocomplete
              fullWidth
              size="small"
              options={users}
              loading={loadingWorkspaceUsers}
              disabled={loadingWorkspaceUsers}
              getOptionLabel={(item) => item.fullName}
              // eslint-disable-next-line eqeqeq
              value={users.find((item) => item.id == values?.ownerId) || null}
              onChange={handleChangeUser}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Owner"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingWorkspaceUsers ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
          {values?.rule === 'FILTERED_BY_MEETING_TYPE' && (
            <TextField
              select
              fullWidth
              size="small"
              variant="outlined"
              label="Meeting Type"
              value={values?.meetingType || ''}
              onChange={handleChangeMeetingType}
            >
              {meetingTypeOptions.map((item, index) =>
                item.isCategory ? (
                  <ListSubheader key={index} classes={{ root: styles.listSubHeaderRoot }}>
                    {item.label}
                  </ListSubheader>
                ) : (
                  <MenuItem
                    key={index}
                    value={item.value || ''}
                    selected={item.value === values?.meetingType}
                  >
                    {item.label}
                  </MenuItem>
                ),
              )}
            </TextField>
          )}
        </Box>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          disableElevation
          color="primary"
          variant="contained"
          disabled={!values}
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2, 3, 3),
  },
  listSubHeaderRoot: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

export default ManageThirdPartyWorkspaceAutomationFilterDialog;
