import random from 'lodash/random';
import { memo } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

export interface MeetingCardSkeletonProps {
  count?: number;
}

export const MeetingCardSkeleton: React.VFC<MeetingCardSkeletonProps> = memo(({ count = 1 }) => {
  const styles = useStyles();
  const cards = [...Array(count)];

  return (
    <>
      {cards.map((_, id) => (
        <div key={id} className={styles.root}>
          <Box flex="1">
            <Skeleton
              variant="rect"
              animation="wave"
              height={24}
              width={`${random(35, 65)}%`}
              className={styles.skeleton}
            />
            <Box mt={2} />
            <Skeleton
              variant="rect"
              animation="wave"
              height={18}
              width={`${random(18, 30)}%`}
              className={styles.skeleton}
            />
          </Box>
          <Skeleton variant="circle" animation="wave" height={30} width={30} />
        </div>
      ))}
    </>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(2),
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: 8,
    boxShadow: theme.shadows[1],
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius,
  },
}));

export default MeetingCardSkeleton;
