// Material UI
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { TransitionSlide } from '@sembly-ui';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
// App Shared
import LimitExceededMessage from '@shared/dialogs/LimitExceededMessage';

export interface RecordingProhibitedDialogProps {
  onClose: () => void;
}

export const RecordingProhibitedDialog: React.VFC<RecordingProhibitedDialogProps> = ({
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));
  /* #endregion */

  return (
    <Dialog
      open
      keepMounted
      fullWidth
      fullScreen={isSmallScreen}
      maxWidth="sm"
      scroll="paper"
      TransitionComponent={TransitionSlide}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={styles.title}>
        <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        textAlign="center"
        px={4}
        py={4}
        pt={0}
      >
        <LimitExceededMessage onClose={onClose}>
          You've exceeded your time limit of Sembly audio recordings.
          <br />
          Upgrade now to record audio without limitations.
        </LimitExceededMessage>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default RecordingProhibitedDialog;
