import { useLocation, useRouteMatch } from 'react-router-dom';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Box, Grid, Toolbar, Typography, Link } from '@material-ui/core';
// App Shared Assets
import genericAuthImage from '@shared/assets/sign-generic-background.webp';
import layoutBcg from '@shared/assets/generic-gradient-background.webp';
import logoPhilips from '@shared/assets/logo-philips.svg';
import logoSembly from '@shared/assets/logo.svg';
import authPhillipsImage from '@shared/assets/auth-philips.png';
import smartMeetingDeviceImage from '@shared/assets/smartmeeting-device.png';
import smartMeetingImage from '@shared/assets/smartmeeting-sign.png';
import smartMikeImage from '@shared/assets/smartmike-sign.png';
// App Shared
import { APP_NAME } from '@shared/configuration';
import { Routes } from '@shared/enums';
import { useUserInterface } from '@shared/hooks';

export const SignPageLayout: React.FC = ({ children }) => {
  const styles = useStyles();
  const location = useLocation();
  const { isPhillipsUser } = useUserInterface();

  /* #region  Render Helpers */
  const searchParams = new URLSearchParams(location.search);
  const isSmartMikeUserSignUp = useRouteMatch(Routes.SignUpSmartMikeUser);
  const isPhilipsDeviceOnboarding = useRouteMatch(Routes.OnboardingPhilipsDevice);
  const isSmartMeetingUserSignUp = useRouteMatch(Routes.SignUpSmartMeetingUser);
  const isSmartMikeOnboarding = searchParams.get('usertype') === 'smartmike';
  const isSmartMeetingOnboarding = searchParams.get('usertype') === 'smartmeeting';
  const isSmartMikeUser = isSmartMikeUserSignUp || isSmartMikeOnboarding;
  const isSmartMeetingUser = isSmartMeetingUserSignUp || isSmartMeetingOnboarding;

  // background image
  const getAccentImage = () => {
    const commonProps: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    > = { loading: 'lazy' };

    if (isPhillipsUser) {
      return (
        <img
          {...commonProps}
          src={authPhillipsImage}
          alt="Philips Onboarding"
          data-testid="philips"
          style={{ objectFit: 'contain', maxWidth: '584px', width: '70%', height: '70%' }}
        />
      );
    }

    if (isPhilipsDeviceOnboarding) {
      return (
        <img
          {...commonProps}
          src={smartMeetingDeviceImage}
          alt="Philips Onboarding"
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      );
    }

    if (isSmartMikeUser) {
      return (
        <img
          {...commonProps}
          src={smartMikeImage}
          alt="SmartMike User Authenticating"
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      );
    }

    if (isSmartMeetingUser) {
      return (
        <img
          {...commonProps}
          src={smartMeetingImage}
          alt="SmartMeeting User Authenticating"
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      );
    }

    return (
      <img
        {...commonProps}
        src={genericAuthImage}
        alt="Sembly Authenticating"
        style={{ objectFit: 'contain', maxWidth: '800px', width: '70%', height: '70%' }}
      />
    );
  };
  /* #endregion */

  return (
    <Grid container className={styles.root}>
      <Grid item xs={12} lg={6} className={styles.primary}>
        <AppBar className={styles.appBar} position="static" color="transparent">
          <Toolbar>
            <Link href="https://www.sembly.ai" target="_blank" rel="noopener noreferrer">
              <img src={logoSembly} alt={APP_NAME} width={148} height={24} />
            </Link>
            {(isSmartMikeUser || isSmartMeetingUser || isPhillipsUser) && (
              <Box flex={1} ml={6} textAlign="right">
                <img src={logoPhilips} alt="Philips" style={{ marginTop: 5, height: 16 }} />
              </Box>
            )}
          </Toolbar>
        </AppBar>
        <div className={styles.content}>{children}</div>
        <Typography variant="body2" className={styles.terms}>
          <span>By using Sembly you agree to the </span>
          <Link
            href="https://go.sembly.ai/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          <span> and </span>
          <Link
            href="https://go.sembly.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
      <Grid
        item
        xs={false}
        lg={6}
        className={styles.secondary}
        data-testid={isSmartMikeUser ? 'smartmike' : 'basic'}
      >
        {getAccentImage()}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    boxShadow: 'none',
    flex: 0,
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      paddingTop: 24,
    },
  },
  primary: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2),
  },
  secondary: {
    height: '100%',
    backgroundColor: '#1D202B',
    backgroundImage: `url(${layoutBcg})`,
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  terms: {
    flex: 0,
    textAlign: 'center',
  },
}));

export default SignPageLayout;
