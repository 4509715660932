import { Theme } from '@material-ui/core';
// Material Icons
import ImageIcon from '@material-ui/icons/Image';
import SubjectIcon from '@material-ui/icons/Subject';
// Lib Shared
import PDFIcon from '../../icons/PdfType';
import { ChatAnswerFileTypes } from '../../types';

export const getChatArtefactMeta = (type: ChatAnswerFileTypes | null, theme: Theme) => {
  switch (type) {
    case ChatAnswerFileTypes.IMAGE:
      return {
        type: 'Image',
        icon: <ImageIcon fontSize="inherit" />,
        color: theme.palette.accents.greenHawaii1000,
      };
    case ChatAnswerFileTypes.PDF:
      return {
        type: 'PDF',
        icon: <PDFIcon fontSize="inherit" />,
        color: theme.palette.accents.pinkDream1200,
      };
    case ChatAnswerFileTypes.TEXT:
      return {
        type: 'Text',
        icon: <SubjectIcon fontSize="inherit" />,
        color: theme.palette.accents.purpleHaze1300,
      };
    default:
      return null;
  }
};

export default getChatArtefactMeta;
