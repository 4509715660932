import { useMutation } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// Material Icons
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
// Sembly UI
import { GenericDialog } from '@sembly-ui';
// GraphQL Queries and Types
import ChangeEntryVideoMutation from '@shared/queries/ChangeEntryVideoStatus.graphql';
import { ChangeEntryVideoStatus, ChangeEntryVideoStatusVariables } from '@gql-types';
// App Shared
import GetAppIcon from '@shared/icons/LightningBolt';
import { useUserContext, useUserInterface } from '@shared/hooks';
import { RESOURCE_CENTER_ELEMENTS } from '@shared/constants';
import { graphErrorHorsemen } from '@shared/utils';

export const ShowVideoOnboardingDialog: React.VFC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useUserContext();
  const { isOpenIntroduction: isOpenGettingStarted, update } = useUserInterface();

  const [changeEntryVideoStatus] = useMutation<
    ChangeEntryVideoStatus,
    ChangeEntryVideoStatusVariables
  >(ChangeEntryVideoMutation);

  /* #region  Handlers */
  const handleChangeEntryVideoStatus = async () => {
    const { data: responseData } = await changeEntryVideoStatus({
      update: (cache) => {
        cache.modify({
          id: cache.identify({ __typename: 'DetailedUserType', id: user.data?.me?.id }),
          fields: {
            showEntryVideo: () => !user.data?.me?.showEntryVideo,
          },
        });
      },
    });

    if (!responseData?.changeEntryVideoStatus?.success) {
      graphErrorHorsemen(responseData?.changeEntryVideoStatus?.errors);
    }
  };

  const handleClose = (
    e?: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    update({ isOpenIntroduction: false });

    const isShowEntryVideo = user.data?.me?.showEntryVideo ?? false;
    if (isShowEntryVideo) {
      e?.stopPropagation();
      handleChangeEntryVideoStatus();
    }
  };

  const handleOpenRc = () => {
    handleClose();
    update({ isOpenResourceCenter: true });
  };

  const handleOpenTutorials = () => {
    handleClose();
    update({ isOpenTutorial: true });
  };
  /* #endregion */

  const renderPromotedPlatforms = () => (
    <Box display="flex">
      {RESOURCE_CENTER_ELEMENTS.promotedPlatforms.map(({ icon, link, text }, index) => (
        <Box key={text} flex="1" mx={index === 1 ? 1 : 0}>
          <Button
            fullWidth
            disableElevation
            key={index}
            href={link}
            title={text}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Open "${text}"`}
            className={styles.platformBtn}
          >
            {icon}
          </Button>
        </Box>
      ))}
    </Box>
  );

  return (
    <GenericDialog
      hideTitle
      dialogProps={{
        open: isOpenGettingStarted,
        fullWidth: true,
        maxWidth: 'md',
        classes: { paper: styles.paper },
        fullScreen: isSmallScreen,
      }}
      onClose={handleClose}
    >
      <div className={styles.root}>
        <Box
          mb={2}
          flexDirection="row"
          alignItems="center"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="h4">Welcome to Sembly - the smartest AI Teammate!</Typography>
          <IconButton size="small" color="inherit" aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'}>
          <Box flex={isSmallScreen ? '12' : '5'}>
            <iframe
              className={styles.video}
              width="100%"
              height={isSmallScreen ? '240px' : '100%'}
              src="https://www.youtube.com/embed/OR5prcCBNsI"
              title="YouTube"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            flex={isSmallScreen ? '12' : '3'}
            mt={isSmallScreen ? 2 : 0}
            ml={isSmallScreen ? 0 : 3}
          >
            <Box className={`${styles.box} ${styles.cursor}`} onClick={handleOpenTutorials}>
              <Typography gutterBottom className={styles.boxTitle}>
                <span className={styles.titleIcon}>
                  <PlayCircleOutlineIcon color="primary" />
                </span>
                <span>Sembly Tutorials</span>
              </Typography>
              <Typography className={styles.boxSecondary} variant="body1">
                Watch short tutorials and become a Sembly Pro user in 10 minutes
              </Typography>
            </Box>
            <Box my={2} className={`${styles.box} ${styles.cursor}`} onClick={handleOpenRc}>
              <Typography gutterBottom className={styles.boxTitle}>
                <span className={`${styles.titleIcon} ${styles.helpCenterIcon}`}>
                  <HelpOutlineIcon />
                </span>
                <span>Resource Center</span>
              </Typography>
              <Typography className={styles.boxSecondary} variant="body1">
                Get support, watch tutorials, and discover Pro tips in the Resource Center
              </Typography>
            </Box>
            <Box className={styles.box}>
              <Typography gutterBottom className={styles.boxTitle}>
                <span className={`${styles.titleIcon} ${styles.getAppIcon}`}>
                  <GetAppIcon />
                </span>
                <span>Get Sembly Apps</span>
              </Typography>
              <Box mt={1} className={styles.boxSecondary}>
                {renderPromotedPlatforms()}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box mt={4} className={styles.box}>
          <Typography gutterBottom className={styles.boxTitle}>
            <span className={styles.titleIcon}>
              <PlayCircleOutlineIcon color="primary" />
            </span>
            <span>Watch 5 minute introduction to Sembly</span>
          </Typography>
          <Typography className={styles.boxSecondary} variant="body1">
            Unlock Sembly's full potential for more productive meetings by watching our brief intro.
          </Typography>
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            aria-label="Start using Sembly"
            onClick={handleClose}
          >
            Start using Sembly
          </Button>
        </Box>
      </div>
    </GenericDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: { color: theme.palette.common.white },
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.surface.main,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 'unset',
    },
  },
  title: {
    fontSize: 48,
  },
  video: {
    borderRadius: theme.spacing(1),
    border: 'none',
  },
  cursor: {
    cursor: 'pointer',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.surface.light,
  },
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    '& svg': {
      fontSize: '17px',
    },
  },
  boxSecondary: {
    marginLeft: theme.spacing(3),
    lineHeight: '1.3',
  },
  platformBtn: {
    borderRadius: theme.shape.borderRadius,
    background: 'rgb(255,255,255,0.1)',
    '&:hover': {
      background: 'rgb(255,255,255,0.3)',
    },
    '&:hover svg': {
      color: theme.palette.common.black,
    },
    '& svg': {
      color: theme.palette.common.white,
    },
  },
  titleIcon: {
    position: 'relative',
    top: -1,
    display: 'flex',
    alignItems: 'center',
    width: theme.spacing(3),
  },
  helpCenterIcon: {
    '& svg': {
      fill: theme.palette.highlight.main,
    },
  },
  getAppIcon: {
    '& svg': {
      fill: theme.palette.status.scheduled.color,
    },
  },
}));

export default ShowVideoOnboardingDialog;
