// Sembly UI
// import { Compass as WorkstreamsIcon } from '@sembly-ui';
// Icons
import AssignmentsIcon from '@shared/icons/Assignments';
import AutomationsIcon from '@shared/icons/Automations';
import BarChartIcon from '@material-ui/icons/BarChart';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import ChatIcon from '@material-ui/icons/Chat';
import PeopleIcon from '@material-ui/icons/People';
import TodayIcon from '@material-ui/icons/Today';
// App Shared
import { Routes as R } from '@shared/enums';

export interface AppDrawerNavItem {
  readonly icon: React.ReactElement;
  readonly label: string;
  readonly route: string;
  readonly isBeta?: boolean;
  readonly requiresAdmin?: boolean;
}

export interface AppDrawerNavItems {
  readonly aiAssociate: AppDrawerNavItem[];
  readonly meetings: AppDrawerNavItem[];
  readonly workspace: AppDrawerNavItem[];
}

export const APP_DRAWER_NAV_ITEMS: Readonly<AppDrawerNavItems> = {
  aiAssociate: [
    // My Workstreams is hidden until it's ready
    // { label: 'My Workstreams', icon: <WorkstreamsIcon />, route: R.MyWorkstreams, isBeta: true },
    { label: 'My Chats', icon: <ChatIcon />, route: R.MyChats },
  ],
  meetings: [
    { label: 'My Meetings', icon: <TodayIcon />, route: R.Home },
    { label: 'Shared With Me', icon: <PeopleIcon />, route: R.SharedWithMe },
    { label: 'My Assignments', icon: <AssignmentsIcon />, route: R.MyAssignments },
    { label: 'My Bookmarks', icon: <BookmarksIcon />, route: R.Bookmarks },
    { label: 'My Automations', icon: <AutomationsIcon />, route: R.MyAutomations },
  ],
  workspace: [
    { label: 'Automations', icon: <AutomationsIcon />, route: R.WorkspaceAutomations },
    { label: 'Analytics', icon: <BarChartIcon />, route: R.Analytics }
  ],
};

export default APP_DRAWER_NAV_ITEMS;
