import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import { parseISO } from 'date-fns';
import { useLazyQuery } from '@apollo/client';
// Material UI
import Grid, { GridProps } from '@material-ui/core/Grid';
// Lib Queries
import decisionsQuery from '../graphql/queries/Decisions.graphql';
import issuesQuery from '../graphql/queries/Issues.graphql';
import keyEventsQuery from '../graphql/queries/KeyEvents.graphql';
import noteworthyDetailsQuery from '../graphql/queries/NoteworthyDetails.graphql';
import parkingLotsQuery from '../graphql/queries/ParkingLots.graphql';
import qAndAsQuery from '../graphql/queries/QandAs.graphql';
import requirementsQuery from '../graphql/queries/Requirements.graphql';
import risksQuery from '../graphql/queries/Risks.graphql';
// Lib Generated Types
import {
  Decisions,
  DecisionsVariables,
  Issues,
  IssuesVariables,
  ItemsCounter,
  KeyEvents,
  KeyEventsVariables,
  NoteworthyDetails,
  NoteworthyDetailsVariables,
  ParkingLots,
  ParkingLotsVariables,
  QAndAs,
  QAndAsVariables,
  Requirements,
  RequirementsVariables,
  Risks,
  RisksVariables,
} from '../types';
// Lib Shared
import { GenericKeyItem, ActivityType } from '../types';
import { ACTIVITY_TYPES } from '../constants';
import { KeyItemCard, KeyItemClickDueDateHandler, KeyItemsGroup } from '../components';

export interface KeyItemsGroupContainerMenuData {
  anchorEl: HTMLElement;
  keyItem: GenericKeyItem;
  keyItemType: ActivityType;
}

export interface KeyItemsGroupContainerProps {
  gridItemProps?: GridProps;
  initialKeyItems: GenericKeyItem[];
  isAuthorizedToEdit?: boolean;
  isAuthorizedToExport?: boolean;
  isRTLDirected?: boolean;
  keyItemsType: ActivityType;
  meetingId: string;
  numberOfActivities: ItemsCounter;
  scrollTargetId?: string | null;
  onChangeDueDate?: KeyItemClickDueDateHandler;
  onChangeActionText?: (text: string, keyItem: GenericKeyItem, keyItemType: ActivityType) => void;
  onClickOnTimestamp?: (timestamp: number) => void;
  onOpenMobileMenu?: (data: KeyItemsGroupContainerMenuData) => void;
  onToggleDesktopMenu?: (data: KeyItemsGroupContainerMenuData | null) => void;
}

export const KeyItemsGroupContainer: React.VFC<KeyItemsGroupContainerProps> = ({
  gridItemProps,
  initialKeyItems,
  isAuthorizedToEdit = false,
  isAuthorizedToExport = false,
  isRTLDirected = false,
  keyItemsType,
  meetingId,
  numberOfActivities,
  scrollTargetId,
  onChangeDueDate = () => null,
  onChangeActionText = () => null,
  onClickOnTimestamp = () => null,
  onOpenMobileMenu = () => null,
  onToggleDesktopMenu = () => null,
}) => {
  /* #region  Hooks */
  const [shownInactive, setShownInactive] = useState(false);

  const [getDecisions, { refetch: refetchDecisions, called: isCalledGetDecisions, data: decisionsData, loading: loadDecisions }] = useLazyQuery<Decisions, DecisionsVariables>(decisionsQuery); // prettier-ignore
  const [getIssues, { refetch: refetchIssues, called: isCalledGetIssues, data: issuesData, loading: loadIssues }] = useLazyQuery<Issues, IssuesVariables>(issuesQuery); // prettier-ignore
  const [getKeyEvents, { refetch: refetchKeyEvents, called: isCalledGetKeyEvents, data: keyEventsData, loading: loadKeyEvents }] = useLazyQuery<KeyEvents, KeyEventsVariables>(keyEventsQuery); // prettier-ignore
  const [getNoteworthyDetails, { refetch: refetchNoteworthyDetails, called: isCalledGetNoteworthyDetails, data: noteworthyDetailsData, loading: loadNoteworthyDetails }] = useLazyQuery<NoteworthyDetails, NoteworthyDetailsVariables>(noteworthyDetailsQuery); // prettier-ignore
  const [getParkingLots, { refetch: refetchParkingLots, called: isCalledGetParkingLots, data: parkingLotsData, loading: loadParkingLots }] = useLazyQuery<ParkingLots, ParkingLotsVariables>(parkingLotsQuery); // prettier-ignore
  const [getQAndAs, { refetch: refetchQAndAs, called: isCalledGetQAndAs, data: qAndAsData, loading: loadQAndAs }] = useLazyQuery<QAndAs, QAndAsVariables>(qAndAsQuery); // prettier-ignore
  const [getRequirements, { refetch: refetchRequirements, called: isCalledGetRequirements, data: requirementsData, loading: loadRequirements }] = useLazyQuery<Requirements, RequirementsVariables>(requirementsQuery); // prettier-ignore
  const [getRisks, { refetch: refetchRisks, called: isCalledGetRisks, data: risksData, loading: loadRisks }] = useLazyQuery<Risks, RisksVariables>(risksQuery); // prettier-ignore
  /* #endregion */

  /* #region  Utils */
  const requestKeyItems = useCallback(
    (type: ActivityType, requestType: 'active' | 'inactive' | 'all' = 'all') => {
      const requestTypes = { active: true, inactive: false, all: undefined as any };
      const variables = { meetingId, isActive: requestTypes[requestType] };

      // Checking whether the requested data must be refetched
      let isRefetch = false;

      // prettier-ignore
      switch (type) {
        case 'decisions':         isRefetch = isCalledGetDecisions;           break;
        case 'issues':            isRefetch = isCalledGetIssues;              break;
        case 'keyEvents':         isRefetch = isCalledGetKeyEvents;           break;
        case 'noteworthyDetails': isRefetch = isCalledGetNoteworthyDetails;   break;
        case 'parkingLots':       isRefetch = isCalledGetParkingLots;         break;
        case 'qAndAs':            isRefetch = isCalledGetQAndAs;              break;
        case 'requirements':      isRefetch = isCalledGetRequirements;        break;
        case 'risks':             isRefetch = isCalledGetRisks;               break;
      }

      if (isRefetch) {
        // prettier-ignore
        switch (type) {
          case 'decisions':         refetchDecisions && refetchDecisions(variables);                  break;
          case 'issues':            refetchIssues && refetchIssues(variables);                        break;
          case 'keyEvents':         refetchKeyEvents && refetchKeyEvents(variables);                  break;
          case 'noteworthyDetails': refetchNoteworthyDetails && refetchNoteworthyDetails(variables);  break;
          case 'parkingLots':       refetchParkingLots && refetchParkingLots(variables);              break;
          case 'qAndAs':            refetchQAndAs && refetchQAndAs(variables);                        break;
          case 'requirements':      refetchRequirements && refetchRequirements(variables);            break;
          case 'risks':             refetchRisks && refetchRisks(variables);                          break;
        }
      } else {
        // prettier-ignore
        switch (type) {
          case 'decisions':           getDecisions({ variables });          break;
          case 'issues':              getIssues({ variables });             break;
          case 'keyEvents':           getKeyEvents({ variables });          break;
          case 'noteworthyDetails':   getNoteworthyDetails({ variables });  break;
          case 'parkingLots':         getParkingLots({ variables });        break;
          case 'qAndAs':              getQAndAs({ variables });             break;
          case 'requirements':        getRequirements({ variables });       break;
          case 'risks':               getRisks({ variables });              break;
        }
      }
    },
    // prettier-ignore
    [
      meetingId,
      getDecisions, isCalledGetDecisions,refetchDecisions,
      getIssues, isCalledGetIssues, refetchIssues,
      getKeyEvents, isCalledGetKeyEvents, refetchKeyEvents,
      getNoteworthyDetails, isCalledGetNoteworthyDetails, refetchNoteworthyDetails,
      getParkingLots, isCalledGetParkingLots, refetchParkingLots,
      getQAndAs, isCalledGetQAndAs, refetchQAndAs,
      getRequirements, isCalledGetRequirements, refetchRequirements,
      getRisks, isCalledGetRisks, refetchRisks,
    ],
  );

  const getKeyItemsData = (keyItemType: ActivityType) => {
    // prettier-ignore
    switch (keyItemType) {
      case 'decisions':         return decisionsData?.decisions || null;
      case 'issues':            return issuesData?.issues || null;
      case 'keyEvents':         return keyEventsData?.keyEvents || null;
      case 'noteworthyDetails': return noteworthyDetailsData?.noteworthyDetails || null;
      case 'parkingLots':       return parkingLotsData?.parkingLots || null;
      case 'qAndAs':            return qAndAsData?.qAndAs || null;
      case 'requirements':      return requirementsData?.requirements || null;
      case 'risks':             return risksData?.risks || null;
    }
  };
  /* #endregion */

  /* #region  Handlers */
  const handleOpenMobileMenu = (keyItem: GenericKeyItem) => (anchorEl: HTMLElement) => {
    onOpenMobileMenu({ anchorEl, keyItem, keyItemType: keyItemsType });
  };

  const handleToggleDesktopMenu = (keyItem: GenericKeyItem) => (anchorEl: HTMLElement | null) => {
    onToggleDesktopMenu(!anchorEl ? null : { anchorEl, keyItem, keyItemType: keyItemsType });
  };

  const handleChangeText = (keyItem: GenericKeyItem) => (value: string) => {
    onChangeActionText(value, keyItem, keyItemsType);
  };

  const handleRequestAllActive = () => {
    requestKeyItems(keyItemsType, 'active');
  };

  const handleToggleInactiveItems = () => {
    if (shownInactive) {
      // show all items
      setShownInactive(false);
      requestKeyItems(keyItemsType, 'active');
    } else {
      // show active items
      setShownInactive(true);
      requestKeyItems(keyItemsType, 'all');
    }
  };
  /* #endregion */

  /* #region  Effects */
  // helpers
  const currentKeyItems = getKeyItemsData(keyItemsType);
  const keyItems = currentKeyItems === null ? initialKeyItems : currentKeyItems;

  // load all activities if the "showAllActivities" prop is enabled
  useEffect(() => {
    if (!!scrollTargetId) {
      setShownInactive(true);
      requestKeyItems(keyItemsType, 'all');
    }
  }, [keyItemsType, scrollTargetId, requestKeyItems]);

  // automatic scrolling to the requested target activity
  useLayoutEffect(() => {
    if (!scrollTargetId || !keyItems) return;

    const targets = Array.from(
      document.querySelectorAll(`[data-scroll-item-id="${scrollTargetId}"]`),
    );

    if (!targets.length) return;

    const firstTarget = targets[0];
    firstTarget.scrollIntoView({ behavior: 'smooth', block: 'end' });
    targets?.forEach((target) => target.classList.add('highlighted'));

    function handleRemoveHightlight() {
      targets.forEach((target) => target.classList.remove('highlighted'));
    }

    document.addEventListener('click', handleRemoveHightlight, { once: true });

    return () => {
      window.removeEventListener('click', handleRemoveHightlight);
    };
  }, [scrollTargetId, keyItems]);
  /* #endregion */

  /* #region  Render Helpers */
  const isLoading =
    loadDecisions ||
    loadIssues ||
    loadKeyEvents ||
    loadParkingLots ||
    loadQAndAs ||
    loadRequirements ||
    loadNoteworthyDetails ||
    loadRisks;

  const { title } = ACTIVITY_TYPES[keyItemsType];
  const requestedKeyItems = getKeyItemsData(keyItemsType);
  const activities = requestedKeyItems === null ? initialKeyItems : requestedKeyItems;
  const activeItems = activities.filter(({ isActive }) => isActive);
  /* #endregion */

  // do not render empty sections
  if (!numberOfActivities.active && !numberOfActivities.inactive) {
    return null;
  }

  return (
    <KeyItemsGroup
      title={title}
      isLoading={isLoading}
      isRTLDirected={isRTLDirected}
      isShowInactive={shownInactive}
      keyItemsType={keyItemsType}
      numberOfActiveItems={activeItems.length}
      totalNumberOfActiveItems={numberOfActivities.active}
      totalNumberOfInactiveItems={numberOfActivities.inactive}
      onClickOnToggleInactiveItems={handleToggleInactiveItems}
      onClickOnClickOnShowHiddenItems={handleRequestAllActive}
    >
      <Grid container spacing={0}>
        {activities.map((keyItem) => {
          const keyItemText = keyItem.text || '';
          const keyItemCustomText = keyItem.customText || '';
          const keyItemDiarization = keyItem.diarizationItem;
          const isKeyEventType = keyItem.__typename === 'KeyEventType';

          const diarizationItem = {
            id: keyItemDiarization?.id,
            startTime: keyItemDiarization?.startTime,
            participantName: keyItemDiarization?.participant.name,
            participantAvatar: keyItemDiarization?.participant.user?.avatar,
          };

          let extraFields = undefined;

          if (isKeyEventType) {
            extraFields = {
              dueDate: (keyItem.dueDate && parseISO(keyItem.dueDate)) || keyItem.rawTiming,
            };
          }

          return (
            <KeyItemCard
              keyItemExtraFields={extraFields}
              diariazationItem={diarizationItem}
              isActive={keyItem.isActive}
              isAuthorizedToEdit={isAuthorizedToEdit}
              isAuthorizedToExport={isAuthorizedToExport}
              isRTLDirected={isRTLDirected}
              key={keyItem.id}
              keyItemCategory={keyItem.category}
              keyItemId={keyItem.id}
              keyItemText={keyItemText}
              keyItemCustomText={keyItemCustomText}
              keyItemType={keyItemsType}
              onChangeText={handleChangeText(keyItem)}
              onClickOnDueDate={onChangeDueDate}
              onClickOnTimestamp={onClickOnTimestamp}
              onOpenMobileMenu={handleOpenMobileMenu(keyItem)}
              onToggleDesktopMenu={handleToggleDesktopMenu(keyItem)}
              {...gridItemProps}
            />
          );
        })}
      </Grid>
    </KeyItemsGroup>
  );
};

export default KeyItemsGroupContainer;
