import sanitize from 'sanitize-html';
import { useState, useEffect } from 'react';
// Material UI
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import SendIcon from '@material-ui/icons/Send';
import PlusIcon from '@material-ui/icons/Add';

export interface ChatInputBoxProps {
  isAddingInsight: boolean;
  isAddingMeeting: boolean;
  isAskingQuestion: boolean;
  isChatInProcessing: boolean;
  onClickOnOptions: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: (value: string) => void;
}

export const ChatInputBox: React.VFC<ChatInputBoxProps> = ({
  isAddingInsight,
  isAddingMeeting,
  isAskingQuestion,
  isChatInProcessing,
  onClickOnOptions,
  onSubmit,
}) => {
  const styles = useStyles();

  const [value, setValue] = useState('');
  const [rows, setRows] = useState(1);

  /* #region  Helpers */
  const sanitizeValue = (value: string) => {
    return sanitize(value, {
      allowedTags: ['b', 'i', 'em', 'strong', 'p', 'br'],
      allowedAttributes: {},
    });
  };
  /* #endregion */

  /* #region  Handlers */
  const handleUpdateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleKeyDownOnInput = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter' && event.shiftKey) {
      setValue((prevValue) => `${prevValue}\n`);
      return;
    }

    if (!!value && event.key === 'Enter' && !event.ctrlKey) {
      event.preventDefault();
      if (!isChatInProcessing) {
        const sanitizedValue = sanitizeValue(value);
        onSubmit(sanitizedValue);
        setValue('');
      }
    }

    if (!value && event.key === 'Enter' && !event.ctrlKey) {
      event.preventDefault();
    }
  };

  const handleClickOnSubmit = () => {
    if (!value) return;

    const sanitizedValue = sanitizeValue(value);
    onSubmit(sanitizedValue);
    setValue('');
  };
  /* #endregion */

  useEffect(() => {
    const lines = value.split('\n').length;
    setRows(Math.min(5, lines)); // limit to 5 rows
  }, [value]);

  return (
    <div className={`${styles.root} hover-scrollbar`}>
      <IconButton
        className={styles.optionsBtn}
        disabled={isAskingQuestion || isAddingInsight || isChatInProcessing}
        onClick={onClickOnOptions}
      >
        <PlusIcon color="inherit" fontSize="small" />
      </IconButton>
      <InputBase
        fullWidth
        multiline
        placeholder="Ask me anything"
        className={styles.input}
        inputProps={{ maxLength: 1000 }}
        disabled={isAskingQuestion || isAddingInsight || isAddingMeeting}
        maxRows={5}
        value={value}
        minRows={rows}
        onKeyDown={handleKeyDownOnInput}
        onChange={handleUpdateValue}
      />
      <Button
        size="small"
        className={styles.submitBtn}
        disabled={isAskingQuestion || isAddingInsight || isChatInProcessing || !value}
        onClick={handleClickOnSubmit}
      >
        {isAskingQuestion || isAddingInsight ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          <>
            {isChatInProcessing ? (
              <SendIcon color="inherit" fontSize="small" style={{ opacity: '0.5' }} />
            ) : (
              <SendIcon color="inherit" fontSize="small" />
            )}
          </>
        )}
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: '2em',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 0.875),
    '& textarea:not(:hover)::-webkit-scrollbar': {
      display: 'none',
    },
  },
  input: {
    padding: theme.spacing(1),
  },
  submitBtn: {
    minWidth: 44,
    padding: theme.spacing(0.75, 1.5),
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[900],
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.grey[100],
    },
  },
  optionsBtn: {
    padding: theme.spacing(1),
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export default ChatInputBox;
