import { useContext } from 'react';
import { PlayerContext } from '../contexts';

export const usePlayer = () => {
  const context = useContext(PlayerContext);

  if (context === undefined) {
    throw new Error('The usePlayer hook must be used within a PlayerContext');
  }

  return context;
};

export default usePlayer;
