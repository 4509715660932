import { ToastContentProps } from 'react-toastify';
// Material UI
import MaterialAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/CloseSharp';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

export interface AlertProps extends Pick<ToastContentProps, 'closeToast'> {
  closable?: boolean;
  hideIcon?: boolean;
  severity?: 'error' | 'warning' | 'info' | 'success';
  onClose?: () => void;
}

export const Alert: React.FC<AlertProps> = ({
  children,
  closable = true,
  hideIcon = false,
  severity = 'info',
  closeToast = () => null,
  onClose = () => null,
  ...other
}) => {
  const styles = useStyles();
  const { toastProps } = other as Omit<ToastContentProps, 'closeToast'>;

  const handleClose = () => {
    onClose();
    closeToast();
    toastProps.deleteToast();
  };

  /* #region  Render Helpers */
  const actionControl = (
    <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  const iconMapping = {
    info: <InfoIcon fontSize="small" />,
    error: <ErrorIcon fontSize="small" />,
    success: <CheckCircleIcon fontSize="small" />,
    warning: <WarningIcon fontSize="small" />,
  };

  const { action, filledInfo, filledWarning, icon, message, filledError } = styles;
  /* #endregion */

  return (
    <MaterialAlert
      variant="filled"
      className={styles.alert}
      classes={{ filledInfo, filledWarning, icon, message, action, filledError }}
      iconMapping={iconMapping}
      severity={severity}
      icon={hideIcon ? false : null}
      action={closable ? actionControl : null}
    >
      {children}
    </MaterialAlert>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(1, 5, 1, 2),
    borderRadius: 0,
  },
  action: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    margin: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
    padding: 0,
  },
  filledInfo: {
    backgroundColor: '#D9F0FE',
    color: '#2267B8',
  },
  filledWarning: {
    backgroundColor: '#FFEBAD',
    color: '#AA4A00',
  },
  filledError: {
    backgroundColor: theme.palette.fault.main,
    color: theme.palette.fault.contrastText,
  },
  message: {
    padding: 0,
  },
}));

export default Alert;
