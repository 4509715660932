import { Box, BoxProps, Typography, CircularProgress } from '@material-ui/core';
// Lib Shared
import TranscriptionItem, { TranscriptionItemData } from './TranscriptionItem';
import { usePlayer } from '../hooks';
import bookmarksImage from '../assets/icon-bookmarks-48.svg';

export interface BookmarksWidgetTranscriptionItems extends TranscriptionItemData {
  bookmarkId: string;
}

export interface BookmarksWidgetProps extends BoxProps {
  isLoading?: boolean;
  isRTLDirected?: boolean;
  transcriptionItems: BookmarksWidgetTranscriptionItems[];
  onClickOnBookmark: (diarizationItemId: string) => void;
  onClickOnCopyLink: (diarizationItemId: string) => void;
}

/**
 * @component BookmarksWidget
 * @description A component that renders a list of a meeting's bookmarks.
 */
export const BookmarksWidget: React.VFC<BookmarksWidgetProps> = ({
  isLoading = false,
  isRTLDirected = false,
  transcriptionItems,
  onClickOnBookmark,
  onClickOnCopyLink,
  ...boxProps
}) => {
  const diarizationPlayer = usePlayer();

  const handleClickOnTimestamp = (timestamp: number) => {
    if (!diarizationPlayer?.audio.current) return;
    diarizationPlayer.audio.current.currentTime = timestamp;
    diarizationPlayer.audio.current.play();
  };

  return (
    <Box height="100%" {...boxProps}>
      {isLoading ? (
        <Box py={4} textAlign="center">
          <CircularProgress size={24} />
        </Box>
      ) : (
        <>
          {!transcriptionItems.length && (
            <Box
              p={1}
              display="flex"
              height="100%"
              alignItems="center"
              textAlign="center"
              flexDirection="column"
              justifyContent="center"
            >
              <img width={48} alt="Bookmarks" src={bookmarksImage} />
              <Box my={1}>
                <Typography variant="subtitle1">No bookmarks</Typography>
              </Box>
              <Typography variant="body2" color="textSecondary">
                Transcription bookmarks will appear here.
              </Typography>
            </Box>
          )}
          {transcriptionItems.map((item) => (
            <TranscriptionItem
              dense
              button
              isBookmarksWidget
              isBookmarked
              key={item.bookmarkId}
              isAuthorizedToEdit={false}
              transcriptionItem={item}
              onClick={onClickOnBookmark}
              onClickOnCopyLink={onClickOnCopyLink}
              onClickOnTimestamp={handleClickOnTimestamp}
            />
          ))}
        </>
      )}
    </Box>
  );
};

export default BookmarksWidget;
