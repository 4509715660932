import { useQuery } from '@apollo/client';
// Lib Shared
import query from '../graphql/queries/MeetingBookmarksContainerQuery.graphql';
import BookmarksWidget from '../components/BookmarksWidget';
import { MeetingBookmarksContainerQuery, MeetingBookmarksContainerQueryVariables } from '../types';

export interface BookmarksContainerProps {
  meetingId: string;
  onClickOnBookmark: (diarizationItemId: string) => void;
  onClickOnCopyLink: (diarizationItemId: string) => void;
}

/**
 * @component BookmarksContainer
 * @description A container component for the BookmarksWidget component.
 */
export const BookmarksContainer: React.VFC<BookmarksContainerProps> = ({
  meetingId,
  onClickOnCopyLink,
  onClickOnBookmark,
}) => {
  const { data, loading: isBookmarksLoading } = useQuery<
    MeetingBookmarksContainerQuery,
    MeetingBookmarksContainerQueryVariables
  >(query, { variables: { meetingId } });

  /* #region  Render Helpers */
  const transcriptionItems = data?.meetingBookmarks?.map((item) => ({
    bookmarkId: item.id,
    id: item.diarizationItem.id,
    name: item.diarizationItem.participant.name,
    startTime: item.diarizationItem.startTime,
    transcriptionItemBookmarkId: item.diarizationItem.bookmark?.id || null,
    userAvatar: item.diarizationItem.participant.user?.avatar || null,
    userFullName: item.diarizationItem?.participant.user?.fullName || null,
    word: item.diarizationItem.word,
  }));
  /* #endregion */

  return (
    <BookmarksWidget
      isLoading={isBookmarksLoading}
      transcriptionItems={transcriptionItems || []}
      onClickOnBookmark={onClickOnBookmark}
      onClickOnCopyLink={onClickOnCopyLink}
    />
  );
};
