import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const MyAutomationsModuleContainer = lazyWithPreload(
  () => import('./containers/MyAutomationsModuleContainer'),
);

export const MyAutomationsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.MyAutomations,
    render: () => <MyAutomationsModuleContainer />,
  },
};

export default MyAutomationsModule;
