import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MaterialLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { TransitionSlide } from '@sembly-ui';
// App Shared
import { isWebView, getParentPathname } from '@shared/utils';
import { Routes } from '@shared/enums';

export const WebViewModal: React.VFC = () => {
  const styles = useStyles();

  const history = useHistory();

  const targetHref =
    getParentPathname({ pathname: window.location.href }) || 'https://webapp.sembly.ai';
  const targetPathname = getParentPathname() || Routes.Home;

  const handleCopyLink = async () => {
    toast.dark(
      <>
        <Typography component="span" variant="body1">
          Please copy and paste this URL into a browser address bar:
        </Typography>
        <Box mt={1}>
          <Typography
            component="span"
            variant="body1"
            color="primary"
            style={{ userSelect: 'all' }}
          >
            {targetHref}
          </Typography>
        </Box>
      </>,
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      },
    );
  };

  const handleClose = () => {
    history.replace(targetPathname);
  };

  if (!isWebView()) {
    history.replace(targetPathname);
    return null;
  }

  return (
    <Dialog open scroll="body" onClose={handleClose} TransitionComponent={TransitionSlide}>
      <DialogContent className={styles.content}>
        <DialogTitle>
          <IconButton aria-label="close" className={styles.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Typography gutterBottom variant="h3">
          Sembly can't sign you in safely inside embedded browsers
        </Typography>

        <Box mt={3}>
          <Typography variant="body1">
            You can use Google sign-in by visiting{' '}
            <MaterialLink href={targetHref} target="_blank" rel="external">
              webapp.sembly.ai
            </MaterialLink>{' '}
            in a browser like Chrome or Safari.
          </Typography>
          <Box mt={4}>
            {isWebView('ios') ? (
              <Button
                disableElevation
                color="primary"
                variant="contained"
                component="a"
                href="x-web-search://?webapp.sembly.ai"
                target="_blank"
                rel="external"
                aria-label="Open in default browser"
              >
                Open in default browser
              </Button>
            ) : (
              <Button
                disableElevation
                variant="contained"
                color="primary"
                aria-label="Open in default browser"
                onClick={handleCopyLink}
              >
                Open in default browser
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default WebViewModal;
