// Material UI
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Pound = (props: SvgIconProps) => (
      <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
          fill="currentColor"
          d="M6.92308 21L9.90074 3H12.3573L9.39206 21H6.92308ZM11.3896 21L14.3672 3H16.8362L13.8586 21H11.3896ZM18.8462 10.4052H5.93052V8.14286H18.8462V10.4052ZM17.9032 15.9313H5V13.669H17.9032V15.9313Z"
        />
      </SvgIcon>
);

export default Pound;
