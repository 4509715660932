import { useState } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import { SearchSharp, CloseSharp, ArrowBack } from '@material-ui/icons';
// Lib Shared
import { GenericDefaultUser } from '../types';
import MeetingParticipantAvatar from './MeetingParticipantAvatar';

export interface ActionPerformerMenuProps {
  actionId: string;
  anchorEl: HTMLElement;
  attendees: GenericDefaultUser[];
  currentPerformer: GenericDefaultUser | string | null;
  isLoading?: boolean;
  onChange: (id: string, performer: GenericDefaultUser | string | null) => void;
  onClose: () => void;
}

export const ActionPerformerMenu: React.VFC<ActionPerformerMenuProps> = ({
  actionId,
  anchorEl,
  attendees = [],
  currentPerformer,
  isLoading = false,
  onChange,
  onClose,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [filterValue, setFilterValue] = useState('');
  const [customPerformer, setCustomPerformer] = useState('');
  const [isAddingCustomPerformer, setIsAddingCustomPerformer] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleUnassignItem = () => {
    onChange(actionId, null);
    onClose();
  };

  const handleApplyCustomPerformer = () => {
    onChange(actionId, customPerformer);
    onClose();
  };

  const handleClickOnListItem = (newPerformer: GenericDefaultUser) => () => {
    onChange(actionId, newPerformer);
    onClose();
  };

  const handleToggleAddingPerformer = (value: boolean) => () => {
    setIsAddingCustomPerformer(value);
  };

  const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value.toLowerCase());
  };

  const handleChangeCustomPerformerName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomPerformer(event.target.value);
  };

  const handleClearFilterValue = () => {
    setFilterValue('');
  };
  /* #endregion */

  const filteredAttendees = attendees.filter(({ fullName }) =>
    fullName.toLowerCase().includes(filterValue),
  );

  return (
    <Menu
      open
      variant="menu"
      classes={{ paper: styles.paper, list: styles.menu }}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {isAddingCustomPerformer ? (
              <Box m={2} pb={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  component={Link}
                  onClick={handleToggleAddingPerformer(false)}
                >
                  <ArrowBack color="action" />
                  <Box mr={1} />
                  <Typography variant="body2" color="textPrimary">
                    Back to attendees list
                  </Typography>
                </Box>
                <Box mt={3} mb={1}>
                  <TextField
                    fullWidth
                    autoFocus
                    label="Assignee Name"
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 255 }}
                    value={customPerformer}
                    onChange={handleChangeCustomPerformerName}
                  />
                </Box>
                <Button
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="primary"
                  disabled={!customPerformer}
                  onClick={handleApplyCustomPerformer}
                >
                  <Typography variant="body1">Apply</Typography>
                </Button>
              </Box>
            ) : (
              <div>
                <Box mx={2} mt={2} mb={1}>
                  <TextField
                    autoFocus
                    fullWidth
                    placeholder="Search"
                    variant="filled"
                    size="small"
                    value={filterValue}
                    onChange={handleChangeFilterValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchSharp fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {!!filterValue && (
                            <IconButton size="small" onClick={handleClearFilterValue}>
                              <CloseSharp fontSize="small" color="primary" />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box maxHeight="30vh" overflow="auto" mb={1}>
                  {filteredAttendees.length ? (
                    filteredAttendees.map((user) => (
                      <MenuItem
                        key={user.id}
                        onClick={handleClickOnListItem(user)}
                        selected={
                          !!currentPerformer &&
                          typeof currentPerformer !== 'string' &&
                          currentPerformer.id === user.id
                        }
                      >
                        <MeetingParticipantAvatar
                          className={styles.avatar}
                          name={user.fullName}
                          avatar={user.avatar}
                        />
                        <span>{user.fullName}</span>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Sorry, nothing matches your search.</MenuItem>
                  )}
                  <MenuItem onClick={handleToggleAddingPerformer(true)}>
                    <Link>Add a custom assignee</Link>
                  </MenuItem>
                </Box>
                <Box mx={2} mb={2}>
                  {currentPerformer !== null && (
                    <Button fullWidth variant="outlined" onClick={handleUnassignItem}>
                      <Typography variant="body2">Clear All</Typography>
                    </Button>
                  )}
                </Box>
              </div>
            )}
          </>
        )}
      </div>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 24,
    height: 24,
    fontSize: '0.75rem',
    marginRight: theme.spacing(2),
  },
  menu: {
    padding: 0,
    minWidth: '40ch',
  },
  paper: {
    borderRadius: theme.shape.borderRadius,
  },
}));

export default ActionPerformerMenu;
