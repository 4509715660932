import { useState } from 'react';
// Material UI
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
// Shared
import { AgentCallDialog } from '@shared/dialogs';

export const AgentCallPlanner: React.VFC = () => {
  /* #region  Hooks */
  const styles = useStyles();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  const [shownAgentCallDialog, setShownAgentCallDialog] = useState(false);
  /* #endregion */

  /* #region  Handlers */
  const handleShowAgentCallDialog = () => {
    setShownAgentCallDialog(true);
  };

  const handleHideAgentCallDialog = () => {
    setShownAgentCallDialog(false);
  };
  /* #endregion */

  return (
    <>
      {isSmallScreen ? (
        <IconButton className={styles.button} onClick={handleShowAgentCallDialog}>
          <PhoneInTalkIcon />
        </IconButton>
      ) : (
        <Button
          disableElevation
          variant="contained"
          color="primary"
          startIcon={<PhoneInTalkIcon />}
          aria-label="Invite Sembly Agent to Meeting"
          onClick={handleShowAgentCallDialog}
        >
          <Box component="span" whiteSpace="nowrap">
            Invite to Meeting
          </Box>
        </Button>
      )}
      {/* Modal Dialogs */}
      {shownAgentCallDialog && <AgentCallDialog onClose={handleHideAgentCallDialog} />}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default AgentCallPlanner;
