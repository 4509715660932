import debounce from 'lodash/debounce';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useState, useRef, useEffect } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
// Material Icons
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/LinkSharp';
import QuestionMarkIcon from '@material-ui/icons/HelpOutlineSharp';
import RestoreIcon from '@material-ui/icons/Restore';
// Lib Queries and Mutations
import editMeetingNotesMutation from '../graphql/mutations/EditMeetingNotes.graphql';
import meetingNotesQuery from '../graphql/queries/MeetingNotesQuery.graphql';
import meetingQuery from '../graphql/queries/Meeting.graphql';
import publishMeetingNotesMutation from '../graphql/mutations/PublishMeetingNotes.graphql';
import restoreMeetingNotesMutation from '../graphql/mutations/RestoreMeetingNotes.graphql';
// Lib Shared
import MeetingPlaceholderNotes from '../components/MeetingPlaceholderNotes';
import RichTextEditor from '../components/RichTextEditor';
import GenericConfirmation from '../components/GenericConfirmation';
import MeetingPlaceholderProcessing from '../components/MeetingPlaceholderProcessing';
import { useConfirmationDialog } from '../hooks';
import { isAsianLang } from '../utils';
import {
  AgentCallPlatform,
  EditMeetingNotes,
  EditMeetingNotesVariables,
  GraphError,
  Meeting,
  MeetingNotesQuery,
  MeetingNotesQueryVariables,
  MeetingStatuses,
  MeetingVariables,
  PublishMeetingNotes,
  PublishMeetingNotesVariables,
  RedirectTarget,
  RestoreMeetingNotes,
  RestoreMeetingNotesVariables,
} from '../types';

export interface MeetingNotesContainerProps {
  editorScrollContainerId?: string;
  isAuthorizedToExport?: boolean;
  isAuthorizedToView: boolean;
  isPromoteUpgrade?: boolean;
  isRestrictedKeyItemsPromotion: boolean;
  meetingId: string;
  onClickOnUpgradePlan?: () => void;
  onCopyGuestMinutesLink?: (link: string) => void;
  onExportContent?: (title: string, content: string) => void;
  onRedirect: (target: RedirectTarget) => void;
  onResponseError: (errorMessage: GraphError) => void;
  onRevertedContent?: () => void;
}

/**
 * Container that represents the Meeting Notes section
 */
export const MeetingNotesContainer: React.VFC<MeetingNotesContainerProps> = ({
  editorScrollContainerId,
  isAuthorizedToExport = false,
  isAuthorizedToView,
  isPromoteUpgrade = false,
  isRestrictedKeyItemsPromotion,
  meetingId,
  onClickOnUpgradePlan = () => null,
  onCopyGuestMinutesLink = () => null,
  onExportContent = () => null,
  onRedirect,
  onResponseError,
  onRevertedContent = () => null,
}) => {
  /* #region  Hooks */
  const client = useApolloClient();

  const currentEditorContentRef = useRef<string | null>(null);

  const [contentKey, setContentKey] = useState(1);
  const [editorContent, setEditorContent] = useState<string | null>('');
  const [editorElement, setEditorElement] = useState<HTMLDivElement | null>(null);
  const [isRefetchingMeetingNotes, setIsRefetchingMeetingNotes] = useState(false);

  const [confirmRestore, RestoreNotesConfirmationDialog] = useConfirmationDialog((resolve) => (
    <GenericConfirmation
      open
      titleProps={{ color: 'inherit' }}
      title="Revert Meeting Notes"
      text="All your changes in Meeting Notes will be lost. Are you sure?"
      confirmButtonProps={{ color: 'secondary' }}
      confirmButtonLabel="Revert"
      onCancel={() => resolve(false)}
      onConfirm={() => resolve(true)}
    />
  ));
  /* #endregion */

  /* #region  Apollo Hooks */
  const { data, loading, refetch } = useQuery<MeetingNotesQuery, MeetingNotesQueryVariables>(
    meetingNotesQuery,
    {
      variables: { meetingId },
      skip: !isAuthorizedToView,
    },
  );

  const { data: meetingData } = useQuery<Meeting, MeetingVariables>(meetingQuery, {
    variables: { meetingId },
    fetchPolicy: 'cache-only',
  });

  const [updateMeetingNotes] = useMutation<EditMeetingNotes, EditMeetingNotesVariables>(
    editMeetingNotesMutation,
  );

  const [publishMeetingNotes, { loading: isPublishing }] = useMutation<
    PublishMeetingNotes,
    PublishMeetingNotesVariables
  >(publishMeetingNotesMutation);

  const [restoreMeetingNotes, { loading: isRestoring }] = useMutation<
    RestoreMeetingNotes,
    RestoreMeetingNotesVariables
  >(restoreMeetingNotesMutation);
  /* #endregion */

  const updateMinutes = debounce(
    async (
      minutesId: string,
      minutesContent: string,
      publicMeetingMinutesLink: string | null = null,
    ) => {
      currentEditorContentRef.current = minutesContent;

      const result = await updateMeetingNotes({
        variables: { id: minutesId, text: minutesContent },
        optimisticResponse: {
          editMeetingMinutes: {
            __typename: 'EditMeetingMinutesMutationPayload',
            errors: [],
            success: true,
            meetingMinutes: {
              __typename: 'MeetingMinutesType',
              id: minutesId,
              text: minutesContent,
              language: data?.meetingMinutes?.language || 'ENGLISH',
              publicMeetingMinutesLink,
            },
          },
        },
      });

      if (!result.data?.editMeetingMinutes?.success) {
        onResponseError(result.data?.editMeetingMinutes?.errors);
      }
    },
    1000,
  );

  /* #region  Handlers */
  const handleClickOnExport = () => {
    if (!isAuthorizedToExport) return;
    const title = data?.meeting?.title || 'Untitled Meeting';
    onExportContent(title, editorContent || data?.meetingMinutes?.text || '');
  };

  const handleSaveMinutesContent = (value: string) => {
    const minutesId = data?.meetingMinutes?.id;
    const publicMeetingMinutesLink = data?.meetingMinutes?.publicMeetingMinutesLink;

    if (!minutesId) return;

    setEditorContent(value);
    updateMinutes(minutesId, value, publicMeetingMinutesLink);
  };

  const handleRestoreMeetingNotes = async () => {
    const minutesId = data?.meetingMinutes?.id;
    if (!minutesId) throw new Error('Missing minutesId');

    const isConfirmed = await confirmRestore();
    if (!isConfirmed) return;

    const result = await restoreMeetingNotes({ variables: { id: minutesId } });

    if (!result.data?.restoreMeetingMinutes?.success) {
      onResponseError(result.data?.restoreMeetingMinutes?.errors);
    } else {
      onRevertedContent();
      setContentKey((prev) => prev + 1); // Force editor to re-render
    }
  };

  const handleCopyGuestMinutesLink = async () => {
    const minutesId = data?.meetingMinutes?.id;
    const minutesExistingLink = data?.meetingMinutes?.publicMeetingMinutesLink;

    const copyLinkToClipboard = async (value: string) => {
      onCopyGuestMinutesLink(value);
    };

    if (minutesExistingLink) {
      copyLinkToClipboard(minutesExistingLink);
      return;
    }

    if (!minutesId) throw new Error('Missing minutesId');

    const result = await publishMeetingNotes({ variables: { id: minutesId } });
    const generatedMinutes = result.data?.generateMeetingMinutesPublicLink?.meetingMinutes;
    const publicMeetingMinutesLink = generatedMinutes?.publicMeetingMinutesLink;

    if (publicMeetingMinutesLink) {
      copyLinkToClipboard(publicMeetingMinutesLink);
    } else {
      onResponseError(result.data?.generateMeetingMinutesPublicLink?.errors);
    }
  };
  /* #endregion */

  useEffect(() => {
    // Update the editor content when the meeting notes are updated
    // from another source (e.g. MeetingTypeManageDialog)
    if ((data?.meetingMinutes?.text ?? null) !== currentEditorContentRef.current) {
      setContentKey((prev) => prev + 1); // Force editor to re-render
      currentEditorContentRef.current = data?.meetingMinutes?.text ?? null;
    }
  }, [data?.meetingMinutes?.text]);

  useEffect(() => {
    if (!meetingId) return;

    const unsubscribe = client.cache.watch<Meeting, MeetingVariables>({
      query: meetingQuery,
      variables: { meetingId },
      optimistic: false,
      callback: async (newData) => {
        const hasMeetingText = data?.meetingMinutes?.text !== undefined;
        const hasMeetingNotes = newData?.result?.meeting?.hasMeetingNotes ?? false;
        const isProcessed = newData?.result?.meeting?.processingResults?.processedMeetingNotes ?? false;
        // Refetching the meeting notes when the meeting is
        // completly processed and there are no meeting notes
        if (isProcessed && hasMeetingNotes && !loading && !hasMeetingText) {
          setIsRefetchingMeetingNotes(true);
          await refetch();
          setIsRefetchingMeetingNotes(false);
        }
      },
    });

    return () => unsubscribe();
  }, [client, data?.meetingMinutes?.text, meetingId, loading, refetch]);

  /* #region  Render Helpers */
  const meeting = data?.meeting;
  const agentCall = meetingData?.meeting?.agentCall;
  const meetingPermissions = meeting?.permissions;
  const meetingMainLang = meeting?.processingResults?.mainLanguage;
  const isProcessedAssignments = meeting?.processingResults?.processedAssignments ?? false;
  const isAuthorizedToManage = meetingPermissions?.canManage || false;
  const isTranscribed = meeting?.processingResults?.processedTranscribing ?? false;
  const isProcessedAnalytics = meeting?.processingResults?.processedAnalytics ?? false;
  const isProcessedNotes = meeting?.processingResults?.processedMeetingNotes ?? false;
  const isProcessingComplete = meeting?.status !== MeetingStatuses.processing;
  const isProcessed = isProcessingComplete || isProcessedNotes;
  const meetingNotesLanguage = data?.meetingMinutes?.language;
  const isExportable = !isAsianLang(meetingNotesLanguage || meetingMainLang);
  const isManualUpload = agentCall?.platform === AgentCallPlatform.MANUAL_UPLOAD;

  const copyIcon = isPublishing ? <CircularProgress size={18} /> : <LinkIcon color="action" />;
  const restoreIcon = isRestoring ? <CircularProgress size={18} /> : <RestoreIcon color="action" />;
  /* #endregion */

  /* #region  Props Validation */
  if (isAuthorizedToManage && !onCopyGuestMinutesLink) {
    throw new Error('If user is authorized to manage, handler is required');
  }

  if (isAuthorizedToExport && !onExportContent) {
    throw new Error('If user is authorized to export, handler is required');
  }

  if (isPromoteUpgrade && !onClickOnUpgradePlan) {
    throw new Error('If promotion upgrade is true, handler is required');
  }
  /* #endregion */

  // Render Fallback
  if (loading || isRefetchingMeetingNotes) {
    return (
      <Box flex={1} width="100%" paddingTop={4}>
        <Box textAlign="center" mt={8}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  if (!meeting) return null;

  return (
    <>
      {data?.meetingMinutes?.text !== undefined ? (
        <Box mt={4}>
          <Box mb={1} display="flex" flexWrap="wrap" alignItems="center" gridGap={8}>
            <Box flex={1}>
              <Typography component="div" variant="h4">
                Meeting Notes{' '}
                <Link
                  color="textSecondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Help"
                  href="https://helpdesk.sembly.ai/hc/en-us/articles/4421010439185-Sembly-Meeting-Notes"
                  style={{ display: 'inline-block', lineHeight: '1rem', fontSize: '1rem' }}
                >
                  <QuestionMarkIcon fontSize="inherit" />
                </Link>
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" gridGap={6}>
              {isAuthorizedToExport && isExportable && (
                <Button
                  size="small"
                  variant="outlined"
                  disabled={!data.meetingMinutes.text || !editorElement}
                  startIcon={<GetAppIcon color="action" />}
                  onClick={handleClickOnExport}
                >
                  <Box component="span" mx={1}>
                    Download
                  </Box>
                </Button>
              )}
              {isAuthorizedToManage && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isRestoring}
                    startIcon={restoreIcon}
                    onClick={handleRestoreMeetingNotes}
                  >
                    <Box component="span" mx={1}>
                      Revert
                    </Box>
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={isPublishing}
                    startIcon={copyIcon}
                    onClick={handleCopyGuestMinutesLink}
                  >
                    <Box component="span" mx={1} whiteSpace="nowrap">
                      Copy public link
                    </Box>
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <RichTextEditor
            persistToolbar
            stickyToolbar
            key={contentKey}
            maxLength={150000}
            placeholder="Add your Meeting Notes here..."
            ref={setEditorElement}
            scrollContainerId={editorScrollContainerId}
            readOnly={!isAuthorizedToManage}
            defaultValue={data.meetingMinutes.text}
            onChange={handleSaveMinutesContent}
          />
        </Box>
      ) : isProcessed ? (
        <MeetingPlaceholderNotes
          failureReason={agentCall?.failureReason || null}
          isManualRecording={isManualUpload}
          isProcessedAssignments={isProcessedAssignments}
          isProcessedAnalytics={isProcessedAnalytics}
          isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
          isTranscribed={isTranscribed}
          meetingStatus={meeting.status}
          onRedirect={onRedirect}
        />
      ) : (
        // Meeting is not processed yet
        <MeetingPlaceholderProcessing
          isProcessedAssignments={isProcessedAssignments}
          isPromoteUpgrade={isPromoteUpgrade}
          isProcessedAnalytics={isProcessedAnalytics}
          isProcessedNotes={isProcessedNotes}
          isTranscribed={isTranscribed}
          isRestrictedKeyItems={isRestrictedKeyItemsPromotion}
          meetingDuration={meeting.duration}
          meetingEnd={meeting.finishedAt}
          onChangeRoute={onRedirect}
          onClickOnUpgradePlan={onClickOnUpgradePlan}
        />
      )}
      {/* Begin: Dialogs */}
      {RestoreNotesConfirmationDialog}
      {/* End: Dialogs */}
    </>
  );
};

export default MeetingNotesContainer;
