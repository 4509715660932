import { createElement } from 'react';
import { findAll, FindChunksArgs, Chunk } from 'highlight-words-core';

export interface HighlighterProps {
  /**
   * The class name to be applied to an active match. Use along with activeIndex
   */
  activeClassName?: string;
  /**
   * Specify the match index that should be actively highlighted. Use along with activeClassName
   */
  activeIndex?: number;
  /**
   * The inline style to be applied to an active match. Use along with activeIndex
   */
  activeStyle?: React.CSSProperties;
  /**
   * Escape characters in searchWords which are meaningful in regular expressions
   */
  autoEscape?: boolean;
  /**
   * Search should be case sensitive; defaults to `false`
   */
  caseSensitive?: boolean;
  /**
   * CSS class name applied to the outer/wrapper `<span>`
   */
  className?: string;
  /**
   * CSS properties applied to the outer/wrapper `<span>`
   */
  style?: React.CSSProperties;
  /**
   * Use a custom function to search for matching chunks. This makes it possible to use
   * arbitrary logic when looking for matches. See the default findChunks function in
   * highlight-words-core for signature. Have a look at the custom findChunks example
   * on how to use it.
   */
  findChunks?: (args: FindChunksArgs) => Chunk[];
  /**
   * CSS class name applied to highlighted text.
   */
  highlightClassName?: string;
  /**
   * Inline styles applied to highlighted text.
   */
  highlightStyle?: React.CSSProperties;
  /**
   * Type of tag to wrap around highlighted matches; defaults to mark.
   */
  highlightTag?: string;
  /**
   * Process each search word and text to highlight before comparing (eg remove accents);
   * signature (text: string): string.
   */
  sanitize?: (text: string) => string;
  /**
   * Array of search words. String search terms are automatically cast to RegExps unless
   * autoEscape is true.
   */
  searchWords: string[];
  /**
   * Text to highlight matches in.
   */
  textToHighlight: string;
  /**
   * CSS class name applied to unhighlighted text.
   */
  unhighlightClassName?: string;
  /**
   * Inline styles applied to unhighlighted text.
   */
  unhighlightStyle?: React.CSSProperties;
}

/**
 * Highlights all occurrences of search terms (searchText) within a string (textToHighlight).
 * This function returns an array of strings and <span>s (wrapping highlighted words).
 * Forked from https://github.com/bvaughn/react-highlight-words
 */
export const Highlighter: React.VFC<HighlighterProps> = ({
  activeClassName = '',
  activeIndex = -1,
  activeStyle,
  autoEscape,
  caseSensitive = false,
  className,
  style,
  findChunks,
  highlightClassName = '',
  highlightStyle = {},
  highlightTag = 'mark',
  sanitize,
  searchWords,
  textToHighlight,
  unhighlightClassName = '',
  unhighlightStyle,
  ...rest
}) => {
  const chunks = findAll({
    autoEscape,
    caseSensitive,
    findChunks,
    sanitize,
    searchWords,
    textToHighlight,
  });

  const HighlightTag = highlightTag;
  let highlightIndex = -1;
  let highlightClassNames = '';
  let highlightStyles;

  return createElement('span', {
    className,
    style,
    ...rest,
    children: chunks.map((chunk, index) => {
      const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);

      if (chunk.highlight) {
        highlightIndex++;

        const isActive = highlightIndex === +activeIndex;

        highlightClassNames = `${highlightClassName} ${isActive ? activeClassName : ''}`;
        highlightStyles =
          isActive === true && activeStyle != null
            ? Object.assign({}, highlightStyle, activeStyle)
            : highlightStyle;

        const props = {
          children: text,
          className: highlightClassNames,
          key: index,
          style: highlightStyles,
        };

        // Don't attach arbitrary props to DOM elements; this triggers React DEV warnings (https://fb.me/react-unknown-prop)
        // Only pass through the highlightIndex attribute for custom components.
        if (typeof HighlightTag !== 'string') {
          // @ts-expect-error
          props.highlightIndex = highlightIndex;
        }

        return createElement(HighlightTag, props);
      } else {
        return createElement('span', {
          children: text,
          className: unhighlightClassName,
          key: index,
          style: unhighlightStyle,
        });
      }
    }),
  });
};

export default Highlighter;
