// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// Sembly UI
import { GenericDialog } from '@sembly-ui';

export interface ConfirmDeleteRecordingProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmDeleteRecording: React.VFC<ConfirmDeleteRecordingProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GenericDialog
      hideTitle
      dialogProps={{ open, fullWidth: true, maxWidth: 'sm', fullScreen: isSmallScreen }}
      onClose={onCancel}
    >
      <Box component={DialogContent} mt={1} mb={4}>
        <Box my={3} textAlign="center">
          <Typography paragraph variant="h5" color="primary">
            Are you sure you want to delete this recording?
          </Typography>
          <Typography variant="body1">
            This recording will be deleted immediately. You can't undo this action.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={1}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              aria-label="Confirm delete recording"
              onClick={onConfirm}
            >
              Delete
            </Button>
          </Box>
          <Button variant="outlined" aria-label="Cancel delete recording" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default ConfirmDeleteRecording;
