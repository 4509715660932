import { lazyWithPreload } from '@shared/utils';
import { Routes } from '@shared/enums';
import { ProtectedRouterModule } from '@shared/types';

export const BookmarksModuleContainer = lazyWithPreload(
  () => import('./containers/BookmarksModuleContainer'),
);

export const BookmarksModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.Bookmarks,
    render: () => <BookmarksModuleContainer />,
  },
};

export default BookmarksModule;
