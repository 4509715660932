/**
 * @name formatCurrencyAmount
 * @description Formats a currency amount
 * @param {number} amount - The amount to format
 * @param {string} currencyCode - The currency code to use for formatting
 * @param {boolean} [showOptionalFractionDigits=false] - Whether to show optional fraction digits
 * @returns {string} The formatted currency amount
 */
export function formatCurrencyAmount(
  amount: number,
  currencyCode: string = 'USD',
  showOptionalFractionDigits: boolean = false,
): string {
  const isUSD = currencyCode === 'USD';
  const minimumFractionDigits = isUSD ? 0 : 2;
  const maximumFractionDigits = 2;

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: showOptionalFractionDigits ? minimumFractionDigits : 0,
    maximumFractionDigits,
  }).format(amount);
}

export default formatCurrencyAmount;
