const OUTLOOK_SAFE_LINK_REGEXP = '[a-zA-Z0-9]{1,18}.safelinks.protection.outlook.com';
const ZOOM_LINK_REGEXP = 'zoom.(com|us)/[A-z0-9%-/?=]+';

export const MEETING_PLATFORM_URL_REGEXPS = {
  meetUrlRegExp: 'meet.google.com/[a-z-]{12}',
  teamsUrlWithContextRegExp:
    'teams.microsoft.com/l/meetup-join[A-z0-9%-/?=@:]+context+[A-z0-9%-/?=@:{""}]+',
  teamsUrlRegExp: 'teams.microsoft.com/l/meetup-join[A-z0-9%-/?=@:]+',
  teamsMicrosoftUrlRegExp: 'teams.microsoft.com/_#/pre-join-calling[A-z0-9%-/?=:]+',
  teamsLiveUrlRegExp: 'teams\\.live\\.com/meet/[0-9]{12,}(\\?p=[a-zA-Z0-9]+)?',
  protectUsMimecastUrlRegExp:
    'protect-us.mimecast.com/s/[A-Za-z0-9]+(\\?domain=teams.microsoft.com)?',
  zoomUrlRegExp: ZOOM_LINK_REGEXP,
  zoomWithExceptionRegExp: `${ZOOM_LINK_REGEXP}/[A-z0-9%-/?=]+/start`,
  zoomWithDomainUrlRegExp: `[a-zA-Z0-9|-]+.${ZOOM_LINK_REGEXP}`,
  zoomWithDomainAndPwdUrlRegExp: `[a-zA-Z0-9|-]+.zoom.(com|us)/[a-zA-Z]{1,5}/[0-9]{9,15}[?]+pwd+[=]+[a-zA-Z0-9]{28,35}`,
  zoomWithCustomDomainAndPwdUrlRegExp: '[a-zA-Z0-9-]+\\.zoom-[a-zA-Z0-9-]+\\.[a-z]{2,}/j/[0-9]{9,15}(\\?pwd=[a-zA-Z0-9]+)?',
  outlookSafeLinkWithZoomRegExp: `${OUTLOOK_SAFE_LINK_REGEXP}/[%-/?]+url+[=]+https+[A-z0-9%]+[a-zA-Z0-9]{1,18}.zoom.us[A-z0-9%-/?=]+`,
  outlookSafeLinkWithTeemsRegExp: `${OUTLOOK_SAFE_LINK_REGEXP}/[A-z0-9-/]+[%-/?]+url+[=]+https+[A-z0-9%]+teams.microsoft.com[A-z0-9%-/?=]+`,
  outlookSafeLinkWithMeetRegExp: `${OUTLOOK_SAFE_LINK_REGEXP}/[%-/?]+url+[=]+https+[A-z0-9%]+[a-zA-Z0-9]{1,18}.meet.google.com[A-z0-9%-/?=]+`,
  webexUrlRegExp: /^https:\/\/([a-zA-Z0-9-]+\.)*webex\.com\/(meet\/[a-zA-Z0-9._-]+|[^/]+\/j\.php\?MTID=[a-zA-Z0-9]+)$/,
} as const;

export default MEETING_PLATFORM_URL_REGEXPS;
