// Material UI
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import EditIcon from '@material-ui/icons/Edit';
// Lib Shared
import meetingNotesIntegrationImage from '../assets/icon-custom-meeting-notes-integration-36.svg';
import notionIntegrationImage from '../assets/icon-notion-integration-24.svg';
import slackIntegrationImage from '../assets/icon-slack-integration-24.svg';
import transcriptIntegrationImage from '../assets/icon-custom-trascript-integration-36.svg';
import {
  ExtendedFlowInstance,
  GenericNotionDatabase,
  GenericOutboundIntegration,
  GenericSlackDestination,
  IntegrationTypes,
} from '../types';

interface MeetingIntegrationsMenuItemsProps {
  disabled?: boolean;
  outboundIntegrations: GenericOutboundIntegration[];
  fabricAutomations: ExtendedFlowInstance[];
  loading?: boolean;
  onClickOnCustomItem: (integrationType: IntegrationTypes, integrationId: string) => void;
  onClickOnEditNotion: () => void;
  onClickOnEditSlack: () => void;
  onClickOnFabricItem: (automation: ExtendedFlowInstance) => void;
  onClickOnNotionItem: (destination: GenericNotionDatabase | null) => void;
  onClickOnSlackItem: (destination: GenericSlackDestination | null) => void;
}

/**
 * @component MeetingIntegrationsMenuItems
 * @description A component that renders a list of integrations menu items
 * @param {MeetingIntegrationsMenuItemsProps} props
 * @returns {JSX.Element}
 */
export const MeetingIntegrationsMenuItems: React.VFC<MeetingIntegrationsMenuItemsProps> = ({
  disabled = false,
  fabricAutomations,
  loading = false,
  outboundIntegrations,
  onClickOnCustomItem,
  onClickOnEditNotion,
  onClickOnEditSlack,
  onClickOnFabricItem,
  onClickOnNotionItem,
  onClickOnSlackItem,
}) => {
  const styles = useStyles();

  /* #region Handlers */
  const handleClickOnSlackDestinationItem = (destination: GenericSlackDestination | null) => () => {
    onClickOnSlackItem(destination);
  };

  const handleClickOnNotionDestinationItem = (database: GenericNotionDatabase | null) => () => {
    onClickOnNotionItem(database);
  };

  const handleClickOnFabricItem = (automation: ExtendedFlowInstance) => () => {
    onClickOnFabricItem(automation);
  };

  const handleClickOnCustomIntegrationItem =
    (integrationType: IntegrationTypes, integrationId: string) => () => {
      onClickOnCustomItem(integrationType, integrationId);
    };

  const handleClickOnChangeSlackDestination = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClickOnEditSlack();
  };

  const handleClickOnChangeDatabase = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClickOnEditNotion();
  };
  /* #endregion */

  /* #region Render Helpers */
  const tooltipTitle = loading ? 'Loading content...' : 'There is no content to post';
  /* #endregion */

  return (
    <Box maxHeight="30vh" overflow="auto" mb={1}>
      <Box px={2} my={2}>
        <Typography component="div">
          <b>Post</b>
        </Typography>
      </Box>
      {outboundIntegrations.map(({ childObject }) => {
        if (childObject?.__typename === 'SlackUserIntegrationType') {
          return (
            <Tooltip
              key={childObject.id}
              arrow
              classes={{ tooltip: styles.tooltip }}
              title={tooltipTitle}
              disableFocusListener={!disabled}
              disableHoverListener={!disabled}
              disableTouchListener={!disabled}
            >
              <div>
                <MenuItem
                  disabled={disabled}
                  className={styles.menuItem}
                  onClick={handleClickOnSlackDestinationItem(childObject.lastUsedDestination)}
                >
                  <div className={styles.content}>
                    <img
                      src={slackIntegrationImage}
                      alt="Slack Integration"
                      width={24}
                      height={24}
                    />
                    <Box display="flex" ml={2} mr={1}>
                      <span>Post Meeting Notes to</span>
                      <Tooltip
                        arrow
                        title={childObject.lastUsedDestination?.name || 'Select a destination'}
                        classes={{ tooltip: styles.tooltip }}
                      >
                        <Typography noWrap variant="inherit" className={styles.label}>
                          <b>{childObject.lastUsedDestination?.name || 'Select a destination'}</b>
                        </Typography>
                      </Tooltip>
                    </Box>
                  </div>
                  <IconButton
                    size="small"
                    className={`${styles.iconButton} ghostly`}
                    onClick={handleClickOnChangeSlackDestination}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </MenuItem>
              </div>
            </Tooltip>
          );
        }
        if (childObject?.__typename === 'NotionMeetingNotesUserIntegrationType') {
          return (
            <Tooltip
              key={childObject.id}
              arrow
              classes={{ tooltip: styles.tooltip }}
              title={tooltipTitle}
              disableFocusListener={!disabled}
              disableHoverListener={!disabled}
              disableTouchListener={!disabled}
            >
              <div>
                <MenuItem
                  disabled={disabled}
                  className={styles.menuItem}
                  onClick={handleClickOnNotionDestinationItem(childObject.lastUsedDatabase)}
                >
                  <div className={styles.content}>
                    <img src={notionIntegrationImage} alt="Notion Integration" />
                    <Box display="flex" ml={2} mr={1}>
                      <span>Post Meeting Notes to </span>
                      <Tooltip
                        arrow
                        title={childObject.lastUsedDatabase?.name || 'Select a destination'}
                        classes={{ tooltip: styles.tooltip }}
                      >
                        <Typography noWrap variant="inherit" className={styles.label}>
                          <b>{childObject.lastUsedDatabase?.name || 'Select a destination'}</b>
                        </Typography>
                      </Tooltip>
                    </Box>
                  </div>
                  <IconButton
                    size="small"
                    className={`${styles.iconButton} ghostly`}
                    onClick={handleClickOnChangeDatabase}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </MenuItem>
              </div>
            </Tooltip>
          );
        }
        if (
          childObject?.__typename === 'MeetingNotesCustomOutboundUserIntegrationType' &&
          childObject?.isActive
        ) {
          return (
            <MenuItem
              key={childObject.id}
              disabled={disabled}
              className={styles.menuItem}
              title={`Post Meeting Notes to ${childObject.title}`}
              onClick={handleClickOnCustomIntegrationItem(
                IntegrationTypes.MEETING_NOTES_CUSTOM_OUTBOUND_USER_INTEGRATION,
                childObject.id,
              )}
            >
              <div className={styles.content}>
                <img
                  src={meetingNotesIntegrationImage}
                  alt="Custom Meeting Notes Integration"
                  width={24}
                  height={24}
                />
                <Box display="flex" ml={2} mr={1}>
                  <span>Post Meeting Notes to</span>
                  <Typography noWrap variant="inherit" className={styles.label}>
                    <b>{childObject.title}</b>
                  </Typography>
                </Box>
              </div>
            </MenuItem>
          );
        }
        if (
          childObject?.__typename === 'TranscriptionCustomOutboundUserIntegrationType' &&
          childObject?.isActive
        ) {
          return (
            <MenuItem
              key={childObject.id}
              disabled={disabled}
              className={styles.menuItem}
              title={`Post Transcription to ${childObject.title}`}
              onClick={handleClickOnCustomIntegrationItem(
                IntegrationTypes.TRANSCRIPTION_CUSTOM_OUTBOUND_USER_INTEGRATION,
                childObject.id,
              )}
            >
              <div className={styles.content}>
                <img
                  src={transcriptIntegrationImage}
                  alt="Custom Transcription Integration"
                  width={24}
                  height={24}
                />
                <Box display="flex" ml={2} mr={1}>
                  <span>Post Transcription to</span>
                  <Typography noWrap variant="inherit" className={styles.label}>
                    <b>{childObject.title}</b>
                  </Typography>
                </Box>
              </div>
            </MenuItem>
          );
        }
        return null;
      })}
      {fabricAutomations.map((automation) => {
        if (
          !automation.flow ||
          !(
            automation.flow.key === 'push-meeting-notes' ||
            automation.flow.key === 'push-transcriptions'
          )
        ) {
          return null;
        }

        const isNotesType = automation.flow.key === 'push-meeting-notes';

        return (
          <MenuItem
            key={automation.id}
            disabled={disabled}
            className={styles.menuItem}
            title={`Post ${isNotesType ? 'Meeting Notes' : 'Transcription'} to ${automation.name}`}
            onClick={handleClickOnFabricItem(automation)}
          >
            <div className={styles.content}>
              {!!automation.integration?.logoUri && (
                <img
                  src={automation.integration?.logoUri}
                  alt={automation.name}
                  width={24}
                  height={24}
                />
              )}
              <Box display="flex" ml={2} mr={1}>
                <span>{isNotesType ? 'Post Meeting Notes to' : 'Post Transcription to'}</span>
                <Tooltip title={automation.name} classes={{ tooltip: styles.tooltip }}>
                  <Typography noWrap variant="inherit" className={styles.label}>
                    <b>{automation.name}</b>
                  </Typography>
                </Tooltip>
              </Box>
            </div>
          </MenuItem>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .ghostly': {
      visibility: 'hidden',
      [theme.breakpoints.down('xs')]: {
        visibility: 'visible',
      },
    },
    '&:hover .ghostly': {
      visibility: 'visible',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  tooltip: {
    fontSize: theme.typography.body1.fontSize,
  },
  iconButton: {
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    display: 'block',
    maxWidth: '10rem',
    marginLeft: theme.spacing(0.5),
  },
}));
