import { useContext } from 'react';
import { AutocompleteContext } from '../contexts';

export const useAutocomplete = () => {
    const {
        isOpenShareDialog,
        isLoadingSuggestions,
        options,
        target,
        inviteValue,
        calledSubmit,
        handlePaste,
        setTarget,
        setCalledSubmit,
        handleChangeInput,
        handleChangeValue,
        setOpenShareDialog,
    } = useContext(AutocompleteContext);

    return {
        isOpenShareDialog,
        isLoadingSuggestions,
        options,
        target,
        inviteValue,
        calledSubmit,
        setTarget,
        handlePaste,
        setCalledSubmit,
        handleChangeInput,
        handleChangeValue,
        setOpenShareDialog,
    };
};

export default useAutocomplete;
