import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
// Material UI
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material Icons
import AssignmentsIcon from '@shared/icons/Assignments';
import CheckIcon from '@material-ui/icons/Check';
import MeetingNotesIcon from '@material-ui/icons/Assignment';
import SubjectIcon from '@material-ui/icons/Subject';
import { Creation as InsightsIcon } from '@sembly-ui';
// App Shared
import { Routes } from '@shared/enums';
import { generateTargetPath } from '@shared/utils';

export interface MeetingNavMenuDesktopProps {
  expanded?: boolean;
  meetingId: string;
  restrictAssignments?: boolean;
  restrictKeyItems?: boolean;
  restrictMeetingNotes?: boolean;
  restrictAiAssociate?: boolean;
}

/**
 * The desktop version of the meeting's navigation menu
 */
export const MeetingNavMenuDesktop: React.VFC<MeetingNavMenuDesktopProps> = ({
  expanded = false,
  meetingId,
  restrictAssignments = false,
  restrictKeyItems = false,
  restrictMeetingNotes = false,
  restrictAiAssociate = false,
}) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={clsx(styles.content, expanded && 'expanded')}>
        {!restrictMeetingNotes && (
          <Button
            disableElevation
            size="large"
            variant="outlined"
            startIcon={<MeetingNotesIcon />}
            classes={{ startIcon: styles.startIcon }}
            className={styles.button}
            activeClassName={styles.buttonActive}
            component={NavLink}
            to={generateTargetPath(Routes.MeetingNotes, meetingId)}
            aria-label="Navigate to Meeting Notes"
          >
            <Typography component="span" variant="body2" className={styles.lurk}>
              Meeting Notes
            </Typography>
          </Button>
        )}
        {!restrictAiAssociate && (
          <Button
            disableElevation
            size="large"
            variant="outlined"
            startIcon={<InsightsIcon />}
            classes={{ startIcon: styles.startIcon }}
            className={styles.button}
            activeClassName={styles.buttonActive}
            component={NavLink}
            to={generateTargetPath(Routes.MeetingInsights, meetingId)}
            aria-label="Navigate to My Insights"
          >
            <Typography component="span" variant="body2" className={styles.lurk}>
              My Insights
            </Typography>
          </Button>
        )}
        {!restrictAssignments && (
          <Button
            disableElevation
            size="large"
            variant="outlined"
            startIcon={<AssignmentsIcon />}
            classes={{ startIcon: styles.startIcon }}
            className={styles.button}
            activeClassName={styles.buttonActive}
            component={NavLink}
            to={generateTargetPath(Routes.MeetingAssignments, meetingId)}
            aria-label="Navigate to Meeting Tasks"
          >
            <Typography component="span" variant="body2" className={styles.lurk}>
              Tasks
            </Typography>
          </Button>
        )}
        {!restrictKeyItems && (
          <Button
            disableElevation
            size="large"
            variant="outlined"
            startIcon={<CheckIcon />}
            classes={{ startIcon: styles.startIcon }}
            className={styles.button}
            activeClassName={styles.buttonActive}
            component={NavLink}
            to={generateTargetPath(Routes.MeetingKeyItems, meetingId)}
            aria-label="Navigate to Key Items View"
          >
            <Typography component="span" variant="body2" className={styles.lurk}>
              Key Items
            </Typography>
          </Button>
        )}
        <Button
          disableElevation
          size="large"
          variant="outlined"
          startIcon={<SubjectIcon />}
          component={NavLink}
          activeClassName={styles.buttonActive}
          classes={{ startIcon: styles.startIcon }}
          className={styles.button}
          to={generateTargetPath(Routes.MeetingTranscript, meetingId)}
          aria-label="Navigate to Transcription View"
        >
          <Typography component="span" variant="body2" className={styles.lurk}>
            Transcription
          </Typography>
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    top: 0,
    paddingTop: theme.spacing(6),
    marginRight: theme.spacing(3),
    position: 'sticky',
    width: 64,
    zIndex: 100,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'absolute',
    borderRadius: '2em',
    border: '1px solid',
    borderColor: theme.palette.grey[100],
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    gap: theme.spacing(0.5),
    transition: 'all 0.25s cubic-bezier(0.52, 0.16, 0.24, 1)',
    '& $startIcon': {
      justifyContent: 'center',
    },
    '&:hover, &.expanded': {
      boxShadow: theme.shadows[12],
      '& $lurk': {
        opacity: 1,
      },
      '& $button': {
        minWidth: '9rem',
      },
    },
  },
  button: {
    border: 0,
    paddingLeft: theme.spacing(3),
    transition: 'all 0.25s cubic-bezier(0.52, 0.16, 0.24, 1)',
    justifyContent: 'flex-start',
    width: 48,
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.text.primary,
    },
  },
  buttonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& $startIcon': {
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      cursor: 'default',
    },
  },
  lurk: {
    opacity: 0,
    whiteSpace: 'nowrap',
    transition: 'opacity 0.15s cubic-bezier(0.52, 0.16, 0.24, 1)',
  },
  startIcon: {
    color: theme.palette.grey[600],
  },
}));

export default MeetingNavMenuDesktop;
