/**
 * Use the event command to send event data to `gtag`.
 */
export const event = (arg: {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}) => {
  if (!window.gtag) return;
  if (!process.env.REACT_APP_GA_ID) {
    console.warn('Tracking not enabled, as `trackingId` was not given and there is no `GA_ID`.');
    return;
  }

  window.gtag('event', arg.action, {
    event_category: arg.category,
    event_label: arg.label,
    value: arg.value,
    non_interaction: arg.nonInteraction,
    event_user_id: window.SemblyGAMeta?.userId,
    event_workspace_id: window.SemblyGAMeta?.workspaceId,
  });
};
