import { useState } from 'react';
import { useQuery } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/CloseSharp';
import SearchIcon from '@material-ui/icons/SearchSharp';
// GraphQL Queries and Types
import query from '../graphql/queries/WorkstreamsListContainerQuery.graphql';
import { WorkstreamsListContainerQuery, WorkstreamsListContainerQueryVariables } from '../types';
import { WorkstreamCard } from '../components';

export interface WorkstreamsListContainerProps {
  processing: boolean;
  onSelect: (id: string, name: string) => void;
}

export const WorkstreamsListContainer: React.VFC<WorkstreamsListContainerProps> = ({
  processing,
  onSelect,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [filterValue, setFilterValue] = useState('');

  const { data, loading } = useQuery<
    WorkstreamsListContainerQuery,
    WorkstreamsListContainerQueryVariables
  >(query, { variables: { isActive: true } });
  /* #endregion */

  /* #region  Handlers */
  const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  const handleClearFilterValue = () => {
    setFilterValue('');
  };

  const handleClickOnItem = (id: string, name: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(id, name);
  };
  /* #endregion */

  const items = data?.workstreams || [];
  const filteredItems = items.filter((workstream) =>
    workstream.name.toLowerCase().includes(filterValue.toLowerCase()),
  );

  return (
    <div className={styles.root}>
      {loading || processing ? (
        <Box display="flex" justifyContent="center" my={8}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TextField
            autoFocus
            fullWidth
            size="small"
            variant="filled"
            placeholder="Search workstreams"
            disabled={!items.length}
            value={filterValue}
            onChange={handleChangeFilterValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" color="disabled" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {!!filterValue && (
                    <IconButton size="small" onClick={handleClearFilterValue}>
                      <CloseIcon fontSize="small" color="primary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <div>
            {!items.length ? (
              <Box mt={2}>
                <Typography>There are currently no workstreams available</Typography>
              </Box>
            ) : (
              <>
                <div className={styles.content}>
                  {filteredItems.map((workstream) => (
                    <WorkstreamCard
                      unread={false}
                      key={workstream.id}
                      title={workstream.name}
                      tag={workstream.tag || ''}
                      chatsCount={workstream.numRelatedChats}
                      lastActivity={workstream.latestActivity}
                      tasksCount={workstream.numRelatedTasks}
                      meetingsCount={workstream.numRelatedMeetings}
                      type={workstream.isRelatedToContextUser ? 'Personal' : 'Team'}
                      onClick={handleClickOnItem(workstream.id, workstream.name)}
                      onClickOnCreateChat={handleClickOnItem(workstream.id, workstream.name)}
                      processing={processing}
                    />
                  ))}

                  {!!filterValue && !filteredItems.length && (
                    <Box my={4}>
                      <Typography align="center">No results found for "{filterValue}"</Typography>
                    </Box>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2, 0),
  },
}));

export default WorkstreamsListContainer;
