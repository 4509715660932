import { ZERO_DECIMAL_CURRENCIES } from '@sembly-ui';

/**
 * Calculate the discount amount based on the discount percent or discount amount.
 * The discount amount is calculated as follows:
 * - If the discount percent is provided, the discount amount is calculated as the percentage of the amount.
 * - If the discount amount is provided, the discount amount is used.
 * - If both discount percent and discount amount are provided, the discount amount is calculated based on the discount percent.
 * - If neither discount percent nor discount amount are provided, the discount amount is zero.
 * The discount amount is rounded to the upper integer if the currency is zero decimal.
 * @param discountPercent The discount percent.
 * @param discountAmount The discount amount.
 * @param amount The amount to apply the discount.
 * @param currencyCode The currency code.
 * @returns The discount amount.
 */
export function calculateDiscountAmount(
  discountPercent: number,
  discountAmount: number,
  amount: number,
  currencyCode: string = 'USD',
): number {
  const value = !!discountPercent ? amount * (discountPercent / 100) : discountAmount;
  const isZeroDecimalCurrency = ZERO_DECIMAL_CURRENCIES.includes(currencyCode.toUpperCase());
  return isZeroDecimalCurrency ? Math.ceil(value) : value;
}

export default calculateDiscountAmount;
