import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useHistory, generatePath } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import HelpIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Sembly UI
import { SearchBox } from '@sembly-ui';
// App Shared
import ShowVideoOnboardingDialog from '@shared/dialogs/ShowVideoOnboardingDialog';
import TutorialsDialog from '@shared/dialogs/TutorialsDialog';
import { APP_DRAWER_WIDTH } from '@shared/configuration';
import { AppBar } from '@shared/components';
import { Routes as R } from '@shared/enums';
import { ga } from '@shared/utils';
import { useUserInterface } from '@shared/hooks';
// GraphQL Queries and Types
import searchQuery from '@shared/queries/MeetingsPaginated.graphql';
import { MeetingsPaginated, MeetingsPaginatedVariables, MeetingStatuses } from '@gql-types';

export const MyWorkstreamsPageLayout: React.FC = ({ children }) => {
  /* #region  Hooks */
  const { isOpenIntroduction: isOpenGettingStarted, isOpenTutorial, update } = useUserInterface();

  // theme
  const styles = useStyles();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  // router
  const history = useHistory();

  // apollo
  const [find, { fetchMore, refetch, data, called, networkStatus }] = useLazyQuery<
    MeetingsPaginated,
    MeetingsPaginatedVariables
  >(searchQuery, { notifyOnNetworkStatusChange: true });
  /* #endregion */

  /* #region  Handlers */
  const handleSearch = ({
    search,
    page = 1,
    perPage = 6,
    ...other
  }: MeetingsPaginatedVariables) => {
    if (search && called && refetch) {
      refetch({ search, page, perPage, statuses: [MeetingStatuses.submitted], ...other });
    } else {
      find({
        variables: {
          search,
          page,
          perPage,
          statuses: [MeetingStatuses.submitted],
          ...other,
        },
      });
    }
    ga.event({ category: 'Search', action: 'Type Search Term' });
  };

  const handleDrawerOpen = () => {
    update({ isAppDrawerOpen: true });
  };

  const handleRequestNextSearchPage = () => {
    if (fetchMore && data?.meetingsPaginated?.page) {
      fetchMore({ variables: { page: data.meetingsPaginated.page + 1 } });
      ga.event({ category: 'Search', action: 'Requested Next Search Page' });
    }
  };

  const handleJumpToMeeting = (meetingId: string) => {
    history.push(generatePath(R.Meeting, { meetingId }));
    ga.event({ category: 'Search', action: 'Jump to Search Result' });
  };
  /* #endregion */

  /* #region  Render Helpers */
  const fetchingStatuses = [
    NetworkStatus.loading,
    NetworkStatus.setVariables,
    NetworkStatus.fetchMore,
    NetworkStatus.refetch,
  ];

  const isFetching = fetchingStatuses.includes(networkStatus);
  /* #endregion */

  return (
    <>
      <div className={styles.root}>
        {/* Begin: AppDrawer placeholder */}
        {/* AppDrawer currenty always open on desktops */}
        {!isSmallScreen && <Box flex="0 1 auto" width={APP_DRAWER_WIDTH} />}
        {/* End: AppDrawer placeholder */}
        <div className={styles.content}>
          <AppBar toolbarClassName={styles.appbar} layout="extra-wide">
            {isSmallScreen && (
              <IconButton onClick={handleDrawerOpen} size="small" className={styles.menuIcon}>
                <MenuIcon />
              </IconButton>
            )}
            <div className={styles.nogrow}>
              <Typography
                noWrap
                component="div"
                variant={isSmallScreen ? 'body2' : 'body1'}
                className={styles.title}
              >
                <b>My Workstreams</b>
              </Typography>
            </div>
            <div className={styles.grow}>
              {!isSmallScreen && (
                <SearchBox
                  isFetching={isFetching}
                  placeholder="Search meetings"
                  data={data?.meetingsPaginated?.objects}
                  hasNextPage={data?.meetingsPaginated?.hasNext || false}
                  onChangeSearchTerm={handleSearch}
                  onSelectSearchResult={handleJumpToMeeting}
                  onRequestNextPage={handleRequestNextSearchPage}
                />
              )}
            </div>
            <div className={styles.nogrow}>
              <IconButton
                title="Help"
                target="_blank"
                rel="noopener noreferrer"
                href="https://helpdesk.sembly.ai/hc/en-us"
              >
                <HelpIcon fontSize="small" />
              </IconButton>
            </div>
          </AppBar>

          <div className={styles.appBarSpacer} />

          {children}
        </div>
      </div>
      {/* Dialogs: Begin */}
      {isOpenGettingStarted && <ShowVideoOnboardingDialog />}
      {isOpenTutorial && <TutorialsDialog />}
      {/* Dialogs: End */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  loading: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    marginLeft: theme.spacing(-2.5),
    marginRight: theme.spacing(1),
  },
  appbar: {
    gap: theme.spacing(1),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  grow: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  nogrow: {
    flexGrow: 0,
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

export default MyWorkstreamsPageLayout;
