import { lazyWithPreload } from '@shared/utils';
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const SharedMeetingsModuleContainer = lazyWithPreload(
  () => import('./containers/SharedMeetingsModuleContainer'),
);

export const SharedMeetingsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.SharedWithMe,
    render: () => <SharedMeetingsModuleContainer />,
  },
};

export default SharedMeetingsModule;
