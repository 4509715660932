// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

export interface TasksItemsSkeletonProps {
  count?: number;
}

export const TasksItemsSkeleton: React.VFC<TasksItemsSkeletonProps> = ({ count = 10 }) => {
  const styles = useStyles();

  return (
    <List>
      {[...Array(count)].map((_, index) => (
        <ListItem key={index} className={styles.item}>
          <ListItemText primary={<Skeleton width={Math.random() * 100 + 200} />} />
          <ListItemSecondaryAction>
            <Skeleton variant="circle" width={24} height={24} />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1),
    background: theme.palette.action.selected,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

export default TasksItemsSkeleton;
