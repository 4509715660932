import { generatePath } from 'react-router-dom';
import { Routes } from '@shared/enums';
import { isGuestAccessRoute } from './IsGuestAccessRoute';

export function generateTargetPath(route: string, meetingId: string) {
  const isGuestMode = isGuestAccessRoute();
  const target = isGuestMode ? route.replace(Routes.Meeting, Routes.GuestMeeting) : route;

  return isGuestMode
    ? generatePath(target, { meetingToken: meetingId })
    : generatePath(target, { meetingId });
}

export default generateTargetPath;
