import { Redirect } from 'react-router-dom';
// App Shared
import { ProtectedRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

/**
 * @deprecated due to the new MyAssignments page
 * @todo remove this module once the old MyCommitments page is no longer needed
 * @name MyCommitmentsModule
 * @description Module responsible for the rendering of the user commitments pages
 * @returns JSX.Element
 */
export const MyCommitmentsModule: ProtectedRouterModule = {
  isProtected: true,
  routeProps: {
    path: Routes.MyCommitments,
    render: () => <Redirect to={Routes.MyAssignments} />,
  },
};

export default MyCommitmentsModule;
