import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Material UI Icons
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
// GraphQL Queries and Types
import query from '../graphql/queries/WorkstreamsContainerQuery.graphql';
import createChatMutation from '../graphql/mutations/CreateAssociatesChat.graphql';
import editWorkstreamMutation from '../graphql/mutations/EditWorkstream.graphql';
import markAsReadMutation from '../graphql/mutations/MarkWorkstreamsAsRead.graphql';
import { formatInTimeZone } from '../utils';
import {
  CreateAssociatesChat,
  CreateAssociatesChatVariables,
  EditWorkstream,
  EditWorkstreamVariables,
  GenericWorkstream,
  GraphError,
  MarkWorkstreamsAsRead,
  WorkstreamsContainerQuery,
  WorkstreamsContainerQueryVariables,
} from '../types';
// Lib Shared
import { WorkstreamCard, WorkstreamCardMenuDesktop, GenericDialog } from '../components';

export interface WorkstreamsContainerProps {
  onCreatedChat: (chatId: string) => void;
  onResponseError: (error: GraphError) => void;
}

export const WorkstreamsContainer: React.VFC<WorkstreamsContainerProps> = ({
  onCreatedChat,
  onResponseError,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [tab, setTab] = useState<'active' | 'inactive'>('active');
  const [collapsedSections, setCollapsedSections] = useState<string[]>([]);
  const [hiddenSections, setHiddenSections] = useState<string[]>([]);
  const [processingWorkstreamId, setProcessingWorkstreamId] = useState<string | null>(null);
  const [selectedDescription, setSelectedDescription] = useState<null | GenericWorkstream>(null);
  const [selectedWorkstream, setSelectedWorkstream] = useState<null | {
    workstream: GenericWorkstream;
    element: HTMLElement;
  }>(null);

  const { data, networkStatus, refetch } = useQuery<
    WorkstreamsContainerQuery,
    WorkstreamsContainerQueryVariables
  >(query, {
    variables: { isActive: true, search: '' },
    notifyOnNetworkStatusChange: true,
  });

  const [createChat] = useMutation<CreateAssociatesChat, CreateAssociatesChatVariables>(
    createChatMutation,
  );

  const [editWorkstream] = useMutation<EditWorkstream, EditWorkstreamVariables>(
    editWorkstreamMutation,
  );

  const [markAsRead] = useMutation<MarkWorkstreamsAsRead>(markAsReadMutation);
  /* #endregion */

  /* #region  Handlers */
  const handleSearchDebounced = useDebouncedCallback((value) => {
    const term = value ? value.toLowerCase() : '';
    refetch({ search: term, isActive: tab === 'active' });
  }, 1000);

  const handleUpdateSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchDebounced(value);
  };

  const handleClearSearchTerm = () => {
    setSearchTerm('');
    refetch({ search: '', isActive: tab === 'active' });
  };

  const handleCreateChat = (workstream: GenericWorkstream) => async (e: React.MouseEvent) => {
    e.stopPropagation();

    const workstreamId = workstream.id;
    const workstreamName = workstream.name;
    const currentDate = formatInTimeZone(new Date(), 'PP p');
    const name = `${workstreamName} | ${currentDate}`;

    setProcessingWorkstreamId(workstreamId);

    const response = await createChat({
      variables: { workstreamId, name },
      update: (cache) => {
        // Remove the chatsPaginated cache to force a refetch
        cache.evict({ fieldName: 'chatsPaginated' });
      },
    });

    setProcessingWorkstreamId(null);

    if (response.data?.createChat?.chat?.id) {
      onCreatedChat(response.data.createChat.chat.id);
    } else {
      onResponseError(response.data?.createChat?.errors);
    }
  };

  const handleToggleSection = (date: string) => () => {
    const id = `${tab}-${date}`;
    setCollapsedSections((sections) => {
      return sections.includes(id)
        ? sections.filter((section) => section !== id)
        : [...sections, id];
    });
  };

  const handleToggleHiddenSection = (date: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const id = `${tab}-${date}`;
    setHiddenSections((sections) => {
      return sections.includes(id)
        ? sections.filter((section) => section !== id)
        : [...sections, id];
    });
  };

  const handleOpenMenu = (workstream: GenericWorkstream) => (anchorEl: HTMLElement) => {
    setSelectedWorkstream({ workstream, element: anchorEl });
  };

  const handleHideMenu = () => {
    setSelectedWorkstream(null);
  };

  const handleChange = async (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue as 'active' | 'inactive');
    await refetch({ isActive: newValue === 'active' });
  };

  const handlePinWorkstream = (workstream: GenericWorkstream) => async () => {
    const workstreamId = workstream.id;
    const isPinned = !workstream.isPinned;
    const isHidden = workstream.isHidden;

    const result = await editWorkstream({
      variables: { id: +workstreamId, isPinned, isHidden },
      update: (cache) => {
        // Update the cache to reflect the new pinned state
        cache.modify({
          id: cache.identify({ __typename: 'WorkstreamType', id: workstreamId }),
          fields: {
            isPinned() {
              return isPinned;
            },
          },
        });

        if (!isPinned) {
          // Remove the pinnedWorkstreams cache to force a refetch
          cache.updateQuery<WorkstreamsContainerQuery, WorkstreamsContainerQueryVariables>(
            { query, variables: { isActive: tab === 'active', search: searchTerm } },
            (data) => {
              if (!data?.pinnedWorkstreams?.length) return data;
              return {
                ...data,
                pinnedWorkstreams: data?.pinnedWorkstreams.filter((ws) => ws.id !== workstreamId),
              };
            },
          );
        } else {
          // Add pinnedWorkstreams to the cache
          cache.updateQuery<WorkstreamsContainerQuery, WorkstreamsContainerQueryVariables>(
            { query, variables: { isActive: tab === 'active', search: searchTerm } },
            (data) => {
              if (!data) return data;
              return {
                ...data,
                pinnedWorkstreams: uniqBy(
                  [
                    { ...workstream, isPinned: !workstream.isPinned },
                    ...(data.pinnedWorkstreams ?? []),
                  ],
                  'id',
                ),
              };
            },
          );
        }
      },
    });

    if (result.data?.manageWorkstreamState?.errors) {
      onResponseError(result.data.manageWorkstreamState.errors);
    }
  };

  const handleHideWorkstream = (workstream: GenericWorkstream) => async () => {
    const workstreamId = workstream.id;
    const isPinned = workstream.isPinned;
    const isHidden = !workstream.isHidden;

    const result = await editWorkstream({
      variables: { id: +workstreamId, isHidden, isPinned },
      update: (cache) => {
        // Update the cache to reflect the new pinned state
        cache.modify({
          id: cache.identify({ __typename: 'WorkstreamType', id: workstreamId }),
          fields: {
            isHidden() {
              return isHidden;
            },
          },
        });
      },
    });

    if (result.data?.manageWorkstreamState?.errors) {
      onResponseError(result.data.manageWorkstreamState.errors);
    }
  };
  /* #endregion */

  // Mark the workstreams as read after 5 seconds
  useEffect(() => {
    const hasUnreadItems =
      !!data?.me?.numUnreadActiveWorkstreams || !!data?.me?.numUnreadInactiveWorkstreams;

    if (!hasUnreadItems) return;

    const timeout = setTimeout(() => {
      markAsRead();
    }, 5000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [markAsRead, data?.me?.numUnreadActiveWorkstreams, data?.me?.numUnreadInactiveWorkstreams]);

  /* #region  Render Helpers */
  const isLoading = networkStatus === NetworkStatus.loading;
  const isRefetching = networkStatus === NetworkStatus.refetch;
  const isSetVariables = networkStatus === NetworkStatus.setVariables;
  const workstreams = data?.workstreams ?? [];
  const hasUnreadActiveItems = !!data?.me?.numUnreadActiveWorkstreams;
  const hasUnreadInactiveItems = !!data?.me?.numUnreadInactiveWorkstreams;

  // Group the workstreams by whether they are related to the context user
  const groupedWorkstreams = groupBy(workstreams, (workstream) => {
    return workstream.isRelatedToContextUser ? 'Personal' : 'Team';
  });

  // Sort the grouped workstreams exactly in the order: Presonal, Team
  const sortedWorkstreamGroups = Object.entries(groupedWorkstreams).sort(([a], [b]) => {
    if (a === 'Personal') return -1;
    if (b === 'Personal') return 1;
    return 0;
  });

  const sortedGroupedWorkstreams = {
    ...(data?.pinnedWorkstreams?.length ? { Pinned: data.pinnedWorkstreams } : {}),
    ...Object.fromEntries(sortedWorkstreamGroups),
  };
  /* #endregion */

  return (
    <>
      {isLoading ? (
        <List>
          {[1, 2, 3].map((index) => (
            <ListItem key={index} classes={{ root: styles.paper }}>
              <ListItemText
                classes={{ primary: styles.primary, secondary: styles.secondary }}
                primary={<Skeleton className={styles.skeleton} variant="rect" />}
                secondary={<Skeleton className={styles.skeleton} variant="rect" />}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <>
          <Tabs
            value={tab}
            textColor="primary"
            indicatorColor="primary"
            className={styles.tabs}
            onChange={handleChange}
          >
            <Tab
              value="active"
              label="Active"
              className={clsx(styles.tab, { unread: hasUnreadActiveItems })}
            />
            <Tab
              value="inactive"
              label="Inactive"
              className={clsx(styles.tab, { unread: hasUnreadInactiveItems })}
            />
          </Tabs>

          <div className={styles.head}>
            <TextField
              fullWidth
              size="small"
              variant="filled"
              placeholder="Search workstreams..."
              value={searchTerm}
              onChange={handleUpdateSearchTerm}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      {isSetVariables ? (
                        <CircularProgress size={18} />
                      ) : (
                        <>
                          {!!searchTerm && (
                            <IconButton size="small" onClick={handleClearSearchTerm}>
                              <CloseIcon color="primary" />
                            </IconButton>
                          )}
                        </>
                      )}
                    </>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          {isRefetching || isSetVariables ? (
            <List>
              {[1, 2, 3].map((index) => (
                <ListItem key={index} classes={{ root: styles.paper }}>
                  <ListItemText
                    classes={{ primary: styles.primary, secondary: styles.secondary }}
                    primary={<Skeleton className={styles.skeleton} variant="rect" />}
                    secondary={<Skeleton className={styles.skeleton} variant="rect" />}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <div onMouseLeave={handleHideMenu}>
              {!isRefetching && !isSetVariables && workstreams.length > 0 ? (
                <>
                  {Object.entries(sortedGroupedWorkstreams).map(([section, items]) => {
                    const hiddenItemsCount = items.filter((item) => item.isHidden).length;
                    const unreadItemsCount = items.filter((item) => item.isUnread).length;
                    const isCollapsed = collapsedSections.includes(`${tab}-${section}`);
                    const isHidden = hiddenSections.includes(`${tab}-${section}`);

                    return (
                      <div key={`${tab}-${section}`} className={styles.list}>
                        <div className={styles.subheader} onClick={handleToggleSection(section)}>
                          <div className={styles.grow}>
                            <ExpandMoreIcon
                              className={clsx(styles.expandIcon, !isCollapsed && styles.expanded)}
                            />
                            {section}
                            <Chip
                              size="small"
                              color="primary"
                              label={items.length}
                              className={styles.itemsChip}
                            />
                            {!!unreadItemsCount && (
                              <Chip
                                size="small"
                                color="secondary"
                                label={`${unreadItemsCount} new`}
                                className={styles.unreadChip}
                              />
                            )}
                          </div>
                          {!!hiddenItemsCount && !isCollapsed && (
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={handleToggleHiddenSection(section)}
                            >
                              <Typography noWrap component="span" variant="body2">
                                {isHidden ? 'Show hidden' : 'Hide hidden'} ({hiddenItemsCount})
                              </Typography>
                            </Button>
                          )}
                        </div>

                        <Collapse in={!isCollapsed}>
                          {items.map((workstream) => {
                            if (isHidden && workstream.isHidden) return null;
                            return (
                              <WorkstreamCard
                                key={workstream.id}
                                chatsCount={workstream.numRelatedChats}
                                lastActivity={workstream.latestActivity}
                                meetingsCount={workstream.numRelatedMeetings}
                                processing={processingWorkstreamId === workstream.id}
                                tag={workstream.tag || ''}
                                tasksCount={workstream.numRelatedTasks}
                                title={workstream.name}
                                type={workstream.isRelatedToContextUser ? 'Personal' : 'Team'}
                                unread={workstream.isUnread}
                                onClick={() => setSelectedDescription(workstream)}
                                onClickOnCreateChat={handleCreateChat(workstream)}
                                onToggleMenu={handleOpenMenu(workstream)}
                              />
                            );
                          })}
                        </Collapse>

                        {!!selectedWorkstream && (
                          <WorkstreamCardMenuDesktop
                            editable
                            allowPinning
                            pinned={selectedWorkstream.workstream.isPinned}
                            hidden={selectedWorkstream.workstream.isHidden}
                            anchorEl={selectedWorkstream.element}
                            onClose={handleHideMenu}
                            onClickOnPin={handlePinWorkstream(selectedWorkstream.workstream)}
                            onClickOnToggleVisibility={handleHideWorkstream(
                              selectedWorkstream.workstream,
                            )}
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
                  <Typography variant="body1" align="center">
                    {!searchTerm
                      ? 'There are currently no workstreams available'
                      : 'No matching workstreams found'}
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </>
      )}
      {!!selectedDescription && (
        <GenericDialog
          title={selectedDescription.name}
          dialogProps={{ fullWidth: true, maxWidth: 'sm' }}
          onClose={() => setSelectedDescription(null)}
        >
          <DialogContent className={styles.dialogContent}>
            <Typography gutterBottom variant="body1">
              <b>Description</b>
            </Typography>
            <Typography variant="body1">{selectedDescription.description}</Typography>
          </DialogContent>
        </GenericDialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[200]}`,
    margin: theme.spacing(1, 0),
  },
  head: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5, 0),
    },
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  primary: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(1),
    maxWidth: '90ch',
  },
  secondary: {
    ...theme.typography.body1,
    maxWidth: '90ch',
  },
  skeleton: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: theme.shape.borderRadius * 2,
    background: theme.palette.grey[100],
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    marginLeft: `-${theme.spacing(2)}px`,
    marginRight: `-${theme.spacing(2)}px`,
    cursor: 'pointer',
  },
  list: {
    position: 'relative',
    padding: theme.spacing(0, 2, 1),
    background: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius * 2,
  },
  itemsChip: {
    minWidth: 30,
    background: theme.palette.grey[200],
    color: theme.palette.text.primary,
  },
  unreadChip: {
    minWidth: 30,
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  expandIcon: {
    position: 'relative',
    transition: 'transform 0.25s cubic-bezier(0.52, 0.16, 0.24, 1)',
    left: -2,
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
  tabs: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  tab: {
    minWidth: 100,
    '&.unread::after': {
      content: '""',
      position: 'absolute',
      top: 12,
      right: 0,
      width: 6,
      height: 6,
      borderRadius: '50%',
      background: theme.palette.error.main,
    },
  },
  dialogContent: {
    marginBottom: theme.spacing(3),
  },
}));

export default WorkstreamsContainer;
