// Material UI
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import { getChatArtefactMeta } from '../utils';
import { GenericChatArtifact } from '../types';

export interface ChatCardProps {
  artifacts: GenericChatArtifact[];
  description?: string | null;
  numArtifacts: number;
  title: string;
  onClick?: () => void;
  onClickOnArtifact: (artifact: GenericChatArtifact) => void;
  onToggleMenu: (anchorEl: HTMLElement) => void;
}

/**
 * Component that represents a chat card
 */
export const ChatCard: React.VFC<ChatCardProps> = ({
  artifacts,
  description,
  numArtifacts,
  title,
  onClick,
  onClickOnArtifact,
  onToggleMenu,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const handleToggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onToggleMenu(event.currentTarget);
  };

  const handleArtifactsClick = (artifact: GenericChatArtifact) => (event: React.MouseEvent) => {
    event.stopPropagation();
    onClickOnArtifact(artifact);
  };

  return (
    <Paper className={styles.root} onClick={onClick} onMouseEnter={handleToggleMenu}>
      <Typography variant="h6">{title}</Typography>
      {!!description && <Typography variant="body1">{description}</Typography>}
      {!!artifacts.length && (
        <div className={styles.actions}>
          {/* render first two artifacts */}
          {artifacts.slice(0, 2).map((artifact) => {
            if (!artifact.type) return null;

            const meta = getChatArtefactMeta(artifact.type, theme);
            if (!meta) return null;

            return (
              <Chip
                clickable
                key={artifact.id}
                size="small"
                variant="outlined"
                label={artifact.name}
                className={styles.chip}
                onClick={handleArtifactsClick(artifact)}
                icon={
                  <div className={styles.icon} style={{ backgroundColor: meta.color }}>
                    {meta.icon}
                  </div>
                }
              />
            );
          })}
          {numArtifacts > 2 && (
            <Chip
              size="small"
              variant="outlined"
              className={styles.chip}
              label={`+ ${numArtifacts - 2}`}
            />
          )}
        </div>
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.grey[50],
    },
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
    gridGap: theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
  },
  chip: {
    padding: theme.spacing(0.5, 1),
    overflow: 'hidden',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    height: 16,
    width: 16,
    fontSize: 12,
    marginLeft: theme.spacing(1),
  },
}));

export default ChatCard;
