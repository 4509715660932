import { MeetingStatusEntity, MeetingStatuses } from '../types';
import { CustomMeetingStatuses } from '../enums';
import { PALETTE } from './Palette';

export const MEETING_STATUSES: Record<
  MeetingStatuses | CustomMeetingStatuses,
  MeetingStatusEntity
> = {
  [MeetingStatuses.failed]: {
    title: 'No content',
    palette: PALETTE.error,
  },
  [MeetingStatuses.on_call]: {
    title: 'Live',
    palette: PALETTE.live,
  },
  [MeetingStatuses.on_pause]: {
    title: 'Paused',
    palette: PALETTE.live,
  },
  [MeetingStatuses.processing]: {
    title: 'Processing',
    palette: PALETTE.success,
  },
  [MeetingStatuses.scheduled]: {
    title: 'Will Attend',
    palette: PALETTE.scheduled,
  },
  [MeetingStatuses.not_scheduled]: {
    title: 'Not Attending',
    palette: PALETTE.nonscheduled,
  },
  [MeetingStatuses.submitted]: {
    title: 'Completed',
    palette: PALETTE.success,
  },
  [CustomMeetingStatuses.limit_exceeded]: {
    title: 'Limit Exceeded',
    palette: PALETTE.limitexceeded,
  },
} as const;

export default MEETING_STATUSES;
