import { lazyWithPreload } from '@shared/utils';
import { PublicRouterModule } from '@shared/types';
import { Routes } from '@shared/enums';

export const LoginModuleContainer = lazyWithPreload(
  () => import('./containers/LoginModuleContainer'),
);

export const LoginModule: PublicRouterModule = {
  isProtected: false,
  routeProps: {
    path: Routes.Login,
    render: () => <LoginModuleContainer />,
  },
};

export default LoginModule;
