import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { parseISO } from 'date-fns';
import { useState, memo } from 'react';
// Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import AssignmentCard from './AssignmentCard';
import { ActionItemMenuType, GenericAssignment } from '../types';

/* #region  Types */
export type MyAssignmentsGroupAttributes = 'isCompleted';

export interface MyAssignmentsGroupProps {
  enableBetaFeatures: boolean;
  isPinned?: boolean;
  isForceHiddenIntegrations?: boolean;
  items: GenericAssignment[];
  title: string;
  onChangeDiscussedWith: (keyItem: GenericAssignment, text: string) => void;
  onChangeText: (keyItem: GenericAssignment, text: string) => void;
  onChangeTitle: (keyItem: GenericAssignment, text: string) => void;
  onClickOnMeetingTitle: (meetingId: string) => void;
  onToggleAttribute: (attribute: MyAssignmentsGroupAttributes, item: GenericAssignment) => void;
  onToggleMenu: (
    data: { type: ActionItemMenuType; item: GenericAssignment; anchorEl: HTMLElement } | null,
  ) => void;
}
/* #endregion */

const Component: React.VFC<MyAssignmentsGroupProps> = ({
  enableBetaFeatures,
  isPinned = false,
  isForceHiddenIntegrations = false,
  items,
  onChangeDiscussedWith,
  onChangeText,
  onChangeTitle,
  onClickOnMeetingTitle,
  onToggleAttribute,
  onToggleMenu,
  title,
}) => {
  /* #region  Hooks */
  const styles = useStyles();

  const [shownInactive, setShownInactive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  /* #endregion */

  /* #region  Handlers */
  const handleToggleExpand = () => {
    setIsExpanded((currrentState) => !currrentState);
  };

  const handleToggleInactiveItems = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShownInactive((currentState) => !currentState);
  };

  const handleToggleAttribute =
    (attribute: MyAssignmentsGroupAttributes, actionItem: GenericAssignment) => () => {
      onToggleAttribute(attribute, actionItem);
    };

  const handleToggleMenu =
    (item: GenericAssignment) => (type: ActionItemMenuType, anchorEl: HTMLElement | null) => {
      onToggleMenu(anchorEl ? { type, item, anchorEl } : null);
    };

  const handleClickOnMeetingTitle = (meetingId: string) => () => {
    onClickOnMeetingTitle(meetingId);
  };

  const handleChangeText = (keyItem: GenericAssignment) => (text: string) => {
    onChangeText(keyItem, text);
  };

  const handleChangeTitle = (keyItem: GenericAssignment) => (text: string) => {
    onChangeTitle(keyItem, text);
  };

  const handleChangeInDiscussionWith = (keyItem: GenericAssignment) => (text: string) => {
    onChangeDiscussedWith(keyItem, text);
  };
  /* #endregion */

  // Render Helpers

  const hasInactiveItems = items.some((item) => !item.isActive && !isPinned);
  const hasActiveItems = items.some((item) => item.isActive);
  const unreadedItemsAmount = items.filter((item) => item.isUnread).length;
  const hasData = items.length > 0;

  // Don't render if there is no data
  if (!hasData) return null;

  return (
    <div className={styles.root}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ cursor: hasActiveItems || shownInactive ? 'pointer' : 'default' }}
        marginTop={1}
        marginBottom={1}
        onClick={hasActiveItems || shownInactive ? handleToggleExpand : undefined}
      >
        <Box flex={1} display="flex" alignItems="center" gridGap={8}>
          {(shownInactive || hasActiveItems) && (
            <ExpandMoreIcon className={clsx(styles.expandIcon, !isExpanded && styles.expanded)} />
          )}
          <Typography variant="h6">{title}</Typography>
          <Typography component="div" className={styles.quantity}>
            {items.length}
          </Typography>
          {!!unreadedItemsAmount && (
            <Typography component="div" className={styles.unreaded}>
              {unreadedItemsAmount} new
            </Typography>
          )}
        </Box>
        {isExpanded && hasInactiveItems && !isPinned && (
          <Button
            size="small"
            variant="outlined"
            className={styles.chipButton}
            onClick={handleToggleInactiveItems}
          >
            <span>{shownInactive ? 'Hide inactive ' : 'Show inactive '}</span>
          </Button>
        )}
      </Box>

      {shownInactive || hasActiveItems ? (
        <Collapse in={isExpanded}>
          <Grid container spacing={0}>
            <Box width="100%" my={1}>
              {items.map((item) => {
                const isInactiveItem = !item.isActive;
                const dueDate = item.dueDate && parseISO(item.dueDate);

                // Render inactive items only if they are shown
                if (isInactiveItem && !shownInactive) return null;

                // Don't render pinned items if they are innactive in pinned group
                if (isInactiveItem && item.isPinned && isPinned) return null;

                return (
                  <AssignmentCard
                    assignee={item.assignedBy || item.rawAssignedBy}
                    customText={item.customText || ''}
                    customeTitle={item.customHeaderText || ''}
                    discussedWith={item.inDiscussionWith}
                    dueDate={dueDate || item.rawTiming}
                    isActive={item.isActive}
                    isAuthorizedToEdit={item.meeting.permissions.canManage}
                    isAuthorizedToExport={item.meeting.permissions.canExport}
                    isAuthorizedToViewMeeting={item.meeting.permissions.canView}
                    isCompleted={item.isCompleted}
                    isEditedBySembly={item.editedBySembly}
                    isPerformer
                    isHiddenIntegrations={isForceHiddenIntegrations}
                    isPinned={item.isPinned}
                    isUnreaded={item.isUnread}
                    key={item.id}
                    keyItemId={item.id}
                    meetingStartDate={item.meeting.startedAt}
                    meetingTitle={item.meeting.title}
                    mode="extended"
                    performer={item.performer || item.rawPerformerName}
                    text={item.text || ''}
                    title={item.headerText || ''}
                    type={item.itemType}
                    workstreamName={enableBetaFeatures ? item.workstream?.name : undefined}
                    onChangeDiscussedWith={handleChangeInDiscussionWith(item)}
                    onChangeText={handleChangeText(item)}
                    onChangeTitle={handleChangeTitle(item)}
                    onClickOnMeetingTitle={handleClickOnMeetingTitle(item.meeting.id)}
                    onToggleIsCompleted={handleToggleAttribute('isCompleted', item)}
                    onToggleMenu={handleToggleMenu(item)}
                  />
                );
              })}
            </Box>
          </Grid>
        </Collapse>
      ) : (
        <Typography variant="body1" color="textSecondary">
          There are no active items in this group
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  expandIcon: {
    position: 'relative',
    transition: 'transform 0.25s cubic-bezier(0.52, 0.16, 0.24, 1)',
    left: -2,
  },
  expanded: {
    transform: 'rotate(180deg)',
  },
  chipButton: {
    height: 24,
    borderColor: theme.palette.grey['A100'],
    color: theme.palette.common.black,
  },
  quantity: {
    padding: theme.spacing(0, 1.25),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 10,
    backgroundColor: theme.palette.grey[200],
  },
  unreaded: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.indication.dark,
    padding: theme.spacing(0, 1.25),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 10,
  },
}));

function compareProps(prev: MyAssignmentsGroupProps, next: MyAssignmentsGroupProps) {
  // Compare data and return true if they are not need to be re-rendered
  return (
    prev.title === next.title &&
    prev.isPinned === next.isPinned &&
    prev.isForceHiddenIntegrations === next.isForceHiddenIntegrations &&
    isEqual(prev.items, next.items)
  );
}

/**
 * @name MyAssignmentsGroup
 * @description The component that renders a collapsible group of assignment cards
 * @param {MyAssignmentsGroupProps} props
 * @returns {JSX.Element}
 */
export const MyAssignmentsGroup = memo(Component, compareProps);

export default MyAssignmentsGroup;
