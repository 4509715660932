import { useState, useRef } from 'react';
// Material UI
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { SwitchProps } from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
// Shared Components
import { AccentSwitch } from './AccentSwitch';

export type AccentSplitSwitchMenuItem =
  | 'divider'
  | {
      icon: JSX.Element;
      label: string;
      onClick: () => void;
    };

export interface AccentSplitSwitchProps extends SwitchProps {
  label: string;
  menuItems: AccentSplitSwitchMenuItem[];
}

export const AccentSplitSwitch: React.VFC<AccentSplitSwitchProps> = ({
  menuItems,
  label,
  ...props
}) => {
  const styles = useStyles();
  const anchorRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuItemClick = (callback: () => void) => (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    callback();
    setShowMenu(false);
  };

  const handleOpenMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowMenu(true);
  };

  const handleCloseMenu = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    event.preventDefault();
    setShowMenu(false);
  };

  return (
    <>
      <div className={styles.root} ref={anchorRef}>
        <AccentSwitch label={label} {...props} />
        <div className={styles.divider} />
        <Button
          disableElevation
          variant="contained"
          size={props.size}
          color={props.checked ? 'primary' : 'default'}
          className={styles.addon}
          onClick={handleOpenMenu}
        >
          <ArrowDropDownIcon color={props.disabled ? 'disabled' : 'inherit'} />
        </Button>
      </div>
      <Popper open={showMenu} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <Menu
                  keepMounted
                  className={styles.menu}
                  open={showMenu}
                  anchorEl={anchorRef.current}
                  onClose={handleCloseMenu}
                >
                  {menuItems.map((item, index) =>
                    item === 'divider' ? (
                      <Divider
                        key={`AccentSplitSwitch-divider-${index}`}
                        className={styles.menuDivider}
                      />
                    ) : (
                      <MenuItem
                        key={`AccentSplitSwitch-item-${index}`}
                        className={styles.menuItem}
                        onClick={handleMenuItemClick(item.onClick)}
                      >
                        {item.icon}
                        <div className={styles.menuLabel}>{item.label}</div>
                      </MenuItem>
                    ),
                  )}
                </Menu>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& label': {
      borderRadius: '18px 0 0 18px',
      borderRight: 0,
    },
    '& button': {
      borderRadius: '0 18px 18px 0',
      borderLeft: 0,
    },
  },
  divider: {
    width: 1,
    background: 'rgba(0,0,0,0.05)',
  },
  menu: {
    maxWidth: 375,
    marginTop: 45,
    '& .MuiPaper-rounded': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  menuDivider: {
    margin: theme.spacing(1, 0),
  },
  menuLabel: {
    margin: theme.spacing(0, 1),
  },
  menuItem: {
    display: 'flex',
  },
  addon: {
    display: 'flex',
    height: 24,
    borderRadius: 18,
    alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    margin: 0,
    paddingRight: 16,
    paddingLeft: 14,
    width: 28,
    minWidth: 'auto',
  },
}));

export default AccentSplitSwitch;
