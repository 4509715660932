import tutorialImage1 from '@shared/assets/resourceCenter/tutorials/tutorial-1.png';
import tutorialImage2 from '@shared/assets/resourceCenter/tutorials/tutorial-2.png';
import tutorialImage3 from '@shared/assets/resourceCenter/tutorials/tutorial-3.png';
import tutorialImage4 from '@shared/assets/resourceCenter/tutorials/tutorial-4.png';
import tutorialImage5 from '@shared/assets/resourceCenter/tutorials/tutorial-5.png';
import tutorialImage6 from '@shared/assets/resourceCenter/tutorials/tutorial-6.png';

export const TUTORIALS = [
  {
    title: 'Sembly Basics',
    list: [
      {
        title: 'Сonnect your calendar and set up Sembly attendance mode',
        url: 'https://www.youtube.com/embed/JPa3vjvgbgc',
        preview: tutorialImage1,
      },
      {
        title: 'Instantly invite Sembly to your ongoing meeting',
        url: 'https://www.youtube.com/embed/iTQVs9CEie0',
        preview: tutorialImage2,
      },
      {
        title: 'Add Sembly to a scheduled meeting using calendar invite',
        url: 'https://www.youtube.com/embed/TKedHyOeGc0',
        preview: tutorialImage3,
      },
      {
        title: 'Record a meeting in Sembly',
        url: 'https://www.youtube.com/embed/TRUYmTYjiTE',
        preview: tutorialImage4,
      },
      {
        title: 'Upload a meeting to Sembly',
        url: 'https://www.youtube.com/embed/EoIrwWLSHww',
        preview: tutorialImage5,
      },
      {
        title: 'Control Sembly agent during the call',
        url: 'https://www.youtube.com/embed/6BG8k9ZQ0HE',
        preview: tutorialImage6,
      },
    ],
  },
] as const;

export default TUTORIALS;
