import LoopIcon from '@material-ui/icons/Loop';
import { Banner } from '@shared/components';

export const CalendarSyncingBanner: React.VFC = () => (
  <Banner
    icon={<LoopIcon />}
    content="Syncing your calendars… this may take a few minutes."
    bgcolor="status.error.backgroudColor.main"
    color="status.error.color"
  />
);

export default CalendarSyncingBanner;
