// Material UI
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
// Lib Graphql
import {
  ActionsCustomIntegrationSettingsContentTypes,
  GenericActionsCustomOutboundUserIntegration,
} from '../../types';
// Lib Assets
import customIntegrationImage from '../../assets/icon-custom-actions-integration-36.svg';

interface CustomIntegrationItemProps {
  customIntegrations: GenericActionsCustomOutboundUserIntegration;
  onSend: (
    customId: string,
    customContentType: ActionsCustomIntegrationSettingsContentTypes,
  ) => void;
}

export const CustomIntegrationItem: React.VFC<CustomIntegrationItemProps> = ({
  customIntegrations,
  onSend,
}) => {
  /* #region Helpers */
  const customContentType = customIntegrations.contentType;
  const customId = customIntegrations.id;
  const customDescription = customIntegrations.description;
  const customTitle = customIntegrations.title;
  /* #endregion */

  /* #region Helpers */
  const handleClickSendToCustomIntegrations =
    (customId: string, customContentType: ActionsCustomIntegrationSettingsContentTypes) => () => {
      onSend(customId, customContentType);
    };
  /* #endregion */

  if (!customContentType) {
    return null;
  }

  return (
    <MenuItem
      title={customDescription || undefined}
      onClick={handleClickSendToCustomIntegrations(customId, customContentType)}
    >
      <img
        src={customIntegrationImage}
        alt={customTitle || 'Custom Automation'}
        width={24}
        height={24}
      />
      <Box mx={1}>Post item to:</Box>
      <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        <b>{customTitle}</b>
      </Box>
    </MenuItem>
  );
};

export default CustomIntegrationItem;
