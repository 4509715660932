// Material UI
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// Lib Shared
import { getChatArtefactMeta } from '../utils';
import { GenericChatArtifact } from '../types';

export interface ChatArtifactProps {
  data: GenericChatArtifact;
  onClick?: (data: GenericChatArtifact) => void;
}

export const ChatArtifact: React.VFC<ChatArtifactProps> = ({ data, onClick = () => null }) => {
  const styles = useStyles();
  const theme = useTheme();

  const handleClickOnArtifact = () => {
    onClick(data);
  };

  const meta = getChatArtefactMeta(data.type, theme);

  if (!meta) return null;

  return (
    <div className={styles.root} onClick={handleClickOnArtifact}>
      <div className={styles.icon} style={{ backgroundColor: meta.color }}>
        {!!meta && meta.icon}
      </div>
      <div className={styles.content}>
        <Typography variant="body1" component="span" style={{ wordBreak: 'break-word' }}>
          {data.name}
        </Typography>
        <Typography variant="body1" component="span" color="textSecondary">
          {meta.type}
        </Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    gridGap: theme.spacing(1),
    cursor: 'pointer',
    alignItems: 'center',
    alignSelf: 'flex-start',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 2,
    height: 40,
    width: 40,
    fontSize: 24,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 100,
  },
}));

export default ChatArtifact;
