import { VFC, CSSProperties } from 'react';
import { toast } from 'react-toastify';
import { copyToClipboard } from '@powowfe/common';
import { Button, Link, Typography, Tooltip, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AGENT_EMAIL } from '@shared/configuration';

export interface CalendarFormProps {
  logo: string;
  logoStyle: CSSProperties;
}

export const CalendarForm: VFC<CalendarFormProps> = ({ logo, logoStyle }) => {
  const theme = useTheme();
  const styles = useStyles();

  const handleCopyEmail = async () => {
    const success = await copyToClipboard(AGENT_EMAIL);
    if (success) toast.dark('Copied to clipboard.');
  };

  return (
    <div className={styles.root}>
      <Box mb={2}>
        <Typography variant="subtitle1">Invite Sembly agent in your calendar.</Typography>
      </Box>
      <Box
        height={120}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        mb={4}
        bgcolor="grey.100"
        borderRadius={6 * theme.shape.borderRadius}
      >
        <img src={logo} alt="Calendar" style={logoStyle} />
      </Box>
      <ol className={styles.list}>
        <li>
          Invite
          <Tooltip title="Click to copy">
            <Button
              variant="outlined"
              color="primary"
              className={styles.button}
              aria-label="Copy email address to clipboard"
              onClick={handleCopyEmail}
            >
              {` ${AGENT_EMAIL} `}
            </Button>
          </Tooltip>
          to the meeting, just as you would any other participant.
        </li>
        <li>Save and send out the calendar invite — you're done!</li>
        <li>
          Join your meeting at the scheduled time and you will see Sembly Agent as a participant!
        </li>
      </ol>

      <Typography variant="body1">
        Still have questions?{' '}
        <Link
          variant="body1"
          href="https://sembly-ai.zendesk.com/hc/en-us/articles/4407362769553"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read more
        </Link>
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 6),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 1),
      marginLeft: theme.spacing(0),
    },
  },
  list: {
    fontSize: theme.typography.body1.fontSize,
    margin: theme.spacing(3, 0),
    paddingLeft: theme.spacing(2),
    '& li': {
      marginBottom: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  grid: {
    height: 1,
  },
}));

export default CalendarForm;
