import { useEffect, forwardRef } from 'react';
import { useToastContainer, ToastContainerProps } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

export type AlertContainerProps = Omit<
  ToastContainerProps,
  'position' | 'autoClose' | 'containerId' | 'enableMultiContainer'
>;

export const AlertContainer = forwardRef<HTMLDivElement, AlertContainerProps>((props, ref) => {
  const styles = useStyles();

  const { getToastToRender, containerRef, isToastActive } = useToastContainer({
    ...props,
    autoClose: false,
    containerId: 'GlobalAlert',
    enableMultiContainer: true,
  });

  useEffect(() => {
    if (ref) {
      (ref as React.MutableRefObject<HTMLDivElement>).current = containerRef.current!;
    }
    // there is no need to track state updates for the ref forwarding
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root} ref={containerRef} id="GlobalAlert">
      {getToastToRender((position, announcementList) => {
        return announcementList.map(({ content, props: toastProps }) => {
          const isActive = isToastActive(toastProps.toastId);
          if (!isActive) toastProps.deleteToast();
          return isActive ? (
            <div key={`toast-${toastProps.key}`} className={styles.item}>
              {content}
            </div>
          ) : null;
        });
      })}
    </div>
  );
});

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  item: {
    width: '100%',
  },
}));

export default AlertContainer;
