// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
// Lib Shared
import logomarkImage from '../assets/sembly-logomark.svg';

export interface ChatAnswerCardProps {
  processing?: boolean;
  className?: string;
}

export const ChatAnswerCard: React.FC<ChatAnswerCardProps> = ({
  children,
  className = '',
  processing = false,
}) => {
  const styles = useStyles();

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.icon}>
        <img src={logomarkImage} alt="Sembly AI" />
      </div>
      <div className={styles.content}>
        {processing ? <CircularProgress size={16} color="inherit" /> : children}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
    gridGap: theme.spacing(2),
  },
  icon: {
    borderRadius: theme.shape.borderRadius * 2,
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 40,
    height: 40,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    gridGap: theme.spacing(1),
  },
}));

export default ChatAnswerCard;
