import { Breadcrumb, BreadcrumbHint } from '@sentry/types';

/**
 * Format a breadcrumb message from a UI click event.
 */
export function breadcrumbUIClick(
  breadcrumb: Breadcrumb,
  hint: BreadcrumbHint | undefined,
): Breadcrumb {
  if (!hint) return breadcrumb;

  const event = hint?.event as Event;
  const target = hint?.event?.target as HTMLElement;
  if (!event || !target) return breadcrumb;

  const message: Array<string> = [];
  const path = event.composedPath();

  message.push(pathToString(path));

  if (event.target instanceof HTMLElement) {
    if (event.target.ariaLabel) {
      message.push(event.target.ariaLabel);
    } else {
      message.push(event.target.innerText);
    }
  }

  const name = target.getAttribute('name');
  if (!!name) {
    message.push(`[name=${name}]`);
  } else {
    const formControlName = target.getAttribute('formcontrolname');
    if (!!formControlName) message.push(`[formcontrolname=${formControlName}]`);
  }

  const type = target.getAttribute('type');
  if (!!type) message.push(`[type=${type}]`);

  const placeholder = target.getAttribute('placeholder');
  if (!!placeholder) message.push(`[placeholder=${placeholder}]`);

  breadcrumb.message = message.map((m) => m?.trim()).join(' > ');

  return breadcrumb;
}

function pathToString(path: Array<any>): string {
  return path
    .filter((e) => e instanceof HTMLElement)
    .reverse()
    .map((e: HTMLElement) => {
      let result = e.tagName.toLowerCase();

      const classes = Array.from(e.classList)
        .filter((c) => ['Mui'].every((prefix) => !c.startsWith(prefix)))
        .join(' ');

      if (classes.length > 0) {
        result += `.[${classes}]`;
      }

      const id = e.getAttribute('id');
      if (id !== null) {
        result += `#${id}`;
      }

      return result;
    })
    .join(' > ');
}

export default breadcrumbUIClick;
