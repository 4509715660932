import { useState } from 'react';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { useHistory, generatePath } from 'react-router-dom';
// Material UI
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// Sembly UI
import { SearchInput, TransitionSlide, SearchResults } from '@sembly-ui';
// App Shared
import { Routes } from '@shared/enums';
// GraphQl Queries and Types
import searchQuery from '@shared/queries/MeetingsPaginated.graphql';
import { MeetingsPaginated, MeetingsPaginatedVariables, MeetingStatuses } from '@gql-types';

export interface SearchModalProps {
  onClose: () => void;
}

export const SearchModal: React.VFC<SearchModalProps> = ({ onClose }) => {
  const styles = useStyles();
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState('');

  const [find, { fetchMore, refetch, data, called, networkStatus }] = useLazyQuery<
    MeetingsPaginated,
    MeetingsPaginatedVariables
  >(searchQuery, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' });

  const handleSearch = (search: string, page = 1, perPage = 6) => {
    setSearchTerm(search);
    if (!search) return;
    if (called && refetch) {
      refetch({ search: search || null, page, perPage, statuses: [MeetingStatuses.submitted] });
    } else {
      find({ variables: { search, page, perPage, statuses: [MeetingStatuses.submitted] } });
    }
  };

  const handleRequestNextSearchPage = () => {
    if (fetchMore && data?.meetingsPaginated?.page) {
      fetchMore({ variables: { page: data.meetingsPaginated.page + 1 } });
    }
  };

  const handleJumpToMeeting = (meetingId: string) => {
    history.push(generatePath(Routes.Meeting, { meetingId }));
    onClose();
  };

  const fetchingStatuses = [NetworkStatus.loading, NetworkStatus.fetchMore, NetworkStatus.refetch];
  const isFetching = fetchingStatuses.includes(networkStatus);

  return (
    <Dialog open fullScreen onClose={onClose} TransitionComponent={TransitionSlide}>
      <DialogContent className={styles.content}>
        <DialogTitle disableTypography className={styles.title}>
          <Typography variant="h5">Search</Typography>
          <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box mt={2} width="100%">
          <SearchInput isFetching={isFetching} onChange={handleSearch} />

          {searchTerm &&
            data?.meetingsPaginated?.objects &&
            !data.meetingsPaginated.objects.length && (
              <div>
                {!isFetching && (
                  <Box m={2}>
                    <Typography variant="body1">Sorry, nothing matches your search.</Typography>
                  </Box>
                )}
              </div>
            )}

          {searchTerm && !!data?.meetingsPaginated?.objects?.length && (
            <SearchResults
              data={data.meetingsPaginated.objects}
              onClickOnItem={handleJumpToMeeting}
              onClickOnNextPage={handleRequestNextSearchPage}
              hasNextPage={data?.meetingsPaginated?.hasNext || false}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
}));

export default SearchModal;
